import { useTranslate } from "@pankod/refine-core";
import { Descriptions, Tag } from "antd";
import { DataText, LinkText } from "components";
import { SLUGS } from "configs/path";
import IQuotation, { QuotationStatus } from "interfaces/Quotation";
import { FC, memo } from "react";
import { getStatus } from "../..";

interface Props {
  record?: IQuotation;
}
const General: FC<Props> = memo((props) => {
  const { record } = props;
  const translate = useTranslate();

  return (
    <Descriptions
      column={2}
      title={translate("common.titles.detail")}
      layout="vertical"
      style={{ background: "white", padding: 24 }}
    >
      <Descriptions.Item
        label={translate("MVV kinh doanh")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <LinkText
          value={{ ...record?.case, description: record?.case?.name }}
          resource={SLUGS.caseForSale}
          showFullInfo
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("MVV phương án kinh doanh")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.businessPlan?.code} />
        {/* <LinkText
          value={{
            ...record?.businessPlan,
            description: record?.businessPlan?.name,
          }}
          resource={SLUGS.caseForSale}
          showFullInfo
        /> */}
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Mã báo giá")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.code} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Tên báo giá")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.name} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Trạng thái")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <Tag {...getStatus(record?.quotationStatus)}>
          {translate(
            `quotations.status.${
              record?.quotationStatus || QuotationStatus.Unexported
            }`
          )}
        </Tag>
      </Descriptions.Item>
    </Descriptions>
  );
});

export default General;
