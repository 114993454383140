import {
  Button,
  Col,
  DatePicker,
  Form,
  Icons,
  Row,
  Table,
  Typography,
  notification,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { CurrencyType } from "api/enums";
import { SelectCustom } from "components";
import { MoneyInput } from "components/MoneyInput";
import { API_PATH } from "configs/path";
import { ChangeEvent, FC, memo, useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { ContractContext, IForm } from "../../..";
import { cloneDeep, isEmpty } from "lodash";
import { normalizeNumberBetterThanZero } from "utils/commons";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { MAX_SIZE, acceptExtends, acceptFiles } from "components/UploadMulti";
import { getLinkPrewviewFile, uploadFileApi } from "api";
import ButtonConfirm from "components/ButtonConfirm";
import dayjs from "dayjs";
import { DATE_FORMAT } from "configs/constants";

const { Title, Text } = Typography;
const { useWatch, useFormInstance } = Form;

const ContractDocument: FC = memo(() => {
  const { isEdit, initialValue } = useContext(ContractContext);
  const form = useFormInstance<IForm>();
  const [list, setList] = useState<string[]>([]);
  const translate = useTranslate();

  const contractGuaranteeInfos = useWatch("contractGuaranteeInfos", form);
  const caseId = useWatch("caseId", form);

  const onChangeFile =
    (keyRow: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        var extend = file.name.split(".").pop() || "";
        const isRightType = acceptExtends.indexOf(extend.toLowerCase()) > -1;
        const isLessThan5M = file.size / 1024 / 1024 < MAX_SIZE;
        if (!isLessThan5M) {
          notification.error({
            message: translate("common.upload.errorTitleSize"),
            description: translate("common.upload.errorDescSize"),
          });
        }
        if (!isRightType) {
          notification.error({
            message: translate("common.upload.errorTitleType"),
            description: translate("common.upload.errorDescType"),
          });
        }

        if (isLessThan5M && isRightType) uploadFile(keyRow, file);
      }
    };

  const uploadFile = async (keyRow: string, file: File) => {
    if (!file) return;
    const formData = new FormData();
    formData.append(`files`, file);

    try {
      const res = await uploadFileApi(
        API_PATH.contractsUploadFile,
        formData,
        caseId
      );
      const file = res.data?.[0];
      const newContractGuaranteeInfos = cloneDeep(contractGuaranteeInfos);
      newContractGuaranteeInfos[keyRow] = {
        ...newContractGuaranteeInfos[keyRow],
        attachment: file?.fileId,
        attachmentName: file?.fileUrlOriginal,
        attachmentUrl: file?.fileUrl,
      };

      form.setFieldsValue({
        contractGuaranteeInfos: newContractGuaranteeInfos,
      });
    } catch (error) {
      //
    }
  };

  const onRemoveAttachment = (keyRow: string) => () => {
    const newContractGuaranteeInfos = cloneDeep(contractGuaranteeInfos);
    newContractGuaranteeInfos[keyRow] = {
      ...newContractGuaranteeInfos[keyRow],
      attachment: null,
      attachmentName: "",
      attachmentUrl: "",
    };

    form.setFieldsValue({
      contractGuaranteeInfos: newContractGuaranteeInfos,
    });
  };

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const onGetLinkView = (index: number) => async () => {
    try {
      const fileUrl = contractGuaranteeInfos[list[index]]?.attachmentUrl;
      if (!fileUrl) return;
      const res = await getLinkPrewviewFile(
        API_PATH.contractGetLinkPreviewFile,
        fileUrl
      );
      const url: string = res?.data as unknown as string;
      window.open(url, "_blank");
    } catch (error) {
      notification.error({
        message: "Đã có lỗi xảy ra. Vui lòng liên hệ quản trị viên.",
      });
    }
  };

  useEffect(() => {
    if (isEdit && initialValue?.contractGuaranteeInfos?.length) {
      const ids: string[] = [];
      const contractGuaranteeInfosData: any = {};
      initialValue?.contractGuaranteeInfos?.forEach((gi) => {
        ids.push(gi.id);
        contractGuaranteeInfosData[gi.id] = {
          ...gi,
          attachmentName: gi?.attachment?.fileUrlOrigin,
          attachment: gi?.attachment?.fileId,
          attachmentUrl: gi?.attachment?.fileUrl,
          guaranteeType: gi?.guaranteeType?.id,
          guaranteeIssueParty: gi?.guaranteeIssueParty?.id,
          guaranteeCondition: gi?.guaranteeCondition?.id,
        };
      });
      setList(ids);

      setTimeout(
        () =>
          form.setFieldsValue({
            contractGuaranteeInfos: contractGuaranteeInfosData,
          }),
        0
      );
    }
  }, [isEdit, initialValue?.contractGuaranteeInfos?.length]);

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Row style={{ marginBottom: 8 }}>
        <Col flex={1}>
          <Title level={5}>Thông tin chứng từ đảm bảo hợp đồng</Title>
        </Col>
        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setList([...list, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {translate("actions.create")}
          </Button>
        </div>
      </Row>
      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        scroll={{ x: 1400 }}
      >
        <Table.Column
          title={translate("common.no")}
          dataIndex="stt"
          align="center"
          width={70}
          render={(v, keyRow: string, index) => index + 1}
        />
        <Table.Column
          title={translate("Loại bảo lãnh")}
          dataIndex="guaranteeType"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <>
                <Form.Item
                  hidden
                  name={["contractGuaranteeInfos", keyRow, "id"]}
                />
                <Form.Item
                  className="m-0"
                  name={["contractGuaranteeInfos", keyRow, "guaranteeType"]}
                >
                  <SelectCustom
                    resource={API_PATH.contractGuaranteeTypeDropdownlist}
                    optionLabel="name"
                    optionValue="id"
                    placeholder={translate("Chọn loại bảo lãnh")}
                    style={{ color: "#000" }}
                    allowClear
                  />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("Ngày dự kiến phát hành")}
          dataIndex="estimatedIssueDate"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                className="m-0"
                name={["contractGuaranteeInfos", keyRow, "estimatedIssueDate"]}
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
              >
                <DatePicker
                  className="w-full"
                  placeholder={DATE_FORMAT}
                  format={DATE_FORMAT}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("Thời gian hiệu lực")}
          dataIndex="validPeriod"
          width={150}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                className="m-0"
                name={["contractGuaranteeInfos", keyRow, "validPeriod"]}
                normalize={normalizeNumberBetterThanZero}
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      if (isEmpty(value)) return Promise.resolve();
                      const num = Number(value);

                      if (num < 1) {
                        return Promise.reject(
                          new Error(translate("errors.ER013", { min: "1" }))
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <MoneyInput
                  currencyType={CurrencyType.VND}
                  className="w-full"
                  suffix=" ngày"
                  placeholder="Nhập thời gian hiệu lực"
                  min={1}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("Đơn vị phát hành")}
          dataIndex="guaranteeIssueParty"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                className="m-0"
                name={["contractGuaranteeInfos", keyRow, "guaranteeIssueParty"]}
              >
                <SelectCustom
                  resource={API_PATH.contractGuaranteeIssuePartyDropdownlist}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={translate("Chọn đơn vị phát hành")}
                  style={{ color: "#000" }}
                  allowClear
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("Điều kiện")}
          dataIndex="guaranteeCondition"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                className="m-0"
                name={["contractGuaranteeInfos", keyRow, "guaranteeCondition"]}
              >
                <SelectCustom
                  resource={API_PATH.contractGuaranteeCondition}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={translate("Chọn điều kiện")}
                  style={{ color: "#000" }}
                  allowClear
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("Giá trị bảo lãnh")}
          dataIndex="guaranteeValue"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["contractGuaranteeInfos", keyRow, "guaranteeValue"]}
                placeholder="Nhập giá trị bảo lãnh"
                isLimit
                isRequired={false}
                className="m-0"
              />
            );
          }}
        />
        <Table.Column
          title={translate("common.fields.attachments")}
          dataIndex="attachment"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <>
                <Form.Item
                  hidden
                  name={["contractGuaranteeInfos", keyRow, "attachmentName"]}
                  className="m-0"
                />
                <Form.Item
                  hidden
                  name={["contractGuaranteeInfos", keyRow, "attachmentUrl"]}
                  className="m-0"
                />

                <Row align="middle">
                  <Col style={{ flex: 1 }}>
                    <Form.Item
                      name={["contractGuaranteeInfos", keyRow, "attachment"]}
                      className="m-0"
                    >
                      <Text>
                        {contractGuaranteeInfos?.[keyRow]?.attachmentName ||
                          "-"}
                      </Text>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        if (!caseId) {
                          notification.error({
                            message: "Hãy chọn mã vụ việc trước khi tải file!",
                          });
                        } else {
                          document.getElementById(keyRow)?.click();
                        }
                      }}
                      icon={<Icons.UploadOutlined size={32} />}
                      type="text"
                    />
                    <input
                      type="file"
                      accept={acceptFiles.join(",")}
                      id={keyRow}
                      onChange={onChangeFile(keyRow)}
                      hidden
                      multiple={false}
                    />
                  </Col>
                  {!!contractGuaranteeInfos?.[keyRow]?.attachment && (
                    <Col style={{ margin: "0 2px" }}>
                      <div className="line-between-action"></div>
                    </Col>
                  )}
                  <Col>
                    <Button
                      type="text"
                      icon={<Icons.EyeOutlined />}
                      className="primary"
                      onClick={onGetLinkView(index)}
                      style={{ padding: "0 8px" }}
                      hidden={!contractGuaranteeInfos?.[keyRow]?.attachment}
                    />
                    <ButtonConfirm
                      text={translate("Bạn muốn xóa tài liệu này?")}
                      description=""
                      onClick={onRemoveAttachment(keyRow)}
                      type="text"
                      danger
                      hidden={!contractGuaranteeInfos?.[keyRow]?.attachment}
                      style={{ padding: "0 8px" }}
                    >
                      <Icons.DeleteOutlined size={32} />
                    </ButtonConfirm>
                  </Col>
                </Row>
              </>
            );
          }}
        />
        <Table.Column
          align="center"
          width={100}
          fixed="right"
          title={translate("table.actions")}
          render={(v, record, index) => {
            return (
              <ButtonConfirm
                text={translate("Bạn muốn xóa dòng này?")}
                description=""
                onClick={onRemoveItem(index)}
                type="text"
                danger
              >
                <Icons.DeleteOutlined />
              </ButtonConfirm>
            );
          }}
        />
      </Table>
    </div>
  );
});

export default ContractDocument;
