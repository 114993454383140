import { Tabs } from "@pankod/refine-antd";
import { IHeaderTab } from "interfaces";
import React, { CSSProperties } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  tabs?: IHeaderTab[];
  onChangeTab?: any;
  style?: CSSProperties;
  onTabClick?: (
    activeKey: string,
    e: React.KeyboardEvent | React.MouseEvent
  ) => void;
  tabKey?: string;
  outsideParams?: any;
  isSetParamsOnUrl?: boolean;
};

export const HeaderTab: React.FC<Props> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    tabs = [],
    style = {},
    tabKey = "tab",
    outsideParams = {},
    onChangeTab,
    isSetParamsOnUrl = true,
    onTabClick,
  } = props;

  const isTabKeyInTabList = (tabKey: string) => {
    return tabs?.findIndex((t) => t.key == tabKey) !== -1;
  };

  const initialActiveTab = tabs?.find((t) => t.isActive)?.key;
  const tabParam = encodeURIComponent(
    searchParams.get(tabKey) || initialActiveTab
  );
  const [activeTabKey, setActiveTabKey] = React.useState<string>(
    isTabKeyInTabList(tabParam) ? tabParam : initialActiveTab
  );

  React.useEffect(() => {
    if (tabParam && isTabKeyInTabList(tabParam)) {
      onChangeTab(tabParam);
    }
  }, [tabParam]);

  const renderTabItems = () => {
    return tabs
      ?.filter((t) => !t.hidden)
      .map((t) => {
        return <Tabs.TabPane {...t} tab={t.name} key={t.key}></Tabs.TabPane>;
      });
  };

  const onChange = (activeKey: string) => {
    if (onChangeTab) {
      isSetParamsOnUrl &&
        setSearchParams(
          { ...outsideParams, [tabKey]: activeKey },
          { replace: true }
        );

      onChangeTab(activeKey);
      setActiveTabKey(activeKey);
    }
  };

  const onClick = (
    activeKey: string,
    e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ) => {
    if (onTabClick) {
      setSearchParams(
        { ...outsideParams, [tabKey]: activeKey },
        { replace: true }
      );
      onTabClick(activeKey, e);
    }
  };

  return (
    <Tabs
      activeKey={activeTabKey}
      onChange={onChange}
      style={{ borderBottom: "none", width: "100%", ...style }}
      onTabClick={onClick}
    >
      {renderTabItems()}
    </Tabs>
  );
};
