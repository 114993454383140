import { useTranslate, IResourceComponentsProps, useCreate, useUpdate, useNavigation } from "@pankod/refine-core";
import React from "react";
import {
  Form,
  Input,
  useForm,
  notification,
  Typography,
  Row,
  Col
} from "@pankod/refine-antd";
import { IOfficialDispatchConfig } from "interfaces";
import { PATH } from "configs/path";
import { CreateCustom } from "components/layout";
import { useNavigate } from 'react-router-dom'
import { mappingErrorFromApi } from "utils/commons";
import { useParams } from 'react-router-dom'
import InputUpper from "components/InputUpper";

const { Title } = Typography
const { TextArea } = Input;

export const OfficialDispatchCreate: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate()
  const { show,list } = useNavigation()
  const navigate = useNavigate()
  const { id } = useParams()
  const [ visibleShowModal, setVisibleShowModal ] = React.useState<boolean>(false);
  const { mutate: mutateCreate, isLoading } = useCreate<IOfficialDispatchConfig>();
  const { mutate: mutateUpdate } = useUpdate<IOfficialDispatchConfig>()
  const { form, formProps, saveButtonProps, queryResult } = useForm<IOfficialDispatchConfig>(
    {
      resource: PATH.configOfficialDispatches,
      id: id
    }
  );
  const record: IOfficialDispatchConfig | undefined = queryResult?.data?.data
  const isEdit = !!record?.id

  const onSubmit = () => {
    if (isEdit) {
      mutateUpdate(
        {
          id: id || '',
          resource: PATH.configOfficialDispatches,
          values: {
            name: form.getFieldValue('name').trim(),
            code: form.getFieldValue('code').trim(),
            description: form.getFieldValue('description'),
          },
        },
        {
          onSuccess: (res) => {
            notification.success({
              message: t('common.config.editSuccess')
            })
            navigate(`show/${res?.data?.id}`)
          },
          onError: (error: any) => {
            setVisibleShowModal(false)
            const errorFieldMapping: any = {
              'ER051': 'name'
            }
            mappingErrorFromApi(error, form, errorFieldMapping )
          },
        },
      )
    } else {
      mutateCreate(
        {
          resource: PATH.configOfficialDispatches,
          values: {
            name: form.getFieldValue('name').trim(),
            code: form.getFieldValue('code').trim(),
            description: form.getFieldValue('description'),
          },
        },
        {
          onSuccess: (res) => {
            notification.success({
              message: t('common.createSuccess')
            })
            navigate(`show/${res?.data?.id}`)
          },
          onError: (error: any) => {
            setVisibleShowModal(false)
            const errorFieldMapping: any = {
              'ER051': 'code'
            }
            mappingErrorFromApi(error, form, errorFieldMapping )
          },
        },
      )
    }
  }

  return (
    <CreateCustom
      {...props}
      title={
        isEdit
          ? t('officialDispatches.titles.edit') + ': ' + record?.name
          : t('officialDispatches.titles.create')
      }
      confirmModalProps={{
        title: isEdit
          ? t('common.config.editConfirm')
          : t('officialDispatches.createConfirm'),
        description: isEdit ? t('common.config.editConfirmDesc') : '',
      }}
      saveButtonProps={{
        ...saveButtonProps,
        title: isEdit
          ? t('common.saveEdit')
          : t('buttons.add')
      }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      saving={isLoading}
      cancelModalProps={{
        okButtonProps: {
            onClick: () => {
                isEdit ? show(PATH.configOfficialDispatches, record?.id || '') : list(PATH.configOfficialDispatches)
            },
            danger: true
        },
        cancelButtonProps: {}
    }}
    >
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout="vertical"
        labelCol={{ span: 10 }}
      >
        <Title level={5}>{t('common.titles.detail')}</Title>

        <Row gutter={12} style={{ width: '100%' }}>
          <Col span={12}>
            <Form.Item
              label={t('officialDispatches.fields.code')}
              name="code"
              rules={[
                {
                  required: true,
                  message: t('errors.ER005'),
                },
                {
                  pattern: /^[A-Za-z0-9~!@#$%^&*\_\-\+\=\`\|\(\)\{\}\[\]\:\;\"\'\<\>\,\.\?\/]+$/,
                  message: t('errors.ER053'),
                },
                {
                  max: 20,
                  message: t('errors.ER014', { max: '20' }),
                },
              ]}
            >
              <InputUpper
                placeholder={t('officialDispatches.placeholder.code')}
                disabled={isEdit}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('officialDispatches.fields.name')}
              name="name"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t('errors.ER005'),
                },
                {
                  max: 100,
                  message: t('errors.ER014', { max: '100' }),
                },
              ]}
            >
              <Input placeholder={t('officialDispatches.placeholder.name')} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('officialDispatches.fields.description')}
              name="description"
              rules={[
                {
                  max: 200,
                  message: t('errors.ER014', { max: '200' }),
                },
              ]}
            >
              <TextArea
                showCount={{
                  formatter: (args: { count: number }) => `${args?.count}/200`,
                }}
                placeholder={t('officialDispatches.placeholder.description')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </CreateCustom>
  );

}