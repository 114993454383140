import {
  Card,
  Col,
  Descriptions,
  Icons,
  Radio,
  Row,
  Space,
  Table,
  Tag,
  TextField,
  Typography
} from "@pankod/refine-antd";
import {
  BaseKey,
  IResourceComponentsProps,
  useOne,
  useShow,
  useTable,
  useTranslate,
} from "@pankod/refine-core";
import { ApprovalStatus } from "api/enums";
import { ButtonTooltip, EmptyData, SearchBox } from "components";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import { DataText } from "components/DataText";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { IntegrationStatusTag } from "components/StatusTag/IntegrationStatusTag";
import { ShowCustom } from "components/layout";
import { DATE_FORMAT } from "configs/constants";
import { API_PATH, PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { ICase, IPayment } from "interfaces";
import React, { useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { formatISODateTimeToView } from "utils/commons";
import ActionBusinessPlan, { groupById } from "../components/actionBusinessPlan";
import { getTagColor } from "./list";
const { Text } = Typography;

export const CaseShow: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { id } = useParams();
  const { queryResult } = useShow<ICase>({
    resource: PATH.cases,
    id,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: nonsaleGroupFeature } = useOne({
    resource: `${API_PATH.cases}/${id}/objects-group-general-by-feature`,
    id: "",
    queryOptions: {
      enabled: !!id
    }
  })
  const groupData = groupById(nonsaleGroupFeature?.data);

  const renderActionBusinessPlan = useCallback((key: string) => (
    <ActionBusinessPlan key={key} {...groupData[key]} />
  ), [groupData]);

  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        title={
          <Text style={{ marginBottom: 0 }}>
            {t("cases.info")}: {record?.code}
          </Text>
        }
        contentStyles={{ backgroundColor: "transparent", padding: 0 }}
        bodyStyle={{ padding: 0 }}
        breadcrumbText={record?.code}
        headerButtons={<></>}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} lg={16}>
            <Card>
              <Descriptions column={2} title={t("cases.info")} layout="vertical">
                <Descriptions.Item
                  labelStyle={{ fontWeight: "500" }}
                  label={t("cases.fields.code")}
                  span={2}
                >
                  <DataText value={record?.code} />
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ fontWeight: "500" }}
                  label={t("common.createdDate")}
                >
                  <DateTimeFieldCustom
                    value={record?.createdDate}
                    defaultValue={t("common.emptyText")}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ fontWeight: "500" }}
                  label={t("Trạng thái")}
                >
                  <Tag color={getTagColor(record?.status?.code)}>
                    {record?.status?.name}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ fontWeight: "500" }}
                  label={t("common.createdName")}
                >
                  <DataText className="primary" value={record?.createdName} />
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ fontWeight: "500" }}
                  label={t("cases.fields.department")}
                >
                  <DataText
                    value={`${record?.department?.code} - ${record?.department?.name}`}
                  />
                </Descriptions.Item>
                

                <Descriptions.Item
                  label={t("integration.syncTime")}
                  labelStyle={{ fontWeight: 500 }}
                >
                  <DataText
                    value={formatISODateTimeToView(record?.syncTime, DATE_FORMAT)}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("integration.syncStatus")}
                  labelStyle={{ fontWeight: 500 }}
                  span={2}
                >
                  <IntegrationStatusTag status={record?.syncStatus} />
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label={t("cases.fields.name")}
                  span={3}
                >
                  <DataText value={record?.description} />
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card >
              <Descriptions
                column={1}
                extra={true}
                title={t("common.activities")}
                layout="vertical"
                className="no-padding"
              >
                {
                  <Descriptions.Item labelStyle={{ display: "block", padding: "0px" }} contentStyle={{ display: "block" }}>
                    {(nonsaleGroupFeature?.data?.length ?? 0) > 0
                      ? Object.keys(groupData).map(renderActionBusinessPlan)
                      : <p>{t("common.noActivities")}</p>
                    }
                  </Descriptions.Item>
                }
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </ShowCustom>
    </>
  );
};

interface PaymentInfo extends React.HTMLAttributes<HTMLDivElement> {
  caseId?: BaseKey;
  hasFilter?: boolean;
}

export const PaymentInformation = ({
  caseId,
  hasFilter = false,
  ...props
}: PaymentInfo) => {
  const t = useTranslate();
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get("code") || "";

  const [paymentCode, setPaymentCode] = React.useState<string>(codeParam);

  const {
    tableQueryResult: { data: dataTable, isLoading, isRefetching },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    setFilters,
  } = useTable<IPayment>({
    initialCurrent: 1,
    resource: PATH.payments,
    metaData: {
      subFilter: `/case/${caseId}`,
    },
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const setSearchFilter = (value: any) => {
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  const onSearch = (value: string) => {
    setCurrent(1);
    setSearchFilter(value);
  };

  return (
    <div {...props}>
      {hasFilter && (
        <>
          <Space align="start">
            <div>
              <SearchBox
                placeholder={t("payments.fields.searchPlaceHolder")}
                onSearch={onSearch}
                style={{ width: 304 }}
                value={paymentCode}
                onChange={(value) => {
                  setPaymentCode(value.target.value);
                }}
              />
            </div>
          </Space>
          <Radio.Group
            defaultValue={-1}
            buttonStyle="solid"
            onChange={(e) => {
              setFilters([
                {
                  field: "status",
                  operator: "eq",
                  value: e?.target?.value === -1 ? "" : e?.target?.value,
                },
              ]);
            }}
            style={{ display: "block", marginBottom: 16 }}
          >
            <Radio.Button value={-1}>{t("common.status.all")}</Radio.Button>
            <Radio.Button value={ApprovalStatus.Approved}>
              {t("common.status.approved")}
            </Radio.Button>
            <Radio.Button value={ApprovalStatus.Draft}>
              {t("common.status.draft")}
            </Radio.Button>
          </Radio.Group>
        </>
      )}
      <Table
        loading={isLoading || isRefetching}
        dataSource={dataTable?.data}
        rowKey="id"
        locale={{
          emptyText: <EmptyData text={t("payments.noDataSearch")} />,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              window.open(
                `/${PATH.eOffice}/${PATH.payment}/${PATH.payments}/show/${record.id}`,
                "_blank"
              );
            },
          };
        }}
        pagination={{
          pageSize: pageSize,
          current: current,
          total: dataTable?.total,
          onChange: (cur, size) => {
            setCurrent(cur);
            setPageSize(size);
          },
          showTotal: (total) => (
            <p>{t("common.totalPage", { total: total })}</p>
          ),
          locale: {
            items_per_page: t("common.pageNumber"),
          },
        }}
        size="small"
        showSorterTooltip={false}
        scroll={{ x: "auto" }}
      >
        <Table.Column
          dataIndex="code"
          title={t("payments.fields.code")}
          render={(value) => <DataText value={value} defaultValue="-" />}
        />
        <Table.Column
          dataIndex="name"
          title={t("payments.fields.name")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="createdName"
          title={t("common.createdUsername")}
          render={(value) => <TextField className="primary" value={value} />}
        />
        <Table.Column
          dataIndex="createdDate"
          title={t("common.createdDate")}
          render={(value) => value && <DateTimeFieldCustom value={value} />}
        />
        <Table.Column
          dataIndex="status"
          title={t("payments.fields.status")}
          render={(value) => <ApprovalStatusLabel status={value} />}
        />
      </Table>
    </div>
  );
};

interface PrepayListInterface extends React.HTMLAttributes<HTMLDivElement> {
  caseId?: BaseKey;
  hasCreate?: boolean;
  hasFilter?: boolean;
  onCreate?: () => void;
}

export const PrepaysList = ({
  caseId,
  hasCreate = false,
  hasFilter = false,
  onCreate,
  ...props
}: PrepayListInterface) => {
  const { checkCreateHaveHelperText } = usePermissions();
  const t = useTranslate();

  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get("code") || "";

  const [prepayCode, setPrePayCode] = React.useState<string>(codeParam);

  const {
    tableQueryResult: { data: dataTable, isLoading },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    setFilters,
  } = useTable<IPayment>({
    initialCurrent: 1,
    resource: PATH.prepays,
    metaData: {
      subFilter: `/case/${caseId}`,
    },
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const setSearchFilter = (value: any) => {
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  const onSearch = (value: string) => {
    setCurrent(1);
    setSearchFilter(value);
  };

  return (
    <div {...props}>
      <div style={{ marginBottom: 16 }} className="flex justify-between">
        <div>
          {hasFilter && (
            <>
              <Space align="start">
                <SearchBox
                  placeholder={t("prepays.fields.searchPlaceHolder")}
                  onSearch={onSearch}
                  style={{ width: 304 }}
                  value={prepayCode}
                  onChange={(value) => {
                    setPrePayCode(value.target.value);
                  }}
                />
              </Space>
              <Radio.Group
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => {
                  setFilters([
                    {
                      field: "status",
                      operator: "eq",
                      value: e?.target?.value === -1 ? "" : e?.target?.value,
                    },
                  ]);
                }}
                style={{ display: "block" }}
              >
                <Radio.Button value={-1}>{t("common.all")}</Radio.Button>
                <Radio.Button value={ApprovalStatus.Approved}>
                  {t("common.status.approved")}
                </Radio.Button>
                <Radio.Button value={ApprovalStatus.Draft}>
                  {t("common.status.draft")}
                </Radio.Button>
              </Radio.Group>
            </>
          )}
        </div>
        {hasCreate && (
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={onCreate}
            {...checkCreateHaveHelperText(PATH.prepays)}
          >
            {t("actions.create")}
          </ButtonTooltip>
        )}
      </div>

      <Table
        loading={isLoading}
        dataSource={dataTable?.data}
        rowKey="id"
        locale={{
          emptyText: <EmptyData text={t("prepays.noDataSearch")} />,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              window.open(
                `/${PATH.eOffice}/${PATH.prepays}/show/${record.id}`,
                "_blank"
              );
            },
          };
        }}
        pagination={{
          pageSize: pageSize,
          current: current,
          total: dataTable?.total,
          onChange: (cur, size) => {
            setCurrent(cur);
            setPageSize(size);
          },
          showTotal: (total) => (
            <p>{t("common.totalPage", { total: total })}</p>
          ),
          locale: {
            items_per_page: t("common.pageNumber"),
          },
        }}
        size="small"
        showSorterTooltip={false}
        scroll={{ x: "auto" }}
      >
        <Table.Column
          dataIndex="code"
          title={t("prepays.fields.code")}
          render={(value) => <TextField value={value || "-"} />}
        />
        <Table.Column
          dataIndex="name"
          title={t("prepays.fields.name")}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="createdName"
          title={t("common.createdUsername")}
          render={(value) => <TextField className="primary" value={value} />}
        />
        <Table.Column
          dataIndex="createdDate"
          title={t("common.createdDate")}
          render={(value) => value && <DateTimeFieldCustom value={value} />}
        />
        <Table.Column
          dataIndex="status"
          title={t("prepays.fields.status")}
          render={(value) => <ApprovalStatusLabel status={value} />}
        />
      </Table>
    </div>
  );
};
