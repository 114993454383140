import { FC, memo } from "react";
import CaseInfo from "./CaseInfo";
import styled from "styled-components";
import General from "./General";
import ProductList from "./ProductList";

const InformationStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Information: FC = memo(() => {
  return (
    <InformationStyle>
      <CaseInfo />
      <General />
      <ProductList />
    </InformationStyle>
  );
});

export default Information;
