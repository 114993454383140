import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Row,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { IForm, SettlementFormContext } from "../../..";
import type { ColumnsType } from "antd/es/table";
import { ObjectSubmitCondition } from "interfaces";
import { SubmitConditionInfoType } from "api/enums";
import { useTranslate } from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import { cloneDeep } from "lodash";
import { CheckBoxError } from "components/ErrorCheckbox";

const { useFormInstance } = Form;
const { Title, Text } = Typography;

const SubmitConditionsInfo: FC = memo(() => {
  const { submitConditionFetch } = useContext(SettlementFormContext);
  const { refetch } = submitConditionFetch!;
  const translate = useTranslate();
  const form = useFormInstance<IForm>();
  const { id } = useParams();

  const [conditionState, setConditionState] = useState<
    ObjectSubmitCondition[] | undefined
  >([]);

  const onCheckboxChange = (
    e: any,
    featureSubmitConditionId: string | undefined
  ) => {
    setConditionState((prevState) => {
      const newState = prevState?.map((item: any) => {
        if (item.featureSubmitConditionId === featureSubmitConditionId) {
          item.isOk = e.target.checked;
        }
        return item;
      });
      return newState;
    });

    const submitConditions = form.getFieldValue("submitConditions");
    const data = submitConditions?.map((item: any) => {
      if (item.featureSubmitConditionId === featureSubmitConditionId) {
        item.isOk = e.target.checked;
      }
      return item;
    });
    form.setFieldsValue({
      submitConditions: data,
    });
  };

  const columns = useMemo<ColumnsType<ObjectSubmitCondition>>(
    () => [
      {
        title: translate("STT"),
        width: 50,
        dataIndex: "id",
        key: "id",
        render: (_, record, index) => index + 1,
      },
      {
        title: translate("Điều kiện"),
        width: 200,
        dataIndex: "description",
        key: "description",
        render: (_, record, index) => (
          <Text>{record?.submitConditionInfo?.description}</Text>
        ),
      },
      {
        title: translate("Thông tin"),
        width: 200,
        dataIndex: "url",
        key: "url",
        render: (_, record, index) => (
          <div
            dangerouslySetInnerHTML={{
              __html: record?.submitConditionInfo?.url,
            }}
          />
        ),
      },
      {
        title: translate("Thỏa điều kiện"),
        width: 50,
        dataIndex: "action",
        key: "action",
        align: "center",
        render: (_, record, index) => {
          const { submitConditionInfo, isOk } = record;
          return submitConditionInfo?.type ===
            SubmitConditionInfoType.Manual ? (
            <Checkbox
              checked={isOk}
              onChange={(e) =>
                onCheckboxChange(e, record?.featureSubmitConditionId)
              }
            />
          ) : isOk ? (
            <Checkbox checked />
          ) : (
            <CheckBoxError checked />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (submitConditionFetch?.data?.data) {
      form.setFieldsValue({
        submitConditions: submitConditionFetch.data.data.map((item) => ({
          id: !!id ? item.id : null,
          isOk: item.isOk,
          featureSubmitConditionId: item.featureSubmitConditionId,
        })),
      });
    }

    setConditionState(submitConditionFetch?.data?.data);
  }, [submitConditionFetch?.data?.data, id, form]);

  return (
    <Form.Item
      name="submitConditions"
      style={{ padding: 24, backgroundColor: "white" }}
    >
      <Title level={5}>{translate("Thông tin cần để chuyển DTHĐ")}</Title>
      <Row style={{ marginBottom: 16 }} align="middle">
        <Col flex={1} />

        <Button
          type="primary"
          onClick={() => refetch()}
          loading={submitConditionFetch?.isFetching}
        >
          {translate("Cập nhật")}
        </Button>
      </Row>
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="featureSubmitConditionId"
        columns={columns}
        dataSource={cloneDeep(conditionState)}
        scroll={{ x: 500 }}
        style={{ paddingLeft: 16 }}
        size="small"
      />
    </Form.Item>
  );
});

export default SubmitConditionsInfo;
