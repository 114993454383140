import { useTranslate } from "@pankod/refine-core";
import { Rule } from "antd/lib/form";
import { formatNumber, isValidEmail } from "utils/commons";

interface State {
  phone: Rule[];
  email: Rule[];
  required: Rule[];
  max: (length: number) => Rule[];
}
export const useFormRule = (): State => {
  const translate = useTranslate();
  return {
    max: (length: number) => [
      {
        max: length,
        message: translate("errors.ER014", { max: formatNumber(length) }),
      },
    ],
    required: [{ required: true, message: translate("errors.ER005") }],
    phone: [
      {
        pattern: /^[0-9]+$/,
        message: translate("errors.ER037"),
      },
      {
        min: 10,
        message: translate("errors.ER013", { min: "10" }),
      },
      {
        max: 11,
        message: translate("errors.ER014", { max: "11" }),
      },
    ],
    email: [
      {
        max: 200,
        message: translate("errors.ER014", { max: "200" }),
      },
      {
        validator: async (_, value) => {
          if (!value) return;
          if (!isValidEmail(value)) {
            return Promise.reject(translate("errors.ER015"));
          }
        },
      },
    ],
  };
};
