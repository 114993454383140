import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useUpdate,
  useShow
} from "@pankod/refine-core";
import { Typography, Tag, Button, Table, Space, notification } from "@pankod/refine-antd";
import { UserStatus } from "api/enums";
import { userResendEmail } from "api/index"
import usePermissions from 'hooks/permission'
import { PATH } from "configs/path";
import { ShowCustom } from "components/layout";
import { showErrorToast } from "api/common";

const { Title, Text } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();

  const { queryResult } = useShow<any>();
  const [ isDisable, setIsDisable ] = React.useState<boolean>(false);
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const { mutate: mutateSubmit, isLoading: isDisableUpdateStatus} = useUpdate<any>();
  const {
    checkEdit,
    checkCreate,
  } = usePermissions();

  const renderStatus = (value: number) => {
    switch (value) {
      case UserStatus.Welcome:
        return <Tag color="processing">{t("users.fields.status.welcome")}</Tag>
    
      case UserStatus.Active:
        return <Tag color="success">{t("users.fields.status.active")}</Tag>
    
      default:
        return <Tag color="#F0F0F0" style={{color: '#8C8C8C'}}>{t("users.fields.status.deactive")}</Tag>
    }
  }

  const resend = () => {
    setIsDisable(true)
    userResendEmail({
      userId: record?.userId
    }).then(res => {
      if (res && res.data) {
        setIsDisable(false)
        notification.success({
          message: t('users.actions.reSendEmailSuccess')
        })
      }
    })
    .catch(err => {
      setIsDisable(false)
      showErrorToast(err)
    })
  }

  const updateStatus = (status: number) => {
    mutateSubmit(
      {
        id: '',
        resource: PATH.users,
        values: {
          userId: record?.userId,
          newStatus: status
        }
      },
      {
        onSuccess: () => {
          refetch()
          notification.success({
            message: status == UserStatus.Deactive ?  t('users.actions.deactiveSuccess') : t('users.actions.activeSuccess')
          })
        },
        onError: (error) => {
          showErrorToast(error)
        },
      },
    )
  }

  return (
    <ShowCustom
      {...props}
      isLoading={isLoading}
      breadcrumbText={t("users.accountInfo")}
      title={<Text style={{marginBottom: 0}}>{t("users.user")}: {record?.email}</Text>}
      headerButtons={<></>}
    >
      <Title level={5}>{t("users.accountInfo")}</Title>
      <Table
        dataSource={[record]}
        pagination={false}
        rowKey="userId"
      >
        <Table.Column title={t("users.fields.email")} dataIndex="email" key="email" />
        <Table.Column
          dataIndex={["userTenantStatus"]}
          title={t("users.fields.status.title")}
          render={(value) => <Space style={{display: 'flex', justifyContent: "space-between"}}>
            {renderStatus(value)}
            {record?.userTenantStatus === UserStatus.Welcome && <Button
              type="primary"
              disabled={
                isDisable || !(checkCreate(PATH.users) || checkEdit(PATH.users))
              }
              onClick={() => resend()}
            >
              {t("users.actions.reSendEmail")}
          </Button>}
          {record?.userTenantStatus !== UserStatus.Welcome && <Button
            disabled={
              isDisableUpdateStatus || !checkEdit(PATH.users) 
            }
            type={record?.userTenantStatus === UserStatus.Welcome ? "default" : "primary" }
            onClick={() => {
              const newStatus = record?.userTenantStatus === UserStatus.Deactive ? UserStatus.Active : UserStatus.Deactive
              updateStatus(newStatus)
            }}
            >
              {record?.userTenantStatus === UserStatus.Deactive ? t("users.actions.active"): t("users.actions.deactive")}
          </Button>}
          </Space>}
        />
      </Table>
    </ShowCustom>
  );
};
