import { useForm, useSelect } from "@pankod/refine-antd";
import { useNavigation, useTable, useTranslate } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import { BusinessOpportunitiesStatus } from "interfaces/BusinessOpportunities";
import Employee from "interfaces/Employee";
import { MarketStatus } from "interfaces/MarketArea";
import { RegionStatus } from "interfaces/Region";
import { useCallback, useMemo } from "react";
import usePermissions from "hooks/permission";
import { getStatus } from "../show/controller";

export const useController = () => {
  const { edit, show, create } = useNavigation();
  const translate = useTranslate();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();

  const { form, formProps } = useForm<any>();

  const table = useTable<any>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const { filters, setSorter, setCurrent, setFilters } = table;

  const { selectProps: employeeSelectProps, queryResult } = useSelect<Employee>(
    {
      resource: API_PATH.businessOpportunitieEmployeeDropdownlist,
      optionValue: "id",
      optionLabel: "fullName",
      onSearch: (value) => [
        {
          field: "q",
          operator: "eq",
          value: value,
        },
      ],
    }
  );

  const employeeOptions = useMemo(
    () =>
      queryResult.data?.data?.map((op) => ({
        label: `${op?.code} - ${op?.fullName}`,
        value: op.id,
      })),
    [queryResult]
  );

  const { selectProps: areaSelectProps } = useSelect({
    resource: API_PATH.regionDropdownList,
    optionValue: "value",
    optionLabel: "label",
    filters: [
      { field: "regionStatus", operator: "eq", value: RegionStatus.ALL },
    ],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: marketProps } = useSelect({
    resource: API_PATH.marketDropdownList,
    optionValue: "id",
    optionLabel: "name",
    filters: [
      { field: "marketAreaStatus", operator: "eq", value: MarketStatus.ALL },
    ],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: statusProps } = useSelect({
    resource: API_PATH.businessOpportunituesStatusDropdownlist,
    optionValue: "id",
    optionLabel: "name",
  });

  const { selectProps: statusCloseProps } = useSelect({
    resource: API_PATH.businessOpportunituesStatusCloseDropdownlist,
    optionValue: "id",
    optionLabel: "name",
  });

  const statusList = useMemo(() => {
    return [
      ...(statusProps?.options || []),
      ...(statusCloseProps?.options || []),
    ];
  }, [statusProps, statusCloseProps]);

  const renderStatus = useCallback(
    (
      value: BusinessOpportunitiesStatus = BusinessOpportunitiesStatus[
        "Confirm Opportunity"
      ]
    ) => getStatus(value),
    []
  );

  return {
    formProps,
    areaSelectProps,
    marketProps,
    employeeSelectProps,
    employeeOptions,
    filters,
    form,
    statusList,
    table,
    translate,
    create,
    checkCreateHaveHelperText,
    setFilters,
    setCurrent,
    setSorter,
    checkEditHaveHelperText,
    edit,
    show,
    renderStatus,
  };
};
