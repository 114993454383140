import {
  Button,
  notification,
  Icons,
  Space,
  Typography,
  Modal,
  useForm,
  Divider,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useTranslate,
  useUpdate,
  useNavigation,
  useShow,
  useOne,
} from "@pankod/refine-core";
import { EditCustom } from "components/layout";
import {
  WorkflowDiagram,
  WorkflowDiagramNode,
} from "components/WorkflowDiagram";
import { IWorkflowNode, IWorkflowNodeData } from "interfaces";
import React, { createContext, useState } from "react";
import { useEdgesState, Position, useNodesState } from "reactflow";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom";
import { PATH } from "configs/path";
import {
  ApprovalProcedureStatus,
  WorkflowStepType,
  WorkflowStatus,
} from "api/enums";
import {
  diagramMapping,
  edgesMapping,
  renderStepOption,
  validateNode,
} from "components/WorkflowDiagram/diagramHelper";
import { diagramOptionsRender } from "components/WorkflowDiagram/constants";
import { showErrorToast } from "api/common";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const { Title, Text, Paragraph } = Typography;

interface ContextState {
  feature?: any;
}

export const WorkflowContext = createContext<ContextState>({
  feature: undefined,
});

export const WorkflowDiagramEdit: React.FC<IResourceComponentsProps> = (
  props
) => {
  const handleFullWorkflow = useFullScreenHandle();

  const diagramOptions = diagramOptionsRender();
  const t = useTranslate();
  const [selectedNode, setSelectedNode] = React.useState<IWorkflowNodeData>();
  const [edges, setEdges] = useEdgesState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();
  const { id: workflowId } = useParams();
  const { show } = useNavigation();
  const { form, formProps, saveButtonProps } = useForm<IWorkflowNode>();
  const [deleteNodeId, setDeleteNodeId] = React.useState<string>();

  // Modal state
  const [visibleShowModal, setVisibleShowModal] =
    React.useState<boolean>(false);
  const [visibleErrorModal, setVisibleErrorModal] =
    React.useState<boolean>(false);
  const [visibleOutFocusModal, setVisibleOutFocusModal] =
    React.useState<boolean>(false);

  // Status state
  const [isCreating, setIsCreating] = React.useState<boolean>(false);
  const [isUpdateWhenOutFocus, setIsUpdateWhenOutFocus] =
    React.useState<boolean>(false);
  const [isUpdateWhenSubmmit, setIsUpdateWhenSubmmit] =
    React.useState<boolean>(false);
  const [isForceUpdateStartNode, setIsForceUpdateStartNode] =
    React.useState<boolean>(false);
  const [isSavingDraft, setIsSavingDraft] = React.useState<boolean>(false);
  const { queryResult } = useShow<any>();
  const { data, isLoading: isLoadingStep } = queryResult;
  const steps = data?.data;

  const workflowQueryResult = useOne<any>({
    id: workflowId ?? "",
    resource: PATH.workflows,
  });

  const { data: workflowData, isLoading: isLoadingWorkflow } =
    workflowQueryResult;
  const workflow = workflowData?.data;

  React.useEffect(() => {
    const disabledEditWorkflowsDiagrams =
      workflow?.status === WorkflowStatus.Published ||
      !!workflow?.appliedRecord;
    if (disabledEditWorkflowsDiagrams) {
      show(PATH.workflows, workflow?.id);
    }
  }, [workflow]);

  React.useEffect(() => {
    if (!isLoadingStep)
      !steps || !steps.length ? initialBasicSteps() : initDataMapping();
  }, [steps, isLoadingStep]);

  // Init start/end steps
  const initialBasicSteps = () => {
    // Init start
    const startNodeId = uuid();
    const startNode = {
      ...diagramOptions.node.start,
      id: startNodeId,
    };

    // Init end
    const endNodeId = uuid();
    const endNode = {
      ...diagramOptions.node.end,
      id: endNodeId,
      position: {
        x: diagramOptions.node.widthNode + diagramOptions.node.margin,
        y: 0,
      },
    };

    const newState = [startNode, endNode];
    setLoaded(true);
    setNodes(newState);
  };

  const [loaded, setLoaded] = useState(false);

  const initDataMapping = () => {
    const stepData = diagramMapping(steps, onDeleteNode);

    const edges = edgesMapping(steps);
    setNodes(stepData as any[]);
    setEdges(edges);
    setLoaded(true);
  };

  const onAddNewNode = (forceAdd: boolean = false) => {
    if (!forceAdd && isUpdateWhenOutFocus) {
      setIsCreating(true);
      setVisibleOutFocusModal(true);
      return;
    }
    let newState = nodes.map((node) => {
      return {
        ...node,
        selected: false,
      };
    });

    const lastNode = newState[diagramOptions.node.end.index];
    const newNodeId = uuid();
    const newNode = {
      id: newNodeId,
      type: "nodeCustom",
      sourcePosition: Position.Left,
      targetPosition: Position.Right,
      data: {
        onDelNode: (e: string) => onDeleteNode(e),
        type: WorkflowStepType.Normal,
        errors: ["errors.ER056", "errors.ER057", "errors.ER062"],
      },
      position: { ...lastNode.position },
      selected: true,
    };
    newState[diagramOptions.node.end.index].position = calculateNextPosition();
    newState.push(newNode);
    setSelectedNode(newNode);
    setNodes(newState);
  };

  const onValidateSelectedNodeBeforeAddNewNode = () => {
    if (
      [WorkflowStepType.Normal, WorkflowStepType.Decision].includes(
        selectedNode?.data?.type!
      )
    ) {
      form
        .validateFields(["name"])
        .then((r) => {
          onAddNewNode();
        })
        .catch((e) => {});
    } else {
      onAddNewNode();
    }
  };

  const onDeleteNode = (e: string) => {
    setDeleteNodeId(e);
  };

  const calculateNextPosition = (): any => {
    if (nodes.length == 0) {
      return { x: 0, y: 0 };
    } else {
      const lastNode = nodes[diagramOptions.node.end.index];
      return {
        x: lastNode.position.x,
        y:
          lastNode.position.y +
          diagramOptions.node.heightNode +
          diagramOptions.node.margin,
      };
    }
  };

  const onChangeNodeSelect = (nodeSelect: IWorkflowNodeData) => {
    if (isUpdateWhenOutFocus) {
      setVisibleOutFocusModal(true);
      return;
    }
    if (
      [WorkflowStepType.Normal, WorkflowStepType.Decision].includes(
        selectedNode?.data?.type!
      )
    ) {
      form
        .validateFields(["name"])
        .then((r) => {
          setNodes((nds) =>
            nds.map((n) => {
              return {
                ...n,
                selected: n.id === nodeSelect.id ? true : false,
              };
            })
          );
          setSelectedNode(nodeSelect);
        })
        .catch((e) => {
          setNodes((nds) =>
            nds.map((n) => {
              return {
                ...n,
                selected: n.id === selectedNode?.id ? true : false,
              };
            })
          );
        });
    } else {
      setNodes((nds) =>
        nds.map((n) => {
          return {
            ...n,
            selected: n.id === nodeSelect.id ? true : false,
          };
        })
      );
      setSelectedNode(nodeSelect);
    }
  };

  const onPaneClick = () => {
    if (isUpdateWhenOutFocus) {
      setVisibleOutFocusModal(true);
      return;
    }
    if (
      [WorkflowStepType.Normal, WorkflowStepType.Decision].includes(
        selectedNode?.data?.type!
      )
    ) {
      form
        .validateFields(["name"])
        .then((r) => {})
        .catch((e) => {
          setNodes((nds) =>
            nds.map((n) => {
              return {
                ...n,
                selected: n.id === selectedNode?.id ? true : false,
              };
            })
          );
        });
    }
  };

  const onSubmitNode = (updatedStep: IWorkflowNode) => {
    if (selectedNode)
      setSelectedNode({
        ...selectedNode,
        data: { ...selectedNode.data, ...updatedStep },
      });

    const nextStep = updatedStep.defaultNextStep;
    const yesStep = updatedStep.defaultYesStep;
    const noStep = updatedStep.defaultNoStep;
    const beforeStepsConnect = edges.filter(
      (ed) => ed.target === updatedStep.id
    );
    const isBeforeStepsConnect =
      beforeStepsConnect && beforeStepsConnect.length > 0;

    const oldNextStepId = edges.filter((ed) => ed.source === updatedStep.id);
    let newEdgesState = edges.filter((ed) => ed.source !== updatedStep.id);
    if (!!updatedStep) {
      if (!!nextStep || !!yesStep || !!noStep) {
        if (nextStep) {
          newEdgesState.push({
            id: `${updatedStep.id}-${nextStep?.value}`,
            source: updatedStep.id || "",
            target: nextStep?.value || "",
          });
        }
        if (yesStep) {
          newEdgesState.push({
            id: `${updatedStep.id}-${yesStep?.value}`,
            source: updatedStep.id || "",
            target: yesStep?.value || "",
            label: "Yes",
            labelBgPadding: [8, 4],
            labelBgBorderRadius: 4,
            labelBgStyle: { stroke: "#16a34a" },
            labelStyle: { fill: "#16a34a" },
          });
        }
        if (noStep) {
          newEdgesState.push({
            id: `${updatedStep.id}-${noStep?.value}`,
            source: updatedStep.id || "",
            target: noStep?.value || "",
            label: "No",
            labelBgPadding: [8, 4],
            labelBgBorderRadius: 4,
            labelBgStyle: { stroke: "#dc2626" },
            labelStyle: { fill: "#dc2626" },
          });
        }

        setEdges(newEdgesState);
      }

      setNodes((nds) =>
        nds.map((node) => {
          const isDecision = node?.data?.type === WorkflowStepType.Decision;

          const isBeforeUpdateStep = beforeStepsConnect?.find(
            (t) => t?.source === node.id
          );

          if (node?.id === updatedStep.id) {
            const errors = validateNode(updatedStep, isBeforeStepsConnect);
            return {
              ...node,
              data: {
                ...node?.data,
                ...updatedStep,
                errors: errors,
              },
            };
          }

          // Change name before step
          if (isBeforeUpdateStep) {
            const dataDecision =
              node.data?.defaultYesStep === updatedStep?.id
                ? {
                    defaultYesStep: renderStepOption({
                      ...node?.data?.defaultYesStep,
                      name: updatedStep?.name,
                      value: updatedStep.id,
                    }),
                  }
                : node.data?.defaultNoStep === updatedStep?.id
                ? {
                    defaultNoStep: renderStepOption({
                      ...node?.data?.defaultNoStep,
                      name: updatedStep?.name,
                      value: updatedStep.id,
                    }),
                  }
                : {};
            return {
              ...node,
              data: {
                ...node?.data,
                ...(isDecision
                  ? dataDecision
                  : {
                      defaultNextStep: renderStepOption({
                        ...node?.data?.defaultNextStep,
                        name: updatedStep?.name,
                        value: updatedStep.id,
                      }),
                    }),
              },
            };
          }

          // Validate new next step
          if (
            [nextStep?.value, yesStep?.value, noStep?.value].includes(node?.id)
          ) {
            const errors = validateNode(node.data, true);
            return {
              ...node,
              data: {
                ...node?.data,
                errors: errors,
              },
            };
          }

          // Validate old next step

          if (oldNextStepId?.length) {
            let errors: string[] = node.data?.errors;
            oldNextStepId.forEach((oldStep) => {
              if (
                oldStep.target === node.id &&
                ![nextStep?.value, yesStep?.value, noStep?.value].includes(
                  oldStep.target
                )
              ) {
                const hasBeforeStep = newEdgesState?.find(
                  (ed) => ed.target === node.id
                );
                errors = validateNode(node.data, !!hasBeforeStep);
              }
            });

            return {
              ...node,
              data: {
                ...node?.data,
                errors,
              },
            };
          }

          return node;
        })
      );
    }

    if (isUpdateWhenOutFocus) {
      setIsUpdateWhenOutFocus(false);
    }
    if (isForceUpdateStartNode) {
      setIsForceUpdateStartNode(false);
    }
  };

  const parseDataToSave = () => {
    return nodes.map((node) => {
      return {
        ...node.data,
        id: node.id,
        positionX: node.position.x,
        positionY: node.position.y,
        defaultNextStepIdIfAccept: node?.data?.defaultNextStep?.value,
        nextStepIdIfYes: node?.data?.defaultYesStep?.value,
        nextStepIdIfNo: node?.data?.defaultNoStep?.value,
        applyAcceptanceWhen: node?.data?.applyAcceptanceWhen
          ? node?.data?.applyAcceptanceWhen
          : 0,
        defaultNextStep: undefined,
        defaultYesStep: undefined,
        defaultNoStep: undefined,
        errors: undefined,
        onDelNode: undefined,
      };
    });
  };

  const onValidate = () => {
    let isError = false;
    nodes.forEach((node) => {
      const isErrorNode = node?.data?.errors && node?.data?.errors.length > 0;

      if (isErrorNode) {
        isError = true;
      }
    });

    if (isError) {
      setVisibleErrorModal(true);
      return false;
    }

    return true;
  };

  const onSave = () => {
    if (isUpdateWhenOutFocus && selectedNode) {
      setIsUpdateWhenSubmmit(true);
      return;
    }
    if (onValidate()) {
      setVisibleShowModal(true);
    }
  };

  const onSubmit = () => {
    const nodesSubmit = parseDataToSave();

    mutateUpdate(
      {
        id: workflowId || "",
        resource: PATH.workflowsDiagrams,
        values: nodesSubmit,
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: t("workflows.messages.createDiagramSuccess"),
          });
          show(PATH.workflows, workflowId || "");
        },
        onError: (error: any) => {
          setVisibleShowModal(false);
          showErrorToast(error);
        },
      }
    );
  };

  const onSaveDraft = () => {
    if (isUpdateWhenOutFocus && selectedNode) {
      setIsSavingDraft(true);
      setIsUpdateWhenSubmmit(true);
      return;
    }
    onSaveDraftSubmit();
  };

  const onSaveDraftSubmit = () => {
    setIsSavingDraft(false);
    if (
      workflow?.status === ApprovalProcedureStatus.Published ||
      workflow?.status === ApprovalProcedureStatus.Unpublished
    ) {
      notification.error({ message: t("errors.ER069") });
      return;
    }

    const nodesSubmit = parseDataToSave();

    mutateUpdate(
      {
        id: workflowId || "",
        resource: PATH.workflowsDiagrams,
        values: nodesSubmit,
        metaData: {
          type: "/save-draft",
        },
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: t("workflows.messages.createDraftDiagramSuccess"),
          });
          show(PATH.workflows, workflowId || "");
        },
        onError: (error: any) => {
          setVisibleShowModal(false);
          showErrorToast(error);
        },
      }
    );
  };

  const updateAndSubmitSave = () => {
    if (selectedNode?.data?.type === WorkflowStepType.Begin) {
      setIsForceUpdateStartNode(true);
    }
    if (
      [WorkflowStepType.Normal, WorkflowStepType.Decision].includes(
        selectedNode?.data?.type!
      )
    ) {
      form.submit();
    }
  };

  React.useEffect(() => {
    // Call submit when update node done
    if (isUpdateWhenSubmmit) {
      if (isSavingDraft) {
        onSaveDraftSubmit();
        setIsUpdateWhenSubmmit(false);
        return;
      }
      if (onValidate()) {
        onSubmit();
        setIsUpdateWhenSubmmit(false);
      }
    }
    // Add new node when update node done
    if (isCreating) {
      setIsCreating(false);
      onAddNewNode(true);
    }
  }, [nodes]);

  React.useEffect(() => {
    if (deleteNodeId) {
      const newEdges = edges.filter(
        (edge) => edge.source !== deleteNodeId || edge.target !== deleteNodeId
      );

      const newState = nodes.filter((node) => node.id !== deleteNodeId);
      const newStateMap = newState.map((node) => {
        const beforeStep = newState?.find((t) =>
          [
            t?.data?.defaultNextStep?.value,
            t?.data?.defaultYesStep?.value,
            t?.data?.defaultNoStep?.value,
          ].includes(node.id)
        );
        if (node?.data?.defaultNextStep?.value === deleteNodeId) {
          node.data = {
            ...node?.data,
            defaultNextStep: null,
          };
        }
        if (node?.data?.defaultYesStep?.value === deleteNodeId) {
          node.data = {
            ...node?.data,
            defaultYesStep: null,
          };
        }
        if (node?.data?.defaultNoStep?.value === deleteNodeId) {
          node.data = {
            ...node?.data,
            defaultNoStep: null,
          };
        }

        const errors = validateNode(node.data, beforeStep?.data);

        return {
          ...node,
          data: {
            ...node?.data,
            errors: errors,
          },
        };
      });

      setNodes(newStateMap);
      setEdges(newEdges);
      setSelectedNode(undefined);
      setDeleteNodeId(undefined);
    }
  }, [deleteNodeId]);

  return (
    <WorkflowContext.Provider value={{ feature: workflow?.feature }}>
      <EditCustom
        {...props}
        title={
          <Text>
            {t("workflows.titles.manageWorkflow")}:{" "}
            <Text className="primary">{workflow?.code}</Text>
          </Text>
        }
        titlePopupConfirm={t("workflows.messages.saveConfirm")}
        visibleShowModal={visibleShowModal}
        setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
        onSubmit={() => onSubmit()}
        saveButtonProps={{
          onClick: onSave,
          icon: null,
          disabled: isLoadingUpdate,
        }}
        editButtonText={t("workflows.saveWorkflowAction")}
        onDraft={onSaveDraft}
        draftButtonProps={{
          disabled:
            workflow?.status === WorkflowStatus.Noset ||
            workflow?.status === WorkflowStatus.Draft
              ? false
              : true,
        }}
        isLoading={isLoadingUpdate}
        cancelModalProps={{
          title:
            workflow?.status === ApprovalProcedureStatus.Noset
              ? t("workflows.messages.cancelConfirm")
              : t("workflows.messages.cancelSetupDiagramConfirm"),
        }}
        onCancel={() => show(PATH.workflows, workflow.id)}
      >
        <FullScreen
          className="reactflow-fullscreen"
          handle={handleFullWorkflow}
        >
          <Space
            align="center"
            style={{
              width: "100%",
              justifyContent: "space-between",
              padding: handleFullWorkflow.active ? 16 : "0px 0px 16px",
            }}
          >
            <Title level={5}>{t("workflows.titles.diagram")}</Title>
            <div style={{ display: "flex", gap: 8 }}>
              <Button
                icon={<Icons.FullscreenOutlined />}
                type="ghost"
                onClick={
                  handleFullWorkflow.active
                    ? handleFullWorkflow.exit
                    : handleFullWorkflow.enter
                }
              />
              <Button
                icon={<Icons.PlusOutlined />}
                type="primary"
                onClick={onValidateSelectedNodeBeforeAddNewNode}
              >
                {t("workflows.actions.addNode")}
              </Button>
            </div>
          </Space>
          <Divider style={{ margin: 0 }} />
          <div
            style={{
              height: handleFullWorkflow.active ? "auto" : "50vh",
              flex: handleFullWorkflow.active ? 1 : "inherit",
            }}
          >
            <WorkflowDiagram
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onNodeClick={(_, node) => {
                if (node.id !== selectedNode?.id) {
                  onChangeNodeSelect(node);
                }
              }}
              onPaneClick={() => {
                onPaneClick();
              }}
              onNodeDragStart={(_, node) => {
                if (node.id !== selectedNode?.id) {
                  onChangeNodeSelect(node);
                }
              }}
              selectNodesOnDrag={false}
              references={[]}
              fitView={!!nodes?.length && loaded}
              onChangeEdgeWhenHover={(nodes, edges) => {
                setNodes(nodes);
                setEdges(edges);
              }}
            />
          </div>
        </FullScreen>
        <Divider style={{ margin: 0 }} />
        <div style={{ marginTop: 24 }}>
          <WorkflowDiagramNode
            onSubmit={onSubmitNode}
            step={selectedNode}
            isUpdate={isForceUpdateStartNode}
            form={form}
            formProps={formProps}
            onFormChange={() => {
              setIsUpdateWhenOutFocus(true);
            }}
            isCreate={steps?.length === 0}
            saveButtonProps={saveButtonProps}
            otherSteps={nodes.filter((t) => t.id != selectedNode?.id)}
          />
        </div>
      </EditCustom>
      <Modal
        title=""
        visible={visibleErrorModal}
        onOk={() => setVisibleErrorModal(false)}
        onCancel={() => setVisibleErrorModal(false)}
        okText={t("common.understand")}
        cancelButtonProps={{
          hidden: true,
        }}
        width={417}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Paragraph
              style={{
                fontWeight: 500,
                fontSize: 16,
                paddingTop: 5,
                marginBottom: 0,
                paddingRight: 20,
              }}
            >
              {t("workflows.messages.notFinishNodeSetupError")}
            </Paragraph>
          </div>
        </Space>
      </Modal>
      <Modal
        title=""
        visible={visibleOutFocusModal}
        onOk={() => {
          setVisibleOutFocusModal(false);
          if (selectedNode?.data?.type === WorkflowStepType.Begin) {
            setIsForceUpdateStartNode(true);
          }
          if (
            [WorkflowStepType.Normal, WorkflowStepType.Decision].includes(
              selectedNode?.data?.type!
            )
          ) {
            form
              .validateFields(["name"])
              .then((r) => {
                form.submit();
              })
              .catch((e) => {
                setNodes((nds) =>
                  nds.map((n) => {
                    return {
                      ...n,
                      selected: n.id === selectedNode?.id ? true : false,
                    };
                  })
                );
              });
          }
        }}
        onCancel={() => {
          setIsUpdateWhenOutFocus(false);
          setSelectedNode(undefined);
          if (isCreating) {
            setIsCreating(false);
            onAddNewNode(true);
          }
          setVisibleOutFocusModal(false);
        }}
        okText={t("buttons.update")}
        cancelText={t("buttons.reject")}
        width={417}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Paragraph
              style={{
                fontWeight: 500,
                fontSize: 16,
                paddingTop: 5,
                marginBottom: 0,
                paddingRight: 20,
              }}
            >
              {t("workflows.messages.updateNodeConfirm")}
            </Paragraph>
          </div>
        </Space>
      </Modal>
      <Modal
        title=""
        visible={isUpdateWhenSubmmit}
        onOk={() => updateAndSubmitSave()}
        onCancel={() => {
          setIsUpdateWhenSubmmit(false);
        }}
        cancelButtonProps={{
          disabled: isLoadingUpdate,
          loading: isLoadingUpdate,
        }}
        okButtonProps={{ disabled: isLoadingUpdate, loading: isLoadingUpdate }}
        okText={t("buttons.updateAndSave")}
        cancelText={t("buttons.reject")}
        width={417}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Paragraph
              style={{
                fontWeight: 500,
                fontSize: 16,
                paddingTop: 5,
                marginBottom: 0,
                paddingRight: 20,
              }}
            >
              {t("workflows.messages.saveDraftConfirm")}
            </Paragraph>
          </div>
        </Space>
      </Modal>
    </WorkflowContext.Provider>
  );
};
