import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { PATH } from "configs/path";
import { FC, createContext, memo } from "react";
import CaseInfo from "./components/CaseInfo";
import IServiceRequest, { ServiceRequestData } from "interfaces/ServiceRequest";
import DetailInfo from "./components/DetailInfo";
import ProductList from "./components/ProductList";
import {
  ApprovalServiceRequest,
  TabKey,
} from "components/ApprovalServiceRequest";
import styled from "styled-components";
import { Typography } from "@pankod/refine-antd";

interface InformationProps {
  record?: Partial<ServiceRequestData>;
  hidden?: boolean;
}
const { Text } = Typography;

const InfomationStyle = styled.div<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  gap: 8px;
  min-height: 400px;
`;

const Infomation: FC<InformationProps> = memo(({ record, hidden }) => {
  return (
    <ServiceRequestShowContext.Provider value={record}>
      <InfomationStyle hidden={hidden}>
        <CaseInfo />
        <DetailInfo />
        <ProductList />
      </InfomationStyle>
    </ServiceRequestShowContext.Provider>
  );
});

export const ServiceRequestShowContext = createContext<
  Partial<ServiceRequestData> | undefined
>({});

const ServiceRequestShow: FC<IResourceComponentsProps> = memo((props) => {
  const translate = useTranslate();
  return (
    <ApprovalServiceRequest
      {...props}
      onApprovalSuccess={() => {}}
      resource={PATH.serviceRequests}
      renderInformation={(record: IServiceRequest, tab: TabKey) => (
        <Infomation
          record={record?.objectData}
          hidden={tab !== TabKey.INFO_TAB}
        />
      )}
      title={(record: IServiceRequest) => (
        <>
          {translate("common.ServiceRequests")}:{" "}
          <Text className="primary">{record?.objectData?.name}</Text>
        </>
      )}
    />
  );
});

export default ServiceRequestShow;
