import {
  Descriptions,
  Icons,
  Modal,
  Space,
  Tag,
  Typography,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { ButtonTooltip, DataText } from "components";
import { ShowCustom } from "components/layout";
import { PATH } from "configs/path";
import { FC } from "react";
import { CUSTOMER_STATUS_LABEL, DATE_FORMAT } from "configs/constants";
import { formatISODateTimeToView } from "utils/commons";
import { useController } from "./controller";
import { BusinessIndustry, CustomerStatus } from "interfaces/Customer";
import { IntegrationStatusTag } from "components/StatusTag/IntegrationStatusTag";

const { Text, Paragraph } = Typography;

const CustomerShow: FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const { edit } = useNavigation();
  const {
    isLoading,
    record,
    isDraft,
    isAssigned,
    visibleModalDelete,
    isDeleteLoading,
    visibleModalActivate,
    isUpdateLoading,
    toggleModalDelete,
    checkEditHaveHelperText,
    checkDeleteHaveHelperText,
    toggleModalActivate,
    renderStatus,
    onDelete,
    onActive,
  } = useController();

  const businessIndustry = record?.businessIndustry || ({} as BusinessIndustry);
  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        breadcrumbText={record?.name}
        title={
          <Text style={{ marginBottom: 0 }}>
            {translate("customer.customer")}:{" "}
            <Text className="primary">{record?.name}</Text>
          </Text>
        }
        headerButtons={
          <>
            {isDraft && (
              <ButtonTooltip
                type="primary"
                danger
                icon={<Icons.DeleteOutlined />}
                onClick={toggleModalDelete}
                {...checkDeleteHaveHelperText(PATH.customers)}
              >
                {translate("actions.delete")}
              </ButtonTooltip>
            )}
            <ButtonTooltip
              type={isAssigned ? "primary" : "ghost"}
              icon={<Icons.EditOutlined />}
              onClick={() => edit(PATH.customers, record?.id!)}
              {...checkEditHaveHelperText(PATH.customers)}
            >
              {translate("actions.edit")}
            </ButtonTooltip>
            {isDraft && (
              <ButtonTooltip
                type="primary"
                icon={<Icons.CheckCircleOutlined />}
                onClick={toggleModalActivate}
                {...checkEditHaveHelperText(PATH.customers)}
              >
                {translate("customer.turnIntoPotential")}
              </ButtonTooltip>
            )}
          </>
        }
      >
        <Descriptions
          column={3}
          title={translate("customer.detailedInformation")}
          layout="vertical"
        >
          <Descriptions.Item
            label={translate("customer.customerCode")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.code} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.customerName")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.name} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.status")}
            labelStyle={{ fontWeight: 500 }}
          >
            <Tag {...renderStatus(record?.status)}>
              {translate(
                CUSTOMER_STATUS_LABEL[record?.status || CustomerStatus.DRAFT]
              )}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.abbreviationName")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.abbreviationName} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("integration.syncTime")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={formatISODateTimeToView(record?.syncTime, DATE_FORMAT)}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("integration.syncStatus")}
            labelStyle={{ fontWeight: 500 }}
          >
            <IntegrationStatusTag
              status={record?.syncStatus}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.taxCode")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.identifiedTaxCode} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.phoneNumber")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.phoneNumber} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.address")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.address} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.email")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.email} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.website")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.website} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.debtMaturityDate")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.paymentTerm} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.businessScope")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={
                businessIndustry?.code &&
                `${businessIndustry?.code}-${businessIndustry?.name}`
              }
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.market")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={
                businessIndustry?.marketArea &&
                `${businessIndustry?.marketArea?.code}-${businessIndustry?.marketArea?.name}`
              }
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.area")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={
                record?.region &&
                `${record?.region.code}-${record?.region.name}`
              }
            />
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label={translate("customer.miningStaff")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={
                record?.employeeAssigned
                  ?.map((emp) =>
                    emp?.employee?.code && emp?.employee?.fullName
                      ? `${emp?.employee?.code} - ${emp?.employee?.fullName}`
                      : emp?.employee?.code || emp?.employee?.fullName
                  )
                  ?.join(", ") || "-"
              }
            />
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label={translate("customer.billingInformation")}
            labelStyle={{ fontWeight: 700 }}
          >
            <DataText value={"-"}></DataText>
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.unitName")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.nameInBillingInfo} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.taxCode")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.taxCodeInBillingInfo} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.phoneNumber")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.phoneNumberInBillingInfo} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.email")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.emailInBillingInfo} />
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={translate("customer.address")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.addressInBillingInfo} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.updateTime")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={formatISODateTimeToView(record?.updatedDate, DATE_FORMAT)}
            />
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={translate("customer.updater")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.updatedName} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("customer.createdTime")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={formatISODateTimeToView(record?.createdDate, DATE_FORMAT)}
            />
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={translate("customer.creator")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.createdName} />
          </Descriptions.Item>
        </Descriptions>
      </ShowCustom>
      <Modal
        title=""
        visible={visibleModalDelete}
        onOk={onDelete}
        onCancel={toggleModalDelete}
        okText={translate("buttons.confirm")}
        cancelText={translate("buttons.reject")}
        okButtonProps={{
          danger: true,
          loading: isDeleteLoading,
        }}
        cancelButtonProps={{ disabled: isDeleteLoading }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Paragraph style={{ fontSize: 14 }}>
              {translate("customer.confirmDeleteCustomer")}
            </Paragraph>
          </div>
        </Space>
      </Modal>
      <Modal
        title=""
        visible={visibleModalActivate}
        onOk={onActive}
        onCancel={toggleModalActivate}
        okText={translate("buttons.confirm")}
        cancelText={translate("buttons.reject")}
        okButtonProps={{
          loading: isUpdateLoading,
        }}
        cancelButtonProps={{ disabled: isUpdateLoading }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Paragraph style={{ fontSize: 16, fontWeight: 500 }}>
              {translate("customer.turnIntoPotential")}?
            </Paragraph>
            <Paragraph style={{ fontSize: 14 }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: translate(`customer.descConfirmTurnIntoPotential`, {
                    email: 123123,
                  }),
                }}
              ></p>
            </Paragraph>
          </div>
        </Space>
      </Modal>
    </>
  );
};

export default CustomerShow;
