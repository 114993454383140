import {
  Button,
  Descriptions,
  Icons,
  Table,
  notification,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { DataText } from "components";
import { FC, memo, useContext, useMemo } from "react";
import { ContractShowConText } from "../..";
import { formatISODateTimeToView } from "utils/commons";
import type { ColumnsType } from "antd/es/table";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "configs/constants";
import { ContractDocuments } from "interfaces/Contract";
import { getLinkPrewviewFile } from "api";
import { API_PATH } from "configs/path";

const ContractInfo: FC = memo(() => {
  const record = useContext(ContractShowConText);
  const translate = useTranslate();

  const onGetLinkView = (fileUrl: string) => async () => {
    try {
      const res = await getLinkPrewviewFile(
        API_PATH.contractGetLinkPreviewFile,
        fileUrl
      );
      const url: string = res?.data as unknown as string;
      window.open(url, "_blank");
    } catch (error) {
      notification.error({
        message: "Đã có lỗi xảy ra. Vui lòng liên hệ quản trị viên.",
      });
    }
  };

  const columns = useMemo<ColumnsType<ContractDocuments>>(
    () => [
      {
        title: translate("Tài liệu"),
        width: 200,
        dataIndex: "fileUrlOrigin",
        key: "fileUrlOrigin",
        render: (value) => {
          return <DataText value={value || "-"} />;
        },
      },
      {
        title: translate("common.description"),
        width: 200,
        dataIndex: "description",
        key: "description",
        render: (value) => {
          return <DataText value={value || "-"} />;
        },
      },
      {
        title: translate("Ngày tải file"),
        width: 200,
        dataIndex: "uploadDate",
        key: "uploadDate",
        render: (value) => {
          return (
            <DataText
              value={
                value ? formatISODateTimeToView(value, DATE_TIME_FORMAT) : "-"
              }
            />
          );
        },
      },
      {
        title: translate("table.actions"),
        width: 90,
        dataIndex: "fileUrl",
        key: "fileUrl",
        align: "center",
        fixed: "right",
        render: (value, record) => {
          return (
            <Button
              type="text"
              icon={<Icons.EyeOutlined />}
              className="primary"
              onClick={onGetLinkView(value)}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={{ xs: 1, lg: 3 }}
        title={translate("Thông tin hợp đồng")}
        layout="vertical"
      >
        <Descriptions.Item
          label={translate("Ngày ký")}
          labelStyle={{ fontWeight: 500 }}
          span={3}
        >
          <DataText
            value={formatISODateTimeToView(record?.signDate, DATE_FORMAT)}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Ngày hiệu lực")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText
            value={formatISODateTimeToView(record?.validDate, DATE_FORMAT)}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Ngày hết hiệu lực")}
          labelStyle={{ fontWeight: 500 }}
          span={2}
        >
          <DataText
            value={formatISODateTimeToView(record?.expirationDate, DATE_FORMAT)}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Nội dung hợp đồng")}
          labelStyle={{ fontWeight: 500 }}
          span={3}
        >
          <DataText value={record?.description} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Tài liệu đính kèm (Hợp đồng chính thức)")}
          labelStyle={{ fontWeight: 500 }}
          span={2}
        >
          <div />
        </Descriptions.Item>
      </Descriptions>
      <Table
        style={{ padding: "0 16px" }}
        size="small"
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={record?.contractDocuments}
        scroll={{ x: 800 }}
      />
    </div>
  );
});

export default ContractInfo;
