import { Col, Form, Row, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { useSearchParams } from "@pankod/refine-react-router-v6";
import { DatePicker } from "antd";
import { SelectCustom } from "components";
import { DATE_FORMAT } from "configs/constants";
import { API_PATH } from "configs/path";
import dayjs from "dayjs";
import { useFormRule } from "hooks/useFormRule";
import { FC, memo, useContext, useEffect } from "react";
import { IForm, ProjectContext } from "../..";

const { Title } = Typography;
const { useWatch, useFormInstance } = Form;
const ProjectInfo: FC = memo(() => {
  const { required } = useFormRule();
  const { isEdit, initialValues } = useContext(ProjectContext);
  const form = useFormInstance<IForm>();
  const translate = useTranslate();

  const startDate = useWatch("startDate", form);
  const endDate = useWatch("endDate", form);
  const params = useSearchParams();
  useEffect(() => {
    if (!!startDate && !!endDate) {
      form.setFields([
        {
          name: "endDate",
          errors:
            dayjs(startDate).valueOf() <= dayjs(endDate).valueOf()
              ? undefined
              : [
                  translate(
                    "Ngày bắt đầu phải lớn hơn hoặc bằng ngày kết thúc"
                  ),
                ],
        },
      ]);
    }
  }, [startDate, endDate]);

  return (
    <div style={{ backgroundColor: "white", padding: 24 }} className="h-full">
      <Title level={5}>{translate("Thông tin dự án")}</Title>
      <Form.Item rules={required} label={translate("Mã vụ việc")} name="caseId" initialValue={params[0].get("caseId")}>
        <SelectCustom
          resource={API_PATH.projectCaseDropdownlist}
          placeholder={translate("Chọn mã vụ việc")}
          optionLabel="name"
          optionValue="id"
          style={{ width: "100%", color: "#000" }}
          disabled={isEdit}
          label={
            isEdit
              ? `${initialValues?.case?.code} - ${initialValues?.case?.name}`
              : ""
          }
        />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="startDate"
            className={isEdit ? "" : "m-0"}
            rules={required}
            label={translate("Ngày bắt đầu")}
            getValueProps={(value) => ({
              value: value ? dayjs(value).startOf("day") : "",
            })}
          >
            <DatePicker
              className="w-full"
              placeholder="Chọn ngày bắt đầu"
              format={DATE_FORMAT}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="endDate"
            className={isEdit ? "" : "m-0"}
            rules={[
              ...required,
              {
                validator: (_, value) => {
                  if (
                    !!value &&
                    dayjs(value).valueOf() < dayjs(startDate).valueOf()
                  ) {
                    return Promise.reject(
                      new Error(
                        translate(
                          "Ngày bắt đầu phải lớn hơn hoặc bằng ngày kết thúc"
                        )
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label={translate("Ngày kết thúc")}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : "",
            })}
          >
            <DatePicker
              className="w-full"
              placeholder="Chọn ngày kết thúc"
              format={DATE_FORMAT}
            />
          </Form.Item>
        </Col>
      </Row>
      {isEdit && (
        <Form.Item
          rules={required}
          label={translate("Trạng thái")}
          name="status"
          className="m-0"
        >
          <SelectCustom
            resource={API_PATH.projectStatusDrodownlist}
            placeholder={translate("Chọn trạng thái")}
            optionLabel="name"
            optionValue="id"
            style={{ width: "100%", color: "#000" }}
          />
        </Form.Item>
      )}
    </div>
  );
});

export default ProjectInfo;
