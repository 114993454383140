import { useTranslate } from "@pankod/refine-core";
import { Checkbox, Form, Typography } from "antd";
import { CurrencyType } from "api/enums";
import { generateCurrency } from "components/Currency";
import { FC, memo, useContext, useMemo } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import styled from "styled-components";
import CostTable from "./CostTable";
import { IForm, InternalEstimateFormContext } from "../..";
import MandayTable from "./MandayTable";
import TotalEstimate from "./TotalEstimate";
import ModalConfirm from "components/ModalConfirm";
import { cloneDeep } from "lodash";

const { Title, Text } = Typography;
const { useWatch, useFormInstance } = Form;
export const EstimateInfoStyle = styled.div`
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  /* margin-bottom: 0.5em; */

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ControlLeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CTitle = styled(Title)`
  margin-bottom: 0 !important;
  margin-right: 24px;
`;

const EstimateInfo: FC = memo(() => {
  const translate = useTranslate();
  const { onFetchPolicy } = useContext(InternalEstimateFormContext);
  const form = useFormInstance<IForm>();
  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);

  const listInternalEstimateProduct = useWatch(
    "listInternalEstimateProduct",
    form
  );
  const listInternalEstimatedCost = useWatch("listInternalEstimatedCost", form);
  const listInternalEstimatedByManDays = useWatch(
    "listInternalEstimatedByManDays",
    form
  );
  const internalEstimateType = useWatch("internalEstimateType", form);
  const isAllProducts = useWatch("isAllProducts", form);

  const onChangeRequestType =
    (isDone?: boolean) => (e: CheckboxChangeEvent) => {
      const checked = e.target.checked;
      if (internalEstimateType && !isDone) return;
      form.setFieldsValue({ isAllProducts: checked });

      if (!checked) {
        const products = Object.values(listInternalEstimateProduct || {});
        onFetchPolicy(products, internalEstimateType, checked);
      } else {
        const newCost = cloneDeep(listInternalEstimatedCost || {});
        const newManday = cloneDeep(listInternalEstimatedByManDays || {});
        for (const cost in newCost) {
          if (newCost[cost]?.orderSort) {
            newCost[cost].unitPrice = null;
          }
        }
        for (const cost in newManday) {
          if (newManday[cost]?.orderSort) {
            newManday[cost].unitPrice = null;
          }
        }
        form.setFieldsValue({
          listInternalEstimatedCost: newCost,
          listInternalEstimatedByManDays: newManday,
        });
      }
    };

  const onCancelChangeEstimateType = () =>
    form.setFieldsValue({ isAllProducts });

  return (
    <EstimateInfoStyle>
      <ControlContainer>
        <ControlLeftContainer>
          <CTitle level={4}>{translate("Chi tiết dự toán")}</CTitle>
          <Form.Item
            name="isAllProducts"
            className="m-0 flex-1"
            valuePropName="checked"
            initialValue={true}
          >
            <ModalConfirm
              title={translate("Bạn muốn thay đổi phương thức dự toán?")}
              enable
              desc={
                "Lưu ý: Sau khi thay đổi phương thức dự toán, các thông tin ở bảng Chi phí bằng tiền, Chi phí nhân công và Dự toán tổng sẽ bị xóa."
              }
              onCancel={onCancelChangeEstimateType}
              onChange={onChangeRequestType()}
              onDone={onChangeRequestType(true)}
            >
              <Checkbox>{translate("Dự toán cho tất cả sản phẩm")}</Checkbox>
            </ModalConfirm>
          </Form.Item>
        </ControlLeftContainer>
        <Text>
          {translate("common.currency.unit")}:{" "}
          <Text style={{ color: currency.unitColor, fontWeight: "bold" }}>
            {currency.unit}
          </Text>
        </Text>
      </ControlContainer>
      <CostTable />
      <MandayTable />
      <TotalEstimate />
    </EstimateInfoStyle>
  );
});

export default EstimateInfo;
