import { Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { ApprovalServiceRequest } from "components/ApprovalServiceRequest";
import { PATH } from "configs/path";
import { IPrepay } from "interfaces";
import React from "react";
import { Information } from "./components/Information";

interface PrepayShowProps {
  isApprover?: boolean;
}
interface IPrepayProps {
  "objectData": IPrepay;
}
const { Text } = Typography;
export const PrepayShow: React.FC<
  IResourceComponentsProps & PrepayShowProps
> = (props) => {
  const t = useTranslate();

  return (
    <ApprovalServiceRequest 
    cloneResource={true}
    {...props}
      title={(record: any) => (
      <>
        {`${t("prepays.titles.show")}: `}
        <Text className="primary">{`${record?.objectData?.name}`} </Text>
      </>
    )}
      resource={`${PATH.prepays}`}
      onApprovalSuccess={() => {}}
      renderInformation={(record: IPrepayProps) => <Information record={record?.objectData} />}
    />
  );
};
