import { Icons, notification, useForm } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { CreateCustom } from "components/layout";
import { FC, useEffect, useState } from "react";
import FormQuotations from "./components/Form";
import { PATH } from "configs/path";
import { mappingErrorFromApi } from "utils/commons";
import IQuotation from "interfaces/Quotation";

export interface IForm {
  name: string;
  caseId: string;
  businessPlanId: string;
  customerRepresentative: string;
  customerPosition: string;
  pricePolicy: string;
  deliveryTimePolicy: string;
  durationPolicy: string;
  commercialEmployeeId: string;
  commercialEmployeeTitleId: string;
  employeeEmail: string;
  employeePhone: string;
  quotationProducts: {
    [key: string]: {
      description: string;
      note: string;
      partNumber: string;
      productId: string;
      quantity: number;
      taxPercent: number;
      unitId: string;
      unitPrice: number;
    };
  };
}

const QuotationCreate: FC<IResourceComponentsProps> = (props) => {
  const { show } = useNavigation();
  const translate = useTranslate();
  const { form, formProps, saveButtonProps, formLoading } = useForm<
    any,
    any,
    IForm
  >();
  const { mutate: mutateCreate, isLoading } = useCreate<{ id: string }, any>();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();
  const [visibleModalConfirmCreate, setVisibleShowModalConfirm] =
    useState(false);

  const initialValues: Partial<IQuotation> = formProps?.initialValues || {};

  const isEdit = !!initialValues?.id;

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleShowModalConfirm(isOpen);

  const onSubmit = () => {
    const formData = form.getFieldsValue();

    const quotationProducts = Object.values(formData?.quotationProducts).map(
      (product) => ({
        ...product,
        taxPercent: product?.taxPercent ? product.taxPercent / 100 : 0,
      })
    );

    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: translate(
            isEdit ? "Cập nhật thành công" : "Tạo mới thành công"
          ),
        });
        show(PATH.quotations, res?.data?.id);
      },
      onError: (error: any) => {
        toggleModalConfirm(false);
        notification.error({
          message: translate(isEdit ? "Cập nhật thất bại" : "Tạo mới thất bại"),
          description:
            !!error?.errors?.length &&
            translate(
              `errors.${error?.errors?.[0]?.errorCode}`,
              error?.errors?.[0]?.value
            ),
        });
        const errorFieldMapping: any = {};

        mappingErrorFromApi(error, form, errorFieldMapping);
      },
    };

    const dataRequest = {
      resource: PATH.quotations,
      values: {
        ...formData,
        name: formData?.name?.trim(),
        pricePolicy: formData?.pricePolicy?.trim(),
        durationPolicy: formData?.durationPolicy?.trim(),
        deliveryTimePolicy: formData?.deliveryTimePolicy?.trim(),
        quotationProducts,
      },
      id: initialValues?.id!,
    };

    if (isEdit) {
      mutateUpdate(dataRequest, handleResponse);
    } else {
      mutateCreate(dataRequest, handleResponse);
    }
  };

  return (
    <CreateCustom
      {...props}
      title={translate(isEdit ? "Chỉnh sửa báo giá" : "Tạo báo giá")}
      onSubmit={onSubmit}
      visibleShowModal={visibleModalConfirmCreate}
      setVisibleShowModal={toggleModalConfirm}
      saving={isLoading || isLoadingUpdate}
      confirmModalProps={{
        title: translate(
          isEdit
            ? "assignExploiting.editConfirm"
            : "assignExploiting.createConfirm"
        ),
      }}
      saveButtonProps={{
        ...saveButtonProps,
        title: translate(isEdit ? "common.saveEdit" : "actions.create"),
        icon: isEdit && <Icons.SaveOutlined />,
      }}
      isLoading={formLoading}
      contentStyles={{ background: "transparent", padding: 0 }}
      bodyStyle={{ padding: 0 }}
    >
      <FormQuotations
        form={form}
        formProps={formProps}
        onFinishedFormValidate={() => toggleModalConfirm(true)}
        isEdit={isEdit}
      />
    </CreateCustom>
  );
};

export default QuotationCreate;
