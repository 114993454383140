import { Space, Typography } from "@pankod/refine-antd";
import { CurrencyType } from "api/enums";
import { CSSProperties, ReactNode } from "react";
import { isEmpty, roundToHundreds } from "utils/commons";

const { Text } = Typography;
interface CurrencyProps {
  value: number | undefined;
  currencyType?: CurrencyType | undefined;
  customStyles?: CSSProperties;
  strong?: boolean;
  showCurrency?: boolean;
  hidden?: boolean;
  customValueShow?: (value: number) => string;
  className?: string;
  after?: ReactNode;
  decimalLength?: number;
}

export const generateCurrency = (
  currencyType: CurrencyType = CurrencyType.VND
) => {
  let locateCode = "en-US";
  let unit = "EUR";
  let unitColor = "#597EF7";
  let text = "vnd";
  switch (currencyType) {
    case CurrencyType.EUR:
      locateCode = "en-US";
      unit = "EUR";
      unitColor = "#597EF7";
      text = "currency.euro";
      break;
    case CurrencyType.USD:
      locateCode = "en-US";
      unit = "USD";
      unitColor = "#52C41A";
      text = "currency.usd";
      break;
    case CurrencyType.VND:
      locateCode = "vi-VN";
      unit = "VND";
      unitColor = "#FFA940";
      text = "currency.vnd";
      break;
    case CurrencyType.SGD:
      locateCode = "en-US";
      unit = "SGD";
      unitColor = "#13C2C2";
      text = "currency.sgd";
      break;
  }

  return { locateCode, unit, unitColor, text };
};

export const Currency = ({
  value,
  currencyType = CurrencyType.VND,
  customStyles,
  strong = false,
  showCurrency = true,
  hidden,
  customValueShow,
  after,
  decimalLength = 3,
  ...props
}: CurrencyProps) => {
  const { locateCode, unit, unitColor } = generateCurrency(currencyType);

  const formatedMoneyStr = new Intl.NumberFormat("en-US").format(
    roundToHundreds(value || 0, decimalLength)
  );

  const styles: CSSProperties = {
    ...customStyles,
    fontWeight: strong ? "bold" : "normal",
    
  };
  if (isEmpty(value)) return <span>-</span>;

  const getColor = (value: number) => {
    if (value > 0) return "#52c41a";
    if (value < 0) return "red";
    return "black";
  }
  
  return (
    <Space className={hidden ? "hidden" : ""}>
      <Text {...props} style={{...styles, color: getColor(value ?? 0)}}>
        {customValueShow ? customValueShow(value || 0) : formatedMoneyStr}
        {after}
      </Text>
      {showCurrency && !isEmpty(value) && (
        <Text
          style={{
            color: unitColor,
            ...styles,
          }}
        >
          {unit}
        </Text>
      )}
    </Space>
  );
};
