import {
  Button,
  ButtonProps,
  Col,
  Divider,
  Form,
  FormInstance,
  FormProps,
  Icons,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
  useSelect,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import {
  CurrencyType,
  DisplayType,
  InputType,
  LogicalOperator,
} from "api/enums";
import ButtonConfirm from "components/ButtonConfirm";
import { MoneyInput } from "components/MoneyInput";
import { SelectCustom } from "components/SelectCustom";
import { dataDecisionDefault } from "components/WorkflowDiagram/constants";
import {
  classNameSelect,
  getOperator,
  nextStepOptions,
} from "components/WorkflowDiagram/diagramHelper";
import { API_PATH } from "configs/path";
import {
  IApprovalStepConditionItemForApprovalStep,
  IWorkflowNode,
  IWorkflowNodeData,
} from "interfaces";
import { cloneDeep } from "lodash";
import { WorkflowContext } from "pages/workflowDiagrams";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { roundToHundreds, searchSelect } from "utils/commons";
import { v4 as uuid } from "uuid";

interface Props {
  step?: IWorkflowNode;
  otherSteps?: IWorkflowNodeData[];
  onChange: (e: IWorkflowNode) => void;
  onFormChange: () => void;
  form: FormInstance;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
}

const { useWatch } = Form;

const { Title, Text } = Typography;

const DecisionDataNode: React.FC<Props> = ({
  step,
  otherSteps,
  form,
  formProps,
  saveButtonProps,
  onChange,
  onFormChange,
}) => {
  const { feature } = useContext(WorkflowContext);

  const t = useTranslate();

  const defaultYesStep = useWatch("defaultYesStep", form);
  const defaultNoStep = useWatch("defaultNoStep", form);
  const conditions: {
    [key: string]: IApprovalStepConditionItemForApprovalStep;
  } = useWatch("conditions", form);
  const [conditionIds, setConditionIds] = useState<string[]>([uuid()]);

  const {
    selectProps: objectSelectProps,
    queryResult: { isFetching: isLoadingObject, data: objectData },
  } = useSelect({
    resource: API_PATH.approvalFetureConditionAttr,
    optionValue: "id",
    optionLabel: "name",
    metaData: {
      subFilter: `?featureId=${feature?.featureId}`,
    },
  });
  const listObject = useMemo(() => objectData?.data || [], [objectData]);

  React.useEffect(() => {
    form.resetFields();
    if (!isLoadingObject) {
      const {
        approvalStepConditionItemForApprovalStep = [],
        logical = LogicalOperator.AND,
      } =
        step?.approvalStepConditionForApprovalStep ||
        dataDecisionDefault.approvalStepConditionForApprovalStep;
      const ids: string[] = [];

      const transformConditions: {
        [key: string]: IApprovalStepConditionItemForApprovalStep;
      } = {};
      approvalStepConditionItemForApprovalStep?.forEach((condition) => {
        const approvalFeatureConditionAttr = listObject?.find(
          (o) => o.id === condition.approvalFeatureConditionAttrId
        );
        const id = uuid();

        ids.push(id);
        transformConditions[id] = {
          ...condition,
          approvalFeatureConditionAttrOptions:
            approvalFeatureConditionAttr?.approvalFeatureConditionAttrOptions,
          inputType: approvalFeatureConditionAttr?.inputType,
          dataType: approvalFeatureConditionAttr?.dataType,
          displayType: approvalFeatureConditionAttr?.displayType,
          compareValue:
            approvalFeatureConditionAttr?.displayType === DisplayType.Percent
              ? roundToHundreds((condition?.compareValue || 0) * 1e2)
              : condition.compareValue,
        };
      });
      if (ids.length) setConditionIds(ids);

      setTimeout(
        () =>
          form.setFieldsValue({
            ...step,
            logical: logical,
            conditions: transformConditions,
          }),
        0
      );
    }
  }, [step, isLoadingObject]);

  const emitOnChangeEvent = () => {
    const formValue = form.getFieldsValue();

    const transformData = (
      condition: IApprovalStepConditionItemForApprovalStep
    ): IApprovalStepConditionItemForApprovalStep => {
      let { compareValue, displayType } = condition;

      switch (displayType) {
        case DisplayType.Percent:
          compareValue = (compareValue / 1e2).toString();
          break;
      }

      return { ...condition, compareValue };
    };

    const updatedStep: IWorkflowNode = {
      ...step,
      ...formValue,
      approvalStepConditionForApprovalStep: {
        approvalStepConditionItemForApprovalStep:
          Object.values(conditions).map(transformData),
        logical: formValue?.logical || LogicalOperator.AND,
      },
    };

    onChange(updatedStep);
  };

  const objectSelected = useMemo(
    () =>
      conditions
        ? Object.values(conditions).map(
            (condition: any) => condition.approvalFeatureConditionAttrId
          )
        : [],
    [conditions]
  );

  const objectOptions = useCallback(
    (_: any, value?: string) => {
      return (
        listObject.map((o: any) => ({
          ...o,
          label: o.name,
          value: o.id,
        })) || []
      );
    },
    [listObject]
  );

  const isShowButtonRemoveItem = useMemo(
    () => conditionIds.length > 1,
    [conditionIds]
  );

  const onRemoveObject = (index: number) => () => {
    const newList = cloneDeep(conditionIds);
    newList.splice(index, 1);
    setConditionIds(newList);
  };

  const onChangeItem =
    (keyRow: string) => (value: any, selectedItem: any, _: any) => {
      const newList: any = cloneDeep(conditions);

      newList[keyRow] = {
        ...newList[keyRow],
        approvalFeatureConditionAttrId: value,
        dataType: selectedItem?.dataType,
        displayType: selectedItem?.displayType,
        compareOperator: null,
        inputType: selectedItem?.inputType,
        compareValue:
          selectedItem?.inputType === InputType.Selection ? null : "",
        ...(selectedItem?.inputType === InputType.Selection
          ? {
              approvalFeatureConditionAttrOptions:
                selectedItem?.approvalFeatureConditionAttrOptions,
            }
          : {}),
      };

      form.setFieldsValue({ conditions: newList });
      setConditionIds(cloneDeep(conditionIds));
    };

  const renderInputComponent = (
    row: IApprovalStepConditionItemForApprovalStep
  ) => {
    const {
      approvalFeatureConditionAttrOptions = [],
      inputType = InputType.InputBox,
      displayType,
    } = row;

    const options = approvalFeatureConditionAttrOptions?.map((op) => ({
      ...op,
      label: op?.name,
    }));

    switch (inputType) {
      case InputType.InputBox:
        switch (displayType) {
          case DisplayType.Number:
            return (
              <MoneyInput
                currencyType={CurrencyType.VND}
                placeholder={t("Nhập giá trị")}
              />
            );
          case DisplayType.Decimal:
            return (
              <MoneyInput
                currencyType={CurrencyType.VND}
                placeholder={t("Nhập giá trị")}
                allowDecimal
                decimalLength={2}
              />
            );
          case DisplayType.Percent:
            return (
              <MoneyInput
                currencyType={CurrencyType.VND}
                allowDecimal
                suffix="%"
                placeholder={t("Nhập giá trị")}
              />
            );

          case DisplayType.String:
            return <Input />;

          default:
            return null;
        }
      case InputType.Selection:
        return (
          <Select
            showSearch
            options={options}
            placeholder={t("Chọn giá trị")}
            filterOption={(inputValue: string, option: any) => {
              return option && searchSelect(inputValue, option?.label);
            }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Form
        {...formProps}
        initialValues={{ ...step }}
        onFinish={emitOnChangeEvent}
        onFinishFailed={() => {}}
        layout="vertical"
        labelCol={{ span: 10 }}
        style={{ height: "100%" }}
        onChange={onFormChange}
      >
        <Row wrap={false} style={{ flexDirection: "column", height: "100%" }}>
          <Col flex="auto">
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t("workflows.fields.stepName")}
                  name="name"
                  style={{ maxWidth: 500 }}
                  rules={[
                    {
                      required: true,
                      message: t("errors.ER056"),
                    },
                    {
                      max: 300,
                      message: t("errors.ER014", { max: "300" }),
                    },
                    {
                      validator: async (_, value) => {
                        if (
                          value &&
                          otherSteps?.findIndex(
                            (t) =>
                              t.data?.name?.toLowerCase() ===
                              value.trim().toLowerCase()
                          ) !== -1
                        ) {
                          return Promise.reject(t("errors.ER051"));
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder={t("workflows.fields.stepNamePlaceHolder")}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  hidden={!!step?.name}
                  label={t("Loại")}
                  name="type"
                  style={{ maxWidth: 500 }}
                  rules={[
                    {
                      required: true,
                      message: t("errors.ER056"),
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "Normal", value: 1 },
                      {
                        label: "Gateway - Decision",
                        value: 3,
                      },
                    ]}
                    onChange={(value) =>
                      onChange({
                        ...step,
                        type: value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Title level={5}>Điều kiện chuyển bước</Title>
            <Divider style={{ margin: "8px 0 16px" }} />
            <div
              className="flex items-center justify-between"
              style={{ marginBottom: 16 }}
            >
              <div style={{ gap: 24 }} className="flex items-center">
                <span>Điều kiện kết hợp</span>
                <Form.Item style={{ marginBottom: 0 }} name="logical">
                  <Radio.Group defaultValue={LogicalOperator.AND}>
                    <Radio value={LogicalOperator.AND}>AND</Radio>
                    <Radio value={LogicalOperator.OR}>OR</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <Button
                type="primary"
                onClick={() => {
                  setConditionIds([...conditionIds, uuid()]);
                }}
                icon={<Icons.PlusOutlined />}
              >
                {t("Thêm đối tượng")}
              </Button>
            </div>
            <Table<String>
              size="small"
              className="vertical-align-top"
              dataSource={conditionIds}
              pagination={{
                hideOnSinglePage: true,
                pageSize: 1000,
              }}
              locale={{
                emptyText: " ",
              }}
            >
              <Table.Column
                title={"Đối tượng"}
                dataIndex="approvalFeatureConditionAttrId"
                width={"35%"}
                render={(v, keyRow: string) => {
                  return (
                    <>
                      <Form.Item
                        hidden
                        name={["conditions", keyRow, "dataType"]}
                      />
                      <Form.Item
                        hidden
                        name={["conditions", keyRow, "displayType"]}
                      />
                      <Form.Item
                        hidden
                        name={["conditions", keyRow, "inputType"]}
                      />
                      <Form.Item
                        hidden
                        name={[
                          "conditions",
                          keyRow,
                          "approvalFeatureConditionAttrOptions",
                        ]}
                      />
                      <Form.Item
                        className="m-0"
                        name={[
                          "conditions",
                          keyRow,
                          "approvalFeatureConditionAttrId",
                        ]}
                      >
                        <SelectCustom
                          resource={API_PATH.approvalFetureConditionAttr}
                          activeStatusOptions={objectSelectProps.options}
                          subFilter={`?featureId=${feature?.featureId}`}
                          useServerFiltering={false}
                          allowClear
                          customOptions={objectOptions}
                          onChangeOption={onChangeItem(keyRow)}
                          isLoading={isLoadingObject}
                          placeholder={t("Chọn đối tượng")}
                        />
                      </Form.Item>
                    </>
                  );
                }}
              />

              <Table.Column
                title={"Toán tử"}
                dataIndex="compareOperator"
                render={(v, keyRow: string) => (
                  <Form.Item
                    name={["conditions", keyRow, "compareOperator"]}
                    className="m-0"
                  >
                    <Select
                      options={getOperator(conditions?.[keyRow]?.dataType)}
                      placeholder={t("Chọn toán tử")}
                    />
                  </Form.Item>
                )}
              />

              <Table.Column
                title={`Giá trị`}
                width={"35%"}
                dataIndex="compareValue"
                render={(v, keyRow: string) => {
                  if (conditions?.[keyRow]?.approvalFeatureConditionAttrId) {
                    return (
                      <Form.Item
                        name={["conditions", keyRow, "compareValue"]}
                        className="m-0"
                      >
                        {renderInputComponent(conditions[keyRow])}
                      </Form.Item>
                    );
                  }
                }}
              />

              <Table.Column
                align="center"
                width="120px"
                title={t("table.actions")}
                render={(v, record, index) => {
                  if (!isShowButtonRemoveItem) return null;
                  return (
                    <ButtonConfirm
                      text={t("payments.costs.deleteConfirmText")}
                      description=""
                      onClick={onRemoveObject(index)}
                      type="text"
                    >
                      <Icons.DeleteOutlined />
                    </ButtonConfirm>
                  );
                }}
              />
            </Table>
          </Col>

          <Space style={{ marginTop: 24 }} align="baseline">
            <Text>{t("Bước đến khi thỏa mãn điều kiện")}</Text>
            <Form.Item
              name="defaultYesStep"
              shouldUpdate={(prevValues, curValues) =>
                prevValues.age !== curValues.age
              }
            >
              <Select
                className={classNameSelect(defaultYesStep?.type)}
                style={{ width: "fit-content" }}
                dropdownMatchSelectWidth={false}
                placeholder={t("workflows.selectStep")}
                options={nextStepOptions(otherSteps ?? [])}
                onChange={(_, option: any) => {
                  form.setFieldsValue({
                    defaultYesStep: option,
                    defaultNoStep:
                      option.value === defaultNoStep?.value
                        ? null
                        : defaultNoStep,
                  });
                  onFormChange();
                }}
              />
            </Form.Item>
          </Space>

          <Space align="baseline">
            <Text>{t("Bước đến khi không thỏa mãn điều kiện")}</Text>
            <Form.Item
              name="defaultNoStep"
              shouldUpdate={(prevValues, curValues) =>
                prevValues.age !== curValues.age
              }
            >
              <Select
                className={classNameSelect(defaultNoStep?.type)}
                style={{ width: "fit-content" }}
                dropdownMatchSelectWidth={false}
                placeholder={t("workflows.selectStep")}
                options={nextStepOptions(otherSteps ?? [])}
                onChange={(_, option: any) => {
                  form.setFieldsValue({
                    defaultNoStep: option,
                    defaultYesStep:
                      option.value === defaultYesStep?.value
                        ? null
                        : defaultYesStep,
                  });
                  onFormChange();
                }}
              />
            </Form.Item>
          </Space>

          <Col flex="80px" style={{ textAlign: "right" }}>
            <Divider type="horizontal" />
            <Button {...saveButtonProps} type="primary">
              {t("buttons.update")}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default DecisionDataNode;
