import { ApprovalStatus } from "api/enums";
import {
  ApprovalApproverDetail,
  IProductResponse,
  ObjectSubmitCondition,
  SubmitEmployee,
  WaitingProcessEmployees,
} from "interfaces";

export default interface IContractEstimate {
  objectData: ContractEstimateData;
  approvalData: ApprovalApproverDetail;
}

export interface ContractEstimateData {
  code: string;
  note: string;
  case: {
    code: string;
    id: string;
    name: string;
  };
  contractEstimatedDocuments: ContractEstimatedDocuments[];
  contractEstimatedProducts: IProductResponse[];
  createdDate: string;
  createdName: string;
  createdUser: string;
  financialIndicator: TPlanItem[];
  id: string;
  status: ApprovalStatus;
  statusDetail: {
    code: ApprovalStatus;
    displayName: string;
    name: string;
  };
  totalCost: number;
  totalMargin: number;
  totalPrice: number;
  updatedDate: string;
  updatedName: string;
  updatedUser: string;
  objectSubmitConditions: ObjectSubmitCondition[];
}

export type TPlanItem = {
  businessPlanValue: number;
  code: string;
  isPersonInput: boolean;
  isRequired: boolean;
  isStrong: boolean;
  margin: number;
  name: string;
  note: string;
  percentage: number;
  value: number;
  errorMessage?: string;
  errorCode?: string;
};

export type ContractEstimatedDocuments = {
  fileId: string;
  fileUrl: string;
  fileUrlOrigin: string;
  fileUrlView: string;
};

export enum TabKey {
  INFO = "info",
  ESTIMATE_INFO = "estimate-info",
  HISTORY = "history",
}

export interface ContractEstimateTableRow {
  status: ApprovalStatus;
  statusDetail: {
    code: ApprovalStatus;
    name: string;
    displayName: string;
  };
  submitTimes: number;
  currentApprovalStepId: string;
  submitEmployeeId: string;
  code: string;
  caseId: string;
  case: {
    code: string;
    id: string;
    name: string;
  };
  originalContractEstimatedId: string;
  originalContractEstimated: {
    code: string;
    id: string;
    name: string;
  };
  ownerByDepartmentId: string;
  ownerByDepartment: {
    id: string;
    name: string;
  };
  ownerByEmployeeId: string;
  ownerByEmployee: {
    code: string;
    id: string;
    name: string;
  };
  createdUserDetail: {
    code: string;
    id: string;
    name: string;
  };
  updatedUserDetail: {
    code: string;
    id: string;
    name: string;
  };
  createdUser: string;
  createdDate: string;
  createdName: string;
  updatedUser: string;
  updatedDate: string;
  updatedName: string;
  id: string;
}

export interface ContractEstimateTableData {
  objectData: ContractEstimateTableRow;
  submitEmployee: SubmitEmployee;
  waitingProcessEmployees: WaitingProcessEmployees;
}
