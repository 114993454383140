export interface MarketArea {
  code: string;
  id: string;
  name: string;
  status: boolean;
}

export enum MarketStatus {
  DEACTIVE,
  ACTIVE,
  ALL,
}
