import React, { CSSProperties, useEffect, useState } from "react";
import {
  useTranslate,
  useNavigation,
  useRouterContext,
} from "@pankod/refine-core";
import { RefineCrudCreateProps } from "@pankod/refine-ui-types";
import {
  Create,
  Modal,
  Typography,
  Button,
  Icons,
  Space,
  ButtonProps,
  Spin,
} from "@pankod/refine-antd";
import "./styles.less";
import { IHeaderTab } from "interfaces";
import { HeaderTab } from "../headerTab";
import { HeaderStyle } from "../show";

const { Text, Paragraph } = Typography;

type Props = {
  saveButtonProps?: ButtonProps;
  draftButtonProps?: ButtonProps;
  visibleShowModal?: boolean;
  saving?: boolean;
  setVisibleShowModal?: (isShow: boolean) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  onDraft?: () => void;
  onClickDraftButton?: () => void;
  validateDraft?: () => Promise<boolean>;
  confirmModalProps?: {
    title?: string;
    description?: React.ReactNode | string;
    okButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
  };
  cancelModalProps?: {
    title?: string;
    description?: React.ReactNode | string;
    okButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
  };
  draftModalProps?: {
    title?: string;
  };
  name?: string;
  spinning?: boolean;
  contentStyles?: CSSProperties;
  bodyStyle?: CSSProperties;
  tabs?: IHeaderTab[];
  onChangeTab?: any;
};

export const MESSAGE_CONFIRM = "common.leavePage";

export const CreateCustom: React.FC<RefineCrudCreateProps & Props> = (
  props
) => {
  const t = useTranslate();
  const { goBack, list } = useNavigation();
  const [isCancel, setCancel] = useState(false);
  const [isDraft, setDraft] = useState(false);
  const [isShowConfirm, setShowConfirm] = useState(true);

  const {
    children,
    saveButtonProps,
    draftButtonProps,
    visibleShowModal,
    setVisibleShowModal,
    onSubmit,
    onCancel,
    onDraft,
    saving,
    isLoading,
    validateDraft,
    draftModalProps,
    cancelModalProps = {
      okButtonProps: { danger: true },
    },
    confirmModalProps,
    spinning,
    contentStyles,
    bodyStyle,
    title,
    onClickDraftButton,
    tabs,
    onChangeTab,
  } = props;

  useEffect(() => {
    if (saving === false) {
      setDraft(false);
      setCancel(false);
    }
  }, [saving]);

  React.useEffect(() => {
    window.onbeforeunload = function () {
      return t(MESSAGE_CONFIRM);
    };

    window.history.pushState(null, document.title, window.location.href);

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const { Prompt } = useRouterContext();

  let customTitle = title;
  const hasTabs = tabs && tabs?.length > 0;

  if (hasTabs) {
    customTitle = (
      <>
        <HeaderStyle direction="vertical">
          {title}
          <HeaderTab
            tabs={tabs}
            onChangeTab={onChangeTab}
            isSetParamsOnUrl={false}
          />
        </HeaderStyle>
      </>
    );
  }

  return (
    <>
      <Create
        {...props}
        goBack={false}
        title={customTitle}
        breadcrumb={false}
        headerProps={{
          className: `${hasTabs ? "custom-tab-header" : ""} ant-sticky`,
          ...props.headerProps,
        }}
        headerButtons={
          <>
            <Button
              onClick={() => {
                setCancel(true);
                setShowConfirm(false);
              }}
            >
              {t("buttons.cancel")}
            </Button>
            {onDraft && (
              <Button
                {...draftButtonProps}
                onClick={async () => {
                  if (onClickDraftButton) onClickDraftButton();
                  let isValid = true;
                  if (validateDraft) {
                    const validateResult = await validateDraft();
                    isValid = validateResult;
                  }
                  setDraft(isValid);
                }}
              >
                {draftButtonProps?.title || t("buttons.draft")}
              </Button>
            )}
            <Button {...saveButtonProps} type="primary">
              {saveButtonProps?.title || t("buttons.add")}
            </Button>
          </>
        }
        footerButtons={<></>}
        contentProps={{
          style: {
            ...contentStyles,
          },
          bodyStyle: {
            ...bodyStyle,
          },
        }}
      >
        {children}
      </Create>
      <Modal
        title=""
        visible={visibleShowModal}
        onOk={() => {
          setShowConfirm(false);
          onSubmit && onSubmit();
        }}
        closable={!saving}
        onCancel={() => {
          if (saving) return;
          setShowConfirm(true);
          setVisibleShowModal && setVisibleShowModal(false);
        }}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        okButtonProps={{
          loading: isLoading || saving || false,
          disabled: saving,
        }}
        cancelButtonProps={{
          // loading: isLoading || saving || false,
          disabled: saving,
        }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Text style={{ fontWeight: 500, fontSize: 16 }}>
              {confirmModalProps?.title || t("common.createConfirm")}
            </Text>
            {!!confirmModalProps?.description && (
              <Paragraph style={{ fontSize: 14 }}>
                {confirmModalProps?.description}
              </Paragraph>
            )}
          </div>
        </Space>
      </Modal>

      <Modal
        title=""
        visible={isDraft}
        closable={!saving}
        onOk={() => {
          setShowConfirm(false);
          onDraft && onDraft();
        }}
        onCancel={() => {
          if (saving) return;
          setShowConfirm(true);
          setDraft(false);
        }}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        cancelButtonProps={{ disabled: saving, loading: saving }}
        okButtonProps={{ disabled: saving, loading: saving }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22, marginRight: 10 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <Text style={{ fontWeight: 500, fontSize: 16 }}>
            {draftModalProps?.title || t("common.draftConfirm")}
          </Text>
        </Space>
      </Modal>

      <Modal
        title=""
        closable={!saving}
        visible={isCancel}
        onOk={() => {
          if (onCancel) {
            onCancel();
          } else {
            props ? list(props.resource || props.name || "") : goBack();
          }
        }}
        onCancel={() => {
          setShowConfirm(true);
          setCancel(false);
        }}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        okButtonProps={cancelModalProps?.okButtonProps || { danger: true }}
        cancelButtonProps={cancelModalProps?.cancelButtonProps}
      >
        <Space align="start">
          <Text style={{ fontSize: 22, marginRight: 10 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Text style={{ fontWeight: 500, fontSize: 16 }}>
              {cancelModalProps?.title || t("common.cancelConfirm")}
            </Text>
            <Paragraph>
              {cancelModalProps?.description ||
                t("common.cancelConfirmDescription")}
            </Paragraph>
          </div>
        </Space>
      </Modal>

      <Prompt message={t(MESSAGE_CONFIRM)} when={isShowConfirm} />
    </>
  );
};
