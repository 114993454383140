import {
  Button,
  Form,
  Icons,
  List,
  Select,
  Table,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { EmptyData, SearchBox } from "components";
import CTableColumn from "components/CTableColumn";
import { PATH } from "configs/path";
import CaseId from "interfaces/CaseId";
import { formatISODateTimeToView, searchSelect } from "utils/commons";
import { useController } from "./controller";
import { IntegrationStatusTag } from "components/StatusTag/IntegrationStatusTag";

const Column = CTableColumn<CaseId>();

const CaseIdList = () => {
  const {
    translate,
    formProps,
    marketProps,
    customerSelectProps,
    form,
    show,
    table: {
      tableQueryResult: { data: dataTable, isLoading },
      sorter,
      pageSize,
      current,
      filters,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
  } = useController();

  return (
    <List title={translate("caseId.listCaseId")} breadcrumb={null}>
      <div className="list-content">
        <Form {...formProps}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 8,
            }}
          >
            <SearchBox
              placeholder={translate("caseId.search")}
              onSearch={(e) => {
                setFilters([
                  {
                    field: "q",
                    operator: "eq",
                    value: e,
                  },
                ]);
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 8,
              }}
            >
              <Form.Item name={["customerId"]} style={{ marginBottom: 16 }}>
                <Select
                  {...customerSelectProps}
                  allowClear
                  onChange={(e) => {
                    setFilters([
                      {
                        field: "customerId",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                  className={"placeholderBlack"}
                  dropdownStyle={{ color: "black" }}
                  style={{ width: 150 }}
                  bordered={false}
                  placeholder={translate("customer.customer")}
                  filterOption={(inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label);
                  }}
                />
              </Form.Item>
              <Form.Item name={["marketArea"]} style={{ marginBottom: 16 }}>
                <Select
                  {...marketProps}
                  allowClear
                  onChange={(e) => {
                    setFilters([
                      {
                        field: "marketArea",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                  className={"placeholderBlack"}
                  dropdownStyle={{ color: "black" }}
                  style={{ width: 150 }}
                  bordered={false}
                  placeholder={translate("customer.market")}
                  filterOption={(inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label);
                  }}
                />
              </Form.Item>
            </div>

            {filters &&
              filters.length > 0 &&
              !(
                filters.length === 1 && filters[0]?.operator === "containss"
              ) && (
                <Button
                  type="text"
                  onClick={(e) => {
                    form.resetFields();
                    setFilters([], "replace");
                  }}
                  style={{ color: "#8C8C8C", marginBottom: 16 }}
                >
                  {translate("common.cleanFilter")}
                  <Icons.CloseOutlined />
                </Button>
              )}
          </div>
        </Form>
        <Table
          loading={isLoading}
          dataSource={dataTable?.data}
          rowKey="id"
          locale={{
            emptyText: <EmptyData text={translate("common.noDataSearch")} />,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                show(PATH.caseId, record.id);
              },
            };
          }}
          pagination={{
            pageSize: pageSize,
            current: current,
            total: dataTable?.total,
            onChange: (cur, size) => {
              setCurrent(cur);
              setPageSize(size);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            },
            locale: {
              items_per_page: translate("common.pageNumber"),
            },
            showTotal: (total) => (
              <p>{translate("common.totalPage", { total: total })}</p>
            ),
          }}
          onChange={(_, __, s) => {
            const newSort = s as any;
            setSorter([
              {
                field:
                  newSort?.field === "department"
                    ? "department.name"
                    : newSort?.field === "title"
                    ? "title.name"
                    : newSort?.field,
                order: newSort?.order,
              },
            ]);
          }}
          scroll={{ x: "2000px" }}
          showSorterTooltip={false}
        >
          <Column
            key="code"
            dataIndex="code"
            title={translate("caseId.caseId")}
            render={(value, record) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("code", sorter)}
            sorter
          />
          <Column
            key="customer"
            dataIndex="customer"
            title={translate("customer.customerName")}
            render={(value, record) => (
              <TextField value={record?.customer?.name} />
            )}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
          />
          <Column
            key="status"
            dataIndex="status"
            title={translate("customer.status")}
            render={(value, record) => (
              <TextField value={record?.status?.name} />
            )}
            defaultSortOrder={getDefaultSortOrder("status.name", sorter)}
            sorter
          />
          <Column
            key="ownerDepartment"
            dataIndex="ownerDepartment"
            title={translate("caseId.department")}
            render={(value, record) => (
              <TextField value={record?.ownerDepartment?.name} />
            )}
            defaultSortOrder={getDefaultSortOrder("ownerDepartment", sorter)}
            sorter
          />

          <Column
            key="marketArea"
            dataIndex="marketArea"
            title={translate("customer.market")}
            render={(businessIndustry, record) => {
              const { code = "", name = "" } = record?.marketArea || {};
              return <TextField value={!!code && `${code}-${name}`} />;
            }}
            sorter
          />

          <Column
            key="ownerEmployee"
            dataIndex="ownerEmployee"
            title={translate("caseId.businessStaff")}
            render={(value, record) => {
              const { code = "", name = "" } = record?.ownerEmployee || {};
              return <TextField value={!!code && `${code} - ${name}`} />;
            }}
            defaultSortOrder={getDefaultSortOrder("ownerEmployee", sorter)}
            sorter
          />

          <Column
            key="syncStatus"
            dataIndex="syncStatus"
            title={translate("integration.syncStatus")}
            render={(_, record) => {
              return <IntegrationStatusTag status={record?.syncStatus} />;
            }}
          />

          <Column
            key="createdName"
            dataIndex="createdName"
            title={translate("common.createdName")}
            render={(value, record) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
            sorter
          />

          <Column
            key="createdDate"
            dataIndex="createdDate"
            title={translate("customer.createdTime")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(
                  value as string,
                  "DD/MM/YYYY HH:mm:ss"
                )}
              />
            )}
            defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
            sorter
          />
        </Table>
      </div>
    </List>
  );
};

export default CaseIdList;
