import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import detector from "i18next-browser-languagedetector";
import dayjs from "dayjs";

const getDefaultLanguage = () => {
  const storedLanguage = localStorage.getItem("i18nextLng");
  return storedLanguage || "vi";
};

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["vi", "en", "key"],
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?version=${dayjs().valueOf()}`,
    },
    defaultNS: "common",
    fallbackLng: ["vi", "en"],
    lng: getDefaultLanguage(),
  });

export default i18n;
