import React, { CSSProperties } from "react";
import { useTranslate, useNavigation } from "@pankod/refine-core";
import { RefineCrudShowProps } from "@pankod/refine-ui-types";
import { Icons, Show, Space, Typography } from "@pankod/refine-antd";
import { IHeaderTab } from "interfaces";
import { HeaderTab } from "../headerTab";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.less";
import styled from "styled-components";

const { Text } = Typography;
const REQUEST_TAB = "request";

type Props = {
  name?: string;
  breadcrumbText?: string;
  tabs?: IHeaderTab[];
  onChangeTab?: any;
  contentStyles?: CSSProperties;
  bodyStyle?: CSSProperties;
  tabKey?: string;
  outsideParams?: any;
};

const TestStyle = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;

export const HeaderStyle = styled(Space)`
  width: 100%;
  white-space: initial;
`;

export const ShowCustom: React.FC<RefineCrudShowProps & Props> = (props) => {
  const t = useTranslate();
  const {
    children,
    name,
    breadcrumbText,
    title,
    tabs,
    onChangeTab,
    contentStyles,
    bodyStyle,
    tabKey,
    outsideParams,
  } = props;
  const { listUrl } = useNavigation();

  const navigate = useNavigate();
  const location = useLocation();
  const resource = name || "";
  const hasTabs = tabs && tabs?.length > 0;
  const activeTab = location?.pathname?.startsWith(`/${resource}/approval/`)
    ? REQUEST_TAB
    : "";

  let customTitle = title;
  if (hasTabs) {
    customTitle = (
      <>
        <HeaderStyle direction="vertical">
          {title}
          <HeaderTab
            tabs={tabs}
            onChangeTab={onChangeTab}
            tabKey={tabKey}
            outsideParams={outsideParams}
          />
        </HeaderStyle>
      </>
    );
  }

  return (
    <Show
      {...props}
      title={customTitle}
      headerProps={{
        className: `${hasTabs ? "custom-tab-header" : ""} ant-sticky`,
        ...props.headerProps,
      }}
      breadcrumb={
        <div className="breadcrumb">
          <TestStyle
            type="secondary"
            onClick={(e) => {
              e?.stopPropagation();
              navigate({
                pathname: listUrl(resource),
                search: activeTab ? `?tab=${activeTab}` : "",
              });
            }}
          >
            {t(`${resource}.titles.list`)} /
          </TestStyle>
          <Text> {breadcrumbText || t("common.detail")}</Text>
        </div>
      }
      contentProps={{
        style: {
          ...contentStyles,
        },
        bodyStyle: {
          ...bodyStyle,
        },
      }}
      goBack={
        <Icons.ArrowLeftOutlined
          onClick={(e) => {
            e.stopPropagation();
            navigate({
              pathname: listUrl(resource),
              search: activeTab ? `?tab=${activeTab}` : "",
            });
          }}
        />
      }
    >
      {children}
    </Show>
  );
};
