import { Descriptions, Icons, Table, Typography } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { ButtonTooltip, ConfigDisplayText, DataText } from "components";
import { ShowCustom } from "components/layout";
import { PATH } from "configs/path";
import { FC, useMemo } from "react";
import type { ColumnsType } from "antd/es/table";
import { useController } from "./controller";
import { formatISODateTimeToView } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
import {
  AssignExploitingType,
  ProductOwner,
} from "interfaces/AssignExploiting";

const { Text, Title } = Typography;

const AssignExploitingsShow: FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const { edit } = useNavigation();
  const { isLoading, record, checkEditHaveHelperText } = useController();

  const isProduct =
    record?.assignExploitingType?.id === AssignExploitingType.PRODUCT;

  const productColumn = useMemo<ColumnsType<ProductOwner>>(
    () => [
      {
        title: translate("businessOpportunities.productId"),
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return (
            <ConfigDisplayText status={record.status} value={record?.code} />
          );
        },
        sorter: (a, b) => a?.code?.length - b?.code?.length,
      },
      {
        title: translate("businessOpportunities.productName"),
        dataIndex: "name",
        key: "name",
        render: (_, record) => {
          return (
            <ConfigDisplayText status={record.status} value={record?.name} />
          );
        },
        sorter: (a, b) => a?.name?.length - b?.name?.length,
      },
      {
        title: translate("businessOpportunities.productGroup"),
        dataIndex: "productGroup",
        key: "productGroup",
        render: (_, record) => {
          return (
            <ConfigDisplayText
              status={record?.productGroup?.status}
              value={record?.productGroup?.name}
            />
          );
        },
        sorter: (a, b) =>
          a?.productGroup?.name?.length - b?.productGroup?.name?.length,
      },
    ],
    []
  );

  const customerColumns = useMemo<ColumnsType<any>>(
    () => [
      {
        title: translate("assignExploiting.customerCode"),
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Text>{record?.code}</Text>;
        },
        sorter: (a, b) => a?.code?.length - b?.code?.length,
      },
      {
        title: translate("customer.customerName"),
        dataIndex: "name",
        key: "name",
        render: (_, record) => {
          return <Text>{record?.name}</Text>;
        },
        sorter: (a, b) => a?.name?.length - b?.name?.length,
      },
      {
        title: translate("assignExploiting.industry"),
        dataIndex: "businessIndustry",
        key: "businessIndustry",
        render: (_, record) => {
          return (
            <ConfigDisplayText
              status={record?.businessIndustry?.status}
              value={record?.businessIndustry?.name}
            />
          );
        },
        sorter: (a, b) =>
          a?.businessIndustry?.name?.length - b?.businessIndustry?.name?.length,
      },
      {
        title: translate("customer.market"),
        dataIndex: "businessIndustry",
        key: "market",
        render: (_, record) => {
          return (
            <ConfigDisplayText
              status={record?.businessIndustry?.marketArea?.status}
              value={record?.businessIndustry?.marketArea?.name}
            />
          );
        },
        sorter: (a, b) =>
          a?.businessIndustry?.marketArea?.name?.length -
          b?.businessIndustry?.marketArea?.name?.length,
      },
      {
        title: translate("customer.provinceCity"),
        dataIndex: "province",
        key: "province",
        render: (_, record) => {
          return <Text>{record?.province?.name}</Text>;
        },
        sorter: (a, b) => a?.province?.name?.length - b?.province?.name?.length,
      },
    ],
    []
  );

  const { employee = {} } = record || {};

  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        title={
          <Text style={{ marginBottom: 0 }}>
            {translate("assignExploiting.detail")}
          </Text>
        }
        headerButtons={
          <>
            <ButtonTooltip
              type="primary"
              icon={<Icons.EditOutlined />}
              onClick={() => edit(PATH.assignExploitings, record?.id!)}
              {...checkEditHaveHelperText(PATH.assignExploitings)}
            >
              {translate("actions.edit")}
            </ButtonTooltip>
          </>
        }
      >
        <Descriptions
          column={2}
          title={translate("common.titles.detail")}
          layout="vertical"
        >
          <Descriptions.Item
            label={translate("employees.employee")}
            labelStyle={{ fontWeight: 500 }}
            span={2}
          >
            <DataText
              value={`${!!employee?.code && `${employee?.code} - `}${
                employee?.name
              }`}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("assignExploiting.assignExploitingType")}
            labelStyle={{ fontWeight: 500 }}
            span={2}
          >
            <DataText
              value={translate(
                `assignExploiting.type.${record?.assignExploitingType?.id}`
              )}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("common.createdDate")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={formatISODateTimeToView(record?.createdDate, DATE_FORMAT)}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("common.createdName")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.createdName} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("common.updatedDate")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={formatISODateTimeToView(record?.updatedDate, DATE_FORMAT)}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("common.updatedName")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.updatedName} />
          </Descriptions.Item>
        </Descriptions>
      </ShowCustom>
      <div style={{ padding: 24 }}>
        <Title level={5} style={{ marginBottom: 24 }}>
          {translate(
            `assignExploiting.${isProduct ? "productList" : "customerList"}`
          )}
        </Title>
        {isProduct ? (
          <Table
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            rowKey="id"
            columns={productColumn}
            dataSource={record?.productOwner}
          />
        ) : (
          <Table
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            rowKey="id"
            columns={customerColumns}
            dataSource={record?.customer}
          />
        )}
      </div>
    </>
  );
};

export default AssignExploitingsShow;
