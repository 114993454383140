import React, { useState } from "react";
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Space,
} from "@pankod/refine-antd";
import { useLogin, useTranslate } from "@pankod/refine-core";
import { useNavigate } from 'react-router-dom'
import {
    layoutStyles,
    containerStyles,
    titleStyles,
} from "./styles";
import { forgotPassword } from "api";
import { isValidEmail } from "utils/commons";
import LangSelect from "components/LangSelect";

const { Title } = Typography;
export interface IForgotPasswordForm {
    email: string;
}

export const CustomForgotPasswordPage: React.FC = () => {
    const [form] = Form.useForm<IForgotPasswordForm>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSentRequest, setIsSentRequest] = useState<boolean>(false);
    const [isSucess, setIsSuccess] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState('')
    const [enteredEmail, setEnteredEmail] = React.useState<String>();

    const translate = useTranslate();
    const navigate = useNavigate();

    const CardTitle = (isSent: boolean) => (
        <Title level={2} style={titleStyles}>
            {
                !isSent ? translate("pages.login.forgotPassword") : translate("pages.login.forgotPasswordSuccessTitle")
            }
        </Title>
    );

    const onSubmit = (values: IForgotPasswordForm) => {
        const body = {
            userName: values.email
        };
        setEnteredEmail(values.email);

        setIsLoading(true);
        forgotPassword(body).then(res => {
            setIsSuccess(true);
            setIsSentRequest(true);
        })
            .catch(err => {
                setIsError(true)
                setErrorMessage(`errors.${err.errorCode}`);
            }).finally(() => {
                setIsLoading(false);
            })
    };

    const enterEmailScreen = () => {
        return (
            <Card style={{ border: 'none' }} title={CardTitle(isSentRequest)} headStyle={{ borderBottom: 0, color: 'black' }}>
                <Form<IForgotPasswordForm>
                    layout="vertical"
                    form={form}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    requiredMark={false}
                    initialValues={{}}
                >
                    <Row>
                        <Col span={24}>
                            <Space>
                                {translate("pages.login.forgotPasswordDescription")}
                            </Space>
                        </Col>
                    </Row>
                    <Form.Item
                        name="email"
                        label={translate(
                            "pages.login.email",
                            "Email",
                        )}
                        rules={[
                            {
                                required: true,
                                message: translate("errors.ER005"),
                            },
                            {
                                max: 255,
                                message: translate("errors.ER014", { max: '255' }),
                            },
                            {
                                validator: async (_, value) => {
                                    if (!value) return;
                                    if (isValidEmail(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(translate("errors.ER015")),
                                    );
                                },
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            onChange={() => { setErrorMessage('') }}
                            placeholder={translate(
                                "pages.login.emailPlaceholder",
                                "Email",
                            )}

                        />
                    </Form.Item>
                    {isError &&
                        <Row>
                            <Col>
                                <div role="alert" className="ant-form-item-explain-error">{errorMessage && translate(errorMessage ||'')}</div>
                            </Col>
                        </Row>
                    }
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={isLoading}
                        block
                        disabled={isLoading}
                    >
                        {translate("pages.login.forgotPasswordButton")}
                    </Button>

                    <Button
                        type="link"
                        size="large"
                        style={{ color: "black" }}
                        block
                        onClick={() => navigate('/')}
                    >
                        {translate("pages.login.forgotPasswordBackButton")}
                    </Button>
                </Form>
            </Card>
        );
    }
    const sentScreen = () => {
        return (
            <Card style={{ border: 'none' }} title={CardTitle(isSentRequest)} headStyle={{ borderBottom: 0, color: 'black' }}>
                <Row style={{ marginBottom: 16 }}>
                    <Col span={24}>
                        {translate("pages.login.forgotPasswordSuccessMessage")} <a style={{ fontWeight: "bold" }} onClick={e => e.preventDefault()}>{enteredEmail}</a>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={() => navigate('/')}
                        >
                            {translate("pages.login.forgotPasswordSuccessButton")}
                        </Button>
                    </Col>
                </Row>
            </Card>
        );
    }

    return (
        <AntdLayout style={layoutStyles}>
            <LangSelect isFixed isText />
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "70vh"
                }}
            >
                <Col xs={22}>
                    <div style={containerStyles}>
                        {
                            !isSentRequest ? enterEmailScreen() : sentScreen()
                        }
                    </div>
                </Col>

            </Row>
        </AntdLayout>
    );
};
