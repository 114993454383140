import {
  Button,
  Card,
  Col,
  Descriptions,
  Icons,
  Row,
  Table,
  notification,
} from "@pankod/refine-antd";
import { DataText } from "components";
import { FC, memo, useContext, useMemo } from "react";
import { useTranslate } from "@pankod/refine-core";
import { formatISODateTimeToView } from "utils/commons";
import { DATE_TIME_FORMAT } from "configs/constants";
import { getLinkPrewviewFile } from "api";
import { API_PATH } from "configs/path";
import { ContractEstimateShowContext } from "../..";
import { IAttachment } from "interfaces";
import type { ColumnsType } from "antd/es/table";

const General: FC = memo(() => {
  const { record } = useContext(ContractEstimateShowContext);
  const { contractEstimatedDocuments = [] } = record || {};

  const translate = useTranslate();

  const onGetLinkView = (fileUrl: string) => async () => {
    try {
      const res = await getLinkPrewviewFile(
        API_PATH.contractEstimatedsPreviewFile,
        fileUrl
      );
      const url: string = res?.data as unknown as string;
      window.open(url, "_blank");
    } catch (error) {
      notification.error({
        message: "Đã có lỗi xảy ra. Vui lòng liên hệ quản trị viên.",
      });
    }
  };

  const columns = useMemo<ColumnsType<IAttachment>>(
    () => [
      {
        title: translate("Tài liệu"),
        width: 200,
        dataIndex: "fileUrlOrigin",
        key: "fileUrlOrigin",
        render: (value, record) => {
          return (
            <Row gutter={16}>
              <Col flex={1}>
                <DataText value={value} />
              </Col>
              <Col>
                <Button
                  type="text"
                  icon={<Icons.EyeOutlined />}
                  className="primary"
                  onClick={onGetLinkView(record?.fileUrl!)}
                />
              </Col>
            </Row>
          );
        },
      },
    ],
    []
  );

  return (
    <Card style={{ background: "#ffffff" }}>
      <Descriptions
        column={{ xs: 1, lg: 3 }}
        layout="vertical"
        title={translate("common.generalInfor")}
      >
        <Descriptions.Item
          label={translate("Mã DTHĐ")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.code} />
        </Descriptions.Item>

        <Descriptions.Item
          label={translate("Mã DTHĐ gốc")}
          labelStyle={{ fontWeight: 500 }}
          span={2}
        >
          <DataText value={"-"} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("common.createdDate")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText
            value={formatISODateTimeToView(
              record?.createdDate,
              DATE_TIME_FORMAT
            )}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("common.createdName")}
          labelStyle={{ fontWeight: 500 }}
          span={2}
        >
          <DataText value={record?.createdName} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Ghi chú")}
          labelStyle={{ fontWeight: 500 }}
          span={3}
        >
          <DataText value={record?.note} />
        </Descriptions.Item>

        <Descriptions.Item
          label={translate("common.fields.attachments")}
          labelStyle={{ fontWeight: 500 }}
          span={3}
        >
          <Table
            className="w-full"
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            rowKey="id"
            columns={columns}
            dataSource={contractEstimatedDocuments}
          />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
});

export default General;
