import { useShow } from "@pankod/refine-core";
import usePermissions from "hooks/permission";
import AssignExploiting from "interfaces/AssignExploiting";

export const useController = () => {
  const { queryResult } = useShow<AssignExploiting>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { checkEditHaveHelperText } = usePermissions();

  return {
    isLoading,
    record,
    checkEditHaveHelperText,
  };
};
