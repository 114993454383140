import { EmployeeStatus, IntegrationStatus } from "api/enums";
import Employee from "./Employee";
import { MarketArea } from "./MarketArea";
import Region, { District, Province, Ward } from "./Region";

export default interface Customer {
  abbreviationName: string;
  address: string;
  addressInBillingInfo: string;
  businessIndustryId: string;
  businessIndustry: BusinessIndustry;
  code: string;
  createdDate: Date;
  createdName: string;
  district: District;
  districtId: string;
  email: string;
  emailInBillingInfo: string;
  hasBillingInfo: boolean;
  id: string;
  name: string;
  nameInBillingInfo: string;
  paymentTerm: number;
  phoneNumber: string;
  phoneNumberInBillingInfo: string;
  province: Province;
  provinceId: string;
  status: CustomerStatus;
  taxCodeInBillingInfo: string;
  tenantId: string;
  identifiedTaxCode: string;
  updatedDate: Date;
  updatedName: string;
  ward: Ward;
  wardId: string;
  website: string;
  region: Region;
  employeeAssigned: EmployeeAssigned[];
  syncTime: Date;
  syncStatus: IntegrationStatus;
}

export interface EmployeeAssigned {
  employee: {
    code: string;
    fullName: string;
    status: EmployeeStatus;
    id: string;
  };
  assignExloitingType: {
    id: ExploitingType;
    name: string;
  };
}

export enum CustomerStatus {
  DRAFT = 0,
  UNASSIGNED = 1,
  ASSIGNED = 2,
}

export type BusinessIndustry = {
  code: string;
  createdDate: Date;
  createdName: string;
  description: string;
  id: string;
  marketArea: MarketArea;
  name: string;
  status: boolean;
  updatedDate: Date;
  updatedName: string;
};

export enum ExploitingType {
  AM,
  Sub,
}
