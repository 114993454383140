import { Form, FormInstance, FormProps, useSelect } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import { useLocationData } from "contexts/LocationsContext";
import { CustomerStatus } from "interfaces/Customer";
import { useEffect, useMemo } from "react";

export interface Props {
  _mounted: boolean;
  form: FormInstance<{}>;
  formProps: FormProps;
  isEdit?: boolean;
  onFinishedFormValidate?: () => void;
}

const { useWatch } = Form;

export const useController = (props: Props) => {
  const { _mounted, form, formProps, isEdit, onFinishedFormValidate } = props;
  const { getDistrictsByProvinceId, getWardsByDistrictId } = useLocationData();
  const translate = useTranslate();

  const { selectProps: propvinceSelectProps } = useSelect({
    resource: API_PATH.provinceDropdownList,
    optionLabel: "label",
    optionValue: "value",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: businessIndustrySelectProps } = useSelect({
    resource: API_PATH.businessIndustryDropdownList,
    optionLabel: "label",
    optionValue: "value",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const provinceValue = useWatch("provinceId", form);
  const districtValue = useWatch("districtId", form);
  const isHasBillInfomation = useWatch("hasBillingInfo", form);
  const status = form.getFieldValue("status");

  const isDraft = useMemo(
    () => !status || status === CustomerStatus.DRAFT,
    [status]
  );

  const isAssigned = useMemo(
    () => status === CustomerStatus.ASSIGNED,
    [status]
  );

  const districts = useMemo(
    () => getDistrictsByProvinceId(provinceValue),
    [provinceValue]
  );

  const wards = useMemo(
    () => getWardsByDistrictId(districtValue),
    [districtValue]
  );

  useEffect(() => {
    if (_mounted) {
      form.setFieldsValue({ districtId: undefined });
    }
  }, [provinceValue]);

  useEffect(() => {
    if (_mounted) {
      form.setFieldsValue({ wardId: undefined });
    }
  }, [districtValue]);

  return {
    formProps,
    propvinceSelectProps,
    businessIndustrySelectProps,
    provinceValue,
    districtValue,
    districts,
    wards,
    isHasBillInfomation,
    isEdit,
    isAssigned,
    isDraft,
    onFinishedFormValidate,
    translate,
  };
};
