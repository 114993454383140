import { useForm } from "@pankod/refine-antd";
import {
  GetOneResponse,
  IResourceComponentsProps,
  QueryObserverResult,
  useCreate,
  useNavigation,
  useOne,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { Form, notification } from "@pankod/refine-antd";
import { CreateCustom } from "components/layout";
import { API_PATH, PATH } from "configs/path";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { formatOnlyDateToUTC, mappingErrorFromApi } from "utils/commons";
import dayjs from "dayjs";
import { EstimateType, TabKey } from "interfaces/InternalEstimate";
import { useParams } from "react-router-dom";
import { ApprovalStatus } from "api/enums";
import Information from "./components/Information";
import styled from "styled-components";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import EstimateInfo from "./components/EstimateInfo";
import { getInternalEstimatePolicy } from "api";
import { v4 as uuid } from "uuid";

export interface IForm {
  caseId: string;
  referenceRequestID: string | null;
  referenceRequestCode: string | null;
  internalEstimateType: string;
  internalEstimateCode: EstimateType;
  startDate: Date;
  endDate: Date;
  description: string;
  price: number;
  isAllProducts: boolean;
  listInternalEstimateProduct: {
    [key: string]: {
      id: string;
      productId: string;
      unitId: string;
      partNumber: string;
      description: string;
      quantity: number;
      unitPrice: number;
      [key: string]: any;
    };
  };
  listInternalEstimatedCost: {
    [key: string]: {
      costId: string;
      productId: string;
      productName: string;
      internalEstimatePolicyId: string;
      note: string;
      quantity: number;
      unitPrice: number | null;
      percentage: number;
      orderSort: number;
    };
  };
  listInternalEstimatedByManDays: {
    [key: string]: {
      internalRateCardId: string;
      productId: string;
      productName: string;
      internalEstimatePolicyId: string;
      estimatedUnitId: string;
      unitPrice: number | null;
      quantity: number;
      description: string;
      percentage: number;
      orderSort: number;
    };
  };
  listInternalEstimateDocument: {
    fileId: string;
    fileUrl: string;
    fileUrlOriginal: string;
    id: string;
    uploadDate: Date;
  }[];
}

interface State {
  initialValues?: any;
  canEditCaseId?: boolean;
  caseData?: QueryObserverResult<GetOneResponse<ICaseInfo>, unknown>;
  isEdit: boolean;
  onFetchPolicy: (
    products: any[],
    internalEstimateType: string,
    isAllProducts: boolean
  ) => void;
  product: [string[], Dispatch<SetStateAction<string[]>>];
  cost: [string[], Dispatch<SetStateAction<string[]>>];
  manday: [string[], Dispatch<SetStateAction<string[]>>];
  totalCostByMoney: number;
  totalCostByManday: number;
  totalCost: number;
  totalCostOfProductList: number;
  internalCost: (orderSort: number, productTarget: any) => number;
}

const { useWatch } = Form;

const Container = styled.div<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? "node" : "inherit")};
`;

export const InternalEstimateFormContext = createContext<State>({
  initialValues: {},
  canEditCaseId: false,
  isEdit: false,
  onFetchPolicy: () => {},
  product: [[], () => {}],
  cost: [[], () => {}],
  manday: [[], () => {}],
  totalCostByMoney: 0,
  totalCostByManday: 0,
  totalCost: 0,
  totalCostOfProductList: 0,
  internalCost: () => 0,
});

const InternalEstimatesCreate: FC<IResourceComponentsProps> = (props) => {
  const { id } = useParams();
  const [isCanFetch, setIsCanFetch] = useState(true);
  const { form, formProps, saveButtonProps, formLoading } = useForm<
    any,
    any,
    IForm
  >({
    metaData: { isConverting: true },
    queryOptions: { enabled: isCanFetch },
  });
  const { mutate: mutateCreate, isLoading } = useCreate<{ id: string }, any>();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();
  const translate = useTranslate();
  const { show } = useNavigation();
  const product = useState<string[]>([uuid()]);
  const cost = useState<string[]>([]);
  const manday = useState<string[]>([]);

  const [listProduct, setListProduct] = product;
  const [__, setListCost] = cost;
  const [___, setListManday] = manday;

  const isEdit = !!formProps.initialValues?.id;

  const canEditCaseId =
    Number(formProps?.initialValues?.status) === ApprovalStatus.Draft ||
    !isEdit;

  const [visibleModalConfirmCreate, setVisibleShowModalConfirm] =
    useState(false);

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleShowModalConfirm(isOpen);

  const caseId = useWatch("caseId", form);
  const listInternalEstimateProduct = useWatch(
    "listInternalEstimateProduct",
    form
  );
  const listInternalEstimatedCost = useWatch("listInternalEstimatedCost", form);
  const listInternalEstimatedByManDays = useWatch(
    "listInternalEstimatedByManDays",
    form
  );
  const isAllProducts = useWatch("isAllProducts", form);

  const caseData = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(caseId || ""),
    id: "",
    queryOptions: { enabled: !!caseId },
  });

  const onFetchPolicy = async (
    products: any[],
    internalEstimateType: string,
    isAllProducts: boolean
  ) => {
    try {
      if (!products.length || !internalEstimateType || isAllProducts)
        throw new Error("data invalid");
      const productsRequest = products?.map((product, index) => ({
        productId: product?.productId,
        orderSort: index + 1,
      }));
      const res = await getInternalEstimatePolicy({
        internalEstimateType,
        products: productsRequest,
      });
      let tempCost: any = {};
      let defaultCost: any = {};

      let tempManday: any = {};
      let defaultManday: any = {};

      const {
        internalEstimatedCostsProportion,
        internalEstimatedLaborsProportion,
      } = res.data;

      for (let cost in listInternalEstimatedCost) {
        if (!listInternalEstimatedCost[cost]?.orderSort) {
          defaultCost[cost] = listInternalEstimatedCost[cost];
        }
      }

      for (let manday in listInternalEstimatedByManDays) {
        if (!listInternalEstimatedByManDays[manday]?.orderSort) {
          defaultManday[manday] = listInternalEstimatedByManDays[manday];
        }
      }

      if (internalEstimatedCostsProportion?.length) {
        internalEstimatedCostsProportion.forEach((cost) => {
          const { orderSort, product, proportion } = cost;
          const id = uuid();

          tempCost[id] = {
            orderSort: orderSort,
            productId: product?.id,
            productName: `${product?.code} - ${product?.name}`,
            percentage: proportion,
            unitPrice:
              products?.[orderSort - 1]?.unitPrice *
              products?.[orderSort - 1]?.quantity,
            internalEstimatePolicyId: cost.id,
          };
        });
        const newCost = { ...tempCost, ...defaultCost };
        setListCost(Object.keys(newCost || {}));
        form.setFieldsValue({ listInternalEstimatedCost: newCost });
      }

      if (internalEstimatedLaborsProportion?.length) {
        internalEstimatedLaborsProportion.forEach((manday) => {
          const { orderSort, product, proportion } = manday;
          const id = uuid();
          tempManday[id] = {
            orderSort: orderSort,
            productId: product?.id,
            productName: `${product?.code} - ${product?.name}`,
            percentage: proportion,
            unitPrice:
              products?.[orderSort - 1]?.unitPrice *
              products?.[orderSort - 1]?.quantity,
            internalEstimatePolicyId: manday.id,
          };
        });
        const newManday = { ...tempManday, ...defaultManday };
        setListManday(Object.keys(newManday || {}));
        form.setFieldsValue({ listInternalEstimatedByManDays: newManday });
      }
    } catch (error) {
      //
    }
  };

  const { initialValues } = formProps;

  const validateDraft = async () => {
    try {
      await form.validateFields();
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.resolve(false);
    }
  };

  const [activeTab, setActiveTab] = useState(TabKey.INFO);

  const onChangeTab = (tabKey: TabKey) => {
    setActiveTab(tabKey);
    window.scrollTo(0, 0);
  };

  const headerTab = useMemo(
    () => [
      {
        name: translate("Thông tin tổng quan"),
        key: TabKey.INFO,
      },
      {
        name: translate("Chi tiết dự toán nội bộ"),
        key: TabKey.ESTIMATE_INFO,
      },
    ],
    []
  );

  const totalCostOfProductList = useMemo(
    () =>
      Object.values(listInternalEstimateProduct || {})?.reduce(
        (cur, next) => cur + (next?.unitPrice || 0) * (next?.quantity || 0),
        0
      ),
    [listInternalEstimateProduct]
  );

  const totalCostByMoney = useMemo(
    () =>
      Object.values(listInternalEstimatedCost || {})?.reduce((cur, next) => {
        let temp;
        temp =
          (next?.unitPrice || 0) *
          (!next.orderSort || isAllProducts
            ? next?.quantity || 0
            : next.percentage);
        return cur + temp;
      }, 0),
    [listInternalEstimatedCost, isAllProducts]
  );

  const totalCostByManday = useMemo(
    () =>
      Object.values(listInternalEstimatedByManDays || {})?.reduce(
        (cur, next) => {
          let temp;
          temp =
            (next?.unitPrice || 0) *
            (!next.orderSort || isAllProducts
              ? next?.quantity || 0
              : next.percentage);
          return cur + temp;
        },
        0
      ),
    [listInternalEstimatedByManDays, isAllProducts]
  );

  const totalCost = useMemo(
    () => totalCostByManday + totalCostByMoney,
    [totalCostByManday, totalCostByMoney]
  );

  const internalCost = useCallback(
    (orderSort: number, productTarget: any): number => {
      if (isAllProducts) {
        return listProduct.length ? totalCost / listProduct.length : 0;
      }
      const listCost = Object.values(listInternalEstimatedCost || {});
      const listManday = Object.values(listInternalEstimatedByManDays || {});
      const estimateData = [...listCost, ...listManday];
      const proportionDatas = estimateData?.filter(
        (ed) => ed.orderSort === orderSort
      );
      const countDuplicateProducts = Object.values(
        listInternalEstimateProduct || {}
      )?.filter((prod) => prod?.productId === productTarget?.productId)?.length;
      const nonPropotionProducts = estimateData?.filter(
        (ed) => !ed.orderSort && ed?.productId === productTarget?.productId
      );

      if (!!proportionDatas.length) {
        return proportionDatas?.reduce(
          (cur, next) =>
            cur +
            next?.percentage *
              productTarget?.quantity *
              productTarget?.unitPrice,

          0
        );
      }
      if (!nonPropotionProducts?.length) return 0;

      const money = nonPropotionProducts?.reduce((cur, next) => {
        return cur + (next?.quantity || 0) * (next?.unitPrice || 0);
      }, 0);

      if (countDuplicateProducts > 1) return money / countDuplicateProducts;

      return money;
    },
    [
      totalCost,
      listProduct,
      isAllProducts,
      listInternalEstimateProduct,
      listInternalEstimatedByManDays,
      listInternalEstimatedCost,
    ]
  );

  const onSubmit = (isDraft?: boolean) => () => {
    const dataForm = form.getFieldsValue();

    const listInternalEstimateProduct = Object.values(
      dataForm?.listInternalEstimateProduct || {}
    )?.map((prod, index) => {
      const internalCostRequest = internalCost(index + 1, prod);
      const selling = (prod?.quantity || 0) * (prod?.unitPrice || 0);
      return {
        productId: prod?.productId,
        unitId: prod?.unitId,
        partNumber: prod?.partNumber,
        description: prod?.description,
        quantity: prod?.quantity,
        unitPrice: prod?.unitPrice,
        sellingPrice: selling,
        internalCost: internalCostRequest,
        margin: selling - internalCostRequest,
        orderSort: index + 1,
        id: prod?.id
      };
    });

    const listInternalEstimateDocument =
      dataForm?.listInternalEstimateDocument?.map((d) => d.fileId);

    const listInternalEstimatedCost = Object.values(
      dataForm?.listInternalEstimatedCost || {}
    )?.map((cost) => {
      return {
        orderSort: isAllProducts ? 0 : cost?.orderSort,
        costId: cost?.orderSort && !isAllProducts ? null : cost?.costId,
        productId: cost?.productId,
        internalEstimatePolicyId: isAllProducts
          ? null
          : cost.internalEstimatePolicyId,
        note: cost?.note,
        quantity: cost?.quantity,
        unitPrice: cost?.unitPrice,
        proportion: cost?.percentage,
      };
    });

    const listInternalEstimatedByManDays = Object.values(
      dataForm?.listInternalEstimatedByManDays || {}
    )?.map((cost) => {
      return {
        internalRateCardId:
          cost?.orderSort && !isAllProducts ? null : cost?.internalRateCardId,
        productId: cost?.productId,
        internalEstimatePolicyId: isAllProducts
          ? null
          : cost.internalEstimatePolicyId,
        estimatedUnitId:
          cost?.orderSort && !isAllProducts ? null : cost?.estimatedUnitId,
        unitPrice: cost?.unitPrice,
        quantity: cost?.quantity,
        description: cost?.description,
        orderSort: isAllProducts ? 0 : cost?.orderSort,
        proportion: cost?.percentage,
      };
    });

    const dataRequest = {
      id: id || "",
      resource: PATH.internalEstimates,
      values: {
        caseId: dataForm.caseId,
        referenceRequestID: dataForm.referenceRequestID,
        internalEstimateType: dataForm.internalEstimateType,
        startDate: formatOnlyDateToUTC(dataForm.startDate),
        endDate: formatOnlyDateToUTC(dataForm.endDate),
        description: dataForm?.description?.trim(),
        price: dataForm?.price,
        isAllProducts: dataForm?.isAllProducts,
        estimateStatus: isDraft ? ApprovalStatus.Draft : ApprovalStatus.Waiting,
        listInternalEstimateProduct: listInternalEstimateProduct,
        listInternalEstimateDocument: listInternalEstimateDocument,
        listInternalEstimatedCost,
        listInternalEstimatedByManDays,
      },
    };

    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: translate(
            isEdit ? "Cập nhật thành công" : "Tạo mới thành công"
          ),
        });
        show(PATH.internalEstimates, res?.data?.id);
      },
      onError: (error: any) => {
        toggleModalConfirm(false);

        const errorFieldMapping: any = {};

        mappingErrorFromApi(error, form, errorFieldMapping);
      },
    };

    if (isEdit) {
      mutateUpdate(dataRequest, handleResponse);
    } else {
      mutateCreate(dataRequest, handleResponse);
    }
  };

  useEffect(() => {
    if (isEdit && !formLoading) {
      const {
        listInternalEstimateProduct = {},
        listInternalEstimatedCost = {},
        listInternalEstimatedByManDays = {},
      } = formProps?.initialValues || {};

      setListProduct(Object.keys(listInternalEstimateProduct));
      setListCost(Object.keys(listInternalEstimatedCost));
      setListManday(Object.keys(listInternalEstimatedByManDays));
      setIsCanFetch(false);
    }
  }, [isEdit, formLoading]);

  return (
    <InternalEstimateFormContext.Provider
      value={{
        initialValues: formProps?.initialValues,
        canEditCaseId,
        caseData,
        isEdit,
        onFetchPolicy,
        product,
        cost,
        manday,
        totalCostByMoney,
        totalCostByManday,
        totalCost,
        totalCostOfProductList,
        internalCost,
      }}
    >
      <CreateCustom
        {...props}
        title={translate(
          isEdit ? "Chỉnh sửa dự toán nội bộ" : "Tạo mới dự toán nội bộ"
        )}
        tabs={headerTab}
        onChangeTab={onChangeTab}
        onSubmit={onSubmit()}
        visibleShowModal={visibleModalConfirmCreate}
        setVisibleShowModal={toggleModalConfirm}
        saving={isLoading || isLoadingUpdate}
        saveButtonProps={{
          ...saveButtonProps,
          title: translate("Lưu và chuyển"),
        }}
        isLoading={formLoading}
        validateDraft={validateDraft}
        onDraft={onSubmit(true)}
        confirmModalProps={{
          title: translate(
            `Bạn muốn ${isEdit ? "sửa" : "tạo"} và chuyển dự toán nội bộ này?`
          ),
        }}
        draftModalProps={{
          title: "Bạn muốn lưu nháp dự toán nội bộ này?",
        }}
        draftButtonProps={{ title: isEdit ? "Lưu chỉnh sửa" : "Lưu nháp" }}
        cancelModalProps={{
          title: `Bạn muốn hủy ${
            isEdit ? "chỉnh sửa" : "tạo"
          } dự toán nội bộ này`,
          description: `Sau khi hủy ${
            isEdit ? "chỉnh sửa" : "tạo"
          } dự toán nội bộ, mọi thông tin của bạn sẽ không được lưu lại.`,
        }}
        contentStyles={{ background: "transparent", padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          {...formProps}
          form={form}
          onFinish={() => toggleModalConfirm(true)}
          layout="vertical"
          initialValues={{
            startDate: dayjs().startOf("date"),
            ...initialValues,
          }}
        >
          <Container hidden={activeTab !== TabKey.INFO}>
            <Information />
          </Container>

          <Container hidden={activeTab !== TabKey.ESTIMATE_INFO}>
            <EstimateInfo />
          </Container>
        </Form>
      </CreateCustom>
    </InternalEstimateFormContext.Provider>
  );
};

export default InternalEstimatesCreate;
