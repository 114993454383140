export interface Province {
  code: string;
  id: string;
  name: string;
}

export interface District {
  code: string;
  id: string;
  name: string;
}

export interface Ward {
  code: string;
  id: string;
  name: string;
}

export default interface Region {
  code: string;
  id: string;
  name: string;
  status: boolean;
}

export enum RegionStatus {
  DEACTIVE,
  ACTIVE,
  ALL,
}
