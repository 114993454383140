import {
  Typography,
  useForm,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  notification,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
  useUpdate,
} from '@pankod/refine-core'
import { EditCustom } from 'components/layout'
import { SelectCustom } from 'components/SelectCustom'
import { API_PATH, PATH } from 'configs/path'
import { IFeature, IWorkflow } from 'interfaces'
import moment from 'moment'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  formatOnlyDateToUTC,
  isValidCodeField,
  mappingErrorFromApi,
} from 'utils/commons'
import { ApprovalProcedureStatus, WorkflowStatus } from 'api/enums'
import UploadMulti from 'components/UploadMulti'
const { RangePicker } = DatePicker
const { Title, Text } = Typography
const { TextArea } = Input

export const WorkflowEdit: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate()
  const navigate = useNavigate()
  const { goBack, show } = useNavigation()

  const [isFinish, setFinish] = useState(false)
  const [selectedFeature, setSelectedFeature] = useState<IFeature>()

  const { form, formProps, saveButtonProps } = useForm<IWorkflow>()
  const { mutate: mutateUpdate, isLoading } = useUpdate()
  const record = formProps.initialValues || {}

  useEffect(() => {
    if (record?.status == WorkflowStatus.Published) {
      show(PATH.workflows, record?.id);
    }

    form.setFieldsValue({
      appliedDate: [
        dayjs(record?.appliedFromDate),
        dayjs(record?.appliedToDate),
      ],
    })
  }, [record])

  const onSubmit = () => {
    const valueForm = form.getFieldsValue() as IWorkflow

    const appliedDates = form.getFieldValue('appliedDate')
    const body = {
      name: valueForm.name.trim(),
      appliedFromDate: formatOnlyDateToUTC(appliedDates[0]),
      appliedToDate: formatOnlyDateToUTC(appliedDates[1]),
      code: valueForm.code.trim().toUpperCase(),
      featureId: selectedFeature?.featureId,
      featureName: selectedFeature?.featureName,
      featureCode: selectedFeature?.featureCode,
      description: valueForm.description,
      attachments: valueForm.attachments
    }

    mutateUpdate(
      {
        resource: PATH.workflows,
        values: body,
        id: record?.id,
        invalidates: []
      },
      {
        onSuccess: (res: any) => {
          notification.success({
            message: t('workflows.messages.editSuccess'),
          })
          navigate(`show/${res?.data?.id}`)
        },
        onError: (error: any) => {
          setFinish(false)
          mappingErrorFromApi(error, form, { ER074: 'appliedDate'})
        },
      },
    )
  }


  const disabled = record.status === ApprovalProcedureStatus.Published

  return (
    <>
      <EditCustom
        record={{
          id: undefined,
        }}
        {...props}
        title={
          <Text>
            {t('workflows.titles.edit')}:{' '}
            <Text className="primary">{record.code}</Text>
          </Text>
        }
        onCancel={() => {
          goBack()
        }}
        onSubmit={() => onSubmit()}
        visibleShowModal={isFinish}
        setVisibleShowModal={(isShow: boolean) => setFinish(isShow)}
        saveButtonProps={{
          ...saveButtonProps,
          disabled: disabled || isLoading
        }}
        isLoading={isLoading}
        titlePopupConfirm={t('workflows.messages.saveConfirm')}
        cancelModalProps={{
          title: t('workflows.messages.cancelEditConfirm')
        }}
      >
        <Form
          {...formProps}
          onFinish={() => setFinish(true)}
          onFinishFailed={() => {}}
          layout="vertical"
          labelCol={{ span: 10 }}
        >
          <Title level={4}>{t('workflows.workflowInfo')}</Title>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
            <Col span={12}>
              <Form.Item
                label={t('workflows.fields.code')}
                name="code"
              >
                <Input
                  disabled
                  placeholder={t('workflows.fields.codePlaceHolder')}
                  style={{ textTransform: 'uppercase' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
            <Col span={12}>
              <Form.Item
                label={t('workflows.fields.name')}
                name="name"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: t('errors.ER005'),
                  },
                  {
                    max: 300,
                    message: t('errors.ER014', { max: '300' }),
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  placeholder={t('workflows.fields.namePlaceHolder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
            <Col span={12}>
              <Form.Item
                label={t('workflows.fields.feature')}
                name={['feature', 'featureId']}
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: t('errors.ER005'),
                  },
                  {
                    max: 300,
                    message: t('errors.ER014', { max: '300' }),
                  },
                ]}
              >
                <SelectCustom
                  allowClear
                  disabled={disabled}
                  resource={API_PATH.businessFeature}
                  optionValue="featureId"
                  optionLabel="featureName"
                  valueInObject={true}
                  onChangeItem={(value: any, option: any, data: any) => {
                    setSelectedFeature(data)
                  }}
                  label={''}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
            <Col span={12}>
              <Form.Item
                label={t('workflows.fields.appliedDate')}
                name="appliedDate"
                rules={[
                  {
                    required: true,
                    message: t('errors.ER005'),
                  },
                ]}
              >
                <RangePicker
                  disabled={disabled}
                  format={'DD/MM/YYYY'}
                  style={{ width: '100%' }}
                  placeholder={[
                    t('workflows.fields.appliedFromDatePlaceHolder'),
                    t('workflows.fields.appliedToDatePlaceHolder'),
                  ]}
                  disabledDate={(current) => {
                    let now = moment().add(-1, 'day') as any
                    return current && current <= now
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
            <Col span={12}>
              <Form.Item
                label={t('workflows.fields.description')}
                name="description"
                rules={[
                  {
                    max: 500,
                    message: t('errors.ER014', { max: '500' }),
                  },
                ]}
              >
                <TextArea
                  disabled={disabled}
                  showCount={{formatter: (args: {count: number}) => `${args?.count}/500`}}
                  placeholder={t('roles.fields.descriptionPlaceHolder')}
                />
              </Form.Item>
            </Col>
          </Row>
          
          <Row>
            <Col span={24}>
              <Form.Item
                name="attachments"
                validateStatus="success"
                label=""
                required
                rules={[
                  {
                    required: true,
                    message: t('errors.ER005'),
                  },
                ]}
              >
                <UploadMulti
                  title={t('common.fields.attachments')}
                  repo="ApprovalProcedures"
                  required
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </EditCustom>
    </>
  )
}
