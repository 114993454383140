import { Icons, Typography } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import { ButtonTooltip } from "components";
import { ShowCustom } from "components/layout";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import IQuotation, { QuotationStatus } from "interfaces/Quotation";
import { FC, useState } from "react";
import General from "./components/General";
import Customer from "./components/Customer";
import ProductTable from "./components/ProductTable";
import Policy from "./components/Policy";
import Commercial from "./components/Commercial";
import { exportPDFQuotation } from "api";
import EndPoints from "configs/api";
import QuotCreator from "../common/Creator";

const { Text } = Typography;

export const getStatus = (
  value: QuotationStatus = QuotationStatus.Unexported
) => {
  let agr: { color: string } = { color: "success" };

  switch (Number(value)) {
    case QuotationStatus.Exported:
      agr.color = "success";
      break;
    case QuotationStatus.Unexported:
      agr.color = "warning";
      break;
    default:
      agr.color = "warning";
      break;
  }

  return agr;
};

const QuotationShow: FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const { checkEditHaveHelperText } = usePermissions();
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);

  const {
    queryResult: { data, isLoading },
  } = useShow<IQuotation>();
  const record = data?.data;
  const { edit } = useNavigation();

  const printBase64PDF = async () => {
    setIsLoadingPreview(true);
    try {
      const resPdf = (await exportPDFQuotation(
        EndPoints.exportPDFQuotation(record?.id!)
      )) as unknown as ArrayBuffer;

      const pdfBlob = new Blob([resPdf], { type: "application/pdf" });

      var fileURL = URL.createObjectURL(pdfBlob);

      var winparams =
        "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
        "resizable,screenX=50,screenY=50,width=3000,height=2000";

      const b = document.createElement("embed");

      b.src = fileURL;
      b.type = "application/pdf";
      b.width = "100%";
      b.height = "100%";

      const css = "@page { size: landscape; }";
      const style = document.createElement("style");
      style.media = "print";

      style.appendChild(document.createTextNode(css));
      b.appendChild(style);

      var printWindow = window.open("", "PDF", winparams);
      printWindow?.document.write(b.outerHTML);
    } catch (error) {
      //
    } finally {
      setIsLoadingPreview(false);
    }
  };

  return (
    <ShowCustom
      {...props}
      isLoading={isLoading}
      breadcrumbText={record?.name}
      title={
        <Text style={{ marginBottom: 0 }}>
          {translate("Báo giá")}:{" "}
          <Text className="primary">{record?.name}</Text>
        </Text>
      }
      headerButtons={
        <>
          <ButtonTooltip
            type="ghost"
            icon={<Icons.EditOutlined />}
            onClick={() => edit(PATH.quotations, record?.id!)}
            {...checkEditHaveHelperText(PATH.quotations)}
          >
            {translate("actions.edit")}
          </ButtonTooltip>
          <ButtonTooltip
            type="primary"
            loading={isLoadingPreview}
            icon={<Icons.PrinterOutlined />}
            onClick={printBase64PDF}
            {...checkEditHaveHelperText(PATH.quotations)}
          >
            {translate("In báo giá")}
          </ButtonTooltip>
        </>
      }
      contentStyles={{
        padding: 0,
        background: "transparent",
      }}
      bodyStyle={{
        padding: 0,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <General record={record} />
        <Customer record={record} />
        <QuotCreator
        creater={{
          fullName: record?.creater?.fullName,
          code: record?.creater?.code,
          phoneNumber: record?.creater?.phoneNumber,
        }}
      />
        <ProductTable record={record} />
        <Policy record={record} />
        <Commercial record={record} />
      </div>
    </ShowCustom>
  );
};

export default QuotationShow;
