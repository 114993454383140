import { Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import {
  ApprovalServiceRequest, TabKey,
} from "components/ApprovalServiceRequest";
import { API_PATH, PATH } from "configs/path";
import ISettlement, { SettlemenData } from "interfaces/Settlement";
import { FC, createContext, memo, useState } from "react";
import styled from "styled-components";
import CaseInfo from "./components/CaseInfo";
import SettlementInfo from "./components/SettlementInfo";
import Cost from "./components/Cost";
import SubmitionInfomationSettlement from "./components/SubmitCondition";
import { sendSettlementDefaultData } from "api";
import ButtonConfirm from "components/ButtonConfirm";
import { SettlementCostsData } from "../create";

const { Text } = Typography;

interface InformationProps {
  record?: Partial<SettlemenData>;
  hidden?: boolean;
}

const InfomationStyle = styled.div<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  gap: 8px;
  min-height: 400px;
`;

export const SettlementShowContext = createContext<Partial<SettlemenData>>({});

const Infomation: FC<InformationProps> = memo((props) => {
  const { hidden, record } = props;

  return (
    <SettlementShowContext.Provider value={record || {}}>
      <InfomationStyle hidden={hidden}>
        <CaseInfo />
        <SettlementInfo />
        <SubmitionInfomationSettlement
          {...{ objectSubmitConditions: record?.objectSubmitConditions }}
        />
      </InfomationStyle>
    </SettlementShowContext.Provider>
  );
});

const SettlementShow: FC<IResourceComponentsProps> = memo((props) => {
  const t = useTranslate();
  const [isUpdateData, setIsUpdateData] = useState<boolean>(false);
  const checkCostsDataNeedUpdate = async (body: SettlementCostsData): Promise<boolean> => {
    try {
      const res = await sendSettlementDefaultData(
        API_PATH.settlementDefaultData,
        body
      )
      if(res?.data?.isUpdated === true) {
        setIsUpdateData(true);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  return (
    <ApprovalServiceRequest
      {...props}
      onApprovalSuccess={() => {}}
      resource={PATH.settlements}
      checkSubmit= { async (record: ISettlement)=> {
        return await checkCostsDataNeedUpdate({
          "caseId": record?.objectData?.case?.id,
          "settlementCosts" :record?.objectData?.settlementCosts,
          "settlementLaborMandays" : record?.objectData?.settlementLaborMandays,
        })
      }}
      requestName={t("Phê duyệt QTTK")}
      headerTabs={[
        {
          name: t("approval.info"),
          key: TabKey.INFO_TAB,
          isActive: true,
          hidden: false,
        },
        {
          name: t("settlements.detail"),
          key: TabKey.COSTS_TAB,
          isActive: false,
          hidden: false,
        },
        {
          name: t("approval.history"),
          key: TabKey.HISTORY_TAB,
          isActive: false,
          hidden: false,
        },]
      }
      renderInformation={(record: ISettlement, tab: TabKey) => (
        <SettlementShowContext.Provider value={record?.objectData || {}}>
          <Infomation
            record={record?.objectData}
            hidden={tab !== TabKey.INFO_TAB}
          />
          <InfomationStyle hidden={tab !== TabKey.COSTS_TAB} >
            <Cost />
          </InfomationStyle>
          <ButtonConfirm 
          visible={isUpdateData}
          toggle={() => setIsUpdateData(false)}
          onClick={() => {
            setIsUpdateData(false) 
          }}
          text={t("settlements.update.data.confirm")}
          description={t("settlements.update.data.description")}
        />
        </SettlementShowContext.Provider>
      )}
      title={(record: ISettlement) => (
        <>
          {t("common.Settlements")}:{" "}
          <Text className="primary">{record?.objectData?.case?.name}</Text>
        </>
      )}
    />
  );
});

export default SettlementShow;
