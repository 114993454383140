import { Button, Create, Form, Icons, Input, useForm, notification, Modal, Space, Row, Col } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useTranslate } from "@pankod/refine-core";
import { Typography } from 'antd';
import { forgotPassword, postRolePermissions } from "api";
import InputUpper from "components/InputUpper";
import { CreateCustom } from "components/layout";
import { API_PATH, PATH } from "configs/path";
import { IFeaturePermission, IRole, IRolePermission } from "interfaces";
import { RolePermissions } from "pages/rolePermissions";
import React from "react";
import { useNavigate } from 'react-router-dom'
import { isValidCodeField, isValidRoleName, mappingErrorFromApi } from "utils/commons";
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

export const RoleCreate: React.FC<IResourceComponentsProps> = (props) => {
    const { form, formProps, saveButtonProps } = useForm<IRole>();
    const translate = useTranslate();
    const navigate = useNavigate()
    const [isDisable, setIsDisable] = React.useState<boolean>(true);
    const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>();
    const [visibleShowRoleModal, setVisibleShowRoleModal] = React.useState<boolean>();
    const [error, setError] = React.useState<string>();
    const { mutate: mutateCreate, isLoading } = useCreate<any>();
    
    const onCreate = () => {
        mutateCreate(
            {
                resource: API_PATH.roles,
                values: {
                    name: form.getFieldValue('name').trim(),
                    code: form.getFieldValue('code').trim(),
                    description: form.getFieldValue('description')?.trim(),
                },
            },
            {
                onSuccess: (res) => {
                    createRolePermissions(res?.data);
                },
                onError: (error: any) => {
                    setVisibleShowModal(false)
                    mappingErrorFromApi(error, form, {
                        ER047: 'name',
                        ER046: 'code',
                      })
                },
            },
        )
    }
    const onSubmit = () => {
        // form.submit();
        onCreate()
    }
    const validateRolePermissions = (): boolean => {
        var isValid = true;
        var rolePermissions = form.getFieldValue('rolePermissions') as IFeaturePermission[];

        if (rolePermissions && rolePermissions.filter(t => !t.featureId).length > 0) {
            isValid = false;
            setVisibleShowRoleModal(true);
        }
        return isValid;
    }


    const createRolePermissions = (roleId: string) => {
        var rolePermissions = form.getFieldValue('rolePermissions') as IFeaturePermission[];
        if (rolePermissions && rolePermissions.length > 0) 
        {
            const body: IRolePermission = {
                misRoleId: roleId,
                featurePermissions: rolePermissions
            }
            postRolePermissions(PATH.rolePermissions, body).then(res => {
                notification.success({
                    message: translate("roles.message.createSuccess")
                })
                navigate(`show/${roleId}`)
            })
            .catch(error => {
                mappingErrorFromApi(error, form, {
                    ER047: 'name',
                    ER046: 'code',
                })
            }).finally(() =>{
                setVisibleShowModal(false)
            })
        } else {
            notification.success({
                message: translate("roles.message.createSuccess")
            })
            setVisibleShowModal(false);
            navigate(`show/${roleId}`);
        }
    }
    

    return (
        <>
            <CreateCustom
                {...props}
                saveButtonProps={{
                    ...saveButtonProps,
                    title: translate('roles.actions.create')
                }}
                confirmModalProps={{
                    title: translate('roles.message.createConfirm')
                }}
                visibleShowModal={visibleShowModal}
                setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
                onSubmit={onSubmit}
                saving={isLoading}
            >
                <Form
                    {...formProps}
                    onFinish={() => { setVisibleShowModal(true);}}
                    onFinishFailed={() => { setIsDisable(true)}}
                    layout='vertical'
                    labelCol={{ span: 10 }}
                >
                    <Title level={4}>{translate("roles.roleInfor")}</Title>
                    <Form.Item
                        label={translate("roles.fields.code")}
                        labelAlign="left"
                        name="code"
                        style={{maxWidth: 500}}
                        rules={[
                            {
                                whitespace: false,
                                required: true,
                                message: translate("errors.ER005"),
                            },
                            {
                                min: 3,
                                message: translate("errors.ER013", { min: '3'}),
                            },
                            {
                                max: 10,
                                message: translate("errors.ER014", { max: '10'}),
                            },
                            {
                                validator: async (_, value) => {
                                    if (!value) return;
                                    return isValidCodeField(value) ? Promise.resolve() : Promise.reject(new Error(translate("errors.ER053")))
                                },
                            }
                        ]}
                        >
                        <InputUpper placeholder={translate("roles.fields.codePlaceHolder")}  />
                    </Form.Item>
                    <Form.Item
                        label={translate("roles.fields.name")}
                        name="name"
                        style={{maxWidth: 500}}
                        rules={[
                            {
                                required: true,
                                message: translate("errors.ER005"),
                            },
                            {
                                min: 3,
                                message: translate("errors.ER013", { min: '3'}),
                            },
                            {
                                max: 50,
                                message: translate("errors.ER014", { max: '50'}),
                            },
                            {
                                validator: async (_, value) => {
                                    if (!value) return;
                                    if (isValidRoleName(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(translate("errors.ER029")),
                                    );
                                },
                            }
                        ]}
                        >
                        <Input placeholder={translate("roles.fields.namePlaceHolder")} />
                    </Form.Item>
                    <Form.Item
                        label={translate("roles.fields.description")}
                        name="description"
                        style={{maxWidth: 500}}
                        rules={[
                            {
                                max: 300,
                                message: translate("errors.ER014", { max: '300'}),
                            },
                        ]}
                        >
                        <TextArea
                            showCount={{formatter: (args: {count: number}) => `${args?.count}/300`}}
                            placeholder={translate("roles.fields.descriptionPlaceHolder")}
                        />
                    </Form.Item>
                    <Form.Item
                        name="rolePermissions"
                        validateTrigger="submit"
                        validateStatus="success"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (!value) return;
                                    if (validateRolePermissions()) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject();
                                },
                            }
                        ]}
                        >
                        <RolePermissions value={[]} />
                    </Form.Item>
                </Form>
            </CreateCustom>
            {/* <Modal title=""
                visible={visibleShowModal}
                onOk={onCreate}
                onCancel={() => setVisibleShowModal(false)}
                okText={translate("buttons.confirm")}
                cancelText={translate("buttons.reject")}
            >
                <Space>
                    <Text style={{fontSize: 22}} type="warning"><Icons.InfoCircleOutlined/></Text>
                    <Text style={{fontWeight: 500, fontSize: 16}}>{translate("roles.message.createConfirm")}</Text>
                </Space>
            </Modal> */}

            <Modal title=""
                visible={visibleShowRoleModal}
                onOk={() => setVisibleShowRoleModal(false)}
                onCancel={() => setVisibleShowRoleModal(false)}
                okText={translate("buttons.confirm")}
                cancelText={translate("buttons.reject")}
                cancelButtonProps={{hidden: true}}
            >
                <Space align="start">
                    <Text style={{fontSize: 22, marginRight: 10}} type="warning"><Icons.InfoCircleOutlined/></Text>
                    <Row>
                        <Col>
                            <Text style={{fontWeight: 500, fontSize: 16}}>{translate("roles.message.roleErrorTitle")}</Text>
                            <Paragraph>{translate("roles.message.roleErrorMessage")}</Paragraph>
                        </Col>
                    </Row>
                </Space>
                
            </Modal>
        </>
    );
}