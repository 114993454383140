import {
  getDefaultSortOrder,
  Radio,
  Select,
  Space,
  Table,
  TextField,
  useSelect,
} from "@pankod/refine-antd";
import { useTable, useTranslate } from "@pankod/refine-core";
import { ApprovalActionStatus } from "api/enums";
import { EmptyData, SearchBox } from "components";
import { ProcessApprovalStatusLabel } from "components/ApprovalStatusLabel";
import { DataText } from "components/DataText";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { API_PATH, PATH, SLUGS } from "configs/path";
import { IApprovalDetailBase } from "interfaces";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LinkText } from "components";
import { WORKID_TYPE_OPTIONS } from "configs/constants";

interface ApprovalTableProps {
  resource: string;
  hiddenColumn?: boolean;
  filterByWorkIdType?: boolean;
}

const ApprovalTable = ({
  resource,
  hiddenColumn = false,
  filterByWorkIdType = false,
}: ApprovalTableProps) => {
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get("code") || "";
  const [paymentCode, setPaymentCode] = React.useState<string>(codeParam);
  const t = useTranslate();
  const navigate = useNavigate();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);

  const {
    tableQueryResult: { data: dataTable, isLoading, isRefetching },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    filters,
    setFilters,
    sorter,
    setSorter,
  } = useTable<IApprovalDetailBase>({
    resource: resource,
    metaData: { subFilter: "/approvals" },
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "status",
        operator: "eq",
        value: ApprovalActionStatus.WaitingProcess,
      },
    ],
  });

  // Read the active tab, payment code from url params
  React.useEffect(() => {
    if (codeParam) {
      setSearchFilter(codeParam);
    }
  }, [codeParam]);

  const onSearch = (value: string) => {
    setCurrent(1);
    setIsSearch(value ? true : false);
    setSearchFilter(value);
  };

  React.useEffect(() => {
    setCurrent(1);
    setIsSearch(filters && filters.length > 0);
  }, [filters]);

  const setSearchFilter = (value: any) => {
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  return (
    <div className="list-content">
      <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
        <SearchBox
          placeholder={t("payments.fields.searchPlaceHolder")}
          onSearch={onSearch}
          style={{ width: 304 }}
          value={paymentCode}
          onChange={(value) => {
            setPaymentCode(value.target.value);
          }}
        />
        {filterByWorkIdType && (
          <Select
            options={WORKID_TYPE_OPTIONS.map((op) => ({
              ...op,
              label: t(op.label),
            }))}
            allowClear
            style={{ width: 200, marginBottom: 16 }}
            bordered={false}
            placeholder={t("prepays.fields.caseCode")}
            onChange={(e) => {
              setFilters([
                {
                  field: "caseType",
                  operator: "eq",
                  value: e,
                },
              ]);
            }}
          />
        )}
      </div>
      <Radio.Group
        defaultValue={ApprovalActionStatus.WaitingProcess}
        buttonStyle="solid"
        onChange={(e) => {
          setFilters([
            {
              field: "status",
              operator: "eq",
              value: e?.target?.value === -1 ? "" : e?.target?.value,
            },
          ]);
        }}
        style={{ display: "block", marginBottom: 16 }}
      >
        <Radio.Button value={-1}>{t("common.all")}</Radio.Button>
        <Radio.Button value={ApprovalActionStatus.WaitingProcess}>
          {t("common.status.processing")}
        </Radio.Button>
        <Radio.Button value={ApprovalActionStatus.Approved}>
          {t("common.status.approved")}
        </Radio.Button>
        <Radio.Button value={ApprovalActionStatus.Returned}>
          {t("common.status.returned")}
        </Radio.Button>
        <Radio.Button value={ApprovalActionStatus.Rejected}>
          {t("common.status.reject")}
        </Radio.Button>
      </Radio.Group>

      <Table
        loading={isLoading || isRefetching}
        dataSource={dataTable?.data}
        rowKey="requestId"
        locale={{
          emptyText: <EmptyData />,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`approval/${record.requestId}`);
            },
          };
        }}
        pagination={{
          pageSize: pageSize,
          current: current,
          total: dataTable?.total,
          showSizeChanger: true,
          onChange: (cur, size) => {
            setCurrent(cur);
            setPageSize(size);
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
          },
          showTotal: (total) => (
            <p>{t("common.totalPage", { total: total })}</p>
          ),
          locale: {
            items_per_page: t("common.pageNumber"),
          },
        }}
        onChange={(_, f, s) => {
          const newSort = s as any;
          let sorterField = "";
          switch (newSort?.field) {
            case "employee":
              sorterField = "employee.fullName";
              break;
            case "department":
              sorterField = "department.name";
              break;
            default:
              sorterField = newSort?.field;
              break;
          }
          setSorter([
            {
              field: sorterField,
              order: newSort?.order,
            },
          ]);
        }}
        showSorterTooltip={false}
        scroll={{ x: "1660px" }}
      >
        <Table.Column
          key="id"
          dataIndex="id"
          title={t("common.no")}
          render={(_, __, index) => (current - 1) * pageSize + index + 1}
          width={80}
        />

        <Table.Column
          dataIndex="requestCode"
          title={t("payments.fields.code")}
          width={160}
          render={(value) => (
            <DataText value={value} defaultValue={"-"} className="primary" />
          )}
          defaultSortOrder={getDefaultSortOrder("code", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="case"
          title={t("prepays.fields.caseCode")}
          width={150}
          render={(value) => <DataText value={value?.code} defaultValue="-" />}
          sorter
        />
        {!hiddenColumn && (
          <Table.Column
            dataIndex="requestName"
            title={t("payments.fields.name")}
            defaultSortOrder={getDefaultSortOrder("requestName", sorter)}
            sorter
            width={220}
            render={(value) => <TextField value={value} />}
          />
        )}

        <Table.Column
          dataIndex="status"
          title={t("payments.fields.processStatus")}
          width={160}
          render={(value) => <ProcessApprovalStatusLabel status={value} />}
        />

        <Table.Column
          dataIndex="createdDate"
          width={220}
          title={t("common.createdDate")}
          defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
          sorter
          render={(value) => value && <DateTimeFieldCustom value={value} />}
        />

        <Table.Column
          dataIndex="receivedTime"
          title={t("payments.fields.receivedTime")}
          defaultSortOrder={getDefaultSortOrder("receivedTime", sorter)}
          sorter
          render={(value) =>
            value ? (
              <DateTimeFieldCustom value={value} />
            ) : (
              <DataText defaultValue="-" />
            )
          }
          width={220}
        />

        <Table.Column<IApprovalDetailBase>
          dataIndex="submitterCode"
          width={220}
          title={t("payments.fields.submitter")}
          render={(value, record) => {
            const submiter =
              record?.submitterCode || record?.submitterName
                ? `${record?.submitterCode} - ${record?.submitterName}`
                : "";
            return <DataText value={submiter} defaultValue="-" />;
          }}
          sorter
          defaultSortOrder={getDefaultSortOrder("submitterCode", sorter)}
        />

        {!hiddenColumn && (
          <Table.Column
            dataIndex="department"
            title={t("payments.fields.department")}
            defaultSortOrder={getDefaultSortOrder("department", sorter)}
            render={(value) => (
              <DataText value={value?.name} defaultValue="-" />
            )}
            sorter
            width={220}
          />
        )}
      </Table>
    </div>
  );
};
export default ApprovalTable;
