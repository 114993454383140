import { useMsal } from "@azure/msal-react";
import { AntdLayout } from "@pankod/refine-antd";
import { PageLoading } from "components/layout/pageLoading";
import { EXTERNAL_LOGIN } from "configs/storage";
import jwtDecode from "jwt-decode";
import React from "react"
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
export const ExternalLoginCallback = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const location = useLocation()
    

    function getHashValue(key: string) {
        var matches = location.hash.match(new RegExp(key+'=([^&]*)'));
        return matches ? matches[1] : null;
      }
    

    React.useEffect(() => {
        const historyStorage = JSON.parse(localStorage.getItem(EXTERNAL_LOGIN) || "{}")
        let redirectUrl = ''
        if (historyStorage && historyStorage.page) {
            redirectUrl = `/${historyStorage.page}?callback=${historyStorage.type}`
            const hashToken = location.hash
            if (historyStorage.token) {
                redirectUrl += `&token=${historyStorage.token}`
            }
            if (hashToken) {
                redirectUrl += hashToken
            }
            
            // Get Home Account ID (active user in logged account list) from the client info token on URL
            const clientInfo = getHashValue("client_info");
            if (clientInfo) {
                const tokenClaims = jwtDecode(clientInfo, {header: true}) as any
                const homeAccountId = `${tokenClaims.uid}.${tokenClaims.utid}`
                historyStorage.homeAccountId = homeAccountId
                localStorage.setItem(EXTERNAL_LOGIN, JSON.stringify(historyStorage))
            }
        }
        // redirect to the login/reset-password page
        navigate(redirectUrl)
    }, [])

    return (
        <AntdLayout>
            <PageLoading isLoading={true} />
        </AntdLayout>
    )
}
export default ExternalLoginCallback