import { createContext, useState, useContext } from "react";
import { ILocation } from 'api/types'

type ContextType = {
    provinces: ILocation[];
    districts: {[key: string]: ILocation[]};
    wards: {[key: string]: ILocation[]};
    getProvinceName: (districtId: string) => string;
    getDistrictName: (districtId: string, data: ILocation[]) => string;
    getWardName: (districtId: string, data: ILocation[]) => string;
    setProvinces: (data: ILocation[]) => void,
    setDistricts: (data: {[key: string]: ILocation[]}) => void,
    setWards: (data: {[key: string]: ILocation[]}) => void,
    getDistrictsByProvinceId: (provinceId: string) => ILocation[],
    getWardsByDistrictId: (provinceId: string) => ILocation[]
}

const Context = createContext<ContextType | string>('useLocationData should be used inside LocationsProvider');


export const LocationsProvider = ({ children } : { children: any }) => {
    const [provinces, setProvinces] = useState<ILocation[]>([]);
    const [districts, setDistricts] = useState<{ [key: string]: ILocation[] }>({});
    const [wards, setWards] = useState<{ [key: string]: ILocation[] }>({});

    const getDistrictsByProvinceId = (provinceId: string): ILocation[] => {
        const data = districts[provinceId]
        return data || []
    }

    const getWardsByDistrictId = (provinceId: string): ILocation[] => {
        const data = wards[provinceId]
        return data || []
    }

    const getProvinceName = (provinceId: string): string => {
        const item = provinces.find(item => item.value === provinceId)
        return item?.label || ''
    }

    const getDistrictName = (districtId: string, data: ILocation[]): string => {
        const item = data.find(item => item.value === districtId)
        return item?.label || ''
    }

    const getWardName = (wardId: string, data: any[]): string => {
        const item = data.find(item => item.value === wardId)
        return item.label || ''
    }

    const value: ContextType = {
        provinces, setProvinces,
        districts, setDistricts,
        wards, setWards,
        getDistrictsByProvinceId,
        getWardsByDistrictId,
        getProvinceName,
        getDistrictName,
        getWardName,
        
    };

    return <Context.Provider {...{ value, children }} />;
};

export function useLocationData(): ContextType {
    const c = useContext(Context);
    if (typeof c === 'string') {
      throw new Error(c);
    }
    return c;
  }
