export default interface Project {
  code: string;
  startDate: Date;
  endDate: Date;
  contactCustomerName: string;
  contactCustomerPhone: string;
  contactCustomerEmail: string;
  totalLaborCost: number;
  totalPrice: number;
  totalProjectValue: number;
  case: {
    id: string;
    code: string;
    name: string;
  };
  projectStatus: {
    projectStatusTranslator: ProjectStatusTranslator[];
    id: string;
    code: ProjectStatusCode;
    name: string;
  };
  projectMember: ProjectMember[];
  projectMemberTypeConsulting: ProjectMember[];
  projectMemberTypeImplementation: ProjectMember[];
  createdDate: Date;
  createdName: string;
  updatedDate: Date;
  updatedName: string;
  id: string;
}

export type ProjectMember = {
  employeeId: string;
  employee: {
    id: string;
    code: string;
    name: string;
  };
  department: {
    id: string;
    code: string;
    name: string;
  };
  title: {
    id: string;
    code: string;
    name: string;
  };
  projectRole: {
    projectRoleTranslator: string;
    id: string;
    code: string;
    name: string;
  };
};

export type ProjectStatusTranslator = {
  languageId: string;
  name: string;
  code2: string;
};

export enum ProjectStatusCode {
  Project_Not_Started = "Project_Not_Started",
  Project_In_Progress = "Project_In_Progress",
  Project_Pending = "Project_Pending",
  Project_Off_Track = "Project_Off_Track",
  Project_Waiting_Confirm = "Project_Waiting_Confirm",
  Project_Completed = "Project_Completed",
  Project_Cancelled = "Project_Cancelled",
  Project_Close = "Project_Close",
}
