import { notification } from "@pankod/refine-antd";
import {
  useDelete,
  useNavigation,
  useShow,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { API_PATH, PATH } from "configs/path";
import usePermissions from "hooks/permission";
import Customer, { CustomerStatus } from "interfaces/Customer";
import { CSSProperties, useCallback, useMemo, useState } from "react";

export const useController = () => {
  const translate = useTranslate();
  const { list } = useNavigation();
  const { queryResult } = useShow<Customer>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { mutate: mutateUpdate, isLoading: isUpdateLoading } = useUpdate();
  const { checkEditHaveHelperText, checkDeleteHaveHelperText } =
    usePermissions();
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleModalActivate, setVisibleModalActivate] = useState(false);
  const { mutate: mutateDelete, isLoading: isDeleteLoading } = useDelete();

  const toggleModalDelete = () => setVisibleModalDelete((prev) => !prev);

  const toggleModalActivate = () => setVisibleModalActivate((prev) => !prev);

  const isDraft = useMemo(
    () => record?.status === CustomerStatus.DRAFT,
    [record?.status]
  );

  const isAssigned = useMemo(
    () => record?.status === CustomerStatus.ASSIGNED,
    [record?.status]
  );

  const renderStatus = useCallback(
    (value: CustomerStatus = CustomerStatus.DRAFT) => {
      let agr: { color: string; style?: CSSProperties } = { color: "success" };
      switch (value) {
        case CustomerStatus.DRAFT:
          agr.color = "warning";
          break;
        case CustomerStatus.UNASSIGNED:
          agr.color = "processing";
          break;
        case CustomerStatus.ASSIGNED:
          agr.color = "success";
          break;
        default:
          agr.color = "success";
          break;
      }

      return agr;
    },
    []
  );

  const onActive = () => {
    mutateUpdate(
      {
        resource: PATH.customers,
        id: record?.id!,
        values: {},
        metaData: { type: "/activate" },
      },
      {
        onError: (error, variables, context) => {
          notification.error({
            message: translate("customer.notifycation.activeCustomerFailed"),
            description: error.message,
          });
          toggleModalActivate();
        },
        onSuccess: (data, variables, context) => {
          toggleModalActivate();
          notification.success({
            message: translate(
              "customer.notifycation.activateCustomerSuccessful"
            ),
          });
        },
      }
    );
  };

  const onDelete = () => {
    mutateDelete(
      {
        resource: PATH.customers,
        id: record?.id!,
        metaData: {
          url: API_PATH.deleteCustomer,
        },
      },
      {
        onError: (error, variables, context) => {
          notification.error({
            message: translate("customer.notifycation.deleteCustomerFailed"),
            description: error.message,
          });
          toggleModalDelete();
        },
        onSuccess: (data, variables, context) => {
          notification.success({
            message: translate(
              "customer.notifycation.deleteCustomerSuccessful"
            ),
          });
          list(PATH.customers);
        },
      }
    );
  };

  return {
    isLoading,
    record,
    isDraft,
    isAssigned,
    visibleModalDelete,
    isDeleteLoading,
    visibleModalActivate,
    isUpdateLoading,
    toggleModalDelete,
    checkEditHaveHelperText,
    checkDeleteHaveHelperText,
    toggleModalActivate,
    renderStatus,
    onDelete,
    onActive,
  };
};
