import React from "react";
import {
  getDefaultSortOrder,
  Icons,
  List,
  Table,
  TextField,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useTranslate,
  useTable,
  useNavigation,
} from "@pankod/refine-core";
import { EmptyData, SearchBox, ButtonTooltip } from "components";
import { PATH } from "configs/path";
import { IRole } from "interfaces";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import usePermissions from "hooks/permission";

export const RoleList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { edit, show, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);
  const {
    tableQueryResult: { data: dataTable },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    setFilters,
    sorter,
    setSorter,
  } = useTable<any>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const onSearch = (value: string) => {
    setCurrent(1);
    setIsSearch(value ? true : false);
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };
  return (
    <>
      <List
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.roles)}
            {...checkCreateHaveHelperText(PATH.roles)}
          >
            {translate("roles.titles.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <SearchBox
            placeholder={translate("roles.searchPlaceHolder")}
            onSearch={onSearch}
            style={{ width: 304 }}
          />
          <Table
            dataSource={dataTable?.data}
            rowKey="code"
            locale={{
              emptyText: (
                <EmptyData
                  text={isSearch ? translate("common.noDataSearch") : ""}
                />
              ),
            }}
            onRow={(record) => {
              return {
                onClick: () => show(PATH.roles, record.id),
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: translate("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{translate("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, f, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field: newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            showSorterTooltip={false}
          >
            <Table.Column
              key="id"
              dataIndex="id"
              title={translate("common.no")}
              render={(_, __, index) => (current - 1) * pageSize + index + 1}
            />
            <Table.Column
              dataIndex="code"
              title={translate("roles.fields.code")}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              width="140px"
              sorter
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="name"
              title={translate("roles.fields.name")}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="updatedName"
              title={translate("common.updatedUsername")}
              render={(value) => (
                <TextField className="primary" value={value} />
              )}
            />
            <Table.Column
              dataIndex="updatedDate"
              title={translate("common.updatedDate")}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              width="200px"
              sorter
              render={(value) => value && <DateTimeFieldCustom value={value} />}
            />
            <Table.Column
              dataIndex="createdName"
              title={translate("common.createdUsername")}
              render={(value) => (
                <TextField className="primary" value={value} />
              )}
            />
            <Table.Column
              dataIndex="createdDate"
              title={translate("common.createdDate")}
              defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
              sorter
              render={(value) => value && <DateTimeFieldCustom value={value} />}
            />
            <Table.Column<IRole>
              title={translate("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => (
                <ButtonTooltip
                  type="text"
                  icon={<Icons.EditOutlined />}
                  className="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    edit(PATH.roles, record.id);
                  }}
                  {...checkEditHaveHelperText(PATH.roles)}
                />
              )}
            />
          </Table>
        </div>
      </List>
    </>
  );
};
