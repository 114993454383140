import { useMemo } from "react";
import n2words from "n2words";
import { useGetLocale, useTranslate } from "@pankod/refine-core";
import { capitalize } from "lodash";
import { CurrencyType } from "api/enums";
import { generateCurrency } from "components/Currency";

interface Props {
  num: number;
  currency: CurrencyType;
}

export const useNumToWord = (props: Props) => {
  const translate = useTranslate();
  const locale = useGetLocale();
  const curLang = locale();
  const { num, currency } = props;

  const getCurrencyText = useMemo(
    () => translate(generateCurrency(currency).text),
    [currency, translate]
  );

  return {
    text: `${capitalize(n2words(num, { lang: curLang }))} ${getCurrencyText}${
      curLang !== "vi" && num > 1 ? "s" : ""
    }`,
  };
};
