import { Col, Form, Row, Typography } from "@pankod/refine-antd";
import { useOne, useTranslate } from "@pankod/refine-core";
import { useSearchParams } from "@pankod/refine-react-router-v6";
import { SelectCustom } from "components";
import CCollapse, { CaseInfoPanel } from "components/CCollapse";
import { API_PATH } from "configs/path";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import { ContractStatusCode } from "interfaces/Contract";
import { FC, memo, useContext } from "react";
import { ContractContext, IForm } from "../../..";

interface Props {
  onChangeCase: (value: string) => void;
}

const { Title } = Typography;
const { useWatch, useFormInstance } = Form;

const CaseInfo: FC<Props> = memo((props) => {
  const { isEdit, initialValue } = useContext(ContractContext);

  const form = useFormInstance<IForm>();
  const caseId = useWatch("caseId", form);
  const { onChangeCase } = props;
  const translate = useTranslate();
  const { data: caseData, isFetching } = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(caseId),
    id: "",
    queryOptions: { enabled: !!caseId },
  });

  const caseDetail = caseData?.data;
  const params = useSearchParams();
  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Thông tin vụ việc")}</Title>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="caseId"
            label="Vụ việc"
            initialValue={params[0].get("caseId")}
            rules={[{ required: true, message: translate("errors.ER005") }]}
            className={caseId ? "" : "m-0"}
          >
            <SelectCustom
              resource={API_PATH.contractsCaseDropdownlist}
              optionLabel="label"
              optionValue="value"
              placeholder={translate("Chọn vụ việc")}
              style={{ color: "#000" }}
              allowClear
              onChange={onChangeCase}
              disabled={
                isEdit &&
                initialValue?.contractStatus?.code !== ContractStatusCode.Draft
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item hidden={!caseId} className="m-0">
            <CCollapse
              loading={isFetching}
              hidden={!caseId}
              title={translate("Thông tin vụ việc")}
            >
              <CaseInfoPanel caseInfo={caseDetail} />
            </CCollapse>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
});

export default CaseInfo;
