import { FC, memo } from "react";

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  text: string;
}
export const RequiredLable: FC<Props> = memo((props) => {
  const { text, className = "", ...rest } = props;
  return (
    <label className={` ${className}`} {...rest}>
      <span className="required-mark">*</span>
      {text}
    </label>
  );
});
