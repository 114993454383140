import {
  Button,
  Col,
  Descriptions,
  Divider,
  Icons,
  Radio,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useList,
  useOne,
  useTranslate,
} from "@pankod/refine-core";
import { useNavigate } from "react-router-dom";
import { API_PATH, PATH } from "configs/path";
import { ConfigDisplayText, EmptyData } from "components";
import { WorkflowDiagram } from "components/WorkflowDiagram";
import React, { useCallback, useMemo } from "react";
import {
  IApprovalStepApprover,
  IApprovalStepCondition,
  IApprovalStepConditionItemForApprovalStep,
  IWorkflowData,
  IWorkflowNode,
} from "interfaces";
import {
  diagramMapping,
  edgesMapping,
  findNextStep,
  getColorByNodeType,
  getIconByNodeType,
  getOperator,
  subjectMapping,
} from "components/WorkflowDiagram/diagramHelper";
import {
  CompareDataTypeDecision,
  DisplayType,
  InputType,
  LogicalOperator,
  WorkflowDiagramApplyAcceptanceWhen,
  WorkflowStatus,
  WorkflowStepType,
} from "api/enums";
import { useEdgesState, useNodesState } from "reactflow";
import { v4 as uuid } from "uuid";
import usePermissions from "hooks/permission";
import NoNodeSelected from "components/WorkflowDiagram/DefaultNode/EmptyNode";
import type { ColumnsType } from "antd/es/table";
import { formatISODateTimeToView, formatNumber } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
import { Currency } from "components/Currency";

const { Title, Text, Paragraph } = Typography;

interface WorkflowDiagramShowProps {
  workflowId: any;
  workflow?: IWorkflowData;
}

export const WorkflowDiagramShow: React.FC<
  IResourceComponentsProps & WorkflowDiagramShowProps
> = (props) => {
  const { workflowId, workflow } = props;
  const t = useTranslate();
  const navigate = useNavigate();
  const { checkEdit, checkCreate } = usePermissions();

  const queryResult = useOne<any>({
    id: workflowId,
    resource: PATH.workflowsDiagrams,
    metaData: {
      after: "/by-approval-procedure",
    },
  });

  const { data } = queryResult;

  const queryResultAttributeCondition = useList({
    resource: API_PATH.approvalFetureConditionAttr,
    metaData: {
      after: `?featureId=${workflow?.feature?.featureId}`,
    },
    queryOptions: {
      enabled: !!workflow?.feature?.featureId,
    },
  });

  const steps: IWorkflowNode[] = data?.data ?? [];
  const [nodes, setNodes] = useNodesState([]);
  const isEmptySteps = !steps || steps.length === 0;

  const [edges, setEdges] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = React.useState<any>();
  const selectedStep = selectedNode?.data;
  const nextStep = findNextStep(
    nodes,
    selectedStep?.defaultNextStep?.value ?? ""
  )?.data;
  const yesStep = findNextStep(
    nodes,
    selectedStep?.defaultYesStep?.value ?? ""
  )?.data;
  const noStep = findNextStep(
    nodes,
    selectedStep?.defaultNoStep?.value ?? ""
  )?.data;

  React.useEffect(() => {
    if (steps.length) dataMapping();
  }, [steps]);

  const dataMapping = () => {
    const stepData = diagramMapping(steps);
    const edges = edgesMapping(steps);
    setNodes(stepData as any[]);
    setEdges(edges);
  };

  const onChangeNode = (nodes: any, edges: any) => {
    if (nodes && nodes.length > 0) {
      setSelectedNode(nodes[0]);
    } else {
      setSelectedNode(undefined);
    }
  };

  const getAttributeCondition = useCallback(
    (record: IApprovalStepConditionItemForApprovalStep) =>
      queryResultAttributeCondition?.data?.data?.find(
        (e) => e.id === record?.approvalFeatureConditionAttrId
      ) as IApprovalStepConditionItemForApprovalStep,
    [queryResultAttributeCondition]
  );

  const renderStepButton = (step?: IWorkflowNode) => {
    if (!step) {
      return <></>;
    }

    const headerColor = getColorByNodeType(step.type);

    const headerIcon = getIconByNodeType(step.type);

    return (
      <Tooltip title={step?.name} className="default-cursor">
        <Button
          disabled
          style={{ backgroundColor: headerColor, color: "black" }}
          icon={headerIcon}
        >
          <Text style={{ maxWidth: 100 }} ellipsis>
            {step?.name}
          </Text>
        </Button>
      </Tooltip>
    );
  };

  const mappingSubjects = (step: IWorkflowNode | undefined) => {
    let subjectData: IApprovalStepApprover[] = [];

    if (step?.isLineManagerApprove) {
      subjectData.push({
        subjectType: t("workflows.directManager"),
        displayName: t("workflows.directManager"),
        subjectTypeName: t("workflows.directManager"),
        value: uuid().toString(),
        id: "",
      });
    }

    if (step?.approvalStepApprovers) {
      subjectData = [
        ...subjectData,
        ...(subjectMapping(step.approvalStepApprovers) ?? []),
      ];
    }
    return subjectData;
  };

  const subjectColumns: any[] = [
    {
      title: t("workflows.subject"),
      dataIndex: "displayName",
      width: "50%",
      key: "displayName",
    },
    {
      title: t("workflows.subjectType"),
      dataIndex: "subjectTypeName",
      key: "subjectTypeName",
    },
  ];

  const renderActionText = () => {
    let result: string[] = [t("workflows.fields.approve")];
    if (selectedStep) {
      if (selectedStep.allowEdit) {
        result.push(t("workflows.fields.modify"));
      }
      if (selectedStep.allowReject) {
        result.push(t("workflows.fields.reject"));
      }
      if (selectedStep.allowReturn) {
        result.push(t("workflows.fields.return"));
      }
    }
    return result.join(" - ");
  };

  const subjectList = mappingSubjects(selectedStep);

  const disabledCreate =
    workflow?.status === WorkflowStatus.Published ||
    !checkCreate(PATH.workflowsDiagrams);
  const disabledEdit =
    workflow?.status === WorkflowStatus.Published ||
    !checkEdit(PATH.workflowsDiagrams) ||
    (workflow?.appliedRecord || 0) > 0;

  const decisionConditionColumn = useMemo<
    ColumnsType<IApprovalStepConditionItemForApprovalStep>
  >(
    () => [
      {
        title: t("Đối tượng"),
        dataIndex: "approvalFeatureConditionAttrId",
        key: "approvalFeatureConditionAttrId",
        render: (_, record) => {
          return (
            <ConfigDisplayText value={getAttributeCondition(record)?.name} />
          );
        },
      },
      {
        title: t("Toán tử"),
        dataIndex: "compareOperator",
        key: "compareOperator",
        render: (_, record) => {
          return (
            <ConfigDisplayText
              value={
                getOperator(record?.dataType)?.find(
                  (item) => item.value === record?.compareOperator
                )?.label
              }
            />
          );
        },
      },
      {
        title: t("Giá trị"),
        dataIndex: "compareValue",
        key: "compareValue",
        render: (_, record) => {
          const attr = getAttributeCondition(record);

          if (attr.inputType === InputType.Selection) {
            const optionSelected =
              attr?.approvalFeatureConditionAttrOptions?.find(
                (e) => e.value === record?.compareValue
              );

            return <ConfigDisplayText value={optionSelected?.name} />;
          }

          switch (attr.displayType) {
            case DisplayType.Number:
              return <Currency value={record?.compareValue} />;

            case DisplayType.Decimal:
              return (
                <Currency
                  value={record?.compareValue}
                  showCurrency={false}
                  decimalLength={2}
                />
              );

            case DisplayType.Percent:
              return (
                <Currency
                  value={record?.compareValue * 100}
                  showCurrency={false}
                  customValueShow={(value) => `${formatNumber(value, 2)}%`}
                />
              );

            default:
              return <ConfigDisplayText value={record?.compareValue} />;
          }
        },
      },
    ],
    [getAttributeCondition, t]
  );

  const renderDataNode = () => {
    switch (selectedStep?.type) {
      case WorkflowStepType.Normal:
        return (
          <Row gutter={[8, 16]} style={{ marginTop: 12 }}>
            <Col span={24}>
              <Text style={{ fontWeight: 500 }}>
                {t("workflows.subjectList")}
              </Text>
              <Table
                size="small"
                columns={subjectColumns}
                dataSource={subjectList}
                pagination={{
                  pageSize: Number.MAX_VALUE,
                  hideOnSinglePage: true,
                }}
              />
            </Col>
            <Col span={24}>
              <Text style={{ fontWeight: 500 }}>{t("workflows.action")}</Text>
              <Paragraph>{renderActionText()}</Paragraph>
            </Col>

            <Col span={24}>
              <Text style={{ fontWeight: 500 }}>
                {t("workflows.fields.approveConditionDetail")}
              </Text>
              <Paragraph>
                {selectedStep.applyAcceptanceWhen ===
                WorkflowDiagramApplyAcceptanceWhen.OnlyOne
                  ? t("workflows.fields.onlyOneApprove")
                  : t("workflows.fields.allApprove")}
              </Paragraph>
            </Col>
          </Row>
        );

      case WorkflowStepType.Decision:
        const {
          approvalStepConditionItemForApprovalStep = [],
          logical = LogicalOperator.AND,
        } =
          (selectedStep?.approvalStepConditionForApprovalStep as IApprovalStepCondition) ||
          {};
        return (
          <div style={{ marginTop: 12, gap: 12 }} className="flex flex-col">
            <div style={{ gap: 24 }} className="flex items-center">
              <span>Điều kiện kết hợp</span>
              <Radio.Group value={logical}>
                <Radio value={LogicalOperator.AND}>AND</Radio>
                <Radio value={LogicalOperator.OR}>OR</Radio>
              </Radio.Group>
            </div>
            <Table
              pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
              rowKey="id"
              columns={decisionConditionColumn}
              dataSource={approvalStepConditionItemForApprovalStep}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Descriptions
        column={3}
        title={t("workflows.workflowDetail")}
        layout="vertical"
        extra={
          <Button
            disabled={isEmptySteps ? disabledCreate : disabledEdit}
            icon={
              isEmptySteps ? <Icons.SettingOutlined /> : <Icons.EditOutlined />
            }
            type="primary"
            onClick={() =>
              navigate(
                `${PATH.workflowsDiagrams}/${
                  isEmptySteps ? "create" : "edit"
                }/${props.workflowId}`
              )
            }
          >
            {isEmptySteps
              ? t("workflows.actions.setupWorkflow")
              : t("workflows.actions.editWorkflow")}
          </Button>
        }
      >
        {isEmptySteps && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EmptyData
              text={t("workflows.noDiagram")}
              image={<img width={240} src="/images/no-data-1.svg" />}
              style={{ padding: 40 }}
            />
          </div>
        )}
      </Descriptions>
      <Divider
        style={{ marginTop: 0, marginBottom: 12 }}
        orientationMargin={0}
        type="horizontal"
      />
      {!isEmptySteps && (
        <>
          <Title level={5}>{t("workflows.titles.diagram")}</Title>
          {!!nodes.length && (
            <div style={{ height: "50vh" }}>
              <WorkflowDiagram
                onNodeClick={(_, node) => {
                  if (node.id !== selectedNode?.id) {
                    setSelectedNode(node);
                  }
                  setNodes((nds) =>
                    nds.map((n) => {
                      return {
                        ...n,
                        selected: n.id === node.id ? true : false,
                      };
                    })
                  );
                }}
                onChangeEdgeWhenHover={(nodes, edges) => {
                  setNodes(nodes);
                  setEdges(edges);
                }}
                nodes={nodes}
                edges={edges}
                references={[]}
                nodesDraggable={false}
                fitView
              />
            </div>
          )}
          <Divider style={{ margin: 0 }} />
          <div style={{ padding: 24 }}>
            {!selectedNode && <NoNodeSelected />}
            {selectedNode && (
              <>
                <Title level={5}>{t("workflows.titles.diagramInfo")}</Title>
                <Row gutter={[8, 16]}>
                  <Col span={24}>
                    <Space>
                      <Text>{t("workflows.fields.stepName")}</Text>
                      {renderStepButton(selectedStep)}
                    </Space>
                  </Col>
                  {selectedStep?.defaultNextStep?.value && (
                    <Col span={24}>
                      <Space>
                        <Text>{t("workflows.nextApprovalStep")}</Text>
                        {renderStepButton(nextStep)}
                      </Space>
                    </Col>
                  )}
                  {selectedStep?.defaultYesStep?.value && (
                    <Col span={24}>
                      <Space>
                        <Text>{t("Bước đến khi thỏa mãn điều kiện")}</Text>
                        {renderStepButton(yesStep)}
                      </Space>
                    </Col>
                  )}
                  {selectedStep?.defaultNoStep?.value && (
                    <Col span={24}>
                      <Space>
                        <Text>
                          {t("Bước đến khi không thỏa mãn điều kiện")}
                        </Text>
                        {renderStepButton(noStep)}
                      </Space>
                    </Col>
                  )}
                </Row>
                {renderDataNode()}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
