import { FC, useState } from "react";
import AssignExploitingForm from "./components/Form";
import { CreateCustom } from "components/layout";
import {
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { Icons, Typography, notification, useForm } from "@pankod/refine-antd";
import { PATH } from "configs/path";
import { mappingErrorFromApi } from "utils/commons";

const { Title } = Typography;

const AssignExploitingsCreate: FC<IResourceComponentsProps> = (props) => {
  const { form, formProps, saveButtonProps, formLoading } = useForm<any>();
  const { mutate: mutateCreate, isLoading } = useCreate<{ id: string }, any>();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();
  const { show } = useNavigation();
  const translate = useTranslate();
  const [visibleModalConfirmCreate, setVisibleShowModalConfirm] =
    useState(false);

  const isEdit = !!formProps.initialValues?.id;

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleShowModalConfirm(isOpen);

  const onSubmit = () => {
    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: translate(
            isEdit ? "Cập nhật thành công" : "Tạo mới thành công"
          ),
        });
        show(PATH.assignExploitings, res?.data?.id);
      },
      onError: (error: any) => {
        toggleModalConfirm(false);

        notification.error({
          message: translate(isEdit ? "Cập nhật thất bại" : "Tạo mới thất bại"),
          description:
            !!error?.errors.length &&
            translate(
              `errors.${error?.errors?.[0]?.errorCode}`,
              error?.errors?.[0]?.value
            ),
        });
        const errorFieldMapping: any = {};

        mappingErrorFromApi(error, form, errorFieldMapping);
      },
    };

    const dataForm: any = form.getFieldsValue();

    const products = dataForm.products
      ? Object.values(dataForm.products).map((prod: any) => ({
          baseID: prod.id,
        }))
      : null;

    const customers = dataForm.customers
      ? Object.values(dataForm.customers).map((customer: any) => ({
          baseID: customer.id,
        }))
      : null;

    const dataToRequest = {
      resource: PATH.assignExploitings,
      values: {
        assignExploitingType: dataForm.assignExploitingType,
        baseIDs: dataForm.assignExploitingType === 2 ? products : customers,
        employeeID: dataForm.employeeID,
      },
      id: formProps.initialValues?.id || "",
    };

    if (isEdit) {
      mutateUpdate(dataToRequest, handleResponse);
    } else {
      mutateCreate(dataToRequest, handleResponse);
    }
  };

  return (
    <CreateCustom
      {...props}
      title={translate(
        isEdit ? "assignExploiting.edit" : "assignExploiting.create"
      )}
      onSubmit={onSubmit}
      visibleShowModal={visibleModalConfirmCreate}
      setVisibleShowModal={toggleModalConfirm}
      saving={isLoading || isLoadingUpdate}
      confirmModalProps={{
        title: translate(
          isEdit
            ? "assignExploiting.editConfirm"
            : "assignExploiting.createConfirm"
        ),
      }}
      saveButtonProps={{
        ...saveButtonProps,
        title: translate(isEdit ? "common.saveEdit" : "actions.create"),
        icon: isEdit && <Icons.SaveOutlined />,
      }}
      isLoading={formLoading}
    >
      <Title level={4}>{translate("common.titles.detail")}</Title>
      <AssignExploitingForm
        form={form}
        formProps={formProps}
        onFinishedFormValidate={() => toggleModalConfirm(true)}
      />
    </CreateCustom>
  );
};

export default AssignExploitingsCreate;
