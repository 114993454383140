import { Form, notification, useForm, useSelect } from "@pankod/refine-antd";
import { useTranslate, useUpdate, Option } from "@pankod/refine-core";
import { API_PATH, PATH } from "configs/path";
import Customer, { ExploitingType } from "interfaces/Customer";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

export interface Props {
  record?: Customer;
  visibleModal: boolean;
  toggleModal: () => void;
}

interface IForm {
  employeeInAssigned: {
    [key: string]: {
      assignExloitingType: ExploitingType;
      employeeId: string;
      employeeName: string;
    };
  };
}

const { useWatch } = Form;

export const useController = (props: Props) => {
  const translate = useTranslate();
  const { toggleModal, record, visibleModal } = props;
  const form = useForm<any, any, IForm>();

  const [list, setList] = useState([uuid()]);

  const { mutate } = useUpdate();

  const { selectProps: employeeSelectProps } = useSelect({
    resource: API_PATH.employeesForAssignCustomer,
    optionLabel: "label",
    optionValue: "id",
    metaData: {
      formatData: (r: any) => ({ ...r, label: `${r?.code} - ${r?.fullName}` }),
    },
    onSearch: (value) => [
      {
        field: "q",
        operator: "containss",
        value: visibleModal ? value : undefined,
      },
    ],
  });

  const { selectProps: typeSelectProps } = useSelect({
    resource: API_PATH.customerAssignExploitingTypeDropdownlist,
    optionLabel: "name",
    optionValue: "id",
    onSearch: () => [
      {
        field: "q",
        operator: "containss",
        value: undefined,
      },
    ],
  });

  const employeeInAssigned = useWatch("employeeInAssigned", form.form);

  const emplyeeIdSelecteds = useMemo(
    () =>
      employeeInAssigned
        ? Object.values(employeeInAssigned).map((emp) => emp.employeeId)
        : [],
    [employeeInAssigned]
  );

  const employeeOptions = useCallback(
    (_: any[], value?: string, options: Option[] = []) =>
      options?.map((o) => ({
        ...o,
        disabled: emplyeeIdSelecteds
          .filter((empId) => empId !== value)
          .includes(o.value),
      })),
    [emplyeeIdSelecteds]
  );

  const exploitingOptions = useCallback<any>(
    (keyRow: string) => {
      const rowHasAm =
        employeeInAssigned?.[keyRow]?.assignExloitingType === ExploitingType.AM;
      const isHasAM = Object?.values(employeeInAssigned || {})?.find(
        (e) => e.assignExloitingType === ExploitingType.AM
      );

      if (!!rowHasAm || !isHasAM) {
        return typeSelectProps?.options;
      }

      return typeSelectProps?.options?.filter(
        (op) => op.value !== ExploitingType.AM
      );
    },
    [employeeInAssigned, typeSelectProps]
  );

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const onSubmit = (data: IForm) => {
    const employeeInAssigned = Object.values(data?.employeeInAssigned || {});
    if (!employeeInAssigned.length && !record?.employeeAssigned?.length) {
      toggleModal();
      return;
    }
    mutate(
      {
        resource: PATH.customers,
        id: "",
        values: {
          employeeInAssigned,
          customerId: record?.id,
        },
        metaData: { type: "/assigned-to-employee", method: "POST" },
      },
      {
        onError: (error, variables, context) => {
          notification.error({
            message: translate("customer.assignFailed"),
            description: error.message,
          });
          toggleModal();
        },
        onSuccess: (data, variables, context) => {
          toggleModal();
          notification.success({
            message: translate("customer.assignSuccessful"),
          });
        },
      }
    );
  };

  const onSelectEmployee =
    (keyRow: string) => (_: any, option: any, __: any) => {
      const newList = cloneDeep(employeeInAssigned);
      newList[keyRow] = {
        ...newList[keyRow],
        employeeId: option?.value,
        employeeName: option?.label,
      };
      form.form.setFieldsValue({ employeeInAssigned: newList });
    };

  useEffect(() => {
    if (!visibleModal) {
      form.form.resetFields();
      setList([uuid()]);
    } else {
      if (record?.employeeAssigned?.length) {
        const newList: any = {};
        const ids: string[] = [];
        record?.employeeAssigned?.forEach((emp) => {
          const id = uuid();
          ids.push(id);
          newList[id] = {
            assignExloitingType: emp?.assignExloitingType?.id,
            employeeId: emp?.employee?.id,
            employeeName: `${emp?.employee?.code} - ${emp?.employee?.fullName}`,
          };
        });

        setList(ids);

        setTimeout(
          () => form.form.setFieldsValue({ employeeInAssigned: newList }),
          0
        );
      }
    }
  }, [visibleModal]);

  return {
    props,
    employeeSelectProps,
    typeSelectProps,
    form,
    list,
    exploitingOptions,
    employeeInAssigned,
    employeeOptions,
    translate,
    onSubmit,
    setList,
    onRemoveItem,
    onSelectEmployee,
  };
};
