import { FC } from "react";
import styled from "styled-components";
import CaseInfo from "./CaseInfo";
import General from "./General";
import SubmitConditionsInfo from "./SubmitConditionInfo";
export type SettlementsContract = {
    productName?: string;
}


const InformationStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    `;
const SettlementsContractInfo: FC = (props) => {
    return (
        <InformationStyle>
            <CaseInfo onChangeCase={function (value: string): void {
             }} />
            <General/>
            <SubmitConditionsInfo />
        </ InformationStyle>
    );
}

export default SettlementsContractInfo;