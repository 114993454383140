import { useTranslate } from "@pankod/refine-core";
import { Descriptions, Typography } from "antd";
import { DataText } from "components";
import { FC, memo } from "react";

const { Title } = Typography;

interface QuotProps {
  creater: QuotCreater;
}

interface QuotCreater {
  code: string | undefined;
  fullName: string | undefined;
  phoneNumber: string | undefined;
}

const QuotCreator: FC<QuotProps> = memo((props) => {
  const translate = useTranslate();

  const { creater } = props;

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Thông tin người tạo báo giá")}</Title>
      <Descriptions layout="vertical" column={3}>
        <Descriptions.Item label="Nhân viên kinh doanh" span={1}>
          <DataText value={`${creater?.code ?? ""} - ${creater?.fullName ?? ""}`} />
        </Descriptions.Item>
        <Descriptions.Item style={{ width: 24 }}>{""}</Descriptions.Item>
        <Descriptions.Item label="Số điện thoại" span={1}>
          <DataText value={`${creater?.phoneNumber ?? "-"}`} />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
});

export default QuotCreator;
