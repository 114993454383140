import { Col, Form, Input, notification, Row, Typography, useForm } from "@pankod/refine-antd"
import { IResourceComponentsProps, useCreate, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core"
import InputUpper from "components/InputUpper"
import { CreateCustom } from "components/layout"
import { PATH } from "configs/path"
import { ICostConfig } from "interfaces"
import React from "react"
import { isValidCodeField, isValidConfigCodeField, mappingErrorFromApi } from "utils/commons"

const { Text } = Typography
const { TextArea } = Input

export const TitleCreate: React.FC<IResourceComponentsProps> = (props) => {
	const t = useTranslate()

	const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>(false);
	const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreate<ICostConfig>()
	const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<ICostConfig>()
	const { form, formProps, saveButtonProps } = useForm<ICostConfig>();

	const isEdit = !!formProps.initialValues?.id
	const record = formProps.initialValues
	const { show, list } = useNavigation()

	const onSubmit = () => {
		const handleResponse = {
			onSuccess: (res: any) => {
				notification.success({
					message: isEdit ? t('common.config.editSuccess') : t('common.config.createSuccess'),
				})
				show(PATH.configTitles, res?.data?.id)
			},
			onError: (error: any) => {
				setVisibleShowModal(false)
				const errorFieldMapping: any = {
					ER0130: 'name',
					ER051: 'code'
				}
				mappingErrorFromApi(error, form, errorFieldMapping)
			},
		}

		const dataSend = {
			resource: PATH.configTitles,
			values: {
				name: form.getFieldValue('name').trim(),
				code: form.getFieldValue('code').trim(),
				description: form.getFieldValue('description'),
			},
			id: '',
		}

		if (isEdit) {
			dataSend.id = formProps.initialValues?.id || ''
			mutateUpdate(dataSend, handleResponse)
		} else {
			mutateCreate(dataSend, handleResponse)
		}
	}

	return (
		<CreateCustom
			title={isEdit ? <Text style={{marginBottom: 0}}>{t("titles.titles.edit")}: <Text className="primary">{record?.name}</Text></Text> : t('titles.titles.create')}
			{...props}
			saveButtonProps={{
				...saveButtonProps,
				title: isEdit ? t('common.saveEdit') : t('buttons.add')
			}}
			visibleShowModal={visibleShowModal}
			setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
			onSubmit={() => onSubmit()}
			confirmModalProps={{
				title: !isEdit ? t('titles.createConfirm') : t('common.config.editConfirm'),
				description: isEdit ? t('common.config.editConfirmDesc') : '',
			}}
			saving={isLoadingCreate || isLoadingUpdate}
			cancelModalProps={{
				okButtonProps: {
					onClick: () => {
						isEdit ? show(PATH.configTitles, record?.id) : list(PATH.configTitles)
					},
					danger: true
				},
				cancelButtonProps: {}
			}}
		>
			<Form
				{...formProps}
				onFinish={() => setVisibleShowModal(true)}
				layout="vertical"
				labelCol={{ span: 10 }}
			>
				<div style={{ marginBottom: 12 }}>
					<Text style={{ fontWeight: 500, fontSize: 16 }}>
						{t('common.titles.detail')}{' '}
					</Text>
				</div>

				<Row gutter={12} style={{ width: '100%' }}>
					<Col span={12}>
						<Form.Item
							label={t('titles.fields.code')}
							name="code"
							rules={[
								{
									required: true,
									message: t('errors.ER005'),
								},
								{
									max: 20,
									message: t('errors.ER014', { max: '20' }),
								},
								{
									validator: async (_, value) => {
										if (!value) return;
										if (!isValidConfigCodeField(value)) {
											return Promise.reject(t("errors.ER0139"))
										}
									},
								},
							]}
						>
							<InputUpper
								placeholder={t('titles.fields.codePlaceholder')}
								disabled={isEdit}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							label={t('titles.fields.name')}
							name="name"
							rules={[
								{
									whitespace: true,
									required: true,
									message: t('errors.ER005'),
								},
								{
									max: 100,
									message: t('errors.ER014', { max: '100' }),
								},
							]}
						>
							<Input placeholder={t('titles.fields.namePlaceholder')} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							label={t('costs.fields.description')}
							name="description"
							rules={[
								{
									max: 200,
									message: t('errors.ER014', { max: '200' }),
								},
							]}
						>
							<TextArea
								showCount={{
									formatter: (args: { count: number }) => `${args?.count}/200`,
								}}
								placeholder={t('costs.placeholder.description')}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</CreateCustom>
	)
}