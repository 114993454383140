import { ApprovalStatus } from "api/enums";
import {
  ApprovalApproverDetail,
  SubmitEmployee,
  WaitingProcessEmployees,
} from "interfaces";

export default interface IInternalEstimate {
  objectData: EstimateObjectData;
  approvalData: ApprovalApproverDetail;
}

export type EstimateObjectData = {
  caseId: string;
  case: {
    name: string;
    code: string;
    caseStatus: {
      id: string;
      code: string;
      name: string;
    };
    employee: {
      id: string;
      code: string;
      name: string;
    };
    customer: {
      id: string;
      code: string;
      name: string;
    };
    marketArea: {
      id: string;
      code: string;
      name: string;
    };
    createdDate: string;
    createdName: string;
    updatedDate: string;
    updatedName: string;
    id: string;
  };
  referenceRequestItem: {
    id: string;
    code: string;
    creationDate: string;
    completionDate: string;
    description: string;
    note: string;
    status: number;
    case: null;
    name: string;
    referenceRequestStatus: {
      id: string;
      code: string;
      name: string;
    };
    employeeRequest: {
      id: string;
      code: string;
      name: string;
    };
  };
  listInternalEstimatedCost: EstimateCost[];
  listInternalEstimatedByManDay: EstimateManday[];
  listInternalEstimatedViewDocument: {
    fileId: string;
    fileUrl: string;
    fileUrlOrigin: string;
  }[];
  listInternalEstimatedProduct: EstimateProduct[];
  code: string;
  status: ApprovalStatus;
  estimateType: {
    id: string;
    code: EstimateType;
    name: string;
  };
  startDate: string;
  endDate: string;
  description: string;
  price: number;
  totalCost: number;
  totalLabourCost: number;
  totalByCash: number;
  totalSellingPrice: number;
  totalInternalCost: number;
  totalMargin: number;
  createdDate: string;
  createdName: string;
  updatedDate: string;
  updatedName: string;
  id: string;
  isAllProducts: boolean;
  originalInternalEstimated: {
    id: string;
    code: string;
  };
};

export type RateCard = {
  description: string;
  id: string;
  position: string;
  rateCardByDay: number;
  rateCardByHour: number;
};

export enum EstimateType {
  Estimated_Consulting = "Estimated_Consulting",
  Estimated_Implementation = "Estimated_Implementation",
  Estimated_Implementation_Plan = "Estimated_Implementation_Plan",
}

export enum UnitCode {
  Estimated_Unit_Hour = "Estimated_Unit_Hour",
  Estimated_Unit_Day = "Estimated_Unit_Day",
}

export type Policy = {
  id: string;
  product: {
    id: string;
    name: string;
    code: string;
  };
  estimateMethod: number;
  proportion: number;
  publishedDate: Date;
  orderSort: number;
};

export type EstimatePolicy = {
  internalEstimatedCostsProportion: Policy[];
  internalEstimatedLaborsProportion: Policy[];
};

export enum TabKey {
  INFO = "info",
  ESTIMATE_INFO = "estimate-info",
  HISTORY = "history",
}

export type EstimateProduct = {
  orderSort: number;
  product: {
    id: string;
    code: string;
    name: string;
  };
  partNumber: string;
  description: string;
  quantity: number;
  unitPrice: number;
  unit: {
    id: string;
    code: string;
    name: string;
  };
  sellingPrice: number;
  internalCost: number;
  margin: number;
  id: string;
};

export type EstimateCost = {
  orderSort: number;
  cost: {
    id: string;
    code: string;
    name: string;
  };
  product: {
    id: string;
    code: string;
    name: string;
  };
  proportionPolicy: {
    id: string;
    estimateMethod: false;
    proportion: number;
    publishedDate: string;
  };
  note: string;
  quantity: number;
  unitPrice: number;
  totalAmount: number;
};

export type EstimateManday = {
  orderSort: number;
  internalRateCard: {
    id: string;
    position: string;
    description: string;
    rateCardByHour: number;
    rateCardByDay: number;
  };
  product: {
    id: string;
    code: string;
    name: string;
  };
  proportionPolicy: {
    id: string;
    estimateMethod: false;
    proportion: number;
    publishedDate: string;
  };
  estimatedUnit: {
    code: string;
    id: string;
    name: string;
  };
  unitPrice: number;
  quantity: number;
  totalAmount: number;
  description: string;
};

export type InteralEstimateTableRow = {
  objectData: EstimateObjectData;
  submitEmployee: SubmitEmployee;
  waitingProcessEmployees: WaitingProcessEmployees[];
};
