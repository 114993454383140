import { FC } from "react";
import { useController } from "./controller";
import {
  Button,
  Form,
  Icons,
  List,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { ButtonTooltip, EmptyData, SearchBox } from "components";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { formatISODateTimeToView, searchSelect } from "utils/commons";
import {
  CUSTOMER_STATUS_LABEL,
  CUSTOMER_STATUS_LIST,
  DATE_TIME_FORMAT,
} from "configs/constants";
import Customer, { CustomerStatus } from "interfaces/Customer";
import CTableColumn from "components/CTableColumn";
import { Icon, IconName } from "components/Icon";
import ModalAssign from "./component/ModalAssign";
import { IntegrationStatusTag } from "components/StatusTag/IntegrationStatusTag";

const Column = CTableColumn<Customer>();

const CustomerList: FC = () => {
  const {
    formProps,
    areaSelectProps,
    marketProps,
    form,
    table: {
      tableQueryResult: { data: dataTable, isLoading },
      sorter,
      pageSize,
      current,
      filters,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
    currentRecord,
    visibleModalAssign,
    renderStatus,
    setCurrentRecord,
    toggleModalAssign,
  } = useController();
  const { edit, show, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const translate = useTranslate();

  return (
    <>
      <List
        title={translate("customer.customerList")}
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.customers)}
            {...checkCreateHaveHelperText(PATH.customers)}
          >
            {translate("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Form {...formProps} style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
              <SearchBox
                placeholder={translate("customer.search")}
                onSearch={(e) => {
                  setFilters([
                    {
                      field: "q",
                      operator: "eq",
                      value: e,
                    },
                  ]);
                }}
              />
              <Form.Item name={["region"]} style={{ marginBottom: 16 }}>
                <Select
                  {...areaSelectProps}
                  allowClear
                  onChange={(e) => {
                    setFilters([
                      {
                        field: "region",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                  className={"placeholderBlack"}
                  dropdownStyle={{ color: "black" }}
                  style={{ width: 150 }}
                  bordered={false}
                  placeholder={translate("customer.area")}
                  filterOption={(inputValue: string, option: any) =>
                    option && searchSelect(inputValue, option?.label)
                  }
                />
              </Form.Item>
              <Form.Item name={["marketArea"]} style={{ marginBottom: 16 }}>
                <Select
                  {...marketProps}
                  allowClear
                  onChange={(e) => {
                    setFilters([
                      {
                        field: "marketArea",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                  className={"placeholderBlack"}
                  dropdownStyle={{ color: "black" }}
                  style={{ width: 150 }}
                  bordered={false}
                  placeholder={translate("customer.market")}
                  filterOption={(inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label);
                  }}
                />
              </Form.Item>
              {filters &&
                filters.length > 0 &&
                !(
                  filters.length === 1 && filters[0]?.operator === "containss"
                ) && (
                  <Button
                    type="text"
                    onClick={(e) => {
                      form.resetFields();
                      setFilters([], "replace");
                    }}
                    style={{ color: "#8C8C8C", marginBottom: 16 }}
                  >
                    {translate("common.cleanFilter")}
                    <Icons.CloseOutlined />
                  </Button>
                )}
            </div>

            <Form.Item name={["status"]}>
              <Radio.Group
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => {
                  setCurrent(1);
                  setFilters([
                    {
                      field: "status",
                      operator: "eq",
                      value: e?.target?.value === -1 ? "" : e?.target?.value,
                    },
                  ]);
                }}
              >
                <Radio.Button value={-1}>
                  {translate("common.all")}
                </Radio.Button>
                {CUSTOMER_STATUS_LIST.map(
                  ({ label: csLabel, value: csValue }) => {
                    return (
                      <Radio.Button value={csValue}>
                        {translate(csLabel)}
                      </Radio.Button>
                    );
                  }
                )}
              </Radio.Group>
            </Form.Item>
          </Form>
          <Table
            sticky
            loading={isLoading}
            dataSource={dataTable?.data}
            rowKey="id"
            locale={{
              emptyText: <EmptyData text={translate("common.noDataSearch")} />,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show(PATH.customers, record.id);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: translate("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{translate("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, __, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field:
                    newSort?.field === "department"
                      ? "department.name"
                      : newSort?.field === "title"
                      ? "title.name"
                      : newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            scroll={{ x: "2000px" }}
            showSorterTooltip={false}
          >
            <Column
              key="code"
              dataIndex="code"
              title={translate("customer.customerCode")}
              render={(value, record) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              sorter
            />
            <Column
              key="name"
              dataIndex="name"
              title={translate("customer.customerName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />
            <Column
              key="identifiedTaxCode"
              dataIndex="identifiedTaxCode"
              title={translate("customer.taxCode")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder(
                "identifiedTaxCode",
                sorter
              )}
              sorter
            />
            <Column
              key="province"
              dataIndex="province"
              title={translate("customer.provinceCity")}
              render={(value) => <TextField value={value?.name} />}
              defaultSortOrder={getDefaultSortOrder("province", sorter)}
              sorter
            />
            <Column
              key="status"
              dataIndex="status"
              title={translate("customer.status")}
              render={(_, record) => {
                return (
                  <Tag {...renderStatus(record?.status)}>
                    {translate(
                      CUSTOMER_STATUS_LABEL[
                        record?.status || CustomerStatus.DRAFT
                      ]
                    )}
                  </Tag>
                );
              }}
            />
            <Column
              key="employeeAssigned"
              dataIndex="employeeAssigned"
              title={translate("customer.miningStaff")}
              render={(value, record) => {
                const content = record?.employeeAssigned
                  ?.map((emp) =>
                    emp?.employee?.code && emp?.employee?.fullName
                      ? `${emp?.employee?.code} - ${emp?.employee?.fullName}`
                      : emp?.employee?.code || emp?.employee?.fullName
                  )
                  ?.join(", ");
                return <TextField value={content || "-"} />;
              }}
              sorter
            />

            <Column
              key="businessIndustryId"
              dataIndex="businessIndustryId"
              title={translate("customer.businessScope")}
              render={(businessIndustry, record) => (
                <TextField
                  value={
                    businessIndustry &&
                    `${record?.businessIndustry?.code}-${record?.businessIndustry?.name}`
                  }
                />
              )}
              sorter
            />
            <Column
              key="market"
              dataIndex="market"
              title={translate("customer.market")}
              render={(businessIndustry, record) => {
                const { marketArea = {} } = (record as any).businessIndustry;
                return (
                  <TextField
                    value={`${marketArea?.code}-${marketArea?.name}`}
                  />
                );
              }}
              sorter
            />
            <Column
              key="syncStatus"
              dataIndex="syncStatus"
              title={translate("integration.syncStatus")}
              render={(_, record) => {
                return <IntegrationStatusTag status={record?.syncStatus} />;
              }}
            />
            <Column
              key="updatedDate"
              dataIndex="updatedDate"
              title={translate("customer.updateTime")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              sorter
            />

            <Column
              title={translate("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => {
                const isDraft = record.status === CustomerStatus.DRAFT;
                return (
                  <Space>
                    <ButtonTooltip
                      type="text"
                      icon={<Icons.EditOutlined />}
                      className="gray"
                      onClick={(e) => {
                        e.stopPropagation();
                        edit(PATH.customers, record.id);
                      }}
                      {...checkEditHaveHelperText(PATH.customers)}
                    />
                    <div className="line-between-action"></div>
                    <ButtonTooltip
                      type="text"
                      icon={
                        <Icon
                          iconName={IconName.personAdd}
                          color="#8C8C8C"
                          hoverColor="#000"
                        />
                      }
                      className="gray"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleModalAssign();
                        setCurrentRecord(record);
                      }}
                      {...checkEditHaveHelperText(PATH.customers, isDraft)}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </div>
      </List>
      <ModalAssign
        visibleModal={visibleModalAssign}
        toggleModal={toggleModalAssign}
        record={currentRecord}
      />
    </>
  );
};

export default CustomerList;
