import { Tag } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

interface StatusProps {
    type: TagType
    text: string
}
export enum TagType {
    RED,
    GRAY,
    GREEN,
    BLUE,
    YELLOW,
    CAYAN,
    PURPLE
}

export const BaseTag = (props: StatusProps) => {
    const t = useTranslate()
    const {type, text} = props
    const colorName = TagType[type]
    switch (type) {
        case TagType.GRAY:
            return <GrayTag {...props} />
        case TagType.GREEN:
            return <GreenTag {...props} />
        case TagType.YELLOW:
            return <YellowTag {...props} />

        case TagType.GREEN:
        case TagType.RED:
        case TagType.BLUE:
        case TagType.CAYAN:
        case TagType.PURPLE:
        default:
            return <Tag color={colorName}>{text}</Tag>
    }
        
}


export const GrayTag = ({text }: StatusProps) => {
    return <Tag color="default" style={{color: '#8C8C8C', background: '#F0F0F0'}}>{text}</Tag>
}

export const GreenTag = ({text }: StatusProps) => {
    return <Tag color="green">{text}</Tag>
}

export const BlueTag = ({text }: StatusProps) => {
    return <Tag color="blue">{text}</Tag>
}

export const YellowTag = ({text }: StatusProps) => {
    return <Tag color="lime">{text}</Tag>
}


