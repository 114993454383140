import { Form, FormInstance, FormProps } from "@pankod/refine-antd";
import {
  IForm,
  ServiceRequestFormContext,
} from "pages/sale/serviceRequest/create";
import { FC, memo, useContext, useState } from "react";
import CaseInfo from "./CaseInfo";
import DetailInfo from "./DetailInfo";
import ProductList from "./ProductList";
import { getProductDefaultForServiceRequest } from "api";
import { API_PATH } from "configs/path";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import { DATE_FORMAT } from "configs/constants";

interface Props {
  onFinishedFormValidate: () => void;
  formProps: FormProps;
  form: FormInstance<IForm>;
}

const { useWatch } = Form;

const ServiceRequestForm: FC<Props> = memo((props) => {
  const { form, formProps, onFinishedFormValidate } = props;
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [productIdList, setProductIdList] = useState<string[]>([uuid()]);
  const { initialValues } = useContext(ServiceRequestFormContext);
  const caseId = useWatch("caseId", form);

  const onFetchProduct = async () => {
    const ids: string[] = [];
    const listServiceRequestProduct: any = {};
    try {
      setLoadingProduct(true);
      const res = await getProductDefaultForServiceRequest(
        `${API_PATH.serviceUploadGetDefaultProducts}/${caseId}`
      );

      res?.data?.forEach((product) => {
        const id = uuid();
        ids.push(id);
        listServiceRequestProduct[id] = {
          productId: product.id,
          unitId: product.unit?.id,
          quantity: product.quantity,
          unitPrice: product.unitPrice,
          partNumber: product?.partNumber,
          description: product?.description,
        };
      });

      setProductIdList(ids.length ? ids : [uuid()]);
      setTimeout(() => {
        form.setFieldsValue({
          listServiceRequestProduct,
        });
      }, 0);
    } catch (error) {
      //
    } finally {
      setLoadingProduct(false);
    }
  };

  return (
    <Form
      {...formProps}
      onFinish={onFinishedFormValidate}
      layout="vertical"
      style={{ display: "flex", flexDirection: "column", gap: 8 }}
      initialValues={{
        ...initialValues,
        caseId: initialValues?.case?.id,
        serviceRequestTypeId: initialValues?.serviceRequestType?.id,
        serviceRequestTypeCode: initialValues?.serviceRequestType?.code,
        requestDate: initialValues?.requestDate
          ? dayjs(initialValues?.requestDate).format(DATE_FORMAT)
          : "-",
        listServiceRequestDocument:
          initialValues?.serviceRequestView?.serviceRequestViewDocuments,
      }}
    >
      <CaseInfo />
      <DetailInfo />
      <ProductList
        onFetchProduct={onFetchProduct}
        list={productIdList}
        setList={setProductIdList}
        loadingProduct={loadingProduct}
      />
    </Form>
  );
});

export default ServiceRequestForm;
