import { useTranslate } from "@pankod/refine-core";
import { Descriptions } from "antd";
import { DataText } from "components";
import IQuotation from "interfaces/Quotation";
import { FC, memo } from "react";

interface Props {
  record?: IQuotation;
}
const Commercial: FC<Props> = memo((props) => {
  const { record } = props;
  const translate = useTranslate();
  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={3}
        title={translate("Thông tin bộ phận Commercial")}
        layout="vertical"
      >
        <Descriptions.Item
          label={translate("Nhân viên kinh doanh")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.commercialEmployee?.fullName} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Email")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.commercialEmployee?.emailUser} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Sô điện thoại")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.commercialEmployee?.phoneNumber} />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
});

export default Commercial;
