import { Icons } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { ButtonTooltip } from "components";
import { ListCustom } from "components/layout";
import { PATH, SLUGS } from "configs/path";
import usePermissions from "hooks/permission";
import React from "react";
import { PrepayRequest } from "./components/tabs/prepayRequest";

export const PrepayList: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { create } = useNavigation();
  const { checkCreateHaveHelperText } = usePermissions();


  return (
    <ListCustom
      title={t(
         "prepays.titles.list"  
      )}
      headerButtons={
        <ButtonTooltip
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => create(SLUGS.prepays)}
          {...checkCreateHaveHelperText(PATH.prepays)}
        >
          {t("prepays.create")}
        </ButtonTooltip>
      }
      breadcrumb={null}
    >
      {<PrepayRequest />}
    </ListCustom>
  );
};
