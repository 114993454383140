import { ExploitingType } from "./Customer";

export default interface BusinessOpportunities {
  closedDate: Date;
  code: string;
  createdDate: Date;
  createdName: string;
  creationDate: Date;
  employeeId: string;
  id: string;
  description: string;
  closeReason: string;
  customer: {
    code: string;
    id: string;
    name: string;
  };
  marketArea: {
    code: string;
    id: string;
    name: string;
  };
  name: string;
  ownerEmployee: {
    code: string;
    id: string;
    name: string;
  };
  provinceId: string;
  region: {
    code: string;
    id: string;
    name: string;
  };
  caseId: string;
  tenantId: string;
  updatedDate: string;
  updatedName: string;
  budgetEstimation: number;
  profitEstimation: number;
  revenueEstimation: number;
  businessOppsStatus: {
    code: BusinessOpportunitiesStatus;
    id: string;
    name: string;
    percentSuccess: number;
  };
  products: Product[];
  assignExloitingType: {
    id: ExploitingType;
    name: string;
  };
}

export enum BusinessOpportunitiesStatus {
  "Lost Deal" = 0,
  "Confirm Opportunity" = 1,
  "Convert Leads to Opportunity" = 2,
  "Consulting" = 3,
  "Quotation/BID" = 4,
  "Win Deal" = 5,
  "Negotiation contract" = 6,
  "Process contract" = 7,
  "Won Deal" = 8,
}

export type Product = {
  id: string;
  estimateValue: number;
  quantity: number;
  unitPrice: number;
  description: string;
  partNumber: string;
  unit: {
    code: string;
    id: string;
    name: string;
    status: boolean;
  };
  product: {
    code: string;
    id: string;
    name: string;
    status: boolean;
  };
  productGroup: {
    code: string;
    id: string;
    name: string;
    status: boolean;
  };
};
