import {
  Button,
  Col,
  DatePicker,
  Form,
  Icons,
  Input,
  Row,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { CurrencyType } from "api/enums";
import { generateCurrency } from "components/Currency";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { FC, memo, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { ContractContext, IForm } from "../../..";
import { isEmpty, roundToHundreds } from "utils/commons";
import ButtonConfirm from "components/ButtonConfirm";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import { DATE_FORMAT } from "configs/constants";

const { Title, Text } = Typography;
const { useWatch, useFormInstance } = Form;
const InvoicePlan: FC = memo(() => {
  const { isEdit, initialValue } = useContext(ContractContext);
  const form = useFormInstance<IForm>();
  const [list, setList] = useState<string[]>([]);
  const translate = useTranslate();

  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);
  const contractProducts = useWatch("contractProducts", form);
  const contractInvoicePlans = useWatch("contractInvoicePlans", form);

  const sumAfterTax = useMemo(
    () =>
      Object.values(contractProducts || {})?.reduce((sum, next) => {
        const { unitPrice = 0, quantity = 0, taxPercent = 0 } = next || {};
        const priceBeforeTax = unitPrice && quantity ? unitPrice * quantity : 0;
        const tax =
          priceBeforeTax && taxPercent
            ? (priceBeforeTax * taxPercent) / 100
            : 0;
        const priceAfterTax = priceBeforeTax + tax;
        return roundToHundreds(sum + priceAfterTax);
      }, 0),
    [contractProducts]
  );

  const sumInvoicePlanValue = useMemo(
    () =>
      Object.values(contractInvoicePlans || {}).reduce((sum, next) => {
        if (!isEmpty(next.invoiceValue)) return sum + Number(next.invoiceValue);
        return sum;
      }, 0),
    [contractInvoicePlans]
  );

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  useEffect(() => {
    form.setFields([
      {
        name: "validateTotalInvoicePayment",
        errors:
          isEmpty(contractProducts) ||
          isEmpty(contractInvoicePlans) ||
          sumInvoicePlanValue === 0 ||
          sumInvoicePlanValue === sumAfterTax
            ? undefined
            : [translate("errors.ER0177")],
      },
    ]);
  }, [
    sumInvoicePlanValue,
    sumAfterTax,
    contractInvoicePlans,
    contractProducts,
  ]);

  useEffect(() => {
    if (isEdit && initialValue?.contractInvoicingPlans?.length) {
      const ids: string[] = [];
      const contractInvoicingPlansData: any = {};
      initialValue?.contractInvoicingPlans?.forEach((ip) => {
        contractInvoicingPlansData[ip.id] = { ...ip };
        ids.push(ip.id);
      });
      setList(ids);
      setTimeout(
        () =>
          form.setFieldsValue({
            contractInvoicePlans: contractInvoicingPlansData,
          }),
        0
      );
    }
  }, [isEdit, initialValue]);

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Row style={{ marginBottom: 8 }}>
        <Col flex={1}>
          <Title level={5}>Kế hoạch xuất hóa đơn</Title>
        </Col>
        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setList([...list, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {translate("actions.create")}
          </Button>
        </div>
      </Row>
      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        scroll={{ x: 800 }}
      >
        <Table.Column
          title={translate("Đợt")}
          dataIndex="invoiceTerm"
          width={100}
          render={(v, keyRow: string, index) => {
            return (
              <>
                <Form.Item
                  hidden
                  name={["contractInvoicePlans", keyRow, "id"]}
                />
                <Form.Item
                  className="m-0"
                  name={["contractInvoicePlans", keyRow, "invoiceTerm"]}
                  rules={[
                    {
                      max: 200,
                      message: translate("errors.ER014", { max: 200 }),
                    },
                  ]}
                >
                  <Input
                    className="w-full"
                    placeholder={translate("Nhập giá trị")}
                  />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("Ngày xuất hóa đơn dự kiến")}
          dataIndex="estimatedInvoiceDate"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["contractInvoicePlans", keyRow, "estimatedInvoiceDate"]}
                className="m-0"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
              >
                <DatePicker
                  className="w-full"
                  format={DATE_FORMAT}
                  placeholder={DATE_FORMAT}
                />
              </Form.Item>
            );
          }}
        />

        <Table.Column
          title={translate("Giá trị hóa đơn")}
          dataIndex="invoiceValue"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["contractInvoicePlans", keyRow, "invoiceValue"]}
                placeholder={translate("Nhập giá trị")}
                isLimit
                isRequired={false}
                className="m-0"
                allowDecimal
              />
            );
          }}
        />
        <Table.Column
          align="center"
          width={70}
          fixed="right"
          title={translate("table.actions")}
          render={(v, record, index) => {
            return (
              <ButtonConfirm
                text={translate("Bạn muốn xóa dòng này?")}
                description=""
                onClick={onRemoveItem(index)}
                type="text"
                danger
              >
                <Icons.DeleteOutlined />
              </ButtonConfirm>
            );
          }}
        />
      </Table>
      <Form.Item
        name="validateTotalInvoicePayment"
        className="m-0"
        style={{ margin: 0, minHeight: 0 }}
        rules={[
          {
            validator: () => {
              if (
                isEmpty(contractProducts) ||
                isEmpty(contractInvoicePlans) ||
                sumInvoicePlanValue === 0 ||
                sumInvoicePlanValue === sumAfterTax
              ) {
                return Promise.resolve();
              }
              return Promise.reject(translate("errors.ER0177"));
            },
          },
        ]}
      />
    </div>
  );
});

export default InvoicePlan;
