import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useUpdate,
} from "@pankod/refine-core";
import {
  Form,
  Input,
  useForm,
  notification,
  Typography,
  Row,
  Col,
} from "@pankod/refine-antd";

import { IMarketAreaConfig, IPost } from "interfaces";
import { PATH, API_PATH } from "configs/path";
import { CreateCustom } from "components/layout";
import { SelectCustom } from "components/SelectCustom";

import InputUpper from "components/InputUpper";
import { mappingErrorFromApi } from "utils/commons";
import { MoneyInput } from "components/MoneyInput";
import { CurrencyType } from "api/enums";
const { Text } = Typography;
const { TextArea } = Input;

export const ProductCreate: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const [visibleShowModal, setVisibleShowModal] =
    React.useState<boolean>(false);
  const { mutate: mutateCreate, isLoading } = useCreate<IMarketAreaConfig>();
  const { mutate: mutateUpdate } = useUpdate<IMarketAreaConfig>();
  const { form, formProps, saveButtonProps } = useForm<IPost>();
  const { show, list } = useNavigation();

  const isEdit = !!formProps.initialValues?.id;
  const record = formProps.initialValues;

  const onSubmit = () => {
    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: isEdit
            ? translate("common.config.editSuccess")
            : translate("common.config.createSuccess"),
        });
        show(PATH.configProducts, res?.data?.id);
      },
      onError: (error: any) => {
        setVisibleShowModal(false);
        mappingErrorFromApi(error, form);
      },
    };

    const dataSend = {
      resource: PATH.configProducts,
      values: {
        name: form.getFieldValue("name").trim(),
        description: form.getFieldValue("description"),
        unitId: form.getFieldValue("unitId"),
        productGroupId: form.getFieldValue("productGroupId"),
        unitPrice: form.getFieldValue("unitPrice"),
        category: form.getFieldValue("category"),
        code: form.getFieldValue("code"),
      },
      id: "",
    };

    if (isEdit) {
      dataSend.id = formProps.initialValues?.id || "";
      mutateUpdate(dataSend, handleResponse);
    } else {
      mutateCreate(dataSend, handleResponse);
    }
  };
  return (
    <CreateCustom
      {...props}
      saveButtonProps={{
        ...saveButtonProps,
        title: isEdit
          ? translate("common.saveEdit")
          : translate("products.button.create"),
      }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      confirmModalProps={{
        title: isEdit
          ? translate("common.config.editConfirm")
          : translate("products.message.createConfirm"),
        description: isEdit ? translate("common.config.editConfirmDesc") : "",
      }}
      title={
        isEdit ? (
          <Text>
            {" "}
            {translate("products.titles.edit")}:{" "}
            <span className="primary">{record?.name}</span>
          </Text>
        ) : (
          translate("products.titles.create")
        )
      }
      cancelModalProps={{
        okButtonProps: {
          onClick: () => {
            isEdit
              ? show(PATH.configProducts, record?.id)
              : list(PATH.configProducts);
          },
          danger: true,
        },
        cancelButtonProps: {},
      }}
      saving={isLoading}
    >
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout="vertical"
        labelCol={{ span: 10 }}
      >
        <div style={{ marginBottom: 12 }}>
          <Text style={{ fontWeight: 500, fontSize: 16 }}>
            {translate("common.titles.detail")}{" "}
          </Text>
        </div>
        <Row gutter={12} style={{ width: "100%" }}>
          <Col span={12}>
            <Form.Item
              label={translate("products.fields.code")}
              name="code"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: translate("errors.ER005"),
                },
                {
                  max: 20,
                  message: translate("errors.ER014", { max: "20" }),
                },
                {
                  min: 3,
                  message: translate("errors.ER013", { min: "3" }),
                },
              ]}
            >
              <Input disabled={isEdit} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} style={{ width: "100%" }}>
          <Col span={12}>
            <Form.Item
              label={translate("products.fields.name")}
              name="name"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: translate("errors.ER005"),
                },
                {
                  max: 100,
                  message: translate("errors.ER014", { max: "100" }),
                },
              ]}
            >
              <Input placeholder={translate("products.placeholder.name")} />
            </Form.Item>
          </Col>

          <Col span={12} />

          <Col span={12}>
            <Form.Item
              label={translate("products.fields.productGroup")}
              name="productGroupId"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: translate("errors.ER005"),
                },
              ]}
            >
              <SelectCustom
                resource={API_PATH.configProductGroups + "/dropdownlist"}
                placeholder={translate("products.placeholder.productGroup")}
                disabled={isEdit}
                optionValue="id"
                optionLabel="label"
                label={record?.productGroup?.name}
                formatData={(r: any) => ({
                  ...r,
                  label: `${r.code} - ${r.name}`,
                })}
                useServerFiltering={false}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate("product.category")}
              name="category"
              rules={[
                {
                  required: true,
                  message: translate("errors.ER005"),
                },
              ]}
            >
              <SelectCustom
                resource={API_PATH.configProducts + "/category-dropdownlist"}
                placeholder={translate("product.selectCategory")}
                optionValue="id"
                optionLabel="value"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate("products.fields.unit")}
              name="unitId"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: translate("errors.ER005"),
                },
              ]}
            >
              <SelectCustom
                optionLabel="label"
                optionValue="value"
                resource={API_PATH.configUnits + "/dropdownlist"}
                placeholder={translate("products.placeholder.unit")}
                label={record?.unit?.name}
                useServerFiltering={false}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={translate("products.fields.price")}
              name="unitPrice"
              rules={[
                {
                  validator: async (_, value) => {
                    if (value === null) {
                      return Promise.resolve();
                    }
                    if (value > 100 * 1000 * 1000 * 1000) {
                      return Promise.reject(
                        translate("errors.ER086", { min: 1, max: "100 tỷ" })
                      );
                    }
                    if (value < 1) {
                      return Promise.reject(
                        translate("errors.ER086", { min: 1, max: "100 tỷ" })
                      );
                    }
                  },
                },
              ]}
            >
              <MoneyInput
                align="left"
                placeholder={translate("products.placeholder.price")}
                currencyType={CurrencyType.VND}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate("products.fields.description")}
              name="description"
              rules={[
                {
                  max: 200,
                  message: translate("errors.ER014", { max: "200" }),
                },
              ]}
            >
              <TextArea
                showCount={{
                  formatter: (args: { count: number }) => `${args?.count}/200`,
                }}
                placeholder={translate("products.placeholder.description")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CreateCustom>
  );
};
