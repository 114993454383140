import {
  Descriptions,
  Form,
  Icons,
  Modal,
  Select,
  Tag,
  Typography,
  Input,
  Button,
  Row,
  Col,
  Space,
  Table,
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  ButtonTooltip,
  ConfigDisplayText,
  DataText,
  LinkText,
} from "components";
import { ShowCustom } from "components/layout";
import { PATH, SLUGS } from "configs/path";
import { FC, useMemo } from "react";
import { formatISODateTimeToView } from "utils/commons";
import { useController } from "./controller";
import type { ColumnsType } from "antd/es/table";
import { Product } from "interfaces/BusinessOpportunities";
import { DATE_FORMAT } from "configs/constants";
import { Currency } from "components/Currency";
import { CurrencyType } from "api/enums";

const { Text, Title } = Typography;
const { TextArea } = Input;

const BusinessOpportunitiesShow: FC<IResourceComponentsProps> = (props) => {
  const {
    isLoading,
    record,
    isClose,
    visibleModalClose,
    statusSelectProps,
    isUpdateLoading,
    translate,
    toggleModalClose,
    checkEditHaveHelperText,
    renderStatus,
    edit,
    onEdit,
  } = useController();

  const columns = useMemo<ColumnsType<Product>>(() => {
    return [
      {
        title: translate("common.no"),
        width: 70,
        dataIndex: "id",
        key: "id",
        render: (_, __, index) => {
          return <DataText value={index + 1} />;
        },
      },
      {
        title: translate("Tên sản phẩm"),
        width: 200,
        dataIndex: "name",
        key: "name",
        render: (_, record) => {
          return (
            <ConfigDisplayText
              status={record?.product?.status}
              value={`${record?.product?.code} - ${record?.product?.name}`}
            />
          );
        },
        sorter: (a, b) =>
          `${a?.product?.name}${a?.product?.code}`.length -
          `${b?.product?.name}${b?.product?.code}`.length,
      },
      {
        title: translate("products.fields.partNumber"),
        width: 200,
        dataIndex: "partNumber",
        key: "partNumber",
        render: (_, record) => {
          return <DataText value={record?.partNumber} />;
        },
      },
      {
        title: translate("businessOpportunities.productDescription"),
        width: 250,
        dataIndex: "description",
        key: "description",
        render: (_, record) => {
          return <DataText value={record?.description} />;
        },
      },
      {
        title: translate("common.quantity"),
        width: 100,
        dataIndex: "quantity",
        key: "quantity",
        render: (_, record) => {
          return <DataText value={record?.quantity} />;
        },
        sorter: (a, b) => a?.quantity - b?.quantity,
      },
      {
        title: translate("products.fields.unitShort"),
        width: 120,
        dataIndex: "product",
        key: "product",
        render: (_, record) => {
          return <DataText value={record?.unit?.name} />;
        },
        sorter: (a, b) => a?.unit?.name?.length - b?.unit?.name?.length,
      },
      {
        title: translate("products.fields.unitPrice"),
        width: 200,
        dataIndex: "unitPrice",
        key: "unitPrice",
        render: (_, record) => {
          return (
            <Currency
              value={record?.unitPrice}
              currencyType={CurrencyType.VND}
            />
          );
        },
        sorter: (a, b) => a?.unitPrice - b?.unitPrice,
      },
      {
        title: translate("businessOpportunities.value"),
        dataIndex: "estimateValue",
        key: "estimateValue",
        width: 200,
        render: (_, record) => {
          return (
            <Currency
              value={record?.estimateValue}
              currencyType={CurrencyType.VND}
            />
          );
        },
        sorter: (a, b) => a?.estimateValue - b?.estimateValue,
      },
    ];
  }, []);

  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        breadcrumbText={record?.name}
        title={
          <Text style={{ marginBottom: 0 }}>
            {translate("businessOpportunities.opportunity")}:{" "}
            <Text className="primary">{record?.name}</Text>
          </Text>
        }
        headerButtons={
          <>
            {!isClose && (
              <ButtonTooltip
                type="ghost"
                onClick={toggleModalClose}
                {...checkEditHaveHelperText(PATH.businessOpportunities)}
              >
                {translate("businessOpportunities.closeTheOpportunity")}
              </ButtonTooltip>
            )}
            {!isClose && (
              <ButtonTooltip
                type="primary"
                icon={<Icons.EditOutlined />}
                onClick={() => edit(PATH.businessOpportunities, record?.id!)}
                {...checkEditHaveHelperText(PATH.businessOpportunities)}
              >
                {translate("actions.edit")}
              </ButtonTooltip>
            )}
            {isClose && (
              <ButtonTooltip
                type="primary"
                style={{ backgroundColor: "#ffffff", color: "#2F54EB" }}
                {...checkEditHaveHelperText(PATH.businessOpportunities)}
              >
                {translate("businessOpportunities.locked")}
              </ButtonTooltip>
            )}
          </>
        }
      >
        <Descriptions
          column={{ lg: 3, xs: 1 }}
          title={translate("common.titles.detail")}
          layout="vertical"
        >
          <Descriptions.Item
            label={translate("businessOpportunities.nameOfBusinessOpportunity")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText value={record?.name} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.status")}
            labelStyle={{ fontWeight: 500 }}
          >
            <Tag {...renderStatus(record?.businessOppsStatus.code)}>
              {`${record?.businessOppsStatus?.name} - ${record?.businessOppsStatus?.percentSuccess}%`}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.workId")}
            labelStyle={{ fontWeight: 500 }}
          >
            <LinkText
              value={{ code: record?.code, id: record?.caseId }}
              resource={SLUGS.caseForSale}
            />
          </Descriptions.Item>

          <Descriptions.Item
            label={translate("businessOpportunities.customer")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={`${record?.customer.code} - ${record?.customer.name}`}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.market")}
            labelStyle={{ fontWeight: 500 }}
            span={2}
          >
            <DataText value={record?.marketArea?.name} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.startDate")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={formatISODateTimeToView(record?.creationDate, DATE_FORMAT)}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.estimatedClosingDate")}
            labelStyle={{ fontWeight: 500 }}
            span={2}
          >
            <DataText
              value={formatISODateTimeToView(record?.closedDate, DATE_FORMAT)}
            />
          </Descriptions.Item>

          <Descriptions.Item
            label={translate("businessOpportunities.estimatedBudget")}
            labelStyle={{ fontWeight: 500 }}
          >
            <Currency value={record?.budgetEstimation} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.estimatedContractValue")}
            labelStyle={{ fontWeight: 500 }}
          >
            <Currency value={record?.revenueEstimation} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.grossProfit")}
            labelStyle={{ fontWeight: 500 }}
          >
            <Currency value={record?.profitEstimation} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.description")}
            labelStyle={{ fontWeight: 500 }}
            span={3}
          >
            <DataText value={record?.description} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.personInCharge")}
            labelStyle={{ fontWeight: 500 }}
            span={1}
          >
            <DataText value={record?.ownerEmployee?.name} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("businessOpportunities.AccountType")}
            labelStyle={{ fontWeight: 500 }}
            span={2}
          >
            <DataText value={record?.assignExloitingType?.name} />
          </Descriptions.Item>

          <Descriptions.Item
            label={translate("common.createdDate")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={formatISODateTimeToView(
                record?.createdDate,
                "DD/MM/YYYY hh:MM:ss"
              )}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("common.createdName")}
            labelStyle={{ fontWeight: 500 }}
            span={2}
          >
            <DataText value={record?.createdName} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("common.updatedDate")}
            labelStyle={{ fontWeight: 500 }}
          >
            <DataText
              value={formatISODateTimeToView(
                record?.updatedDate,
                "DD/MM/YYYY hh:MM:ss"
              )}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("common.editor")}
            labelStyle={{ fontWeight: 500 }}
            span={2}
          >
            <DataText value={record?.updatedName} />
          </Descriptions.Item>
          {isClose && (
            <Descriptions.Item
              label={translate("businessOpportunities.closeReason")}
              labelStyle={{ fontWeight: 500 }}
              span={3}
            >
              <DataText value={record?.closeReason} />
            </Descriptions.Item>
          )}
        </Descriptions>
      </ShowCustom>
      <div style={{ padding: 24 }}>
        <Title level={5} style={{ marginBottom: 24 }}>
          {translate("businessOpportunities.productList")}
        </Title>
        <Table
          scroll={{ x: 1350 }}
          pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
          columns={columns}
          dataSource={record?.products}
          rowKey="id"
        />
      </div>
      <Modal
        visible={visibleModalClose}
        onCancel={toggleModalClose}
        onOk={toggleModalClose}
        footer={false}
        closable={false}
        // bodyStyle={{ padding: 0 }}
      >
        <Space style={{ marginBottom: 24 }}>
          <Text style={{ fontSize: 16, fontWeight: 500 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <Text>{translate("businessOpportunities.notify.confirmClose")}</Text>
        </Space>
        <Form layout="vertical" onFinish={onEdit}>
          <Form.Item
            required
            rules={[{ required: true, message: translate("errors.ER005") }]}
            label={translate("businessOpportunities.status")}
            name={"statusId"}
          >
            <Select {...statusSelectProps} />
          </Form.Item>
          <Form.Item
            required
            rules={[
              { required: true, message: translate("errors.ER005") },
              { max: 200, message: translate("errors.ER014", { max: 200 }) },
            ]}
            label={translate("businessOpportunities.closeReason")}
            name="closeReason"
          >
            <TextArea />
          </Form.Item>
          <Row style={{ justifyContent: "flex-end", marginTop: 24 }} gutter={8}>
            <Col>
              <Form.Item noStyle>
                <Button
                  style={{ marginBottom: 0 }}
                  type="ghost"
                  onClick={toggleModalClose}
                  disabled={isUpdateLoading}
                >
                  {translate("businessOpportunities.cancel")}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle>
                <Button
                  loading={isUpdateLoading}
                  type="primary"
                  danger
                  htmlType="submit"
                >
                  {translate("businessOpportunities.submit")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default BusinessOpportunitiesShow;
