import React, {useEffect} from "react";
import { useTranslate, IResourceComponentsProps, useShow, useUpdate, useNavigation } from "@pankod/refine-core";
import {
  Form,
  Input,
  useForm,
  notification,
  Typography,
} from "@pankod/refine-antd";

import { IDepartment } from "interfaces";
import { PATH, API_PATH } from "configs/path";
import { EditCustom } from "components/layout";
import { mappingErrorFromApi, searchSelect } from "utils/commons"
import usePermissions from 'hooks/permission'
import { SelectCustom } from "components/SelectCustom";
import { showErrorToast } from "api/common";


const { Text, Paragraph } = Typography;

const { TextArea } = Input;

export const DepartmentEdit: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const { queryResult } = useShow<any>();
  const [ visibleShowModal, setVisibleShowModal ] = React.useState<boolean>(false);
  const { mutate: mutateUpdate, isLoading } = useUpdate<any>();
  const { form, formProps, saveButtonProps } = useForm<IDepartment>();
  const { data } = queryResult;
  const { show } = useNavigation()

  const record = data?.data;
  const { checkEdit } = usePermissions()

  const onSubmit = () => {
    mutateUpdate(
      {
        id: record?.id,
        resource: PATH.departments,
        values: {
          name: form.getFieldValue('name').trim(),
          description: form.getFieldValue('description'),
          parentDepartmentId: form.getFieldValue('parentDepartment')?.id || null,
        },
      },
      {
        onSuccess: () => {
          notification.success({
            message: translate('departments.editSuccess')
          })
          show(PATH.departments, record?.id)
        },
        onError: (error: any) => {
          setVisibleShowModal(false)
          const errorFieldMapping: any = {
            'ER0130': 'name'
          }
          mappingErrorFromApi(error, form, errorFieldMapping )
        },
      },
    )
  }

  useEffect(() =>{
    if(record){
      form.setFieldsValue({
        caseCode : `${record?.case?.code} ${record?.case?.description ? `- ${record?.case?.description}` : ""}`
      })
    }
  },[record])

  return (
    <EditCustom
      {...props}
      record={record}
      title={<Text style={{marginBottom: 0}}>{translate("departments.department")}: {record?.name}</Text>}
      titlePopupConfirm={translate('departments.editConfirm')}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: !checkEdit(PATH.departments)
      }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      isLoading={isLoading}
    >
      <Text style={{ fontWeight: 500, fontSize: 16 }}> {translate("departments.fields.title")} </Text>
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout='vertical' style={{maxWidth: 500, paddingLeft: 16}}
        labelCol={{ span: 10 }}
      >
        <Form.Item
          label={translate("cases.cases")}
          name="caseCode"
          style={{marginTop:24}}
        >
          <Input disabled className="purple"/>
        </Form.Item>
        <Form.Item
          label={translate("departments.fields.name")}
          name="name"
          rules={[
            {
              whitespace: true,
              required: true,
              message: translate("errors.ER005"),
            },
            {
              max: 300,
              message: translate("errors.ER014", { max: '300'}),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("departments.fields.description")}
          name="description"
          rules={[
            {
              max: 500,
              message: translate("errors.ER014", { max: '500'}),
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={translate("departments.fields.code")}
          name="code"
          rules={[
            {
              required: true,
              message: translate("errors.ER005"),
            },
            {
              pattern: /^[A-Za-z0-9]+$/,
              message: <div>
                <p style={{marginBottom: 6}}>{translate("errors.ER020")}</p>
                <ul>
                  <li>{translate("errors.ER020_1")}</li>
                  <li>{translate("errors.ER020_2")}</li>
                </ul>
              </div>,
            },
            {
              max: 50,
              message: translate("errors.ER014", { max: '50'}),
            },
          ]}
        >
          <Input disabled/>
        </Form.Item>
        <Form.Item
          label={translate("departments.fields.parentDepartment")}
          name={["parentDepartment", "name"]}
        >
          <SelectCustom
            allowClear
            resource={API_PATH.getParentsDepartment}
            subFilter={record ? record?.parentDepartment?.id ?
              `?currentDepartmentId=${record?.id}&currentParentId=${record?.parentDepartment.id}` : `?currentDepartmentId=${record?.id}` : ''
            }
            onChange={(e) => {
              form.setFieldsValue({parentDepartment: {
                id: e
              }})
            }}
          />
        </Form.Item>
      </Form>
    </EditCustom>
  );
};
