import { Icons, notification, useSelect } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import ButtonConfirm from "components/ButtonConfirm";
import { Currency } from "components/Currency";
import { cloneDeep } from "lodash";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { FC, memo, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "../CaseInfo";
import {
  ContractEstimatedFormContext,
  ContractEstimatedProducts,
  IForm,
} from "../../..";
import { v4 as uuid } from "uuid";
import { API_PATH } from "configs/path";
import { searchSelect } from "utils/commons";
import { Product } from "interfaces/BusinessPlan";
import styled from "styled-components";

const { TextArea } = Input;
const { Title } = Typography;
const { useFormInstance, useWatch } = Form;

const ControlButtonWrapperStyle = styled.div`
  display: flex;
  gap: 8px;
  overflow: auto;
`;

const ProductList: FC = memo(() => {
  const {
    productState,
    sumCost,
    sumPrice,
    onFetchFiancialIndicator,
    planState: [plans],
    onFetchProducts,
    stateModalFetchProduct,
    initialValues,
    isEdit,
  } = useContext(ContractEstimatedFormContext);
  const form = useFormInstance<IForm>();
  const contractEstimatedProducts = useWatch("contractEstimatedProducts", form);
  const [visibleModalFetchProduct, setVisibleModalFetchProduct] =
    stateModalFetchProduct;
  const caseId = useWatch("caseId", form);
  const [list, setList] = productState;
  const translate = useTranslate();

  const onRemoveProduct = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const isShowRemoveButton = useMemo(() => list.length > 1, [list]);

  const sumMargin = useMemo(() => sumPrice - sumCost, [sumPrice, sumCost]);

  const {
    selectProps: productSelectProps,
    queryResult: { data: productsData },
  } = useSelect<Product>({
    resource: API_PATH.productDropdownList,
    optionLabel: "label",
    optionValue: "id",
    metaData: {
      formatData: (r: any) => ({
        ...r,
        label: `${r.code} - ${r.name}`,
      }),
    },
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const products = useMemo(() => productsData?.data, [productsData]);

  const { selectProps: unitSelectProps } = useSelect({
    resource: API_PATH.unitsDropdownlist,
    optionLabel: "label",
    optionValue: "value",
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const onChangeProduct = (keyRow: string) => (value: any) => {
    const product = products?.find(({ id }) => id === value);
    const newList = cloneDeep(contractEstimatedProducts);
    newList[keyRow] = {
      ...newList[keyRow],
      productCode: product?.code,
      productId: product?.id!,
      productName: product?.name,
    };
    form.setFieldsValue({ contractEstimatedProducts: newList });
    onFetchFiancialIndicator({
      caseId,
      plansRequest: plans,
      products: newList,
    });
  };

  const onChangePrice =
    (keyRow: string, field: keyof ContractEstimatedProducts) =>
    (value: any) => {
      const newList = cloneDeep(contractEstimatedProducts);
      newList[keyRow][field] = value as never;
      onFetchFiancialIndicator({
        caseId,
        plansRequest: plans,
        products: newList,
      });
    };

  const toggleModalFetchProduct = () => {
    if (!caseId) {
      return notification.error({
        message: translate("errors.ER0194"),
      });
    }
    setVisibleModalFetchProduct((prev) => !prev);
  };

  useEffect(() => {
    if (isEdit && initialValues?.id) {
      const ids: string[] = [];
      const contractEstimatedProducts: any = {};
      initialValues?.contractEstimatedProducts?.forEach((bProduct) => {
        const id = uuid();
        ids.push(id);

        contractEstimatedProducts[id] = {
          productCode: bProduct?.product?.code,
          productId: bProduct?.product?.id,
          productName: bProduct?.product?.name,
          partNumber: bProduct?.partNumber,
          description: bProduct?.description,
          quantity: bProduct?.quantity,
          unitId: bProduct?.unit?.id,
          unitName: bProduct?.unit?.name,
          unitCost: bProduct?.unitCost,
          unitPrice: bProduct?.unitPrice,
          id: bProduct?.id,
        };
      });

      setList(ids);
      setTimeout(
        () =>
          form.setFieldsValue({
            contractEstimatedProducts,
          }),
        0
      );
    }
  }, [isEdit, initialValues]);

  return (
    <Box>
      <Title level={5}>{translate("products.titles.list")}</Title>
      <div style={{ paddingLeft: 16 }}>
        <Row style={{ marginBottom: 16 }} align="middle">
          <Col flex={1} />

          <ControlButtonWrapperStyle>
            <ButtonConfirm
              type="primary"
              onClick={onFetchProducts}
              text={translate(
                "Bạn muốn lấy dữ liệu danh sách sản phẩm/dịch vụ từ Phương án kinh doanh?"
              )}
              description={
                "Lưu ý: Sau khi lấy dữ liệu, các thông tin trong danh sách sản phẩm hiện tại sẽ bị xóa."
              }
              noCancel={false}
              visible={visibleModalFetchProduct}
              toggle={toggleModalFetchProduct}
            >
              {translate("Lấy từ PAKD")}
            </ButtonConfirm>
            <Button
              type="primary"
              onClick={() => {
                setList([...list, uuid()]);
              }}
              icon={<Icons.PlusOutlined />}
            >
              {translate("actions.create")}
            </Button>
          </ControlButtonWrapperStyle>
        </Row>
        <Table<String>
          style={{ marginTop: 8 }}
          size="small"
          className="vertical-align-top w-full"
          dataSource={list}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 1000,
          }}
          locale={{
            emptyText: " ",
          }}
          scroll={{ x: 2500 }}
          bordered
        >
          <Table.Column
            title={translate("common.no")}
            dataIndex="code"
            width={"50px"}
            align="center"
            render={(v, keyRow: string, index) => index + 1}
          />

          <Table.Column
            title={translate("Tên sản phẩm")}
            dataIndex="productId"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <>
                  <Form.Item
                    hidden
                    name={["contractEstimatedProducts", keyRow, "id"]}
                  />
                  <Form.Item
                    hidden
                    name={["contractEstimatedProducts", keyRow, "productName"]}
                  />
                  <Form.Item
                    hidden
                    name={["contractEstimatedProducts", keyRow, "productCode"]}
                  />
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["contractEstimatedProducts", keyRow, "productId"]}
                    rules={[
                      {
                        required: true,
                        message: translate("errors.ER005"),
                      },
                    ]}
                  >
                    <Select
                      {...productSelectProps}
                      placeholder={translate("Chọn sản phẩm")}
                      allowClear
                      onChange={onChangeProduct(keyRow)}
                      filterOption={(inputValue: string, option: any) => {
                        return (
                          option && searchSelect(inputValue, option?.label)
                        );
                      }}
                    />
                  </Form.Item>
                </>
              );
            }}
          />
          <Table.Column
            title={translate("products.fields.partNumber")}
            dataIndex="partNumber"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["contractEstimatedProducts", keyRow, "partNumber"]}
                  rules={[
                    {
                      max: 200,
                      message: translate("errors.ER014", { max: "200" }),
                    },
                  ]}
                >
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    resource=""
                    placeholder="Nhập part number"
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("common.description")}
            dataIndex="description"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["contractEstimatedProducts", keyRow, "description"]}
                  rules={[
                    {
                      max: 3000,
                      message: translate("errors.ER014", { max: "3000" }),
                    },
                    {
                      required: true,
                      message: translate("errors.ER005"),
                    },
                  ]}
                >
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    placeholder= {translate("common.input.description")}
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("common.quantity")}
            dataIndex="quantity"
            width={100}
            render={(v, keyRow: string, index) => {
              return (
                <InputMoney
                  style={{ margin: 0 }}
                  name={["contractEstimatedProducts", keyRow, "quantity"]}
                  placeholder={translate("common.input.quantity")}
                  isRequired
                  isLimit
                  onChange={onChangePrice(keyRow, "quantity")}
                />
              );
            }}
          />
          <Table.Column
            title={translate("products.fields.unit")}
            dataIndex="productGroup"
            width={100}
            render={(v, keyRow: string, index) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["contractEstimatedProducts", keyRow, "unitId"]}
                  rules={[
                    {
                      required: true,
                      message: translate("errors.ER005"),
                    },
                  ]}
                >
                  <Select
                    {...unitSelectProps}
                    placeholder={translate("products.placeholder.unit")}
                    filterOption={(inputValue: string, option: any) => {
                      return option && searchSelect(inputValue, option?.label);
                    }}
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("products.fields.unitPriceSell")}
            dataIndex="unitPrice"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <InputMoney
                  style={{ margin: 0 }}
                  name={["contractEstimatedProducts", keyRow, "unitPrice"]}
                  placeholder={translate("products.placeholder.unitPrice.sell")}
                  isRequired
                  isLimit
                  onChange={onChangePrice(keyRow, "unitPrice")}
                />
              );
            }}
          />
          <Table.Column
            title={translate("products.fields.unitPriceCapital")}
            dataIndex="unitCost"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <InputMoney
                  style={{ margin: 0 }}
                  name={["contractEstimatedProducts", keyRow, "unitCost"]}
                  placeholder={translate("products.placeholder.unitPrice.capital")}
                  //isRequired
                  isLimit
                  min={0}
                  errorCode="ER0204"
                  onChange={onChangePrice(keyRow, "unitCost")}
                />
              );
            }}
          />
          <Table.Column
            title={translate("Giá bán")}
            dataIndex="price"
            width={200}
            render={(v, keyRow: string, index) => {
              const row = contractEstimatedProducts?.[keyRow];
              return row?.quantity && row?.unitPrice ? (
                <Currency
                  value={row?.quantity * row?.unitPrice}
                  showCurrency={false}
                />
              ) : (
                "-"
              );
            }}
          />

          <Table.Column
            title={translate("Giá vốn")}
            dataIndex="cost"
            width={200}
            render={(v, keyRow: string, index) => {
              const row = contractEstimatedProducts?.[keyRow];
              return row?.quantity && row?.unitCost ? (
                <Currency
                  value={row?.quantity * row?.unitCost}
                  showCurrency={false}
                />
              ) : (
                "-"
              );
            }}
          />

          <Table.Column
            title={translate("Margin")}
            dataIndex="margin"
            width={200}
            render={(v, keyRow: string, index) => {
              const row = contractEstimatedProducts?.[keyRow];
              const margin =
                (row?.quantity || 0) *
                ((row?.unitPrice || 0) - (row?.unitCost || 0));

              return (
                <Currency
                  value={margin}
                  showCurrency={false}
                  className={margin >= 0 ? "green" : "red"}
                />
              );
            }}
          />
          <Table.Column
            align="center"
            width="90px"
            title={translate("table.actions")}
            fixed="right"
            render={(v, keyRow, index) => {
              if (isShowRemoveButton)
                return (
                  <ButtonConfirm
                    text={translate("Bạn muốn xóa sản phẩm dịch vụ này?")}
                    description=""
                    onClick={onRemoveProduct(index)}
                    type="text"
                    danger
                    noCancel={false}
                  >
                    <Icons.DeleteOutlined />
                  </ButtonConfirm>
                );
            }}
          />
        </Table>
        <Descriptions
          column={{ sm: 1, md: 3 }}
          layout="vertical"
          className="no-padding"
          style={{ marginTop: 8 }}
        >
          <Descriptions.Item
            label={translate("Tổng giá bán")}
            labelStyle={{ fontWeight: 500 }}
            span={1}
          >
            <Currency showCurrency={false} value={sumPrice} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("Tổng giá vốn")}
            labelStyle={{ fontWeight: 500 }}
            span={1}
          >
            <Currency showCurrency={false} value={sumCost} />
          </Descriptions.Item>

          <Descriptions.Item
            label={translate("Tổng margin")}
            labelStyle={{ fontWeight: 500 }}
            span={1}
          >
            <Currency
              className={sumMargin >= 0 ? "green" : "red"}
              showCurrency={false}
              value={sumMargin}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Box>
  );
});

export default ProductList;
