import { Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
const { Text } = Typography;

interface Props {
  value?: any;
  defaultValue?: any;
  className?: string;
}

export const DataText = ({
  value,
  defaultValue,
  className = "white-space-wrap",
}: Props) => {
  const t = useTranslate();

  if (!value) {
    return defaultValue ? (
      <Text className={className}>{defaultValue}</Text>
    ) : (
      <Text type="secondary">{t("common.emptyText")}</Text>
    );
  }
  return <Text className={className}>{value}</Text>;
};
