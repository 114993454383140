import { IWorkflowNode, IWorkflowNodeData } from "interfaces";
import React, { useEffect, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  Node,
  ReactFlowProps,
  MarkerType,
  BackgroundVariant,
  getIncomers,
  getOutgoers,
} from "reactflow";
import { NodeCustom } from "./NodeCustom";

import "reactflow/dist/style.css";
import { WorkflowStepType } from "api/enums";
import { uniq } from "lodash";

type WorkflowDiagramProps = {
  references?: any[];
  onChangeEdgeWhenHover?: (nodes: any[], edges: any[]) => void;
};

const nodeTypes = {
  nodeCustom: NodeCustom,
};

const minimapStyle = {
  height: 120,
};

const connectionLineStyle = { stroke: "#000" };

const defaultEdgeOptions = {
  animated: false,
  style: { strokeWidth: 2, stroke: "black" },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: "black",
  },
};

const unHighlightEdgeOptions = {
  markerEnd: {
    color: "#cbd5e1",
    type: MarkerType.ArrowClosed,
  },
  style: {
    stroke: "#cbd5e1",
    strokeWidth: 2,
  },
};

const highlightEdgeOptions = {
  animated: true,
  markerEnd: {
    color: "#ef4444",
    type: MarkerType.ArrowClosed,
  },
  style: {
    stroke: "#ef4444",
    strokeWidth: 3,
  },
  zIndex: 2,
};

const nodeColor = (node: Node<IWorkflowNode>) => {
  switch (node.data?.type) {
    case WorkflowStepType.Begin:
      return "#FCCE45";
    case WorkflowStepType.End:
      return "#A0D911";
    case WorkflowStepType.Decision:
      return "#fb923c";
    case WorkflowStepType.Normal:
      return "#ADC6FF";

    default:
      return "#ADC6FF";
  }
};

export const WorkflowDiagram: React.FC<
  WorkflowDiagramProps & ReactFlowProps
> = (props) => {
  const {
    nodes: nodesProp = [],
    edges: edgesProp = [],
    onChangeEdgeWhenHover,
  } = props;
  const [nodes, setNodes] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);

  useEffect(() => {
    setEdges(edgesProp);
    setNodes(nodesProp);
  }, [nodesProp, edgesProp]);

  const getAllIncomers = (node: any): any => {
    return getIncomers(node, nodes, edges || []).reduce(
      (memo: any, incomer) => [...memo, incomer, ...getAllIncomers(incomer)],
      []
    );
  };

  const getAllOutgoers = (node: any): any => {
    return getOutgoers(node, nodes, edges || []).reduce(
      (memo: any, outgoer) => [...memo, outgoer, ...getAllOutgoers(outgoer)],
      []
    );
  };

  const highlightPath = (node: any) => {
    if (node && nodes) {
      const nodeConnectIds = uniq(
        [...getAllIncomers(node), ...getAllOutgoers(node)].map((n: any) => n.id)
      );

      const allIncomers = getAllIncomers(node).map((i: any) => i.id);
      const newNodes = nodes.map((nd) => {
        if (nodeConnectIds.length > 0) {
          const isHighlight =
            nd.id === node.id || nodeConnectIds.includes(nd.id);
          return {
            ...nd,
            data: { ...nd.data, style: { opacity: isHighlight ? 1 : 0.25 } },
          };
        }

        return nd;
      });

      const newEdges = edges.map((ed) => {
        if (nodeConnectIds.length > 0) {
          const isHighlight =
            allIncomers.includes(ed.target) || ed.target === node.id;
          if (isHighlight) {
            return {
              ...ed,
              ...highlightEdgeOptions,
              labelBgStyle: { ...ed.labelBgStyle, opacity: 1 },
            };
          }
          return {
            ...ed,
            ...unHighlightEdgeOptions,
            labelBgStyle: { ...ed.labelBgStyle, opacity: 0.2 },
          };
        }
        return ed;
      });

      setNodes(newNodes);
      setEdges(newEdges);

      onChangeEdgeWhenHover && onChangeEdgeWhenHover(newNodes, newEdges);
    }
  };

  const resetNodeStyles = () => {
    const newNodes = nodes.map((node) => ({
      ...node,
      data: { ...node.data, style: {} },
    }));

    const newEdges = edges.map((edge) => ({
      ...edge,
      ...defaultEdgeOptions,
      labelBgStyle: { ...edge.labelBgStyle, opacity: 1 },
    }));
    setNodes(newNodes);

    setEdges(newEdges);

    onChangeEdgeWhenHover && onChangeEdgeWhenHover(newNodes, newEdges);
  };

  return (
    <ReactFlow
      {...props}
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      defaultEdgeOptions={defaultEdgeOptions}
      defaultViewport={{
        x: 280,
        y: 50,
        zoom: 1,
      }}
      deleteKeyCode={null}
      connectionLineStyle={connectionLineStyle}
      attributionPosition="top-right"
      onNodeMouseEnter={(event, node) => highlightPath(node)}
      onNodeMouseLeave={(event, node) => resetNodeStyles()}
    >
      <MiniMap style={minimapStyle} nodeColor={nodeColor} zoomable pannable />
      <Controls />
      <Background
        variant={BackgroundVariant.Dots}
        gap={25}
        size={1}
        color="#aaa"
      />
    </ReactFlow>
  );
};
