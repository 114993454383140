import { useForm, useSelect } from "@pankod/refine-antd";
import { useTable } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import { CustomerStatus } from "interfaces/Customer";
import Project from "interfaces/Project";

interface FilterForm {
  region: string;
  marketArea: string;
  status: CustomerStatus;
}

export const useController = () => {
  const { form, formProps } = useForm<FilterForm>();
  const table = useTable<Project>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const { selectProps: statusProps } = useSelect({
    resource: API_PATH.projectStatusDrodownlist,
    optionValue: "id",
    optionLabel: "name",
  });

  return {
    table,
    formProps,
    form,
    status: statusProps?.options,
  };
};
