import { Checkbox, Form } from "@pankod/refine-antd";
import { FC, useEffect, useState } from "react";
import { IFormCreateSettlementsContract } from "..";
interface Props {
    id?: string;
    value: boolean;
}

const {useFormInstance} = Form;

export const CheckBoxCondition: FC<Props> = (props: Props) => {
    const [state, setState] = useState(props.value);
    const form = useFormInstance<IFormCreateSettlementsContract>();
    useEffect(()=> {
        setState(props.value)
    }, [props])
    const onChange = (e: any) => {
        setState(e.target.checked);  
        const submitConditions = form.getFieldValue("submitConditions");
        const data = submitConditions?.map((item: any) => {
            if (item.featureSubmitConditionId === props.id) {
                item.isOk = e.target.checked;
            }
            return item;
        })
    
        form.setFieldsValue({
            submitConditions: data,
        })
    }
    return (
        <div>
            <Checkbox checked={state} onChange={onChange} />
        </div>
    );
}