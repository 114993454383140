import { notification, useForm } from "@pankod/refine-antd";
import {
  useNavigation,
  useShow,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { API_PATH, PATH } from "configs/path";
import BusinessOpportunities from "interfaces/BusinessOpportunities";
import { useState } from "react";
import { formatOnlyDateToUTC, mappingErrorFromApi } from "utils/commons";
import { FormInterface } from "../create/controller";

export const useController = () => {
  const { show } = useNavigation();
  const translate = useTranslate();

  const { form, formProps, saveButtonProps } = useForm<any, any, FormInterface>(
    {}
  );

  const { mutate: mutateUpdate, isLoading } = useUpdate<{ id: string }, any>();

  const { queryResult } = useShow<BusinessOpportunities>();

  const record = queryResult?.data?.data;

  const onSubmit = () => {
    const dataForm = form.getFieldsValue();

    const businessOppsProduct = Object.values(dataForm.products || {});

    const dataToRequest = {
      ...dataForm,
      caseId: record?.caseId,
      products: null,
      budgetEstimation: dataForm?.budgetEstimation
        ? dataForm?.budgetEstimation
        : null,
      revenueEstimation: dataForm?.revenueEstimation,
      profitEstimation: dataForm?.profitEstimation,
      creationDate: formatOnlyDateToUTC(dataForm.creationDate),
      closedDate: formatOnlyDateToUTC(dataForm.closedDate),
      businessOppsProduct,
    };

    mutateUpdate(
      {
        resource: API_PATH.businessOpportunities,
        values: dataToRequest,
        id: record?.id!,
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: translate(
              "businessOpportunities.notifycation.updateSuccessful"
            ),
          });
          show(PATH.businessOpportunities, res?.data?.id);
        },
        onError: (error) => {
          toggleModalConfirm(false);
          const errorFieldMapping: any = {};
          notification.error({
            message: translate(
              "businessOpportunities.notifycation.updateFailed"
            ),
          });
          mappingErrorFromApi(error, form, errorFieldMapping);
        },
      }
    );
  };

  const [visibleModalConfirmUpdate, setVisibleModalConfirmCreate] =
    useState(false);

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleModalConfirmCreate(isOpen);

  return {
    record,
    visibleModalConfirmUpdate,
    isLoading,
    saveButtonProps,
    form,
    formProps,
    translate,
    onSubmit,
    toggleModalConfirm,
  };
};
