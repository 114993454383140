import React, { useLayoutEffect } from "react";
import { LayoutProps } from "@pankod/refine-core";
import { Affix, AntdLayout } from "@pankod/refine-antd";
import usePermissions from "hooks/permission";
import { TENANT_ID } from "configs/storage";
import { useNavigate } from "react-router-dom";
import { API_PATH, PATH } from "configs/path";
import {
  LOCATION_PROVINCES,
  LOCATION_DISTRICTS,
  LOCATION_WARDS,
} from "configs/storage";
import { useLocationData } from "contexts/LocationsContext";
import { getLocationList } from "api";
import { ILocation } from "api/types";
import { groupBy } from "lodash";
import { useLocation } from "react-router-dom";
import { usePage } from "hooks/page";

export const Layout: React.FC<LayoutProps> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isUser } = usePermissions();
  const tenantId = localStorage.getItem(TENANT_ID);

  const { provinces, setProvinces, districts, setDistricts, wards, setWards } =
    useLocationData();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { isPageReady } = usePage();

  const getProvinces = async () => {
    if (!provinces || provinces.length === 0) {
      const dataJson = localStorage.getItem(LOCATION_PROVINCES) || "[]";
      const dataCurrent = JSON.parse(dataJson);

      if (!dataCurrent || dataCurrent.length === 0) {
        const { data } = await getLocationList(API_PATH.provinces);
        localStorage.setItem(LOCATION_PROVINCES, JSON.stringify(data));
        setProvinces(data as ILocation[]);
      } else {
        setProvinces(dataCurrent);
      }
    }
  };

  const getDistricts = async () => {
    if (!districts || Object.keys(districts).length === 0) {
      const dataJson = localStorage.getItem(LOCATION_DISTRICTS) || "[]";
      const dataCurrent = JSON.parse(dataJson);

      if (!dataCurrent || dataCurrent.length === 0) {
        const { data } = await getLocationList(API_PATH.districts);
        const groupData = groupBy(data, "provinceId");
        localStorage.setItem(LOCATION_DISTRICTS, JSON.stringify(groupData));
        setDistricts(groupData);
      } else {
        setDistricts(dataCurrent);
      }
    }
  };

  const getWards = async () => {
    if (!wards || Object.keys(wards).length === 0) {
      const dataJson = localStorage.getItem(LOCATION_WARDS) || "[]";
      const dataCurrent = JSON.parse(dataJson);

      if (!dataCurrent || dataCurrent.length === 0) {
        const { data } = await getLocationList(API_PATH.wards);
        const groupData = groupBy(data, "districtId");
        localStorage.setItem(LOCATION_WARDS, JSON.stringify(groupData));
        setWards(groupData);
      } else {
        setWards(dataCurrent);
      }
    }
  };

  const getLocationData = async () => {
    await getProvinces();
    await getDistricts();
    await getWards();
  };

  React.useEffect(() => {
    if (isUser() && !tenantId) {
      navigate(PATH.myTenants);
    }
    getLocationData();
  }, []);

  // Users need to choose the Tenant to access
  if (isUser() && !tenantId) {
    return null;
  }

  return (
    <AntdLayout
      style={{ minHeight: "100vh", flexDirection: "row", display: "flex" }}
    >
      {Sider && <Sider />}
      <AntdLayout
        style={{
          flex: 1,
          width: 0,
        }}
      >
        <AntdLayout.Content>
          <div
            style={{
              minHeight: 360,
            }}
          >
            {children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout.Content>
        {Footer && <Footer />}
      </AntdLayout>
    </AntdLayout>
  );
};
