import { useCallback, useMemo, useState } from "react";
import { Grid, Sider as DefaultSider, Affix } from "@pankod/refine-antd";
import { Menu } from "antd";
import { useTitle, useMenu, useNavigation } from "@pankod/refine-core";
import { Title as DefaultTitle } from "../title";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import usePermissions from "hooks/permission";
import Setting from "./Setting";
import { useLocation } from "react-router-dom";

import "./styles.less";
import { PATH } from "configs/path";
import styled from "styled-components";

type IItemMenu = ItemType & { orderIndex: number };

const Styled = styled.div<{
  isCollapse: boolean;
  isMobile: boolean;
  visiblePopoverSetting: boolean;
  visibleMenuTenantDropdown: boolean;
}>`
  position: ${(props) => (props.isMobile ? "fixed" : "relative")};
  top: 0;
  bottom: 0;
  height: ${(props) => (props.isMobile ? "auto" : "100vh")};
  width: ${({ isMobile, isCollapse }) =>
    isCollapse ? 270 : isMobile ? 0 : 80}px;
  transition: all 0.2s;

  .sider-main {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: ${({ visiblePopoverSetting, isMobile, visibleMenuTenantDropdown }) =>
      (visiblePopoverSetting || visibleMenuTenantDropdown) && !isMobile
        ? "270px !important"
        : "100%"};
    transition: all 0.2s;
    background: #414e62;

    &:hover {
      ${({ isMobile }) => (isMobile ? "" : "width: 270px !important")};
    }

    .sider-container {
      width: 100%;
      height: 100%;
      position: relative;

      &:hover {
        .btn-action-collapse {
          display: block;
        }
      }

      .btn-action-collapse {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: pink;
        right: -${({ isMobile }) => (isMobile ? 20 : 10)}px;
        display: ${({ isMobile }) => (isMobile ? "block" : "none")};
      }

      .sider-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

const defaultOpenKeys = [
  `/${PATH.workId}`,
  `/${PATH.report}`,
  `/${PATH.sales}`,
  `/${PATH.eOffice}`,
  `/${PATH.system}`,
  `/${PATH.estimate}`,
  `/${PATH.projectManagement}`,
];
export const Sider: typeof DefaultSider = () => {
  const [collapsed, setCollapse] = useState<boolean>(true);
  const [isHover, setIsHover] = useState(false);
  const Title = useTitle();
  const { menuItems, selectedKey } = useMenu();
  const { pathname } = useLocation();

  const [visiblePopoverSetting, setVisiblePopoverSetting] = useState(false);
  const [visibleTenantDropdown, setVisibleTenantDropsdown] = useState(false);

  const toggleTenantDropdown = () => setVisibleTenantDropsdown((prev) => !prev);
  const togglePopoverSetting = () => setVisiblePopoverSetting((prev) => !prev);
  const onCloseSetting = () => setVisiblePopoverSetting(false);

  const breakpoint = Grid.useBreakpoint();

  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const RenderToTitle = Title ?? DefaultTitle;

  const { push } = useNavigation();
  const { checkView } = usePermissions();

  const isLastWrapperIndex = useCallback(
    (index: number) => menuItems.length === index + 1,
    [menuItems]
  );

  const convertToPathArray = () => {
    const splitPathName = pathname.split("/").filter(Boolean);

    return splitPathName.map(
      (segment, index) => `/${splitPathName.slice(0, index + 1).join("/")}`
    );
  };

  const openKey = convertToPathArray();

  const isApproval = selectedKey.indexOf("/approval/");

  let sKey = selectedKey;

  if (isApproval > -1) {
    sKey = sKey.substring(0, isApproval);
  }

  const convertMenu =
    (wrapper?: boolean) =>
    (data: any, index: number): any => {
      const isSelected = sKey === data.route;
      const parentSelect = sKey.includes(data.name);
      const isHasChildren = !!data?.children?.length;

      if (
        !checkView(data?.options?.permission || data.name) &&
        !data.options?.allAccess
      )
        return null;

      const item = {
        icon: data?.icon,
        label: data?.label,
        key: data?.key,
        style: {
          background: isSelected ? "#4F6688" : "transparent",
          color: "#FFFFFF",
          borderRight: isSelected ? "6px solid #85A5FF" : "unset",
          whiteSpace: "no-wrap",
          fontWeight: wrapper ? "bold" : "normal",
        },

        className: wrapper
          ? `${!isLastWrapperIndex(index) ? "menu-parent-item" : ""} ${
              parentSelect ? "menu-parent-item-selected" : ""
            }`
          : "menu-child",
      };

      if (!isHasChildren) return item;

      const isHasChildrenAfterCheckPermission = !!data.children
        .map(convertMenu())
        .filter(Boolean).length;

      if (!isHasChildrenAfterCheckPermission) return null;

      return {
        ...item,
        children: data.children.map(convertMenu()).filter(Boolean),
      };
    };

  const items = useMemo<IItemMenu[]>(
    () => menuItems.map(convertMenu(true)),
    [menuItems]
  );

  const toggleCollapse = () => setCollapse((prev) => !prev);

  const onHover = () => setIsHover(true);

  const onUnHover = () => setIsHover(false);

  return (
    <Affix style={{ zIndex: 1000 }} offsetTop={0}>
      <Styled
        isCollapse={collapsed}
        isMobile={isMobile}
        visibleMenuTenantDropdown={visibleTenantDropdown}
        visiblePopoverSetting={visiblePopoverSetting}
      >
        <div
          className="sider-main"
          onMouseEnter={onHover}
          onMouseLeave={onUnHover}
        >
          <div className="sider-container">
            <div className="btn-action-collapse" onClick={toggleCollapse} />
            <div className="sider-box">
              <RenderToTitle
                collapsed={
                  collapsed ||
                  isHover ||
                  visiblePopoverSetting ||
                  visibleTenantDropdown
                }
                visible={visibleTenantDropdown}
                toggle={toggleTenantDropdown}
              />
              {(collapsed ||
                isHover ||
                visiblePopoverSetting ||
                visibleTenantDropdown) && (
                <small
                  style={{
                    position: "absolute",
                    top: 48,
                    right: 12,
                    color: "#FFFFFF",
                  }}
                >
                  {process.env.REACT_APP_VERSION}
                </small>
              )}
              <Menu
                selectedKeys={[sKey]}
                defaultOpenKeys={
                  openKey?.[0] === `/${PATH.dashboards}` ||
                  openKey?.[0] === `/${PATH.login}` ||
                  !openKey.length
                    ? defaultOpenKeys
                    : openKey
                }
                mode={"inline"}
                className="main-menu"
                onClick={({ key, ...a }) => {
                  if (!breakpoint.lg) {
                    setCollapse(false);
                  }
                  push(key as string);
                }}
                inlineCollapsed={
                  !collapsed &&
                  !isHover &&
                  !isMobile &&
                  !visiblePopoverSetting &&
                  !visibleTenantDropdown
                }
                items={items}
              />

              <Setting
                collapsed={
                  collapsed ||
                  isHover ||
                  visiblePopoverSetting ||
                  visibleTenantDropdown
                }
                isMobile={isMobile}
                togglePopover={togglePopoverSetting}
                visiblePopoverSetting={visiblePopoverSetting}
                onClose={onCloseSetting}
              />
            </div>
          </div>
        </div>
      </Styled>
    </Affix>
  );
};
