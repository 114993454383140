import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Row, Tag, Typography } from "@pankod/refine-antd";
import { useTranslate } from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import { PERMISSION_PATH } from 'configs/path';
import usePermissions from 'hooks/permission';
import { ActionBusiness, ObjectActionBusiness } from 'interfaces/CaseId';
import { FC, memo } from "react";
const { Text } = Typography;
const { Panel } = Collapse;

export const groupById = (array: any) => {

    const newArr: Record<string, any> = {}
    if (!Array.isArray(array)) return newArr;
  
    array.forEach((item: any) => {
      if (!newArr[item.groupId]) {
        newArr[item.groupId] = []
      }
      newArr[item.groupId].push(item)
    })
  
    return newArr;
  }

const getColorFromStatus = (status: string) => {
    switch (status) {
      case "0":
        return "warning"
      case "1":
      case "3":
        return "blue"
      case "2":
        return "success"
      case "4":
      case "5":
            return "error"
      default:
        return "default"
    }
  }

export const CardActionBusinessPlan: FC<ActionBusiness> = memo((props: ActionBusiness) => {
    const t = useTranslate();
    const {id} = useParams();
    const {checkCreate} = usePermissions();

    return (
        <Card
            key={props.featureCode}
            size="small"
            title={t(`common.${props.featureCode}`)}
            headStyle={{ backgroundColor: "#F0F0F0", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
            extra={
                <Button type="primary" shape="default" icon={<PlusOutlined />} disabled={!checkCreate(PERMISSION_PATH[props.featureCode])} href={`../../../${props.createUrl}?caseId=${id}`} target='_blank' />
            }
            style={{ width: "100%" }}
        >
            {props.objects.length > 0 ? (
                <>
                    {props.objects.map((item: ObjectActionBusiness) => (
                        <Row key={item.id} gutter={[8,8]} justify="space-between" style={{padding: "2px"}}>
                            <Col span={12}>
                            <a href={`../../../${item.detailUrl}`} target="_blank" rel="noopener noreferrer">
                                {item.code ?? t("common.unknown.code")}
                            </a>  
                            </Col>
                            <Col span={10} style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-end"}}>
                                <Tag color={getColorFromStatus(item.statusCode)} style={{marginRight: "0px"}}>{item.status}</Tag>
                            </Col>    
                        </Row>
                    ))}
                </>
            ) : (
                <Text type="secondary">{t("common.emptyData")}</Text>
            )}
        </Card>
    )
});

const ActionBusinessPlan: FC<ActionBusiness[]> = memo((props: ActionBusiness[]) => {
    const arr = Object.values(props);
    return (
        <Collapse key={arr[0].groupdId} defaultActiveKey={"2"}  className="mis-case-detail-collapse" bordered={false} style={{ backgroundColor: "white" }}>
            <Panel header={arr[0].groupName} key={"2"}>
                {arr.map((item: ActionBusiness) => {
                    return (
                        <div key={`${item.groupdId}_${item.featureCode}`} style={{ paddingBottom: "8px" }}>
                            <CardActionBusinessPlan {...item} />
                        </div>
                    )
                })}
            </Panel>
        </Collapse>
    )
});

export default ActionBusinessPlan;
