import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useTable,
  useNavigation,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  getDefaultSortOrder,
  Space,
  Tag,
  Icons,
  Select,
  Radio,
} from "@pankod/refine-antd";
import { IBaseConfig } from "interfaces";
import { EmptyData, SearchBox, ButtonTooltip, DataText } from "components";
import { useNavigate } from "react-router-dom";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { ConfigStatusTag } from "components/StatusTag";

export const UnitsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { create, edit } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);

  const {
    tableQueryResult: { data: dataTable },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    sorter,
    setSorter,
    setFilters,
  } = useTable<IBaseConfig>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const onSearch = (value: string) => {
    setCurrent(1);
    if (value) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  return (
    <>
      <List
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.configUnits)}
            {...checkCreateHaveHelperText(PATH.configUnits)}
          >
            {t("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Space align="start">
            <SearchBox
              placeholder={t("units.searchPlaceHolder")}
              onSearch={onSearch}
            />
          </Space>
          <div>
            <Radio.Group
              defaultValue={-1}
              buttonStyle="solid"
              onChange={(e) => {
                setCurrent(1);
                setFilters([
                  {
                    field: "status",
                    operator: "eq",
                    value: e?.target?.value === -1 ? "" : e?.target?.value,
                  },
                ]);
              }}
              style={{ display: "block", marginBottom: 16 }}
            >
              <Radio.Button value={-1}>{t("common.all")}</Radio.Button>
              <Radio.Button value={true}>
                {t("common.status.active")}
              </Radio.Button>
              <Radio.Button value={false}>
                {t("common.status.deactivate")}
              </Radio.Button>
            </Radio.Group>
          </div>
          <Table
            dataSource={dataTable?.data}
            rowKey="id"
            locale={{
              emptyText: (
                <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
              ),
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  navigate(`show/${record.id}`);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: t("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{t("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, f, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field: newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            showSorterTooltip={false}
            scroll={{ x: "auto" }}
          >
            <Table.Column
              key="id"
              title={t("common.no")}
              render={(_, __, index) => (current - 1) * pageSize + index + 1}
            />
            <Table.Column
              dataIndex="name"
              title={t("units.fields.name")}
              render={(value) => <DataText value={value} />}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="status"
              title={t("common.config.status")}
              render={(value) => <ConfigStatusTag status={value} />}
              defaultSortOrder={getDefaultSortOrder("status", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="updatedName"
              title={t("common.updatedUsername")}
              render={(value) => <DataText className="primary" value={value} />}
              defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="updatedDate"
              title={t("common.updatedDate")}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              width="200px"
              sorter
              render={(value) => value && <DateTimeFieldCustom value={value} />}
            />
            <Table.Column
              dataIndex="createdName"
              title={t("common.createdUsername")}
              render={(value) => <DataText className="primary" value={value} />}
              defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="createdDate"
              title={t("common.createdDate")}
              defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
              sorter
              render={(value) => value && <DateTimeFieldCustom value={value} />}
            />
            <Table.Column<IBaseConfig>
              title={t("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => (
                <ButtonTooltip
                  type="text"
                  icon={<Icons.EditOutlined />}
                  className="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    edit(PATH.configUnits, record.id || "");
                  }}
                  {...checkEditHaveHelperText(PATH.configUnits, !record.status)}
                />
              )}
            />
          </Table>
        </div>
      </List>
    </>
  );
};
