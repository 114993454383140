import { Typography, Button, ButtonProps, Tooltip } from "@pankod/refine-antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { useTranslate } from "@pankod/refine-core";
const { Text } = Typography;

interface Props {
  helpertext?: string;
  placement?: TooltipPlacement;
}

export const ButtonTooltip = (props: Props & ButtonProps) => {
  const t = useTranslate();

  return (
    <Tooltip
      arrowPointAtCenter
      placement={props?.placement || "topRight"}
      title={t(props?.helpertext || "")}
    >
      <Button {...props} />
    </Tooltip>
  );
};
