import {
  Card,
  Checkbox,
  Descriptions,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";
import { SubmitConditionInfoType } from "api/enums";
import { CheckBoxError } from "components/ErrorCheckbox";
import { ObjectSubmitCondition } from "interfaces";
import { FC, memo, useMemo } from "react";

const { Text } = Typography;

interface RecordProps {
  objectSubmitConditions: ObjectSubmitCondition[] | undefined;
}
const SubmitionInfomationSettlement: FC<RecordProps> = memo(
  (record: RecordProps) => {
    const translate = useTranslate();

    const columns = useMemo<ColumnsType<ObjectSubmitCondition>>(
      () => [
        {
          title: translate("STT"),
          width: 50,
          dataIndex: "id",
          key: "id",
          render: (_, record, index) => index + 1,
        },
        {
          title: translate("Điều kiện"),
          width: 200,
          dataIndex: "description",
          key: "description",
          render: (_, record, index) => (
            <Text>{record?.submitConditionInfo?.description}</Text>
          ),
        },
        {
          title: translate("Thông tin"),
          width: 200,
          dataIndex: "url",
          key: "url",
          render: (_, record, index) => (
            <div
              dangerouslySetInnerHTML={{
                __html: record?.submitConditionInfo?.url,
              }}
            />
          ),
        },
        {
          title: translate("Thỏa điều kiện"),
          width: 120,
          dataIndex: "action",
          key: "action",
          align: "center",
          render: (_, record, index) => {
            const { submitConditionInfo, isOk } = record;
            return submitConditionInfo?.type ===
              SubmitConditionInfoType.Manual ? (
              <Checkbox checked={isOk} />
            ) : isOk ? (
              <Checkbox checked />
            ) : (
              <CheckBoxError checked />
            );
          },
        },
      ],
      []
    );

    return (
      <Card style={{ backgroundColor: "#ffffff" }}>
        <Descriptions
          column={1}
          layout="vertical"
          title={translate("Thông tin cần để chuyển DTHĐ")}
        />
        <Table
          pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
          rowKey="id"
          columns={columns}
          dataSource={record?.objectSubmitConditions}
          scroll={{ x: 570 }}
          style={{ paddingLeft: 16 }}
          size="small"
        />
      </Card>
    );
  }
);

export default SubmitionInfomationSettlement;
