import { FC } from "react";
import { useController } from "./controller";
import { EditCustom } from "components/layout";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Typography } from "@pankod/refine-antd";
import CustomerForm from "../create/components/Form";

const { Title, Text } = Typography;

const CustomerEdit: FC<IResourceComponentsProps> = (props) => {
  const {
    form,
    formProps,
    isLoading,
    saveButtonProps,
    visibleModalConfirmEdit,
    record,
    _mounted,
    onSubmit,
    toggleModalConfirm,
    translate,
    onFishValidateform,
  } = useController();

  return (
    <EditCustom
      {...props}
      record={record}
      title={
        <Text style={{ marginBottom: 0 }}>
          {translate("customer.updateCustomer")}:{" "}
          <Text className="primary">{record?.name}</Text>
        </Text>
      }
      titlePopupConfirm={translate("employees.editConfirm")}
      saveButtonProps={{
        ...saveButtonProps,
      }}
      visibleShowModal={visibleModalConfirmEdit}
      setVisibleShowModal={toggleModalConfirm}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Title level={4}>{translate("customer.detailedInformation")}</Title>
      <CustomerForm
        _mounted={_mounted.current}
        form={form}
        formProps={formProps}
        isEdit
        onFinishedFormValidate={onFishValidateform}
      />
    </EditCustom>
  );
};

export default CustomerEdit;
