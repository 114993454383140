import { useTranslate } from "@pankod/refine-core";
import { Col, Form, Input, Row, Typography } from "antd";
import { memo } from "react";
import { Regs, uppercase } from "utils/commons";
const { Title } = Typography;

const Policy = memo(() => {
  const translate = useTranslate();
  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Điều khoản và điều kiện")}</Title>
      <Row style={{ paddingLeft: 16 }}>
        <Col span={12}>
          <Form.Item
            name="pricePolicy"
            label="Giá bán"
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },
              {
                max: 100,
                message: translate("errors.ER014", { max: 100 }),
              },
            ]}
          >
            <Input placeholder="Nhập điều kiện và điều khoản" />
          </Form.Item>
        </Col>
        <Col span={12} />
        <Col span={12}>
          <Form.Item
            name="deliveryTimePolicy"
            label="Thời gian giao hàng"
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },
              {
                max: 100,
                message: translate("errors.ER014", { max: 100 }),
              },
            ]}
          >
            <Input placeholder="Nhập điều kiện và điều khoản" />
          </Form.Item>
        </Col>
        <Col span={12} />
        <Col span={12}>
          <Form.Item
            name="durationPolicy"
            label="Thời gian báo giá"
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },

              {
                max: 100,
                message: translate("errors.ER014", { max: 100 }),
              },
            ]}
          >
            <Input placeholder="Nhập điều kiện và điều khoản" />
          </Form.Item>
        </Col>
        <Col span={12} />
      </Row>
    </div>
  );
});

export default Policy;
