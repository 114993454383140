import { Descriptions, Table, Typography } from "@pankod/refine-antd";
import { FC, memo, useContext, useMemo } from "react";
import { ContractShowConText } from "../..";
import { useTranslate } from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";
import { ContractImplementProgress as TContractImplementProgress } from "interfaces/Contract";
import { Currency } from "components/Currency";
import { DataText } from "components";
import { formatISODateTimeToView, isEmpty } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
const { Text } = Typography;

const ContractImplementProgress: FC = memo(() => {
  const record = useContext(ContractShowConText);
  const translate = useTranslate();

  const columns = useMemo<ColumnsType<TContractImplementProgress>>(
    () => [
      {
        title: translate("Đợt"),
        width: 150,
        dataIndex: "paymentTerm",
        key: "paymentTerm",
        render: (value) => <Text>{value || "-"}</Text>,
      },
      {
        title: translate("Ngày triển khai"),
        width: 200,
        dataIndex: "implementationDate",
        key: "implementationDate",
        render: (value) => (
          <DataText
            value={value ? formatISODateTimeToView(value, DATE_FORMAT) : "-"}
          />
        ),
      },
      {
        title: translate("Ngày hoàn thành"),
        width: 200,
        dataIndex: "completionDate",
        key: "completionDate",
        render: (value) => (
          <DataText
            value={value ? formatISODateTimeToView(value, DATE_FORMAT) : "-"}
          />
        ),
      },

      {
        title: translate("Giá trị hóa đơn"),
        width: 200,
        dataIndex: "description",
        key: "description",
        render: (value) => <DataText value={value || "-"} />,
      },
    ],
    []
  );

  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={2}
        title={translate("Tiến độ triển khai")}
        layout="vertical"
      />
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={record?.contractImplementProgress}
        scroll={{ x: 800 }}
      />
    </div>
  );
});

export default ContractImplementProgress;
