import { Card, Descriptions, Icons, Modal, notification, Tag, Typography } from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate, useNavigation, useShow, useUpdate } from "@pankod/refine-core"
import { showErrorToast } from "api/common"
import { ButtonTooltip, DataText, LinkText } from "components"
import { ConfigDisplayText } from "components/ConfigDisplayText"
import { DateTimeFieldCustom } from "components/DateTimeTextField"
import { ShowCustom } from "components/layout"
import { ConfigStatusTag } from "components/StatusTag"
import { PATH } from "configs/path"
import usePermissions from 'hooks/permission'
import { ICostConfig, ISupplierConfig } from "interfaces"
const { Text } = Typography;

export const SupplierShow: React.FC<IResourceComponentsProps> = (props) => {
    const t = useTranslate()
    const { edit } = useNavigation()
    const { checkEditHaveHelperText } = usePermissions()
    const { mutate: mutateUpdate, isLoading: isUpdateLoading } = useUpdate<ISupplierConfig>()

    const { queryResult } = useShow<any>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const confirmToggleStatus = () => {
        Modal.confirm({
            title: record?.status ? t('suppliers.message.deactivateConfirm') : t('suppliers.message.activeConfirm'),
            content: record?.status ? t('suppliers.message.deactivateConfirmDesc') : '',
            okText: t('buttons.confirm'),
            okButtonProps: {
                danger: record?.status
            },
            cancelText: t('buttons.reject'),
            onOk: () => {
                toggleStatus()
            }
        })
    }

    const toggleStatus = () => {
        mutateUpdate({
            resource: PATH.configSuppliers,
            id: record?.id,
            metaData: {
                type: record?.status ? '/deactivate' : '/activate'
            },
            values: {},
        }, {
            onSuccess: () => {
                notification.success({
                    message: record?.status ? t('common.config.deactiveSuccess') : t('common.config.activeSuccess'),
                })
            },
            onError: (error) => {
                showErrorToast(error)
            },
        })
    }

    return (
        <ShowCustom
            {...props}
            isLoading={isLoading}
            title={
                <Text style={{ marginBottom: 0 }}>{t("suppliers.suppliers")}: <span className="primary">{record?.name}</span> </Text>
            }
            breadcrumbText={record?.name}
            contentStyles={{ backgroundColor: 'transparent', padding: 0 }}
            bodyStyle={{ padding: 0 }}
            headerButtons={
                <>
                    <ButtonTooltip
                        type="default"
                        icon={<Icons.EditOutlined />}
                        onClick={() => edit(PATH.configSuppliers, record.id)}
                        {...checkEditHaveHelperText(PATH.configSuppliers, !record?.status)}
                    >
                        {t('buttons.edit')}
                    </ButtonTooltip>

                    {
                        !record?.status &&
                        <ButtonTooltip
                            type="primary"
                            icon={<Icons.CheckCircleOutlined />}
                            onClick={confirmToggleStatus}
                            {...checkEditHaveHelperText(PATH.configSuppliers)}
                        >
                            {t('buttons.active')}
                        </ButtonTooltip>
                    }
                    {
                        record?.status &&
                        <ButtonTooltip
                            type="primary"
                            danger
                            icon={<Icons.CloseCircleOutlined />}
                            onClick={confirmToggleStatus}
                            {...checkEditHaveHelperText(PATH.configSuppliers)}
                        >
                            {t('buttons.deactivate')}
                        </ButtonTooltip>
                    }

                </>
            }
        >
            <Card>
                <Descriptions
                    column={3}
                    title={t("common.titles.detail")}
                    layout="vertical"
                >
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.code")}
                    >
                        <DataText value={record?.code} />
                    </Descriptions.Item>

                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.name")}
                    >
                        <DataText value={record?.name} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("common.config.status")}
                    >
                        <ConfigStatusTag status={record?.status} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.abbreviation")}
                    >
                        <DataText value={record?.abbreviation} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.taxNo")}
                    >
                        <DataText value={record?.taxNo} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.address")}
                    >
                        <DataText value={record?.address} />
                    </Descriptions.Item>

                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.phone")}
                    >
                        <DataText value={record?.phoneNo} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.businessIndustry")}
                    >
                        <ConfigDisplayText value={record?.businessIndustry?.name} status={record?.businessIndustry?.status} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.email")}
                    >
                        <DataText value={record?.email} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.website")}
                    >
                        <DataText value={record?.website} />
                    </Descriptions.Item>

                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        span={2}
                        label={t("suppliers.fields.note")}
                    >
                        <DataText value={record?.note} className="line-break" />
                    </Descriptions.Item>

                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("common.updatedDate")}
                    >
                        <DateTimeFieldCustom value={record?.updatedDate} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        span={2}
                        labelStyle={{ fontWeight: 500 }}
                        label={t("common.updatedName")}
                    >
                        <DataText value={record?.updatedName} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("common.createdDate")}
                    >
                        <DateTimeFieldCustom value={record?.createdDate} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        span={2}
                        labelStyle={{ fontWeight: 500 }}
                        label={t("common.createdName")}
                    >
                        <DataText value={record?.createdName} />
                    </Descriptions.Item>

                </Descriptions>
            </Card>

            <Card style={{marginTop: 10}}>
                <Descriptions
                    column={3}
                    title={t("common.title.contactInformation")}
                    layout="vertical"
                >
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.contactName")}
                    >
                        <DataText value={record?.contactName} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.contactPositionName")}
                    >
                        <DataText value={record?.contactPositionName} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.phone")}
                    >
                        <DataText value={record?.contactPhoneNo} />
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ fontWeight: 500 }}
                        label={t("suppliers.fields.email")}
                    >
                        <DataText value={record?.contactEmail} />
                    </Descriptions.Item>
                </Descriptions>
            </Card>

        </ShowCustom>
    )
}