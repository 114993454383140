import { Col, Form, FormInstance, FormProps, Row } from "@pankod/refine-antd";
import { FC, memo } from "react";
import { IForm } from "../..";
import ProjectInfo from "../ProjectInfo";
import ContactInfo from "../ContactInfo";
import { ConsultDepartment, DeployingDepartment } from "../TableDepartment";
interface Props {
  form: FormInstance<IForm>;
  formProps: FormProps<IForm>;
  onFinishedFormValidate: () => void;
}
const ProjectForm: FC<Props> = memo((props) => {
  const { formProps, onFinishedFormValidate } = props;
  return (
    <Form
      {...formProps}
      initialValues={{
        ...formProps.initialValues,
        status: formProps?.initialValues?.projectStatus?.id,
        caseId: formProps?.initialValues?.case?.id,
      }}
      onFinish={onFinishedFormValidate}
      layout="vertical"
      style={{ display: "flex", flexDirection: "column", gap: 8 }}
    >
      <Row gutter={8}>
        <Col span={12}>
          <ProjectInfo />
        </Col>
        <Col span={12}>
          <ContactInfo />
        </Col>
      </Row>
      <ConsultDepartment />
      <DeployingDepartment />
    </Form>
  );
});

export default ProjectForm;
