import { getLinkPrewviewFile } from "api";

interface IGetLinkView {
    fileUrl: string;
    path: string;
}
export const onGetLinkView = (props: IGetLinkView) => async () => {
    const res = await getLinkPrewviewFile(
        props.path,
        props.fileUrl
    );
    const url: string = res?.data as unknown as string;
    window.open(url, "_blank");
};