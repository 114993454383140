import { FC } from "react";
import { useController } from "./controller";
import { CreateCustom } from "components/layout";
import { Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import CustomerForm from "./components/Form";

const { Title } = Typography;

const CustomerCreate: FC<IResourceComponentsProps> = (props) => {
  const {
    formProps,
    isLoading,
    saveButtonProps,
    visibleModalConfirmCreate,
    form,
    onSubmit,
    toggleModalConfirm,
    translate,
  } = useController();

  return (
    <CreateCustom
      {...props}
      title={translate("customer.createCustomers")}
      onSubmit={onSubmit}
      visibleShowModal={visibleModalConfirmCreate}
      setVisibleShowModal={toggleModalConfirm}
      saving={isLoading}
      confirmModalProps={{ title: translate("customer.confirmCreateCustomer") }}
      saveButtonProps={{
        ...saveButtonProps,
        title: translate("customer.createNewCustomers"),
      }}
    >
      <Title level={4}>{translate("customer.detailedInformation")}</Title>
      <CustomerForm
        _mounted
        form={form}
        formProps={formProps}
        onFinishedFormValidate={() => toggleModalConfirm(true)}
      />
    </CreateCustom>
  );
};

export default CustomerCreate;
