import { useTable } from "@pankod/refine-core";
import { IQuotationRow } from "interfaces/Quotation";

export const useController = () => {
  const table = useTable<IQuotationRow>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  return {
    table,
  };
};
