import { Icons } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import { Typography } from "antd";
import { ButtonTooltip } from "components";
import { ShowCustom } from "components/layout";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { FC, createContext, memo } from "react";
import ProjectInfo from "./components/ProjectInfo";
import ContactInfo from "./components/ContactInfo";

import Project, { ProjectStatusCode } from "interfaces/Project";
import TableDepartment from "./components/TableDepartment";
const { Text } = Typography;

export enum TableType {
  CONSULT = "consult",
  IMPLEMENT = "implement",
}

export const getStatus = (
  status: ProjectStatusCode = ProjectStatusCode.Project_Pending
) => {
  let agr: { color: string } = { color: "success" };

  switch (status) {
    case ProjectStatusCode.Project_Not_Started:
      agr = { color: "default" };
      break;
    case ProjectStatusCode.Project_In_Progress:
      agr = { color: "blue" };
      break;
    case ProjectStatusCode.Project_Pending:
      agr = { color: "warning" };
      break;
    case ProjectStatusCode.Project_Off_Track:
      agr = { color: "volcano" };
      break;
    case ProjectStatusCode.Project_Waiting_Confirm:
      agr = { color: "cyan" };
      break;
    case ProjectStatusCode.Project_Completed:
      agr = { color: "success" };
      break;
    case ProjectStatusCode.Project_Cancelled:
      agr = { color: "error" };
      break;
    case ProjectStatusCode.Project_Close:
      agr = { color: "purple" };
      break;
  }

  return agr;
};

export const ProjectShowContext = createContext<Partial<Project>>({});
const ProjectShow: FC<IResourceComponentsProps> = memo((props) => {
  const {
    queryResult: { data, isLoading },
  } = useShow<Project>();
  const record = data?.data;
  const { edit } = useNavigation();
  const translate = useTranslate();
  const { checkEditHaveHelperText } = usePermissions();

  return (
    <ProjectShowContext.Provider value={record || {}}>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        breadcrumbText={record?.case?.name}
        title={
          <Text style={{ marginBottom: 0 }}>
            {translate("Dự án")}:{" "}
            <Text className="primary">{record?.case?.name}</Text>
          </Text>
        }
        headerButtons={
          <>
            <ButtonTooltip
              type="primary"
              icon={<Icons.EditOutlined />}
              onClick={() => edit(PATH.projects, record?.id!)}
              {...checkEditHaveHelperText(PATH.projects)}
            >
              {translate("actions.edit")}
            </ButtonTooltip>
          </>
        }
        contentStyles={{
          padding: 0,
          background: "transparent",
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <ProjectInfo />
          <ContactInfo />
          <TableDepartment
            dataTable={record?.projectMemberTypeConsulting}
            label={translate("Ban dự án tư vấn")}
          />
          <TableDepartment
            dataTable={record?.projectMemberTypeImplementation}
            label={translate("Ban dự án triển khai")}
          />
        </div>
      </ShowCustom>
    </ProjectShowContext.Provider>
  );
});

export default ProjectShow;
