import { Icons, Typography, notification, useForm } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { CreateCustom } from "components/layout";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ContractForm from "./components/Form";
import IContract, { ContractStatusCode, PartyType } from "interfaces/Contract";
import { formatOnlyDateToUTC, mappingErrorFromApi } from "utils/commons";
import { useParams } from "react-router-dom";
import { PATH } from "configs/path";

const { Text } = Typography;
export interface IForm {
  caseId: string;
  name: string;
  contractType: string;
  contractTypeCode: string;
  userParty: {
    partyId: string;
    partyType: PartyType;
    id?: string;
  };
  contractStatus: string;
  contractStatusCode: ContractStatusCode;
  serviceProviderParty: {
    partyId: string;
    partyType: PartyType;
    id: string;
  };
  thirdParty: {
    partyId: string;
    partyType: PartyType;
    id: string;
  };
  customerContractCode: string;
  signDate: Date;
  validDate: Date;
  expirationDate: Date;
  description: string;
  attachments: {
    [key: string]: {
      fileId: string;
      description: string;
      fileName: string;
      uploadDate: Date;
      fileUrl: string;
    };
  };
  contractProducts: {
    [key: string]: {
      productId: string;
      partNumber: string;
      description: string;
      quantity: number;
      unitId: string;
      unitPrice: number;
      taxPercent: number;
      id?: string;
    };
  };
  contractPaymentProcesses: {
    [key: string]: {
      term: string;
      paymentValue: number;
      estimatedPaymentDate: Date;
      id?: string;
      note?: string;
    };
  };
  contractInvoicePlans: {
    [key: string]: {
      invoiceTerm: string;
      estimatedInvoiceDate: Date;
      invoiceValue: number;
      id?: string;
    };
  };

  contractGuaranteeInfos: {
    [key: string]: {
      guaranteeType?: string;
      guaranteeValue?: number;
      guaranteeIssueParty?: string;
      guaranteeCondition?: string;
      estimatedIssueDate?: Date;
      validPeriod?: number;
      attachment?: string | null;
      attachmentName?: string;
      attachmentUrl?: string;
      id?: string;
    };
  };

  contractImplementProgress: {
    [key: string]: {
      paymentTerm: string;
      implementationDate: Date;
      completionDate: Date;
      description: string;
    };
  };

  originalContractId: string;
}

export type ContractTypeConstraint = {
  requireThirdParty: boolean;
  requireOriginal: boolean;
  requireProduct: boolean;
  userPartyEditable: boolean;
  providerPartyEditable: boolean;
};

export interface ContextState {
  initialValue?: Partial<IContract>;
  isEdit: boolean;
  isHasContractCode: boolean;
  contractTypeConstraintState: [
    Partial<ContractTypeConstraint>,
    Dispatch<SetStateAction<Partial<ContractTypeConstraint>>>
  ];
}

const initTabState: ContextState = {
  initialValue: {},
  isEdit: false,
  isHasContractCode: false,
  contractTypeConstraintState: [{}, () => {}],
};

export const ContractContext = createContext<ContextState>(initTabState);

const ContractCreate: FC<IResourceComponentsProps> = (props) => {
  const { id } = useParams();

  const { form, formProps, saveButtonProps, formLoading } = useForm<
    any,
    any,
    IForm
  >();
  const { mutate: mutateCreate, isLoading } = useCreate<{ id: string }, any>();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();
  const { show } = useNavigation();
  const translate = useTranslate();
  const [visibleModalConfirmCreate, setVisibleShowModalConfirm] =
    useState(false);

  const [contractTypeConstraint, setContractTypeConstraint] = useState<
    Partial<ContractTypeConstraint>
  >({});

  const isEdit = !!id;

  const toggleModalConfirm = (isOpen: boolean) => {
    if (isOpen) {
      const { userParty, serviceProviderParty } = form.getFieldsValue();
      if (
        userParty?.partyType !== PartyType.Tenant &&
        serviceProviderParty?.partyType !== PartyType.Tenant
      ) {
        notification.error({ message: translate("errors.ER0199") });
        return;
      }
    }
    setVisibleShowModalConfirm(isOpen);
  };

  const onSubmit = (isDraft?: boolean) => () => {
    const dataForm = form.getFieldsValue();
    const attachments = Object.values(dataForm?.attachments || {}).map(
      (at) => ({
        fileId: at?.fileId,
        description: at?.description,
      })
    );

    const contractProducts = Object.values(
      dataForm?.contractProducts || {}
    )?.map((pr) => ({ ...pr, taxPercent: pr.taxPercent / 100 }));

    const contractPaymentProcesses = Object.values(
      dataForm?.contractPaymentProcesses || {}
    )?.map((pp) => ({
      ...pp,
      estimatedPaymentDate: formatOnlyDateToUTC(pp.estimatedPaymentDate),
    }));

    const contractInvoicePlans = Object.values(
      dataForm?.contractInvoicePlans || {}
    )?.map((ip) => ({
      ...ip,
      estimatedInvoiceDate: formatOnlyDateToUTC(ip.estimatedInvoiceDate),
    }));

    const contractGuaranteeInfos = Object.values(
      dataForm?.contractGuaranteeInfos || {}
    )?.map((gi) => ({
      ...gi,
      estimatedIssueDate: formatOnlyDateToUTC(gi.estimatedIssueDate),
    }));

    const contractImplementProgress = Object.values(
      dataForm?.contractImplementProgress || {}
    )?.map((ip) => ({
      ...ip,
      completionDate: formatOnlyDateToUTC(ip.completionDate),
      implementationDate: formatOnlyDateToUTC(ip.implementationDate),
    }));

    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: translate(
            isEdit ? "Cập nhật thành công" : "Tạo mới thành công"
          ),
        });
        show(PATH.contracts, res?.data?.id);
      },
      onError: (error: any) => {
        toggleModalConfirm(false);

        const errorFieldMapping: any = {};

        mappingErrorFromApi(error, form, errorFieldMapping);
      },
    };

    const dataToRequest = {
      resource: PATH.contracts,
      values: {
        ...dataForm,
        signDate: formatOnlyDateToUTC(dataForm.signDate),
        validDate: formatOnlyDateToUTC(dataForm.validDate),
        expirationDate: formatOnlyDateToUTC(dataForm.expirationDate),
        attachments,
        contractProducts,
        contractPaymentProcesses,
        contractInvoicePlans,
        contractGuaranteeInfos,
        contractImplementProgress,
      },
      metaData: {
        type: isDraft ? "draft" : "",
      },
      id: formProps.initialValues?.id || "",
    };

    if (isEdit) {
      mutateUpdate(dataToRequest, handleResponse);
    } else {
      mutateCreate(dataToRequest, handleResponse);
    }
  };

  const validateDraft = async () => {
    try {
      await form.validateFields();
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.resolve(false);
    }
  };

  const isHasContractCode = useMemo(
    () => formProps?.initialValues?.code,
    [formProps]
  );

  useEffect(() => {
    setContractTypeConstraint({
        ...formProps?.initialValues?.contractType,
    });
  }, [formProps?.initialValues?.contractType]);

  return (
    <ContractContext.Provider
      value={{
        initialValue: formProps?.initialValues || {},
        isEdit,
        isHasContractCode,
        contractTypeConstraintState: [
          contractTypeConstraint,
          setContractTypeConstraint,
        ],
      }}
    >
      <CreateCustom
        {...props}
        title={
          isEdit ? (
            <Text style={{ marginBottom: 0 }}>
              {translate("Chỉnh sửa hợp đồng")}:{" "}
              <Text className="primary">{formProps?.initialValues?.name}</Text>
            </Text>
          ) : (
            translate("Tạo hợp đồng")
          )
        }
        onSubmit={onSubmit()}
        visibleShowModal={visibleModalConfirmCreate}
        setVisibleShowModal={toggleModalConfirm}
        saving={isLoading || isLoadingUpdate}
        confirmModalProps={{
          title: translate(
            isEdit
              ? "Bạn muốn chỉnh sửa hợp đồng này?"
              : "Bạn muốn tạo mới hợp đồng này?"
          ),
        }}
        saveButtonProps={{
          ...saveButtonProps,
          title: translate(isEdit ? "common.saveEdit" : "actions.create"),
          icon: isEdit && <Icons.SaveOutlined />,
        }}
        isLoading={formLoading}
        contentStyles={{ background: "transparent", padding: 0 }}
        bodyStyle={{ padding: 0 }}
        onDraft={onSubmit(true)}
        draftButtonProps={{
          title: isEdit ? "Lưu chỉnh sửa" : "Lưu nháp",
          hidden: isEdit,
        }}
        draftModalProps={{
          title: translate("Bạn muốn lưu nháp hợp đồng này"),
        }}
        validateDraft={validateDraft}
      >
        <ContractForm
          formProps={formProps}
          onFinishedFormValidate={() => toggleModalConfirm(true)}
          form={form}
        />
      </CreateCustom>
    </ContractContext.Provider>
  );
};

export default ContractCreate;
