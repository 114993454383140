import CaseId from "./CaseId";

export default interface IQuotation {
  id: string;
  quotationProducts: Product[];
  name: string;
  case: CaseId;
  businessPlan: {
    code: string;
    id: string;
    name: string;
  };
  customerRepresentative: string;
  customerPosition: string;
  totalBeforeTaxValue: number;
  totalTaxValue: number;
  totalAfterTaxValue: number;
  pricePolicy: string;
  durationPolicy: string;
  deliveryTimePolicy: string;
  commercialEmployee: {
    code: string;
    emailUser: string;
    fullName: string;
    id: string;
    phoneNumber: string;
  };
  code: string;
  quotationStatus: QuotationStatus;
  createdName: string;
  customerAddress: string;
  customerIdentifiedTaxCode: string;
  customerName: string;
  creater: {
    code: string,
    fullName: string,
    phoneNumber: string
  }
}

export interface IQuotationRow {
  businessPlanCode: string;
  caseCode: string;
  caseId: string;
  code: string;
  createdDate: Date;
  createdName: string;
  id: string;
  name: string;
  updatedDate: Date;
  updatedName: string;
}

export type Product = {
  afterTaxValue: number;
  beforeTaxValue: number;
  createdDate: Date;
  createdName: string;
  description: string;
  id: string;
  note: string;
  partNumber: string;
  productId: string;
  productName: string;
  quantity: number;
  taxPercent: number;
  taxValue: number;
  unitId: string;
  unitPrice: number;
  updatedDate: Date;
  updatedName: string;
  unit: {
    id: string;
    name: string;
  };
};

export enum QuotationStatus {
  Unexported = 1,
  Exported = 2,
}
