import { useForm, useSelect } from "@pankod/refine-antd";
import { useNavigation, useTable, useTranslate } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import { MarketStatus } from "interfaces/MarketArea";

export const useController = () => {
  const translate = useTranslate();

  const table = useTable<any>({
    resource: API_PATH.caseId,
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
    metaData: {
      type: "/for-sale",
    },
  });
  const { form, formProps } = useForm<any>();
  const { show } = useNavigation();
  const { selectProps: marketProps } = useSelect({
    resource: API_PATH.marketDropdownList,
    optionValue: "id",
    optionLabel: "name",
    filters: [
      { field: "marketAreaStatus", operator: "eq", value: MarketStatus.ALL },
    ],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: customerSelectProps } = useSelect({
    resource: API_PATH.caseIdCustomerDropdownlist,
    optionValue: "id",
    optionLabel: "name",

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  return {
    formProps,
    marketProps,
    customerSelectProps,
    form,
    table,
    show,
    translate,
  };
};
