import { useTranslate } from "@pankod/refine-core";
import { API_PATH, PATH } from "configs/path";
import { IHeaderTab } from "interfaces";
import CaseId from "interfaces/CaseId";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  CaseIdTab,
  ParamsConsultation,
  RenderType,
  State as ContextState,
  ParamsPrepay,
} from "./context";
import usePermissions from "hooks/permission";
import { fetchDetail } from "api";

interface State {
  record?: CaseId;
  isLoading?: boolean;
}

export const useController = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // consultation params
  const consultationTab = searchParams.get("consultationTab") || "";
  const consultationCode = searchParams.get("consultationCode") || "";
  // prepay params
  const prepayTab = searchParams.get("prepayTab") || "";
  const prepayCode = searchParams.get("prepayCode") || "";

  const renderType = searchParams.get("renderType") as RenderType;
  const approval = searchParams.get("approval") || "";

  const consultationParams: ParamsConsultation = {
    consultationCode,
    renderType,
    consultationTab,
    ...(approval ? { approval: approval === "true" } : {}),
  };

  const prepayParams: ParamsPrepay = {
    prepayCode,
    renderType,
    prepayTab,
    ...(approval ? { approval: approval === "true" } : {}),
  };

  const setParams = <T>(newParam: T) =>
    setSearchParams(
      { tab: activeTab, ...newParam },
      {
        replace: true,
      }
    );

  const translate = useTranslate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(CaseIdTab.INFO_TAB);
  const [currentRenderType, setCurrentRenderType] = useState(renderType);

  const [state, setState] = useState<State>({ isLoading: true });

  const { isLoading, record } = state;

  const { checkView } = usePermissions();

  const fetchData = async () => {
    setState({ ...state, isLoading: true });
    try {
      const res = await fetchDetail(API_PATH.caseId, id!);
      setState({ isLoading: false, record: res?.data as CaseId });
    } catch {
      setState({ ...state, isLoading: false });
    }
  };

  useEffect(() => {
    if (activeTab === CaseIdTab.INFO_TAB) {
      fetchData();
    }
  }, [activeTab]);

  const headerTabs: IHeaderTab[] = useMemo(
    () =>
      [
        {
          name: translate("caseId.caseIdDetail"),
          key: CaseIdTab.INFO_TAB,
          isActive: activeTab === CaseIdTab.INFO_TAB,
        },
        {
          name: translate("prepays.prepays"),
          key: CaseIdTab.PREPAY_TAB,
          isActive: activeTab === CaseIdTab.PREPAY_TAB,
        },
        {
          name: translate("payments.payments"),
          key: CaseIdTab.PAYMENT_TAB,
          isActive: activeTab === CaseIdTab.PAYMENT_TAB,
          disabled: false,
        },
        {
          name: translate("Tư vấn"),
          key: CaseIdTab.CONSULTATION_TAB,
          isActive: activeTab === CaseIdTab.CONSULTATION_TAB,
          disabled: !checkView(PATH.consultationRequests),
        },
      ].filter((i) => !i.disabled),

    [activeTab, checkView]
  );

  const onChangeTab = (activeKey: CaseIdTab) => setActiveTab(activeKey);

  const onTabClick = (
    activeKey: string,
    e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ) => {
    switch (activeKey) {
      case CaseIdTab.CONSULTATION_TAB:
        onChangeModeConsultation({ renderType: RenderType.LIST });
        break;
      case CaseIdTab.PREPAY_TAB:
        onChangeModePrepay({ renderType: RenderType.LIST });
        break;
    }
  };

  const setRenderType = (type: RenderType) => setCurrentRenderType(type);

  const onChangeModeConsultation = (params: ParamsConsultation) => {
    setRenderType(params.renderType!);
    setParams<ParamsConsultation>({
      renderType: params.renderType!,
      ...(params?.consultationCode
        ? { consultationCode: params?.consultationCode }
        : {}),
    });
  };

  const onChangeModePrepay = (params: ParamsPrepay) => {
    setRenderType(params.renderType!);
    setParams<ParamsPrepay>({
      renderType: params.renderType!,
      ...(params?.prepayCode ? { prepayCode: params?.prepayCode } : {}),
    });
  };

  const contextValue: ContextState = {
    tab: activeTab,
    consultation: {
      renderType: currentRenderType,
      setRenderType,
      onChangeMode: onChangeModeConsultation,
      param: {
        params: consultationParams,
        setParams,
      },
    },
    prepay: {
      renderType: currentRenderType,
      setRenderType,
      onChangeMode: onChangeModePrepay,
      param: {
        params: prepayParams,
        setParams,
      },
    },

    caseId: record,
  };

  return {
    activeTab,
    record,
    isLoading,
    translate,
    onChangeTab,
    headerTabs,
    onTabClick,
    contextValue,
  };
};
