import {
  Descriptions,
  Form,
  FormInstance,
  FormProps,
  Input,
  Radio,
  Select,
  Typography,
  notification,
  useSelect,
} from "@pankod/refine-antd";
import {
  useCreate,
  useOne,
  useTranslate,
  useUpdate
} from "@pankod/refine-core";
import { useLocation, useSearchParams } from "@pankod/refine-react-router-v6";
import { fetchDetail } from "api";
import { showErrorPopup } from "api/common";
import {
  ApprovalStatus,
  CurrencyType,
  PaymentBeneficiaryType,
  PaymentRequestType,
  PaymentType,
} from "api/enums";
import { SelectCustom } from "components";
import CostTable from "components/CostTable";
import { Currency } from "components/Currency";
import ModalConfirm from "components/ModalConfirm";
import UploadMulti from "components/UploadMulti";
import { CreateCustom } from "components/layout";
import { API_PATH, PATH } from "configs/path";
import { useNumToWord } from "hooks/useNumToWord";
import { ICase, IPayment, IPrepay } from "interfaces";
import { unionBy } from "lodash";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { validateFieldsRequied } from "utils/commons";
import { CostTableTypeEnum } from "utils/enums";
import { v4 as uuid } from "uuid";

interface Props {
  form: FormInstance<{}>;
  formProps: FormProps;
  record?: IPayment;
  saveButtonProps: any;
  onSubmitSuccess: (id: string) => void;
  onCancel: () => void;
}

const { Title } = Typography;
const { TextArea } = Input;

const FormPayment: FC<Props> = memo((props) => {
  const t = useTranslate();
  const {
    form,
    formProps,
    record,
    saveButtonProps,
    onSubmitSuccess,
    onCancel,
  } = props;

  const [visibleShowModal, setVisibleShowModal] = useState<boolean>();
  const { mutate: mutateCreate } = useCreate<any>();
  const { mutate: mutateUpdate } = useUpdate<any>();
  const [saving, setSaving] = useState(false);
  const [prepayOptions, setPrepayOptions] = useState<any[]>([]);
  const location = useLocation();
  const isEdit = !!record?.id && location.pathname.includes("edit");

  const [prepayDetail, setPrepayDetail] = useState<IPrepay | undefined>();

  const { selectProps: selectPropsCase } = useSelect({
    resource: API_PATH.paymentsCaseDropdownlist,
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { data: employeeInforData } = useOne<ICase>({
    resource: API_PATH.employeeInformation,
    id: "",
  });
  const employeeInformation = employeeInforData?.data;

  const [costKeys, setCostKeys] = useState<string[]>();

  const caseId = Form.useWatch("caseId", form);
  const beneficiaryType = Form.useWatch("beneficiaryType", form);
  const requestType = Form.useWatch("requestType", form);
  const advancePaymentId = Form.useWatch("advancePaymentId", form);
  const currencyType = Form.useWatch("currencyType", form);
  const name = Form.useWatch("name", form);
  const description = Form.useWatch("description", form);
  const refundType = Form.useWatch("refundType", form);
  const attachments = Form.useWatch("attachments", form) || [];
  const employeeId = Form.useWatch("employeeId", form);
  const departmentId = Form.useWatch("departmentId", form);
  const supplierId = Form.useWatch("supplierId", form);
  const bankId = Form.useWatch("bankId", form);
  const bankAccountName = Form.useWatch("bankAccountName", form);
  const bankAccountNumber = Form.useWatch("bankAccountNumber", form);
  const bankBranch = Form.useWatch("bankBranch", form);
  const realTotalAmountWord = Form.useWatch("realTotalAmountWord", form);
  const costObj = Form.useWatch("listCosts", form) || {};
  const params = useSearchParams();
  const changedCost = useMemo(() => {
    let isChanged = false;
    if (requestType === PaymentRequestType.Payment && costObj) {
      Object.keys(costObj).forEach((k) => {
        isChanged = costObj[k]?.realMoney > 0;
      });
    }
    return isChanged;
  }, [costObj, requestType]);

  const confirmChangeType =
    name ||
    beneficiaryType ||
    currencyType ||
    description ||
    attachments.length ||
    bankId ||
    bankAccountName ||
    bankAccountNumber ||
    bankBranch ||
    realTotalAmountWord ||
    changedCost ||
    supplierId ||
    employeeId ||
    departmentId ||
    advancePaymentId;

  const confirmChangePrepay =
    changedCost ||
    realTotalAmountWord ||
    bankId ||
    bankAccountName ||
    bankAccountNumber ||
    bankBranch ||
    realTotalAmountWord ||
    changedCost ||
    supplierId ||
    employeeId ||
    departmentId;

  const resetAllField = () => {
    form.setFieldsValue({
      refundType: PaymentType.Banking,
      beneficiaryType: PaymentBeneficiaryType.Internal,
      employeeId: null,
      departmentId: null,
      advancePaymentId: null,
      currencyType: CurrencyType.VND,
      realTotalAmountWord: "",
      listCosts: null,
      attachments: null,
      bankId: null,
      bankAccountName: "",
      bankAccountNumber: "",
      bankBranch: "",
      name: "",
      description: "",
    });
  };

  const resetSomeField = () => {
    form.setFieldsValue({
      refundType: PaymentType.Banking,
      beneficiaryType: PaymentBeneficiaryType.Internal,
      employeeId: null,
      departmentId: null,
      currencyType: CurrencyType.VND,
      realTotalAmountWord: "",
      attachments: null,
      bankId: null,
      bankAccountName: "",
      bankAccountNumber: "",
      bankBranch: "",
    });
  };

  const costTableType =
    requestType === PaymentRequestType.Payment
      ? advancePaymentId
        ? CostTableTypeEnum.EDIT
        : CostTableTypeEnum.FULL
      : CostTableTypeEnum.READ;

  const total = Object.keys(costObj)
    .map((a) => costObj[a])
    .reduce((a: any, b: any) => a + +(b.money || 0), 0);

  const realTotal = Object.keys(costObj)
    .map((a) => costObj[a])
    .reduce((a: any, b: any) => a + +(b.realMoney || 0), 0);

  const getPrepayDetail = async (prepayId: string) => {
    var res;
    if (prepayId) {
      res = await fetchDetail(API_PATH.prepays, prepayId + "/refundpayment");
    }
    var d = res?.data as IPrepay;

    setPrepayDetail(d);
    return d;
  };

  const applyPrepayDetail = (r: IPrepay, updateCosts?: boolean) => {
    const costOlds = r?.advancePaymentListCosts;
    if (costOlds) {
      const costList: string[] = [];
      const costObj: any = {};
      costOlds.forEach((item: any) => {
        let k = item.id;
        costObj[k] = {
          money: item.money,
          costName: item.cost.name,
          costId: item.cost.id,
          costCode: item.cost.code,
          note: "",
        };
        costList.push(k);
      });
      if(updateCosts){
        setCostKeys(costList);
        form.setFieldsValue({
          listCosts: costObj,
        });
      }
      form.setFieldsValue({
        refundType: r?.advanceType,
        currencyType: r?.currencyType,
        bankAccountName: r?.bank?.bankAccountName,
        bankAccountNumber: r?.bank?.bankAccountNumber,
        bankBranch: r?.bank?.bankBranch,
        bankId: r?.bank?.bankId,
        bankName: r?.bank?.bankName,
        totalAmount: r.totalAmount,
        totalAmountWord: r.totalAmountWord,
      });
    } else {
      let k = uuid();
      form.setFieldsValue({
        listCosts: {
          [k]: {
            note: "",
            costName: "",
            costId: null,
            money: null,
            realMoney: null,
          },
        },
      });
      setCostKeys([k]);
    }
  };

  const { text } = useNumToWord({
    currency: currencyType,
    num: realTotal,
  });

  const onSubmit = (isDraft = false) => {
    let listCosts = Object.keys(costObj).map((k) => costObj[k]);
    listCosts = listCosts.map((a) => ({
      ...a,
      realMoney: isNaN(a.realMoney) ? null : a.realMoney,
    }));
    setSaving(true);
    const refundType =
      realTotal > total || !advancePaymentId
        ? form.getFieldValue("refundType")
        : null;

    var bankObj = {};
    if (refundType === PaymentType.Banking) {
      bankObj = {
        bankId: form.getFieldValue("bankId"),
        bankName: form.getFieldValue("bankName")?.trim(),
        bankAccountName: form.getFieldValue("bankAccountName")?.trim(),
        bankAccountNumber: form.getFieldValue("bankAccountNumber")?.trim(),
        bankBranch: form.getFieldValue("bankBranch"),
      };
    }
    const dataSend: any = {
      resource: PATH.payments,
      metaData: isDraft ? { type: "draft" } : {},
      values: {
        caseId: form.getFieldValue("caseId"),
        name: form.getFieldValue("name")?.trim(),
        requestType: form.getFieldValue("requestType"),
        beneficiaryType:
          requestType === PaymentRequestType.Refund
            ? null
            : form.getFieldValue("beneficiaryType"),
        advancePaymentId: form.getFieldValue("advancePaymentId"),
        departmentId: form.getFieldValue("departmentId"),
        employeeId: form.getFieldValue("employeeId"),
        supplierId: form.getFieldValue("supplierId"),
        description: form.getFieldValue("description")?.trim(),
        attachments: form.getFieldValue("attachments") || [],
        currencyType: form.getFieldValue("currencyType"),
        refundType: refundType,
        listCosts: listCosts.map((a, idx) => ({ ...a, ordinal: idx })),
        realTotalAmountWord: text,
        ...bankObj,
      },
    };

    if (isEdit) {
      dataSend.id = record?.id;
      mutateUpdate(dataSend, {
        onSuccess: () => {
          setSaving(false);
          setVisibleShowModal(false);
          notification.success({
            message: isDraft
              ? t("common.saveDraftSuccess")
              : t("common.sendRequestSuccess"),
          });
          onSubmitSuccess(record?.id!);
        },
        onError: (error: any) => {
          setSaving(false);
          setVisibleShowModal(false);
          showErrorPopup(error);
        },
      });
    } else {
      mutateCreate(dataSend, {
        onSuccess: (res) => {
          setSaving(false);
          setVisibleShowModal(false);
          notification.success({
            message: isDraft
              ? t("common.saveDraftSuccess")
              : t("common.sendRequestSuccess"),
          });
          onSubmitSuccess(res.data?.id);
        },
        onError: (error: any) => {
          setSaving(false);
          setVisibleShowModal(false);
          showErrorPopup(error);
        },
      });
    }
  };

  const onDraft = () => {
    onSubmit(true);
  };

  useEffect(() => {
    if (record && record.id) {
      setTimeout(() => form.setFieldsValue({ caseId: record?.case?.id }), 0);
      setCostKeys(Object.keys(record.refundPaymentListCosts || {}));
      if (record.advancePayment?.id) {
        getPrepayDetail(record.advancePayment?.id).then((r) => applyPrepayDetail(r));
      }
    }
  }, [record?.id, formProps]);

  useEffect(() => {
    if (!record || !record?.id) {
      setTimeout(
        () =>
          form.setFieldsValue({
            currencyType: CurrencyType.VND,
            refundType: PaymentType.Banking,
            beneficiaryType: PaymentBeneficiaryType.Internal,
          }),
        0
      );
    }
  }, []);
  
  useEffect(()=>{
    if(params[0].get("caseId")){
      form.setFieldsValue({
        caseId: params[0].get("caseId")
      })
    }
  }, [params])

  const onChangeCaseId = (value: any) =>
    form.setFields([
      { name: "attachments", value: [] },
      {
        name: "advancePaymentId",
        value: value === prepayDetail?.case.id ? advancePaymentId : null,
      },
    ]);

  const onChangeAdvancdePayment = (_: any, data: any) => {
    if (caseId) return;
    form.setFields([
      { name: "caseId", value: data?.caseId, errors: undefined },
    ]);
  };

  const advancePaymentOptions = (options: any[]) => {
    const convertTo = options.map((op) => ({
      label: op.name,
      value: op.id,
      ...op,
    }));
    if (caseId) {
      if (record?.advancePaymentId) {
        convertTo.push({
          ...record?.advancePayment,
          caseId: record?.case?.id,
          label: `${record?.advancePayment?.code} - ${record?.advancePayment?.name}`,
          value: record?.advancePaymentId,
        });
      }
      // setPrepayOptions(convertTo.filter((op) => op.caseId === caseId));
      return unionBy(
        convertTo.filter((op) => op.caseId === caseId),
        "value"
      );
    }
    // setPrepayOptions(convertTo);
    return convertTo;
  };

  return (
    <CreateCustom
      title={isEdit ? t("payments.titles.edit") : t("payments.titles.create")}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={onSubmit}
      onDraft={onDraft}
      saving={saving}
      validateDraft={() =>
        validateFieldsRequied(["name", "caseId"], form, t("errors.ER005"))
      }
      saveButtonProps={{
        ...saveButtonProps,
        title: t("payments.actions.sendRequest"),
      }}
      confirmModalProps={{
        title: t("payments.message.confirmSend"),
      }}
      draftModalProps={{
        title: isEdit
          ? t("payments.message.confirmEdit")
          : t("payments.message.confirmEditDraft"),
      }}
      draftButtonProps={{
        title: isEdit ? t("common.saveEdit") : t("buttons.draft"),
        type: isEdit ? "primary" : "default",
      }}
      cancelModalProps={{
        title: t("payments.message.confirmCancel"),
        description: t("payments.message.confirmCancelDesc"),
        okButtonProps: { danger: true, disabled: saving },
        cancelButtonProps: { disabled: saving },
      }}
      onCancel={onCancel}
      spinning={saving}
    >
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout="vertical"
        labelCol={{ span: 10 }}
        initialValues={{
          requestType: PaymentRequestType.Payment,
          ...formProps.initialValues,
        }}
      >
        <Title level={5}>{t("payments.titles.info")}</Title>
        <Form.Item
          label={t("payments.fields.type")}
          name="requestType"
          required
          style={{ maxWidth: 500 }}
        >
          <ModalConfirm
            title={t("payments.messages.titleConfirmChangeType")}
            enable={confirmChangeType}
            isDanger={true}
            desc={t("payments.messages.descConfirmChangeType")}
            onDone={(e: any) => {
              resetAllField();
            }}
          >
            <Radio.Group disabled={isEdit}>
              <Radio value={PaymentRequestType.Payment}>
                {t("payments.type.payment")}
              </Radio>
              <Radio value={PaymentRequestType.Refund}>
                {t("payments.type.refund")}
              </Radio>
            </Radio.Group>
          </ModalConfirm>
        </Form.Item>

        {requestType !== PaymentRequestType.Refund && (
          <Form.Item
            label={t("payments.fields.case")}
            style={{ maxWidth: 500 }}
            required
            name="caseId"
            initialValue={params[0].get("caseId")}
            rules={[
              {
                required: true,
                message: t("errors.ER005"),
              },
            ]}
          >
            <Select
              {...selectPropsCase}
              disabled={record?.status === ApprovalStatus.Returned}
              onChange={onChangeCaseId}
              placeholder="Chọn mã vụ việc"
            />
          </Form.Item>
        )}

        <Form.Item
          label={t("payments.fields.name")}
          required
          name="name"
          style={{ maxWidth: 500 }}
          rules={[
            {
              whitespace: true,
              required: true,
              message: t("errors.ER005"),
            },
            {
              max: 300,
              message: t("errors.ER014", { max: "300" }),
            },
          ]}
        >
          <Input placeholder={t("payments.placeholder.name")} />
        </Form.Item>

        <Form.Item
          label={t("payments.fields.description")}
          name="description"
          style={{ maxWidth: 500 }}
          required
          rules={[
            {
              whitespace: true,
              required: true,
              message: t("errors.ER005"),
            },
            {
              max: 500,
              message: t("errors.ER014", { max: "500" }),
            },
          ]}
        >
          <TextArea
            showCount={{
              formatter: (args: { count: number }) => `${args?.count}/500`,
            }}
            placeholder={t("payments.placeholder.description")}
          />
        </Form.Item>

        <Form.Item
          label={t("payments.fields.prepay")}
          name="advancePaymentId"
          required={requestType === PaymentRequestType.Refund}
          rules={[
            {
              required: requestType === PaymentRequestType.Refund,
              message: t("errors.ER005"),
            },
          ]}
        >
          <ModalConfirm
            title={t("payments.messages.titleConfirmChangePrepay")}
            enable={confirmChangePrepay}
            desc={t("payments.messages.descConfirmChangePrepay")}
            onDone={(e: string) => {
              resetSomeField();
              getPrepayDetail(e).then((r) => applyPrepayDetail(r, true));
            }}
            onCancel={() => {
              form.setFieldsValue({
                advancePaymentId: prepayDetail?.id || null,
              });
            }}
            onChange={onChangeAdvancdePayment}
          >
            <SelectCustom
              resource={API_PATH.prepaysDropdownList}
              disabled={
                record?.status === ApprovalStatus?.Returned &&
                requestType === PaymentRequestType.Refund
              }
              style={{ maxWidth: 500 }}
              optionLabel="label"
              optionValue="id"
              formatData={(r: any) => ({
                ...r,
                label: `${r.code} - ${r.name}`,
              })}
              options={
                record?.advancePaymentId
                  ? [
                      {
                        label: `${record?.advancePayment?.code} - ${record?.advancePayment?.name}`,
                        value: record?.advancePaymentId,
                      },
                    ]
                  : []
              }
              allowClear
              placeholder={t("payments.placeholder.prepay")}
              {...(requestType === PaymentRequestType.Payment
                ? { customOptions: advancePaymentOptions }
                : {})}
            />
          </ModalConfirm>
        </Form.Item>

        {requestType === PaymentRequestType.Payment && !advancePaymentId && (
          <>
            <Form.Item
              label={t("payments.fields.beneficiaryType")}
              name="beneficiaryType"
              required
              style={{ maxWidth: 500 }}
              // initialValue={PaymentBeneficiaryType.Internal}
            >
              <Radio.Group
                onChange={(e) => {
                  form.setFieldsValue({ employeeId: null, supplierId: null });
                }}
              >
                <Radio value={PaymentBeneficiaryType.Internal}>
                  {t("common.beneficiaryType.internal")}
                </Radio>
                <Radio value={PaymentBeneficiaryType.Supplier}>
                  {t("common.beneficiaryType.supplier")}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {beneficiaryType === PaymentBeneficiaryType.Supplier && (
              <>
                <Form.Item
                  label={t("payments.fields.supplier")}
                  name="supplierId"
                  style={{ maxWidth: 500 }}
                  required
                  rules={[
                    {
                      required: true,
                      message: t("errors.ER005"),
                    },
                  ]}
                >
                  <SelectCustom
                    resource={API_PATH.suppliers}
                    optionLabel="label"
                    optionValue="value"
                    placeholder={t("payments.placeholder.supplier")}
                  />
                </Form.Item>
              </>
            )}

            {beneficiaryType === PaymentBeneficiaryType.Internal && (
              <>
                <Form.Item
                  label={t("payments.fields.employee")}
                  name="employeeId"
                  style={{ maxWidth: 500 }}
                  required
                  rules={[
                    {
                      required: true,
                      message: t("errors.ER005"),
                    },
                  ]}
                >
                  <SelectCustom
                    resource={API_PATH.employeeDropdownList}
                    optionLabel="label"
                    optionValue="id"
                    formatData={(r: any) => ({
                      ...r,
                      label: `${r.code} - ${r.fullName}`,
                    })}
                    placeholder={t("payments.placeholder.employee")}
                    valueInObject={true}
                    onChangeItem={(_: any, __: any, e: any) => {
                      form.setFieldsValue({ departmentId: e?.departmentId });
                    }}
                    onLoaded={(options: any[]) => {
                      const currentEmployee = options?.find(
                        (t) => t.id === employeeInformation?.employee.id
                      );
                      if (options && options.length === 1 && currentEmployee) {
                        form.setFieldsValue({
                          employeeId: currentEmployee?.id,
                        });
                      }
                    }}
                  />
                </Form.Item>

                {employeeId && (
                  <Form.Item
                    label={t("payments.fields.department")}
                    name="departmentId"
                    style={{ maxWidth: 500 }}
                    required
                    rules={[
                      {
                        required: true,
                        message: t("errors.ER005"),
                      },
                    ]}
                  >
                    <SelectCustom
                      resource={API_PATH.departmentSelectForPayment}
                      optionLabel="name"
                      optionValue="id"
                      valueInObject={true}
                      placeholder={t("payments.placeholder.department")}
                      disabled
                    />
                  </Form.Item>
                )}
              </>
            )}
          </>
        )}
        <Form.Item
          label={t("payments.fields.currency")}
          name="currencyType"
          style={{ maxWidth: 500 }}
          rules={[
            {
              required: true,
              message: t("errors.ER005"),
            },
          ]}
          required
          hidden={
            !!advancePaymentId || requestType === PaymentRequestType.Refund
          }
        >
          <Select placeholder={t("payments.placeholder.currency")}>
            <Select.Option value={CurrencyType.VND}>VND</Select.Option>
            <Select.Option value={CurrencyType.USD}>USD</Select.Option>
            <Select.Option value={CurrencyType.EUR}>EUR</Select.Option>
            <Select.Option value={CurrencyType.SGD}>SGD</Select.Option>
          </Select>
        </Form.Item>

        {(requestType === PaymentRequestType.Payment || advancePaymentId) && (
          <CostTable
            mode={costTableType}
            prepayDetail={prepayDetail}
            form={form}
            currencyType={currencyType}
            costKeys={costKeys}
          />
        )}

        {advancePaymentId && requestType === PaymentRequestType.Refund && (
          <Descriptions layout="vertical">
            <Descriptions.Item label={t("payments.fields.totalNumber")}>
              <Currency
                value={prepayDetail?.totalAmount}
                currencyType={currencyType}
                customStyles={{ color: "black" }}
              />
            </Descriptions.Item>
            <Descriptions.Item label={t("payments.fields.totalWord")}>
              {prepayDetail?.totalAmountWord}
            </Descriptions.Item>
          </Descriptions>
        )}

        {(prepayDetail || caseId) && (
          <Form.Item
            name="attachments"
            validateStatus="success"
            required={requestType === PaymentRequestType.Payment}
            rules={
              requestType === PaymentRequestType.Payment
                ? [
                    {
                      required: true,
                      message: t("errors.ER005"),
                    },
                  ]
                : []
            }
            label=""
          >
            <UploadMulti
              required={requestType === PaymentRequestType.Payment}
              title={t("payments.fields.attachment")}
              url={`${process.env.REACT_APP_API_URL}${
                API_PATH.paymentUploadFile
              }?caseId=${prepayDetail?.case?.id || caseId}`}
              pathPreviewFile={API_PATH.paymentsDocumentsView}
            />
          </Form.Item>
        )}

        {requestType === PaymentRequestType.Payment &&
          (realTotal > total || !advancePaymentId) && (
            <>
              <Form.Item
                label={t("payments.fields.refundType")}
                name="refundType"
                required
                style={{ maxWidth: 500 }}
              >
                <Radio.Group>
                  <Radio value={PaymentType.Banking}>
                    {t("common.paymentType.banking")}
                  </Radio>
                  <Radio value={PaymentType.Cash}>
                    {t("common.paymentType.cash")}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {refundType === PaymentType.Banking && (
                <>
                  <Form.Item
                    label={t("payments.fields.bankAccountName")}
                    name="bankAccountName"
                    style={{ maxWidth: 500 }}
                    required
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: t("errors.ER005"),
                      },
                      {
                        max: 300,
                        message: t("errors.ER014", { max: "300" }),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("payments.placeholder.bankAccountName")}
                    />
                  </Form.Item>

                  <Form.Item
                    name="bankAccountNumber"
                    label={t("payments.fields.bankAccountNumber")}
                    style={{ maxWidth: 500 }}
                    required
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: t("errors.ER005"),
                      },
                      {
                        max: 20,
                        message: t("errors.ER014", { max: "20" }),
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: t("errors.ER037"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("payments.placeholder.bankAccountNumber")}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t("payments.fields.bank")}
                    name="bankId"
                    rules={[
                      {
                        required: true,
                        message: t("errors.ER005"),
                      },
                    ]}
                    style={{ maxWidth: 500 }}
                    required
                  >
                    <SelectCustom
                      optionLabel="label"
                      optionValue="value"
                      resource={API_PATH.banks}
                      placeholder={t("payments.placeholder.bank")}
                      onChange={(v: any, option: any) => {
                        form.setFieldsValue({ bankName: option?.label });
                      }}
                      formatData={(r: any) => {
                        return {
                          ...r,
                          label: `${r.code} - ${r.label}`,
                        };
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="bankName" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label={t("payments.fields.branchBank")}
                    name="bankBranch"
                    style={{ maxWidth: 500 }}
                    rules={[
                      {
                        max: 300,
                        message: t("errors.ER014", { max: "300" }),
                      },
                    ]}
                  >
                    <Input placeholder={t("payments.placeholder.branchBank")} />
                  </Form.Item>
                </>
              )}
            </>
          )}
      </Form>
    </CreateCustom>
  );
});

export default FormPayment;
