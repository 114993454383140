export const PATH = {
  login: "login",
  externalLoginCallback: "signin-oidc",
  admins: "admins",
  users: "users",
  myTenants: "get-my-tenant",
  departments: "departments",
  roles: "roles",
  rolePermissions: "rolePermissions",
  employees: "employees",
  userTenantMisRoles: "userRoles",
  workflows: "workflows",
  workflowsDiagrams: "diagrams",
  prepays: "prepays",
  prepayApprovalrequests: "prepayApprovalrequests",
  paymentApprovalrequests: "paymentApprovalrequests",
  payment: "payment",
  payments: "payments",
  cost: "cost",
  members: "members",
  cases: "cases",
  caseNonSales: "caseNonSales",
  report: "report",
  salePipelines: "salePipelines",
  dashboards: "dashboards",
  noPermissionPage: "403",
  // Configs
  config: "config",
  configSuppliers: "suppliers",
  configProductGroups: "productGroups",
  configTitles: "titles",
  configCosts: "costs",
  configOfficialDispatches: "officialDispatches",
  configBusinessIndustries: "businessIndustries",
  configBusinessTripItems: "businessTripItems",
  configMarketAreas: "marketAreas",
  configProducts: "products",
  configUnits: "units",
  configRegions: "regions",
  configWorkingGroup: "workingGroup",

  // E-Sales
  sales: "sales",
  customerExploitConfigs: "customerExploitConfigs",
  customers: "customers",
  saleConfigs: "saleConfigs",
  businessOpportunities: "businessOpportunities",
  assignExploitings: "assignExploitings",
  estimate: "estimate",
  quotations: "quotations",
  contracts: "contracts",
  settlementContracts: "settlementContracts",
  contractCommitments: "contractCommitments",
  serviceRequests: "serviceRequests",
  contractEstimateds: "contractEstimateds",

  // E-office
  eOffice: "eOffice",
  eOfficeConfigs: "eofficeConfigs",

  // System
  system: "system",
  systemConfig: "systemConfig",

  // MVV
  workId: "workId",
  caseId: "caseId",
  caseIdPrepay: "prepays",
  consultationRequests: "consultationRequests",
  consultationResults: "consultationResults",

  // Estimate
  internalEstimates: "internalEstimates",
  internalEstimatesApprovalrequests: "internalEstimatesApprovalrequests",
  businessPlans: "businessPlans",
  businessPlanApprovalrequests: "businessPlanApprovalrequests",

  // Project
  projectManagement: "projectManagement",
  projects: "projects",
  settlements: "settlements",
};

// The model API to check the permission
export const PERMISSION_PATH: { [key: string]: string } = {
  Users: "users",
  Departments: "departments",
  Employees: "employees",
  MisRoles: "roles",
  UserTenantMisRoles: "userRoles",
  ApprovalProcedures: "workflows",
  ApprovalSteps: "diagrams",
  AdvancePayments: "prepays",
  RefundPayments: "payments",
  Cases: "cases",
  Suppliers: "suppliers",
  ProductGroups: "productGroups",
  Titles: "titles",
  Costs: "costs",
  OfficialDispatches: "officialDispatches",
  BusinessIndustries: "businessIndustries",
  BusinessTripItems: "businessTripItems",
  MarketAreas: "marketAreas",
  Products: "products",
  Units: "units",
  Regions: "regions",
  CustomerExploitConfigs: "customerExploitConfigs",
  Customers: "customers",
  BusinessOpportunities: "businessOpportunities",
  AssignExploitings: "assignExploitings",
  ConsultationRequests: "consultationRequests",
  SalePipelines: "salePipelines",
  InternalEstimates: "internalEstimates",
  BusinessPlans: "businessPlans",
  WorkingGroups: "workingGroup",
  ConsultationResults: "consultationResults",
  CaseNonSales: "caseNonSales",
  Quotations: "quotations",
  Contracts: "contracts",
  ContractCommitments: "contractCommitments",
  Projects: "projects",
  ServiceRequests: "serviceRequests",
  ContractEstimateds: "contractEstimateds",
  Settlements: "settlements",
  SettlementContracts: "settlementContracts",
};

export const FULL_ACCESS_PATH = [PATH.dashboards];

export const API_PATH = {
  admins: "admins",
  provinces: "provinces",
  districts: "districts",
  wards: "wards",
  dropdownlist: "/dropdownlist",
  myTenants: "get-my-tenant",
  todoListStatus: "api/Home/get-to-do-list-statuses",
  todoList: "api/Home/get-to-do-list",
  todoListDropdownlist: "api/Home/get-features/dropdownlist",

  // API core
  users: "core/users",
  usersSelect: "core/users/get-user-tenant-map-with-employee",
  banks: "core/banks/dropdownlist",

  // API business
  features: "api/features",
  roles: "api/MisRoles",
  rolePermissions: "api/MisRolePermissions",
  businessFeature: "api/features/dropdownlist/business",
  userTenantMisRoles: "api/userTenantMisRoles",

  departments: "api/departments",
  employees: "api/employees",
  employeeInformation: "api/employees/me",
  employeeDropdownList: "api/employees/dropdownlist",
  employeeDropdownListForPrepay: "api/employees/dropdownlist/advance-payment",
  employeeDropdownListForPayment: "api/employees/dropdownlist/refund-payment",
  departmentSelect: "api/departments/dropdownlist/employee",
  departmentSelectForPrepay: "api/departments/dropdownlist/advance-payment",
  departmentSelectForPayment: "api/departments/dropdownlist/refund-payment",
  departmentDropdownlist: "api/departments/dropdownlist",
  departmentRoleDropdownlist: "/dropdownlist/role",
  getParentsDepartment: "api/departments/get-parents",
  getLineManagerEmployees: "api/employees/get-line-manager",
  workflow: "api/ApprovalProcedures",
  workflowsDiagrams: "api/ApprovalSteps",
  employeeTitlesDropdownList: "api/Titles/dropdownlist",

  employeesForAssignExploiting: "api/assignExploitings/dropdownlist/employee",
  employeesForAssignCustomer: "api/customers/dropdownlist/employee",

  prepays: "api/AdvancePayments",
  prepaysDocumentsView: "api/AdvancePayments/documents/view",
  prepaysDropdownList: "api/AdvancePayments/dropdownlist",
  prepaysCaseDropdownlist: "api/AdvancePayments/dropdownlist/case",
  payments: "api/RefundPayments",
  paymentsDocumentsView: "api/RefundPayments/documents/view",
  paymentHistories: "api/RefundPayments/histories",
  paymentsCaseDropdownlist: "api/RefundPayments/dropdownlist/case",
  costDropdownList: "api/Costs/dropdownList",
  suppliers: "api/suppliers/dropdownlist",
  supplierDropdownlistByName: "api/suppliers/dropdownlist/by-name",
  cases: "api/Cases",
  getCase: "api/Cases/get-case",

  caseId: "api/Cases/for-sale",

  // configs
  configMarketAreas: "api/MarketAreas",
  configCosts: "api/costs",
  configUnits: "api/Units",
  configTitles: "api/Titles",
  configOfficialDispatches: "api/OfficialDispatches",
  configBusinessIndustries: "api/BusinessIndustry",
  configProducts: "api/products",
  configProductGroups: "api/productGroups",
  configBusinessTripItems: "api/BusinessTripItems",
  configSuppliers: "api/suppliers",
  supplierSelect: "api/suppliers/dropdownlist",
  businessIndustrySelect: "api/businessIndustry/dropdownlist",
  businessIndustryDropdownList:
    "api/businessIndustry/business-industry-dropdownlist",
  costsSelect: "api/costs/dropdownlist",
  configRegions: "api/regions",
  configWorkingGroup: "api/workingGroup",

  customerExploitConfigs: "api/customerExploitConfigs",
  customer: "api/customers",
  customerActivate: "api/customers/activate",
  customerDeActivate: "api/customers/deactivate",
  provinceDropdownList: "api/regions/province-dropdownlist",
  regionDropdownList: "api/regions/region-dropdownlist",
  marketDropdownList: "api/marketAreas/marketarea-dropdownlist",
  deleteCustomer: "api/customers/delete",
  customerDropdownList: "api/customers/dropdownlist",
  customerDropdownlistForAssignExploiting:
    "api/assignExploitings/dropdownlist/customer-unassigned",
  productCategoryDropdownList: "api/products/category-dropdownlist",

  customerDropdownlistForBusinessOportunity:
    "api/businessOpportunities/dropdownlist/customers",

  customerDropdownlistByName: "api/customers/dropdownlist/by-name",

  businessOpportunities: "api/businessOpportunities",

  productDropdownList: "api/products/product-dropdownlist",

  businessOpportunituesStatusDropdownlist:
    "api/businessOpportunities/dropdownlist/status",

  businessOpportunituesStatusCloseDropdownlist:
    "api/businessOpportunities/dropdownlist/status-won-or-lose",

  assignExploitings: "api/assignExploitings",

  removeAssignExploiting: "api/assingExploitings/remove-assign-exploitings",

  consultationRequestsApi: "api/consultationRequests",

  consultationServiceDropdownlist:
    "api/consultationRequests/dropdownlist/consultation-service",

  consultationServiceStatusDropdownlist:
    "api/consultationRequests/dropdownlist/consultation-request-status",

  consultationRequestUploadfile: "api/consultationRequests/upload-files",

  consultationRequestDownloadFile:
    "api/consultationRequests/upload-file/download",

  consultationRequestDropdownEmployeeAssign:
    "api/consultationRequests/dropdownlist/employee-to-assign-by-department",

  consultationRequestAssignEmployee: "api/consultationRequests/assign-employee",

  consultationResults: "api/consultationResults",

  salePipelines: "api/salePipelines",

  salePipelinesDropdownlistEmployee: "api/salePipelines/dropdownlist/employee",

  salePipelinesDropdownlistBusinessOpportinityStatus:
    "api/salePipelines/dropdownlist/business-opportunity-status",

  salePipelineExportFile: "api/salePipelines/export",

  salePipelineSBADropdown: "api/salePipelines/dropdownlist/sba-group",

  internalEstimates: "api/internalEstimates",

  internalEstimatesCaseDropdownlist: "api/internalEstimates/dropdownlist/case",

  internalEstimatesRequestDropdownlist:
    "api/internalEstimates/dropdownlist/reference-request",

  internalEstimatesPolicyDropdownlist:
    "api/internalEstimates/dropdownlist/internal-estimate-policy",

  businessPlanApi: "api/businessPlans",

  businessPlanMasterDataForCreate:
    "api/businessPlans/business-plan-master-data-for-create",

  businessPlanStatusForCreate:
    "api/businessPlans/dropdownlist/business-plan-status-for-create",

  prepayUploadFile: "api/advancePayments/documents/upload",

  paymentUploadFile: "api/refundPayments/documents/upload",

  caseStatusDropdownlist: "api/cases/nonsale/status/dropdownlist",

  internalEstimatesRateCardDropdownlist:
    "api/internalEstimates/dropdownlist/internal-rate-card",

  businessPlanCaseDropdownlist: "api/businessPlans/dropdownlist/case",

  businessPlanProductDropdownlist: "api/businessPlans/dropdownlist/product",

  businessPlanProductsDefault: "api/businessPlans/products-default",

  businessPlanProductFromOppotunity:
    "api/businessPlans/products-from-oppotunity",

  businessPlanProductFromEstimateCost:
    "api/businessPlans/products-from-estimate-cost",

  businessPlanUploadFile: "api/businessPlans/documents/upload",

  unitsDropdownlist: "api/units/dropdownlist",

  businessPlanDownloadFile: "api/businessPlans/upload-file/download",

  businessPlanStatusDropdownlist:
    "api/businessPlans/dropdownlist/businessplan-status",

  businessPlanStatusClone: "api/businessPlans/business-plan-clone",

  approvalFetureConditionAttr: "api/approvalSteps/approvalFeatureConditionAttr",
  approvalCaseRoleDropdownlist: "api/approvalSteps/dropdownlist/case-roles",
  quotationsApi: "api/quotations",

  quotationsCaseDropdownlist: "api/quotations/dropdownlist/case",

  quotationsBusinessPlanDropdownlist:
    "api/quotations/dropdownlist/business-plan",

  quotationsProductsDefault: "api/quotations/products-default",

  quotationsEmloyeeDropdownlist: "api/quotations/dropdownlist/employee",

  contractsApi: "api/contracts",

  contractsCaseDropdownlist: "api/contracts/dropdownlist/case",

  lookupApi: "api/lookupType",

  contractsUploadFile: "api/contracts/upload-files",

  contractsUserPartyDropdownlist: "api/contracts/dropdownlist/user-party",

  contractsProviderPartyDropdownlist:
    "api/contracts/dropdownlist/provider-party",

  contractsThirdPartyDropdownlist: "api/contracts/dropdownlist/third-party",

  contractsOriginalDropdownlist: "api/contracts/dropdownlist/original",

  contractProductsDefault: "api/contracts/products-default",
  
  contractPartyDefault: "api/contracts/default-party",

  contractCommitmentApi: "api/contractCommitments",

  contractCommitmentStatusDropdownlist:
    "api/contractCommitments/dropdownlist/status",

  contractTypeDropdownlist: "api/contracts/dropdownlist/type",

  contractGuaranteeTypeDropdownlist:
    "api/contracts/dropdownlist/guarantee-type",

  contractStatusDropdownlist: "api/contracts/dropdownlist/status",

  contractGuaranteeIssuePartyDropdownlist:
    "api/contracts/dropdownlist/guarantee-issue-party",

  contractGuaranteeCondition: "api/contracts/dropdownlist/guarantee-condition",
  businessOpportunitieEmployeeDropdownlist:
    "api/businessOpportunities/dropdownlist/employees",

  caseIdCustomerDropdownlist: "api/cases/dropdownlist/customers",

  businessPlanFinancialRatiosSetting:
    "api/businessPlans/dropdownlist/financial-ratios-setting",
  contractGetLinkPreviewFile: "api/contracts/upload-file/view",
  projectApi: "api/projects",

  projectCaseDropdownlist: "api/projects/dropdownlist/project-case",

  projectEmployeeDropdownlist: "api/projects/dropdownlist/employee",

  projectStatusDrodownlist: "api/projects/dropdownlist/project-status",

  projectRoleConsultingDropdownlist:
    "api/projects/dropdownlist/project-role-consulting",

  projectRoleDeployingDropdownlist:
    "api/projects/dropdownlist/project-role-deploying",

  serviceRequestApi: "api/serviceRequests",

  serviceRequestCaseDropdownlist: "api/serviceRequests/dropdownlist/case",

  serviceRequestTypeDropdownlist:
    "api/serviceRequests/dropdownlist/service-request-type",

  serviceRequestUploadFile: "api/serviceRequests/documents/upload",

  serviceUploadGetDefaultProducts: "api/serviceRequests/products-default",

  serviceRequestProductDropdownlist: "api/serviceRequests/dropdownlist/product",

  serviceRequestUnitDropdownlist: "/api/serviceRequests/dropdownlist/unit",

  caseInfo: (id: string) => `api/cases/${id}/info`,

  serviceRequestPreviewFile: "api/serviceRequests/documents/view",

  serviceRequestStatusDropdownlist: "api/serviceRequests/dropdownlist/status",

  internalEstimateTypeApi:
    "api/internalEstimates/dropdownlist/internal-estimate-type",

  interEstimateUploadFile: "api/internalEstimates/documents/upload",

  interalEstimateProductDropdownlist:
    "api/internalEstimates/dropdownlist/products/product-in-estimate-detail",

  interEstimateUnitDropdownlist:
    "api/internalEstimates/dropdownlist/internal-estimate-unit",

  internalEstimateProductServiceRequest:
    "api/internalEstimates/dropdownlist/products/service-request",

  internalEstimateProductBusinessPlan:
    "api/internalEstimates/dropdownlist/products/businessplan",

  internalEstimateCostDropdownlist:
    "api/internalEstimates/dropdownlist/costs-active",

  internalEstimateUnitDropdownlist:
    "api/internalEstimates/dropdownlist/internal-estimate-unit",

  internalEstimateStatusDropdownlist:
    "api/internalEstimates/dropdownlist/status",

  serviceRequestDetail: "api/serviceRequests/detail",

  internalEstimatePreviewFile: "api/internalEstimates/documents/view",

  internalEstimateClone: "api/internalEstimates/internal-estimate-clone",

  customerAssignExploitingTypeDropdownlist:
    "api/customers/dropdownlist/assign-exploiting-type",

  businessPlanFinacialIndicator: "api/businessPlans/financial-indicator",

  financialIndicatorMaterData: "api/financialIndicator/master-data",

  contractEstimatedFinancial: "api/contractEstimateds/financial-indicator",

  contractEstimatedCaseDropdownlist: "api/contractEstimateds/dropdownlist/case",

  contractEstimatedsApi: "api/contractEstimateds",

  contractEstimatedsFinancialIndicator:
    "api/contractEstimateds/financial-indicator",

  contractEstimatedsProductDefault: "api/contractEstimateds/product-default",

  contractEstimatedsPreviewFile: "api/contractEstimateds/documents/view",

  contractEstimatedsClone: "api/contractEstimateds/clone",

  contractEstimatedsSubmitCondition: "api/contractEstimateds/submit-condition",

  settlementApi: "api/settlement",

  settlementForCreate: "api/settlement/for-create",

  settlementDefaultData: "api/settlement/default-data",

  settlementUploadFile: `api/settlement/documents/upload`,

  settlementCaseDropdownlist: "api/settlement/dropdownlist/case",

  settlementRateCardDropdownlist:
    "api/settlement/dropdownlist/internal-ratecard",

  settlementProductDropdownlist:
    "api/settlement/dropdownlist/product-settlement",

  settlementEmployeeDropdownlist: "api/settlement/dropdownlist/case-employee",

  settlementPreviewFile: "api/settlement/documents/view",

  settlementStatusDropdownlist: "api/settlement/dropdownlist/status",

  settlementSubmitCondition: "api/settlement/submit-condition",

  businessPlanPreviewFile: "api/businessPlans/documents/view",

  settlementContracts: "api/SettlementContracts",

  settlementContractsCaseDropdownlist:
    "api/SettlementContracts/dropdownlist/case",

  settlementContractsStatusDropdownlist:
    "api/SettlementContracts/dropdownlist/status",

  settlementContractsDocuments: "api/SettlementContracts/documents",

  settlementContractsSubmitCondition:
    "api/SettlementContracts/submit-condition",

  settlementContractPreviewFile: "/api/SettlementContracts/documents/view",
  settlementContractsFinancialIndicator:
    "api/SettlementContracts/financial-indicator",
    statusForListFilter: "api/ApprovalProcedures/statuses-for-list-filters",
};

export const getApiPath = (path: string) => {
  switch (path) {
    case PATH.users: {
      return API_PATH.users;
    }
    case PATH.myTenants: {
      return API_PATH.myTenants;
    }
    case PATH.departments: {
      return API_PATH.departments;
    }
    case PATH.employees: {
      return API_PATH.employees;
    }
    case PATH.roles: {
      return API_PATH.roles;
    }
    case PATH.userTenantMisRoles: {
      return API_PATH.userTenantMisRoles;
    }
    case PATH.rolePermissions: {
      return API_PATH.rolePermissions;
    }
    case PATH.workflows: {
      return API_PATH.workflow;
    }
    case PATH.workflowsDiagrams: {
      return API_PATH.workflowsDiagrams;
    }
    case PATH.prepays: {
      return API_PATH.prepays;
    }
    case PATH.payments: {
      return API_PATH.payments;
    }
    case PATH.cases: {
      return API_PATH.cases;
    }
    case PATH.configUnits: {
      return API_PATH.configUnits;
    }
    case PATH.configMarketAreas: {
      return API_PATH.configMarketAreas;
    }
    case PATH.configProducts: {
      return API_PATH.configProducts;
    }
    case PATH.configProductGroups: {
      return API_PATH.configProductGroups;
    }
    case PATH.configCosts: {
      return API_PATH.configCosts;
    }
    case PATH.configTitles: {
      return API_PATH.configTitles;
    }
    case PATH.configOfficialDispatches: {
      return API_PATH.configOfficialDispatches;
    }
    case PATH.configBusinessTripItems: {
      return API_PATH.configBusinessTripItems;
    }
    case PATH.configBusinessIndustries: {
      return API_PATH.configBusinessIndustries;
    }
    case PATH.configSuppliers: {
      return API_PATH.configSuppliers;
    }
    case PATH.configRegions: {
      return API_PATH.configRegions;
    }
    case PATH.customerExploitConfigs: {
      return API_PATH.customerExploitConfigs;
    }

    case PATH.customers: {
      return API_PATH.customer;
    }

    case PATH.businessOpportunities:
      return API_PATH.businessOpportunities;

    case PATH.assignExploitings:
      return API_PATH.assignExploitings;

    case PATH.consultationRequests:
      return API_PATH.consultationRequestsApi;

    case PATH.consultationResults:
      return API_PATH.consultationResults;

    case PATH.salePipelines:
      return API_PATH.salePipelines;

    case PATH.internalEstimates:
      return API_PATH.internalEstimates;
    case PATH.businessPlans:
      return API_PATH.businessPlanApi;

    case PATH.configWorkingGroup:
      return API_PATH.configWorkingGroup;

    case PATH.quotations:
      return API_PATH.quotationsApi;

    case PATH.contracts:
      return API_PATH.contractsApi;

    case PATH.contractCommitments:
      return API_PATH.contractCommitmentApi;
    case PATH.projects:
      return API_PATH.projectApi;

    case PATH.serviceRequests:
      return API_PATH.serviceRequestApi;

    case PATH.contractEstimateds:
      return API_PATH.contractEstimatedsApi;

    case PATH.settlements:
      return API_PATH.settlementApi;


    case PATH.settlementContracts:
      return API_PATH.settlementContracts;
      
    default: {
      return path;
    }
  }
};

export const NO_AUTH_PATH = {
  resetPassword: "reset-password",
  forgotPassword: "forgot-password",
  changePasswordSuccess: "update-password-success",
};

export const SLUGS = {
  prepays: `${PATH.eOffice}/${PATH.prepays}`,
  prepayApproval: `${PATH.eOffice}/${PATH.prepays}/${PATH.prepayApprovalrequests}`,
  payments: `${PATH.eOffice}/${PATH.payments}`,
  paymentApproval: `${PATH.eOffice}/${PATH.payment}/${PATH.paymentApprovalrequests}`,
  caseForSale: `${PATH.workId}/${PATH.caseId}`,
  caseNonSale: `${PATH.workId}/${PATH.caseNonSales}`,
  serviceRequest: `${PATH.sales}/${PATH.serviceRequests}`,
  contract: `${PATH.sales}/${PATH.contracts}`,
};
