import { CSSProperties } from "react";

export const layoutStyles: CSSProperties = {
};

export const containerStyles: CSSProperties = {
    maxWidth: "418px",
    margin: "auto",
};

export const titleStyles: CSSProperties = {
    textAlign: "center",
};

export const imageContainer: CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "28px",
};
