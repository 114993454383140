import { Col, Form, Row, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { useSearchParams } from "@pankod/refine-react-router-v6";
import { SelectCustom } from "components";
import CCollapse, { CaseInfoPanel } from "components/CCollapse";
import { API_PATH } from "configs/path";
import { FC, memo, useContext } from "react";
import styled from "styled-components";
import { objectToQueryString } from "utils/commons";
import { ContractEstimatedFormContext, IForm } from "../../..";

export const Box = styled.div`
  background-color: white;
  padding: 24px;
`;

const { Title } = Typography;
const { useWatch, useFormInstance } = Form;

const CaseInfo: FC = memo(() => {
  const { canEditCaseId, initialValues, caseData, isEdit, onChangeCase } =
    useContext(ContractEstimatedFormContext);
  const translate = useTranslate();
  const form = useFormInstance<IForm>();
  const caseId = useWatch("caseId", form);
  const caseDetail = caseData?.data?.data;
  const loadingCase = caseData?.isFetching;
  const params = useSearchParams(); 
  return (
    <Box>
      <Title level={5}>{translate("Thông tin vụ việc")}</Title>
      <div style={{ paddingLeft: 16 }}>
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Form.Item
              label={translate("Vụ việc")}
              className={caseId ? "" : "m-0"}
              name="caseId"
              initialValue={params[0].get("caseId")}
              rules={[{ required: true, message: translate("errors.ER005") }]}
            >
              <SelectCustom
                resource={API_PATH.contractEstimatedCaseDropdownlist}
                subFilter={objectToQueryString({
                  pageNumber: 1,
                  pageSize: 100,
                })}
                optionLabel="name"
                optionValue="id"
                placeholder={translate("Chọn vụ việc")}
                style={{ color: "#000" }}
                disabled={!canEditCaseId}
                allowClear
                label={
                  isEdit
                    ? `${initialValues?.case?.code} - ${initialValues?.case?.name}`
                    : undefined
                }
                onLoaded={()=>{
                  const caseId = params[0].get("caseId")
                  if (caseId) {
                    onChangeCase(caseId);
                  }
                }}
                useServerFiltering
                onChange={onChangeCase}
              />
            </Form.Item>
          </Col>
        </Row>
        <CCollapse
          hidden={!caseId}
          loading={loadingCase}
          title={translate("Chi tiết vụ việc")}
        >
          <CaseInfoPanel caseInfo={caseDetail} />
        </CCollapse>
      </div>
    </Box>
  );
});

export default CaseInfo;
