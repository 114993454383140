import { Form, useForm, useSelect } from "@pankod/refine-antd";
import { useTable } from "@pankod/refine-core";
import { ApprovalStatus } from "api/enums";
import { API_PATH } from "configs/path";
import {
    ServiceRequestTableRow,
} from "interfaces/ServiceRequest";
import { OptionViewApproval } from "interfaces/common";
import { useMemo } from "react";

interface FilterForm {
  region: string;
  marketArea: string;
  status: ApprovalStatus;
  oldStatus: ApprovalStatus;
}
const { useWatch } = Form;
export const useController = () => {
  const { form, formProps } = useForm<FilterForm>();
  const table = useTable<ServiceRequestTableRow>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const { setFilters } = table;
  const optionViewApproval = useWatch("optionViewApproval", form);
  const status = useWatch("status", form);
  const oldStatus = useWatch("oldStatus", form);

  const { selectProps: serviceRequstTypeSelectProps } = useSelect({
    resource: API_PATH.serviceRequestTypeDropdownlist,
    optionValue: "id",
    optionLabel: "name",

    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: serviceRequestStatus } = useSelect({
    resource: API_PATH.serviceRequestStatusDropdownlist,
    optionValue: "code",
    optionLabel: "displayName",
  });

  const statusOptions = serviceRequestStatus?.options;

  const onChangeOptionView = (option: OptionViewApproval) => () => {
    const value = optionViewApproval === option ? undefined : option;
    const isApproving = value === OptionViewApproval.APPROVING;

    form.setFieldsValue({
      optionViewApproval: value,
      status: isApproving ? ApprovalStatus?.Approving : oldStatus,
      oldStatus: isApproving ? status : oldStatus,
    });

    const statusFilter = oldStatus;

    setFilters([
      {
        field: "optionViewApproval",
        operator: "eq",
        value,
      },
      {
        field: "status",
        operator: "eq",
        value: isApproving
          ? ApprovalStatus?.Approving
          : statusFilter > -1
          ? statusFilter
          : undefined,
      },
    ]);
  };

  const dataTable = useMemo(
    () =>
      table?.tableQueryResult?.data?.data?.map((row) => ({
        ...row.objectData,
        submitEmployee: row?.submitEmployee,
        waitingProcessEmployees: row?.waitingProcessEmployees,
      })),
    [table?.tableQueryResult]
  );

  return {
    table,
    formProps,
    form,
    serviceRequstTypeSelectProps,
    statusOptions,
    dataTable,
    onChangeOptionView,
  };
};
