import { Descriptions, Tag } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { FC, memo, useContext } from "react";
import { ContractShowConText, getStatus } from "../..";
import { DataText, LinkText } from "components";
import { formatISODateTimeToView } from "utils/commons";
import { DATE_TIME_FORMAT } from "configs/constants";
import { SLUGS } from "configs/path";

const General: FC = memo(() => {
  const record = useContext(ContractShowConText);
  const translate = useTranslate();

  const hasThirdParty = !!record?.thirdParty;

  return (
    <Descriptions
      column={{ xs: 1, lg: 3 }}
      title={translate("Thông tin tổng quan")}
      layout="vertical"
      style={{ background: "white", padding: 24 }}
    >
      <Descriptions.Item
        label={translate("Tên hợp đồng")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.name} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Loại hợp đồng")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.contractType?.name} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Trạng thái")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <Tag {...getStatus(record?.contractStatus?.code)}>
          {record?.contractStatus?.name}
        </Tag>
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Số hợp đồng")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.code} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Hợp đồng gốc")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        {record?.originalContract?.id ? (
          <LinkText
            value={{
              id: record?.originalContract?.id,
              code: `${record?.originalContract?.code ?? ""}
                -${record?.originalContract?.name}`,
            }}
            resource={SLUGS.contract}
          />
        ) : (
          <DataText value={record?.originalContract?.id} />
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Số hợp đồng của khách hàng")}
        labelStyle={{ fontWeight: 500 }}
        span={2}
      >
        <DataText value={record?.customerContractCode} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Bên sử dụng")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.userParty?.name} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Bên cung cấp")}
        labelStyle={{ fontWeight: 500 }}
        span={hasThirdParty ? 1 : 2}
      >
        <DataText value={record?.serviceProviderParty?.name} />
      </Descriptions.Item>
      {hasThirdParty && (
        <Descriptions.Item
          label={translate("Bên thứ 3")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.thirdParty?.name} />
        </Descriptions.Item>
      )}
      <Descriptions.Item
        label={translate("Người tạo")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.createdName} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Ngày tạo")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText
          value={formatISODateTimeToView(record?.createdDate, DATE_TIME_FORMAT)}
        />
      </Descriptions.Item>
    </Descriptions>
  );
});

export default General;
