import {
  Icons,
  Select,
  SelectProps,
  Typography,
  useSelect,
} from "@pankod/refine-antd";
import { CrudFilters, Option, useTranslate } from "@pankod/refine-core";
import React, { useEffect, useState } from "react";
import { searchSelect } from "utils/commons";
import { uniqBy } from "lodash";
import { DefaultOptionType } from "rc-select/lib/Select";

const { Text } = Typography;

export interface SelectCustomProps {
  label?: string;
  disabled?: boolean;
  allowSearch?: boolean;
  api?: string;
  resource: string;
  optionValue?: string;
  optionLabel?: string;
  noResultMessage?: string;
  isLoading?: boolean;
  onChangeItem?: any;
  onChangeOption?: Function;
  onLoaded?: Function;
  debounce?: number;
  valueInObject?: boolean;
  formatData?: Function;
  useServerFiltering?: boolean;
  subFilter?: string;
  activeStatusOptions?: any;
  filters?: CrudFilters;
  enabledFetching?: boolean;
  customOptions?: (data: any[], value?: string, options?: Option[]) => Option[];
  onChange?: (
    value: any,
    option: DefaultOptionType | DefaultOptionType[],
    selectedItem: any
  ) => void;
}

export const SelectCustom: React.FC<SelectProps & SelectCustomProps> = (
  props
) => {
  const {
    onChange,
    onChangeItem,
    valueInObject,
    optionValue,
    optionLabel,
    formatData,
    onChangeOption,
    onLoaded,
    useServerFiltering = false,
    subFilter,
    activeStatusOptions,
    filters,
    enabledFetching = true,
    customOptions,
    ...otherProps
  } = props;

  const [searchValue, setSearchValue] = React.useState<string>("");

  const [inner, setInner] = useState<any>({
    value: props.value,
  });

  useEffect(() => {
    if (props.value) {
      const selectedItem = data?.data.find(
        (t) => t[props.optionValue as any] == props.value
      );
      const label = selectedItem
        ? selectedItem[props.optionLabel || "name"]
        : "";
      setInner({
        value: props.value,
        option: { value: props.value, label: label },
      });
    }
  }, [props.value]);

  const t = useTranslate();
  const {
    selectProps: customSelectProps,
    queryResult: { isFetching: isLoading, data },
  } = useSelect({
    resource: props.resource,
    optionValue: optionValue ?? "id",
    optionLabel: optionLabel ?? "name",
    debounce: props.debounce ? props.debounce : useServerFiltering ? 500 : 200,
    filters: filters,
    metaData: {
      formatData: formatData,
      selectUseServerFiltering: useServerFiltering,
      subFilter: subFilter,
    },
    onSearch: (value) => {
      setSearchValue(value);
      return [
        {
          field: "q",
          operator: "containss",
          // disable useSelect auto call api when useServerFiltering is false
          value: useServerFiltering ? value : undefined,
        },
      ];
    },
    queryOptions: {
      enabled: !activeStatusOptions && enabledFetching ? true : false,
    },
  });

  useEffect(() => {
    const { value, option } = inner;
    if (!value) return;
    if (onChangeItem) {
      if (valueInObject) {
        const selectedItem = data?.data.find(
          (t) => t[props.optionValue as any] == value
        );
        onChangeItem(value, option, selectedItem);
      } else {
        onChangeItem(value, option);
      }
    }
  }, [inner, data]);

  useEffect(() => {
    if (!isLoading && onLoaded) {
      onLoaded(data?.data);
    }
  }, [isLoading]);

  const options = activeStatusOptions
    ? activeStatusOptions
    : uniqBy(
        (otherProps.options || []).concat(customSelectProps.options || []),
        "value"
      );

  // Add deactivate item

  if (
    props.value &&
    options.findIndex((t: any) => t.value == props.value) === -1
  ) {
    options.unshift({
      disabled: true,
      value: props.value,
      label: props.label ? props.label : props.value,
    });
  }

  return (
    <Select
      showSearch
      {...otherProps}
      {...customSelectProps}
      options={
        customOptions
          ? customOptions(
              data?.data || [],
              options.length > 0 ? props?.value : props?.label,
              options
            )
          : options
      }
      value={options.length > 0 ? props?.value : props?.label}
      filterOption={(inputValue: string, option: any) => {
        return useServerFiltering && options
          ? options
          : option && searchSelect(inputValue, option?.label);
      }}
      suffixIcon={useServerFiltering ? <Icons.SearchOutlined /> : undefined}
      notFoundContent={
        isLoading ? (
          <Icons.LoadingOutlined />
        ) : !searchValue ? (
          <Text>{t("common.searchPlaceHolder")}</Text>
        ) : (
          <Text>
            {t("common.noResultFor")}{" "}
            <span className="primary">{searchValue}</span>
          </Text>
        )
      }
      loading={isLoading || props.isLoading}
      onBlur={() => {
        // Clear search value if no selected option on blur
        if (customSelectProps.onSearch && !props.value) {
          customSelectProps.onSearch("");
        }
      }}
      onChange={(value, option) => {
        setInner({ value, option });

        if (onChange || onChangeOption) {
          const selectedItem = data?.data.find(
            (t) => t[props.optionValue as any] == value
          );

          if (onChange) {
            onChange(value, option, selectedItem);
          }
          if (onChangeOption) {
            onChangeOption(value, option, selectedItem, data?.data);
          }
        }
      }}
    />
  );
};
