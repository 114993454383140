import { Card, Col, Form, Input, notification, Row, Typography, useForm } from "@pankod/refine-antd"
import { IResourceComponentsProps, useCreate, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core"
import { SelectCustom } from "components"
import InputUpper from "components/InputUpper"
import { CreateCustom } from "components/layout"
import { API_PATH, PATH } from "configs/path"
import { ISupplierConfig } from "interfaces"
import React from "react"
import { isValidConfigCodeField, isValidEmail, isValidGeneralPhoneNumber, isValidNumberAndDash, mappingErrorFromApi } from "utils/commons"

const { Text } = Typography
const { TextArea } = Input


export const SupplierCreate: React.FC<IResourceComponentsProps> = (props) => {
    const t = useTranslate()

    const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>(false);
    const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreate<ISupplierConfig>()
    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<ISupplierConfig>()
    const { form, formProps, saveButtonProps } = useForm<ISupplierConfig>();

    const isEdit = !!formProps.initialValues?.id
    const record = formProps.initialValues
    const { show, list } = useNavigation()

    const onSubmit = () => {
        const handleResponse = {
            onSuccess: (res: any) => {
                notification.success({
                    message: isEdit ? t('common.config.editSuccess') : t('common.config.createSuccess'),
                })
                show(PATH.configSuppliers, res?.data?.id)
            },
            onError: (error: any) => {
                setVisibleShowModal(false)
                const errorFieldMapping: any = {
                }
                mappingErrorFromApi(error, form, errorFieldMapping)
            },
        }
        const formValue = form.getFieldsValue() as any

        const dataSend = {
            resource: PATH.configSuppliers,
            values: {
                name: formValue.name?.trim(),
                abbreviation: formValue.abbreviation?.trim(),
                taxNo: formValue.taxNo?.trim(),
                address: formValue.address?.trim(),
                phoneNo: formValue.phoneNo?.trim(),
                businessIndustryId: formValue.businessIndustryId,
                email: formValue.email?.trim(),
                website: formValue.website?.trim(),
                note: formValue.note?.trim(),
                contactName: formValue.contactName?.trim(),
                contactPositionName: formValue.contactPositionName?.trim(),
                contactPhoneNo: formValue.contactPhoneNo?.trim(),
                contactEmail: formValue.contactEmail?.trim()
            } as ISupplierConfig,
            id: '',
        }

        if (isEdit) {
            dataSend.id = formProps.initialValues?.id || ''
            mutateUpdate(dataSend, handleResponse)
        } else {
            mutateCreate(dataSend, handleResponse)
        }
    }

    return (
        <CreateCustom
            title={
                isEdit ?
                    (<Text style={{ marginBottom: 0 }}>{t('suppliers.titles.update')}: <span className="primary">{record?.name}</span> </Text>) :
                    (<Text style={{ marginBottom: 0 }}>{t('suppliers.titles.create')}</Text>)
            }
            {...props}
            saveButtonProps={{
                ...saveButtonProps,
                title: isEdit ? t('common.saveEdit') : t('buttons.add')
            }}
            visibleShowModal={visibleShowModal}
            setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
            onSubmit={() => onSubmit()}
            confirmModalProps={{
                title: !isEdit ? t('suppliers.messages.createConfirm') : t('common.config.editConfirm'),
                description: isEdit ? t('common.config.editConfirmDesc') : '',
            }}
            saving={isLoadingCreate || isLoadingUpdate}
            cancelModalProps={{
                okButtonProps: {
                    onClick: () => {
                        isEdit ? show(PATH.configSuppliers, record?.id) : list(PATH.configSuppliers)
                    },
                    danger: true
                },
                cancelButtonProps: {}
            }}
            contentStyles={{ backgroundColor: 'transparent', padding: 0 }}
            bodyStyle={{ padding: 0 }}
        >
            <Form
                {...formProps}
                onFinish={() => setVisibleShowModal(true)}
                layout="vertical"
                labelCol={{ span: 10 }}
            >
                <Card>
                    <div style={{ marginBottom: 12 }}>
                        <Text style={{ fontWeight: 500, fontSize: 16 }}>
                            {t('common.titles.detail')}{' '}
                        </Text>
                    </div>

                    <Row gutter={12} style={{ width: '100%' }}>
                        {
                            isEdit &&
                            <Col span={12}>
                                <Form.Item
                                    label={t('suppliers.fields.code')}
                                    name="code"
                                >
                                    <InputUpper disabled />
                                </Form.Item>
                            </Col>
                        }

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.name')}
                                name="name"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: t('errors.ER005'),
                                    },
                                    {
                                        max: 100,
                                        message: t('errors.ER014', { max: '100' }),
                                    },
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.name')} />
                            </Form.Item>
                        </Col>
                        {isEdit ? <></> : <Col span={12}></Col>}

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.abbreviation')}
                                name="abbreviation"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: !isEdit,
                                        message: t('errors.ER005'),
                                    },
                                    {
                                        max: 10,
                                        message: t('errors.ER014', { max: '10' }),
                                    },
                                    {
                                        validator: async (_, value) => {
                                          if (!value) return;
                                          if (!isValidConfigCodeField(value)) {
                                            return Promise.reject(t("errors.ER0139"))
                                          }
                                        },
                                      },
                                ]}
                            >
                                <InputUpper placeholder={t('suppliers.placeholder.abbreviation')} disabled={isEdit} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.taxNo')}
                                name="taxNo"
                                rules={[
                                    {
                                        min: 10,
                                        message: t('errors.ER013', { min: '10' }),
                                    },
                                    {
                                        max: 14,
                                        message: t('errors.ER014', { max: '14' }),
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (!value) return;
                                            if (!isValidNumberAndDash(value)) {
                                                return Promise.reject(t("errors.ER0101"))
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.taxNo')} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.address')}
                                name="address"
                                rules={[
                                    {
                                        max: 200,
                                        message: t('errors.ER014', { max: '200' }),
                                    },
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.address')} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.phone')}
                                name="phoneNo"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: t('errors.ER005'),
                                    },
                                    {
                                        min: 8,
                                        message: t('errors.ER013', { min: '8' }),
                                    },
                                    {
                                        max: 20,
                                        message: t('errors.ER014', { max: '20' }),
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (!value) return;
                                            if (!isValidGeneralPhoneNumber(value)) {
                                                return Promise.reject(t("errors.ER0154"))
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.phone')} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.businessIndustry')}
                                name="businessIndustryId"
                            >
                                <SelectCustom
                                    allowClear
                                    resource={API_PATH.businessIndustrySelect}
                                    optionValue="value"
                                    optionLabel="label"
                                    label={record?.businessIndustry?.name}
                                    formatData={(r: any) => ({
                                        ...r,
                                        label: `${r.code} - ${r.label}`,
                                    })}
                                    
                                    useServerFiltering={false}
                                    placeholder={t('suppliers.placeholder.businessIndustry')}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.email')}
                                name="email"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: t('errors.ER005'),
                                    },
                                    {
                                        max: 255,
                                        message: t('errors.ER014', { max: '255' }),
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (!value) return;
                                            if (!isValidEmail(value)) {
                                                return Promise.reject(t("errors.ER015"))
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.email')} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.website')}
                                name="website"
                                rules={[
                                    {
                                        max: 255,
                                        message: t('errors.ER014', { max: '255' }),
                                    }
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.website')} />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.note')}
                                name="note"
                                rules={[
                                    {
                                        max: 200,
                                        message: t('errors.ER014', { max: '200' }),
                                    },
                                ]}
                            >
                                <TextArea
                                    showCount={{
                                        formatter: (args: { count: number }) => `${args?.count}/200`,
                                    }}
                                    placeholder={t('suppliers.placeholder.note')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                </Card>
                <Card style={{ marginTop: 10 }}>
                    <div style={{ marginBottom: 12 }}>
                        <Text style={{ fontWeight: 500, fontSize: 16 }}>
                            {t("common.title.contactInformation")}{' '}
                        </Text>
                    </div>
                    <Row gutter={12} style={{ width: '100%' }}>
                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.contactName')}
                                name="contactName"
                                rules={[
                                    {
                                        max: 100,
                                        message: t('errors.ER014', { max: '100' }),
                                    },
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.contactName')} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.contactPositionName')}
                                name="contactPositionName"
                                rules={[
                                    {
                                        max: 100,
                                        message: t('errors.ER014', { max: '100' }),
                                    },
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.contactPositionName')} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.contactPhone')}
                                name="contactPhoneNo"
                                rules={[
                                    {
                                        min: 8,
                                        message: t('errors.ER013', { min: '8' }),
                                    },
                                    {
                                        max: 20,
                                        message: t('errors.ER014', { max: '20' }),
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (!value) return;
                                            if (!isValidGeneralPhoneNumber(value)) {
                                                return Promise.reject(t("errors.ER0154"))
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.contactPhone')} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t('suppliers.fields.contactEmail')}
                                name="contactEmail"
                                rules={[
                                    {
                                        max: 255,
                                        message: t('errors.ER014', { max: '255' }),
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (!value) return;
                                            if (!isValidEmail(value)) {
                                                return Promise.reject(t("errors.ER015"))
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input placeholder={t('suppliers.placeholder.contactEmail')} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </CreateCustom>
    )
}