import { useTranslate } from '@pankod/refine-core'
import { BaseTag, TagType } from "./BaseTag";

interface Props {
    status?: boolean
}

export const ConfigStatusTag = ({ status = true }: Props) => {
    const t = useTranslate();
    return status ? <BaseTag text={t("common.status.active")} type={TagType.GREEN} /> : <BaseTag text={t("common.status.deactivate")} type={TagType.GRAY} />
}