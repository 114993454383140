import { Icons, Space, Tooltip, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
const { Text } = Typography;

interface Props {
  value?: any;
  status?: boolean;
  hidden?: boolean;
}

export const ConfigDisplayText = ({ value, status = true, hidden }: Props) => {
  const t = useTranslate();

  if (hidden) return null;
  return status ? (
    <Text>{value}</Text>
  ) : (
    <Space>
      <Icons.ExclamationCircleOutlined />
      <Tooltip
        placement="bottom"
        title={t("common.messages.deactivateTooltip")}
      >
        <Text strong type="secondary">
          {value}
        </Text>
      </Tooltip>
    </Space>
  );
};
