import { useOne, useTranslate } from "@pankod/refine-core";
import { Card, Descriptions } from "antd";
import { DataText, LinkText } from "components";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import { FC, memo, useContext } from "react";
import { InternalEstimateShowContext } from "../../..";
import { API_PATH, SLUGS } from "configs/path";
import { CaseDetailPopup } from "pages/sale/serviceRequest/create/components/Form/CaseInfo";

const CaseInfo: FC = memo(() => {
  const { record } = useContext(InternalEstimateShowContext);

  const translate = useTranslate();
  const caseId = record?.caseId;

  const resCase = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(caseId || ""),
    id: "",
    queryOptions: { enabled: !!caseId },
  });

  const caseDetail = resCase?.data?.data;
  const loadingCase = resCase?.isFetching;
  return (
    <Card style={{ background: "#ffffff" }}>
      <Descriptions
        column={1}
        title={translate("Thông tin vụ việc")}
        layout="vertical"
      >
        <Descriptions.Item
          label={translate("Vụ việc")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText
            value={
              record?.case && (
                <LinkText
                  value={{ ...record?.case, description: record?.case?.name }}
                  resource={SLUGS.caseForSale}
                  showFullInfo
                />
              )
            }
          />
        </Descriptions.Item>
      </Descriptions>
      <div style={{ paddingLeft: 16 }}>
        <CaseDetailPopup caseDetail={caseDetail} loading={loadingCase} />
      </div>
    </Card>
  );
});

export default CaseInfo;
