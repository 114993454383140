import { AuthProvider } from "@pankod/refine-core";
import { login, getTenantPermission, externalLogin, logout } from "api";
import {
  TOKEN_KEY,
  EXPIRED_AT,
  ROLE_USER,
  USER as CURRENT_USER,
  TENANT_ID,
  PERMISSIONS,
  TENANTS,
  USER_EMPLOYEE,
} from "configs/storage";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { UserRoles } from "api/enums";
import { PATH, PERMISSION_PATH } from "configs/path";
import { UserData } from "api/types";

export const defaultRedirectUrl = PATH.dashboards;

export const getPermissions = async () => {
  const res = await getTenantPermission();
  const data = res.data;
  let permissions: any = permissionMapping(data as any);
  let redirectTo: string = defaultRedirectUrl;
  savePermissionsToStorage(permissions, true);
  return redirectTo;
};

export const savePermissionsToStorage = (
  permissions: any,
  clearCurrentValue: boolean = false
) => {
  const currentPermissions = clearCurrentValue
    ? {}
    : JSON.parse(localStorage.getItem(PERMISSIONS) || "{}");
  const newPermissions = { ...currentPermissions, ...permissions };
  localStorage.setItem(PERMISSIONS, JSON.stringify(newPermissions));
};

export const permissionMapping = (permissionData: any[]) => {
  const permissions: any = {};
  if (!permissionData) {
    return permissions;
  }
  Object.values(PERMISSION_PATH).forEach((key) => {
    permissionData.forEach((feature: any) => {
      const featureCode = feature?.featureCode || "";
      if (PERMISSION_PATH[featureCode] === key) {
        const permissionKey = key;
        permissions[permissionKey] = feature.permissions;
      }
    });
  });

  return permissions;
};

export const authProvider: AuthProvider = {
  login: async ({ userName, password, externalToken }) => {
    const resultLogin = !externalToken
      ? await login({ userName, password })
      : await externalLogin(externalToken);

    if (resultLogin && resultLogin.data) {
      const data = resultLogin.data;
      const userRole = data.roles[0];
      const isAdmin =
        userRole === UserRoles.SupperAdmin ||
        userRole === UserRoles.TenantAdmin;
      const fullName = isAdmin ? "Tenant Admin" : userName;

      const tokenClaims = jwtDecode<JwtPayload>(data.token) as any;
      const userInformation = {
        email: userName,
        fullname: fullName,
        isSetupPassword: tokenClaims.IsUserSetupPassword === "True",
      } as UserData;
      localStorage.setItem(TOKEN_KEY, data.token);
      localStorage.setItem(ROLE_USER, userRole);
      localStorage.setItem(EXPIRED_AT, data.tokenExpireTime);
      localStorage.setItem(CURRENT_USER, JSON.stringify(userInformation));

      // Only get permissions if logged user is admin, for user, get permissions after choosing the tenant
      if (isAdmin && !localStorage.getItem(PERMISSIONS)) {
        await getPermissions();
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  },
  logout: async () => {
    await logout();

    // Remove user data
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ROLE_USER);
    localStorage.removeItem(TENANT_ID);
    localStorage.removeItem(CURRENT_USER);
    localStorage.removeItem(PERMISSIONS);
    localStorage.removeItem(TENANTS);
    localStorage.removeItem(USER_EMPLOYEE);

    return Promise.resolve();
  },
  checkError: async (error: any): Promise<void> => {
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const payload = jwtDecode<JwtPayload>(token);
      const now = new Date().getTime();
      if (payload?.exp) {
        const stillLogin = payload.exp * 1000 - now > 0;
        if (stillLogin) {
          return Promise.resolve();
        }
      }
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const roles = localStorage.getItem(ROLE_USER);
    const userInformation = JSON.parse(
      localStorage.getItem(CURRENT_USER) || "{}"
    );
    const userEmployeeInformation = JSON.parse(
      localStorage.getItem(USER_EMPLOYEE) || "{}"
    );
    return Promise.resolve({
      id: 1,
      fullName: userInformation?.fullname,
      email: userInformation?.email,
      role: roles,
      isSetupPassword: userInformation.isSetupPassword || false,
      employee: userEmployeeInformation,
    });
  },
};
