import { Form, FormInstance, FormProps, useSelect } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import BusinessOpportunities from "interfaces/BusinessOpportunities";
import { v4 as uuid } from "uuid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import { generateCurrency } from "components/Currency";

export interface Props {
  form: FormInstance<{}>;
  formProps: FormProps;
  isEdit?: boolean;
  onFinishedFormValidate?: () => void;
  disabled?: boolean;
  initialValue?: BusinessOpportunities;
}

const { useWatch } = Form;

export const useController = (props: Props) => {
  const {
    form,
    formProps,
    onFinishedFormValidate,
    isEdit,
    disabled = false,
    initialValue,
  } = props;
  const translate = useTranslate();

  const products = useWatch("products", form);

  const { selectProps: customerSelectProps } = useSelect({
    resource: API_PATH.customerDropdownlistForBusinessOportunity,
    optionLabel: "name",
    optionValue: "id",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const creationDate = useWatch("creationDate", form);
  const closedDate = useWatch("closedDate", form);

  useEffect(() => {
    if (!!creationDate && !!closedDate) {
      form.setFields([
        {
          name: "closedDate",
          errors:
            dayjs(creationDate).valueOf() <= dayjs(closedDate).valueOf()
              ? undefined
              : [
                  translate(
                    "businessOpportunities.theClosingDateMustBeFfterTheOpeningDate"
                  ),
                ],
        },
      ]);
    }
  }, [creationDate, closedDate]);

  const [list, setList] = useState<string[]>([]);

  const onRemoveProduct = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const transformDefaultProductToFormProduct = useMemo(() => {
    const ids = initialValue?.products.map((item) =>item?.id ?? uuid()) || [];
    const products: { [key: string]: any } = {};
    setList(ids);

    if (initialValue) {
      initialValue?.products.forEach((product, index) => {
        products[ids[index]] = {
          productId: product.product.id,
          productName: product.product.name,
          quantity: product.quantity,
          unitPrice: product.unitPrice,
          description: product?.description,
          partNumber: product?.partNumber,
          unitId: product?.unit?.id,
          businessOppsProductId:product?.id,
        };
      });
    }

    return products;
  }, [initialValue]);

  const { selectProps: statusSelectProps } = useSelect({
    resource: API_PATH.businessOpportunituesStatusDropdownlist,
    optionLabel: "name",
    optionValue: "id",
  });

  const currency = useMemo(() => {
    return generateCurrency();
  }, []);

  const productIdSelecteds = useMemo(
    () =>
      products
        ? Object.values(products).map((product: any) => product.productId)
        : [],
    [products]
  );

  const productOptions = useCallback(
    (options: any[], value?: string) =>
      options.map((o) => ({
        ...o,
        label: `${o.code} - ${o.name}`,
        value: o.id
      })),
    []
  );

  const { selectProps: unitSelectProps } = useSelect({
    resource: API_PATH.unitsDropdownlist,
    optionLabel: "label",
    optionValue: "value",
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  return {
    formProps,
    initialValue,
    transformDefaultProductToFormProduct,
    isEdit,
    statusSelectProps,
    customerSelectProps,
    creationDate,
    list,
    products,
    currency,
    unitSelectProps,
    onFinishedFormValidate,
    translate,
    setList,
    onRemoveProduct,
    productOptions,
  };
};
