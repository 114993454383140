import {
  Button,
  Col,
  Descriptions,
  Form,
  Icons,
  Input,
  Row,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { v4 as uuid } from "uuid";
import { Dispatch, FC, SetStateAction, memo, useMemo, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import { SelectCustom } from "components";
import { API_PATH } from "configs/path";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { Currency, generateCurrency } from "components/Currency";
import ButtonConfirm from "components/ButtonConfirm";
import { cloneDeep } from "lodash";
import { CurrencyType } from "api/enums";
import { IForm } from "../../..";

interface Props {
  list: string[];
  setList: Dispatch<SetStateAction<string[]>>;
}

const { Title, Text } = Typography;

const { TextArea } = Input;
const { useFormInstance, useWatch } = Form;
const QuotationTable: FC<Props> = memo((props) => {
  const { list, setList } = props;
  const form = useFormInstance<IForm>();
  const translate = useTranslate();

  const quotationProducts = useWatch("quotationProducts", form);

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);

  const isShowButtonRemoveItem = useMemo(() => list.length > 1, [list]);

  const sumBeforeTax = useMemo(
    () =>
      Object.values(quotationProducts || {})?.reduce(
        (sum, next) => sum + (next?.quantity ?? 0) * (next?.unitPrice ?? 0),
        0
      ),
    [quotationProducts]
  );

  const sumTax = useMemo(
    () =>
      Object.values(quotationProducts || {})?.reduce(
        (sum, next) =>
          sum +
          ((next?.quantity ?? 0) *
            (next?.unitPrice ?? 0) *
            (next?.taxPercent ?? 0)) /
            100,
        0
      ),
    [quotationProducts]
  );

  const sumAfterTax = useMemo(
    () =>
      Object.values(quotationProducts || {})?.reduce((sum, next) => {
        const { unitPrice = 0, quantity = 0, taxPercent = 0 } = next || {};
        const priceBeforeTax = unitPrice && quantity ? unitPrice * quantity : 0;
        const tax =
          priceBeforeTax && taxPercent
            ? (priceBeforeTax * taxPercent) / 100
            : 0;
        const priceAfterTax = priceBeforeTax + tax;
        return sum + priceAfterTax;
      }, 0),
    [quotationProducts]
  );

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Row align="middle" style={{ marginBottom: 8 }}>
        <Col flex={1}>
          <Title level={5}>Bảng báo giá</Title>
        </Col>
        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setList([...list, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {translate("actions.create")}
          </Button>
          <div>
            <Text style={{ marginTop: 4 }} className="block">
              {translate("common.currency.unit")}:{" "}
              <Text style={{ color: currency.unitColor, fontWeight: "bold" }}>
                {currency.unit}
              </Text>
            </Text>
          </div>
        </div>
      </Row>
      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        scroll={{ x: 2100 }}
      >
        <Table.Column
          title={translate("common.no")}
          dataIndex="stt"
          align="center"
          width={"70px"}
          render={(v, keyRow: string, index) => {
            return index + 1;
          }}
        />
        <Table.Column
          title={translate("Tên sản phẩm")}
          dataIndex="productId"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["quotationProducts", keyRow, "productId"]}
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: translate("errors.ER005"),
                  },
                ]}
              >
                <SelectCustom
                  resource={API_PATH.productDropdownList}
                  allowClear
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("products.fields.partNumber")}
          dataIndex="partNumber"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["quotationProducts", keyRow, "partNumber"]}
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: translate("errors.ER005"),
                  },
                  {
                    max: 200,
                    message: translate("errors.ER014", { max: 200 }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("common.description")}
          dataIndex="description"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["quotationProducts", keyRow, "description"]}
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: translate("errors.ER005"),
                  },
                  {
                    max: 3000,
                    message: translate("errors.ER014", { max: 3000 }),
                  },
                ]}
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 4 }} />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("common.quantity")}
          dataIndex="quantity"
          width={"100px"}
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["quotationProducts", keyRow, "quantity"]}
                isLimit
                isRequired
                className="m-0"
              />
            );
          }}
        />
        <Table.Column
          title={translate("products.fields.unit")}
          dataIndex="unitId"
          width={"100px"}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                style={{ margin: 0 }}
                name={["quotationProducts", keyRow, "unitId"]}
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: translate("errors.ER005"),
                  },
                ]}
              >
                <SelectCustom
                  resource={API_PATH.unitsDropdownlist}
                  optionLabel="label"
                  optionValue="value"
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("products.fields.unitPrice")}
          dataIndex="unitPrice"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["quotationProducts", keyRow, "unitPrice"]}
                isLimit
                isRequired
                className="m-0"
              />
            );
          }}
        />
        <Table.Column
          title={translate("% Thuế")}
          dataIndex="taxPercent"
          width={"100px"}
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["quotationProducts", keyRow, "taxPercent"]}
                isLimit
                isRequired
                min={0}
                max={100}
                errorCode="ER0131"
                className="m-0"
              />
            );
          }}
        />
        <Table.Column
          title={translate("common.totalPrice.beforeTax")}
          dataIndex="priceBeforeTax"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            const row = quotationProducts?.[keyRow];
            const { unitPrice = 0, quantity = 0 } = row || {};
            return (
              <Currency
                showCurrency={false}
                value={unitPrice && quantity ? unitPrice * quantity : 0}
              />
            );
          }}
        />
        <Table.Column
          title={translate("Thuế")}
          dataIndex="tax"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            const row = quotationProducts?.[keyRow];
            const { unitPrice = 0, quantity = 0, taxPercent = 0 } = row || {};
            const priceBeforeTax =
              unitPrice && quantity ? unitPrice * quantity : 0;

            return (
              <Currency
                showCurrency={false}
                value={
                  priceBeforeTax && taxPercent
                    ? (priceBeforeTax * taxPercent) / 100
                    : 0
                }
              />
            );
          }}
        />
        <Table.Column
          title={translate("common.totalPrice.afterTax")}
          dataIndex="priceAfterTax"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            const row = quotationProducts?.[keyRow];
            const { unitPrice = 0, quantity = 0, taxPercent = 0 } = row || {};
            const priceBeforeTax =
              unitPrice && quantity ? unitPrice * quantity : 0;
            const tax =
              priceBeforeTax && taxPercent
                ? (priceBeforeTax * taxPercent) / 100
                : 0;
            const priceAfterTax = priceBeforeTax + tax;

            return <Currency showCurrency={false} value={priceAfterTax} />;
          }}
        />
        <Table.Column
          title={translate("Ghi chú")}
          dataIndex="priceAfterTax"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["quotationProducts", keyRow, "note"]}
                className="m-0"
                rules={[
                  {
                    max: 3000,
                    message: translate("errors.ER014", { max: 3000 }),
                  },
                ]}
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 4 }} />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          align="center"
          width="90px"
          fixed="right"
          title={translate("table.actions")}
          render={(v, record, index) => {
            if (isShowButtonRemoveItem)
              return (
                <ButtonConfirm
                  text={translate(
                    "Bạn chắc chắn muốn xóa sản phẩm dịch vụ này?"
                  )}
                  description=""
                  onClick={onRemoveItem(index)}
                  type="text"
                  danger
                >
                  <Icons.DeleteOutlined />
                </ButtonConfirm>
              );
          }}
        />
      </Table>
      <Descriptions
        column={{ sm: 1, md: 3 }}
        layout="vertical"
        className="no-padding"
        style={{ marginTop: 8 }}
      >
        <Descriptions.Item
          label={translate("Giá trị trước thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={sumBeforeTax} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={sumTax} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Giá trị sau thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={sumAfterTax} />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
});

export default QuotationTable;
