import { Button, Checkbox, Col, Form, Icons, Modal, Row, Select, Space, Table, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { Typography } from 'antd';
import { API_PATH } from "configs/path";
import { IFeature, IFeaturePermission } from "interfaces";
import React, { useEffect } from "react";
import { ActionTypeEnum } from "utils/enums";
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { searchSelect } from "utils/commons"

const { Title, Text, Paragraph } = Typography;

interface IRolePermissionsProps {
    onChange?: any,
    actionType?: ActionTypeEnum
    value?: IFeaturePermission[]
}

export const RolePermissions: React.FC<IResourceComponentsProps & IRolePermissionsProps> = ({ onChange, actionType, value }: IRolePermissionsProps) => {
    const translate = useTranslate();
    const permissions = value || [] ;
    const [selectedFeatures, setSelectedFeatures] = React.useState<IFeature[]>([]);
    const [selectedRecord, setSelectedRecord] = React.useState<IFeaturePermission>();
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [isDisable, setIsDisable] = React.useState<boolean>(actionType == ActionTypeEnum.VIEW)
    const [visibleRemoveConfirmModal, setVisibleRemoveConfirmModal] = React.useState<boolean>();

    const { selectProps: parentsSelectProps } = useSelect<IFeature>({
        resource: `${API_PATH.features}/dropdownlist`,
        optionValue: 'featureId',
        optionLabel: 'featureName',
        onSearch: () => ([ // avoid call api when search
            {
                field: "q",
                operator: "eq",
                value: undefined,
            }
        ])
    });

    useEffect(() => {
        setSelectedFeatures(
            permissions.map(t => { return {featureId: t.featureId} as IFeature})
        )
    }, [value])

    const addNewPermission = () => {
        const temp = [...permissions];
        temp.push({...defaultPermission, id: uuid()} as IFeaturePermission);
        onChange(temp);
    }
    const defaultPermission: IFeaturePermission = {
        read: true,
        create: false,
        update: false,
        delete: false,
        all: false,
        print: false,
        featureId: ''
    }

    const columns: ColumnsType<IFeaturePermission> = [
        {
            title: translate('roles.fields.no'),
            dataIndex: 'STT',
            key: 'name',
            render: (_, record, index) => {
                return (
                    <Text>{index + 1}</Text>
                );
            }
        },
        {
            title: translate('roles.fields.featureName'),
            dataIndex: 'featureId',
            key: 'featureId',
            width: 500,
            render: (value, record) => {
                if (actionType == ActionTypeEnum.VIEW) {
                    return (<Text>{record?.featureName}</Text>);
                }

                const selectProps = {...parentsSelectProps};
                const options = selectProps.options?.filter(o => {return selectedFeatures.findIndex(s => (s.featureId == o.value && s.featureId != value)) < 0});
                selectProps.options = options;
                
                return (
                    <Select
                        {...selectProps}
                        disabled={isDisable}
                        onChange={(val) => onSelectFeature(record, val?.value)}
                        value={value}
                        labelInValue={true}
                        style={{ width: '100%' }}
                        allowClear
                        filterOption={
                            (inputValue: string, option: any) => {
                                return option && searchSelect(inputValue, option?.label)
                            }
                        }
                    />
                )
            }
        },
        {
            title: translate('roles.fields.all'),
            dataIndex: 'all',
            align: "center",
            key: 'all',
            
            render: (_, record, index) => {
                return <Checkbox disabled={isDisable} checked={record.all} onChange={(e) => {onCheckAll(record, e.target.checked);}}></Checkbox>
            },
        },
        {
            title: translate('roles.fields.view'),
            dataIndex: 'read',
            key: 'read',
            align: "center",
            render: (_, record, index) => {
                return <Checkbox disabled={true} checked={record.read}></Checkbox>
            },
        },
        {
            title: translate('roles.fields.update'),
            dataIndex: 'update',
            key: 'update',
            align: "center",
            render: (_, record, index) => {
                return <Checkbox disabled={isDisable} onChange={(e) => {onChangePermission('update', e.target.checked, record.id)}} checked={record.update}></Checkbox>
            },
        },
        {
            title: translate('roles.fields.delete'),
            dataIndex: 'delete',
            key: 'delete',
            align: "center",
            render: (_, record, index) => {
                return <Checkbox disabled={isDisable} onChange={(e) => {onChangePermission('delete', e.target.checked, record.id)}} checked={record.delete}></Checkbox>
            },
        },
        {
            title: translate('roles.fields.create'),
            dataIndex: 'create',
            key: 'create',
            align: "center",
            render: (_, record, index) => {
                return <Checkbox disabled={isDisable} onChange={(e) => {onChangePermission('create', e.target.checked, record.id)}} checked={record.create}></Checkbox>
            },
        },
        {
            title: translate('roles.fields.print'),
            dataIndex: 'print',
            key: 'print',
            align: "center",
            render: (_, record, index) => {
                return <Checkbox disabled={isDisable} onChange={(e) => {onChangePermission('print', e.target.checked, record.id)}} checked={record.print}></Checkbox>
            },
        },
        ...(
            actionType == ActionTypeEnum.VIEW ? [] : [
                {
                    title: '',
                    align: "center",
                    render: (_, record, index) => {
                        return (
                            <Button disabled={isDisable} onClick={() => onConfirmRemoveItem(record)} type="text" size="small" style={{color: '#8C8C8C'}} icon={<DeleteOutlined />}></Button>
                        )
                    }
                } as ColumnType<IFeaturePermission>
            ]
        )
    ];

    const onConfirmRemoveItem = (record: IFeaturePermission) => {
        setSelectedRecord(record);
        setVisibleRemoveConfirmModal(true);
    }

    const onRemoveItem = () => {
        if (selectedRecord) {
            const temp = [...permissions.filter(t => t.id !== selectedRecord.id)];
            setSelectedFeatures([...selectedFeatures.filter(t => t.featureId != selectedRecord.featureId)])
            setSelectedRecord(undefined);
            setVisibleRemoveConfirmModal(false);
            onChange(temp)
        }
    }

    const onCheckAll = (record: IFeaturePermission, isChecked: boolean) => {
        const newState = [...permissions.map((val) => {
            if (val.id == record.id) {
                val.all = isChecked;
                val.create = isChecked;
                val.delete = isChecked;
                val.update = isChecked;
                val.delete = isChecked;
                val.print = isChecked;
            }
            return val;
        }) ]
        onChange(newState)
    }

    const onChangePermission = (checkBoxType: string, isChecked: boolean, id: any) => {
        const newState = [...permissions.map(val => {
            if (val.id == id) {
                val[checkBoxType] = isChecked;
                val.all = (val.create && val.update && val.delete && val.read && val.print);
            }
            return val;
        })]
        onChange(newState)
    }

    const onSelectFeature = (record: IFeaturePermission, featureId: any) => {
        const temp = [...selectedFeatures];
        temp.push({featureId: featureId} as IFeature);
        setSelectedFeatures(temp);

        const newState = [...permissions.map(val => {
            if (val.id == record.id) {
                val.featureId = featureId;
            }
            return val;
        })]
        onChange(newState)
    }

    return (
        <>
            <Title level={5} style={{marginTop: 24, marginBottom: 24}}>{translate('roles.titles.rolePermissions')}</Title>
            <Row hidden={actionType == ActionTypeEnum.VIEW}  style={{marginBottom: 12}}>
                <Col style={{textAlign: 'right'}} span={24}>
                    <Button onClick={addNewPermission} type="primary"><PlusOutlined /> {translate('roles.actions.addRow')} </Button>
                </Col>
            </Row>
            <Table pagination={{hideOnSinglePage: true, pageSize: 1000}} columns={columns} dataSource={permissions} rowKey="id" />

            <Modal
                title=""
                visible={visibleRemoveConfirmModal}
                onOk={onRemoveItem}
                onCancel={() => setVisibleRemoveConfirmModal(false)}
                okText={translate('buttons.confirm')}
                okButtonProps={{
                danger: true
                }}
                cancelText={translate('buttons.reject')}
            >
                <Space align="start">
                <Text style={{fontSize: 22}} type="warning"><Icons.InfoCircleOutlined/></Text>
                <div>
                    <Paragraph style={{fontWeight: 500, fontSize: 16, paddingTop: 5, marginBottom: 0}}>{translate('roles.message.deleteConfirm')}</Paragraph>
                </div>
                </Space>
            </Modal>
        </>
    );
}
