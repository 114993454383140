import { Space, Typography } from "@pankod/refine-antd";
import { CurrencyType } from "api/enums";

const {Text} = Typography
interface CurrencyProps {
    currencyType: CurrencyType | undefined
}

export const CurrencyUnit = ({currencyType = CurrencyType.VND}: CurrencyProps) => {
   let unit = '';
   switch(currencyType) {
    case CurrencyType.EUR: unit = 'EUR'; break;
    case CurrencyType.VND: unit = 'VND'; break;
    case CurrencyType.USD: unit = 'USD'; break;
    case CurrencyType.SGD: unit = 'SGD'; break;
   }
   return (
    <Text>{unit}</Text>
   )
}