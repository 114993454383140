import {
  Button,
  Descriptions,
  Space,
  Timeline,
  Typography,
} from '@pankod/refine-antd'
import { useList } from '@pankod/refine-core'
import { API_PATH, PATH } from 'configs/path'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { DataText } from 'components/DataText'
import { DATE_AND_TIME_FORMAT, DATE_FORMAT } from 'configs/constants'
import { ApprovalActionStatus, ApprovalStatus } from 'api/enums'
import { IApprovalHistory } from 'interfaces'
import { EmptyData } from 'components/EmptyData'
import { formatISODateTimeToView } from 'utils/commons'
import { useTranslate } from '@pankod/refine-core'

const { Text } = Typography

interface HistoriesProps {
  recordId?: string
  resource: string
  recordStatus?: string
}
export const Histories = ({
  recordId,
  resource,
  recordStatus,
}: HistoriesProps) => {
  const [current, setCurrent] = useState(1)
  const [totalRecord, setTotalRecord] = useState(15)
  const [histories, setHistories] = useState<IApprovalHistory[]>([])
  const t = useTranslate();

  const { data } = useList<any>({
    resource: resource,
    metaData: {
      after: '/histories/' + recordId,
    },
    config: {
      pagination: { current: current, pageSize: 15 },
    },
  })

  useEffect(() => {
    if (current === 1) {
      setHistories(data?.data || [])
    } else {
      setHistories((a: any) => a.concat(data?.data || []))
    }
    setTotalRecord(data?.total || 0)
  }, [data])

  const seeMore = () => {
    setCurrent((pre) => pre + 1)
  }

  useEffect(() => {
    setCurrent(1)
  }, [recordStatus])
  const getValueText = (value: number) => {
    switch (value) {
      case ApprovalActionStatus.Approved:
        return (
          <Text strong type="success">
            {t('histories.approved').toUpperCase()}
          </Text>
        )
      case ApprovalActionStatus.Rejected:
        return (
          <Text strong type="danger">
            {t('histories.rejected').toUpperCase()}
          </Text>
        )
      case ApprovalActionStatus.Returned:
        return (
          <Text strong style={{ color: 'black' }}>
            {t('histories.returned').toUpperCase()}
          </Text>
        )
      case ApprovalActionStatus.WaitingProcess:
        return (
          <Text strong type="danger">
            {t('histories.waitingProcess').toUpperCase()}
          </Text>
        )
      case ApprovalActionStatus.Submit:
        return (
          <Text strong style={{ color: 'blue' }}>
            {t('histories.submit').toUpperCase()}
          </Text>
        )
      case ApprovalActionStatus.Cancel:
        return (
          <Text strong type="danger">
            {t('histories.cancel').toUpperCase()}
          </Text>
        )
      case ApprovalActionStatus.Retrieve:
        return (
          <Text strong style={{color: "#030852"}}>
            {t('histories.retrieve').toUpperCase()}
          </Text>
        )
      case ApprovalActionStatus.Edit:
        return (
          <Text strong type="warning">
            {t('histories.edit').toUpperCase()}
          </Text>
        )
    }
  }

  return (
    <Descriptions title={t('histories.title')} column={1}>
      {histories?.length ? (
        <>
          <Descriptions.Item>
            <Timeline>
              {histories.map((h) => (
                <Timeline.Item
                  key={h.processedTime}
                  color="blue"
                  dot={
                    <div
                      style={{
                        backgroundColor: '#2F54EB',
                        height: 10,
                        width: 10,
                        borderRadius: 5,
                      }}
                    />
                  }
                >
                  <span>
                    <DataText
                      value={h?.processedTime ? formatISODateTimeToView(h?.processedTime?.toString(), DATE_AND_TIME_FORMAT) : ''}
                    />
                  </span>
                  <Descriptions column={1} className="history">
                    {(h.action !== ApprovalActionStatus.Submit && h.action !== ApprovalActionStatus.Retrieve && h.action !== ApprovalActionStatus.Cancel) ? (
                    <Descriptions.Item label={<span>{t('histories.fields.receivedTime')}:</span>}>
                      <DataText
                        value={h?.receivedTime ? formatISODateTimeToView(h?.receivedTime?.toString(), DATE_AND_TIME_FORMAT) : ''}
                      />
                    </Descriptions.Item>
                    ) : null}

                    <Descriptions.Item label={<span>{t('histories.fields.processedTime')}:</span>}>
                      <DataText
                        value={h?.processedTime ? formatISODateTimeToView(h?.processedTime?.toString(), DATE_AND_TIME_FORMAT) : ''}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label={<span>{t('histories.fields.employee')}:</span>}>
                      {!h?.employee ||
                      (h?.employee && Object.keys(h?.employee).length === 0) ? (
                        <Text type="secondary">{t('common.emptyText')}</Text>
                      ) : (
                        <span>
                          {`${h?.employee?.code} - ${h?.employee?.fullName} ${h?.userEmail ? "-" : ""} `}
                          <a
                            href={'mailto:' + h?.userEmail}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {h?.userEmail}
                          </a>
                        </span>
                      )}
                    </Descriptions.Item>

                    <Descriptions.Item label={<span>{t('histories.fields.action')}:</span>}>
                      {getValueText(h.action)}
                    </Descriptions.Item>

                    <Descriptions.Item label={<span>{t('histories.fields.reason')}:</span>}>
                      <DataText value={h?.reason} />
                    </Descriptions.Item>
                  </Descriptions>
                </Timeline.Item>
              ))}
            </Timeline>
          </Descriptions.Item>
          {totalRecord > histories.length && (
            <Descriptions.Item>
              <Space
                align="center"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Button onClick={() => seeMore()}>{t('histories.viewmore')}</Button>
              </Space>
            </Descriptions.Item>
          )}
        </>
      ) : (
        <Descriptions.Item>
          <Space
            align="center"
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignContent: 'center',
              textAlign: 'center',
            }}
          >
            <EmptyData />
          </Space>
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}
