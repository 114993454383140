import { useTranslate } from "@pankod/refine-core";
import { Descriptions } from "antd";
import { DataText } from "components";
import IQuotation from "interfaces/Quotation";
import { FC, memo } from "react";

interface Props {
  record?: IQuotation;
}
const Policy: FC<Props> = memo((props) => {
  const { record } = props;
  const translate = useTranslate();
  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={1}
        title={translate("Điều khoản báo giá")}
        layout="vertical"
      >
        <Descriptions.Item
          label={translate("1. Giá bán")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.pricePolicy} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("2. Thời gian giao hàng")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.deliveryTimePolicy} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("3. Thời hạn báo giá")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.durationPolicy} />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
});

export default Policy;
