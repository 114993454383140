import { FC } from "react";
import { useController } from "./controller";
import {
  Button,
  Col,
  Form,
  Icons,
  List,
  Radio,
  Row,
  Space,
  Table,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { ButtonTooltip, EmptyData, LinkText, SearchBox } from "components";
import { PATH, SLUGS } from "configs/path";
import usePermissions from "hooks/permission";
import { formatISODateTimeToView } from "utils/commons";
import CTableColumn from "components/CTableColumn";
import { SettlementTable } from "interfaces/Settlement";
import { DATE_TIME_FORMAT } from "configs/constants";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import styled from "styled-components";
import { OptionViewApproval } from "interfaces/common";
import { ApprovalStatus } from "api/enums";
import { SubmitEmployee, WaitingProcessEmployees } from "interfaces";

const Column = CTableColumn<RowData>();

interface RowData extends SettlementTable {
  submitEmployee: SubmitEmployee;
  waitingProcessEmployees: WaitingProcessEmployees[];
}

const RowStyle = styled(Row)`
  justify-content: flex-start;

  @media only screen and (min-width: 1200px) {
    justify-content: flex-end;
  }
`;

const SearchBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8;
`;

const SettlementList: FC = () => {
  const {
    formProps,
    form,
    table: {
      tableQueryResult: { data, isLoading },
      sorter,
      pageSize,
      current,
      filters,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
    dataTable,
    statusOptions,
    onChangeOptionView,
    optionViewApproval,
  } = useController();
  const { edit, show, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const translate = useTranslate();

  return (
    <>
      <List
        title={translate("settlements.titles.list")}
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.settlements)}
            {...checkCreateHaveHelperText(PATH.settlements)}
          >
            {translate("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Form {...formProps} style={{ marginBottom: 16 }}>
            <Row gutter={[16, 16]}>
              <Col lg={24} xl={18}>
                <SearchBoxWrapper>
                  <SearchBox
                    placeholder={translate("settlements.searchPlaceholder")}
                    style={{ width: 400, marginBottom: 0 }}
                    onSearch={(e) => {
                      setFilters([
                        {
                          field: "q",
                          operator: "eq",
                          value: e,
                        },
                      ]);
                    }}
                  />

                  {filters &&
                    filters.length > 0 &&
                    !(
                      filters.length === 1 &&
                      filters[0]?.operator === "containss"
                    ) && (
                      <Button
                        type="text"
                        onClick={(e) => {
                          form.resetFields();
                          setFilters([], "replace");
                        }}
                        style={{ color: "#8C8C8C" }}
                      >
                        {translate("common.cleanFilter")}
                        <Icons.CloseOutlined />
                      </Button>
                    )}
                </SearchBoxWrapper>
              </Col>
              <Form.Item name={["optionViewApproval"]} hidden />
              <Form.Item name={["oldStatus"]} hidden initialValue={-1} />

              <Col lg={24} xl={6}>
                <RowStyle gutter={8}>
                  <Col>
                    <Button
                      onClick={onChangeOptionView(OptionViewApproval.MINE)}
                      shape="round"
                      type={
                        optionViewApproval === OptionViewApproval.MINE
                          ? "primary"
                          : "ghost"
                      }
                    >
                      {translate("common.my")}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={onChangeOptionView(OptionViewApproval.APPROVING)}
                      shape="round"
                      type={
                        optionViewApproval === OptionViewApproval.APPROVING
                          ? "primary"
                          : "ghost"
                      }
                    >
                      {translate("common.needProcess")}
                    </Button>
                  </Col>
                </RowStyle>
              </Col>
            </Row>
            <Form.Item
              name={["status"]}
              style={{ marginTop: 16 }}
              className="m-0"
              initialValue={-1}
            >
              <Radio.Group
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => {
                  setCurrent(1);
                  form.setFieldsValue({ oldStatus: e.target.value });
                  setFilters([
                    {
                      field: "status",
                      operator: "eq",
                      value:
                        e?.target?.value === -1 ? undefined : e?.target?.value,
                    },
                  ]);
                }}
              >
                <Radio.Button disabled={optionViewApproval === 1} value={-1}>
                  {translate("common.all")}
                </Radio.Button>
                {statusOptions?.map(({ label, value }) => {
                  return (
                    <Radio.Button
                      disabled={
                        optionViewApproval === 1 &&
                        value !== ApprovalStatus.Approving
                      }
                      value={value}
                    >
                      {translate(label as string)}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Form>
          <Table
            sticky
            loading={isLoading}
            dataSource={dataTable}
            rowKey="id"
            locale={{
              emptyText: <EmptyData text={translate("common.noDataSearch")} />,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show(PATH.settlements, record.id);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: data?.total,
              showSizeChanger: true,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: translate("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{translate("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, __, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field: newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            scroll={{ x: 2200 }}
            showSorterTooltip={false}
          >
            <Column
              key="id"
              dataIndex="id"
              title={translate("common.no")}
              render={(_, __, index) => (current - 1) * pageSize + index + 1}
              width={80}
            />

            <Column
              key="code"
              dataIndex="code"
              title={translate("settlements.table.title.id")}
              render={(value, record) => <TextField className="blue" value={value || "-"} />}
              width={150}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              sorter
            />

            <Column
              key="case"
              dataIndex="case"
              width={150}
              title={translate("cases.table.title.case")}
              render={(value, record) => (
                <LinkText
                  value={{ id: record?.case?.id, code: record?.case?.code }}
                  resource={SLUGS.caseForSale}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("case", sorter)}
              sorter
            />

            <Column
              key="case"
              dataIndex="case"
              width={200}
              title={translate("cases.table.title.name")}
              render={(value, record) => (
                <TextField value={record?.case?.name} />
              )}
              defaultSortOrder={getDefaultSortOrder("case", sorter)}
              sorter
            />

            <Column
              key="status"
              dataIndex="status"
              title={translate("table.process.status")}
              render={(value, record) => <ApprovalStatusLabel status={value} />}
              width={150}
              defaultSortOrder={getDefaultSortOrder("status", sorter)}
              sorter
            />

            <Column
              width={200}
              key="waitingProcessEmployees"
              dataIndex="waitingProcessEmployees"
              title={translate("Người xử lý")}
              render={(value, record) => {
                const content = record?.waitingProcessEmployees
                  ?.map((emp) => `${emp.code} - ${emp.fullName}`)
                  ?.join(",");
                return <TextField value={content || "-"} />;
              }}
              defaultSortOrder={getDefaultSortOrder(
                "waitingProcessEmployees",
                sorter
              )}
              sorter
            />

            <Column
              key="createdUserDetail"
              dataIndex="createdUserDetail"
              title={translate("common.createdName")}
              render={(value, { createdUserDetail }) => (
                <TextField
                  value={`${createdUserDetail?.code} - ${createdUserDetail?.name}`}
                />
              )}
              defaultSortOrder={getDefaultSortOrder(
                "createdUserDetail",
                sorter
              )}
              sorter
              width={200}
            />

            <Column
              key="createdDate"
              dataIndex="createdDate"
              title={translate("common.createdDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
              sorter
              width={200}
            />

            <Column
              key="updatedUserDetail"
              dataIndex="updatedUserDetail"
              title={translate("common.updatedName")}
              render={(value, { updatedUserDetail }) => (
                <TextField
                  value={`${updatedUserDetail?.code} - ${updatedUserDetail?.name}`}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
              sorter
              width={200}
            />

            <Column
              key="updatedDate"
              dataIndex="updatedDate"
              title={translate("common.updatedDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              sorter
              width={200}
            />

            <Column
              title={translate("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => {
                return (
                  <Space>
                    <ButtonTooltip
                      hidden={
                        ![
                          ApprovalStatus.Draft,
                          ApprovalStatus.Returned,
                        ].includes(Number(record?.status))
                      }
                      type="text"
                      icon={<Icons.EditOutlined />}
                      className="gray"
                      onClick={(e) => {
                        e.stopPropagation();
                        edit(PATH.settlements, record.id!);
                      }}
                      {...checkEditHaveHelperText(PATH.settlements)}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </div>
      </List>
    </>
  );
};

export default SettlementList;
