import { Form, FormInstance, FormProps } from "@pankod/refine-antd";
import { FC, memo, useState } from "react";
import General from "./General";
import ContractInfo from "./ContractInfo";
import ServiceScope from "./ServiceScope";
import PaymentProgress from "./PaymentProgress";
import InvoicePlan from "./InvoicePlan";
import ContractDocument from "./ContractDocument";
import { IForm } from "../..";
import IContract from "interfaces/Contract";
import CaseInfo from "./CaseInfo";
import ContractImplementProgress from "./ContractImplementProgress";

interface Props {
  onFinishedFormValidate: () => void;
  formProps: FormProps;
  form: FormInstance<IForm>;
}

const ContractForm: FC<Props> = memo((props) => {
  const { onFinishedFormValidate, formProps, form } = props;

  const initialValue: Partial<IContract> = formProps?.initialValues || {};

  const [producIds, setProductIds] = useState<string[]>([]);

  const onChangeCase = async (value: any) => {
    // try {
    //   const ids: string[] = [];
    //   const contractProducts: any = {};
    //   const resProduct = await getProductsForBusinessPlan(
    //     `${API_PATH.contractProductsDefault}/${value}`
    //   );

    //   resProduct?.data?.forEach((product: ProductDefault) => {
    //     const id = uuid();
    //     ids.push(id);
    //     contractProducts[id] = {
    //       productId: product?.product?.id,
    //       partNumber: product?.partNumber,
    //       description: product?.description,
    //       quantity: product?.quantity,
    //       unitId: product?.unit?.id,
    //       unitName: product?.unit?.name,
    //       unitPrice: product?.unitPrice,
    //     };
    //   });

    //   setProductIds(ids);
    //   setTimeout(
    //     () =>
    //       form.setFieldsValue({
    //         contractProducts,
    //       }),
    //     0
    //   );
    // } catch (error) {}
  };

  return (
    <Form
      {...formProps}
      initialValues={{
        ...initialValue,
        code: initialValue?.code || "-",
        contractProducts: undefined,
        contractGuaranteeInfos: undefined,
        contractInvoicePlans: undefined,
        contractPaymentProcesses: undefined,
        contractImplementProgress: undefined,
        contractType: initialValue?.contractType?.id,
        contractTypeCode: initialValue?.contractType?.code,
        contractStatusCode: initialValue?.contractStatus?.code,
        contractStatus: initialValue?.contractStatus?.id,
        userParty: {
          partyId: initialValue?.userParty?.partyId,
          partyType: initialValue?.userParty?.partyType,
          id: initialValue?.userParty?.id,
        },
        serviceProviderParty: {
          partyId: initialValue?.serviceProviderParty?.partyId,
          partyType: initialValue?.serviceProviderParty?.partyType,
          id: initialValue?.serviceProviderParty?.id,
        },
        thirdParty: {
          partyId: initialValue?.thirdParty?.partyId,
          partyType: initialValue?.thirdParty?.partyType,
          id: initialValue?.thirdParty?.id,
        },
        originalContractId: initialValue?.originalContract?.id,
      }}
      onFinish={onFinishedFormValidate}
      layout="vertical"
      style={{ display: "flex", flexDirection: "column", gap: 8 }}
    >
      <CaseInfo onChangeCase={onChangeCase} />
      <General />
      <ContractInfo />
      <ServiceScope productsIds={producIds} setProductIds={setProductIds} />
      <ContractImplementProgress />
      <PaymentProgress />
      <InvoicePlan />
      <ContractDocument />
    </Form>
  );
});

export default ContractForm;
