import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useTable,
  useNavigation,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  Space,
  Select,
  Icons,
  Tag,
  Button,
  useSelect,
  Form,
  useForm,
  getDefaultSortOrder,
  Radio,
} from "@pankod/refine-antd";
import { IEmployee } from "interfaces";
import { EmptyData, SearchBox, ButtonTooltip } from "components";
import { useNavigate } from "react-router-dom";
import { EmployeeStatus } from "api/enums";
import { formatISODateTimeToView, searchSelect } from "utils/commons";
import { API_PATH, PATH } from "configs/path";
import { EMPLOYEE_STATUS } from "configs/constants";
import usePermissions from "hooks/permission";

export const EmployeesList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { edit, show, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const { form, formProps } = useForm<any>();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);
  const {
    tableQueryResult: { data: dataTable },
    sorter,
    setSorter,
    pageSize,
    setPageSize,
    current,
    setCurrent,
    setFilters,
    filters,
  } = useTable<any>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const { selectProps: departmentSelectProps } = useSelect<any>({
    resource: API_PATH.departmentSelect,
    optionValue: "id",
    optionLabel: "name",
    onSearch: () => [
      // avoid call api when search
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const { selectProps: titleSelectProps } = useSelect<any>({
    resource: API_PATH.employeeTitlesDropdownList,
    optionValue: "value",
    optionLabel: "label",
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const onSearch = (value: string) => {
    setFilters([
      {
        field: "q",
        operator: "containss",
        value: value,
      },
    ]);
  };

  React.useEffect(() => {
    setCurrent(1);
    if (filters && filters.length > 0) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  }, [filters]);

  const renderStatus = (value: number) => {
    switch (value) {
      case EmployeeStatus.Active:
        return <Tag color="success">{t("employees.fields.status.active")}</Tag>;

      default:
        return (
          <Tag color="#F0F0F0" style={{ color: "#8C8C8C" }}>
            {t("employees.fields.status.deactive")}
          </Tag>
        );
    }
  };

  return (
    <List
      headerButtons={
        <ButtonTooltip
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => create(PATH.employees)}
          {...checkCreateHaveHelperText(PATH.employees)}
        >
          {t("employees.titles.create")}
        </ButtonTooltip>
      }
      breadcrumb={null}
    >
      <div className="list-content">
        <Form {...formProps}>
          <Space>
            <SearchBox
              placeholder={t("employees.search")}
              onSearch={onSearch}
            />
            <Space>
              <Form.Item name={["departmentId"]} style={{ marginBottom: 16 }}>
                <Select
                  {...departmentSelectProps}
                  allowClear
                  onChange={(e) => {
                    setFilters([
                      {
                        field: "departmentId",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                  className={"placeholderBlack"}
                  dropdownStyle={{ color: "black" }}
                  style={{ width: 150 }}
                  bordered={false}
                  placeholder={t("employees.fields.department")}
                  filterOption={(inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label);
                  }}
                />
              </Form.Item>
              <Form.Item name={["titleId"]} style={{ marginBottom: 16 }}>
                <Select
                  {...titleSelectProps}
                  allowClear
                  onChange={(e) => {
                    setFilters([
                      {
                        field: "titleId",
                        operator: "eq",
                        value: e,
                      },
                    ]);
                  }}
                  className={"placeholderBlack"}
                  dropdownStyle={{ color: "black" }}
                  style={{ width: 150 }}
                  bordered={false}
                  placeholder={t("employees.fields.title")}
                  filterOption={(inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label);
                  }}
                />
              </Form.Item>
            </Space>

            {filters &&
              filters.length > 0 &&
              !(
                filters.length === 1 && filters[0]?.operator === "containss"
              ) && (
                <Button
                  type="text"
                  onClick={(e) => {
                    form.resetFields();
                    setFilters([], "replace");
                  }}
                  style={{ color: "#8C8C8C", marginBottom: 16 }}
                >
                  {t("common.cleanFilter")}
                  <Icons.CloseOutlined />
                </Button>
              )}
          </Space>
          <Form.Item name={["status"]} style={{ marginBottom: 16 }}>
            <Radio.Group
              defaultValue={0}
              buttonStyle="solid"
              onChange={(e) => {
                setCurrent(1);
                setFilters([
                  {
                    field: "status",
                    operator: "eq",
                    value: e?.target?.value === -1 ? "" : e?.target?.value,
                  },
                ]);
              }}
              style={{ display: "block", marginBottom: 16 }}
            >
              <Radio.Button value={-1}>{t("common.all")}</Radio.Button>
              {EMPLOYEE_STATUS.map((o) => {
                return (
                  <Radio.Button value={o.value}>{t(o.label)}</Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Form>
        <Table
          dataSource={dataTable?.data}
          rowKey="id"
          locale={{
            emptyText: (
              <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
            ),
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                show(PATH.employees, record.id);
              },
            };
          }}
          pagination={{
            pageSize: pageSize,
            current: current,
            total: dataTable?.total,
            onChange: (cur, size) => {
              setCurrent(cur);
              setPageSize(size);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            },
            locale: {
              items_per_page: t("common.pageNumber"),
            },
            showTotal: (total) => (
              <p>{t("common.totalPage", { total: total })}</p>
            ),
          }}
          onChange={(_, __, s) => {
            const newSort = s as any;
            setSorter([
              {
                field:
                  newSort?.field === "department"
                    ? "department.name"
                    : newSort?.field === "title"
                    ? "title.name"
                    : newSort?.field,
                order: newSort?.order,
              },
            ]);
          }}
          scroll={{ x: "2000px" }}
          showSorterTooltip={false}
        >
          <Table.Column
            key="id"
            dataIndex="id"
            title={t("common.no")}
            render={(_, __, index) => (current - 1) * pageSize + index + 1}
            width={70}
          />
          <Table.Column
            key="code"
            dataIndex="code"
            title={t("employees.fields.code")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("code", sorter)}
            sorter
          />
          <Table.Column
            key="fullName"
            dataIndex="fullName"
            title={t("employees.fields.name")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("fullName", sorter)}
            sorter
          />
          <Table.Column
            key="department"
            dataIndex="department"
            title={t("employees.fields.department")}
            render={(value) => <TextField value={value?.name} />}
            defaultSortOrder={getDefaultSortOrder("department", sorter)}
            sorter
          />
          <Table.Column
            key="title"
            dataIndex="title"
            title={t("employees.fields.title")}
            render={(value) => <TextField value={value?.name} />}
            defaultSortOrder={getDefaultSortOrder("title", sorter)}
            sorter
          />
          <Table.Column
            key="status"
            dataIndex="status"
            title={t("employees.fields.status.title")}
            render={(value) => renderStatus(value)}
          />
          <Table.Column
            key="emailUser"
            dataIndex="emailUser"
            title={t("employees.fields.userTenantId")}
            render={(value) => <TextField className="primary" value={value} />}
            width={220}
          />
          <Table.Column
            key="updatedName"
            dataIndex="updatedName"
            title={t("employees.fields.updatedName")}
            render={(value) => <TextField className="primary" value={value} />}
            width={220}
          />
          <Table.Column
            key="updatedDate"
            dataIndex="updatedDate"
            title={t("employees.fields.updatedDate")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(
                  value as string,
                  "DD/MM/YYYY HH:mm:ss"
                )}
              />
            )}
            width={200}
            defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
            sorter
          />
          <Table.Column
            key="createdName"
            dataIndex="createdName"
            title={t("employees.fields.createdName")}
            render={(value) => <TextField className="primary" value={value} />}
            width={220}
          />
          <Table.Column
            key="createdDate"
            dataIndex="createdDate"
            title={t("employees.fields.createdDate")}
            render={(value) => (
              <TextField
                value={formatISODateTimeToView(
                  value as string,
                  "DD/MM/YYYY HH:mm:ss"
                )}
              />
            )}
            width={200}
            defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
            sorter
          />
          <Table.Column<IEmployee>
            title={t("table.actions")}
            dataIndex="actions"
            align="center"
            fixed="right"
            width={120}
            render={(_, record) => (
              <Space>
                <ButtonTooltip
                  type="text"
                  icon={<Icons.EditOutlined />}
                  className="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    edit(PATH.employees, record.id);
                  }}
                  {...checkEditHaveHelperText(PATH.employees)}
                />
                <div className="line-between-action"></div>
                <ButtonTooltip
                  type="text"
                  icon={<Icons.SettingOutlined />}
                  className="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      `${PATH.userTenantMisRoles}/edit/${record.id}?code=${record.code}`
                    );
                  }}
                  {...checkEditHaveHelperText(PATH.userTenantMisRoles)}
                />
              </Space>
            )}
          />
        </Table>
      </div>
    </List>
  );
};
