import { Col, Form, Input, Row, Typography } from "@pankod/refine-antd";
import { Option, useOne, useTranslate } from "@pankod/refine-core";
import { SelectCustom } from "components";
import { API_PATH } from "configs/path";
import { FC, memo, useCallback, useContext, useEffect, useState } from "react";
import { ContractContext, IForm } from "../../..";
import {
  ContractStatus,
  ContractStatusCode,
  PartySide,
  PartyType,
} from "interfaces/Contract";
import { searchSelect } from "utils/commons";

import lodash from "lodash";
import { useParams } from "@pankod/refine-react-router-v6";

const { Title } = Typography;

const { useWatch, useFormInstance } = Form;

const General: FC = memo(() => {
  const {
    isEdit,
    initialValue,
    isHasContractCode,
    contractTypeConstraintState,
  } = useContext(ContractContext);

  const [contractTypeConstraint, setContractTypeConstraint] =
    contractTypeConstraintState;

  const translate = useTranslate();
  const form = useFormInstance<IForm>();

  const userParty = useWatch("userParty", form);
  const serviceProviderParty = useWatch("serviceProviderParty", form);
  const thirdParty = useWatch("thirdParty", form);
  const contractType = useWatch("contractType", form);
  const originalContractId = useWatch("originalContractId", form);
  const { id } = useParams();
  const currentId = id ?? "";

  const [originalId, setOriginalId] = useState("");

  const defaultParty = useOne<any>({
    resource: API_PATH.contractPartyDefault,
    id: "",
    queryOptions: { enabled: !!originalId && !!contractType },
    metaData: {
      after: `?originalContractId=${originalId ?? ""}&contractType=${
        contractType ?? ""
      }`,
    },
  });

  useEffect(() => {
    var defaultPartyData = defaultParty?.data?.data;
    if (!defaultPartyData) return;
    if (defaultPartyData.userParty?.partyId) {
      form.setFieldsValue({
        userParty: {
          partyId: defaultPartyData.userParty?.partyId,
          partyType: defaultPartyData.userParty?.partyType,
          id: userParty?.id,
        },
      });
    }
    if (defaultPartyData.serviceProviderParty?.partyId) {
      form.setFieldsValue({
        serviceProviderParty: {
          partyId: defaultPartyData.serviceProviderParty?.partyId,
          partyType: defaultPartyData.serviceProviderParty?.partyType,
          id: serviceProviderParty?.id,
        },
      });
    }
    if (defaultPartyData.thirdParty?.partyId) {
      form.setFieldsValue({
        thirdParty: {
          partyId: defaultPartyData.thirdParty?.partyId,
          partyType: defaultPartyData.thirdParty?.partyType,
          id: thirdParty?.id,
        },
      });
    }
  }, [
    defaultParty?.data?.data,
    form,
    userParty?.id,
    serviceProviderParty?.id,
    thirdParty?.id,
  ]);

  const onChangeParty = (
    _: any,
    __: any,
    option: any,
    partySide: PartySide
  ) => {
    if (partySide === PartySide.User) {
      form.setFieldsValue({
        userParty: {
          partyId: option?.partyId,
          partyType: option?.partyType,
          id: userParty?.id,
        },
      });
    } else if (partySide === PartySide.ServiceProvider) {
      form.setFieldsValue({
        serviceProviderParty: {
          partyId: option?.partyId,
          partyType: option?.partyType,
          id: serviceProviderParty?.id,
        },
      });
    } else if (partySide === PartySide.Third) {
      form.setFieldsValue({
        thirdParty: {
          partyId: option?.partyId,
          partyType: option?.partyType,
          id: thirdParty?.id,
        },
      });
    }
  };

  const onChangeContractType = (_: any, __: any, option: any) => {
    form.setFieldsValue({ contractTypeCode: option?.code });
    setContractTypeConstraint({
      requireThirdParty: option?.requireThirdParty,
      requireOriginal: option?.requireOriginal,
      requireProduct: option?.requireProduct,
      userPartyEditable: option?.userPartyEditable,
      providerPartyEditable: option?.providerPartyEditable,
    });
  };

  const onChangeOriginal = (_: any, __: any, option: any) => {
    setOriginalId(option?.id);
  };

  const contractStatusOption = useCallback(
    (options: any[], __: any) => {
      let datas: ContractStatus[] = options;
      if (initialValue?.contractStatus?.code !== ContractStatusCode.Draft) {
        datas = options.filter(
          (op: ContractStatus) => op.code !== ContractStatusCode.Draft
        );
      }

      return datas?.map((d) => ({ ...d, label: d.name, value: d.id }));
    },
    [initialValue?.contractStatus?.code]
  );

  const onChageContractStatus = (_: any, __: any, selectedItem: any) =>
    form.setFieldsValue({ contractStatusCode: selectedItem?.code });

  var userPartyOptions: Option[];
  var providerPartyOptions: Option[];

  const onSetDefaultParty = (
    partySide: PartySide,
    selected?: string,
    options?: Option[]
  ) => {
    if (partySide === PartySide.User) {
      if (lodash.isEqual(options, userPartyOptions)) {
        return;
      }
      userPartyOptions = options ?? [];
      const crrOption = options?.filter((x: any) => !x?.disabled);

      if (
        !!selected &&
        selected === options?.filter((x: any) => x?.disabled)[0]?.value &&
        !!crrOption &&
        crrOption.length >= 1
      ) {
        form.setFieldsValue({
          userParty: {
            partyId: undefined,
            partyType: undefined,
            id: userParty?.id,
          },
        });
      }

      if (!contractTypeConstraint.userPartyEditable) {
        if (!!crrOption && crrOption.length === 1) {
          form.setFieldsValue({
            userParty: {
              partyId: crrOption[0]?.value,
              partyType: PartyType.Tenant,
              id: userParty?.id,
            },
          });
        }
      }
    }
    if (partySide === PartySide.ServiceProvider) {
      if (lodash.isEqual(options, providerPartyOptions)) {
        return;
      }
      providerPartyOptions = options ?? [];
      const crrOption = options?.filter((x: any) => !x?.disabled);

      if (
        !!selected &&
        selected === options?.filter((x: any) => x?.disabled)[0]?.value &&
        !!crrOption &&
        crrOption.length >= 1
      ) {
        form.setFieldsValue({
          serviceProviderParty: {
            partyId: undefined,
            partyType: undefined,
            id: serviceProviderParty?.id,
          },
        });
      }

      if (!contractTypeConstraint.providerPartyEditable) {
        if (!!crrOption && crrOption.length === 1) {
          form.setFieldsValue({
            serviceProviderParty: {
              partyId: crrOption[0]?.value,
              partyType: PartyType.Tenant,
              id: serviceProviderParty?.id,
            },
          });
        }
      }
    }
  };

  const onOriginalContractLoaded = (options: any) => {
    if (!options?.filter((x: any) => x.value === originalContractId)) {
      form.setFieldsValue({
        originalContractId: undefined,
      });
    }
  };

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Thông tin tổng quan")}</Title>
      <Row gutter={16}>
        <Col lg={16} xs={24}>
          <Form.Item
            name="name"
            label="Tên hợp đồng"
            rules={[
              { required: true, message: translate("errors.ER005") },
              { max: 200, message: translate("errors.ER014", { max: 200 }) },
            ]}
          >
            <Input placeholder="Nhập tên hợp đồng" />
          </Form.Item>
        </Col>
        <Col lg={8} xs={24} />

        <Col lg={8} xs={24}>
          <Form.Item name="contractTypeCode" hidden className="m-0" />
          <Form.Item
            name="contractType"
            label="Loại hợp đồng"
            rules={[{ required: true, message: translate("errors.ER005") }]}
          >
            <SelectCustom
              resource={API_PATH.contractTypeDropdownlist}
              optionLabel="name"
              optionValue="id"
              placeholder={translate("Chọn loại hợp đồng")}
              style={{ color: "#000" }}
              allowClear
              onChangeOption={onChangeContractType}
              disabled={
                isEdit &&
                initialValue?.contractStatus?.code !== ContractStatusCode.Draft
              }
            />
          </Form.Item>
        </Col>

        <Col lg={8} xs={24}>
          <Form.Item
            name="originalContractId"
            label="Hợp đồng gốc"
            rules={[
              {
                required: contractTypeConstraint.requireOriginal,
                message: translate("errors.ER005"),
              },
            ]}
          >
            <SelectCustom
              resource={
                API_PATH.contractsOriginalDropdownlist +
                `?ContractType=${contractType}&pageSize=1000&CurrentId=${currentId}`
              }
              onLoaded={(options: any) => onOriginalContractLoaded(options)}
              optionLabel="label"
              optionValue="id"
              placeholder={translate("Hợp đồng gốc")}
              style={{ color: "#000" }}
              allowClear
              onChangeOption={onChangeOriginal}
              useServerFiltering
              filterOption={(inputValue: string, option: any) => {
                return option && searchSelect(inputValue, option?.label);
              }}
              enabledFetching={!!contractType}
              formatData={(r: any) => ({
                ...r,
                label: `${r.code ?? ""} - ${r.name}`,
              })}
            />
          </Form.Item>
        </Col>

        {isEdit ? (
          <Col lg={8} xs={24}>
            <Form.Item hidden name="contractStatusCode" />
            <Form.Item
              name="contractStatus"
              label="Trạng thái hợp đồng"
              rules={[{ required: true, message: translate("errors.ER005") }]}
            >
              <SelectCustom
                disabled={
                  initialValue?.contractStatus?.code ===
                  ContractStatusCode.Signed
                }
                resource={API_PATH.contractStatusDropdownlist}
                optionLabel="name"
                optionValue="id"
                placeholder={translate("Chọn trạng thái hợp đồng")}
                style={{ color: "#000" }}
                allowClear
                customOptions={contractStatusOption}
                onChangeOption={onChageContractStatus}
              />
            </Form.Item>
          </Col>
        ) : (
          <Col lg={8} xs={24} />
        )}

        <Col lg={8} xs={24}>
          <Form.Item name={["userParty", "partyType"]} hidden className="m-0" />
          <Form.Item name={["userParty", "id"]} hidden className="m-0" />
          <Form.Item
            name={["userParty", "partyId"]}
            label={translate("Bên mua/Bên A/Khác")}
            rules={[{ required: true, message: translate("errors.ER005") }]}
          >
            <SelectCustom
              resource={
                API_PATH.contractsUserPartyDropdownlist +
                `?ContractType=${contractType}&pageSize=1000`
              }
              optionLabel="name"
              optionValue="partyId"
              placeholder={translate("Chọn Bên mua/Bên A/Khác")}
              style={{ color: "#000" }}
              allowClear
              onChangeOption={(p1: any, p2: any, p3: any) =>
                onChangeParty(p1, p2, p3, PartySide.User)
              }
              disabled={
                isHasContractCode || !contractTypeConstraint.userPartyEditable
              }
              filterOption={(inputValue: string, option: any) => {
                return option && searchSelect(inputValue, option?.label);
              }}
              useServerFiltering
              enabledFetching={!!contractType}
              customOptions={(_: any, selected?: string, option?: Option[]) => {
                onSetDefaultParty(PartySide.User, selected, option);
                return option ?? [];
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={8} xs={24}>
          <Form.Item
            name={["serviceProviderParty", "partyType"]}
            hidden
            className="m-0"
          />
          <Form.Item
            name={["serviceProviderParty", "id"]}
            hidden
            className="m-0"
          />
          <Form.Item
            name={["serviceProviderParty", "partyId"]}
            label={translate("Bên bán/Bên B/Khác")}
            rules={[{ required: true, message: translate("errors.ER005") }]}
          >
            <SelectCustom
              resource={
                API_PATH.contractsProviderPartyDropdownlist +
                `?ContractType=${contractType}&pageSize=1000`
              }
              optionLabel="name"
              optionValue="partyId"
              placeholder={translate("Chọn Bên bán/Bên B/Khác")}
              style={{ color: "#000" }}
              allowClear
              onChangeOption={(p1: any, p2: any, p3: any) =>
                onChangeParty(p1, p2, p3, PartySide.ServiceProvider)
              }
              disabled={
                isHasContractCode ||
                !contractTypeConstraint.providerPartyEditable
              }
              filterOption={(inputValue: string, option: any) => {
                return option && searchSelect(inputValue, option?.label);
              }}
              useServerFiltering
              enabledFetching={!!contractType}
              customOptions={(_: any, selected?: string, option?: Option[]) => {
                onSetDefaultParty(PartySide.ServiceProvider, selected, option);
                return option ?? [];
              }}
            />
          </Form.Item>
        </Col>
        {contractTypeConstraint.requireThirdParty ? (
          <Col lg={8} xs={24}>
            <Form.Item
              name={["thirdParty", "partyType"]}
              hidden
              className="m-0"
            />
            <Form.Item name={["thirdParty", "id"]} hidden className="m-0" />
            <Form.Item
              name={["thirdParty", "partyId"]}
              label={translate("Bên thứ 3")}
              rules={[{ required: true, message: translate("errors.ER005") }]}
            >
              <SelectCustom
                resource={
                  API_PATH.contractsThirdPartyDropdownlist +
                  `?ContractType=${contractType}&pageSize=1000`
                }
                optionLabel="name"
                optionValue="partyId"
                placeholder={translate("Chọn bên thứ ba")}
                style={{ color: "#000" }}
                allowClear
                onChangeOption={(p1: any, p2: any, p3: any) =>
                  onChangeParty(p1, p2, p3, PartySide.Third)
                }
                disabled={isHasContractCode}
                filterOption={(inputValue: string, option: any) => {
                  return option && searchSelect(inputValue, option?.label);
                }}
                useServerFiltering
                enabledFetching={!!contractType}
              />
            </Form.Item>
          </Col>
        ) : (
          <Col lg={8} xs={24} />
        )}

        <Col lg={8} xs={24}>
          <Form.Item name="code" label="Số hợp đồng">
            <Input style={{ color: "black" }} disabled />
          </Form.Item>
        </Col>
        <Col lg={8} xs={24}>
          <Form.Item
            name="customerContractCode"
            label="Số hợp đồng của khách hàng"
            rules={[
              { max: 200, message: translate("errors.ER014", { max: 200 }) },
            ]}
          >
            <Input placeholder="Nhập số hợp đồng của khách hàng" />
          </Form.Item>
        </Col>
        <Col lg={8} xs={24} />
      </Row>
    </div>
  );
});

export default General;
