import { Icons, useForm } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { Typography, notification } from "antd";
import { CreateCustom } from "components/layout";
import { PATH } from "configs/path";
import { FC, createContext, memo, useState } from "react";
import { useParams } from "react-router-dom";
import { formatOnlyDateToUTC, mappingErrorFromApi } from "utils/commons";
import ProjectForm from "./components/Form";

const { Text } = Typography;

interface ContextState {
  initialValues?: any;
  isEdit?: boolean;
}
export const ProjectContext = createContext<ContextState>({
  initialValues: {},
  isEdit: false,
});

export interface IForm {
  caseId: string;
  startDate: Date;
  endDate: Date;
  contactCustomerName: string;
  contactCustomerPhone: string;
  contactCustomerEmail: string;
  listProjectMemberTypeConsulting: {
    [key: string]: {
      employeeId: string;
      projectRoleMasterDataId: string;
    };
  };
  listProjectMemberTypeImplementation: {
    [key: string]: {
      employeeId: string;
      projectRoleMasterDataId: string;
    };
  };
  status: string;
}

const ProjectCreate: FC<IResourceComponentsProps> = memo((props) => {
  const { form, formProps, saveButtonProps, formLoading } = useForm<
    any,
    any,
    IForm
  >();
  const { mutate: mutateCreate, isLoading } = useCreate<{ id: string }, any>();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();
  const { show } = useNavigation();
  const translate = useTranslate();
  const { id } = useParams();
  const [visibleModalConfirmCreate, setVisibleShowModalConfirm] =
    useState(false);

  const initialValues = formProps?.initialValues;

  const isEdit = !!id;

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleShowModalConfirm(isOpen);

  const onSubmit = () => {
    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: translate(
            isEdit ? "Cập nhật thành công" : "Tạo mới thành công"
          ),
        });
        show(PATH.projects, res?.data?.id);
      },
      onError: (error: any) => {
        toggleModalConfirm(false);

        notification.error({
          message: translate(isEdit ? "Cập nhật thất bại" : "Tạo mới thất bại"),
          description:
            !!error?.errors.length &&
            translate(
              `errors.${error?.errors?.[0]?.errorCode}`,
              error?.errors?.[0]?.value
            ),
        });
        const errorFieldMapping: any = {};

        mappingErrorFromApi(error, form, errorFieldMapping);
      },
    };

    const dataForm = form.getFieldsValue();

    const listProjectMemberTypeConsulting = Object.values(
      dataForm?.listProjectMemberTypeConsulting || {}
    ).map((c) => ({
      employeeId: c.employeeId,
      projectRoleMasterDataId: c.projectRoleMasterDataId,
    }));
    const listProjectMemberTypeImplementation = Object.values(
      dataForm?.listProjectMemberTypeImplementation || {}
    ).map((c) => ({
      employeeId: c.employeeId,
      projectRoleMasterDataId: c.projectRoleMasterDataId,
    }));

    const dataToRequest = {
      resource: PATH.projects,

      values: {
        ...dataForm,
        contactCustomerName: dataForm?.contactCustomerName?.trim() || null,
        contactCustomerEmail: dataForm?.contactCustomerEmail?.trim() || null,
        contactCustomerPhone: dataForm?.contactCustomerPhone?.trim() || null,
        projectStatusId: dataForm?.status,
        endDate: formatOnlyDateToUTC(dataForm?.endDate),
        startDate: formatOnlyDateToUTC(dataForm?.startDate),
        listProjectMemberTypeConsulting,
        listProjectMemberTypeImplementation,
      },
      id: formProps.initialValues?.id || "",
    };

    if (isEdit) {
      mutateUpdate(dataToRequest, handleResponse);
    } else {
      mutateCreate(dataToRequest, handleResponse);
    }
  };

  const onFinishForm = () => {
    const dataForm = form.getFieldsValue();
    if (
      !dataForm?.listProjectMemberTypeConsulting &&
      !dataForm?.listProjectMemberTypeImplementation
    ) {
      notification.error({ message: translate("errors.ER0184") });
      return;
    }
    setVisibleShowModalConfirm(true);
  };

  return (
    <ProjectContext.Provider value={{ initialValues, isEdit: isEdit }}>
      <CreateCustom
        {...props}
        title={
          isEdit ? (
            <Text style={{ marginBottom: 0 }}>
              {translate("Chỉnh sửa dự án")}:{" "}
              <Text className="primary">{initialValues?.case?.name}</Text>
            </Text>
          ) : (
            translate("Tạo mới dự án")
          )
        }
        onSubmit={onSubmit}
        visibleShowModal={visibleModalConfirmCreate}
        setVisibleShowModal={toggleModalConfirm}
        saving={isLoading || isLoadingUpdate}
        confirmModalProps={{
          title: translate(
            isEdit
              ? "assignExploiting.editConfirm"
              : "assignExploiting.createConfirm"
          ),
        }}
        saveButtonProps={{
          ...saveButtonProps,
          title: translate(isEdit ? "common.saveEdit" : "actions.create"),
          icon: isEdit && <Icons.SaveOutlined />,
        }}
        isLoading={formLoading}
        bodyStyle={{ background: "transparent", padding: 0 }}
        contentStyles={{ background: "transparent" }}
      >
        <ProjectForm
          form={form}
          formProps={formProps}
          onFinishedFormValidate={onFinishForm}
        />
      </CreateCustom>
    </ProjectContext.Provider>
  );
});

export default ProjectCreate;
