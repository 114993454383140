import { Card, Descriptions, Table } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { FC, memo, useContext, useMemo } from "react";
import type { ColumnsType } from "antd/es/table";
import { BusinessPlanOverViewProduct } from "interfaces/BusinessPlan";
import { ConfigDisplayText, DataText } from "components";
import { Currency } from "components/Currency";
import { BusinessPlanShowContext } from "../..";

const ProductList: FC = memo(() => {
  const translate = useTranslate();
  const { record } = useContext(BusinessPlanShowContext);
  const { businessPlanOverViewProducts } = record?.businessPlanOverView || {};

  const sumCost = useMemo(
    () =>
      businessPlanOverViewProducts?.reduce(
        (total, next) => total + (next?.totalCost || 0),
        0
      ),
    [businessPlanOverViewProducts]
  );

  const sumPrice = useMemo(
    () =>
      businessPlanOverViewProducts?.reduce(
        (total, next) => total + (next?.totalPrice || 0),
        0
      ),
    [businessPlanOverViewProducts]
  );

  const sumMargin = useMemo(
    () =>
      businessPlanOverViewProducts?.reduce(
        (total, next) => total + (next?.totalMargin || 0),
        0
      ) || 0,
    [businessPlanOverViewProducts]
  );

  const columns = useMemo<ColumnsType<BusinessPlanOverViewProduct>>(
    () => [
      {
        title: translate("common.no"),
        width: 70,
        dataIndex: "id",
        key: "id",
        render: (_, record, index) => index + 1,
      },
      {
        title: translate("Tên sản phẩm"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => (
          <ConfigDisplayText
            value={`${record?.product?.code} - ${record?.product?.name}`}
            hidden={record?.isSum}
          />
        ),
      },
      {
        title: translate("products.fields.partNumber"),
        width: 200,
        dataIndex: "partNumber",
        key: "partNumber",
        render: (_, record, index) => <DataText value={record?.partNumber} />,
      },
      {
        title: translate("common.description"),
        width: 200,
        dataIndex: "partNumber",
        key: "partNumber",
        render: (_, record, index) => <DataText value={record?.description} />,
      },
      {
        title: translate("common.quantity"),
        width: 100,
        dataIndex: "quantity",
        key: "quantity",
        render: (_, record, index) => (
          <Currency showCurrency={false} value={record?.quantity} />
        ),
      },
      {
        title: translate("products.fields.unitShort"),
        width: 100,
        dataIndex: "unit",
        key: "unit",
        render: (_, record, index) => (
          <ConfigDisplayText value={record?.unit?.name} />
        ),
      },
      {
        title: translate("products.fields.unitPriceSell"),
        width: 200,
        dataIndex: "unitPrice",
        key: "unitPrice",
        render: (_, record, index) => (
          <Currency showCurrency={false} value={record?.unitPrice} />
        ),
      },
      {
        title: translate("products.fields.unitPriceCapital"),
        width: 200,
        dataIndex: "unitCost",
        key: "unitCost",
        render: (_, record, index) => (
          <Currency showCurrency={false} value={record?.unitCost} />
        ),
      },
      {
        title: translate("Giá bán"),
        width: 200,
        dataIndex: "totalPrice",
        key: "totalPrice",
        render: (_, record, index) => (
          <Currency showCurrency={false} value={record?.totalPrice} />
        ),
      },

      {
        title: translate("Giá vốn"),
        width: 200,
        dataIndex: "totalCost",
        key: "totalCost",
        render: (_, record, index) => (
          <Currency showCurrency={false} value={record?.totalCost} />
        ),
      },

      {
        title: translate("Margin"),
        width: 200,
        dataIndex: "totalMargin",
        key: "totalMargin",
        render: (_, record, index) => (
          <Currency
            showCurrency={false}
            value={record?.totalMargin}
            className={(record?.totalMargin || 0) >= 0 ? "green" : "red"}
          />
        ),
      },
    ],
    []
  );

  return (
    <Card style={{ backgroundColor: "#ffffff" }}>
      <Descriptions
        column={1}
        layout="vertical"
        title={translate("Danh sách sản phẩm")}
      />
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        bordered
        dataSource={businessPlanOverViewProducts}
        scroll={{ x: 2500 }}
        style={{ paddingLeft: 16 }}
      />
      <Descriptions
        column={{ xs: 1, lg: 3 }}
        layout="vertical"
        style={{ marginTop: 8 }}
      >
        <Descriptions.Item
          label={translate("Tổng giá bán")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={sumPrice} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Tổng giá vốn")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={sumCost} />
        </Descriptions.Item>

        <Descriptions.Item
          label={translate("Tổng margin")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency
            className={sumMargin >= 0 ? "green" : "red"}
            showCurrency={false}
            value={sumMargin}
          />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
});

export default ProductList;
