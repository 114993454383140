import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useShow, useNavigation
} from "@pankod/refine-core";
import { Typography, Table, Icons, Descriptions } from "@pankod/refine-antd";
import { ShowCustom } from "components/layout";
import usePermissions from 'hooks/permission'
import { PATH } from "configs/path";
import { LinkText, ButtonTooltip, DataText } from "components";

const { Title, Text } = Typography;

export const DepartmentShow: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { edit } = useNavigation()
  const { checkEditHaveHelperText } = usePermissions()

  const { queryResult } = useShow<any>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <ShowCustom
      {...props}
      isLoading={isLoading}
      title={<Text style={{marginBottom: 0}}>{t("departments.department")}: <span className="primary">{record?.name}</span> </Text>}
      breadcrumbText={record?.name}
      headerButtons={
        <ButtonTooltip
          type="primary"
          icon={<Icons.EditOutlined/>}
          onClick={() => edit(PATH.departments, record.id)}
          {...checkEditHaveHelperText(PATH.departments)}
        >
          {t('buttons.edit')}
        </ButtonTooltip>
      }
    >
      <Descriptions
        column={3}
        title={t("departments.departmentInfo")}
        layout="vertical"
      >
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("cases.cases")}
        >
          <LinkText
            value={record?.case}
            resource={PATH.cases}
            showFullInfo
            showTooltip={false}
          />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("departments.fields.code")}
        >
          <DataText value={record?.code} />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("departments.fields.name")}
        >
          <DataText value={record?.name} />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          span={3}
          label={t("departments.fields.parentDepartment")}
        >
          <DataText value={record?.parentDepartment?.name} />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          span={3}
          label={t("departments.fields.description")}
        >
          <DataText value={record?.description} className="line-break"/>
        </Descriptions.Item>
      </Descriptions>
    </ShowCustom>
  );
};
