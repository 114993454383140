import { FC, memo } from "react";
import CaseInfo from "./CaseInfo";
import General from "./General";
import ProductList from "./ProductList";

const BusinessPlanInformation: FC = memo((props) => {
  return (
    <>
      <CaseInfo />
      <General />
      <ProductList />
    </>
  );
});

export default BusinessPlanInformation;
