import { Form, notification, useForm } from "@pankod/refine-antd";
import {
  GetOneResponse,
  IResourceComponentsProps,
  QueryObserverResult,
  useCreate,
  useNavigation,
  useOne,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { CreateCustom } from "components/layout";
import {
  ContractEstimateData,
  TPlanItem,
  TabKey,
} from "interfaces/ContractEstimate";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import Information from "./components/Information";
import EstimateInfo from "./components/EstimateInfo";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import { API_PATH, PATH } from "configs/path";
import { ApprovalStatus } from "api/enums";
import { v4 as uuid } from "uuid";
import { useParams } from "@pankod/refine-react-router-v6";
import { cloneDeep } from "lodash";
import { formatNumber, isEmpty, mappingErrorFromApi } from "utils/commons";
import { ONE_THOUSAND_TRILLION } from "configs/constants";
import { getContractEstimatedFinacialIndicator, getProductsDefault } from "api";
import ButtonConfirm from "components/ButtonConfirm";
import { ObjectSubmitCondition } from "interfaces";

export type ContractEstimatedProducts = {
  productName?: string;
  productCode?: string;
  productId: string;
  partNumber: string;
  description: string;
  quantity: number;
  unitCost: number;
  unitPrice: number;
  unitId: string;
  unitName: string;
};

export interface IForm {
  caseId: string;
  note: string;
  contractEstimatedProducts: {
    [key: string]: ContractEstimatedProducts;
  };
  contractEstimatedDocuments: { fileId: string }[];
}

interface State {
  canEditCaseId: boolean;
  initialValues: Partial<ContractEstimateData>;
  caseData: any;
  isEdit: boolean;
  planState: [TPlanItem[], Dispatch<SetStateAction<TPlanItem[]>>];
  isLoadingFetchFinancial: boolean;
  productState: [string[], Dispatch<SetStateAction<string[]>>];
  sumCost: number;
  sumPrice: number;
  stateModalFetchProduct: [boolean, Dispatch<SetStateAction<boolean>>];
  onFetchFiancialIndicator: (data: {
    caseId: string;
    products: { [key: string]: ContractEstimatedProducts };
    plansRequest: TPlanItem[];
  }) => void;
  getEstimateValueError: (data: TPlanItem) => string;
  onChangeCase: (id: string) => void;
  onFetchProducts: () => void;
  submitConditionFetch?: QueryObserverResult<
    GetOneResponse<ObjectSubmitCondition[]>,
    unknown
  >;
}

const { useWatch } = Form;

const Container = styled.div<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? "node" : "inherit")};
`;

let timeoutFetchTableCalc: any;

export const ContractEstimatedFormContext = createContext<State>({
  initialValues: {},
  canEditCaseId: false,
  isEdit: false,
  caseData: {},
  planState: [[], () => []],
  isLoadingFetchFinancial: false,
  productState: [[], () => {}],
  sumCost: 0,
  sumPrice: 0,
  stateModalFetchProduct: [false, () => {}],
  onFetchFiancialIndicator: () => {},
  getEstimateValueError: () => "",
  onChangeCase: () => {},
  onFetchProducts: () => {},
});

const ContractEstimationCreate: FC<IResourceComponentsProps> = memo((props) => {
  const translate = useTranslate();
  const { id } = useParams();
  const productState = useState<string[]>([uuid()]);
  const [, setProducts] = productState;
  const stateModalFetchProduct = useState(false);
  const [, setModalFetchProduct] = stateModalFetchProduct;
  const { show } = useNavigation();
  const [visibleModalWarningDataChange, setVisibleModalWarningDataChange] =
    useState(false);

  const [visibleModalConditionInvalid, setVisibleModalConditionInvalid] =
    useState(false);

  const [isLoadingFetchFinancial, setIsLoadingFetchFinancial] = useState(false);

  const { form, formProps, saveButtonProps, formLoading } = useForm<
    any,
    any,
    IForm
  >({
    metaData: { isConverting: true },
  });

  const initialValues = formProps?.initialValues as ContractEstimateData;

  const planState = useState<TPlanItem[]>([]);
  const [plans, setPlan] = planState;

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreate<
    { id: string },
    any
  >();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();

  const toggleModalWarningDataChange = () =>
    setVisibleModalWarningDataChange((prev) => !prev);

  const toggleModalConditionInvalid = () =>
    setVisibleModalConditionInvalid((prev) => !prev);

  const contractEstimatedProducts = useWatch("contractEstimatedProducts", form);

  const caseId = useWatch("caseId", form);

  const [activeTab, setActiveTab] = useState(TabKey.INFO);

  const isEdit = !!id;

  const [visibleModalConfirmCreate, setVisibleShowModalConfirm] =
    useState(false);

  const headerTab = useMemo(
    () => [
      {
        name: translate("Thông tin tổng quan"),
        key: TabKey.INFO,
      },
      {
        name: translate("Chi tiết dự toán hợp đồng"),
        key: TabKey.ESTIMATE_INFO,
      },
    ],
    []
  );

  const onChangeTab = (tabKey: TabKey) => {
    setActiveTab(tabKey);
    window.scrollTo(0, 0);
  };

  const mappedDataToCalc = (data: {
    caseId: string;
    products: ContractEstimatedProducts[];
    plansRequest: any[];
  }) => {
    const { products, plansRequest, caseId } = data;
    const productsRequest = products?.map(
      ({ productId, unitCost, unitPrice, quantity }) => ({
        productId: productId,
        quantity: quantity || null,
        unitPrice: unitPrice || null,
        unitCost: unitCost || null,
      })
    );
    const indicators = plansRequest.map((d) => ({
      code: d.code,
      value: d.value,
    }));
    return { caseId, productsRequest, indicators };
  };

  const canEditCaseId =
    Number(formProps?.initialValues?.status) === ApprovalStatus.Draft ||
    !isEdit;

  useOne({
    resource: API_PATH.financialIndicatorMaterData,
    id: "",
    successNotification(data: any) {
      setPlan(data?.data);
      return {
        message: `Successfully fetched.`,
        description: "Success with no errors",
        type: "success",
      };
    },
    queryOptions: {
      enabled: !isEdit,
    },
  });

  const submitConditionFetch = useOne<ObjectSubmitCondition[]>({
    resource: API_PATH.contractEstimatedsSubmitCondition,
    id: "",
    metaData: {
      after: caseId ? `?caseId=${caseId}` : "",
    },
  });

  const sumPrice = useMemo(() => {
    if (!contractEstimatedProducts) return 0;
    return Object.values(contractEstimatedProducts)?.reduce(
      (total, next) => total + (next?.quantity || 0) * (next?.unitPrice || 0),
      0
    );
  }, [contractEstimatedProducts]);

  const sumCost = useMemo(() => {
    if (!contractEstimatedProducts) return 0;
    return Object.values(contractEstimatedProducts)?.reduce(
      (total, next) => total + (next?.quantity || 0) * (next?.unitCost || 0),
      0
    );
  }, [contractEstimatedProducts]);

  const getEstimateValueError = (data: TPlanItem) => {
    const { isRequired, value, isPersonInput } = data;
    if (isRequired && isEmpty(value) && isPersonInput)
      return translate("errors.ER005");
    if (
      isPersonInput &&
      !isEmpty(value) &&
      (value > ONE_THOUSAND_TRILLION || value < 1)
    ) {
      return translate(`errors.ER091`, {
        min: 1,
        max: formatNumber(ONE_THOUSAND_TRILLION),
      });
    }

    return "";
  };

  const validationDataForm = () => {
    const errors = plans.find((plan) => getEstimateValueError(plan));
    const errorFromServer = plans.filter(
      (plan) => plan.errorCode || plan.errorMessage
    );

    return !errorFromServer?.length && !errors;
  };

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleShowModalConfirm(isOpen);

  const onFinishInformation = () => async () => {
    const open = await validationDataForm();
    if (open) {
      const { indicators, productsRequest } = mappedDataToCalc({
        caseId,
        plansRequest: plans,
        products: Object.values(contractEstimatedProducts || {}),
      });
      const isChange = await fetchFinancialIndicator({
        caseId,
        products: productsRequest,
        indicators,
        currentPlans: plans,
        compareAll: true,
      });
      if (isChange) return;
    }
    toggleModalConfirm(open);
  };

  const validateDraft = async () => {
    const validTable = validationDataForm();
    try {
      await form.validateFields();
      if (!validTable) throw new Error("nope");
      const { indicators, productsRequest } = mappedDataToCalc({
        caseId,
        plansRequest: plans,
        products: Object.values(contractEstimatedProducts || {}),
      });
      const isChange = await fetchFinancialIndicator({
        caseId,
        products: productsRequest,
        indicators,
        currentPlans: plans,
        compareAll: true,
      });
      if (isChange) throw new Error("nope");
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.resolve(false);
    }
  };

  const fetchFinancialIndicator = async (data: {
    caseId: string;
    products: any[];
    indicators: any[];
    currentPlans: TPlanItem[];
    compareAll?: boolean;
  }): Promise<boolean> => {
    const { indicators, currentPlans, products, caseId, compareAll } = data;
    let isDataChanged = false;
    setIsLoadingFetchFinancial(true);

    try {
      const res = await getContractEstimatedFinacialIndicator({
        caseId,
        indicators,
        products,
      });
      const dataIndicator = res.data.financialIndicator;
      const dataError = res?.data?.errors;
      const newPlans = cloneDeep(currentPlans).map((p) => ({
        ...p,
        errorCode: undefined,
        errorMessage: undefined,
      }));
      Array.from({ length: indicators?.length }).forEach((_, index) => {
        const dataIndex = newPlans[index];
        const valueIndicator = indicators?.[index]?.value;
        const dataIndexNewIndicator = dataIndicator[index];

        if (
          (compareAll || dataIndex.isPersonInput) &&
          (valueIndicator || 0) != (dataIndexNewIndicator?.value || 0)
        ) {
          isDataChanged = true;
        }
        const error = dataError.find((er: any) => er.field === dataIndex.code);
        if (error) {
          dataIndex.errorCode = error.errorCode;
          dataIndex.errorMessage = error.message;
        }
        newPlans[index] = { ...dataIndex, ...dataIndexNewIndicator };
      });
      if (isDataChanged) toggleModalWarningDataChange();
      setPlan(newPlans);
    } catch (error) {
      //
    } finally {
      setIsLoadingFetchFinancial(false);
    }
    return isDataChanged;
  };

  const onFetchFiancialIndicator = (data: {
    caseId: string;
    products: { [key: string]: ContractEstimatedProducts };
    plansRequest: any[];
  }) => {
    const { indicators, productsRequest, caseId } = mappedDataToCalc({
      ...data,
      products: Object.values(data.products || {}),
    });

    if (timeoutFetchTableCalc) clearTimeout(timeoutFetchTableCalc);
    timeoutFetchTableCalc = setTimeout(
      () =>
        fetchFinancialIndicator({
          caseId,
          products: productsRequest,
          indicators,
          currentPlans: plans,
        }),
      1000
    );
  };

  const onChangeCase = (caseId: string) => {
    onFetchFiancialIndicator({
      caseId,
      plansRequest: plans.map((p) => ({ ...p, value: null })),
      products: contractEstimatedProducts,
    });
  };

  const caseData = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(caseId || ""),
    id: "",
    queryOptions: { enabled: !!caseId },
  });

  const onFetchProducts = async () => {
    try {
      const ids: string[] = [];
      const contractEstimatedProducts: any = {};
      const resProduct = await getProductsDefault(
        `${API_PATH.contractEstimatedsProductDefault}/${caseId}`
      );
      resProduct?.data?.forEach((product: any) => {
        const id = uuid();
        ids.push(id);
        contractEstimatedProducts[id] = {
          productCode: product?.code,
          productId: product?.product?.id,
          productName: product?.name,
          partNumber: product?.partNumber,
          description: product?.description,
          quantity: product?.quantity,
          unitId: product?.unit?.id,
          unitName: product?.unit?.name,
          unitCost: product?.unitCost,
          unitPrice: product?.unitPrice,
        };
      });

      onFetchFiancialIndicator({
        caseId,
        plansRequest: plans,
        products: contractEstimatedProducts,
      });

      const empty = uuid();
      setProducts(ids.length ? ids : [empty]);
      setTimeout(
        () =>
          form.setFieldsValue({
            contractEstimatedProducts: ids.length
              ? contractEstimatedProducts
              : {},
          }),
        0
      );
    } catch (error: any) {
      mappingErrorFromApi(error, form);
    } finally {
      setModalFetchProduct(false);
    }
  };

  const onSubmit = (isDraft?: boolean) => () => {
    if (!isDraft) {
      const conditionNotValid = submitConditionFetch?.data?.data?.every(
        (c) => !c.isOk
      );
      if (conditionNotValid) {
        toggleModalConditionInvalid();
        toggleModalConfirm(false);
        return;
      }
    }

    const formData = form.getFieldsValue();

    const { indicators } = mappedDataToCalc({
      caseId,
      plansRequest: plans,
      products: Object.values(formData?.contractEstimatedProducts || {}),
    });

    const contractEstimatedProducts = Object.values(
      formData?.contractEstimatedProducts || {}
    );

    const dataRequest = {
      resource: PATH.contractEstimateds,
      values: {
        note: formData.note?.trim(),
        contractEstimatedDocuments: formData?.contractEstimatedDocuments?.map(
          (d) => d.fileId
        ),
        id,
        indicators,
        caseId: formData.caseId,
        contractEstimatedProducts,
      },
      metaData: {
        type: isDraft ? "draft" : "",
      },
      id: formProps?.initialValues?.id,
    };

    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: translate(
            isDraft
              ? "Lưu nháp dự toán hợp đồng thành công"
              : "Lưu và chuyển dự toán hợp đồng thành công"
          ),
        });

        show(PATH.contractEstimateds, res?.data?.id);
      },
      onError: (error: any) => {
        toggleModalConfirm(false);
        mappingErrorFromApi(error, form);
      },
    };

    if (isEdit) {
      mutateUpdate(dataRequest, handleResponse);
    } else {
      mutateCreate(dataRequest, handleResponse);
    }
  };

  useEffect(() => {
    if (isEdit && initialValues?.id) {
      const { indicators } = mappedDataToCalc({
        caseId: initialValues?.case?.id,
        plansRequest: initialValues?.financialIndicator as any,
        products: initialValues?.contractEstimatedProducts as any,
      });
      const products =
        initialValues?.contractEstimatedProducts?.map(
          ({ product, quantity, unitCost, unitPrice }) => ({
            productId: product?.id,
            quantity: quantity || null,
            unitPrice: unitPrice || null,
            unitCost: unitCost || null,
          })
        ) || [];
      fetchFinancialIndicator({
        caseId: initialValues?.case?.id,
        products,
        indicators,
        currentPlans: initialValues?.financialIndicator,
        compareAll: true,
      });
    }
  }, [isEdit, initialValues]);

  return (
    <ContractEstimatedFormContext.Provider
      value={{
        canEditCaseId,
        caseData,
        initialValues: formProps?.initialValues as ContractEstimateData,
        isEdit,
        planState,
        isLoadingFetchFinancial,
        productState,
        sumCost,
        sumPrice,
        stateModalFetchProduct,
        onFetchFiancialIndicator,
        getEstimateValueError,
        onChangeCase,
        onFetchProducts,
        submitConditionFetch,
      }}
    >
      <CreateCustom
        {...props}
        title={translate(
          isEdit ? "Chỉnh sửa dự toán hợp đồng" : "Tạo mới dự toán hợp đồng"
        )}
        tabs={headerTab}
        onChangeTab={onChangeTab}
        onSubmit={onSubmit()}
        visibleShowModal={visibleModalConfirmCreate}
        setVisibleShowModal={toggleModalConfirm}
        saving={isLoadingCreate || isLoadingUpdate}
        saveButtonProps={{
          ...saveButtonProps,
          title: translate("Lưu và chuyển"),
          disabled: isLoadingFetchFinancial || formLoading,
        }}
        isLoading={formLoading}
        validateDraft={validateDraft}
        onDraft={onSubmit(true)}
        confirmModalProps={{
          title: translate(
            `Bạn muốn ${isEdit ? "sửa" : "tạo"} và chuyển dự toán hợp đồng này?`
          ),
        }}
        draftModalProps={{
          title: "Bạn muốn lưu nháp dự toán hợp đồng này?",
        }}
        draftButtonProps={{
          title: isEdit ? "Lưu chỉnh sửa" : "Lưu nháp",
          disabled: isLoadingFetchFinancial || formLoading,
        }}
        cancelModalProps={{
          title: `Bạn muốn hủy ${
            isEdit ? "chỉnh sửa" : "tạo"
          } dự toán hợp đồng này`,
          description: `Sau khi hủy ${
            isEdit ? "chỉnh sửa" : "tạo"
          } dự toán hợp đồng, mọi thông tin của bạn sẽ không được lưu lại.`,
        }}
        contentStyles={{ background: "transparent", padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          {...formProps}
          form={form}
          onFinish={onFinishInformation()}
          layout="vertical"
        >
          <ButtonConfirm
            hidden
            visible={visibleModalWarningDataChange}
            toggle={toggleModalWarningDataChange}
            onClick={toggleModalWarningDataChange}
            text={translate("popupWarningDataChange.title")}
            description={translate("popupWarningDataChange.desc")}
          />
          <ButtonConfirm
            hidden
            visible={visibleModalConditionInvalid}
            toggle={toggleModalConditionInvalid}
            onClick={toggleModalConditionInvalid}
            text={translate(
              "Phiếu chưa đáp ứng thông tin cần để chuyển duyệt. Vui lòng kiểm tra lại."
            )}
          />
          <Container hidden={activeTab !== TabKey.INFO}>
            <Information />
          </Container>

          <Container hidden={activeTab !== TabKey.ESTIMATE_INFO}>
            <EstimateInfo />
          </Container>
        </Form>
      </CreateCustom>
    </ContractEstimatedFormContext.Provider>
  );
});

export default ContractEstimationCreate;
