import React from "react";

export const useDimensions = (myRef: any) => {
    const [width, setWidth] = React.useState(0)
    const [height, setHeight] = React.useState(0)
    
    const handleResize = React.useCallback(() => {
        setWidth(myRef?.current.offsetWidth)
        setHeight(myRef?.current.offsetHeight)
    }, [myRef])

    React.useEffect(() => {
      handleResize()
    }, [myRef])
  
    React.useEffect(() => {
      window.addEventListener('load', handleResize)
      window.addEventListener('resize', handleResize)
  
      return () => {
        window.removeEventListener('load', handleResize)
        window.removeEventListener('resize', handleResize)
      }
    }, [myRef, handleResize])
  
    return { width, height }
  }