import React from "react";
import { useTranslate, IResourceComponentsProps, useCreate, useNavigation } from "@pankod/refine-core";
import {
  Form,
  Input,
  useForm,
  notification,
  Typography,
  Icons,
  Row,
  Col
} from "@pankod/refine-antd";

import { IPost } from "interfaces";
import { PATH, API_PATH } from "configs/path";
import { CreateCustom } from "components/layout";
import { SelectCustom } from "components/SelectCustom";

import InputUpper from "components/InputUpper";
import { showErrorToast } from "api/common";
import { mappingErrorFromApi } from "utils/commons";
const { Text, Paragraph } = Typography
const { TextArea } = Input;

export const DepartmentsCreate: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const [ visibleShowModal, setVisibleShowModal ] = React.useState<boolean>(false);
  const { mutate: mutateCreate, isLoading } = useCreate<any>();
  const { form, formProps, saveButtonProps } = useForm<IPost>();
  const { show } = useNavigation()
 
  const onSubmit = () => {
    mutateCreate(
      {
        resource: PATH.departments,
        values: {
          name: form.getFieldValue('name').trim(),
          code: form.getFieldValue('code').trim(),
          description: form.getFieldValue('description'),
          parentDepartmentId: form.getFieldValue('parentDepartmentId'),
        },
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: translate('departments.createSuccess')
          })
          show(PATH.departments, res?.data?.id)
        },
        onError: (error: any) => {
          setVisibleShowModal(false)
          const errorFieldMapping: any = {
            'ER0130': 'name'
          }
          mappingErrorFromApi(error, form, errorFieldMapping )
        },
      },
    )
  }

  return (
    <CreateCustom
      {...props}
      saveButtonProps={{
        ...saveButtonProps,
        title: translate('departments.create')
      }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      confirmModalProps={{
        title: translate('departments.createConfirm')
      }}
      saving={isLoading}
    >
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout="vertical" style={{maxWidth: 500}}
        labelCol={{ span: 10 }}
      >
        <Text style={{ fontWeight: 500, fontSize: 16 }}> {translate("departments.fields.title")} </Text>
        <Row style={{background: "#F0F5FF", padding: "8px 16px", borderRadius: "2px", marginTop: 24, marginBottom:10}}>
          <Col span={1}>
              <Icons.InfoCircleFilled style={{color: "#2F54EB"}}/>
          </Col>
          <Col span={23}>
              <Text> {translate("departments.fields.notes")} </Text>
          </Col>
        </Row>
        <Form.Item
          label={translate("departments.fields.name")}
          name="name"
          rules={[
            {
              whitespace: true,
              required: true,
              message: translate("errors.ER005"),
            },
            {
              max: 300,
              message: translate("errors.ER014", { max: '300'}),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("departments.fields.description")}
          name="description"
          rules={[
            {
              max: 500,
              message: translate("errors.ER014", { max: '500'}),
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={translate("departments.fields.code")}
          name="code"
          rules={[
            {
              required: true,
              message: translate("errors.ER005"),
            },
            {
              pattern: /^[A-Za-z0-9]+$/,
              message: <div>
                <p style={{marginBottom: 6}}>{translate("errors.ER020")}</p>
                <ul>
                  <li>{translate("errors.ER020_1")}</li>
                  <li>{translate("errors.ER020_2")}</li>
                </ul>
              </div>,
            },
            {
              max: 50,
              message: translate("errors.ER014", { max: '50'}),
            },
          ]}
        >
          <InputUpper/>
        </Form.Item>
        <Form.Item
          label={translate("departments.fields.parentDepartment")}
          name={["parentDepartmentId"]}
        >
          <SelectCustom
            allowClear
            resource={API_PATH.getParentsDepartment}
            onChange={(e) => {
              form.setFieldsValue({parentDepartment: {
                id: e
              }})
            }}
          />
        </Form.Item>
      </Form>
    </CreateCustom>
  );
};
