import React, { useEffect } from "react";
import { Col, Form, Icons, Input, Modal, notification, Row, Space, Typography, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow, useTranslate, useUpdate } from "@pankod/refine-core"
import { EditCustom } from "components/layout"
import { useNavigate } from 'react-router-dom'
import { IFeaturePermission, IRole, IRolePermission } from "interfaces";
import { isValidRoleName, mappingErrorFromApi } from "utils/commons";
import { PATH } from "configs/path";
import { RolePermissions } from "pages/rolePermissions";
import { ActionTypeEnum } from "utils/enums";
import { getRolePermissions, postRolePermissions } from "api";
import { v4 as uuid } from 'uuid';

const { Text, Title, Paragraph } = Typography;
const { TextArea } = Input;

export const RoleEdit: React.FC<IResourceComponentsProps> = (props) => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const [isDisable, setIsDisable] = React.useState<boolean>();
    const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>();
    const [visibleShowRoleModal, setVisibleShowRoleModal] = React.useState<boolean>();
    const [rolePermissions, setRolePermissions] = React.useState<IFeaturePermission[]>([]);
    const { mutate: mutateUpdate, isLoading } = useUpdate<any>();
    const { form, formProps, saveButtonProps } = useForm<IRole>();
    const { queryResult, showId } = useShow<any>();
    const { data } = queryResult;
    const [error, setError] =  React.useState<string>();

    const record = data?.data;

    useEffect(() => {
       fetchRolePermissions();
    }, [record])

    const fetchRolePermissions = () => {
        getRolePermissions(showId).then(val => {
            const temp = val.data.map(d => {
                const data = {
                    create: d.permissions?.includes('create'),
                    update: d.permissions?.includes('update'),
                    delete: d.permissions?.includes('delete'),
                    read: d.permissions?.includes('read'),
                    print: d.permissions?.includes('print'),
                    featureId: d.featureId,
                    featureName: d.featureName,
                    id: uuid()
                } as IFeaturePermission;
                data.all = (data.create && data.update && data.delete && data.read && data.print);
                return data;
            });
            setRolePermissions(temp);
            form.setFields([{
                name: 'rolePermissions',
                value: temp
            }])
        });
    }
    const onSubmit = () => {
        mutateUpdate(
            {
              id: record?.id,
              resource: PATH.roles,
              values: {
                code: record?.code,
                name: form.getFieldValue('name').trim(),
                description: form.getFieldValue('description')?.trim(),
              },
            },
            {
              onSuccess: () => {
                updateRolePermissions();
              },
              onError: (error: any) => {
                setVisibleShowModal(false)
                mappingErrorFromApi(error, form, {
                    ER047: 'name'
                })
              },
            },
          )
    }

    const validateRolePermissions = (): boolean => {
        var isValid = true;
        var rolePermissions = form.getFieldValue('rolePermissions') as IFeaturePermission[];

        if (rolePermissions && rolePermissions.filter(t => !t.featureId).length > 0) {
            isValid = false;
            setVisibleShowRoleModal(true);
        }
        return isValid;
    }

    
    const updateRolePermissions = () => {
        var rolePermissions = form.getFieldValue('rolePermissions') as IFeaturePermission[];
        
        const body: IRolePermission = {
            misRoleId: (showId?.toString() || ''),
            featurePermissions: rolePermissions ?? []
        }
        postRolePermissions(PATH.rolePermissions, body).then(res => {
            notification.success({
                message: translate("roles.message.editSuccess")
            })
            navigate(`show/${showId}`)
        })
        .catch(err => {
            mappingErrorFromApi(err, form, {
                ER047: 'name'
            })
        }).finally(() =>{
            setVisibleShowModal(false)
        })
    }

    return (
        <EditCustom
            {...props}
            isLoading={isLoading}
            record={record}
            title={<Text style={{ marginBottom: 0 }}>{translate("roles.roleInfor")}: {record?.code}</Text>}
            titlePopupConfirm={translate('roles.message.editConfirm')}
            saveButtonProps={{
                ...saveButtonProps,
                disabled: isLoading
            }}
            visibleShowModal={visibleShowModal}
            setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
            onSubmit={onSubmit}
        >
            <Form
                {...formProps}
                onFinish={() => { setVisibleShowModal(true); }}
                onFinishFailed={() => { setIsDisable(true) }}
                layout='vertical'
                labelCol={{ span: 10 }}
            >
                <Title level={5}>{translate("roles.roleInfor")}</Title>
                <Form.Item
                    label={translate("roles.fields.code")}
                    labelAlign="left"
                    name="code"
                    style={{ maxWidth: 500 }}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label={translate("roles.fields.name")}
                    name="name"
                    style={{ maxWidth: 500 }}
                    rules={[
                        {
                            required: true,
                            message: translate("errors.ER005"),
                        },
                        {
                            min: 3,
                            message: translate("errors.ER013", { min: '3' }),
                        },
                        {
                            max: 50,
                            message: translate("errors.ER014", { max: '50' }),
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                if (isValidRoleName(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(translate("errors.ER029")),
                                );
                            },
                        }
                    ]}
                >
                    <Input placeholder={translate("roles.fields.namePlaceHolder")} />
                </Form.Item>
                <Form.Item
                    label={translate("roles.fields.description")}
                    name="description"
                    style={{ maxWidth: 500 }}
                    rules={[
                        {
                            max: 300,
                            message: translate("errors.ER014", { max: '300' }),
                        },
                    ]}
                >
                    <TextArea
                        showCount={{formatter: (args: {count: number}) => `${args?.count}/300`}}
                        placeholder={translate("roles.fields.descriptionPlaceHolder")}
                    />
                </Form.Item>

                <Form.Item
                    name="rolePermissions"
                    validateTrigger="submit"
                    validateStatus="success"
                    rules={[
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                if (validateRolePermissions()) {
                                    return Promise.resolve();
                                }
                                return Promise.reject();
                            },
                        }
                    ]}
                >
                    <RolePermissions actionType={ActionTypeEnum.EDIT} value={rolePermissions} />
                </Form.Item>
            </Form>
            <Modal title=""
                visible={visibleShowRoleModal}
                onOk={() => setVisibleShowRoleModal(false)}
                onCancel={() => setVisibleShowRoleModal(false)}
                okText={translate("buttons.confirm")}
                cancelText={translate("buttons.reject")}
                cancelButtonProps={{hidden: true}}
            >
                <Space align="start">
                    <Text style={{fontSize: 22, marginRight: 10}} type="warning"><Icons.InfoCircleOutlined/></Text>
                    <Row>
                        <Col>
                            <Text style={{fontWeight: 500, fontSize: 16}}>{translate("roles.message.roleErrorUpdateTitle")}</Text>
                            <Paragraph>{translate("roles.message.roleErrorUpdateMessage")}</Paragraph>
                        </Col>
                    </Row>
                </Space>
                
            </Modal>
        </EditCustom>
    )
}