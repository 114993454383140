import { Button, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { PATH } from "configs/path";
import { FC, createContext, memo, useMemo, useState } from "react";
import { TabKey } from "../create";

import IBusinessPlan, { BusinessPlanObjectData } from "interfaces/BusinessPlan";
import { ApprovalServiceRequest } from "components/ApprovalServiceRequest";
import { IHeaderTab } from "interfaces";
import { ApprovalStatus } from "api/enums";
import CaseInfo from "./components/CaseInfo";
import General from "./components/General";
import ProductList from "./components/ProductList";
import ModalCloneBusinessPlan from "../list/components/ModalClone";
import { TableBusinessPlanShow } from "../create/components/TableBusinessPlan";
import { ButtonTooltip } from "components";
import usePermissions from "hooks/permission";

interface Props extends IResourceComponentsProps {
  isApprover?: boolean;
}

const { Text } = Typography;

const BusinessPlanShow: FC<Props> = (props) => {
  const translate = useTranslate();
  
  const { checkEditHaveHelperText } = usePermissions();

  const [visibleModalClone, setVisibleModalClone] = useState(false);

  const toggleModalClone = () => setVisibleModalClone((prev) => !prev);

  const headerTabs: IHeaderTab[] = useMemo(
    () => [
      {
        name: translate("Thông tin tổng quan"),
        key: TabKey.INFO,
        isActive: true,
        hidden: false,
      },
      {
        name: translate("Phương án kinh doanh tổng"),
        key: TabKey.COSTS,
        isActive: false,
        hidden: false,
      },
      {
        name: translate("Lịch sử phê duyệt"),
        key: TabKey.HISTORY,
        isActive: false,
        hidden: false,
      },
    ],
    []
  );

  return (
    <ApprovalServiceRequest
      {...props}
      headerTabs={headerTabs}
      defaultTab={TabKey.INFO}
      resource={PATH.businessPlans}
      renderInformation={(record: IBusinessPlan, tab: TabKey) => (
        <RenderContent record={record?.objectData} tabKey={tab} />
      )}
      onApprovalSuccess={() => {}}
      requestName={translate("Phê duyệt PAKD")}
      title={(record: IBusinessPlan) => (
        <>
          {translate("Phương án kinh doanh")}:{" "}
          <Text className="primary">{record?.objectData?.case?.name}</Text>
        </>
      )}
      headerButton={(record: IBusinessPlan) => (
        <>
          <ButtonTooltip
            hidden={record?.objectData?.status != ApprovalStatus.Approved}
            type="primary"
            onClick={toggleModalClone}
            {...checkEditHaveHelperText(PATH.businessPlans)}
          >
            {translate("Thay đổi PAKD")}
          </ButtonTooltip>
        </>
      )}
      elements={(record: IBusinessPlan) => (
        <ModalCloneBusinessPlan
          toggleModal={toggleModalClone}
          visibleModal={visibleModalClone}
          record={{
            ...record?.objectData,
            businessPlan: {
              id: record?.objectData?.id,
              code: record?.objectData?.code,
              name: "",
            },
          }}
        />
      )}
    />
  );
};

interface RenderContenProps {
  tabKey: TabKey;
  record: BusinessPlanObjectData;
}

interface State {
  record: Partial<BusinessPlanObjectData>;
}

export const BusinessPlanShowContext = createContext<State>({ record: {} });

const RenderContent: FC<RenderContenProps> = memo((props) => {
  const { record, tabKey } = props;

  return (
    <BusinessPlanShowContext.Provider value={{ record }}>
      <div
        style={{ gap: 8, flexDirection: "column" }}
        className={tabKey !== TabKey.INFO ? "hidden" : "flex"}
      >
        <CaseInfo />
        <General />
        <ProductList />
      </div>
      <div className={tabKey !== TabKey.COSTS ? "hidden" : ""}>
        <TableBusinessPlanShow plans={record?.financialIndicator} />
      </div>
    </BusinessPlanShowContext.Provider>
  );
});

export default BusinessPlanShow;
