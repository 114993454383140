import { Col, Collapse, Row, Table } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { FC, memo, useContext, useMemo } from "react";
import { InternalEstimateShowContext } from "../../..";
import type { ColumnsType } from "antd/es/table";
import { Currency } from "components/Currency";
import { TextStyle } from "pages/sale/estimates/internalEstimates/create/components/EstimateInfo/TotalEstimate";

const { Panel } = Collapse;

const TotalEstimate: FC = memo(() => {
  const translate = useTranslate();
  const { record } = useContext(InternalEstimateShowContext);
  const { totalByCash = 0, totalLabourCost = 0, price = 0 } = record;

  const dataTable = useMemo(() => {
    return [
      { label: "Tổng chi phí bằng tiền", total: totalByCash },
      { label: "Tổng chi phí nhân công", total: totalLabourCost },
      {
        label: "Tổng chi phí (Tổng giá vốn nội bộ)",
        total: totalByCash + totalLabourCost,
      },
      { label: "Tổng giá bán nội bộ (=Tổng giá vốn nội bộ)", total: price },
    ];
  }, [totalByCash, totalLabourCost, price]);

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        width: 350,
        title: translate("assignExploiting.customerCode"),
        dataIndex: "label",
        key: "label",
        render: (value, record, index) => (
          <TextStyle bold={index === 2}>{value}</TextStyle>
        ),
      },
      {
        title: translate("customer.customerName"),
        dataIndex: "total",
        key: "total",
        render: (value, record) => (
          <Currency value={value} showCurrency={false} />
        ),
      },
    ];
  }, [translate]);

  return (
    <Row>
      <Col xs={24} xxl={12}>
        <Collapse
          defaultActiveKey={["1"]}
          ghost
          className="w-full"
          collapsible="header"
        >
          <Panel header={translate("Dự toán tổng")} key="1">
            <Table
              style={{ marginTop: 8 }}
              showHeader={false}
              pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
              rowKey="id"
              columns={columns}
              dataSource={dataTable}
              scroll={{ x: 550 }}
            />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
});

export default TotalEstimate;
