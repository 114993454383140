import { useDelete, useTranslate } from "@pankod/refine-core";
import { Form, Input, Typography, notification } from "antd";
import UploadMulti from "components/UploadMulti";
import { API_PATH } from "configs/path";
import { FC, memo } from "react";
import { IFormCreateSettlementsContract } from "..";

const { Title } = Typography;
const { TextArea } = Input;
const { useWatch, useFormInstance } = Form;

const General: FC = memo(() => {
  const form = useFormInstance<IFormCreateSettlementsContract>();

  const translate = useTranslate();
  const caseId = useWatch("caseId", form);
  const { mutate: mutateDelete, isLoading: isDeleteLoading } = useDelete();
  const onDelete = (fileID: string) => {
    mutateDelete({
      resource: API_PATH.settlementContractsDocuments,
      metaData: {
        url: `${process.env.REACT_APP_API_URL}${API_PATH.settlementContractsDocuments}/delete`,
        method: "delete",
        data: {
          params: {
            "fileId": fileID
          }
        },
      },
      id: fileID,
      successNotification: {
        message: translate("messages.deleteSuccessfully"),
        type: "success",
      },
      errorNotification: {
        message: translate("messages.deleteUnsuccessfully"),
        type: "error",
      }
    });
  }

  return (
    <div style={{backgroundColor:"white", padding: 24}}>
      <Title level={5}>{translate("Thông tin chi tiết")}</Title>
      <div >
        <Form.Item
          label={translate("Nội dung quyết toán")}
          name="note"
          rules={[
            { required: true, message: translate("errors.ER005") },
            {
              max: 3000,
              message: translate("errors.ER014", { max: "3,000" }),
            },
          ]}
        >
          <TextArea rows={4} placeholder={translate("common.input.description")} />
        </Form.Item>
        <Form.Item
          name="settlementContractDocuments"
          validateStatus="success"
          label=""
          className="m-0"
        >
          <UploadMulti
            title={translate("common.fields.attachments")}
            url={`${process.env.REACT_APP_API_URL}${API_PATH.settlementContractsDocuments}/upload?caseId=${caseId}`}
            idKey="id"
            onClickButtonUpload={(action) => {
              if (!caseId) {
                notification.error({
                  message: translate(
                    "Vui lòng chọn mã vụ việc trước khi tải file."
                  ),
                });
              } else {
                action();
              }
            }}
            onRemoveFile={(file) => {
              onDelete(file.fileId);
            }}
          />
        </Form.Item>
      </div>
    </div>
  );
});

export default General;
