import { Collapse, Descriptions, Table } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { DataText } from "components";
import { PanelStyle } from "pages/sale/estimates/internalEstimates/create/components/EstimateInfo/CostTable";
import { FC, memo, useContext, useMemo } from "react";
import type { ColumnsType } from "antd/es/table";
import { InternalEstimateShowContext } from "../../..";
import { isEmpty } from "utils/commons";
import { Currency } from "components/Currency";
import { EstimateCost } from "interfaces/InternalEstimate";

const CostTable: FC = memo(() => {
  const translate = useTranslate();
  const { record } = useContext(InternalEstimateShowContext);
  const {
    listInternalEstimatedCost = [],
    totalByCash = 0,
    isAllProducts,
  } = record;

  const columns = useMemo<ColumnsType<EstimateCost>>(
    () => [
      {
        title: translate("common.no"),
        width: 70,
        dataIndex: "id",
        key: "id",
        render: (_, __, index) => {
          return <DataText value={index + 1} />;
        },
      },
      {
        title: translate("Tên sản phẩm"),
        width: 250,
        dataIndex: "productName",
        key: "productName",
        render: (_, record) => {
          return (
            <DataText
              value={
                isAllProducts
                  ? "Tất cả sản phẩm"
                  : `${record?.product.code} - ${record?.product.name}`
              }
            />
          );
        },
        sorter: (a, b) => a?.product?.code?.length - b?.product?.code?.length,
      },
      {
        title: translate("Tên chi phí"),
        width: 200,
        dataIndex: "cost",
        key: "cost",
        render: (_, record) => {
          return (
            <DataText
              value={
                record?.orderSort
                  ? "-"
                  : `${record?.cost.code} - ${record?.cost.name}`
              }
            />
          );
        },
        sorter: (a, b) => a?.cost?.code?.length - b?.cost?.code?.length,
      },
      {
        title: translate("Tỷ lệ"),
        width: 100,
        dataIndex: "proportionPolicy",
        key: "proportionPolicy",
        render: (_, record) => {
          return (
            <DataText
              value={
                record?.orderSort ? (
                  <Currency
                    value={record?.proportionPolicy?.proportion * 100}
                    showCurrency={false}
                    after="%"
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) =>
          a?.proportionPolicy?.proportion - b?.proportionPolicy?.proportion,
      },
      {
        title: translate("common.quantity"),
        width: 100,
        dataIndex: "quantity",
        key: "quantity",
        render: (_, record) => {
          return (
            <DataText
              value={
                record?.orderSort ? (
                  "-"
                ) : (
                  <Currency value={record?.quantity} showCurrency={false} />
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.quantity - b?.quantity,
      },
      {
        title: translate("products.fields.unitPrice"),
        width: 200,
        dataIndex: "unitPrice",
        key: "unitPrice",
        render: (_, record) => {
          return (
            <DataText
              value={
                record?.unitPrice ? (
                  <Currency value={record?.unitPrice} showCurrency={false} />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.unitPrice - b?.unitPrice,
      },
      {
        title: translate("common.totalPrice.main"),
        width: 200,
        dataIndex: "totalAmount",
        key: "totalAmount",
        render: (_, record) => {
          return (
            <DataText
              value={
                record?.totalAmount ? (
                  <Currency value={record?.totalAmount} showCurrency={false} />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.totalAmount - b?.totalAmount,
      },
      {
        title: translate("Ghi chú"),
        width: 200,
        dataIndex: "note",
        key: "note",
        render: (_, record) => {
          return (
            <DataText
              value={
                record?.orderSort
                  ? translate("Theo định mức quy định của công ty")
                  : record?.note || "-"
              }
            />
          );
        },
      },
    ],
    [isAllProducts]
  );

  return (
    <Collapse
      collapsible="header"
      className="w-full"
      defaultActiveKey={["1"]}
      ghost
    >
      <PanelStyle
        header={translate("Chi phí bằng tiền")}
        style={{ fontSize: 16 }}
        key="1"
      >
        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
          rowKey="id"
          columns={columns}
          dataSource={listInternalEstimatedCost}
          scroll={{ x: 1350 }}
          style={{ marginTop: 16 }}
        />
        <Descriptions style={{ marginTop: 4 }} layout="horizontal">
          <Descriptions.Item
            labelStyle={{ fontWeight: 500 }}
            label="Tổng chi phí bằng tiền"
          >
            <DataText
              value={
                isEmpty(totalByCash) ? (
                  "-"
                ) : (
                  <Currency showCurrency={false} value={totalByCash} />
                )
              }
            />
          </Descriptions.Item>
        </Descriptions>
      </PanelStyle>
    </Collapse>
  );
});

export default CostTable;
