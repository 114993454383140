import { notification } from "@pankod/refine-antd";
import { useDelete, useTranslate } from "@pankod/refine-core";
import { PATH } from "configs/path";
import AssignExploiting from "interfaces/AssignExploiting";

export interface Props {
  record?: AssignExploiting;
  visibleModal: boolean;
  toggleModal: () => void;
}

export const useController = (props: Props) => {
  const { toggleModal, record } = props;
  const translate = useTranslate();

  const { mutate, isLoading } = useDelete();

  const onDelete = () => {
    mutate(
      {
        resource: PATH.assignExploitings,
        id: record?.id!,
      },
      {
        onError: (error, variables, context) => {
          notification.error({
            message: translate("assignExploiting.removeExploitFailed"),
            description: error.message,
          });
          toggleModal();
        },
        onSuccess: (data, variables, context) => {
          toggleModal();
          notification.success({
            message: translate("assignExploiting.removeExploitSuccessful"),
          });
        },
      }
    );
  };

  return {
    props,
    isLoading,
    onDelete,
    translate,
  };
};
