import {
  Button,
  Col,
  Descriptions,
  Form,
  Icons,
  Input,
  Row,
  Table,
  Typography,
  notification,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { SelectCustom } from "components";
import ButtonConfirm from "components/ButtonConfirm";
import { Currency } from "components/Currency";
import { API_PATH } from "configs/path";
import { cloneDeep } from "lodash";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { v4 as uuid } from "uuid";
import { ContractContext, IForm } from "../../..";
import { getProductsDefault } from "api";
import { mappingErrorFromApi } from "utils/commons";

interface Props {
  productsIds: string[];
  setProductIds: Dispatch<SetStateAction<string[]>>;
}

const { Title } = Typography;
const { TextArea } = Input;

const { useFormInstance, useWatch } = Form;
const ServiceScope: FC<Props> = memo((props) => {
  const { isEdit, initialValue, contractTypeConstraintState } =
    useContext(ContractContext);
  const { productsIds, setProductIds } = props;
  const form = useFormInstance<IForm>();
  const translate = useTranslate();

  const [contractTypeConstraint] = contractTypeConstraintState;

  const contractProducts = useWatch("contractProducts", form);
  const caseId = useWatch("caseId", form);

  const isShowButtonRemoveItem = true;

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(productsIds);
    newList.splice(index, 1);
    setProductIds(newList);
  };

  const sumBeforeTax = useMemo(
    () =>
      Object.values(contractProducts || {})?.reduce(
        (sum, next) => sum + (next?.quantity ?? 0) * (next?.unitPrice ?? 0),
        0
      ),
    [contractProducts]
  );

  const sumTax = useMemo(
    () =>
      Object.values(contractProducts || {})?.reduce(
        (sum, next) =>
          sum +
          ((next?.quantity ?? 0) *
            (next?.unitPrice ?? 0) *
            (next?.taxPercent ?? 0)) /
            100,
        0
      ),
    [contractProducts]
  );

  const sumAfterTax = useMemo(
    () =>
      Object.values(contractProducts || {})?.reduce((sum, next) => {
        const { unitPrice = 0, quantity = 0, taxPercent = 0 } = next || {};
        const priceBeforeTax = unitPrice && quantity ? unitPrice * quantity : 0;
        const tax =
          priceBeforeTax && taxPercent
            ? (priceBeforeTax * taxPercent) / 100
            : 0;
        const priceAfterTax = priceBeforeTax + tax;
        return sum + priceAfterTax;
      }, 0),
    [contractProducts]
  );

  const onFetchProducts = async () => {
    try {
      if (!caseId) {
        return notification.error({
          message: translate("errors.ER0194"),
        });
      }
      const ids: string[] = [];
      const contractProductsData: any = {};
      const resProduct = await getProductsDefault(
        `${API_PATH.contractProductsDefault}/${caseId}`
      );
      resProduct?.data?.forEach((product: any) => {
        const id = uuid();
        ids.push(id);
        contractProductsData[id] = {
          productCode: product?.code,
          productId: product?.product?.id,
          productName: product?.name,
          partNumber: product?.partNumber,
          description: product?.description,
          quantity: product?.quantity,
          unitId: product?.unit?.id,
          unitName: product?.unit?.name,
          unitCost: product?.unitCost,
          unitPrice: product?.unitPrice,
        };
      });

      const empty = uuid();
      setProductIds(ids.length ? ids : [empty]);
      setTimeout(
        () => form.setFieldsValue({ contractProducts: contractProductsData }),
        0
      );
    } catch (error: any) {
      mappingErrorFromApi(error, form);
    } finally {
    }
  };

  useEffect(() => {
    form.setFields([
      {
        name: "validateContractProducts",
        errors:
          contractTypeConstraint.requireProduct && !productsIds?.length
            ? [translate("errors.ER005")]
            : undefined,
      },
    ]);
  }, [productsIds, contractTypeConstraint.requireProduct, form, translate]);

  useEffect(() => {
    if (isEdit && !!initialValue?.contractProducts?.length) {
      const ids: string[] = [];
      const contractProductsData: any = {};
      initialValue?.contractProducts?.forEach((pr) => {
        ids.push(pr.id);
        contractProductsData[pr.id] = {
          ...pr,
          taxPercent: pr.taxPercent * 100,
        };
      });

      setProductIds(ids);
      setTimeout(
        () => form.setFieldsValue({ contractProducts: contractProductsData }),
        0
      );
    }
  }, [isEdit, initialValue?.contractProducts?.length]);

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Row>
        <Col flex={1}>
          <Title level={5}>Phạm vi dịch vụ</Title>
        </Col>
        <Col style={{ marginRight: 8 }}>
          <ButtonConfirm
            type="primary"
            onClick={(_) => onFetchProducts()}
            text={translate(
              "Bạn muốn lấy dữ liệu danh sách sản phẩm/dịch vụ từ DTHD?"
            )}
            noCancel={false}
            description={
              "Lưu ý: Sau khi lấy dữ liệu, các thông tin trong danh sách sản phẩm hiện tại sẽ bị xóa."
            }
          >
            {translate("Lấy từ DTHD")}
          </ButtonConfirm>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setProductIds([...productsIds, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {translate("actions.create")}
          </Button>
        </Col>
      </Row>

      <label style={{ display: "inline-block" }}>
        {contractTypeConstraint.requireProduct && <span className="required-mark">*</span>}
        {translate("products.titles.listService")}
      </label>

      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={productsIds}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        scroll={{ x: 1900 }}
      >
        <Table.Column
          title={translate("common.no")}
          dataIndex="stt"
          align="center"
          width={"70px"}
          render={(v, keyRow: string, index) => {
            return index + 1;
          }}
        />
        <Table.Column
          className="required-field"
          title={
            <label>
              <span className="required-mark">*</span>
              {translate("Tên sản phẩm")}
            </label>
          }
          dataIndex="productId"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            return (
              <>
                <Form.Item hidden name={["contractProducts", keyRow, "id"]} />
                <Form.Item
                  name={["contractProducts", keyRow, "productId"]}
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: translate("errors.ER005"),
                    },
                  ]}
                >
                  <SelectCustom
                    resource={API_PATH.productDropdownList}
                    allowClear
                    optionLabel="label"
                    optionValue="id"
                    // label={
                    //   contractProducts?.[keyRow]?.productCode &&
                    //   `${contractProducts?.[keyRow]?.productCode} - ${contractProducts?.[keyRow]?.productName}`
                    // }
                    placeholder={translate("Chọn giá trị")}
                    formatData={(r: any) => ({
                      ...r,
                      label: `${r?.code} - ${r?.name}`,
                    })}
                  />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("products.fields.partNumber")}
          dataIndex="partNumber"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["contractProducts", keyRow, "partNumber"]}
                className="m-0"
                rules={[
                  {
                    max: 200,
                    message: translate("errors.ER014", { max: 200 }),
                  },
                ]}
              >
                <Input placeholder={translate("Nhập giá trị")} />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={
            <label>
              <span className="required-mark">*</span>
              {translate("common.description")}
            </label>
          }
          dataIndex="description"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["contractProducts", keyRow, "description"]}
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: translate("errors.ER005"),
                  },
                  {
                    max: 3000,
                    message: translate("errors.ER014", { max: 3000 }),
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  placeholder={translate("Nhập giá trị")}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={
            <label>
              <span className="required-mark">*</span>
              {translate("common.quantity")}
            </label>
          }
          dataIndex="quantity"
          width={"100px"}
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["contractProducts", keyRow, "quantity"]}
                isLimit
                isRequired
                className="m-0"
                placeholder={translate("Nhập giá trị")}
              />
            );
          }}
        />
        <Table.Column
          title={
            <label>
              <span className="required-mark">*</span>
              {translate("products.fields.unit")}
            </label>
          }
          dataIndex="unitId"
          width={"100px"}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                style={{ margin: 0 }}
                name={["contractProducts", keyRow, "unitId"]}
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: translate("errors.ER005"),
                  },
                ]}
              >
                <SelectCustom
                  resource={API_PATH.unitsDropdownlist}
                  optionLabel="label"
                  optionValue="value"
                  placeholder={translate("Chọn giá trị")}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={
            <label>
              <span className="required-mark">*</span>
              {translate("products.fields.unitPrice")}
            </label>
          }
          dataIndex="unitPrice"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["contractProducts", keyRow, "unitPrice"]}
                isLimit
                isRequired
                className="m-0"
                placeholder={translate("Nhập giá trị")}
              />
            );
          }}
        />
        <Table.Column
          title={
            <label>
              <span className="required-mark">*</span>
              {translate("% Thuế")}
            </label>
          }
          dataIndex="taxPercent"
          width={"100px"}
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["contractProducts", keyRow, "taxPercent"]}
                isLimit
                isRequired
                min={0}
                max={100}
                errorCode="ER0131"
                className="m-0"
                allowDecimal
                placeholder={translate("Nhập % thuế")}
              />
            );
          }}
        />
        <Table.Column
          title={translate("common.totalPrice.beforeTax")}
          dataIndex="priceBeforeTax"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            const row = contractProducts?.[keyRow];
            const { unitPrice = 0, quantity = 0 } = row || {};
            return (
              <Currency
                showCurrency={false}
                value={unitPrice && quantity ? unitPrice * quantity : 0}
              />
            );
          }}
        />
        <Table.Column
          title={translate("Thuế")}
          dataIndex="tax"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            const row = contractProducts?.[keyRow];
            const { unitPrice = 0, quantity = 0, taxPercent = 0 } = row || {};
            const priceBeforeTax =
              unitPrice && quantity ? unitPrice * quantity : 0;

            return (
              <Currency
                showCurrency={false}
                value={
                  priceBeforeTax && taxPercent
                    ? (priceBeforeTax * taxPercent) / 100
                    : 0
                }
              />
            );
          }}
        />
        <Table.Column
          title={translate("common.totalPrice.afterTax")}
          dataIndex="priceAfterTax"
          width={"200px"}
          render={(v, keyRow: string, index) => {
            const row = contractProducts?.[keyRow];
            const { unitPrice = 0, quantity = 0, taxPercent = 0 } = row || {};
            const priceBeforeTax =
              unitPrice && quantity ? unitPrice * quantity : 0;
            const tax =
              priceBeforeTax && taxPercent
                ? (priceBeforeTax * taxPercent) / 100
                : 0;
            const priceAfterTax = priceBeforeTax + tax;

            return <Currency showCurrency={false} value={priceAfterTax} />;
          }}
        />
        <Table.Column
          align="center"
          width={90}
          fixed="right"
          title={translate("table.actions")}
          render={(v, record, index) => {
            if (isShowButtonRemoveItem)
              return (
                <ButtonConfirm
                  text={translate(
                    "Bạn chắc chắn muốn xóa sản phẩm dịch vụ này?"
                  )}
                  description=""
                  onClick={onRemoveItem(index)}
                  type="text"
                  danger
                >
                  <Icons.DeleteOutlined />
                </ButtonConfirm>
              );
          }}
        />
      </Table>
      <Form.Item
        name="validateContractProducts"
        rules={[
          {
            validator: () => {
              if (
                contractTypeConstraint.requireProduct &&
                !productsIds?.length
              ) {
                return Promise.reject(translate("errors.ER005"));
              }
              return Promise.resolve();
            },
          },
        ]}
      />
      <Descriptions
        column={{ xs: 1, lg: 3 }}
        layout="vertical"
        className="no-padding"
        style={{ marginTop: 8 }}
      >
        <Descriptions.Item
          label={translate("Tổng giá trước thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={sumBeforeTax} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={sumTax} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Tổng giá sau thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={sumAfterTax} />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
});

export default ServiceScope;
