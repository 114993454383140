import { Space, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { Image } from "antd";
const { Paragraph } = Typography;

const NoNodeSelected = ({ isCreate }: any) => {
  const t = useTranslate();
  return (
    <Space
      style={{ width: "100%", padding: 24 }}
      direction={"vertical"}
      align={"center"}
    >
      <Image preview={false} width={200} src="/images/workflow-empty.png" />
      <Paragraph>
        {isCreate
          ? t("workflows.messages.empty")
          : t("workflows.messages.emptyDetail")}
      </Paragraph>
    </Space>
  );
};

export default NoNodeSelected;
