import React from "react";
import { useTranslate } from "@pankod/refine-core";
import { Input } from "@pankod/refine-antd";
import { InputRef } from "rc-input/lib/interface";
import { SearchProps } from "antd/lib/input/Search";
import styled from "styled-components";

const { Search } = Input;

interface Props
  extends React.RefAttributes<InputRef & HTMLInputElement>,
    SearchProps {
  onSearch: (e: string) => void;
}

export const SearchBox: React.FC<Props> = (props) => {
  const t = useTranslate();
  return (
    <Search
      {...props}
      allowClear
      placeholder={props?.placeholder || t("common.search")}
      onSearch={props?.onSearch}
      maxLength={300}
      style={{ width: 264, marginBottom: 16, ...props?.style }}
    />
  );
};
