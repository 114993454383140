import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useNavigation,
  useUpdate,
} from "@pankod/refine-core";
import {
  Typography,
  Descriptions,
  Button,
  Icons,
  Card,
  Switch,
  Space,
  Modal,
  Row,
  Col,
  notification,
} from "@pankod/refine-antd";
import { PATH } from "configs/path";
import { ShowCustom } from "components/layout";
import { formatISODateTimeToView, mappingErrorFromApi } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
import { RolesEmployeeList } from "pages/userTenantMisRoles";
import { EmployeeStatusCustom } from "components/EmployeeStatus";
import usePermissions from "hooks/permission";
import { EmployeeStatus } from "api/enums";
import { EmptyData, DataText, ButtonTooltip } from "components";
import { showErrorToast } from "api/common";

const { Title, Text, Paragraph } = Typography;

export const EmployeeShow: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { edit } = useNavigation();
  const { queryResult } = useShow<any>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const checkedStatus = record?.status === EmployeeStatus.Active;
  const [visibleChangeStatusModal, setVisibleChangeStatusModal] =
    React.useState<boolean>();
  const [visibleUnLinkModal, setVisibleUnLinkModal] = React.useState<boolean>();
  const { mutate: mutateUpdate, isLoading: isUpdateLoading } = useUpdate();
  const { checkEditHaveHelperText, checkEdit, checkView } = usePermissions();

  const confirmChangeStatus = (checked: boolean) => {
    setVisibleChangeStatusModal(true);
  };

  const onChangeStatus = () => {
    const body = {
      newStatus:
        record?.status == EmployeeStatus.Active
          ? EmployeeStatus.Deactive
          : EmployeeStatus.Active,
    };
    mutateUpdate(
      {
        resource: PATH.employees,
        id: record?.id,
        values: body,
        metaData: {
          type: "updateStatus",
        },
      },
      {
        onSuccess: () => {
          setVisibleChangeStatusModal(false);
          const message =
            record?.status == EmployeeStatus.Active
              ? t("employees.deactiveStatusSuccess", { code: record?.code })
              : t("employees.activeStatusSuccess", { code: record?.code });
          notification.success({ message: message });
        },
        onError: (e) => {
          setVisibleChangeStatusModal(false);
          showErrorToast(e);
        },
      }
    );
  };

  const confirmUnLink = () => {
    setVisibleUnLinkModal(true);
  };
  const onUnlink = () => {
    mutateUpdate(
      {
        resource: PATH.employees,
        id: record?.id,
        values: {},
        metaData: {
          type: "unlinkEmployeeUser",
        },
      },
      {
        onSuccess: () => {
          setVisibleUnLinkModal(false);
          notification.success({
            message: t("employees.unLinkSuccessMessage"),
          });
        },
        onError: (e) => {
          setVisibleChangeStatusModal(false);
          showErrorToast(e);
        },
      }
    );
  };

  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        breadcrumbText={record?.code}
        title={
          <Text style={{ marginBottom: 0 }}>
            {t("employees.employeesInfo")}:{" "}
            <Text className="primary">{record?.code}</Text>
          </Text>
        }
        headerButtons={<></>}
      >
        <Descriptions
          column={3}
          title={t("employees.employeesInfo")}
          layout="vertical"
          extra={
            <ButtonTooltip
              type="primary"
              icon={<Icons.EditOutlined />}
              onClick={() => edit(PATH.employees, record?.id)}
              {...checkEditHaveHelperText(PATH.employees)}
            >
              {t("actions.edit")}
            </ButtonTooltip>
          }
        >
          <Descriptions.Item
            label={t("employees.fields.userTenantId")}
            span={2}
          >
            <Space>
              <DataText value={record?.emailUser} />
              {record?.emailUser && (
                <Button
                  disabled={!checkEdit(PATH.employees)}
                  onClick={confirmUnLink}
                  type="link"
                >
                  {t("employees.unlink")}
                </Button>
              )}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.status.title")}>
            <Row style={{ width: "100%" }}>
              <Col flex={2}>
                <EmployeeStatusCustom value={record?.status} />
              </Col>
              <Col flex={3}>
                <Switch
                  checked={checkedStatus}
                  disabled={!checkEdit(PATH.employees)}
                  onChange={confirmChangeStatus}
                />
              </Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item
            label={t("employees.employeesInfo")}
            span={3}
            labelStyle={{ fontWeight: 700 }}
          >
            {""}
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.code")}>
            <DataText value={record?.code} />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.fullName")}>
            <DataText value={record?.fullName} />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.birthday")}>
            <DataText
              value={formatISODateTimeToView(record?.birthday, DATE_FORMAT)}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.gender")}>
            <DataText
              value={
                record?.gender === 0 ? t("common.male") : t("common.female")
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.idCard")}>
            <DataText value={record?.idCard} />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.emailEmployee")}>
            <DataText value={record?.emailEmployee} />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.lineManagerId")}>
            <DataText value={record?.lineManagerName} />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.departmentId")}>
            <DataText value={record?.departmentName} />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.title")}>
            <DataText value={record?.title?.name} />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.joinDate")} span={3}>
            <DataText
              value={formatISODateTimeToView(record?.joinDate, DATE_FORMAT)}
            />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.permenantAddress")} span={3}>
            <DataText value={record?.fullPermenantAddress} />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.temporaryAddress")} span={3}>
            <DataText value={record?.fullTemporaryAddress} />
          </Descriptions.Item>
          <Descriptions.Item
            label={t("employees.personalInfo")}
            span={3}
            labelStyle={{ fontWeight: 700 }}
          >
            {""}
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.emailPersonal")}>
            <DataText value={record?.emailPersonal} />
          </Descriptions.Item>
          <Descriptions.Item label={t("employees.fields.phoneNumber")}>
            <DataText value={record?.phoneNumber} />
          </Descriptions.Item>
        </Descriptions>
      </ShowCustom>
      <div className="ant-page-header-content custom-content">
        <Card>
          {!checkView(PATH.userTenantMisRoles) && (
            <div style={{ padding: 24 }}>
              <Title level={4} style={{ marginBottom: 0, fontWeight: 500 }}>
                {t("userRoles.userRoles")}
              </Title>
              <EmptyData
                text={t("common.noPermission")}
                style={{ paddingTop: 24, paddingBottom: 24 }}
              />
            </div>
          )}
          {checkView(PATH.userTenantMisRoles) && (
            <RolesEmployeeList
              options={{ employeeId: record?.id, employeeCode: record?.code }}
            />
          )}
        </Card>
      </div>
      <Modal
        title=""
        visible={visibleChangeStatusModal}
        onOk={onChangeStatus}
        onCancel={() => {
          setVisibleChangeStatusModal(false);
        }}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        okButtonProps={{ loading: isUpdateLoading }}
        cancelButtonProps={{ disabled: isUpdateLoading }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <Row>
            <Col>
              <Text style={{ fontWeight: 500, fontSize: 16 }}>
                {record?.status == EmployeeStatus.Active
                  ? t("employees.deactiveStatusTitle")
                  : t("employees.activeStatusTitle")}
              </Text>
              <Paragraph>
                {record?.status == EmployeeStatus.Active
                  ? t("employees.deactiveStatusMessage")
                  : t("employees.activeStatusMessage")}
              </Paragraph>
            </Col>
          </Row>
        </Space>
      </Modal>

      <Modal
        title=""
        visible={visibleUnLinkModal}
        onOk={onUnlink}
        onCancel={() => {
          setVisibleUnLinkModal(false);
        }}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        okButtonProps={{
          danger: true,
          loading: isUpdateLoading,
        }}
        cancelButtonProps={{ disabled: isUpdateLoading }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Paragraph
              style={{
                fontWeight: 500,
                fontSize: 16,
                paddingTop: 5,
                marginBottom: 0,
              }}
            >
              {t("employees.unlinkConfirmTitle")}
            </Paragraph>
            <Paragraph style={{ fontSize: 14 }}>
              {t("employees.unlinkConfirmMessage", {
                email: record?.emailUser,
              })}
            </Paragraph>
          </div>
        </Space>
      </Modal>
    </>
  );
};
