import React, { useRef } from "react";
import { TitleProps, useTranslate } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import {
  Button,
  Col,
  Dropdown,
  Icons,
  Menu,
  Modal,
  Row,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { tenantSelectionBox } from "./styles";
import { API_PATH } from "configs/path";
import { ITenantData } from "api/types";
import { TENANT_ID } from "configs/storage";
import { useNavigate } from "react-router-dom";
import { defaultRedirectUrl } from "provider/authProvider";
import { fetchTenants } from "api";
import usePermissions from "hooks/permission";
import { getLocalTenants, usePage } from "hooks/page";
import styled from "styled-components";
import useOnClickOutside from "hooks/useOnClickOutside";

interface Props extends TitleProps {
  visible?: boolean;
  toggle?: () => void;
}

const { Text } = Typography;
const { Link } = routerProvider;
const { RetweetOutlined, CheckCircleFilled } = Icons;

const Container = styled(Space)<{ isCollapse?: boolean }>`
  display: flex;
  overflow: hidden;
  margin-bottom: ${({ isCollapse }) => (isCollapse ? 0 : 20)}px;

  .logoCollapse {
    width: 130px;
    padding: 16px 24px;
  }

  .logoUnCollapse {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    width: 80px;
  }
`;

export const Title: React.FC<Props> = ({ collapsed, visible, toggle }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { loadPermissions } = usePage();

  const { isUser } = usePermissions();
  const [isShowChangeSpacePopup, setIsShowChangeSpacePopup] =
    React.useState<boolean>(false);
  const [tenantList, setTenantList] = React.useState<ITenantData[] | null>(
    null
  );
  const [tenantToChange, setTenantToChange] = React.useState<any>();

  const selectedTenantId = localStorage.getItem(TENANT_ID);
  const isMultiTenant = tenantList && tenantList.length > 1;

  const menuRef = useRef<any>(null);

  useOnClickOutside({
    ref: menuRef,
    handler: (event) => {
      if (visible && toggle && event?.target.id !== "menu-tenant") toggle();
    },
  });

  const tenants = tenantList
    ? tenantList.map((item, index) => {
        return {
          key: index,
          className: "tenant-item",
          icon: item.logo ? <img src={item.logo} width={74} /> : null,
          title: "",
          label: (
            <Space
              align="start"
              style={{ padding: "8px 24px", width: "100%" }}
              onClick={() => {
                chooseTenant(item);
              }}
            >
              <img src="/images/icons/home.png" width={22} />
              <Text
                style={{ paddingRight: 4 }}
                className={selectedTenantId === item.id ? "primary" : ""}
              >
                {item.name}
              </Text>
              {selectedTenantId === item.id && (
                <CheckCircleFilled
                  style={{
                    color: "#597EF7",
                    marginTop: 8,
                    fontSize: 14,
                    position: "absolute",
                    right: 20,
                    top: 10,
                  }}
                />
              )}
            </Space>
          ),
        };
      })
    : [];

  const chooseTenant = (tenant: ITenantData) => {
    if (tenant.id !== selectedTenantId) {
      setTenantToChange(tenant);
      setIsShowChangeSpacePopup(true);
    }
  };
  const changeTenant = async (isConfirmed: boolean) => {
    setIsShowChangeSpacePopup(false);
    setTenantToChange(null);
    if (isConfirmed && tenantToChange) {
      localStorage.setItem(TENANT_ID, tenantToChange.id);

      await Promise.all([
        loadPermissions ? loadPermissions() : Promise.resolve(),
      ]);
      const redirectTo = `/${defaultRedirectUrl}`;
      navigate(redirectTo);
      window.location.reload();
    }
  };
  const menu = (
    <div ref={menuRef}>
      <Menu style={tenantSelectionBox} items={tenants} />
    </div>
  );

  const getTenants = async () => {
    const localTenants = getLocalTenants();
    if (localTenants) {
      setTenantList(localTenants);
      return;
    }

    const { data } = await fetchTenants(API_PATH.myTenants);
    if (data.length > 1) {
      setTenantList(data as ITenantData[]);
    }
  };

  React.useEffect(() => {
    if (isUser()) getTenants();
  }, []);

  return (
    <div className="logo">
      <Container
        isCollapse={false}
        style={{
          display: "flex",
          marginBottom: !collapsed ? 20 : 0,
          overflow: "hidden",
        }}
        className="tenant-selection-box"
      >
        <Link to="/">
          {!collapsed ? (
            <img
              src={"/logo-collapsed.svg"}
              alt="Refine"
              className="logoUnCollapse"
            />
          ) : (
            <>
              <img src={"/logo.svg"} alt="Refine" className="logoCollapse" />
            </>
          )}
        </Link>
        {isMultiTenant && collapsed && (
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            overlayStyle={{ width: 320 }}
            visible={visible}
          >
            <Button
              id="menu-tenant"
              onClick={toggle}
              type="text"
              style={{
                position: "absolute",
                right: 10,
                top: !collapsed ? 60 : 10,
              }}
            >
              <div className="w-full h-full relative">
                <RetweetOutlined className="btn-tenant" />
                <div className="absolute inset-0" id="menu-tenant" />
              </div>
            </Button>
          </Dropdown>
        )}
      </Container>

      <Modal
        title=""
        visible={isShowChangeSpacePopup}
        onOk={() => changeTenant(true)}
        onCancel={() => changeTenant(false)}
        okText={translate("components.title.changeSpaceOk")}
        cancelText={translate("components.title.changeSpaceCancel")}
      >
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>
              {translate("components.title.changeSpaceModalTitle")}
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text>
              {translate("components.title.changeSpaceModalMessage", {
                name: tenantToChange?.name,
              })}
            </Text>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
