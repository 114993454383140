import { Icons, Spin } from "@pankod/refine-antd"

export interface PageLoadingProps {
    isLoading?: boolean
}
export const PageLoading = ({ isLoading }: PageLoadingProps) => {
    if (!isLoading) return (<></>)
    return (
        <div style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%', zIndex: 9999, background: 'white', opacity: 0.5, textAlign: 'center', alignItems: 'center' }}>
            <Icons.LoadingOutlined style={{ fontSize: 50, position: 'absolute', top: '50%' }} />
        </div>
    )
}

export const LoadingIndicator = ({ isLoading }: PageLoadingProps) => {
    return <>{isLoading && <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }}>
        <Spin />
    </div>}
    </>
}