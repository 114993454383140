import { Form, notification, useForm, useSelect } from "@pankod/refine-antd";
import {
  CrudFilter,
  LogicalFilter,
  useTable,
  useTranslate,
} from "@pankod/refine-core";
import { salePiepelineExportFile } from "api";
import { CurrencyType } from "api/enums";
import { generateCurrency } from "components/Currency";
import { DATE_FORMAT, DEFAULT_KEY } from "configs/constants";
import { API_PATH } from "configs/path";
import dayjs from "dayjs";
import { BusinessOpportunitiesStatus } from "interfaces/BusinessOpportunities";
import { MarketStatus } from "interfaces/MarketArea";
import { ISalePipeline } from "interfaces/SalePipeline";
import { getStatus } from "pages/sale/businessOpportunities/show/controller";
import { useCallback, useEffect, useMemo, useState } from "react";
import { b64toBlob, formatOnlyDateToUTC } from "utils/commons";
interface FilterForm {
  employeeId: string;
  status: string;
  revenueEstFrom: string;
  revenueEstTo: string;
  SBAGroupId: string;
  marketArea: string;
  date: [Date, Date];
}

const { useWatch } = Form;

const initFilter: CrudFilter[] = [
  {
    field: "employeeId",
    operator: "eq",
    value: DEFAULT_KEY,
  },
  {
    field: "status",
    operator: "eq",
    value: DEFAULT_KEY,
  },
  {
    field: "SBAGroupId",
    operator: "eq",
    value: DEFAULT_KEY,
  },
  {
    field: "marketArea",
    operator: "eq",
    value: DEFAULT_KEY,
  },
];

export const useController = () => {
  const translate = useTranslate();
  const [visibleModalExport, setVisibleModalExport] = useState(false);

  const { form, formProps, saveButtonProps } = useForm<any, any, FilterForm>();

  const status = useWatch("status", form);
  const marketArea = useWatch("marketArea", form);
  const SBAGroupId = useWatch("SBAGroupId", form);
  const employeeId = useWatch("employeeId", form);
  const revenueEstFrom = useWatch("revenueEstFrom", form);
  const revenueEstTo = useWatch("revenueEstTo", form);

  const date = useWatch("date", form);

  const table = useTable<{ listSalesPipeline: any[] }, any>({
    queryOptions: { enabled: !!date },
    initialCurrent: 1,
    initialFilter: initFilter,
  });

  const {
    setFilters,
    sorter,
    tableQueryResult: { data: dataTable },
  } = table;

  const dataSalePipeline = useMemo(() => {
    const data = dataTable?.data as ISalePipeline;
    return {
      list: data?.listSalesPipeline || [],
      totalCase: data?.totalCase,
      totalCustomer: data?.totalCustomer,
      totalBudgetEstimation: data?.totalBudgetEstimation,
      totalRevenueEstimation: data?.totalRevenueEstimation,
      totalProfitEstimation: data?.totalProfitEstimation,
    };
  }, [dataTable]);

  const [openSearch, setOpenSearch] = useState(false);

  const renderStatus = useCallback(
    (
      value: BusinessOpportunitiesStatus = BusinessOpportunitiesStatus[
        "Confirm Opportunity"
      ]
    ) => getStatus(value),
    []
  );

  const { selectProps: employeeSelectProps } = useSelect({
    resource: API_PATH.salePipelinesDropdownlistEmployee,
    optionValue: "id",
    optionLabel: "label",
    metaData: {
      formatData: (emp: any) => ({
        ...emp,
        label: `${emp.code} - ${emp.fullName}`,
      }),
    },
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: businessOpportunityStatusProps } = useSelect({
    resource: API_PATH.salePipelinesDropdownlistBusinessOpportinityStatus,
    optionValue: "id",
    optionLabel: "name",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: SBAGroupSelectProps } = useSelect({
    resource: API_PATH.salePipelineSBADropdown,
    optionValue: "id",
    optionLabel: "name",
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: marketSelectProps } = useSelect({
    resource: API_PATH.marketDropdownList,
    optionValue: "id",
    optionLabel: "name",
    filters: [
      { field: "marketAreaStatus", operator: "eq", value: MarketStatus.ALL },
    ],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const onFilter = () => {
    const {
      employeeId,
      SBAGroupId,
      marketArea,
      revenueEstFrom,
      revenueEstTo,
      status,
    } = form.getFieldsValue();

    setFilters([
      {
        field: "status",
        operator: "eq",
        value: status,
      },
      {
        field: "employeeId",
        operator: "eq",
        value: employeeId,
      },
      {
        field: "revenueEstFrom",
        operator: "eq",
        value: revenueEstFrom,
      },
      {
        field: "revenueEstTo",
        operator: "eq",
        value: revenueEstTo,
      },
      {
        field: "SBAGroupId",
        operator: "eq",
        value: SBAGroupId,
      },
      {
        field: "marketArea",
        operator: "eq",
        value: marketArea,
      },
    ]);
    setOpenSearch(false);
  };

  const onClearFilter = () => {
    form.setFields([
      {
        name: "status",
        value: DEFAULT_KEY,
      },
      {
        name: "employeeId",
        value: DEFAULT_KEY,
      },
      {
        name: "revenueEstFrom",
        value: "",
      },
      {
        name: "revenueEstTo",
        value: "",
      },
      {
        name: "SBAGroupId",
        value: DEFAULT_KEY,
      },
      {
        name: "marketArea",
        value: DEFAULT_KEY,
      },
    ]);

    const { date } = form.getFieldsValue();
    setFilters(
      [
        ...initFilter,
        ...((date
          ? [
              {
                field: "startDate",
                operator: "eq",
                value: formatOnlyDateToUTC(date[0]),
              },
              {
                field: "endDate",
                operator: "eq",
                value: formatOnlyDateToUTC(date[1]),
              },
            ]
          : []) as any),
      ],
      "replace"
    );
    setOpenSearch(false);
  };

  const onExport = async () => {
    const {
      employeeId,
      status,
      revenueEstFrom,
      revenueEstTo,
      SBAGroupId,
      marketArea,
      date: [startDate, endDate],
    } = form.getFieldsValue();
    try {
      const fileDownload: any = await salePiepelineExportFile(
        API_PATH.salePipelineExportFile,
        {
          employeeId,
          marketArea,
          revenueEstFrom: revenueEstFrom ? Number(revenueEstFrom) : undefined,
          revenueEstTo: revenueEstTo ? Number(revenueEstTo) : undefined,
          SBAGroupId,
          endDate: formatOnlyDateToUTC(endDate),
          startDate: formatOnlyDateToUTC(startDate),
          status,
          orderBy: sorter.length ? sorter?.[0]?.field : undefined,
        }
      );
      const blob = b64toBlob(fileDownload);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Onesuite_BaocaoSalesPipeline_${dayjs().format(
        DATE_FORMAT
      )}.xls`;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      toggleModalExport();
    } catch (error: any) {
      notification.error({
        message: "Đã có lỗi xảy ra",
        description: error?.message,
      });
    }
  };

  const toggleModalExport = () => {
    try {
      if (!visibleModalExport) {
        const { date } = form.getFieldsValue();
        if (!date) throw new Error("Bạn phải chọn ngày bắt đầu và kết thúc");

        const [startDate, endDate] = date;
        if (!dataSalePipeline.list?.length)
          throw new Error("Không có dữ liệu để export");

        if (!startDate) throw new Error("Bạn phải chọn ngày bắt đầu");
        if (!endDate) throw new Error("Bạn phải chọn ngày kết thúc");
      }
      setVisibleModalExport((prev) => !prev);
    } catch (error: any) {
      notification.error({
        message: "Đã có lỗi xảy ra",
        description: error?.message,
      });
    }
  };

  useEffect(() => {
    if (!!revenueEstFrom && !!revenueEstTo) {
      form.setFields([
        {
          name: "revenueEstTo",
          errors:
            Number(revenueEstFrom) < Number(revenueEstTo)
              ? undefined
              : [translate("Giá trị Đến phải lớn hơn hoặc bằng giá trị Từ")],
        },
      ]);
    }
  }, [revenueEstFrom, revenueEstTo]);

  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);

  const hiddenButtonClearFilter = useMemo(() => {
    return (
      status === DEFAULT_KEY &&
      marketArea === DEFAULT_KEY &&
      SBAGroupId === DEFAULT_KEY &&
      employeeId === DEFAULT_KEY &&
      !revenueEstFrom &&
      !revenueEstTo
    );
  }, [
    status,
    marketArea,
    SBAGroupId,
    employeeId,
    revenueEstFrom,
    revenueEstTo,
  ]);

  return {
    visibleModalExport,
    formProps,
    businessOpportunityStatusProps,
    marketSelectProps,
    SBAGroupSelectProps,
    employeeSelectProps,
    dataForm: { revenueEstFrom, date },
    saveButtonProps,
    table,
    openSearch,
    currency,
    dataSalePipeline,
    hiddenButtonClearFilter,
    renderStatus,
    translate,
    toggleModalExport,
    onExport,
    onFilter,
    onClearFilter,
    setOpenSearch,
  };
};
