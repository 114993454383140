import {
  Avatar,
  Button,
  Col,
  Icons,
  Modal,
  Popover,
  Row,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { useGetIdentity, useLogout, useTranslate } from "@pankod/refine-core";
import { FC, memo, useRef, useState } from "react";
import { avatarBoxStyles, menuItemAccountStyle, menuItemStyle } from "./styles";
import { UserRoles } from "api/enums";
import { UpdatePassword, UpdatePasswordType } from "components/UpdatePassword";
import LangSelect from "components/LangSelect";
import styled from "styled-components";
import useOnClickOutside from "hooks/useOnClickOutside";

const { CaretDownOutlined } = Icons;
const { Text } = Typography;
const ContainerStyle = styled.div<{ collapsed?: boolean }>`
  height: 48px;
  background: #212a3a;
  line-height: 48px;
  cursor: pointer;
  transition: width 0.1s;
`;

interface Props {
  collapsed: boolean;
  isMobile?: boolean;
  visiblePopoverSetting: boolean;
  togglePopover: () => void;
  onClose: () => void;
}

const Setting: FC<Props> = memo((props) => {
  const { collapsed, isMobile, visiblePopoverSetting, togglePopover, onClose } =
    props;
  const translate = useTranslate();
  const { mutate: logout } = useLogout();
  const { data: user } = useGetIdentity();

  const [visibleLogoutModal, setVisibleLogoutModal] = useState(false);

  const [visibleChangePasswordModal, setVisibleChangePasswordModal] =
    useState<boolean>(false);

  const onLogout = () => {
    logout();
  };

  const settingRef = useRef<any>(null);

  useOnClickOutside({
    ref: settingRef,
    handler: (event) => {
      if (event.target.id !== "menu-setting") onClose();
    },
  });

  return (
    <ContainerStyle collapsed={collapsed}>
      <Popover
        visible={visiblePopoverSetting}
        placement={isMobile ? "topRight" : "rightBottom"}
        style={{ padding: 0, width: 300 }}
        content={
          <div ref={settingRef}>
            <div
              style={{
                padding: "12px 16px",
                columnGap: 12,
              }}
              className="flex items-center"
            >
              <div style={avatarBoxStyles}>
                <Avatar
                  src="/images/default-avatar.png"
                  style={{ width: 40, height: 40 }}
                />
              </div>
              <div>
                {user?.role != UserRoles.User && <div>{user?.role}</div>}
                <div>
                  <Text style={menuItemAccountStyle}>{user?.email}</Text>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "12px 16px",
                columnGap: 12,
              }}
              className="flex justify-between items-center border-t"
            >
              <div>{translate("common.language")}</div>
              <LangSelect />
            </div>
            <Button
              style={menuItemStyle}
              block
              type="link"
              onClick={() => {
                setVisibleChangePasswordModal(true);
              }}
            >
              {translate("pages.logout.changePassword")}
            </Button>
            <Button
              style={menuItemStyle}
              block
              type="link"
              onClick={() => setVisibleLogoutModal(true)}
            >
              {translate("pages.logout.logout")}
            </Button>
          </div>
        }
        trigger="click"
      >
        <div
          style={{ overflow: "hidden" }}
          className="relative"
          id="menu-setting"
          onClick={togglePopover}
        >
          <Button style={{ color: "white" }} type="text">
            <Space>
              <Avatar
                src="/images/default-avatar.png"
                style={{ width: 24, height: 24 }}
              />
              {collapsed && (
                <Text style={{ color: "white" }}>
                  {user?.fullName || translate("common.hello")}
                </Text>
              )}
              <CaretDownOutlined />
            </Space>
          </Button>
          <div id="menu-setting" className="inset-0 absolute" />
        </div>
      </Popover>
      <Modal
        title=""
        visible={visibleLogoutModal}
        onOk={() => onLogout()}
        onCancel={() => setVisibleLogoutModal(false)}
        okText={translate("pages.logout.okAction")}
        cancelText={translate("pages.logout.cancelAction")}
      >
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>
              {translate("pages.logout.logout")}
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text style={{ fontWeight: 500, fontSize: 16 }}>
              {translate("pages.logout.logoutConfirm")}
            </Text>
          </Col>
        </Row>
      </Modal>

      <Modal
        title=""
        visible={visibleChangePasswordModal}
        onOk={() => onLogout()}
        onCancel={() => setVisibleChangePasswordModal(false)}
        okText={translate("pages.changePassword.title")}
        footer={null}
      >
        <Row>
          <Col span={24}>
            <UpdatePassword updateType={UpdatePasswordType.CHANGE_PASSWORD} />
          </Col>
        </Row>
      </Modal>
    </ContainerStyle>
  );
});

export default Setting;
