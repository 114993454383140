import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useTable,
  useNavigation,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  getDefaultSortOrder,
  Space,
  Tag,
  Icons,
  Select,
  Radio,
} from "@pankod/refine-antd";
import { IUser } from "interfaces";
import { EmptyData, SearchBox, ButtonTooltip } from "components";
import { UserStatus } from "api/enums";
import { formatISODateTimeToView } from "utils/commons";
import { useNavigate } from "react-router-dom";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);

  const {
    tableQueryResult: { data: dataTable },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    sorter,
    setSorter,
    setFilters,
  } = useTable<IUser>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "userTenantCreatedDate",
        order: "desc",
      },
    ],
  });

  const onSearch = (value: string) => {
    setCurrent(1);
    if (value) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  const renderStatus = (value: number) => {
    switch (value) {
      case UserStatus.Welcome:
        return <Tag color="processing">{t("users.fields.status.welcome")}</Tag>;

      case UserStatus.Active:
        return <Tag color="success">{t("users.fields.status.active")}</Tag>;

      default:
        return (
          <Tag color="#F0F0F0" style={{ color: "#8C8C8C" }}>
            {t("users.fields.status.deactive")}
          </Tag>
        );
    }
  };

  return (
    <>
      <List
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.users)}
            {...checkCreateHaveHelperText(PATH.users)}
          >
            {t("users.titles.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Space align="start">
            <SearchBox placeholder={t("users.search")} onSearch={onSearch} />
          </Space>
          <div>
            <Radio.Group
              defaultValue={-1}
              buttonStyle="solid"
              onChange={(e) => {
                setCurrent(1);
                setFilters([
                  {
                    field: "userTenantStatus",
                    operator: "eq",
                    value: e?.target?.value === -1 ? "" : e?.target?.value,
                  },
                ]);
              }}
              style={{ display: "block", marginBottom: 16 }}
            >
              <Radio.Button value={-1}>{t("common.all")}</Radio.Button>
              <Radio.Button value={UserStatus.Active}>
                {t("users.fields.status.active")}
              </Radio.Button>
              <Radio.Button value={UserStatus.Welcome}>
                {t("users.fields.status.welcome")}
              </Radio.Button>
              <Radio.Button value={UserStatus.Deactive}>
                {t("users.fields.status.deactive")}
              </Radio.Button>
            </Radio.Group>
          </div>
          <Table
            dataSource={dataTable?.data}
            rowKey="userId"
            locale={{
              emptyText: (
                <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
              ),
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  navigate(`show/${record.userId}`);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: t("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{t("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, f, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field: newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            showSorterTooltip={false}
            scroll={{ x: "auto" }}
          >
            <Table.Column
              key="userId"
              title={t("common.no")}
              render={(_, __, index) => (current - 1) * pageSize + index + 1}
            />
            <Table.Column
              dataIndex="email"
              title={t("users.fields.email")}
              render={(value) => (
                <TextField value={value} style={{ color: "#597EF7" }} />
              )}
              defaultSortOrder={getDefaultSortOrder("email", sorter)}
              sorter
            />

            <Table.Column
              dataIndex="userTenantCreatedDate"
              key="userTenantCreatedDate"
              title={t("users.fields.created")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(
                    value as string,
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                />
              )}
              defaultSortOrder={getDefaultSortOrder(
                "userTenantCreatedDate",
                sorter
              )}
              sorter
            />
            <Table.Column
              dataIndex={["userTenantStatus"]}
              title={t("users.fields.status.title")}
              render={(value) => renderStatus(value)}
            />
          </Table>
        </div>
      </List>
    </>
  );
};
