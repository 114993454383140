import { Card, Descriptions, Table } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";
import { DataText } from "components";
import { ServiceProduct } from "interfaces/ServiceRequest";
import { FC, memo, useContext, useMemo } from "react";
import { ServiceRequestShowContext } from "../..";
import { Currency } from "components/Currency";

const ProductList: FC = memo(() => {
  const translate = useTranslate();
  const record = useContext(ServiceRequestShowContext);
  const columns = useMemo<ColumnsType<ServiceProduct>>(
    () => [
      {
        title: translate("common.no"),
        width: 70,
        dataIndex: "id",
        key: "id",
        render: (_, __, index) => {
          return <DataText value={index + 1} />;
        },
      },
      {
        title: translate("products.fields.name"),
        width: 200,
        dataIndex: "productName",
        key: "productName",
        render: (_, record) => {
          return (
            <DataText
              value={`${record?.product.code} - ${record?.product.name}`}
            />
          );
        },
      },
      {
        title: translate("products.fields.partNumber"),
        width: 200,
        dataIndex: "partNumber",
        key: "partNumber",
        render: (_, record) => {
          return <DataText value={record?.partNumber} />;
        },
      },
      {
        title: translate("common.description"),
        width: 250,
        dataIndex: "description",
        key: "description",
        render: (_, record) => {
          return <DataText value={record?.description} />;
        },
      },
      {
        title: translate("common.quantity"),
        width: 100,
        dataIndex: "quantity",
        key: "quantity",
        render: (_, record) => {
          return <Currency showCurrency={false} value={record?.quantity} />;
        },
      },
      {
        title: translate("products.fields.unit"),
        width: 120,
        dataIndex: "product",
        key: "product",
        render: (_, record) => {
          return <DataText value={record?.unit?.name} />;
        },
      },
      {
        title: translate("products.fields.unitPrice"),
        width: 200,
        dataIndex: "totalPrice",
        key: "totalPrice",
        render: (_, record) => {
          return <Currency showCurrency={false} value={record?.unitPrice} />;
        },
      },
      {
        title: translate("common.totalPrice.main"),
        width: 200,
        dataIndex: "totalPrice",
        key: "totalPrice",
        render: (_, record) => {
          return <Currency showCurrency={false} value={record?.totalPrice} />;
        },
      },
    ],
    []
  );

  return (
    <Card>
      <Descriptions
        column={2}
        title={translate("products.titles.list")}
        layout="vertical"
      />
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={record?.serviceRequestView?.serviceRequestViewProducts}
        scroll={{ x: 1400 }}
        style={{ marginLeft: 16 }}
      />
    </Card>
  );
});

export default ProductList;
