import { CSSProperties } from "react";

export const layoutStyles: CSSProperties = {
    backgroundSize: "cover",
};

export const containerStyles: CSSProperties = {
    maxWidth: "450px",
    margin: "auto",
};

export const titleStyles: CSSProperties = {
    textAlign: "center",
    color: "black",
    fontSize: "30px",
    letterSpacing: "-0.04em",
};
