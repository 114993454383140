import { Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import Employee from "interfaces/Employee";
import { FC, memo } from "react";
const { Text } = Typography;

interface Props {
    employees: Employee[];
}
export const EmployeeNameList: FC<Props> = memo((props) => {
    const t = useTranslate();
    const employees = props.employees;

    return (employees?.length ?? 0) > 0  ? (
        <Text>
          {employees.map((el, index: number) => (
            <span key={index}>{`${el?.code} - ${el?.fullName}${
              index !== employees?.length - 1 ? ", " : ""
            }`}</span>
          ))}
        </Text>
      ) : (
        <Text type="secondary">{t("common.emptyText")}</Text>
      ) 
});