import { Spin } from "antd";
import { FC, memo } from "react";

interface Props {
  isLoading?: boolean;
}
const CSpin: FC<Props> = memo((props) => {
  const { isLoading } = props;
  if (!isLoading) return null;
  return (
    <div className="action-loading">
      <Spin />
    </div>
  );
});

export default CSpin;
