import React from "react"
import { Button } from "@pankod/refine-antd"
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useSearchParams } from 'react-router-dom';
import { ExternalLoginInformation } from ".";
import { useTranslate } from "@pankod/refine-core";
import { msalConfig } from "configs/externalAuth";
import { EXTERNAL_LOGIN } from "configs/storage";

export interface IAzureLoginButton {
    loginText?: string,
    onSuccess?: Function,
    onError?: Function,
    page?: "login" | "reset-password",
    config?: any
}
export const AzureLoginButton = (props: IAzureLoginButton) => {
    const [searchParams] = useSearchParams();
    const { accounts, instance, inProgress,  } = useMsal()
    const historyStorage = JSON.parse(localStorage.getItem(EXTERNAL_LOGIN) || "{}")
    const loggedAccount = accounts && accounts.length !== 0 ? accounts.find(a => a.homeAccountId === historyStorage.homeAccountId) : null
    
    
    const isCallBack = searchParams.get('callback') !== null
    const t = useTranslate()

    const {
        onSuccess,
        page = 'login' } = props

    React.useEffect(() => {
        if (inProgress === 'none' && isCallBack) {
            handleSuccessLogin()
        }
    }, [inProgress])

    const onLogin = () => {
        instance.loginRedirect({ scopes: msalConfig.scopes })
    }

    const handleSuccessLogin = () => {
        const accessTokenRequest: any = {
            scopes: msalConfig.scopes,
            account: loggedAccount,
        };
        instance.acquireTokenSilent(accessTokenRequest).then((val) => {
            if (onSuccess) {
                const loginInfo: ExternalLoginInformation = {
                    page,
                    type: 'azure',
                    email: loggedAccount?.username,
                    accessToken: val.accessToken,
                    idToken: val.idToken,
                    refreshToken: ''
                }
                onSuccess(loginInfo)
            }
        })
    }


    return (
        <>
            {
                <Button
                    onClick={onLogin}
                    type="primary"
                    icon={<img src='/images/ad-login.png' width={20}
                        style={{ marginRight: 10 }} />}
                    size='large'
                    block>
                    { page === 'login' ? t('login.external.microsoft') : t('account.init.microsoft')}
                </Button>
            }
        </>
    )
}
export default AzureLoginButton
