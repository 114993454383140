import React, { useEffect } from 'react'
import {
  useTranslate,
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useUpdate,
} from '@pankod/refine-core'
import {
  Form,
  Input,
  useForm,
  notification,
  Typography,
  Icons,
  Row,
  Col,
} from '@pankod/refine-antd'

import { IMarketAreaConfig, IPost } from 'interfaces'
import { PATH, API_PATH } from 'configs/path'
import { CreateCustom } from 'components/layout'
import { SelectCustom } from 'components/SelectCustom'

import InputUpper from 'components/InputUpper'
import { showErrorToast } from 'api/common'
import { MoneyInput } from 'components/MoneyInput'
import { CurrencyType } from 'api/enums'
import { isValidCodeField, isValidConfigCodeField, mappingErrorFromApi } from "utils/commons"

const { Text, Paragraph } = Typography
const { TextArea } = Input

export const BusinessTripItemCreate: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate()
  const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>(false)
  const { mutate: mutateCreate, isLoading } = useCreate<IMarketAreaConfig>()
  const { mutate: mutateUpdate } = useUpdate<IMarketAreaConfig>()
  const { form, formProps, saveButtonProps } = useForm<IPost>()
  const { show, list } = useNavigation()

  const isEdit = !!formProps.initialValues?.id
  const record = formProps.initialValues

  const onSubmit = () => {
    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: isEdit
            ? translate('common.config.editSuccess')
            : translate('common.config.createSuccess'),
        })
        show(PATH.configBusinessTripItems, res?.data?.id)
      },
      onError: (error: any) => {
        setVisibleShowModal(false)
        const errorFieldMapping: any = {
          ER0130: 'name',
          ER051: 'code'
        }
        mappingErrorFromApi(error, form, errorFieldMapping)
      },
    }

    const dataSend = {
      resource: PATH.configBusinessTripItems,
      values: {
        code: form.getFieldValue('code').trim(),
        name: form.getFieldValue('name').trim(),
        unitId: form.getFieldValue('unitId'),
        costId: form.getFieldValue('costId'),
      },
      id: '',
    }

    if (isEdit) {
      dataSend.id = formProps.initialValues?.id || ''
      mutateUpdate(dataSend, handleResponse)
    } else {
      mutateCreate(dataSend, handleResponse)
    }
  }
  return (
    <CreateCustom
      {...props}
      saveButtonProps={{
        ...saveButtonProps,
        title: isEdit ? translate('common.saveEdit') : translate('buttons.add')
    }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      confirmModalProps={{
        title: isEdit
          ? translate('common.config.editConfirm')
          : translate('businessTripItems.createConfirm'),
        description: isEdit ? translate('common.config.editConfirmDesc') : '',
      }}
      title={
        isEdit
          ? <Text style={{marginBottom: 0}}>{translate("businessTripItems.titles.edit")}: <Text className="primary">{record?.name}</Text></Text>
          : translate('businessTripItems.titles.create')
      }
      cancelModalProps={{
        okButtonProps: {
          onClick: () => {
            isEdit
              ? show(PATH.configBusinessTripItems, record?.id)
              : list(PATH.configBusinessTripItems)
          },
          danger: true,
        },
        cancelButtonProps: {},
      }}
      saving={isLoading}
    >
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout="vertical"
        labelCol={{ span: 10 }}
      >
        <div style={{ marginBottom: 12 }}>
          <Text style={{ fontWeight: 500, fontSize: 16 }}>
            {translate('common.titles.detail')}{' '}
          </Text>
        </div>
        <Row gutter={12} style={{ width: '100%' }}>
            <Col span={12}>
                <Form.Item
                    label={translate('businessTripItems.fields.code')}
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: translate('errors.ER005'),
                      },
                      {
                        max: 20,
                        message: translate('errors.ER014', { max: '20' }),
                      },
                      {
                        validator: async (_, value) => {
                          if (!value) return;
                          if (!isValidConfigCodeField(value)) {
                            return Promise.reject(translate("errors.ER0139"))
                          }
                        },
                      },
                    ]}
                >
                    <InputUpper
                      placeholder={translate('businessTripItems.fields.codePlaceholder')}
                      disabled={isEdit}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                label={translate('businessTripItems.fields.name')}
                name="name"
                rules={[
                    {
                    whitespace: true,
                    required: true,
                    message: translate('errors.ER005'),
                    },
                    {
                    max: 100,
                    message: translate('errors.ER014', { max: '100' }),
                    },
                ]}
                >
                  <Input placeholder={translate('businessTripItems.fields.namePlaceholder')} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                label={translate('businessTripItems.fields.cost')}
                name="costId"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: translate('errors.ER005'),
                  },
                ]}
                initialValue={record?.cost?.id}
                >
                  <SelectCustom
                    optionLabel="label"
                    optionValue="value"
                    resource={API_PATH.configCosts + '/dropdownlist'}
                    label={`${record?.cost?.code} - ${record?.cost?.name}`}
                    placeholder={translate('businessTripItems.fields.costPlaceholder')}
                    formatData={(r: any) => ({
                      ...r,
                      label: `${r.code} - ${r.label}`,
                    })}
                    allowClear
                  />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label={translate('businessTripItems.fields.unit')}
                    name="unitId"
                    rules={[{
                        whitespace: true,
                        required: true,
                        message: translate('errors.ER005'),
                    }]}
                    initialValue={record?.unit?.id}
                >
                    <SelectCustom
                      optionLabel="label"
                      optionValue="value"
                      resource={API_PATH.configUnits + '/dropdownlist'}
                      placeholder={translate('products.placeholder.unit')}
                      label={record?.unit?.name}
                      allowClear
                    />
                </Form.Item>
            </Col>
        </Row>
      </Form>
    </CreateCustom>
  )
}
