import { useForm, useSelect } from "@pankod/refine-antd";
import { useTable } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import Customer, { CustomerStatus } from "interfaces/Customer";
import { MarketStatus } from "interfaces/MarketArea";
import { RegionStatus } from "interfaces/Region";
import { CSSProperties, useCallback, useState } from "react";

interface FilterForm {
  region: string;
  marketArea: string;
  status: CustomerStatus;
}

export const useController = () => {
  const { form, formProps } = useForm<FilterForm>();
  const table = useTable<any>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const { selectProps: areaSelectProps } = useSelect({
    resource: API_PATH.regionDropdownList,
    optionValue: "value",
    optionLabel: "label",
    filters: [
      { field: "regionStatus", operator: "eq", value: RegionStatus.ALL },
    ],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: marketProps } = useSelect({
    resource: API_PATH.marketDropdownList,
    optionValue: "id",
    optionLabel: "name",
    filters: [
      { field: "marketAreaStatus", operator: "eq", value: MarketStatus.ALL },
    ],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const [visibleModalAssign, setVisibleModalAssign] = useState(false);

  const toggleModalAssign = () => setVisibleModalAssign((prev) => !prev);

  const [currentRecord, setCurrentRecord] = useState<Customer>();

  const renderStatus = useCallback((value: CustomerStatus) => {
    let agr: { color: string; style?: CSSProperties } = { color: "success" };
    switch (value) {
      case CustomerStatus.DRAFT:
        agr.color = "warning";
        break;
      case CustomerStatus.UNASSIGNED:
        agr.color = "processing";
        break;
      case CustomerStatus.ASSIGNED:
        agr.color = "success";
        break;
      default:
        agr.color = "success";
        break;
    }

    return agr;
  }, []);

  return {
    table,
    formProps,
    areaSelectProps,
    marketProps,
    form,
    visibleModalAssign,
    currentRecord,
    renderStatus,
    toggleModalAssign,
    setCurrentRecord,
  };
};
