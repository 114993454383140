import { Icons, Typography, notification, useForm } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useCreate,
  useGetIdentity,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { CreateCustom } from "components/layout";
import { FC, createContext, memo, useState } from "react";
import ServiceRequestForm from "./components/Form";
import IServiceRequest, {
  ServiceRequestData,
  ServiceRequestType,
} from "interfaces/ServiceRequest";
import { PATH } from "configs/path";
import { formatOnlyDateToUTC, mappingErrorFromApi } from "utils/commons";
import { UserIdentity } from "api/types";
import { useParams } from "@pankod/refine-react-router-v6";

export interface IForm {
  caseId: string | null;
  name: string;
  description: string;
  listServiceRequestProduct: {
    [key: string]: {
      id: string;
      productId: string;
      unitId: string;
      partNumber: string;
      description: string;
      quantity: number;
      unitPrice: number;
    };
  };
  listServiceRequestDocument: { fileId: string }[];
  serviceRequestTypeId: string | null;
  serviceRequestTypeCode: ServiceRequestType;
  requestDate: Date;
  requestCompletionDate: Date;
}

interface State {
  initialValues: Partial<ServiceRequestData>;
  isEdit: boolean;
}

const { Text } = Typography;

export const ServiceRequestFormContext = createContext<State>({
  initialValues: {},
  isEdit: false,
});

const ServiceRequestCreate: FC<IResourceComponentsProps> = memo((props) => {
  const translate = useTranslate();
  const { show } = useNavigation();
  const { id } = useParams();
  const { data: user } = useGetIdentity<UserIdentity>();
  const { employee } = user || {};
  const { form, formProps, saveButtonProps, formLoading } = useForm<
    IServiceRequest,
    any,
    IForm
  >();

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreate<
    { id: string },
    any
  >();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();

  const [visibleModalConfirmCreate, setVisibleShowModalConfirm] =
    useState(false);

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleShowModalConfirm(isOpen);

  const isEdit = !formLoading && !!id;

  const onSubmit = (isDraft?: boolean) => () => {
    const dataForm = form.getFieldsValue();
    const listServiceRequestDocument =
      dataForm?.listServiceRequestDocument?.map((doc) => ({
        fileId: doc.fileId,
      }));

    const listServiceRequestProduct = Object.values(
      dataForm?.listServiceRequestProduct || {}
    );

    const dataToRequest = {
      resource: PATH.serviceRequests,
      values: {
        ...dataForm,
        departmentId: employee?.department?.id,
        employeeId: employee?.employee?.id,
        requestCompletionDate: formatOnlyDateToUTC(
          dataForm.requestCompletionDate
        ),
        listServiceRequestDocument,
        listServiceRequestProduct,
      },
      metaData: {
        type: isDraft ? "draft" : "",
      },
      id: id || "",
    };

    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: translate(
            isEdit
              ? isDraft
                ? translate("serviceRequests.result.saveSuccess")
                : translate("serviceRequests.result.saveSubmitSuccess")
              : isDraft
              ? translate("serviceRequests.result.saveDraftSuccess")
              : translate("serviceRequests.result.saveSubmitSuccess")
          ),
        });
        show(PATH.serviceRequests, res?.data?.id);
      },
      onError: (error: any) => {
        toggleModalConfirm(false);
        const errorFieldMapping: any = {};
        mappingErrorFromApi(error, form, errorFieldMapping);
      },
    };

    if (isEdit) {
      mutateUpdate(dataToRequest, handleResponse);
    } else {
      mutateCreate(dataToRequest, handleResponse);
    }
  };

  const validateDraft = async () => {
    try {
      await form.validateFields();
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.resolve(false);
    }
  };

  return (
    <ServiceRequestFormContext.Provider
      value={{
        initialValues: formProps.initialValues
          ?.objectData as ServiceRequestData,
        isEdit,
      }}
    >
      <CreateCustom
        {...props}
        title={
          isEdit ? (
            <Text style={{ marginBottom: 0 }}>
              {translate("serviceRequests.titles.update")}:{" "}
              <Text className="primary">{formProps?.initialValues?.name}</Text>
            </Text>
          ) : (
            translate("serviceRequests.titles.createNew")
          )
        }
        // isEdit
        //       ? isDraft
        //         ? "Lưu yêu cầu dịch vụ thành công."
        //         : "Lưu và chuyển yêu cầu dịch vụ thành công."
        //       : isDraft
        //       ? "Lưu nháp yêu cầu dịch vụ thành công."
        //       : "Lưu và chuyển yêu cầu dịch vụ thành công."
        onSubmit={onSubmit()}
        visibleShowModal={visibleModalConfirmCreate}
        setVisibleShowModal={toggleModalConfirm}
        saving={isLoadingCreate || isLoadingUpdate}
        confirmModalProps={{
          title: translate("serviceRequests.confirm.saveSubmit"),
        }}
        saveButtonProps={{
          ...saveButtonProps,
          title: translate("common.saveNSubmit"),
          icon: isEdit && <Icons.SaveOutlined />,
        }}
        isLoading={formLoading}
        contentStyles={{ background: "transparent", padding: 0 }}
        bodyStyle={{ padding: 0 }}
        onDraft={onSubmit(true)}
        draftButtonProps={{
          title: translate(isEdit ? "common.saveEdit" : "common.saveDraft"),
          disabled: formLoading,
        }}
        draftModalProps={{
          title: translate(
            isEdit
              ? "serviceRequests.confirm.save"
              : "serviceRequests.confirm.saveDraft"
          ),
        }}
        validateDraft={validateDraft}
      >
        <ServiceRequestForm
          formProps={formProps}
          onFinishedFormValidate={() => toggleModalConfirm(true)}
          form={form}
        />
      </CreateCustom>
    </ServiceRequestFormContext.Provider>
  );
});

export default ServiceRequestCreate;
