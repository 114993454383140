import { useTranslate } from "@pankod/refine-core";
import { Col, Form, Row, Typography } from "antd";
import { SelectCustom } from "components";
import { API_PATH } from "configs/path";
import { memo } from "react";
import { IForm } from "../../..";

const { Title } = Typography;
const { useFormInstance, useWatch } = Form;
const Commercial = memo(() => {
  const form = useFormInstance<IForm>();
  const translate = useTranslate();
  const employeeEmail = useWatch("employeeEmail", form);
  const employeePhone = useWatch("employeePhone", form);

  const onChangeEmployee = (_: any, __: any, option: any) =>
    form.setFieldsValue({
      commercialEmployeeTitleId: option?.title?.id,
      employeeEmail: option?.emailUser,
      employeePhone: option?.phoneNumber,
    });

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Thông tin bộ phận Commercial")}</Title>
      <Row gutter={{ xs: 8, sm: 24 }} style={{ paddingLeft: 16 }}>
        <Col span={12}>
          <Form.Item
            name="commercialEmployeeId"
            label="Nhân viên"
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },
            ]}
          >
            <SelectCustom
              placeholder="Tìm kiếm nhân viên"
              resource={API_PATH.quotationsEmloyeeDropdownlist}
              optionLabel="label"
              optionValue="id"
              allowClear
              formatData={(r: any) => ({
                ...r,
                label: `${r?.code} - ${r?.fullName}`,
              })}
              onChangeOption={onChangeEmployee}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="commercialEmployeeTitleId"
            label="Chức vụ"
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },
            ]}
          >
            <SelectCustom
              placeholder="Chọn chức vụ"
              resource={API_PATH.employeeTitlesDropdownList}
              optionLabel="label"
              optionValue="value"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="employeeEmail" label="Email">
            {employeeEmail}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="employeePhone" label="Số điện thoại">
            {employeePhone}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
});

export default Commercial;
