import { TextField } from "@pankod/refine-antd";
import { DataText } from "components/DataText";
import { CSSProperties } from "react";
import { formatISODateTimeToView, patternDatatime } from "utils/commons";

interface DateTimeTextFieldProps {
    value?: Date,
    format?: string,
    defaultValue?: string
    style?: CSSProperties
}

export const DateTimeFieldCustom = ({value, style, defaultValue = '-', format = patternDatatime}: DateTimeTextFieldProps) => {
    if (!value) {
        return <DataText value={value} defaultValue={defaultValue} />
    }
    
    return <TextField style={style} value={formatISODateTimeToView(value.toString(), format)} />
}