import { Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { ApprovalServiceRequest } from "components/ApprovalServiceRequest";
import CaseInfo from "components/CaseInfo";
import { InfomationStyle } from "components/StyleComponent";
import { PATH } from "configs/path";
import { EstimateObjectData, TabKey } from "interfaces/InternalEstimate";
import { FC, createContext, memo, useMemo } from "react";
import DetailContractNumber from "../components/detailContractNumber";
import GeneralSettlementContracts from "./general";
import SubmitionInfomationSettlement from "./information";

const { Text } = Typography;

export const SettlementsContractShow: FC<IResourceComponentsProps> = (props) => {
    const translate = useTranslate();
    const headerTabs = useMemo(
        () => [
            {
                name: translate("tab.overview.information"),
                key: TabKey.INFO,
            },
            {
                name: translate("tab.overview.detailSettlementContracts"),
                key: TabKey.ESTIMATE_INFO,
            },
            {
                name: translate("approval.history"),
                key: TabKey.HISTORY,
                isActive: false,
                hidden: false,
            },
        ],
        []
    );

    return (
        <ApprovalServiceRequest 
            {...props}
            headerTabs={headerTabs}
            resource={PATH.settlementContracts}
            title={(record: any) => (
                <>
                    {translate("settlements.contract.name")}:{" "}
                    <Text className="primary">{record?.objectData?.case?.name}</Text>
                </>
            )}
            renderInformation={(record: any, tab: TabKey) => (
                <SettlementsContractShowDetail record={record} tab={tab} />
            )}
            onApprovalSuccess={() => {}}
            />
    )
};

interface RecordProps {
    record: any;
    tab: TabKey;
}

interface State {
    record: Partial<EstimateObjectData>;
}
  
const SettlementRecordContext = createContext<State>( {} as State);
export const SettlementsContractShowDetail: FC<RecordProps> = memo(({record, tab}) => {
    return (
        <SettlementRecordContext.Provider value={
            {
                record,
            }
        }>
            <InfomationStyle hidden={tab !== TabKey.INFO}>
                <CaseInfo  {...record?.objectData?.case}/>
                <GeneralSettlementContracts {...record?.objectData}  />
                <SubmitionInfomationSettlement {...record?.objectData} />
            </InfomationStyle>
            <InfomationStyle hidden={tab !== TabKey.ESTIMATE_INFO} >
                    <DetailContractNumber plans={record?.objectData?.financialIndicator} showButtonGetSettlementNumber={false}/>
            </InfomationStyle>
        </SettlementRecordContext.Provider>
    )
});
