import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useNavigation,
  useUpdate,
} from "@pankod/refine-core";
import {
  Typography,
  Icons,
  Descriptions,
  Space,
  Modal,
  notification,
  useSelect,
} from "@pankod/refine-antd";
import { ShowCustom } from "components/layout";
import usePermissions from "hooks/permission";
import { API_PATH, PATH } from "configs/path";
import { ButtonTooltip, DataText } from "components";
import { CurrencyType } from "api/enums";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { Currency } from "components/Currency";
import { ConfigStatusTag } from "components/StatusTag";
import { ConfigDisplayText } from "components/ConfigDisplayText";
import { showErrorToast } from "api/common";
import { IProduct } from "interfaces";

const { Text } = Typography;

export const ProductShow: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { edit } = useNavigation();
  const { checkEditHaveHelperText } = usePermissions();

  const { queryResult } = useShow<IProduct>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { mutate } = useUpdate();

  const { selectProps: categorySelectProps } = useSelect({
    resource: API_PATH.productCategoryDropdownList,
    optionLabel: "value",
    optionValue: "id",
  });

  const { options: categoryOption } = categorySelectProps;

  return (
    <ShowCustom
      {...props}
      isLoading={isLoading}
      title={
        <Text style={{ marginBottom: 0 }}>
          {t("products.titles.show")}:{" "}
          <span className="primary">{record?.name}</span>
        </Text>
      }
      breadcrumbText={record?.name}
      headerButtons={
        <Space>
          <ButtonTooltip
            icon={<Icons.EditOutlined />}
            onClick={() => edit(PATH.configProducts, record?.id!)}
            {...checkEditHaveHelperText(PATH.configProducts, !record?.status)}
          >
            {t("buttons.edit")}
          </ButtonTooltip>
          {record?.status ? (
            <ButtonTooltip
              danger
              type="primary"
              icon={<Icons.CloseCircleOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: t("products.message.deactiveConfirm"),
                  content: t("products.message.deactiveConfirmDesc"),
                  okText: t("buttons.confirm"),
                  cancelText: t("buttons.reject"),
                  okButtonProps: {
                    danger: true,
                  },
                  onOk: () => {
                    mutate(
                      {
                        resource: PATH.configProducts,
                        id: record?.id || "",
                        metaData: {
                          type: "/deactivate",
                        },
                        values: {},
                      },
                      {
                        onSuccess: () => {
                          notification.success({
                            message: t("common.config.deactiveSuccess"),
                          });
                        },
                        onError: (error) => {
                          showErrorToast(error);
                        },
                      }
                    );
                  },
                });
              }}
              {...checkEditHaveHelperText(PATH.configProducts)}
            >
              {t("buttons.deactivate")}
            </ButtonTooltip>
          ) : (
            <ButtonTooltip
              type="primary"
              icon={<Icons.CheckCircleOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: t("products.message.activeConfirm"),
                  okText: t("buttons.confirm"),
                  cancelText: t("buttons.reject"),
                  onOk: () => {
                    mutate(
                      {
                        resource: PATH.configProducts,
                        id: record?.id || "",
                        metaData: {
                          type: "/activate",
                        },
                        values: {},
                      },
                      {
                        onSuccess: () => {
                          notification.success({
                            message: t("common.config.activeSuccess"),
                          });
                        },
                        onError: (error) => {
                          showErrorToast(error);
                        },
                      }
                    );
                  },
                });
              }}
              {...checkEditHaveHelperText(PATH.configProducts)}
            >
              {t("buttons.active")}
            </ButtonTooltip>
          )}
        </Space>
      }
    >
      <Descriptions
        column={3}
        title={t("common.titles.detail")}
        layout="vertical"
      >
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("products.fields.code")}
        >
          <DataText value={record?.code} />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("products.fields.name")}
        >
          <DataText value={record?.name} />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("products.fields.status")}
        >
          <ConfigStatusTag status={record?.status} />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("Category")}
        >
          <DataText
            value={
              categoryOption?.find((c) => c.value === record?.category)?.label
            }
          />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("products.fields.productGroup")}
        >
          <ConfigDisplayText
            value={record?.productGroup?.name}
            status={record?.productGroup?.status}
          />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("products.fields.unit")}
        >
          <ConfigDisplayText
            value={record?.unit?.name}
            status={record?.unit?.status}
          />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("products.fields.price")}
        >
          {record?.unitPrice === null ? (
            <DataText value={record?.unitPrice} />
          ) : (
            <Currency
              value={record?.unitPrice}
              currencyType={CurrencyType.VND}
            />
          )}
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          span={2}
          label={t("products.fields.description")}
        >
          <DataText value={record?.description} className="line-break" />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("common.updatedDate")}
        >
          {record?.updatedDate && (
            <DateTimeFieldCustom value={record?.updatedDate} />
          )}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          labelStyle={{ fontWeight: 500 }}
          label={t("common.updatedName")}
        >
          <DataText className="primary" value={record?.updatedName} />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("common.createdDate")}
        >
          {record?.createdDate && (
            <DateTimeFieldCustom value={record?.createdDate} />
          )}
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("common.createdName")}
          span={2}
        >
          <DataText className="primary" value={record?.createdName} />
        </Descriptions.Item>
      </Descriptions>
    </ShowCustom>
  );
};
