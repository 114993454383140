import { FC, memo, useContext, useMemo } from "react";
import { Form, Typography } from "@pankod/refine-antd";
import { Currency, generateCurrency } from "components/Currency";
import { CurrencyType } from "api/enums";
import { MoneyInput } from "components/MoneyInput";
import { formatNumber, isEmpty } from "utils/commons";
import { cloneDeep } from "lodash";
import "./styles.less";
import CSpin from "components/CSpin";
import { ContractEstimatedFormContext, IForm } from "../..";
import { TPlanItem } from "interfaces/ContractEstimate";
import { useTranslate } from "@pankod/refine-core";

const { Text } = Typography;

const { useWatch, useFormInstance } = Form;
const EstimateInfo: FC = memo(() => {
  const translate = useTranslate();
  
  const {
    planState: [plans, setPlans],
    isLoadingFetchFinancial,
    onFetchFiancialIndicator,
    getEstimateValueError,
  } = useContext(ContractEstimatedFormContext);
  const form = useFormInstance<IForm>();
  const caseId = useWatch("caseId", form);
  const contractEstimatedProducts = useWatch("contractEstimatedProducts", form);

  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);

  const onChangeValue = (index: number) => (value: any) => {
    const temp = cloneDeep(plans);
    if (value == temp[index].value) return;

    temp[index].value = value;
    setPlans(temp);
    onFetchFiancialIndicator({
      caseId,
      products: contractEstimatedProducts,
      plansRequest: temp,
    });
  };

  const renderPlans = () => {
    return plans?.map((d, index) => {
      const { isStrong } = d;

      return (
        <>
          <div className={`row col-active`} key={d.code}>
            <div className={`col ${isStrong ? "font-bold" : ""}`}>{d.code}</div>
            <div className={`col ${isStrong ? "font-bold" : ""}`}>{d.name}</div>
            <div className="col text-right" style={{ whiteSpace: "nowrap" }}>
              {d?.isPersonInput ? (
                <MoneyInput
                  currencyType={CurrencyType.VND}
                  value={isEmpty(d.value) ? "" : d.value}
                  onChange={onChangeValue(index)}
                  allowDecimal
                />
              ) : (
                <Currency
                  showCurrency={false}
                  value={d.value}
                  customValueShow={formatNumber}
                  strong={isStrong}
                />
              )}

              <div className="ant-form-item-explain-error">
                {d.errorMessage || getEstimateValueError(d)}
              </div>
            </div>
            <div className={`col text-right ${isStrong ? "font-bold" : ""}`}>
              <Currency
                showCurrency={false}
                value={d.businessPlanValue}
                customValueShow={formatNumber}
                strong={isStrong}
              />
            </div>
            <div
              className="col text-right"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              <Currency
                value={d?.percentage * 100}
                showCurrency={false}
                customValueShow={(value) => `${formatNumber(value || 0, 3)}%`}
                strong={isStrong}
                hidden={index === 0}
              />
            </div>
            <div className={`col text-right ${isStrong ? "font-bold" : ""}`}>
              <Currency
                showCurrency={false}
                value={d.margin}
                customValueShow={formatNumber}
                strong={isStrong}
              />
            </div>
            <div
              className={`col ${isStrong ? "font-bold" : ""}`}
              dangerouslySetInnerHTML={{ __html: d?.note }}
            ></div>
          </div>
        </>
      );
    });
  };

  return (
    <>
      <div className="flex items-center justify-end">
        <Text>
          {translate("products.fields.unit")}:{" "}
          <Text style={{ color: currency.unitColor, fontWeight: "bold" }}>
            {currency.unit}
          </Text>
        </Text>
      </div>
      <div style={{ overflow: "auto", position: "relative" }}>
        <section>
          <header>
            <div style={{ width: 50 }} className="col col-head">
              {translate("common.no")}
            </div>
            <div className="col col-head" style={{ minWidth: 250 }}>
              Chỉ tiêu
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              Dự toán
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              PAKD
            </div>
            <div className="col col-head text-right" style={{ minWidth: 100 }}>
              Tỷ lệ
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              Chệnh lệch
            </div>
            <div className="col col-head" style={{ minWidth: 200 }}>
              Ghi chú
            </div>
          </header>
          {renderPlans()}
        </section>
        <CSpin isLoading={isLoadingFetchFinancial} />
      </div>
    </>
  );
});

export default EstimateInfo;

interface TableShowProps {
  plans: TPlanItem[];
}

export const EstimateInfoShow: FC<TableShowProps> = memo(({ plans }) => {
  const translate = useTranslate();

  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);

  const renderPlans = () => {
    return plans?.map((d, index) => {
      const { isStrong } = d;

      return (
        <>
          <div className={`row ${isStrong ? "" : "col-active"}`} key={d.code}>
            <div className={`col ${isStrong ? "font-bold" : ""}`}>{d.code}</div>
            <div className={`col ${isStrong ? "font-bold" : ""}`}>{d.name}</div>
            <div className="col text-right" style={{ whiteSpace: "nowrap" }}>
              <Currency
                showCurrency={false}
                value={d.value}
                customValueShow={formatNumber}
                strong={isStrong}
              />
            </div>
            <div className="col text-right" style={{ whiteSpace: "nowrap" }}>
              <Currency
                showCurrency={false}
                value={d.businessPlanValue}
                customValueShow={formatNumber}
                strong={isStrong}
              />
            </div>
            <div
              className="col text-right"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              <Currency
                value={d?.percentage * 100}
                showCurrency={false}
                customValueShow={(value) => `${formatNumber(value || 0, 3)}%`}
                strong={isStrong}
                hidden={index === 0}
              />
            </div>
            <div className="col text-right" style={{ whiteSpace: "nowrap" }}>
              <Currency
                showCurrency={false}
                value={d.margin}
                customValueShow={formatNumber}
                strong={isStrong}
              />
            </div>
            <div
              className={`col ${isStrong ? "font-bold" : ""}`}
              dangerouslySetInnerHTML={{ __html: d?.note }}
            ></div>
          </div>
        </>
      );
    });
  };

  return (
    <>
      <div className="flex items-center justify-end">
        <Text>
          {translate("products.fields.unit")}:{" "}
          <Text style={{ color: currency.unitColor, fontWeight: "bold" }}>
            {currency.unit}
          </Text>
        </Text>
      </div>
      <div style={{ overflow: "auto", position: "relative" }}>
        <section>
          <header>
            <div style={{ width: 50 }} className="col col-head">
              {translate("common.no")}
            </div>
            <div className="col col-head" style={{ minWidth: 250 }}>
              Chỉ tiêu
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              Dự toán
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              PAKD
            </div>
            <div className="col col-head text-right" style={{ minWidth: 100 }}>
              Tỷ lệ
            </div>
            <div className="col col-head text-right" style={{ minWidth: 220 }}>
              Chệnh lệch
            </div>
            <div className="col col-head" style={{ minWidth: 200 }}>
              Ghi chú
            </div>
          </header>
          {renderPlans()}
        </section>
      </div>
    </>
  );
});
