import { useForm, useSelect } from "@pankod/refine-antd";
import { LogicalFilter, useTable } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import { CustomerStatus } from "interfaces/Customer";
import { useMemo, useState } from "react";

interface FilterForm {
  region: string;
  marketArea: string;
  status: CustomerStatus;
}

export const useController = () => {
  const { form, formProps } = useForm<FilterForm>();
  const table = useTable<any>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const { filters } = table;

  const [visibleModalDelete, setVisibleModalDelete] = useState(false);

  const toggleModalDelete = () => setVisibleModalDelete((prev) => !prev);

  const [currentRecord, setCurrentRecord] = useState<any>();

  const { selectProps: customerSelectProps } = useSelect({
    resource: API_PATH.customerDropdownlistForAssignExploiting,
    optionValue: "id",
    optionLabel: "name",
    // filters: [{ field: "status", operator: "eq", value: 2 }],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const { selectProps: productSelectProps } = useSelect({
    resource: API_PATH.productDropdownList,
    optionValue: "id",
    optionLabel: "name",
    // filters: [{ field: "status", operator: "eq", value: 2 }],
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const filterByCustomer = useMemo(
    () => !!filters.find((f) => (f as LogicalFilter).field === "customerId"),
    [filters]
  );

  const filterByProduct = useMemo(
    () => !!filters.find((f) => (f as LogicalFilter).field === "productId"),
    [filters]
  );

  return {
    table,
    formProps,
    form,
    visibleModalDelete,
    currentRecord,
    setCurrentRecord,
    toggleModalDelete,
    customerSelectProps,
    productSelectProps,
    filterByProduct,
    filterByCustomer,
  };
};
