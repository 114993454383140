import { Icons, Typography } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import { ButtonTooltip } from "components";
import { ShowCustom } from "components/layout";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import IContract, { ContractStatusCode } from "interfaces/Contract";
import {
  CSSProperties,
  FC,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import General from "./components/General";
import ContractInfo from "./components/ContractInfo";
import ServiceScope from "./components/ServiceScope";
import PaymentProgress from "./components/PaymentProgress";
import InvoicePlan from "./components/InvoicePlan";
import ContractDocument from "./components/ContractDocument";
import { IHeaderTab } from "interfaces";
import ContractCommitment from "./components/ContractCommitment";
import { useNavigate } from "@pankod/refine-react-router-v6";
import CaseInfo from "./components/CaseInfo";
import ContractImplementProgress from "./components/ContractImplementProgress";

const { Text } = Typography;
enum TabKey {
  CONTRACT = "contract",
  CONTRACT_COMMITMENT = "contract-commitment",
}

export const getStatus = (
  status: ContractStatusCode = ContractStatusCode.Draft
) => {
  let agr: { color: string; style?: CSSProperties } = { color: "success" };

  switch (status) {
    case ContractStatusCode.Draft:
      agr.color = "warning";
      break;
    case ContractStatusCode.InProgress:
      agr.color = "blue";
      break;
    case ContractStatusCode.Signed:
      agr.color = "success";
      break;
  }

  return agr;
};

export const ContractShowConText = createContext<Partial<IContract>>({});

const ContractShow: FC<IResourceComponentsProps> = (props) => {
  const navigate = useNavigate();
  const { queryResult } = useShow<IContract>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const translate = useTranslate();
  const { edit } = useNavigation();
  const { checkEditHaveHelperText, checkView } = usePermissions();
  const [activeTab, setActiveTab] = useState<any>(TabKey.CONTRACT);

  const headerTabs: IHeaderTab[] = useMemo(
    () => [
      {
        name: translate("Hợp đồng"),
        key: TabKey.CONTRACT,
        isActive: true,
      },
      {
        name: translate("Ghi nhận hợp đồng"),
        key: TabKey.CONTRACT_COMMITMENT,
        isActive: false,
        hidden:
          !checkView(PATH.contractCommitments) ||
          !record?.hasContractCommitmentTab,
      },
    ],
    [checkView, record]
  );

  const onChangeTab = (activeKey: any) => {
    setActiveTab(activeKey);
  };

  useEffect(() => {
    if (
      activeTab === TabKey.CONTRACT_COMMITMENT &&
      !checkView(PATH.contractCommitments)
    )
      navigate(PATH.noPermissionPage);
  }, [activeTab, checkView]);

  return (
    <ContractShowConText.Provider value={record || {}}>
      <ShowCustom
        {...props}
        tabs={headerTabs}
        onChangeTab={onChangeTab}
        isLoading={isLoading}
        breadcrumbText={record?.name}
        title={
          <Text style={{ marginBottom: 0 }}>
            {translate("Hợp đồng")}:{" "}
            <Text className="primary">{record?.name}</Text>
          </Text>
        }
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.EditOutlined />}
            onClick={() => edit(PATH.contracts, record?.id!)}
            {...checkEditHaveHelperText(PATH.contracts)}
          >
            {translate("actions.edit")}
          </ButtonTooltip>
        }
        contentStyles={{
          padding: 0,
          background: "transparent",
        }}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div
          style={{
            display: activeTab === TabKey.CONTRACT ? "flex" : "none",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <CaseInfo />
          <General />
          <ContractInfo />
          <ServiceScope />
          <ContractImplementProgress />
          <PaymentProgress />
          <InvoicePlan />
          <ContractDocument />
        </div>
        {activeTab === TabKey.CONTRACT_COMMITMENT && <ContractCommitment />}
      </ShowCustom>
    </ContractShowConText.Provider>
  );
};

export default ContractShow;
