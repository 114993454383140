import { Card, Col, Form, Row, Typography } from "@pankod/refine-antd";
import { SelectCustom } from "components";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import { FC, memo, useContext } from "react";

import { useOne, useTranslate } from "@pankod/refine-core";
import { useSearchParams } from "@pankod/refine-react-router-v6";
import CCollapse, { CaseInfoPanel } from "components/CCollapse";
import { API_PATH } from "configs/path";
import { BusinessPlanContext, IForm } from "../../..";

const { Title } = Typography;
const { useWatch, useFormInstance } = Form;

const CaseInfo: FC = memo(() => {
  const {
    canEditCaseId,
    initialValues,
    isEdit,
    onChangeCase: handleChangeCase,
  } = useContext(BusinessPlanContext);
  const translate = useTranslate();
  const form = useFormInstance<IForm>();
  const caseId = useWatch("caseId", form);

  const caseData = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(caseId || ""),
    id: "",
    queryOptions: { enabled: !!caseId },
  });

  const caseDetail = caseData?.data?.data;
  const loadingCase = caseData?.isFetching;

  const onChangeCase = (value: any) => handleChangeCase(value);
  const params = useSearchParams();

  return (
    <Card>
      <Title level={5}>{translate("Thông tin vụ việc")}</Title>
      <div style={{ paddingLeft: 16 }}>
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Form.Item
              label={translate("Vụ việc")}
              className={caseId ? "" : "m-0"}
              name="caseId"
              initialValue={params[0].get("caseId")}
              rules={[{ required: true, message: translate("errors.ER005") }]}
            >
              <SelectCustom
                resource={API_PATH.internalEstimatesCaseDropdownlist}
                optionLabel="name"
                optionValue="id"
                placeholder={translate("Chọn vụ việc")}
                style={{ color: "#000" }}
                disabled={!canEditCaseId}
                allowClear
                label={
                  isEdit
                    ? `${initialValues?.case?.code} - ${initialValues?.case?.name}`
                    : undefined
                }
                onChange={onChangeCase}
              />
            </Form.Item>
          </Col>
        </Row>
        <CCollapse
          hidden={!caseId}
          loading={loadingCase}
          title={translate("Chi tiết vụ việc")}
        >
          <CaseInfoPanel caseInfo={caseDetail} />
        </CCollapse>
      </div>
    </Card>
  );
});

export default CaseInfo;
