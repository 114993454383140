import { CompareDataTypeDecision, SubjectType, WorkflowStepType } from "api/enums";
import { IApprovalStepApprover, IWorkflowNode, IWorkflowNodeData, IStepOption } from "interfaces";
import { Edge, Position } from "reactflow";
import i18n from "i18n";

import { Icons, Space, Typography } from "@pankod/refine-antd";
import { UngroupOutlined, FlagOutlined, SisternodeOutlined } from "@ant-design/icons";
import {
  ITEM_IN_COLLECTION_OPERATORS,
  NUMBER_DATE_OPERATORS,
  OPTIONS_OPERATORS,
  STRING_OPERATORS,
} from "configs/constants";

export const diagramMapping = (steps: IWorkflowNode[], onDeleteNode?: (e: string) => void): IWorkflowNodeData[] => {
  const result: IWorkflowNodeData[] = [];
  if (steps && steps.length !== 0) {
    steps.forEach((step, index) => {
      const nextStep = steps.find((t) => t.id === step.defaultNextStepIdIfAccept);
      const yesStep = steps.find((t) => t.id === step.nextStepIdIfYes);
      const noStep = steps.find((t) => t.id === step.nextStepIdIfNo);
      const beforeStep = steps?.find((t) =>
        [t?.defaultNextStepIdIfAccept, t?.nextStepIdIfYes, t?.nextStepIdIfNo].includes(step.id)
      );

      const data = {
        ...step,
        defaultNextStep: step.defaultNextStepIdIfAccept
          ? {
              label: nextStep?.name ?? "",
              value: step.defaultNextStepIdIfAccept,
              name: nextStep?.name ?? "",
              type: nextStep?.type ?? WorkflowStepType.Normal,
            }
          : undefined,
        defaultYesStep: step.nextStepIdIfYes
          ? {
              label: yesStep?.name ?? "",
              value: step.nextStepIdIfYes,
              name: yesStep?.name ?? "",
              type: yesStep?.type ?? WorkflowStepType.Normal,
            }
          : undefined,
        defaultNoStep: step.nextStepIdIfNo
          ? {
              label: noStep?.name ?? "",
              value: step.nextStepIdIfNo,
              name: noStep?.name ?? "",
              type: noStep?.type ?? WorkflowStepType.Normal,
            }
          : undefined,

        approvalStepApprovers: subjectMapping(step.approvalStepApprovers ?? []),
        onDelNode: onDeleteNode ? () => onDeleteNode(step?.id || "") : undefined,
      };

      const errors = validateNode(data, !!beforeStep);

      const node: IWorkflowNodeData = {
        id: step.id ?? "",
        type: "nodeCustom",
        position: {
          x: step.positionX as number,
          y: step.positionY as number,
        },
        sourcePosition: Position.Left,
        targetPosition: Position.Right,
        data: {
          ...data,
          errors: errors,
        },
      };

      result.push(node);
    });
  }

  return result;
};

export const edgesMapping = (steps: IWorkflowNode[]) => {
  const result: Edge<any>[] = [];
  if (steps && steps.length !== 0) {
    steps.forEach((step, index) => {
      if (step.type === WorkflowStepType.Decision) {
        if (step.nextStepIdIfYes) {
          const edge: Edge = {
            id: `${step.id}-${step.nextStepIdIfYes}`,
            source: step.id || "",
            target: step.nextStepIdIfYes || "",
            label: "Yes",
            labelBgPadding: [8, 4],
            labelBgBorderRadius: 4,
            labelBgStyle: { fill: "#16a34a", color: "#fff" },
          };
          result.push(edge);
        }
        if (step.nextStepIdIfNo) {
          const edge: Edge = {
            id: `${step.id}-${step.nextStepIdIfNo}`,
            source: step.id || "",
            target: step.nextStepIdIfNo || "",
            label: "No",
            labelBgPadding: [8, 4],
            labelBgBorderRadius: 4,
            labelBgStyle: { fill: "#dc2626", color: "#fff" },
          };
          result.push(edge);
        }
      } else if (step.defaultNextStepIdIfAccept) {
        const edge: Edge = {
          id: `${step.id}-${step.defaultNextStepIdIfAccept}`,
          source: step.id || "",
          target: step.defaultNextStepIdIfAccept || "",
        };
        result.push(edge);
      }
    });
  }

  return result;
};

export const subjectMapping = (approvalStepApprovers: IApprovalStepApprover[]) => {
  return approvalStepApprovers?.map((subject) => {
    return {
      ...subject,
      displayName: subject.employeeName || subject.titleName || subject?.caseRoleName || subject.displayName,
      subjectTypeName: subject.titleId
        ? i18n.t("workflows.subjectTypeTitle")
        : subject?.employeeId
        ? i18n.t("workflows.subjectTypeEmployee")
        : i18n.t("workflows.subjectTypeCaseMember"),
      subjectType: subject.titleId
        ? SubjectType.TILLE
        : subject?.employeeId
        ? SubjectType.EMPLOYEE
        : SubjectType.CASE_MEMBER,
      id: subject.titleId || subject.employeeId || subject.caseRoleId,
      value: subject.titleId || subject.employeeId || subject.caseRoleId,
    };
  });
};

export const findNextStep = (steps: IWorkflowNodeData[], id: string): IWorkflowNodeData | undefined => {
  let result = undefined;
  if (steps && steps.length !== 0) {
    result = steps.find((t) => t.id == id);
  }
  return result;
};

const { Text } = Typography;

export const nextStepOptions = (otherSteps: any[], showEnd: (step: IWorkflowNode) => boolean = () => true) => {
  const sortedSteps = [...otherSteps].sort((n1, n2) => {
    const name1 = n1.data.name && n1.data.name.toLowerCase();
    const name2 = n2.data.name && n2.data.name.toLowerCase();
    if (name1 > name2) {
      return 1;
    }
    if (name1 < name2) {
      return -1;
    }
    return 0;
  });

  return sortedSteps
    .filter((t) => t.data.type !== WorkflowStepType.Begin && showEnd(t.data))
    ?.map((t) => {
      const isStart = t?.data?.type === WorkflowStepType.Begin;
      const isEnd = t?.data?.type === WorkflowStepType.End;

      return {
        value: t.id,
        label: (
          <span className="ant-select-selection-item" title={t?.data?.name}>
            <Space>
              {isStart ? <UngroupOutlined /> : isEnd ? <FlagOutlined /> : <SisternodeOutlined />}
              <Text>{t.data?.name}</Text>
            </Space>
          </span>
        ),
        type: t.data.type,
        name: t.data.name,
      };
    });
};

export const validateNode = (step: IWorkflowNode, hasBeforeStep?: boolean): string[] => {
  const nextStep = step?.defaultNextStep;
  const yesStep = step?.defaultYesStep;
  const noStep = step?.defaultNoStep;
  const isStart = step?.type === WorkflowStepType.Begin;
  const isEnd = step?.type === WorkflowStepType.End;
  const isNormal = step?.type === WorkflowStepType.Normal;
  const isDecision = step.type === WorkflowStepType.Decision;

  const errors: string[] = [];

  if (!step.name) {
    errors.push("errors.ER056");
  }

  if (
    isNormal &&
    !step.isLineManagerApprove &&
    (!step.approvalStepApprovers || step.approvalStepApprovers.length == 0)
  ) {
    errors.push("errors.ER057");
  }

  if (isStart && nextStep?.type === WorkflowStepType.End) {
    errors.push("errors.ER082");
  }

  if (
    (isStart && !nextStep?.value) ||
    (isEnd && !hasBeforeStep) ||
    (isNormal && !nextStep?.value && !hasBeforeStep) ||
    (isDecision && (!yesStep?.value || !noStep?.value) && !hasBeforeStep)
  ) {
    errors.push("errors.ER062");
  }

  if (isDecision) {
    const condistions = step?.approvalStepConditionForApprovalStep?.approvalStepConditionItemForApprovalStep || [];

    const isError = condistions?.find(
      (condition) =>
        !condition?.approvalFeatureConditionAttrId || !condition?.compareOperator || !condition?.compareValue
    );

    if (!!isError || !condistions?.length) errors.push("errors.ER0122");
  }

  if (
    (nextStep?.value && !hasBeforeStep && isNormal) ||
    (yesStep?.value && !hasBeforeStep && isDecision) ||
    (noStep?.value && !hasBeforeStep && isDecision) ||
    (!nextStep?.value && hasBeforeStep && isNormal) ||
    (!yesStep?.value && hasBeforeStep && isDecision) ||
    (!noStep?.value && hasBeforeStep && isDecision)
  ) {
    errors.push("errors.ER048");
  }

  return errors;
};

export const renderStepOption = (o: IStepOption) => {
  if (!o.value) return;
  const isStart = o.type === WorkflowStepType.Begin;
  const isEnd = o.type === WorkflowStepType.End;

  return {
    ...o,
    label: (
      <Space>
        {isStart ? <UngroupOutlined /> : isEnd ? <FlagOutlined /> : <SisternodeOutlined />}
        <Text>{o?.name}</Text>
      </Space>
    ),
  };
};

export const classNameSelect = (type?: WorkflowStepType) => {
  let className = "select-node-normal";
  switch (type) {
    case WorkflowStepType.Begin:
      className = "select-node-begin";
      break;
    case WorkflowStepType.End:
      className = "select-node-end";
      break;
    case WorkflowStepType.Decision:
      className = "select-node-decision";
      break;
    default:
      break;
  }

  return className;
};

export const getColorByNodeType = (type: WorkflowStepType = WorkflowStepType.Normal) => {
  let color = "#ADC6FF";
  switch (type) {
    case WorkflowStepType.Begin:
      color = "#FCCE45";
      break;
    case WorkflowStepType.End:
      color = "#A0D911";
      break;
    case WorkflowStepType.Normal:
      color = "#ADC6FF";
      break;
    case WorkflowStepType.Decision:
      color = "#fb923c";
      break;
  }

  return color;
};

export const getIconByNodeType = (type: WorkflowStepType = WorkflowStepType.Normal) => {
  let icon = <Icons.SisternodeOutlined />;
  switch (type) {
    case WorkflowStepType.Begin:
      icon = <Icons.UngroupOutlined />;
      break;
    case WorkflowStepType.End:
      icon = <Icons.FlagOutlined />;
      break;
    case WorkflowStepType.Normal:
      icon = <Icons.SisternodeOutlined />;
      break;
    case WorkflowStepType.Decision:
      icon = <Icons.SisternodeOutlined />;
      break;
  }

  return icon;
};

export const getOperator = (valueType?: CompareDataTypeDecision) => {
  let operatorOptioins: { label: string; value: any }[] = [];
  switch (valueType) {
    case CompareDataTypeDecision.DateTime:
    case CompareDataTypeDecision.Decimal:
    case CompareDataTypeDecision.Percent:
      operatorOptioins = NUMBER_DATE_OPERATORS;
      break;
    case CompareDataTypeDecision.ItemInCollection:
      operatorOptioins = ITEM_IN_COLLECTION_OPERATORS;
      break;
    case CompareDataTypeDecision.Options:
      operatorOptioins = OPTIONS_OPERATORS;
      break;
    case CompareDataTypeDecision.String:
      operatorOptioins = STRING_OPERATORS;
      break;
    case CompareDataTypeDecision.Boolean:
      operatorOptioins = OPTIONS_OPERATORS;
  }

  return operatorOptioins;
};
