import {
  Button,
  Col,
  Divider,
  Row,
  Select,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { WorkflowStepType } from "api/enums";
import { IWorkflowNode, IWorkflowNodeData } from "interfaces";
import React from "react";
import { UngroupOutlined } from "@ant-design/icons";
import { classNameSelect, nextStepOptions } from "../diagramHelper";

interface Props {
  step?: IWorkflowNode;
  otherSteps?: IWorkflowNodeData[];
  onChange: (e: IWorkflowNode) => void;
  onFormChange: () => void;
  isUpdate: boolean;
}
const { Title, Text } = Typography;

const StartNode: React.FC<Props> = ({
  step,
  otherSteps,
  onChange,
  onFormChange,
  isUpdate,
}) => {
  const t = useTranslate();
  const [selectedNextNode, setSelectedNextStep] = React.useState(
    step?.defaultNextStep
  );
  const [nextStep, setNextStep] = React.useState<any>(step?.defaultNextStep);

  const emitOnChangeEvent = () => {
    const newState = { ...step, defaultNextStep: selectedNextNode };

    onChange(newState);
  };

  React.useEffect(() => {
    if (isUpdate) {
      emitOnChangeEvent();
    }
  }, [isUpdate]);

  return (
    <Row style={{ flexDirection: "column", height: "100%" }}>
      <Col flex="auto">
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Text>{t("workflows.fields.stepName")}</Text>
          </Col>
          <Col span={24}>
            <Button
              disabled
              style={{ backgroundColor: "#EDD280", color: "black" }}
              icon={<UngroupOutlined />}
            >
              {t("workflows.fields.startNodeName")}
            </Button>
          </Col>
          <Col span={24}>
            <Title level={5}>{t("workflows.action")}</Title>
          </Col>
          <Col span={24}>
            <Space>
              <Text>{t("workflows.defaultStep")}</Text>
              <Select
                className={
                  !nextStep || !nextStep?.type
                    ? "select-node-none"
                    : classNameSelect(nextStep.type)
                }
                value={selectedNextNode}
                style={{ width: 120 }}
                placeholder={t("workflows.selectStep")}
                onChange={(value, option) => {
                  setSelectedNextStep(option as any);
                  setNextStep(option);
                  onFormChange();
                }}
                options={nextStepOptions(
                  otherSteps ?? [],
                  (step) => step.type !== WorkflowStepType.End
                )}
              />
            </Space>
          </Col>
        </Row>
      </Col>

      <Col flex="80px" style={{ textAlign: "right" }}>
        <Divider type="horizontal" />
        <Button onClick={emitOnChangeEvent} type="primary">
          {t("buttons.update")}
        </Button>
      </Col>
    </Row>
  );
};

export default StartNode;
