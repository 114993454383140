import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  FormProps,
  Icons,
  Input,
  Row,
  Select,
  Table,
  Typography,
} from "@pankod/refine-antd";
import ButtonConfirm from "components/ButtonConfirm";
import { DATE_FORMAT } from "configs/constants";
import { FC, memo } from "react";
import { disabledDate, searchSelect } from "utils/commons";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import BusinessOpportunities from "interfaces/BusinessOpportunities";
import InputMoney from "../MoneyInput";
import { useController } from "./controller";
import { API_PATH } from "configs/path";
import { SelectCustom } from "components";
import { Currency } from "components/Currency";

const { Text } = Typography;

interface Props {
  form: FormInstance<{}>;
  formProps: FormProps;
  isEdit?: boolean;
  onFinishedFormValidate?: () => void;
  disabled?: boolean;
  initialValue?: BusinessOpportunities;
}

const { TextArea } = Input;

const BusinessOpportunitiesForm: FC<Props> = memo((props) => {
  const {
    formProps,
    initialValue,
    transformDefaultProductToFormProduct,
    isEdit,
    statusSelectProps,
    customerSelectProps,
    creationDate,
    list,
    products,
    currency,
    unitSelectProps,
    onFinishedFormValidate,
    translate,
    setList,
    onRemoveProduct,
    productOptions,
  } = useController(props);
  return (
    <Form
      {...formProps}
      initialValues={{
        ...initialValue,
        customerId: initialValue?.customer?.id,
        products: transformDefaultProductToFormProduct,
        statusId: initialValue?.businessOppsStatus?.id,
        creationDate: initialValue?.creationDate || dayjs(),
      }}
      onFinish={onFinishedFormValidate}
      layout="vertical"
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          {isEdit && (
            <Form.Item
              label={translate("businessOpportunities.businessCaseId")}
              name="code"
            >
              <Input disabled />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={translate("businessOpportunities.nameOfBusinessOpportunity")}
            name="name"
            rules={[
              { required: true, message: translate("errors.ER005") },
              { max: 100, message: translate("errors.ER014", { max: 100 }) },
            ]}
          >
            <Input placeholder={translate("Nhập tên CHKD")} />
          </Form.Item>
        </Col>
        {isEdit && (
          <Col xs={24} lg={12}>
            <Form.Item
              label={translate("businessOpportunities.status")}
              name="statusId"
              rules={[{ required: true, message: translate("errors.ER005") }]}
            >
              <Select {...statusSelectProps} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={translate("businessOpportunities.customer")}
            name="customerId"
            rules={[{ required: true, message: translate("errors.ER005") }]}
          >
            {isEdit ? (
              <Select
                disabled
                value={initialValue?.customer.id}
                options={[
                  {
                    label: initialValue?.customer?.name,
                    value: initialValue?.customer.id,
                  },
                ]}
              />
            ) : (
              <Select
                {...customerSelectProps}
                placeholder={translate("Chọn khách hàng")}
                filterOption={(inputValue: string, option: any) => {
                  return option && searchSelect(inputValue, option?.label);
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          <InputMoney
            name="budgetEstimation"
            label={
              <span>
                {translate("businessOpportunities.estimatedBudget")}{" "}
                <span style={{ color: currency.unitColor }}>(VND)</span>
              </span>
            }
            placeholder={translate(
              "businessOpportunities.placeholder.enterTheClient'sBudget"
            )}
          />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          <InputMoney
            name="revenueEstimation"
            label={
              <span>
                {translate("businessOpportunities.estimatedContractValue")}{" "}
                <span style={{ color: currency.unitColor }}>(VND)</span>
              </span>
            }
            placeholder={translate(
              "businessOpportunities.enterTheEstimatedContractValue"
            )}
            isRequired
          />
        </Col>
        <Col xs={24} lg={12}>
          <InputMoney
            name="profitEstimation"
            label={
              <span>
                {translate("businessOpportunities.grossProfit")}{" "}
                <span style={{ color: currency.unitColor }}>(VND)</span>
              </span>
            }
            placeholder={translate("businessOpportunities.enterProfit")}
            isRequired
          />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            name="creationDate"
            label={translate("businessOpportunities.startDate")}
            rules={[{ required: true, message: translate("errors.ER005") }]}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : dayjs(),
            })}
          >
            <DatePicker
              placeholder={DATE_FORMAT}
              format={DATE_FORMAT}
              style={{ width: "100%" }}
              disabled={isEdit}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            name="closedDate"
            label={translate("businessOpportunities.estimatedClosingDate")}
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },
              {
                validator: (_, value) => {
                  if (
                    !!value &&
                    dayjs(value).valueOf() < dayjs(creationDate).valueOf()
                  ) {
                    return Promise.reject(
                      new Error(
                        translate(
                          "businessOpportunities.theClosingDateMustBeFfterTheOpeningDate"
                        )
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            getValueProps={(value) => ({
              value: value ? dayjs(value).endOf("date") : "",
            })}
          >
            <DatePicker
              placeholder={DATE_FORMAT}
              format={DATE_FORMAT}
              style={{ width: "100%" }}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={translate("businessOpportunities.description")}
        name="description"
        rules={[
          {
            max: 5000,
            message: translate("errors.ER014", { max: "5,000" }),
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>

      <Divider />

      <Row style={{ marginBottom: 16 }} align="middle">
        <Col flex={1}>
          <label style={{ fontWeight: 500 }}>
            {translate("businessOpportunities.productList")}
          </label>
        </Col>
        <Button
          type="primary"
          onClick={() => {
            setList([...list, uuid()]);
          }}
          icon={<Icons.PlusOutlined />}
        >
          {translate("actions.create")}
        </Button>
      </Row>

      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        scroll={{ x: 1250 }}
      >
        <Table.Column
          title={translate("common.no")}
          dataIndex="name"
          width={50}
          align="center"
          render={(v, keyRow: string, index) => {
            return <Text>{index + 1}</Text>;
          }}
        />
        <Table.Column
          title={translate("businessOpportunities.productName")}
          dataIndex="name"
          width={200}
          render={(v, keyRow: string) => {
            return (
              <>
                <Form.Item name={["products", keyRow, "businessOppsProductId"]} initialValue={products?.[keyRow]?.id} hidden />
                <Form.Item name={["products", keyRow, "productName"]} hidden />
                <Form.Item
                  required
                  className="m-0"
                  name={["products", keyRow, "productId"]}
                  rules={[
                    {
                      required: true,
                      message: translate("errors.ER005"),
                    },
                  ]}
                >
                  <SelectCustom
                    optionLabel="label"
                    optionValue="id"
                    resource={API_PATH.productDropdownList}
                    label={products?.[keyRow]?.productName}
                    allowClear
                    formatData={(r: any) => ({
                      ...r,
                      label: `${r?.name} - ${r?.code}`,
                    })}
                    customOptions={productOptions}
                    placeholder={translate("Chọn sản phẩm")}
                  />
                </Form.Item>
              </>
            );
          }}
        />

        <Table.Column
          title={translate("products.fields.partNumber")}
          dataIndex="partNumber"
          width="150px"
          render={(v, keyRow: string) => {
            return (
              <>
                <Form.Item
                  className="m-0"
                  name={["products", keyRow, "partNumber"]}
                >
                  <Input placeholder={translate("Nhập part number")} />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("common.description")}
          dataIndex="description"
          width="200px"
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                className="m-0"
                name={["products", keyRow, "description"]}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  placeholder={translate("common.input.description")}
                />
              </Form.Item>
            );
          }}
        />

        <Table.Column
          title={translate("common.quantity")}
          width={100}
          dataIndex="quantity"
          render={(v, keyRow: string) => {
            return (
              <InputMoney
                name={["products", keyRow, "quantity"]}
                isRequired
                placeholder={translate("Nhập giá trị")}
                className="m-0"
              />
            );
          }}
        />

        <Table.Column
          title={translate("products.fields.unitShort")}
          dataIndex="unitId"
          width={150}
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                className="m-0"
                name={["products", keyRow, "unitId"]}
                rules={[{ required: true, message: translate("errors.ER005") }]}
              >
                <Select
                  {...unitSelectProps}
                  placeholder={translate("products.placeholder.unit")}
                  filterOption={(inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label);
                  }}
                />
              </Form.Item>
            );
          }}
        />

        <Table.Column
          width={200}
          title={
            <span>
              {translate("products.fields.unitPrice")}{" "}
              <span style={{ color: currency.unitColor }}>(VND)</span>
            </span>
          }
          dataIndex="unitPrice"
          render={(v, keyRow: string) => {
            return (
              <InputMoney
                name={["products", keyRow, "unitPrice"]}
                isRequired
                placeholder={translate("Nhập giá trị")}
                className="m-0"
              />
            );
          }}
        />

        <Table.Column
          title={
            <span>
              {translate("businessOpportunities.value")}{" "}
              <span style={{ color: currency.unitColor }}>(VND)</span>
            </span>
          }
          dataIndex="name"
          width={200}
          render={(v, keyRow: string) => {
            const unitPrice = products?.[keyRow]?.unitPrice || 0;
            const quantity = products?.[keyRow]?.quantity || 0;
            const sum = !unitPrice || !quantity ? 0 : unitPrice * quantity;
            return <Currency value={sum} showCurrency={false} />;
          }}
        />

        <Table.Column
          align="center"
          width="90px"
          fixed="right"
          title={translate("table.actions")}
          render={(v, record, index) => {
            return (
              <ButtonConfirm
                text={translate("payments.costs.deleteConfirmText")}
                description=""
                onClick={onRemoveProduct(index)}
                type="text"
              >
                <Icons.DeleteOutlined />
              </ButtonConfirm>
            );
          }}
        />
      </Table>
    </Form>
  );
});

export default BusinessOpportunitiesForm;
