import { Descriptions, Icons, Modal, notification, Tag, Typography } from "@pankod/refine-antd"
import { IResourceComponentsProps, useTranslate, useNavigation, useShow, useUpdate } from "@pankod/refine-core"
import { ButtonTooltip, DataText, LinkText } from "components"
import { DateTimeFieldCustom } from "components/DateTimeTextField"
import { ShowCustom } from "components/layout"
import { ConfigStatusTag } from "components/StatusTag"
import { PATH } from "configs/path"
import usePermissions from 'hooks/permission'
import { ICostConfig } from "interfaces"
import { showErrorToast } from "api/common";

const { Text } = Typography;

export const TitleShow: React.FC<IResourceComponentsProps> = (props) => {
    const t = useTranslate()
    const { edit } = useNavigation()
    const { checkEditHaveHelperText } = usePermissions()
    const { mutate: mutateUpdate, isLoading: isUpdateLoading } = useUpdate<ICostConfig>()

    const { queryResult } = useShow<any>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const confirmToggleStatus = () => {
        Modal.confirm({
            title: record?.status ? t('titles.message.deactivateConfirm') : t('titles.message.activateConfirm'),
            content: record?.status ? t('common.config.editConfirmDesc') : '',
            okText: t('buttons.confirm'),
            okButtonProps: {
                danger: !record?.status
            },
            cancelText: t('buttons.reject'),
            onOk: () => {
                toggleStatus()
            }
        })
    }

    const toggleStatus = () => {
        mutateUpdate({
            resource: PATH.configTitles,
            id: record?.id,
            metaData: {
                type: record?.status ? '/deactivate' : '/activate'
            },
            values: {},
        }, {
            onSuccess: () => {
                notification.success({
                    message: record?.status ? t('common.config.deactiveSuccess'): t('common.config.activeSuccess'),
                })
            },
            onError: (error: any) => {
                showErrorToast(error)
            },
        })
    }

    return (
        <ShowCustom
            {...props}
            isLoading={isLoading}
            title={
                <Text style={{ marginBottom: 0 }}>{t("titles.titles.menu")}: <span className="primary">{record?.name}</span> </Text>
            }
            breadcrumbText={record?.name}
            headerButtons={
                <>
                    <ButtonTooltip
                        type="default"
                        icon={<Icons.EditOutlined />}
                        onClick={() => edit(PATH.configTitles, record.id)}
                        {...checkEditHaveHelperText(PATH.configTitles, !record?.status)}
                    >
                        {t('buttons.edit')}
                    </ButtonTooltip>

                    {
                        !record?.status &&
                        <ButtonTooltip
                            type="primary"
                            icon={<Icons.CheckCircleOutlined />}
                            onClick={confirmToggleStatus}
                            {...checkEditHaveHelperText(PATH.configTitles)}
                        >
                            {t('buttons.active')}
                        </ButtonTooltip>
                    }
                    {
                        record?.status &&
                        <ButtonTooltip
                            type="primary"
                            danger
                            icon={<Icons.CloseCircleOutlined />}
                            onClick={confirmToggleStatus}
                            {...checkEditHaveHelperText(PATH.configTitles)}
                        >
                            {t('buttons.deactivate')}
                        </ButtonTooltip>
                    }
                </>
            }
        >
            <Descriptions
                column={3}
                title={t("common.titles.detail")}
                layout="vertical"
            >
                <Descriptions.Item
                    labelStyle={{ fontWeight: 500 }}
                    label={t("titles.fields.code")}
                >
                    <DataText value={record?.code} />
                </Descriptions.Item>

                <Descriptions.Item
                    labelStyle={{ fontWeight: 500 }}
                    label={t("titles.fields.name")}
                >
                    <DataText value={record?.name} />
                </Descriptions.Item>
                <Descriptions.Item
                    labelStyle={{ fontWeight: 500 }}
                    label={t("common.config.status")}
                >
                    <ConfigStatusTag status={record?.status} />
                </Descriptions.Item>
                <Descriptions.Item
                    labelStyle={{ fontWeight: 500 }}
                    span={3}
                    label={t("departments.fields.description")}
                >
                    <DataText value={record?.description} className="line-break" />
                </Descriptions.Item>

                <Descriptions.Item
                    labelStyle={{ fontWeight: 500 }}
                    label={t("common.updatedDate")}
                >
                    <DateTimeFieldCustom value={record?.updatedDate} />
                </Descriptions.Item>
                <Descriptions.Item
                    span={2}
                    labelStyle={{ fontWeight: 500 }}
                    label={t("common.updatedName")}
                >
                    <DataText value={record?.updatedName} />
                </Descriptions.Item>
                <Descriptions.Item
                    labelStyle={{ fontWeight: 500 }}
                    label={t("common.createdDate")}
                >
                    <DateTimeFieldCustom value={record?.createdDate} />
                </Descriptions.Item>
                <Descriptions.Item
                    span={2}
                    labelStyle={{ fontWeight: 500 }}
                    label={t("common.createdName")}
                >
                    <DataText value={record?.createdName} />
                </Descriptions.Item>

            </Descriptions>
        </ShowCustom>
    )
}