import { Icons, Table } from "@pankod/refine-antd"
import { useTranslate } from "@pankod/refine-core"
import { IAttachment } from "interfaces"

interface AttachmentsViewProps {
    attachments?: IAttachment[],
    title?: string
  }

export const AttachmentsView = ({ attachments, title }: AttachmentsViewProps) => {
    const list = attachments || []
    const t = useTranslate()
    if (list.length === 0) {
      return <div />
    }
    return (
      <Table
        style={{ width: '100%' }}
        size="small"
        rowKey="fileUrlOrigin"
        dataSource={list}
        locale={{ emptyText: ' ' }}
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
      >
        <Table.Column
          dataIndex="attachment"
          title={ title ? title : t('common.fields.attachment')}
          render={(v: any, item: any) => {
            return item.fileUrlOrigin
          }}
        />
  
        <Table.Column
          title={t('table.actions')}
          width="120px"
          align="center"
          dataIndex="action"
          render={(v: any, item: any) => {
            return (
              <a href={item.fileUrlView} target="_blank" rel="noreferrer">
                <Icons.EyeOutlined style={{ color: '#8C8C8C' }} />
              </a>
            )
          }}
        />
      </Table>
    )
  }