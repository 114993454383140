import {
  Card,
  Checkbox,
  Descriptions,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { FC, memo, useContext, useMemo } from "react";
import type { ColumnsType } from "antd/es/table";
import { ObjectSubmitCondition } from "interfaces";
import { ContractEstimateShowContext } from "../..";
import styled from "styled-components";
import { SubmitConditionInfoType } from "api/enums";

const { Text } = Typography;

export const CheckBoxError = styled(Checkbox)`
  :hover {
    border-color: red !important;
  }
  .ant-checkbox-wrapper:hover {
    border-color: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: red;
  }

  .ant-checkbox-checked .ant-checkbox-inner:hover {
    border-color: red;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    border: none !important;
    display: table;
    transform: rotate(0deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: "X";
    font-size: 10px;
    color: white;
    font-weight: bold;
    left: 50%;
  }
`;

const SubmitionInfomation: FC = memo(() => {
  const { record } = useContext(ContractEstimateShowContext);

  const translate = useTranslate();

  const columns = useMemo<ColumnsType<ObjectSubmitCondition>>(
    () => [
      {
        title: translate("common.no"),
        width: 50,
        dataIndex: "id",
        key: "id",
        render: (_, record, index) => index + 1,
      },
      {
        title: translate("Điều kiện"),
        width: 200,
        dataIndex: "description",
        key: "description",
        render: (_, record, index) => (
          <Text>{record?.submitConditionInfo?.description}</Text>
        ),
      },
      {
        title: translate("Thông tin"),
        width: 200,
        dataIndex: "url",
        key: "url",
        render: (_, record, index) => (
          <div
            dangerouslySetInnerHTML={{
              __html: record?.submitConditionInfo?.url,
            }}
          />
        ),
      },
      {
        title: translate("Thỏa điều kiện"),
        width: 120,
        dataIndex: "action",
        key: "action",
        align: "center",
        render: (_, record, index) => {
          const { submitConditionInfo, isOk } = record;
          return submitConditionInfo?.type ===
            SubmitConditionInfoType.Manual ? (
            <Checkbox checked={isOk} />
          ) : isOk ? (
            <Checkbox checked />
          ) : (
            <CheckBoxError checked />
          );
        },
      },
    ],
    []
  );

  return (
    <Card style={{ backgroundColor: "#ffffff" }}>
      <Descriptions
        column={1}
        layout="vertical"
        title={translate("Thông tin cần để chuyển DTHĐ")}
      />
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={record?.objectSubmitConditions}
        scroll={{ x: 570 }}
        style={{ paddingLeft: 16 }}
        size="small"
      />
    </Card>
  );
});

export default SubmitionInfomation;
