import { Form, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import { CurrencyType } from "api/enums";
import CSpin from "components/CSpin";
import { generateCurrency } from "components/Currency";
import { API_PATH } from "configs/path";
import { FC, memo, useContext, useMemo } from "react";
import { CreateSettlementsContractContext, IFormCreateSettlementsContract } from "..";
import DetailContractNumber from '../../components/detailContractNumber';
import "./styles.less";

const { Text } = Typography;

const { useWatch, useFormInstance } = Form;

// Function Component
const EstimateInfo: FC<IResourceComponentsProps> = memo((props) => {
  const {
    planState: [plansState, setPlansState],
    isLoadingFetchFinancial,
  } = useContext(CreateSettlementsContractContext);
  const form = useFormInstance<IFormCreateSettlementsContract>();
  const caseId = useWatch("caseId", form);
  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);

  // Set planItems
  useOne({
    resource: API_PATH.financialIndicatorMaterData,
    id: "",
    successNotification(data: any) {
      setPlansState(data?.data);
      return {
        message: `Successfully fetched.`,
        description: "Success with no errors",
        type: "success",
      };
    },
    queryOptions: {
      enabled: true,
    },
  });

  useOne({
    resource: API_PATH.settlementContractsFinancialIndicator,
    id: "",
    metaData: {
      after: caseId ? `/${caseId}` : "",
    },
    queryOptions: {
      enabled: caseId ? true : false,
    },
    successNotification(data: any) {
      const newPlans = data?.data;

      setPlansState(newPlans);
      return {
        message: `Successfully fetched.`,
        description: "Success with no errors",
        type: "success",
      };
    }
  });

  return (
    <>
      <DetailContractNumber plans={plansState} />
      <CSpin isLoading={isLoadingFetchFinancial} />
    </>
  );
});

export default EstimateInfo;
