// import { TOKEN_KEY } from 'configs/storage'
import { Button, Col, Form, Icons, Input, Row, Space, Typography, notification, Divider } from '@pankod/refine-antd'
import { useApiUrl, useLogout, useTranslate } from '@pankod/refine-core'
import { changePassword, initByExternalAccount, resetPassword, userActive } from 'api';
import React from 'react'
import { formItemStyle } from './styles';
import { useNavigate } from 'react-router-dom'
import { NO_AUTH_PATH } from 'configs/path';
import { ROLE_USER, TOKEN_KEY, TENANT_ID, EXTERNAL_LOGIN } from 'configs/storage';
import { InitByExternalAccountRequest, SuccessResponse } from 'api/types';
import { useSearchParams } from 'react-router-dom';
import ExternalLogin, { ExternalLoginInformation } from 'components/ExternalLogin';
import { PageLoading } from 'components/layout/pageLoading';

const { Text, Title, Paragraph } = Typography;

export enum UpdatePasswordType {
    CHANGE_PASSWORD,
    FORGOT_PASSWORD,
    INIT_PASSWORD
}
type Props = {
    onChange?: Function
    updateType: UpdatePasswordType,
    email?: string
}
export interface IUpdatePasswordForm {
    currentPassword: string;
    password: string;
    newPassword: string;
    token: string
}
enum ErrorChecklistType {
    MINIMUM_CHARACTERS,
    AT_LEAST_NUMER,
    AT_LEAST_LOWER_CHARACTER,
    AT_LEAST_UPPER_CHARACTER,
    AT_LEAST_SPECIAL_CHARACTER
}

const checkListInit = [
    {
        label: 'password.minLength',
        validate: (pass: string) => pass.length >= 8,
    },
    {
        label: 'password.minLowerCase',
        validate: (pass: string) => /(?=.*[a-z])/.test(pass),
    },
    {
        label: 'password.minNumber',
        validate: (pass: string) => /(?=.*\d)/.test(pass),
    },
    {
        label: 'password.minUpperCase',
        validate: (pass: string) => /(?=.*[A-Z])/.test(pass),
    },
    {
        label: 'password.minSpecialCharacter',
        validate: (pass: string) => /(?=.*[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\]])/.test(pass)
    }
]

export const UpdatePassword = ({ onChange, updateType, email }: Props) => {
    const [searchParams] = useSearchParams();
    let token = encodeURIComponent(searchParams.get('token') || '')
    const isCallBack = searchParams.get('callback') !== null

    if (isCallBack) {
        const historyStorage = JSON.parse(localStorage.getItem(EXTERNAL_LOGIN) || "{}")
        token = historyStorage.token
    }


    const navigate = useNavigate();
    const translate = useTranslate();
    const [error, setError] = React.useState<string>();

    const diplayLabels = [
        {
            type: UpdatePasswordType.INIT_PASSWORD,
            title: translate("pages.changePassword.titleInitAccount")
        },
        {
            type: UpdatePasswordType.CHANGE_PASSWORD,
            title: translate("pages.changePassword.title")
        },
        {
            type: UpdatePasswordType.FORGOT_PASSWORD,
            title: translate("pages.changePassword.titleForgotPassword")
        }
    ];

    const [form] = Form.useForm<IUpdatePasswordForm>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [checkList, setCheckList] = React.useState(checkListInit);
    const [labels, setLabels] = React.useState<any>(diplayLabels.filter(t => t.type === updateType)[0]);


    const onSubmit = (val: IUpdatePasswordForm) => {
        const isValid = validateResult.findIndex(t => t.result == false) < 0;
        if (isValid) {
            let request: Promise<SuccessResponse>;
            switch (updateType) {
                case UpdatePasswordType.CHANGE_PASSWORD:
                    request = changePassword({ currentPassword: val.currentPassword, newPassword: val.password }); break;
                case UpdatePasswordType.INIT_PASSWORD:
                    request = userActive({ token: token, userName: email, newPassword: val.password }); break;
                case UpdatePasswordType.FORGOT_PASSWORD:
                    request = resetPassword({ newPassword: val.password, userName: email || '', token: token || '' }); break;
            }
            const redirectUrl = `${NO_AUTH_PATH.changePasswordSuccess}?type=${updateType.toString()}`;

            request.then(response => {
                afterLoginSuccess(redirectUrl)
            }).catch(err => {
                onUpdatePasswordError(err);
            });
        }
    };

    const afterLoginSuccess = (redirectUrl: string) => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(ROLE_USER);
        localStorage.removeItem(TENANT_ID);
        window.location.replace(redirectUrl);
    }

    const onUpdatePasswordError = (err: any) => {
        if (err) {
            switch (err.errorCode) {
                case 'ER002':
                    form.setFields([{
                        name: 'currentPassword',
                        errors: [err.message]
                    }])
                    break;
                case 'ER009':
                    form.setFields([{
                        name: 'password',
                        errors: [err.message]
                    }])
                    break;
                default:
                    setError(err.message);
                    break;
            }
        }
    }

    const valuePass = Form.useWatch('password', form) || ''
    const validateResult = checkListInit.map(item => ({ ...item, result: item.validate(valuePass) }))

    let keyLang = 'updatePassword';

    if (updateType === UpdatePasswordType.FORGOT_PASSWORD) {
        keyLang = 'forgotPassword';
    }
    if (updateType === UpdatePasswordType.INIT_PASSWORD) {
        keyLang = 'initPassword';
    }

    const onExternalLoginSuccess = (data: ExternalLoginInformation) => {
        const historyStorage = JSON.parse(localStorage.getItem(EXTERNAL_LOGIN) || "{}")

        const body: InitByExternalAccountRequest = {
            accessToken: data.accessToken || '',
            activeUserToken: historyStorage.token || '',
            idToken: data.idToken || '',
            refreshToken: ''
        }
        setIsLoading(true)
        initByExternalAccount(body)
            .then((val) => {
                setIsLoading(false)
                const redirectUrl = `${NO_AUTH_PATH.changePasswordSuccess}?type=${UpdatePasswordType.INIT_PASSWORD.toString()}`;
                afterLoginSuccess(redirectUrl)
            })
            .catch((error) => {
                setIsLoading(false)
                onUpdatePasswordError(error)
            })
    }

    return (
        <>
            <PageLoading isLoading={isLoading} />
            <Row>
                <Col span={24}>
                    <Typography.Title level={2} style={{ textAlign: 'center', marginBottom: 40 }} >
                        {/* {labels.title} */}
                        {translate(`components.${keyLang}.title`)}
                    </Typography.Title>
                </Col>
            </Row>
            {
                error &&
                <Row>
                    <Col span={24}>
                        <Text type="danger">{error}</Text>
                    </Col>
                </Row>
            }
            {
                updateType === UpdatePasswordType.INIT_PASSWORD &&
                <Row>
                    <Col span={24}>
                        <div>
                            <ExternalLogin page='reset-password' type='azure' token={token} onLoginSuccess={onExternalLoginSuccess} />
                        </div>
                        <Divider style={{ color: '#8C8C8C' }}>{translate('account.init.password')}</Divider>
                    </Col>
                </Row>
            }

            <Row>
                <Col span={24}>
                    {
                        updateType === UpdatePasswordType.CHANGE_PASSWORD && <Paragraph>{translate(`components.${keyLang}.desc`)}</Paragraph>
                    }
                    {
                        updateType === UpdatePasswordType.FORGOT_PASSWORD && <Paragraph>{translate(`components.${keyLang}.desc`)}</Paragraph>
                    }
                    {
                        updateType === UpdatePasswordType.INIT_PASSWORD && <p dangerouslySetInnerHTML={{ __html: translate(`components.${keyLang}.desc`, { email: email }) }} ></p>
                    }

                    <Space direction='vertical' style={{ display: 'flex', marginBottom: 20, padding: 5 }}>
                        {
                            validateResult.map((i, idx) => <Space key={idx} align="center" style={{ justifyContent: 'space-between', display: 'flex' }}>
                                <Text type={i.result ? 'success' : 'danger'}>•  {translate(i.label)}</Text>
                                {i.result && <Text style={{ fontSize: 18 }} type="success"><Icons.CheckCircleOutlined /></Text>}
                                {!i.result && <Text style={{ fontSize: 18 }} type='danger'><Icons.CloseCircleOutlined /></Text>}
                            </Space>)
                        }
                    </Space>

                    <Form<IUpdatePasswordForm>
                        layout="vertical"
                        form={form}
                        onFinish={(values) => {
                            onSubmit(values);
                        }}
                        requiredMark={false}
                        initialValues={{}}
                    >

                        {
                            updateType === UpdatePasswordType.CHANGE_PASSWORD &&
                            <>
                                <Form.Item
                                    style={formItemStyle}
                                    name="currentPassword"
                                    label={translate(`components.${keyLang}.currentPassword`)}
                                    rules={[
                                        {
                                            required: true,
                                            message: translate("errors.ER005"),
                                        },
                                    ]}
                                >
                                    <Input.Password
										placeholder={translate("pages.login.passwordPlaceholder")}
										size="large"
									/>
                                </Form.Item>
                            </>
                        }
                        <br />
                        <Form.Item
                            style={formItemStyle}
                            name="password"
                            label={translate(`components.${keyLang}.newPassword`)}
                            rules={[
                                {
                                    required: true,
                                    message: translate("errors.ER005"),
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder={translate(`components.${keyLang}.newPasswordPlaceHolder`)}
                                size="large"
                            />
                        </Form.Item>
                        <br />
                        <Form.Item
                            style={formItemStyle}
                            name="newPassword"
                            label={translate(`components.${keyLang}.repeatNewPassword`)}
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        if (!form.getFieldValue('password') ||
                                            value !== form.getFieldValue('password')
                                        ) {
                                            return Promise.reject(translate("errors.ER004"))
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input.Password
                                placeholder={translate(`components.${keyLang}.repeatNewPasswordPlaceHolder`)}
                                size="large"
                            />
                        </Form.Item>
                        <Button
                            style={{ marginTop: 24 }}
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={isLoading}
                            block
                        >
                            {translate(`components.${keyLang}.changePasswordButton`)}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
