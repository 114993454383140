import {
  Button,
  Form,
  Icons,
  Modal,
  Select,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { FC, memo, useMemo } from "react";
import { Props, useController } from "./controller";
import type { ColumnsType } from "antd/es/table";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { useFormRule } from "hooks/useFormRule";
import ButtonConfirm from "components/ButtonConfirm";
import { SelectCustom } from "components";
import { API_PATH } from "configs/path";

const { Text } = Typography;

const ContentContainer = styled.div`
  padding: 16px 16px 0 16px;
  td {
    vertical-align: baseline;
  }
`;

const CustomerStyle = styled.div`
  padding: 5px 12px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-top: 8px;
`;

const ModalAssign: FC<Props> = memo((props) => {
  const { required } = useFormRule();
  const {
    props: { toggleModal, visibleModal, record },
    employeeSelectProps,
    typeSelectProps,
    form: { form, formProps },
    list,
    exploitingOptions,
    employeeInAssigned,
    employeeOptions,
    translate,
    onSubmit,
    setList,
    onRemoveItem,
    onSelectEmployee,
  } = useController(props);

  const columns = useMemo<ColumnsType<any>>(
    () => [
      {
        title: translate("common.no"),
        width: 50,
        dataIndex: "index",
        key: "index",
        render: (_, record, index) => {
          return <Text>{index + 1}</Text>;
        },
      },
      {
        title: translate("Nhân viên khai thác"),
        maxWidth: 250,
        dataIndex: "employeeId",
        key: "employeeId",
        render: (_, keyRow: string) => (
          <>
            <Form.Item
              hidden
              name={["employeeInAssigned", keyRow, "employeeName"]}
            />
            <Form.Item
              className="m-0"
              name={["employeeInAssigned", keyRow, "employeeId"]}
              rules={required}
            >
              <SelectCustom
                resource={API_PATH.employeesForAssignCustomer}
                optionLabel="label"
                optionValue="id"
                label={employeeInAssigned?.[keyRow]?.employeeName}
                formatData={(r: any) => ({
                  ...r,
                  label: `${r?.code} - ${r?.fullName}`,
                })}
                placeholder={translate("Chọn nhân viên")}
                onChangeOption={onSelectEmployee(keyRow)}
                useServerFiltering
                customOptions={employeeOptions}
              />
            </Form.Item>
          </>
        ),
      },
      {
        title: translate("Loại"),
        width: 110,
        dataIndex: "assignExloitingType",
        key: "assignExloitingType",
        render: (_, keyRow: string) => (
          <Form.Item
            className="m-0"
            name={["employeeInAssigned", keyRow, "assignExloitingType"]}
            rules={required}
          >
            <Select
              {...typeSelectProps}
              options={exploitingOptions(keyRow)}
              placeholder={translate("AM/Sub")}
            />
          </Form.Item>
        ),
      },
      {
        align: "center",
        title: translate("Hành động"),
        width: 90,
        fixed: "right",
        dataIndex: "assignExloitingType",
        key: "assignExloitingType",
        render: (_, keyRow: string, index) => (
          <ButtonConfirm
            text={translate("Bạn muốn xóa nhân viên khai thác này?")}
            description=""
            onClick={onRemoveItem(index)}
            type="text"
            danger
            noCancel={false}
          >
            <Icons.DeleteOutlined />
          </ButtonConfirm>
        ),
      },
    ],
    [employeeSelectProps, typeSelectProps, exploitingOptions]
  );

  return (
    <Modal
      centered
      closable={false}
      visible={visibleModal}
      onOk={form.submit}
      onCancel={toggleModal}
      okText={translate("buttons.confirm")}
      cancelText={translate("buttons.cancel")}
      bodyStyle={{ padding: 0, margin: 0 }}
      width={600}
    >
      <ContentContainer>
        <strong>{translate("customer.assignMinningStaff")}</strong>

        <div
          style={{
            margin: "8px 0 16px 0",
          }}
        >
          <div>{translate("customer.customerName")}</div>
          <CustomerStyle>{`${record?.code}-${record?.name}`}</CustomerStyle>
        </div>

        <div
          style={{ marginBottom: 16 }}
          className="flex justify-between items-center"
        >
          <div className="flex-1">
            {translate("Phân công nhân viên khai thác")}
          </div>
          <Button
            type="primary"
            onClick={(e) => {
              setList([...list, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {translate("actions.create")}
          </Button>
        </div>
        <Form {...formProps} onFinish={onSubmit}>
          <Table<String>
            dataSource={list}
            pagination={false}
            columns={columns}
            size="small"
            scroll={{ x: 500 }}
          ></Table>
        </Form>
      </ContentContainer>
    </Modal>
  );
});

export default ModalAssign;
