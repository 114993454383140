import { Descriptions, Table, Typography } from "@pankod/refine-antd";
import { FC, memo, useContext, useMemo } from "react";
import { ContractShowConText } from "../..";
import { useTranslate } from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";
import { ContractPaymentProcesses } from "interfaces/Contract";
import { Currency } from "components/Currency";
import { DataText } from "components";
import { formatISODateTimeToView } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
const { Text } = Typography;

const PaymentProgress: FC = memo(() => {
  const record = useContext(ContractShowConText);
  const translate = useTranslate();

  const columns = useMemo<ColumnsType<ContractPaymentProcesses>>(
    () => [
      {
        title: translate("Đợt"),
        width: 150,
        dataIndex: "term",
        key: "term",
        render: (value) => {
          return <Text>{value || "-"}</Text>;
        },
      },
      {
        title: translate("Ngày thanh toán dự kiến"),
        width: 200,
        dataIndex: "estimatedPaymentDate",
        key: "estimatedPaymentDate",
        render: (value) => {
          return (
            <DataText
              value={value ? formatISODateTimeToView(value, DATE_FORMAT) : "-"}
            />
          );
        },
      },
      {
        title: translate("Giá trị thanh toán"),
        width: 200,
        dataIndex: "paymentValue",
        key: "paymentValue",
        render: (value) => {
          return (
            <DataText
              value={
                value ? <Currency value={value} showCurrency={false} /> : "-"
              }
            />
          );
        },
      },
      {
        title: translate("Ghi chú"),
        width: 200,
        dataIndex: "description",
        key: "description",
        render: (value) => <DataText value={value || "-"} />,
      },
    ],
    []
  );
  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={2}
        title={translate("Tiến độ thanh toán")}
        layout="vertical"
      />
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={record?.contractPaymentProcesses}
        scroll={{ x: 800 }}
      />
    </div>
  );
});

export default PaymentProgress;
