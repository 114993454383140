import { Col, List, Row, Tabs, Tag } from "@pankod/refine-antd";
import { useOne, useTranslate } from "@pankod/refine-core";
import { SelectCustom } from "components";
import { API_PATH } from "configs/path";
import { FC, useState } from "react";
import TodoList from "./todolist";

interface TabProps {
  key: number;
  name: string;
  total: number;
}

const TabComponent = ((props: TabProps) => {
  
  const getColorNumber = (id: number) => {
    switch (id) {
      case 2:
        return "yellow";
      case 3:
        return "blue";
      case 4:
        return "green";
      default:
        return "default";
    }
  }
  return <>
    {props.name} {props.total > 0 && <Tag color={getColorNumber(props.key)}>{props.total}</Tag>}
  </>
});

// UI Dashboard
const Dashboard: FC = () => {
  const [featureId, setFeatureId] = useState<string>("");
  const t = useTranslate();
  const { data: dataStatus } = useOne({
    id: "",
    resource: API_PATH.todoListStatus,
  })

  return (
    <div style={{ padding: "16px" }}>
      <Row>
        <Col xs={24} lg={12} style={{ backgroundColor: "white", padding: "20px" }}  >
          <div style={{ display: "flex" }}>
            <h2>{t("common.needApproval")}</h2>
            <SelectCustom
              className={"placeholderBlack"}
              allowClear={true}
              allowSearch={false}
              placeholder={t("common.typeTicket")}
              style={{ width: 200, marginBottom: 16, marginInline: 16 }}
              resource={API_PATH.todoListDropdownlist}
              bordered={false}
              onChange={(value) => {
                setFeatureId(value);
               }} 
              optionLabel="label"
              optionValue="featureId"
              formatData={(r: any) => ({
                ...r,
                label: t(`common.${r.featureCode}`),
              })}
              />
          </div>
          <Tabs defaultActiveKey="1" >
            (
            <>
              {dataStatus?.data?.map((item: any) => {
                return <Tabs.TabPane
                  tab={TabComponent({ key: item.id, name: item.name, total: item.total })}
                  key={item.id}
                >
                  <TodoList id={item.id} featureId={featureId} />
                </Tabs.TabPane>
              })}
            </>
            )
          </Tabs>
        </Col>
        <Col xs={24} lg={12} style={{ backgroundColor: "transparent", paddingLeft: "10px", height: "100%" }} >
          <List
            title={t("common.myWork")}
            breadcrumb={null}
          >
          </List>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
