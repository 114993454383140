import Employee from "./Employee";

export interface IWorkingGroup {
  createdDate: Date;
  createdName: string;
  id: string;
  name: string;
  status: EWorkingGroupStatus;
  updatedDate: Date;
  updatedName: string;
  description: string;
  workingGroupMembers: Employee[];
}

export enum EWorkingGroupStatus {
  Deactive = 0,
  Active = 1,
}
