import { FC, memo } from "react";
import CaseInfo from "./CaseInfo";
import General from "./General";
import ProductList from "./ProductList";

const Information: FC = memo(() => {
  return (
    <>
      <CaseInfo />
      <General />
      <ProductList />
    </>
  );
});

export default Information;
