import { notification, useForm } from "@pankod/refine-antd";
import { useCreate, useNavigation, useTranslate } from "@pankod/refine-core";
import { PATH } from "configs/path";
import { useState } from "react";
import { mappingErrorFromApi } from "utils/commons";

interface FormInterface {
  name: string;
  abbreviationName: string;
  identifiedTaxCode: string;
  phoneNumber: string;
  address: string;
  provinceId: string;
  districtId: string;
  wardId: string;
  businessIndustryId: string;
  email: string;
  website: string;
  paymentterm: number;
  hasBillingInfo: boolean;
  nameInBillingInfo: string;
  taxCodeInBillingInfo: string;
  phoneNumberInBillingInfo: string;
  emailInBillingInfo: string;
  addressInBillingInfo: string;
}

export const useController = () => {
  const { show } = useNavigation();
  const translate = useTranslate();
  const { form, formProps, saveButtonProps } = useForm<any, any, FormInterface>(
    {}
  );

  const [visibleModalConfirmCreate, setVisibleModalConfirmCreate] =
    useState(false);

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleModalConfirmCreate(isOpen);

  const { mutate: mutateCreate, isLoading } = useCreate<{ id: string }, any>();

  const onSubmit = () => {
    const dataForm = form.getFieldsValue();
    form.setFields([{ name: "paymentterm", errors: undefined }]);
    form.setFields([{ name: "identifiedtaxcode", errors: undefined }]);

    const params: FormInterface = {
      ...dataForm,
      abbreviationName: dataForm?.abbreviationName?.trim(),
      name: dataForm?.name?.trim(),
      address: dataForm?.address?.trim(),
      nameInBillingInfo: dataForm?.nameInBillingInfo?.trim(),
      addressInBillingInfo: dataForm?.addressInBillingInfo?.trim(),
    };
    mutateCreate(
      {
        resource: PATH.customers,
        values: params,
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: translate(
              "customer.notifycation.createCustomerSuccessful"
            ),
          });
          show(PATH.customers, res?.data?.id);
        },
        onError: (error) => {
          toggleModalConfirm(false);
          notification.error({
            message: translate("customer.notifycation.createCustomerFailed"),
            description:
              error?.errors.length &&
              translate(
                `errors.${error?.errors?.[0]?.errorCode}`,
                error?.errors?.[0]?.value
              ),
          });
          const errorFieldMapping: any = {};
          mappingErrorFromApi(error, form, errorFieldMapping);
        },
      }
    );
  };

  return {
    formProps,
    isLoading,
    saveButtonProps,
    visibleModalConfirmCreate,
    form,
    onSubmit,
    toggleModalConfirm,
    translate,
  };
};
