import React, { memo, useMemo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { Button, Space, Typography } from "antd";
import { Icons } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { Modal } from "@pankod/refine-antd";
import { WorkflowStepType } from "api/enums";
import { getColorByNodeType, getIconByNodeType } from "./diagramHelper";

const { Text, Paragraph } = Typography;

export const NodeCustom: React.FC<NodeProps> = memo((props) => {
  const t = useTranslate();
  const [visibleDeleteModal, setVisibleDeleteModal] =
    React.useState<boolean>(false);
  const nodeType = props.data?.type;
  const isEnd = nodeType === WorkflowStepType.End;
  const isNormal = nodeType === WorkflowStepType.Normal;
  const isDecision = nodeType === WorkflowStepType.Decision;

  const color = useMemo(() => getColorByNodeType(nodeType), [nodeType]);

  const icon = useMemo(() => getIconByNodeType(nodeType), [nodeType]);

  const nextStep = props?.data?.defaultNextStep;
  const yesStep = props?.data?.defaultYesStep;
  const noStep = props?.data?.defaultNoStep;
  const errors = props?.data?.errors;
  const isError = errors && errors.length > 0;

  return (
    <>
      {props?.sourcePosition && (
        <Handle
          type="target"
          className="targetHandle"
          position={props?.sourcePosition || Position.Right}
          isConnectable={props?.isConnectable}
        />
      )}
      <div
        style={{ ...props.data?.style }}
        className={`node-custom${isError ? " error" : ""}`}
      >
        <Space
          align="center"
          style={{
            width: "100%",
            justifyContent: "space-between",
            backgroundColor: color,
            padding: 8,
          }}
        >
          <Space align="center">
            <Text style={{ fontSize: 20 }}>{icon}</Text>
            <Paragraph
              ellipsis={{ rows: 2 }}
              style={{ fontWeight: 500, marginBottom: 0 }}
            >
              {props?.data?.name || t("workflows.texts.enterName")}
            </Paragraph>
          </Space>
          {(isNormal || isDecision) && props?.data?.onDelNode && (
            <Button
              type="link"
              icon={
                <Icons.DeleteOutlined
                  style={{ fontSize: 14, color: "rgba(0, 0, 0, 0.85)" }}
                />
              }
              onClick={() => {
                setVisibleDeleteModal(true);
              }}
            />
          )}
        </Space>
        <div className="node-custom__content">
          {nextStep && (
            <div>
              <Text>
                {t("workflows.nextStep")} <strong>{nextStep?.name}</strong>
              </Text>
            </div>
          )}
          {yesStep && (
            <div>
              <Text>
                {t("workflows.yesStep")} <strong>{yesStep?.name}</strong>
              </Text>
            </div>
          )}
          {noStep && (
            <div>
              <Text>
                {t("workflows.noStep")} <strong>{noStep?.name}</strong>
              </Text>
            </div>
          )}
          {isEnd && !isError && (
            <Text>{t("workflows.nodeContentLinkingToEnd")}</Text>
          )}
          <ul className="node-list">
            {errors &&
              errors.map((i: string, idx: number) => <li key={idx}>{t(i)}</li>)}
          </ul>
          {props?.data?.children}
        </div>
        <div className="status-icon">
          {isError && (
            <Text type="danger">
              <Icons.ExclamationCircleOutlined />
            </Text>
          )}
          {!isError && (
            <Text type="success">
              <Icons.CheckCircleOutlined />
            </Text>
          )}
        </div>
      </div>
      {props?.targetPosition && (
        <Handle
          type="source"
          className="targetHandle"
          position={props?.targetPosition || Position.Left}
          isConnectable={props?.isConnectable}
        />
      )}
      <Modal
        title=""
        visible={visibleDeleteModal}
        onOk={() => props?.data?.onDelNode && props?.data?.onDelNode(props.id)}
        onCancel={() => setVisibleDeleteModal(false)}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        okButtonProps={{
          danger: true,
        }}
        width={417}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <Paragraph
            style={{
              fontWeight: 500,
              fontSize: 16,
              paddingTop: 5,
              marginBottom: 0,
              paddingRight: 20,
            }}
          >
            {t("workflows.messages.deleteNodeConfirm")}
          </Paragraph>
        </Space>
      </Modal>
    </>
  );
});
