import React from "react";
import { useTranslate, IResourceComponentsProps, useCreate } from "@pankod/refine-core";
import {
  Form,
  Input,
  useForm,
  notification
} from "@pankod/refine-antd";

import { IPost } from "interfaces";
import { PATH } from "configs/path";
import { CreateCustom } from "components/layout";
import { useNavigate } from 'react-router-dom'
import { showErrorPopup, showErrorToast } from "api/common";

export const UserCreate: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();
  const [ visibleShowModal, setVisibleShowModal ] = React.useState<boolean>(false);
  const { mutate: mutateCreate, isLoading } = useCreate<any>();
  const { form, formProps, saveButtonProps } = useForm<IPost>();
  const navigate = useNavigate()

  const onSubmit = () => {
    mutateCreate(
      {
        resource: PATH.users,
        values: {
          email: form.getFieldValue('email')
        },
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: translate('users.createSuccess')
          })
          navigate(`show/${res?.data?.id}`)
        },
        onError: (error: any) => {
          setVisibleShowModal(false)
          showErrorPopup(error)
        },
      },
    )
  }

  return (
    <CreateCustom
      {...props}
      confirmModalProps={{
        title: translate('users.createConfirm')
      }}
      saveButtonProps={{
        ...saveButtonProps,
        title: translate('users.create')
      }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      saving={isLoading}
    >
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout='horizontal'
        style={{maxWidth: 400}}
      >
        <Form.Item
          label={translate("users.fields.email")}
          name="email"
          rules={[
            {
              required: true,
              message: translate("errors.ER005"),
            },
            {
              type: 'email',
              message: translate("errors.ER015")
            },
            {
              max: 255,
              message: translate("errors.ER014", { max: '255'}),
            },
          ]}
        >
          <Input placeholder={translate('users.fields.emailPlaceholder')}/>
        </Form.Item>
      </Form>
    </CreateCustom>
  );
};
