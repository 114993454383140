import { Col, Form, Input, Row, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { useSearchParams } from "@pankod/refine-react-router-v6";
import { SelectCustom } from "components";
import { API_PATH } from "configs/path";
import { unionBy } from "lodash";
import { FC, memo } from "react";

const { Title } = Typography;
const { useFormInstance, useWatch } = Form;

interface Props {
  handleChangeBusinessPlan: (option: any) => void;
  isEdit?: boolean;
}

const General: FC<Props> = memo((props) => {
  const { handleChangeBusinessPlan, isEdit } = props;
  const translate = useTranslate();
  const form = useFormInstance();
  const onChangeCase = (_: any, __: any, option: any) =>
    form.setFieldsValue({
      businessPlanId: null,
      customerName: option?.customerName,
      customerAddress: option?.customerAddress,
      customerIdentifiedTaxCode: option?.customerIdentifiedTaxCode,
    });

  const fillCustomerWithCaseId = (option: any) => {
    const customer = option.find((op: any) => op.value === caseId);
    form.setFieldsValue({
      customerName: customer?.customerName,
      customerAddress: customer?.customerAddress,
      customerIdentifiedTaxCode: customer?.customerIdentifiedTaxCode,
    });
    if(!isEdit){
      form.setFieldsValue({
        businessPlanId: null,
      });
    }
  }
  const caseId = useWatch("caseId", form);

  const businessPlanOption = (options: any[]) => {
    const convertTo = options.map((op) => ({
      label: op.code,
      value: op.id,
      ...op,
    }));
    if (caseId) {
      return unionBy(
        convertTo.filter((op) => op.parentId === caseId),
        "value"
      );
    }
    return convertTo;
  };

  const onChangeBusinessPlan = (_: any, option: any) =>
    handleChangeBusinessPlan(option);
  const params = useSearchParams();

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("common.titles.detail")}</Title>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}
        style={{ paddingLeft: 16 }}
      >
        <Col span={12}>
          <Form.Item
            name="caseId"
            label="Mã vụ việc"
            initialValue={params[0].get("caseId")}
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },
            ]}
          >
            <SelectCustom
              placeholder="Chọn mã vụ việc"
              resource={API_PATH.quotationsCaseDropdownlist}
              optionLabel="label"
              optionValue="value"
              allowClear
              onChangeOption={onChangeCase}
              onLoaded={fillCustomerWithCaseId}
              disabled={isEdit}
            />
          </Form.Item>
        </Col>
        <Col span={12} />
        <Col span={12}>
          <Form.Item
            name="businessPlanId"
            label="Phương án kinh doanh"
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },
            ]}
          >
            <SelectCustom
              placeholder="Chọn PAKD"
              resource={API_PATH.quotationsBusinessPlanDropdownlist}
              optionLabel="code"
              optionValue="id"
              allowClear
              customOptions={businessPlanOption}
              onChangeOption={onChangeBusinessPlan}
              disabled={isEdit}
            />
          </Form.Item>
        </Col>
        <Col span={12} />
        <Col span={12}>
          <Form.Item
            name="name"
            label="Tên báo giá"
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },
              {
                max: 1000,
                message: translate("errors.ER014", { max: 1000 }),
              },
            ]}
          >
            <Input placeholder="Nhập tên báo giá" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
});

export default General;
