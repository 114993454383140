import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useTable,
  useNavigation,
} from "@pankod/refine-core";
import { List, Table, TextField, EditButton, Icons } from "@pankod/refine-antd";
import { IDepartment } from "interfaces";
import { EmptyData, LinkText, SearchBox, ButtonTooltip } from "components";
import { useNavigate } from "react-router-dom";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { getSlugToRedirectWorkId } from "utils/commons";
export const DepartmentList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { edit, create } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);
  const {
    tableQueryResult: { data: dataTable },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    setFilters,
  } = useTable<IDepartment>({
    initialCurrent: 1,
  });

  const onSearch = (value: string) => {
    setCurrent(1);
    if (value) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  return (
    <>
      <List
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.departments)}
            {...checkCreateHaveHelperText(PATH.departments)}
          >
            {t("departments.titles.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <SearchBox
            placeholder={t("departments.search")}
            onSearch={onSearch}
          />
          <Table
            dataSource={dataTable?.data}
            rowKey="id"
            locale={{
              emptyText: (
                <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
              ),
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`show/${record.id}`);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              locale: {
                items_per_page: t("common.pageNumber"),
              },
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              showTotal: (total) => (
                <p>{t("common.totalPage", { total: total })}</p>
              ),
            }}
            scroll={{ x: "auto" }}
          >
            <Table.Column
              key="id"
              dataIndex="id"
              title={t("common.no")}
              render={(_, __, index) => (current - 1) * pageSize + index + 1}
              width={80}
            />
            <Table.Column
              dataIndex="code"
              title={t("departments.fields.code")}
              width={220}
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="case"
              title={t("cases.fields.code")}
              width={220}
              render={(value, record: any) => (
                <LinkText
                  value={value}
                  resource={getSlugToRedirectWorkId(record?.case?.type)}
                />
              )}
            />
            <Table.Column
              dataIndex="name"
              title={t("departments.fields.name")}
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="parentDepartment"
              title={t("departments.fields.parentDepartment")}
              width="200px"
              render={(value) => <TextField value={value?.name} />}
            />
            <Table.Column<IDepartment>
              title={t("table.actions")}
              dataIndex="actions"
              align="center"
              width={120}
              render={(_, record) => (
                <ButtonTooltip
                  type="text"
                  icon={<Icons.EditOutlined />}
                  className="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    edit(PATH.departments, record.id);
                  }}
                  {...checkEditHaveHelperText(PATH.departments)}
                />
              )}
            />
          </Table>
        </div>
      </List>
    </>
  );
};
