import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Create, DatePicker, Form, Icons, Input, Modal, notification, Row, Space, Typography, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useNavigation, useTranslate } from "@pankod/refine-core";
import { CreateCustom } from "components/layout";
import { SelectCustom } from "components/SelectCustom";
import { API_PATH, PATH } from "configs/path";
import { IFeature, IRole, IWorkflow } from "interfaces";
import { useNavigate } from 'react-router-dom'
import { formatOnlyDateToUTC, formatTimeToUTC, isValidCodeField, mappingErrorFromApi } from "utils/commons";
import InputUpper from "components/InputUpper";
import UploadMulti from "components/UploadMulti";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export const WorkflowCreate: React.FC<IResourceComponentsProps> = (props) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>(false);
    const [selectedFeature, setSelectedFeature] = React.useState<IFeature>();

    const { form, formProps, saveButtonProps } = useForm<IWorkflow>();
    const { mutate: mutateCreate, isLoading } = useCreate<any>();

    const onSubmit = () => {
        const valueForm = form.getFieldsValue() as IWorkflow
        const appliedDates = form.getFieldValue('appliedDate');
        const body = {
            name: valueForm.name.trim(),
            appliedFromDate: formatOnlyDateToUTC(appliedDates[0]),
            appliedToDate: formatOnlyDateToUTC(appliedDates[1]),
            featureId: selectedFeature?.featureId,
            featureName: selectedFeature?.featureName,
            featureCode: selectedFeature?.featureCode,
            description: valueForm.description,
            attachments: valueForm.attachments
        }

        mutateCreate(
            {
                resource: PATH.workflows,
                values: body,
            },
            {
                onSuccess: (res: any) => {
                    notification.success({ message: t('workflows.messages.createSuccess') })
                    navigate(`show/${res?.data?.id}`)
                },
                onError: (error: any) => {
                    setVisibleShowModal(false)
                    mappingErrorFromApi(error, form, { 'ER074': 'appliedDate' });
                },
            },
        )

    }

    return (
        <>
            <CreateCustom
                {...props}
                confirmModalProps={{
                    title: t('workflows.createConfirm')
                }}
                visibleShowModal={visibleShowModal}
                setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
                saveButtonProps={{
                    ...saveButtonProps,
                    title: t('workflows.actions.create')
                }}
                cancelModalProps={{
                    title: t('workflows.messages.cancelConfirm')
                }}
                onSubmit={() => onSubmit()}
                saving={isLoading}
            >
                <Form
                    {...formProps}
                    onFinish={() => setVisibleShowModal(true)}
                    onFinishFailed={() => { }}
                    layout='vertical'
                    labelCol={{ span: 10 }}

                >
                    <Title level={4}>{t("workflows.workflowInfo")}</Title>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
                        <Col span={12}>
                            <Form.Item
                                label={t("workflows.fields.name")}
                                name="name"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: t("errors.ER005"),
                                    },
                                    {
                                        max: 300,
                                        message: t("errors.ER014", { max: '300' }),
                                    }
                                ]}
                            >
                                <Input placeholder={t("workflows.fields.namePlaceHolder")} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
                        <Col span={12}>
                            <Form.Item
                                label={t("workflows.fields.feature")}
                                name="featureId"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: t("errors.ER005"),
                                    },
                                    {
                                        max: 300,
                                        message: t("errors.ER014", { max: '300' }),
                                    }
                                ]}
                            >
                                <SelectCustom
                                    allowClear
                                    resource={API_PATH.businessFeature}
                                    optionValue="featureId"
                                    optionLabel="featureName"
                                    valueInObject={true}
                                    onChangeItem={(value: any, option: any, data: any) => {
                                        setSelectedFeature(data);
                                    }}
                                    disabled={false}
                                    label={""}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
                        <Col span={12}>
                            <Form.Item
                                label={t("workflows.fields.appliedDate")}
                                name="appliedDate"
                                rules={[
                                    {
                                        required: true,
                                        message: t("errors.ER005"),
                                    }
                                ]}
                            >
                                <RangePicker
                                    format={'DD/MM/YYYY'}
                                    style={{ width: '100%' }}
                                    placeholder={[t("workflows.fields.appliedFromDatePlaceHolder"), t("workflows.fields.appliedToDatePlaceHolder")]}
                                    disabledDate={(current) => {
                                        let now = moment().add(-1, 'day') as any;
                                        return current && current <= now;
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
                        <Col span={12}>
                            <Form.Item
                                label={t("workflows.fields.description")}
                                name="description"
                                rules={[
                                    {
                                        max: 500,
                                        message: t("errors.ER014", { max: '500' }),
                                    }
                                ]}
                            >
                                <TextArea
                                    showCount={{ formatter: (args: { count: number }) => `${args?.count}/500` }}
                                    placeholder={t("roles.fields.descriptionPlaceHolder")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="attachments"
                                validateStatus="success"
                                label=""
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: t('errors.ER005'),
                                    },
                                ]}
                            >
                                <UploadMulti
                                    title={t('common.fields.attachments')}
                                    repo="ApprovalProcedures"
                                    required
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </CreateCustom>
        </>
    );
}