export default interface AssignExploiting {
  createdDate?: Date;
  createdName?: string;
  tenantId?: string;
  updatedDate?: Date;
  updatedName?: string;
  id?: string;
  assignExploitingType?: {
    id?: AssignExploitingType;
    name?: string;
  };
  customer?: {
    code?: string;
    id?: string;
    name?: string;
    parentId?: string;
    businessIndustry?: {
      code?: string;
      createdDate?: Date;
      createdName?: string;
      description?: string;
      id?: string;
    };
    province?: {
      code?: string;
      id?: string;
      name?: string;
    };
  }[];
  employee?: {
    code?: string;
    id?: string;
    name?: string;
  };
  productOwner?: ProductOwner[];
}

export interface ProductOwner {
  code: string;
  id?: string;
  name: string;
  parentId?: string;
  status?: boolean;
  productGroup: {
    code?: string;
    id?: string;
    name: string;
    status?: boolean;
  };
}

export enum AssignExploitingType {
  CUSTOMER = 1,
  PRODUCT = 2,
}
