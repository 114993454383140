import { Collapse, Descriptions, Table } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { SettlementCost } from "interfaces/Settlement";
import { FC, memo, useCallback, useContext, useMemo } from "react";
import type { ColumnsType } from "antd/es/table";
import { DataText } from "components";
import { SettlementShowContext } from "../../..";
import { isEmpty } from "utils/commons";
import { Currency } from "components/Currency";
const { Panel } = Collapse;

const CostByMoney: FC = memo(() => {
  const translate = useTranslate();
  const settlement = useContext(SettlementShowContext);

  const { totalEstimatedByCash, totalSettlementByCash } = settlement;

  const isSum = useCallback((cost: SettlementCost) => cost.refId === "sum", []);

  const columns = useMemo<ColumnsType<SettlementCost>>(
    () => [
      {
        title: translate("common.no"),
        width: 70,
        dataIndex: "id",
        key: "id",
        render: (_, record, index) => {
          if (isSum(record)) return null;
          return <DataText value={index + 1} />;
        },
      },
      // {
      //   title: translate("Tên sản phẩm"),
      //   width: 250,
      //   dataIndex: "productName",
      //   key: "productName",
      //   render: (_, record) => {
      //     if (isSum(record)) return null;
      //     return (
      //       <DataText
      //         value={
      //           isAllProducts
      //             ? "Tất cả sản phẩm"
      //             : `${record?.product?.code} - ${record?.product?.name}`
      //         }
      //       />
      //     );
      //   },
      //   sorter: (a, b) => a?.product?.code?.length - b?.product?.code?.length,
      // },
      {
        title: translate("settlements.cash.costName"),
        width: 200,
        dataIndex: "cost",
        key: "cost",
        render: (_, record) => {
          if (isSum(record)) return null;
          return (
            <DataText
              value={
                isEmpty(record?.proportion)
                  ? `${record?.cost?.code} - ${record?.cost?.name}`
                  : "-"
              }
            />
          );
        },
        sorter: (a, b) => a?.cost?.code?.length - b?.cost?.code?.length,
      },
      // {
      //   title: translate("Tỷ lệ"),
      //   width: 100,
      //   dataIndex: "proportionPolicy",
      //   key: "proportionPolicy",
      //   render: (_, record) => {
      //     if (isSum(record)) return null;
      //     return (
      //       <DataText
      //         value={
      //           isEmpty(record?.proportion) ? (
      //             "-"
      //           ) : (
      //             <Currency
      //               value={record?.proportion * 100}
      //               showCurrency={false}
      //               after="%"
      //             />
      //           )
      //         }
      //       />
      //     );
      //   },
      //   sorter: (a, b) => a?.proportion - b?.proportion,
      // },
      // {
      //   title: translate("Đơn giá"),
      //   width: 200,
      //   dataIndex: "unitPrice",
      //   key: "unitPrice",
      //   render: (_, record) => {
      //     if (isSum(record))
      //       return <Text className="font-bold">{translate("Tổng cộng")}</Text>;

      //     return (
      //       <DataText
      //         value={
      //           record?.unitPrice ? (
      //             <Currency value={record?.unitPrice} showCurrency={false} />
      //           ) : (
      //             "-"
      //           )
      //         }
      //       />
      //     );
      //   },
      //   sorter: (a, b) => a?.unitPrice - b?.unitPrice,
      // },
      // {
      //   title: translate("Số lượng dự toán"),
      //   width: 100,
      //   dataIndex: "quantity",
      //   key: "quantity",
      //   render: (_, record) => {
      //     if (isSum(record))
      //       return (
      //         <Currency value={totalQuantityCostTable} showCurrency={false} />
      //       );
      //     return (
      //       <DataText
      //         value={
      //           isEmpty(record?.proportion) ? (
      //             <Currency value={record?.quantity} showCurrency={false} />
      //           ) : (
      //             "-"
      //           )
      //         }
      //       />
      //     );
      //   },
      //   sorter: (a, b) => a?.quantity - b?.quantity,
      // },
      {
        title: translate("settlements.value"),
        width: 200,
        dataIndex: "settlementValue",
        key: "settlementValue",
        render: (_, record) => {
          return (
            <DataText
              value={
                !isEmpty(record?.settlementValue) ? (
                  <Currency
                    value={record?.settlementValue}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.settlementValue - b?.settlementValue,
      },
      {
        title: translate("settlements.estimate.value"),
        width: 200,
        dataIndex: "estimatedValue",
        key: "estimatedValue",
        render: (_, record) => {
          return (
            <DataText
              value={
                record?.estimatedValue ? (
                  <Currency
                    value={record?.estimatedValue}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.estimatedValue - b?.estimatedValue,
      },
      {
        title: translate("settlements.cash.note"),
        width: 200,
        dataIndex: "note",
        key: "note",
        render: (_, record) => {
          if (isSum(record)) return null;
          return <DataText value={record?.note || "-"} />;
        },
      },
    ],
    [isSum, translate]
  );

  return (
    <Collapse
      className="w-full"
      defaultActiveKey={["1"]}
      ghost
      collapsible="header"
    >
      <Panel header={translate("settlements.cost.cash")} key="1">
        <Table
          size="small"
          pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
          rowKey="id"
          columns={columns}
          dataSource={[
            ...(settlement.settlementCosts || [])
          ]}
          scroll={{ x: 1520 }}
          style={{ marginTop: 16 }}
        />
        <Descriptions
          style={{ marginTop: 4 }}
          layout="vertical"
          column={{ md: 2, xs: 1 }}
        >
          <Descriptions.Item
            labelStyle={{ fontWeight: 500 }}
            label={translate("settlements.cost.totalSettlementValue")}
          >
            <DataText
              value={
                isEmpty(totalSettlementByCash) ? (
                  "-"
                ) : (
                  <Currency
                    showCurrency={false}
                    value={totalSettlementByCash}
                  />
                )
              }
            />
          </Descriptions.Item>

          <Descriptions.Item
            labelStyle={{ fontWeight: 500 }}
            label={translate("settlements.cost.totalEstimateValue")}
          >
            <DataText
              value={
                isEmpty(totalEstimatedByCash) ? (
                  "-"
                ) : (
                  <Currency
                    showCurrency={false}
                    className="blue"
                    value={totalEstimatedByCash}
                  />
                )
              }
            />
          </Descriptions.Item>
        </Descriptions>
      </Panel>
    </Collapse>
  );
});

export default CostByMoney;
