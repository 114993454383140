import React, { useState } from "react";
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Button,
    Space,
} from "@pankod/refine-antd";
import { useTranslate, useNavigation } from "@pankod/refine-core";

import LangSelect from "components/LangSelect";

const { Paragraph } = Typography;

export const NoPermissionPage: React.FC = () => {
    const translate = useTranslate();
    const { push } = useNavigation()

    return (
        <AntdLayout style={{background: '#fff'}}>
            {/* <LangSelect isFixed isText /> */}
            <Row
                justify="center"
                align="middle"
                style={{height: "100vh"}}
            >
                <Col xs={22}>
                    <div className="text-center">
                        <img src='/images/common-no-permission.png' alt="No permission" width={252}/>
                        <Paragraph
                            style={{marginTop: 50, marginBottom: 25, color: "#00000073" }}
                        >
                            {translate('pages.error.e403')}
                        </Paragraph>
                        <Button type="primary" onClick={() => push('/')}>
                            {translate('pages.error.backHome')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
