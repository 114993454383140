import React, { CSSProperties } from 'react'
import { useTranslate } from '@pankod/refine-core'

type Props = {
  text?: string
  image?: React.ReactNode
  style?: CSSProperties
}

export const EmptyData = ({ text, image, style }: Props) => {
  const t = useTranslate();
  return (
    <div style={{paddingTop: 120, paddingBottom: 100, textAlign: 'center', ...style}}>
      {image ? image : <img width={120} src='/images/no-data.svg'/>}
      <p style={{marginTop: 25}}>{text || t("common.noData")}</p>
    </div>
  )
}
