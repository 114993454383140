import { Descriptions, Table, Typography } from "@pankod/refine-antd";
import { FC, memo, useContext, useMemo } from "react";
import { ContractShowConText } from "../..";
import { useTranslate } from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";
import { ContractInvoicingPlans } from "interfaces/Contract";
import { Currency } from "components/Currency";
import { DataText } from "components";
import { formatISODateTimeToView, isEmpty } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
const { Text } = Typography;

const InvoicePlan: FC = memo(() => {
  const record = useContext(ContractShowConText);
  const translate = useTranslate();

  const columns = useMemo<ColumnsType<ContractInvoicingPlans>>(
    () => [
      {
        title: translate("Đợt"),
        width: 150,
        dataIndex: "invoiceTerm",
        key: "invoiceTerm",
        render: (value) => <Text>{value || "-"}</Text>,
      },
      {
        title: translate("Ngày xuất hóa đơn dự kiến"),
        width: 200,
        dataIndex: "estimatedInvoiceDate",
        key: "estimatedInvoiceDate",
        render: (value) => (
          <DataText
            value={value ? formatISODateTimeToView(value, DATE_FORMAT) : "-"}
          />
        ),
      },

      {
        title: translate("Giá trị hóa đơn"),
        width: 200,
        dataIndex: "invoiceValue",
        key: "invoiceValue",
        render: (value) => (
          <DataText
            value={
              isEmpty(value) ? (
                "-"
              ) : (
                <Currency value={value} showCurrency={false} />
              )
            }
          />
        ),
      },
    ],
    []
  );
  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={2}
        title={translate("Kế hoạch xuất hóa đơn")}
        layout="vertical"
      />
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={record?.contractInvoicingPlans}
        scroll={{ x: 800 }}
      />
    </div>
  );
});

export default InvoicePlan;
