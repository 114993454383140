import { Checkbox } from "@pankod/refine-antd";
import styled from "styled-components";

export const CheckBoxError = styled(Checkbox)`
      :hover {
        border-color: red !important;
      }
      .ant-checkbox-wrapper:hover {
        border-color: none;
      }
    
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: red;
        border-color: red;
      }
    
      .ant-checkbox-checked .ant-checkbox-inner:hover {
        border-color: red;
      }
    
      .ant-checkbox-checked .ant-checkbox-inner::after {
        position: absolute;
        border: none !important;
        display: table;
        transform: rotate(0deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: "X";
        font-size: 10px;
        color: white;
        font-weight: bold;
        left: 50%;
      }
    `;