import { Card, Descriptions, Table } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";
import { DataText } from "components";
import { FC, memo, useContext, useMemo } from "react";
import { Currency } from "components/Currency";
import { InternalEstimateShowContext } from "../../..";
import { isEmpty } from "utils/commons";

const ProductList: FC = memo(() => {
  const translate = useTranslate();
  const { record } = useContext(InternalEstimateShowContext);
  const {
    listInternalEstimatedProduct = [],
    totalSellingPrice = 0,
    totalInternalCost = 0,
    totalMargin = 0,
  } = record;

  const columns = useMemo<ColumnsType<any>>(
    () => [
      {
        title: translate("common.no"),
        width: 70,
        dataIndex: "id",
        key: "id",
        render: (_, __, index) => {
          return <DataText value={index + 1} />;
        },
      },
      {
        title: translate("Tên sản phẩm"),
        width: 200,
        dataIndex: "productName",
        key: "productName",
        render: (_, record) => {
          return (
            <DataText
              value={`${record?.product.code} - ${record?.product.name}`}
            />
          );
        },
      },
      {
        title: translate("products.fields.partNumber"),
        width: 200,
        dataIndex: "partNumber",
        key: "partNumber",
        render: (_, record) => {
          return <DataText value={record?.partNumber} />;
        },
      },
      {
        title: translate("common.description"),
        width: 250,
        dataIndex: "description",
        key: "description",
        render: (_, record) => {
          return <DataText value={record?.description} />;
        },
      },
      {
        title: translate("common.quantity"),
        width: 100,
        dataIndex: "quantity",
        key: "quantity",
        render: (_, record) => {
          return <Currency showCurrency={false} value={record?.quantity} />;
        },
      },
      {
        title: translate("products.fields.unitShort"),
        width: 120,
        dataIndex: "product",
        key: "product",
        render: (_, record) => {
          return <DataText value={record?.unit?.name} />;
        },
      },
      {
        title: translate("products.fields.unitPriceSell"),
        width: 200,
        dataIndex: "totalPrice",
        key: "totalPrice",
        render: (_, record) => {
          return <Currency showCurrency={false} value={record?.unitPrice} />;
        },
      },
      {
        title: translate("Giá bán khách hàng"),
        width: 200,
        dataIndex: "sellingPrice",
        key: "sellingPrice",
        render: (_, record) => {
          return <Currency showCurrency={false} value={record?.sellingPrice} />;
        },
      },
      {
        title: translate("Giá vốn nội bộ"),
        width: 200,
        dataIndex: "internalCost",
        key: "internalCost",
        render: (_, record) => {
          return <Currency showCurrency={false} value={record?.internalCost} />;
        },
      },
      {
        title: translate("Margin"),
        width: 200,
        dataIndex: "margin",
        key: "margin",
        render: (_, record) => {
          return (
            <Currency
              showCurrency={false}
              value={record?.margin}
              className={record?.margin > 0 ? "green" : "red"}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <Card>
      <Descriptions
        column={1}
        title={translate("products.titles.list")}
        layout="vertical"
      />
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={listInternalEstimatedProduct}
        scroll={{ x: 1900 }}
        style={{ marginLeft: 16 }}
      />
      <Descriptions
        style={{ marginTop: 20 }}
        column={{ lg: 3, xs: 1 }}
        layout="vertical"
      >
        <Descriptions.Item
          label={translate("Tổng giá bán khách hàng")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText
            value={
              isEmpty(totalSellingPrice) ? (
                "-"
              ) : (
                <Currency value={totalSellingPrice} showCurrency={false} />
              )
            }
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Tổng giá vốn nội bộ")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText
            value={
              isEmpty(totalInternalCost) ? (
                "-"
              ) : (
                <Currency value={totalInternalCost} showCurrency={false} />
              )
            }
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Margin")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText
            value={
              isEmpty(totalMargin) ? (
                "-"
              ) : (
                <Currency
                  className={totalMargin > 0 ? "green" : "red"}
                  value={totalMargin}
                  showCurrency={false}
                />
              )
            }
          />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
});

export default ProductList;
