import React, { CSSProperties } from "react";
import { useTranslate } from "@pankod/refine-core";
import { RefineCrudListProps } from "@pankod/refine-ui-types";
import { List, Space } from "@pankod/refine-antd";
import { IHeaderTab } from "interfaces";
import { HeaderTab } from "../headerTab";

type Props = {
  tabs?: IHeaderTab[];
  onChangeTab?: any;
  contentStyles?: CSSProperties;
  headerButtons?: React.ReactNode;
};

export const ListCustom: React.FC<RefineCrudListProps & Props> = (props) => {
  const t = useTranslate();
  const { children, title, tabs, onChangeTab, contentStyles, headerButtons } =
    props;

  const hasTabs = !!tabs?.length;

  let customTitle = title;
  if (hasTabs) {
    customTitle = (
      <>
        <Space direction="vertical">
          {title}
          <HeaderTab tabs={tabs} onChangeTab={onChangeTab} />
        </Space>
      </>
    );
  }

  return (
    <List
      {...props}
      headerButtons={headerButtons}
      title={customTitle}
      headerProps={{
        className: hasTabs ? "custom-tab-header" : "",
      }}
      contentProps={{
        style: {
          ...contentStyles,
        },
      }}
    >
      {children}
    </List>
  );
};
