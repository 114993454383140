import {
  Button,
  Col,
  DatePicker,
  Form,
  Icons,
  Input,
  Row,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { CurrencyType } from "api/enums";
import { generateCurrency } from "components/Currency";
import { FC, memo, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { ContractContext, IForm } from "../../..";
import ButtonConfirm from "components/ButtonConfirm";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import { DATE_FORMAT } from "configs/constants";

const { Title, Text } = Typography;
const { TextArea } = Input;
const { useWatch, useFormInstance } = Form;
const ContractImplementProgress: FC = memo(() => {
  const { isEdit, initialValue } = useContext(ContractContext);

  const form = useFormInstance<IForm>();
  const [list, setList] = useState<string[]>([]);
  const translate = useTranslate();
  const currency = useMemo(() => generateCurrency(CurrencyType.VND), []);

  const signDate = useWatch("signDate", form);
  const contractImplementProgress = useWatch("contractImplementProgress", form);

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  useEffect(() => {
    if (!signDate || !contractImplementProgress) return;
    const signDateValue = dayjs(signDate).valueOf();
    const temp = Object.values(contractImplementProgress);
    const ids = Object.keys(contractImplementProgress);
    const validate: any[] = [];
    temp.forEach(({ implementationDate, completionDate }, index) => {
      const id = ids[index];
      if (
        !!implementationDate &&
        dayjs(implementationDate).valueOf() < signDateValue
      ) {
        validate.push({
          name: ["contractImplementProgress", id, "implementationDate"],
          errors: [translate("errors.ER0181")],
        });
      }

      if (
        !!completionDate &&
        !!implementationDate &&
        dayjs(completionDate).valueOf() < dayjs(implementationDate).valueOf()
      ) {
        validate.push({
          name: ["contractImplementProgress", id, "completionDate"],
          errors: [translate("errors.ER0183")],
        });
      }
    });

    if (validate.length) form.setFields(validate);
  }, [signDate, form, contractImplementProgress, translate]);

  useEffect(() => {
    if (isEdit && initialValue?.contractImplementProgress?.length) {
      const ids: string[] = [];
      const contractImplementProgress: any = {};
      initialValue?.contractImplementProgress?.forEach((ip) => {
        contractImplementProgress[ip.id] = { ...ip };
        ids.push(ip.id);
      });
      setList(ids);
      setTimeout(
        () =>
          form.setFieldsValue({
            contractImplementProgress: contractImplementProgress,
          }),
        0
      );
    }
  }, [isEdit, initialValue]);

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Row style={{ marginBottom: 8 }}>
        <Col flex={1}>
          <Title level={5}>{translate("Tiến độ triển khai")}</Title>
        </Col>
        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setList([...list, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {translate("actions.create")}
          </Button>
        </div>
      </Row>
      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        scroll={{ x: 800 }}
      >
        <Table.Column
          title={translate("Đợt")}
          dataIndex="term"
          width={100}
          render={(v, keyRow: string, index) => {
            return (
              <>
                <Form.Item
                  hidden
                  name={["contractImplementProgress", keyRow, "id"]}
                />
                <Form.Item
                  className="m-0"
                  name={["contractImplementProgress", keyRow, "paymentTerm"]}
                  rules={[
                    {
                      max: 200,
                      message: translate("errors.ER014", { max: 200 }),
                    },
                  ]}
                >
                  <Input
                    className="w-full"
                    placeholder={translate("Nhập giá trị")}
                  />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("Ngày triển khai")}
          dataIndex="implementationDate"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={[
                  "contractImplementProgress",
                  keyRow,
                  "implementationDate",
                ]}
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                className="m-0"
                rules={[
                  {
                    validator(rule, value, callback) {
                      const implementationDate = dayjs(value).valueOf();
                      if (
                        !!value &&
                        implementationDate < dayjs(signDate)?.valueOf()
                      ) {
                        return Promise.reject(
                          new Error(translate("errors.ER0181"))
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  className="w-full"
                  format={DATE_FORMAT}
                  placeholder={DATE_FORMAT}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("Ngày hoàn thành")}
          dataIndex="completionDate"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["contractImplementProgress", keyRow, "completionDate"]}
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                className="m-0"
                rules={[
                  {
                    validator(rule, value, callback) {
                      const completionDate = dayjs(value).valueOf();
                      const implementationDate = dayjs(
                        contractImplementProgress?.[keyRow]?.implementationDate
                      )?.valueOf();
                      if (!!value && completionDate < implementationDate) {
                        return Promise.reject(
                          new Error(translate("errors.ER0183"))
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  className="w-full"
                  format={DATE_FORMAT}
                  placeholder={DATE_FORMAT}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("Mô tả")}
          dataIndex="description"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["contractImplementProgress", keyRow, "description"]}
                className="m-0"
                rules={[
                  {
                    max: 3000,
                    message: translate("errors.ER014", { max: 3000 }),
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  placeholder={translate("Nhập giá trị")}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          align="center"
          width={90}
          fixed="right"
          title={translate("table.actions")}
          render={(v, record, index) => {
            return (
              <ButtonConfirm
                text={translate("Bạn muốn xóa dòng thông tin này?")}
                description=""
                onClick={onRemoveItem(index)}
                type="text"
                danger
              >
                <Icons.DeleteOutlined />
              </ButtonConfirm>
            );
          }}
        />
      </Table>
    </div>
  );
});

export default ContractImplementProgress;
