import { AntdLayout, Button, Card, Col, Row, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { containerStyles, titleStyles } from "./styles";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { UpdatePasswordType } from "components/UpdatePassword";
import React from "react";
import LangSelect from "components/LangSelect";

const { Title, Paragraph } = Typography;

export const CustomUpdatePasswordSuccessPage: React.FC = () => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const updatePasswordType = searchParams.get('type') || UpdatePasswordType.CHANGE_PASSWORD;
    const diplayLabels = [
        {
            type: UpdatePasswordType.CHANGE_PASSWORD,
            title: translate("pages.changePassword.successTitle"),
            message: translate("pages.changePassword.successMessage"),
            buttonText: translate("pages.changePassword.successButton")
        },
        {
            type: UpdatePasswordType.INIT_PASSWORD,
            title: translate("pages.changePassword.successInitPasswordTitle"),
            message: translate("pages.changePassword.successInitPasswordMessage"),
            buttonText: translate("pages.changePassword.successButton")
        },
        {
            type: UpdatePasswordType.FORGOT_PASSWORD,
            title: translate("pages.changePassword.successForgotPasswordTitle"),
            message: translate("pages.changePassword.successForgotPasswordMessage"),
            buttonText: translate("pages.changePassword.successButton")
        }
    ]
    const labels: any = diplayLabels.find(t => t.type == updatePasswordType) || {}

    const CardTitle = (
        <Title level={3} style={titleStyles}>
            {labels.title}
        </Title>
    );

    return (
        <AntdLayout style={{ backgroundColor: 'white' }}>
				<LangSelect isFixed isText />
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "70vh",
                }}
            >
                <Col xs={22}>
                    <div style={containerStyles}>
                        <Card style={{border: 0}} title={CardTitle} headStyle={{ borderBottom: 0 }}>
                            <Paragraph style={{color: '#697077'}}>{labels.message}</Paragraph>
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => {
                                    navigate('/');
                                }}
                                block
                            >
                                {labels.buttonText}
                            </Button>
                        </Card>
                    </div>

                </Col>
            </Row>
        </AntdLayout>
    );
}