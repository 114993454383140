import { useTranslate } from "@pankod/refine-core";
import { Descriptions, Table, Typography } from "antd";
import IQuotation, { Product } from "interfaces/Quotation";
import { FC, memo, useMemo } from "react";
import type { ColumnsType } from "antd/es/table";
import { Currency } from "components/Currency";
import { DataText } from "components";

interface Props {
  record?: IQuotation;
}

const { Text } = Typography;

const ProductTable: FC<Props> = memo((props) => {
  const { record: quotation } = props;
  const translate = useTranslate();

  const columns = useMemo<ColumnsType<Product>>(
    () => [
      {
        title: translate("common.no"),
        width: 70,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Text>{index + 1}</Text>;
        },
      },
      {
        title: translate("Tên sản phẩm"),
        width: 200,
        dataIndex: "productName",
        key: "productName",
        render: (_, record, index) => {
          return <DataText value={record?.productName} />;
        },
      },
      {
        title: translate("products.fields.partNumber"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <DataText value={record?.partNumber} />;
        },
      },
      {
        title: translate("common.description"),
        width: 250,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <DataText value={record?.description} />;
        },
      },
      {
        title: translate("common.quantity"),
        width: 100,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Currency showCurrency={false} value={record?.quantity} />;
        },
      },
      {
        title: translate("products.fields.unit"),
        width: 120,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Text>{record?.unit?.name}</Text>;
        },
      },
      {
        title: translate("products.fields.unitPrice"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Currency showCurrency={false} value={record?.unitPrice} />;
        },
      },
      {
        title: translate("% Thuế"),
        width: 100,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return (
            <Currency showCurrency={false} value={record?.taxPercent * 100} />
          );
        },
      },
      {
        title: translate("Trước thuế"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return (
            <Currency showCurrency={false} value={record?.beforeTaxValue} />
          );
        },
      },
      {
        title: translate("Thuế"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <Currency showCurrency={false} value={record?.taxValue} />;
        },
      },
      {
        title: translate("Sau thuế"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return (
            <Currency showCurrency={false} value={record?.afterTaxValue} />
          );
        },
      },
      {
        title: translate("Ghi chú"),
        width: 200,
        dataIndex: "product",
        key: "product",
        render: (_, record, index) => {
          return <DataText value={record?.note} />;
        },
      },
    ],
    []
  );

  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={2}
        title={translate("Bảng báo giá")}
        layout="vertical"
      />
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={quotation?.quotationProducts}
        scroll={{ x: 2100 }}
      />
      <Descriptions
        column={3}
        layout="vertical"
        className="no-padding"
        style={{ marginTop: 8 }}
      >
        <Descriptions.Item
          label={translate("Giá trị trước thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency
            showCurrency={false}
            value={quotation?.totalBeforeTaxValue}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency showCurrency={false} value={quotation?.totalTaxValue} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Giá trị sau thuế")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <Currency
            showCurrency={false}
            value={quotation?.totalAfterTaxValue}
          />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
});

export default ProductTable;
