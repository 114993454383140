import { CSSProperties } from "react";

export const rightMenuStyle: CSSProperties = {
  minWidth: 320,
};
export const menuItemAccountStyle: CSSProperties = {
  color: "#597EF7",
};
export const avatarBoxStyles: CSSProperties = {
  textAlign: "center",
};

export const menuItemStyle: CSSProperties = {
  textAlign: "left",
  color: "black",
};
