import { Col, Form, Input, Row, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { useFormRule } from "hooks/useFormRule";
import { FC, memo, useContext } from "react";
import { ProjectContext } from "../..";

const { Title } = Typography;

const ContactInfo: FC = memo(() => {
  const { isEdit } = useContext(ProjectContext);
  const translate = useTranslate();
  const { phone, email } = useFormRule();

  return (
    <div style={{ backgroundColor: "white", padding: 24 }} className="h-full">
      <Title level={5}>{translate("Thông tin liên hệ")}</Title>
      <Form.Item
        label={translate("Tên khách hàng liên hệ")}
        name="contactCustomerName"
        rules={[
          { required: true, message: translate("errors.ER005") },
          {
            max: 300,
            message: translate("errors.ER014", { max: "300" }),
          },
        ]}
      >
        <Input placeholder="Nhập tên khách hàng" />
      </Form.Item>
      <Row gutter={16}>
        <Col span={isEdit ? 24 : 12}>
          <Form.Item
            label={translate("Số điện thoại")}
            name="contactCustomerPhone"
            rules={phone}
            className={isEdit ? "" : "m-0"}
          >
            <Input placeholder="Nhập số điện thoại" />
          </Form.Item>
        </Col>
        <Col span={isEdit ? 24 : 12}>
          <Form.Item
            label={translate("Email")}
            name="contactCustomerEmail"
            rules={email}
            className="m-0"
          >
            <Input placeholder="Nhập email" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
});

export default ContactInfo;
