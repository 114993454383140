import { Input, InputProps } from "@pankod/refine-antd"

const InputUpper = (props: InputProps) => {
    const { value, onChange, ...otherProps } = props;
    return <Input
        {...otherProps}
        value={value ? (value + '').toUpperCase() : value}
        onChange={e => {
            e.target.value = (e.target.value +'').toUpperCase();
            onChange && onChange(e)
        }}

    />
}

export default InputUpper