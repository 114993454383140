import { Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { ShowCustom } from "components/layout";
import { FC } from "react";
import CaseIdInfo from "./components/CaseIdInfo";
import { BusinessWorkIdContext } from "./context";
import { useController } from "./controller";
import "./styles.less";
const { Text } = Typography;

const CaseIdShow: FC<IResourceComponentsProps> = (props) => {
  const {
    record,
    isLoading,
    translate,
    contextValue,
  } = useController();

  return (
    <BusinessWorkIdContext.Provider value={contextValue}>
      <div className="caseid-show-container">
        <ShowCustom
          {...props}
          isLoading={isLoading}
          breadcrumbText={record?.code}
          title={
            <Text style={{ marginBottom: 0 }}>
              {translate("caseId.caseId")}:{" "}
              <Text className="primary">{record?.code}</Text>
            </Text>
          }
          contentStyles={{ backgroundColor: "transparent", padding: 0 }}
          headerButtons={<div />}
          bodyStyle={{ padding: 0 }}
        >
          <CaseIdInfo />
        </ShowCustom>
      </div>
    </BusinessWorkIdContext.Provider>
  );
};

export default CaseIdShow;
