
import { Tooltip, TextField } from "@pankod/refine-antd";
import { BaseKey, useTranslate } from '@pankod/refine-core'
import { PATH } from "configs/path";

interface Props {
    value?: any
    defaultValue?: any
    resource?: string,
    showFullInfo?: boolean,
    showTooltip?: boolean
}

export const LinkText = ({ value, defaultValue,  resource, showFullInfo, showTooltip = true }: Props) => {
    
    const displayText = (showFullInfo && value) ? `${value?.code} - ${value?.description}` : (value?.code || defaultValue || '-')
    const tooltip = showTooltip ? value?.description : ''

    return  <Tooltip placement="bottom" title={tooltip} arrowPointAtCenter>
        <a className="primary" href={`/${resource}/show/${value?.id}`} target="_blank" onClick={(e) => e.stopPropagation()} rel="noreferrer">{displayText}</a>
    </Tooltip>
}