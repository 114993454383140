import React from "react";
import {
  useTranslate,
  IResourceComponentsProps
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  Icons,
  useTable
} from "@pankod/refine-antd";
import { EmptyData, ButtonTooltip } from "components";
import { PATH } from "configs/path";
import { useNavigate } from 'react-router-dom'
import usePermissions from 'hooks/permission'

export const RolesEmployeeList: React.FC<IResourceComponentsProps> = ({options}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { checkEditHaveHelperText } = usePermissions()

  const { tableProps } = useTable<any>({
    resource: PATH.userTenantMisRoles,
    metaData: {
      employeeId: options?.employeeId,
    },
  })

  return (
    <List
      breadcrumb={false}
      title={t('userRoles.userRoles')}
      headerButtons={
        <ButtonTooltip
          type="primary"
          icon={<Icons.EditOutlined />}
          onClick={()=> navigate(`${PATH.userTenantMisRoles}/edit/${options?.employeeId}?code=${options?.employeeCode}`)}
          {...checkEditHaveHelperText(PATH.userTenantMisRoles)}
        >
          {t('buttons.edit')}
        </ButtonTooltip>
      }
    >
      <div className="list-content">
        <Table
          {...tableProps}
          size="small"
          rowKey="ordinal"
          pagination={false}
          locale={{
            emptyText: <EmptyData text={t('userRoles.noDataSearch')} style={{paddingTop: 8, paddingBottom: 24}}/>
          }}
        >
          <Table.Column
            key="ordinal"
            dataIndex="ordinal"
            title={t("common.no")}
            render={(_, __, index) => <TextField value={index + 1} />}
          />
          <Table.Column
            dataIndex="misRoleName"
            key="misRoleId"
            title={t('userRoles.fields.misRoleId')}
          />
          <Table.Column
            dataIndex="departmentName"
            key="departmentId"
            title={t('userRoles.fields.departmentId')}
            render={(value) => <TextField value={value || t('common.personal')} />}
          />
        </Table>
      </div>
    </List>
  );
};
