import { FC, useMemo, SVGProps } from "react";
import { ReactComponent as IconPeronAdd } from "assets/icons/person-add.svg";
import { ReactComponent as IconPeronRemove } from "assets/icons/person-remove.svg";
import { ReactComponent as IconDimand } from "assets/icons/dimand.svg";
import { ReactComponent as IconBox } from "assets/icons/box.svg";
import { ReactComponent as IconFile } from "assets/icons/file.svg";
import { ReactComponent as IconWallet } from "assets/icons/wallet.svg";
import { ReactComponent as IconKey } from "assets/icons/key.svg";
import { ReactComponent as IconCheckedShield } from "assets/icons/checked-shield.svg";
import { ReactComponent as IconStore } from "assets/icons/store.svg";
import { ReactComponent as IconQuotation } from "assets/icons/quotation.svg";
import { ReactComponent as IconComplexBox } from "assets/icons/complex-box.svg";
import { ReactComponent as IconSettlement } from "assets/icons/settlement.svg";

import { Icon as AntdIcon } from "@pankod/refine-antd";
import "./styles.less";

export interface Props extends SVGProps<SVGSVGElement> {
  iconName: IconName;
  color?: string;
  containerClassName?: string;
  hoverColor?: string;
  disabled?: boolean;
}

export enum IconName {
  personAdd = "personAdd",
  personRemove = "personRemove",
  dimand = "dimand",
  box = "box",
  file = "file",
  wallet = "wallet",
  key = "key",
  checkedShield = "checkedShield",
  store = "store",
  quotation = "quotation",
  complexBox = "complexBox",
  settlement = "settlement",
}

const iconsMapper: {
  [key in IconName]: FC<SVGProps<SVGSVGElement> & { title?: string }>;
} = {
  personAdd: IconPeronAdd,
  personRemove: IconPeronRemove,
  dimand: IconDimand,
  box: IconBox,
  file: IconFile,
  wallet: IconWallet,
  key: IconKey,
  checkedShield: IconCheckedShield,
  store: IconStore,
  quotation: IconQuotation,
  complexBox: IconComplexBox,
  settlement: IconSettlement,
};

export const Icon: FC<Props> = (props) => {
  const {
    iconName,
    color = "",
    hoverColor = "",
    containerClassName = "",
    disabled,
  } = props;

  const IconMaped = useMemo(() => iconsMapper[iconName], [iconName]);

  return (
    <AntdIcon
      className={`icon-container ${containerClassName}`}
      style={{
        opacity: disabled ? 0.7 : 1,
        color: color,
        "--hover-color": hoverColor,
      }}
      component={IconMaped}
    />
  );
};
