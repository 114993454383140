import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Table,
  Icons,
  Modal,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { TENANT_ID, TOKEN_KEY } from "configs/storage";
import { ChangeEvent, useState } from "react";
import ButtonConfirm from "./ButtonConfirm";
import { v4 as uuid } from "uuid";
import { onGetLinkView } from "./GetLinkPreview";

const { Text } = Typography;

export const MAX_FILE = 10;
export const MAX_SIZE = 50; // MB

export const acceptExtends = [
  "jpg",
  "jpeg",
  "png",
  "heic",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "heic",
  "pdf",
  "xml",
];
export const acceptFiles = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/heic",
  "text/xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
];

export interface IUploadMulti {
  title?: string;
  value?: any;
  onChange?: Function;
  repo?: string;
  required?: boolean;
  url?: string;
  onRemoveFile?: (file: any) => void;
  idKey?: string;
  onUploadSuccess?: Function;
  onClickButtonUpload?: (onUpload: () => void | undefined) => void;
  pathPreviewFile?: string;
}

const UploadMulti = (props: IUploadMulti) => {
  const {
    title,
    value,
    onChange,
    repo,
    required,
    url,
    onRemoveFile,
    idKey = "uid",
    onUploadSuccess,
    onClickButtonUpload,
    pathPreviewFile,
  } = props;
  
  const t = useTranslate();
  const action =
    url || process.env.REACT_APP_API_URL + "api/" + repo + "/uploads";

  const [isUploading, setUploading] = useState(false);

  const [isMaxFile, setMaxFile] = useState(false);
  const [isMaxSize, setMaxSize] = useState(false);
  const [isWrongType, setWrongType] = useState(false);

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      var files: any = [];
      for (var i = 0; i < e.target.files.length; i++) {
        var file = e.target.files[i];
        var extend = file.name.split(".").pop() || "";
        const isRightType = acceptExtends.indexOf(extend.toLowerCase()) > -1;
        const isLessThan5M = file.size / 1024 / 1024 < MAX_SIZE;
        if (!isLessThan5M) {
          setMaxSize(true);
        }
        if (!isRightType) {
          setWrongType(true);
        }

        if (isLessThan5M && isRightType) {
          files.push(file);
        }
      }

      if (files.length + (value || []).length > MAX_FILE) {
        setMaxFile(true);
      } else {
        uploadFile(files.slice(0, 10 - (value || []).length), pathPreviewFile);
      }
      if (document.getElementById(idInput)) {
        var el = document.getElementById(idInput) as any;
        el.value = "";
      }
    }
  };

  const uploadFile = async (files: any[], pathPreviewFile?: string) => {
    if (!files.length) return;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`files`, file);
    });

    setUploading(true);
    await fetch(action, {
      method: "POST",
      body: formData,
      headers: {
        authorization: "Bearer " + localStorage.getItem(TOKEN_KEY) || "",
        tenantId: localStorage.getItem(TENANT_ID) || "",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setUploading(false);
        if (onUploadSuccess) onUploadSuccess(res.data);
        if (onChange) {
          var newList = res.data.map((a: any) => ({
            ...a,
            [idKey]: a.fileUrl,
          }));
          onChange([...(value || []), ...newList]);
        }
      })
      .catch(() => {
        setUploading(false);
      });
  };
  const idInput = uuid();

  const onOpenPopupSelectFile = () => document.getElementById(idInput)?.click();

  return (
    <>
      <input
        type="file"
        accept={acceptFiles.join(",")}
        multiple
        id={idInput}
        onChange={(e) => onChangeFile(e)}
        hidden
      />
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row align="middle">
          <Col flex={1}>
            <Text style={{ fontWeight: 500 }}>
              {required && <span className="required-mark">*</span>}
              {title}
            </Text>
          </Col>
          <Button
            type="primary"
            icon={<Icons.UploadOutlined />}
            onClick={() => {
              if (onClickButtonUpload) {
                onClickButtonUpload(onOpenPopupSelectFile);
              } else {
                onOpenPopupSelectFile();
              }
            }}
          >
            {t("common.upload.button")}
          </Button>
        </Row>
        <Table
          dataSource={value || []}
          size="small"
          rowKey={idKey}
          pagination={{ pageSize: 1000, hideOnSinglePage: true }}
          locale={{
            emptyText: " ",
          }}
          scroll={{ y: value && value.length > 0 ? 200 : 0 }}
          loading={isUploading}
        >
          <Table.Column
            title={t("common.upload.requiredFile")}
            render={(v, item: any) => {
              var out = [];
              if (item?.status === "uploading") {
                out.push(<Icons.LoadingOutlined />);
              }
              if (item?.status === "error") {
                out.push(<Icons.CloseCircleOutlined />);
              }
              out.push(" ");
              out.push(
                item?.fileUrlOrigin ||
                item?.name ||
                item?.originalName ||
                item?.fileUrlOriginal
              );
              return out;
            }}
          />
          <Table.Column
            width={120}
            align="center"
            // style={{ margin: 0 }}
            render={(v, item: any) => (
              <>
                {pathPreviewFile && <Button
                  type="text"
                  icon={<Icons.EyeOutlined />}
                  onClick={onGetLinkView({ fileUrl: item.fileUrl, path: pathPreviewFile! })}
                />}
                <ButtonConfirm
                  text={t("common.upload.confirmDelete")}
                  size="small"
                  onClick={() => {
                    if (onChange) {
                      onChange(
                        value.filter((a: any) => a["fileId"] !== v["fileId"])
                      );
                    }
                    onRemoveFile && onRemoveFile(item);
                  }}
                  type="text"
                >
                  <Icons.DeleteOutlined />
                </ButtonConfirm>
              </>
            )}
          />
        </Table>
      </Space>

      <Modal
        title=""
        visible={isMaxSize || isMaxFile || isWrongType}
        onOk={() => {
          setMaxFile(false);
          setMaxSize(false);
          setWrongType(false);
        }}
        onCancel={() => {
          setMaxFile(false);
          setMaxSize(false);
          setWrongType(false);
        }}
        okText={t("common.understood")}
        cancelButtonProps={{ hidden: true }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <Space direction="vertical">
            {[
              isMaxSize && (
                <>
                  <Text
                    style={{ fontWeight: 500, fontSize: 16, marginBottom: 0 }}
                  >
                    {t("common.upload.errorTitleSize")}
                  </Text>
                  <Text>{t("common.upload.errorDescSize")}</Text>
                  <br />
                </>
              ),
              isWrongType && (
                <>
                  <Text
                    style={{ fontWeight: 500, fontSize: 16, marginBottom: 0 }}
                  >
                    {t("common.upload.errorTitleType")}
                  </Text>
                  <Text>{t("common.upload.errorDescType")}</Text>
                  <br />
                </>
              ),
              isMaxFile && (
                <>
                  <Text
                    style={{ fontWeight: 500, fontSize: 16, marginBottom: 0 }}
                  >
                    {t("common.upload.errorTitleCount")}
                  </Text>
                  <Text>{t("common.upload.errorDescCount")}</Text>
                </>
              ),
            ]}
          </Space>
        </Space>
      </Modal>
    </>
  );
};

export default UploadMulti;
