import { Col, Row, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { FC, memo, useMemo } from "react";
import CostByMoney from "./CostByMoney";
import { generateCurrency } from "components/Currency";
import ConstByManday from "./CostByManday";
import TotalCost from "./TotalCost";
import styled from "styled-components";

const { Title, Text } = Typography;

const StyledWrapper = styled.div`
  background-color: #ffffff;
  padding: 24px;
`;

const Cost: FC = memo(() => {
  const translate = useTranslate();

  const currency = useMemo(() => {
    return generateCurrency();
  }, []);

  return (
    <StyledWrapper>
      <Row align="middle">
        <Col flex={1}>
          <Title level={4}>{translate("Chi phí")}</Title>
        </Col>
        <div style={{ textAlign: "right" }}>
          <Text className="block">
            {translate("common.currency.unit")}:{" "}
            <Text style={{ color: currency.unitColor, fontWeight: "bold" }}>
              {currency.unit}
            </Text>
          </Text>
        </div>
      </Row>
      <CostByMoney />
      <ConstByManday />
      <TotalCost />
    </StyledWrapper>
  );
});

export default Cost;
