import { Button, Col, Row, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { FlagOutlined } from "@ant-design/icons";

const { Text } = Typography;

const EndNode = () => {
  const t = useTranslate();
  return (
    <Row gutter={[8, 16]}>
      <Col span={24}>
        <Text>{t("workflows.fields.stepName")}</Text>
      </Col>
      <Col span={24}>
        <Button
          disabled
          style={{ backgroundColor: "#CAD8A9", color: "black" }}
          icon={<FlagOutlined />}
        >
          {t("workflows.fields.endNodeName")}
        </Button>
      </Col>
    </Row>
  );
};

export default EndNode;
