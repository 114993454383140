import { Col, Form, Row, Typography } from "@pankod/refine-antd";
import { useOne, useTranslate } from "@pankod/refine-core";
import { useSearchParams } from "@pankod/refine-react-router-v6";
import { ApprovalStatus } from "api/enums";
import { SelectCustom } from "components";
import CCollapse, { CaseInfoPanel } from "components/CCollapse";
import ModalConfirm from "components/ModalConfirm";
import { API_PATH } from "configs/path";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import {
  IForm,
  ServiceRequestFormContext,
} from "pages/sale/serviceRequest/create";
import { FC, memo, useContext } from "react";
const { Title } = Typography;
const { useWatch, useFormInstance } = Form;

interface CaseDetailPopupProps {
  caseDetail?: ICaseInfo;
  hidden?: boolean;
  loading?: boolean;
}
export const CaseDetailPopup: FC<CaseDetailPopupProps> = memo((props) => {
  const translate = useTranslate();
  const { caseDetail, hidden, loading } = props;
  return (
    <CCollapse
      hidden={hidden}
      loading={loading}
      title={translate("workid.sale.title")}
    >
      <CaseInfoPanel caseInfo={caseDetail} />
    </CCollapse>
  );
});

const CaseInfo: FC = memo((props) => {
  const { initialValues, isEdit } = useContext(ServiceRequestFormContext);
  const translate = useTranslate();
  const form = useFormInstance<IForm>();

  const caseId = useWatch("caseId", form);

  const onChangeCaseId = (isDone?: boolean) => (value: string) => {
    if (caseId && !isDone) return;
    form.setFieldsValue({
      caseId: value,
    });
  };

  const onCancelConfirm = () => form.setFieldsValue({ caseId });

  const resCase = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(caseId || ""),
    id: "",
    queryOptions: { enabled: !!caseId },
  });

  const caseDetail = resCase?.data?.data;
  const loadingCase = resCase?.isFetching;
  const params = useSearchParams();

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("cases.info")}</Title>
      <div style={{ paddingLeft: 16 }}>
        <Row gutter={16}>
          <Col lg={12} xs={24}>
            <Form.Item
              label={translate("cases.fields.case")}
              className={caseId ? "" : "m-0"}
              name="caseId"
              initialValue={params[0].get("caseId")}
              rules={[{ required: true, message: translate("errors.ER005") }]}
            >
              <ModalConfirm
                title={translate("serviceRequests.confirm.changeCaseType")}
                enable={!!caseId}
                desc={
                  translate("serviceRequests.confirm.changeCaseNote")
                }
                onCancel={onCancelConfirm}
                onChange={onChangeCaseId()}
                onDone={onChangeCaseId(true)}
              >
                <SelectCustom
                  resource={API_PATH.serviceRequestCaseDropdownlist}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={translate("cases.placeholder.selectWork")}
                  disabled={
                    isEdit && initialValues?.status !== ApprovalStatus.Draft
                  }
                />
              </ModalConfirm>
            </Form.Item>
          </Col>
        </Row>
        <CaseDetailPopup
          hidden={!caseId}
          caseDetail={caseDetail}
          loading={loadingCase}
        />
      </div>
    </div>
  );
});

export default CaseInfo;
