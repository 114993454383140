import React from "react";
import {
	Row,
	Col,
	AntdLayout,
	Card,
	Typography,
	Form,
	Input,
	Button,
	Divider,
	Space
} from "@pankod/refine-antd";
import { useLogin, useTranslate } from "@pankod/refine-core";
import InfoCircleFilled from "@ant-design/icons/InfoCircleFilled"
import {
	layoutStyles,
	containerStyles,
	titleStyles
} from "./styles";
import { EXTERNAL_LOGIN, REMEMBER_LOGIN } from 'configs/storage'
import { useNavigate } from 'react-router-dom'
import usePermissions from 'hooks/permission'
import ExternalLogin, { ExternalLoginInformation } from "components/ExternalLogin";
import { PageLoading } from "components/layout/pageLoading";
import LangSelect from "components/LangSelect";
import { NO_AUTH_PATH, PATH } from "configs/path";

const { Paragraph, Title } = Typography;
export interface ILoginForm {
	userName: string;
	password: string;
	remember: boolean;
}

export const CustomLoginPage: React.FC = () => {
	const [form] = Form.useForm<ILoginForm>();
	const t = useTranslate();
	const {isSuperAdmin, isTenantAdmin} = usePermissions()
	const { mutate: login, isLoading } = useLogin<ILoginForm>();
	const [error, setError] = React.useState<string>('');
	const navigate = useNavigate();
	const {
		isUser
	} = usePermissions()

	const onSubmit = (values: ILoginForm) => {
		localStorage.setItem(REMEMBER_LOGIN, 'TRUE');
		setError('')
		login(values, {
			onSuccess: (data) => {
				onLoginSuccess()
			},
			onError: (e: any) => {
				setError(t(`errors.${e?.errorCode}`))
			}
		})
	};

	const onExternalLoginSuccess = (data: ExternalLoginInformation) => {
		const loginBody: any = {
			userName: data.email,
			externalToken: {
				accessToken: data.accessToken,
				idToken: data.idToken,
				refreshToken: data.refreshToken,
			}
		}
		login(loginBody, {
			onSuccess: (data) => {
				onLoginSuccess()
			},
			onError: (e: any) => {
				setError(t(`errors.${e?.errorCode}`))
			}
		})
	}

	const onLoginSuccess = () => {
		const redirectUrl = (isTenantAdmin() || isSuperAdmin()) ? `/${PATH.dashboards}` : `/${PATH.myTenants}`
		localStorage.removeItem(EXTERNAL_LOGIN)
		navigate(redirectUrl)
	}

	const CardTitle = (
		<Space direction="vertical" style={{textAlign: 'center', paddingTop: 30, width: '100%'}} >
			 <img src={"/logo.svg"} style={{ width: "120px" }}/>
			<Title level={2} style={titleStyles}>
				{t("pages.login.title")}
			</Title>
		</Space>
	);

	return (
		<AntdLayout style={layoutStyles} className="layout-login">
			<LangSelect isFixed isDark />
			<PageLoading isLoading={isLoading} />
			<Row
				justify="center"
				align="middle"
				style={{
					height: "100vh",
				}}
			>
				<Col xs={22}>
					<div style={containerStyles}>
						<Card title={CardTitle} headStyle={{ borderBottom: 0 }} bodyStyle={{paddingTop: 10}} style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 10 }}>
							{!!error && <Paragraph
								type="danger"
								style={{ marginBottom: "16px" }}
							><InfoCircleFilled /> {error}</Paragraph>}

							<div>
								<ExternalLogin onLoginSuccess={onExternalLoginSuccess} />
							</div>

							<Divider style={{ color: '#8C8C8C' }}>{t('account.login.password')}</Divider>
							<Form<ILoginForm>
								layout="vertical"
								className="login-form"
								form={form}
								onFinish={(values) => {
									onSubmit(values)
								}}
								onChange={() => { setError('');}}
								requiredMark={false}
								initialValues={{}}
							>
								<Form.Item
									name="userName"
									label={t(
										"pages.login.email"
									)}
									rules={[
										{
											required: true,
											message: t("errors.ER005"),
										},
										{
											type: 'email',
											message: t("errors.ER015")
										},
										{
											max: 255,
											message: t("errors.ER014", { max: '255' }),
										},
									]}
									style={{ marginBottom: "12px" }}
								>
									<Input
										size="large"
										placeholder={t("pages.login.emailPlaceholder")}
									/>
								</Form.Item>
								<Form.Item
									name="password"
									label={t("pages.login.password", "Password")}
									rules={[
										{
											required: true,
											message: t("errors.ER005"),
										},
									]}
									style={{ marginBottom: "48px" }}
								>
									<Input.Password
										placeholder={t("pages.login.passwordPlaceholder")}
										size="large"
									/>
								</Form.Item>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
									loading={isLoading}
									block
								>
									{t("pages.login.signin")}
								</Button>
								<Row>
									<Col span={24}>
										<Button
											type="link"
											size="large"
											style={{ color: "black" }}
											block
											onClick={() => navigate(`/${NO_AUTH_PATH.forgotPassword}`)}
										>
											{t("pages.login.forgotPassword")}
										</Button>
									</Col>
								</Row>
							</Form>
						</Card>
					</div>
				</Col>
			</Row>
		</AntdLayout>
	);
};
