import { useTranslate } from "@pankod/refine-core";
import { Form, Input, Typography, notification } from "antd";
import { FC, memo } from "react";
import { Box } from "../CaseInfo";
import UploadMulti from "components/UploadMulti";
import { API_PATH } from "configs/path";
import { IForm } from "../../..";

const { Title } = Typography;
const { TextArea } = Input;
const { useWatch, useFormInstance } = Form;

const General: FC = memo(() => {
  const form = useFormInstance<IForm>();

  const translate = useTranslate();
  const caseId = useWatch("caseId", form);

  return (
    <Box>
      <Title level={5}>{translate("Thông tin tổng quan")}</Title>
      <div style={{ paddingLeft: 16 }}>
        <Form.Item
          label={translate("Ghi chú")}
          name="note"
          rules={[
            { required: true, message: translate("errors.ER005") },
            {
              max: 3000,
              message: translate("errors.ER014", { max: "3,000" }),
            },
          ]}
        >
          <TextArea rows={4} placeholder={translate("Nhập nội dung")} />
        </Form.Item>
        <Form.Item
          name="contractEstimatedDocuments"
          validateStatus="success"
          label=""
          className="m-0"
        >
          <UploadMulti
            title={translate("common.fields.attachments")}
            url={`${process.env.REACT_APP_API_URL}${API_PATH.interEstimateUploadFile}?caseId=${caseId}`}
            idKey="id"
            onClickButtonUpload={(action) => {
              if (!caseId) {
                notification.error({
                  message: translate(
                    "Vui lòng chọn mã vụ việc trước khi tải file."
                  ),
                });
              } else {
                action();
              }
            }}
          />
        </Form.Item>
      </div>
    </Box>
  );
});

export default General;
