import { FC } from "react";
import { useController } from "./controller";
import {
  Button,
  DeleteButton,
  Form,
  Icons,
  List,
  Select,
  Space,
  Table,
  Tag,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { ButtonTooltip, EmptyData, SearchBox } from "components";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { formatISODateTimeToView, searchSelect } from "utils/commons";
import CTableColumn from "components/CTableColumn";
import ModalDelete from "./components/ModalDelete";
import AssignExploiting from "interfaces/AssignExploiting";
import { DATE_TIME_FORMAT } from "configs/constants";

const Column = CTableColumn<AssignExploiting>();

const AssignExploitingsList: FC = () => {
  const {
    formProps,
    form,
    table: {
      tableQueryResult: { data: dataTable, isLoading },
      sorter,
      pageSize,
      current,
      filters,
      setSorter,
      setPageSize,
      setCurrent,
      setFilters,
    },
    toggleModalDelete,
    currentRecord,
    visibleModalDelete,
    setCurrentRecord,
    customerSelectProps,
    productSelectProps,
    filterByCustomer,
    filterByProduct,
  } = useController();
  const { edit, show, create } = useNavigation();
  const {
    checkCreateHaveHelperText,
    checkEditHaveHelperText,
    checkDeleteHaveHelperText,
  } = usePermissions();
  const translate = useTranslate();

  return (
    <>
      <List
        title={translate("assignExploiting.titles.list")}
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.assignExploitings)}
            {...checkCreateHaveHelperText(PATH.assignExploitings)}
          >
            {translate("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Form {...formProps}>
            <Space>
              <SearchBox
                placeholder={translate("assignExploiting.search")}
                onSearch={(e) => {
                  setFilters([
                    {
                      field: "q",
                      operator: "eq",
                      value: e,
                    },
                  ]);
                }}
              />
              <Space>
                <Form.Item name={["customerId"]} style={{ marginBottom: 16 }}>
                  <Select
                    {...customerSelectProps}
                    allowClear
                    onChange={(e) => {
                      setFilters([
                        {
                          field: "customerId",
                          operator: "eq",
                          value: e,
                        },
                      ]);
                    }}
                    className={"placeholderBlack"}
                    dropdownStyle={{ color: "black" }}
                    style={{ width: 150 }}
                    bordered={false}
                    placeholder={translate("assignExploiting.customer")}
                    filterOption={(inputValue: string, option: any) =>
                      option && searchSelect(inputValue, option?.label)
                    }
                    disabled={filterByProduct}
                  />
                </Form.Item>
                <Form.Item name={["productId"]} style={{ marginBottom: 16 }}>
                  <Select
                    {...productSelectProps}
                    allowClear
                    onChange={(e) => {
                      setFilters([
                        {
                          field: "productId",
                          operator: "eq",
                          value: e,
                        },
                      ]);
                    }}
                    className={"placeholderBlack"}
                    dropdownStyle={{ color: "black" }}
                    style={{ width: 160 }}
                    bordered={false}
                    placeholder={translate("assignExploiting.productService")}
                    filterOption={(inputValue: string, option: any) =>
                      option && searchSelect(inputValue, option?.label)
                    }
                    disabled={filterByCustomer}
                  />
                </Form.Item>
              </Space>

              {filters &&
                filters.length > 0 &&
                !(
                  filters.length === 1 && filters[0]?.operator === "containss"
                ) && (
                  <Button
                    type="text"
                    onClick={(e) => {
                      form.resetFields();
                      setFilters([], "replace");
                    }}
                    style={{ color: "#8C8C8C", marginBottom: 16 }}
                  >
                    {translate("common.cleanFilter")}
                    <Icons.CloseOutlined />
                  </Button>
                )}
            </Space>
          </Form>
          <Table
            sticky
            loading={isLoading}
            dataSource={dataTable?.data}
            rowKey="id"
            locale={{
              emptyText: <EmptyData text={translate("common.noDataSearch")} />,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  show(PATH.assignExploitings, record.id);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: translate("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{translate("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, __, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field:
                    newSort?.field === "department"
                      ? "department.name"
                      : newSort?.field === "title"
                      ? "title.name"
                      : newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            scroll={{ x: "2000px" }}
            showSorterTooltip={false}
          >
            <Column
              key="employeeCode"
              dataIndex="employee"
              title={translate("assignExploiting.employeeCode")}
              render={(value, record) => <TextField value={value?.code} />}
              defaultSortOrder={getDefaultSortOrder("employee.code", sorter)}
              sorter
            />
            <Column
              key="employeeName"
              dataIndex="employee"
              title={translate("assignExploiting.employeeName")}
              render={(value, record) => <TextField value={value?.name} />}
              defaultSortOrder={getDefaultSortOrder("employee.name", sorter)}
              sorter
            />
            <Column
              key="assignExploitingType"
              dataIndex="assignExploitingType"
              title={translate("assignExploiting.assignExploitingType")}
              render={(value) => (
                <TextField
                  value={translate(`assignExploiting.type.${value?.id}`)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder(
                "assignExploitingType.id",
                sorter
              )}
              sorter
            />

            <Column
              key="customer"
              dataIndex="customer"
              title={translate("assignExploiting.customer")}
              ellipsis
              render={(_, record) =>
                record?.customer?.map((customer: any) => (
                  <Tag color="default">{customer?.name}</Tag>
                ))
              }
              sorter
            />
            <Column
              key="product"
              dataIndex="product"
              title={translate("assignExploiting.products")}
              ellipsis
              render={(_, record) =>
                record?.productOwner?.map((product: any) => (
                  <Tag color="default">{product?.name}</Tag>
                ))
              }
              sorter
            />

            <Column
              key="updatedName"
              dataIndex="updatedName"
              title={translate("common.updatedName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
              sorter
            />

            <Column
              key="updatedDate"
              dataIndex="updatedDate"
              title={translate("common.updatedDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              sorter
            />

            <Column
              key="createdName"
              dataIndex="createdName"
              title={translate("common.createdName")}
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
              sorter
            />

            <Column
              key="createdDate"
              dataIndex="createdDate"
              title={translate("common.createdDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(value, DATE_TIME_FORMAT)}
                />
              )}
              defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
              sorter
            />

            <Column
              title={translate("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => {
                return (
                  <Space>
                    <ButtonTooltip
                      type="text"
                      icon={<Icons.EditOutlined />}
                      className="gray"
                      onClick={(e) => {
                        e.stopPropagation();
                        edit(PATH.assignExploitings, record.id!);
                      }}
                      {...checkEditHaveHelperText(PATH.assignExploitings)}
                    />
                    <div className="line-between-action"></div>
                    <DeleteButton
                      hideText
                      type="text"
                      style={{ color: "#8C8C8C" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleModalDelete();
                        setCurrentRecord(record);
                      }}
                      {...checkDeleteHaveHelperText(PATH.assignExploitings)}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </div>
      </List>
      <ModalDelete
        visibleModal={visibleModalDelete}
        toggleModal={toggleModalDelete}
        record={currentRecord}
      />
    </>
  );
};

export default AssignExploitingsList;
