import axios, { AxiosInstance } from "axios";
import { stringify } from "query-string";
import {
  DataProvider,
  HttpError,
  CrudOperators,
  CrudFilters,
  LogicalFilter,
  CrudSorting,
  Pagination,
  BaseRecord,
  BaseKey,
} from "@pankod/refine-core";

import {
  fetchList,
  fetchCreate,
  fetchUpdate,
  fetchDetail,
  fetchDelete,
  createUser,
  updateStatusUser,
  fetchTenants,
  getUserTenantMisRoles,
} from "api";
import { PATH, getApiPath, API_PATH } from "configs/path";
import { UserRequest } from "api/types";
import { formatData } from "./formatData";
import { isEmpty } from "utils/commons";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "ne":
    case "gte":
    case "lte":
      return `_${operator}`;
    case "contains":
      return "_like";
    case "eq":
    default:
      return "";
  }
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const _sort: string[] = [];
    const _order: string[] = [];

    sort.forEach((item) => {
      _sort.push(item.field);
      _order.push(item.order);
    });

    return {
      _sort,
      _order,
    };
  }

  return;
};

const JsonServer = (
  apiUrl = process.env.REACT_APP_API_URL as string,
  httpClient: AxiosInstance = axiosInstance
): DataProvider => ({
  getList: async <R extends BaseRecord = BaseRecord>(params: {
    resource: string;
    pagination?: Pagination;
    sort?: CrudSorting;
    filters?: CrudFilters;
    dataProviderName?: string;
    metaData?: any;
    getById?: string;
  }) => {
    var page = params.pagination?.current as number;
    var perPage = params.pagination?.pageSize as number;
    var q: LogicalFilter[] = [];
    var field = "";
    var order = "";
    const generatedSort = generateSort(params.sort);
    if (generatedSort) {
      const { _sort, _order } = generatedSort;
      field = _sort.join(",");
      order = _order.join(",");
    }
    if (params.filters) {
      q = params.filters as LogicalFilter[];
    }

    switch (params.resource) {
      case PATH.userTenantMisRoles: {
        if (params.metaData?.employeeId) {
          const employeeId = params.metaData?.employeeId;
          var { data } = await getUserTenantMisRoles(employeeId);
          data = formatData(data, params.resource);
          return {
            data: data as unknown as R[],
            total: 0,
          };
        }
        return {
          data: [] as R[],
          total: 0,
        };
      }
      default: {
        let url = getApiPath(params.resource);
        if (params.metaData?.after) {
          url += params.metaData?.after;
        }
        if (params.metaData?.subFilter) {
          url = url + params.metaData?.subFilter;
        }

        const stringSearch = q.find((i) => i.field === "q");

        // if (params.metaData?.selectUseServerFiltering) {
        //   if (!stringSearch || !stringSearch?.value) {
        //     return {
        //       data: [] as R[],
        //       total: 0,
        //     };
        //   }
        // }

        const otherFilters: any = {};
        q.filter((i) => i.field !== "q").forEach((f) => {
          otherFilters[f.field] = f.value;
        });

        let { data, pagingData } = await fetchList(url, {
          ...otherFilters,
          pageNumber: page,
          pageSize: perPage,
          filter: stringSearch?.value,
          sortBy: field,
          orderBy:
            order === "descend" ? "desc" : order === "ascend" ? "asc" : order,
        });

        if (params.metaData?.formatData) {
          data = data.map(params.metaData?.formatData);
        }

        return {
          data: data as unknown as R[],
          total: pagingData?.rowCount ?? 0,
        };
      }
    }
  },
  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${stringify({ id: ids })}`
    );

    return {
      data,
    };
  },

  create: async <R extends BaseRecord, TVariables = {}>(params: {
    resource: string;
    variables: TVariables;
    metaData?: any;
  }) => {
    const dataRequest = params.variables as unknown;
    switch (params.resource) {
      case PATH.users: {
        const { data } = await createUser(dataRequest);
        return { data: data as unknown as R };
      }
      default:
        var url = getApiPath(params.resource);
        if (params.metaData && params.metaData.type) {
          switch (params.metaData.type) {
            case "draft":
              url += "/draft";
              break;
            default:
              url += params.metaData.type;
              break;
          }
        }

        const { data } = await fetchCreate(url, dataRequest);
        return { data: data as unknown as R };
    }
  },
  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(`${apiUrl}/${resource}`, param);
        return data;
      })
    );

    return { data: response };
  },
  update: async <R extends BaseRecord, TVariables = {}>(params: {
    id: BaseKey;
    resource: string;
    variables: TVariables;
    metaData?: any;
  }) => {
    const dataRequest = params.variables as unknown as UserRequest;
    switch (params.resource) {
      case PATH.users: {
        const { data } = await updateStatusUser(dataRequest);
        return { data: data as unknown as R };
      }
      default:
        let url = getApiPath(params.resource);

        if (params.metaData && params.metaData.type) {
          switch (params.metaData.type) {
            case "draft":
              url += "/draft";
              break;
            case "updateStatus":
              url += "/update-status";
              break;
            case "unlinkEmployeeUser":
              url += "/un-link-employee-user";
              break;
            default:
              url += params.metaData.type;
              break;
          }
        }

        if (params.metaData?.method === "POST") {
          const { data } = await fetchCreate(url, dataRequest);
          return { data: data as unknown as R };
        } else {
          const { data } = await fetchUpdate(
            url,
            params.id as string,
            dataRequest,
            params?.metaData?.config
          );
          return { data: data as unknown as R };
        }
    }
  },
  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}`,
          variables
        );
        return data;
      })
    );

    return { data: response };
  },
  getOne: async <R extends BaseRecord = BaseRecord>(params: {
    resource: string;
    id: BaseKey;
    metaData?: any;
  }) => {
    switch (params.resource) {
      case PATH.userTenantMisRoles: {
        const { data } = await getUserTenantMisRoles(params.id as string);
        return { data: data as unknown as R };
      }
      case PATH.workflowsDiagrams: {
        let url = getApiPath(params.resource);
        const { data } = await fetchDetail(
          `${url}/by-approval-procedure`,
          params.id as string
        );
        return { data: data as unknown as R };
      }
      default:
        let url = getApiPath(params.resource);

        if (params.metaData && params.metaData.after) {
          url += params.metaData.after;
        }

        var { data } = await fetchDetail(url, params.id as string);
        data = formatData(
          data,
          params.resource,
          params?.metaData?.isConverting
        );
        return { data: data as unknown as R };
    }
  },
  deleteOne: async <R extends BaseRecord = {}>(params: {
    id: BaseKey;
    resource: string;
    metaData?: any;
  }) => {
    const metaData = params?.metaData;
    switch (params.resource) {
      default:
        const { data } = await fetchDelete(
          getApiPath(metaData?.url || params.resource),
          params.id as string,
          params.metaData.data
        );
        return { data: data[0] as unknown as R };
    }
  },
  deleteMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);
        return data;
      })
    );
    return { data: response };
  },
  getApiUrl: () => {
    return apiUrl;
  },
  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const generatedSort = generateSort(sort);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url);
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

export default JsonServer;
