import { Col, Form, Input, notification, Row, Select, Typography, useForm } from "@pankod/refine-antd"
import { IResourceComponentsProps, useCreate, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core"
import InputUpper from "components/InputUpper"
import { CreateCustom } from "components/layout"
import { API_PATH, PATH } from "configs/path"
import { LOCATION_PROVINCES } from "configs/storage"
import { IRegionConfig } from "interfaces"
import React from "react"
import { isValidConfigCodeField, mappingErrorFromApi } from "utils/commons"
import { searchSelect } from "utils/commons"

const { Text } = Typography
const { TextArea } = Input

export const RegionCreate: React.FC<IResourceComponentsProps> = (props) => {
	const t = useTranslate()

	const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>(false);
	const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreate<IRegionConfig>()
	const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<IRegionConfig>()
	const { form, formProps, saveButtonProps } = useForm<IRegionConfig>();
	const [provinces, setProvinces] = React.useState<any[]>([])

	const isEdit = !!formProps.initialValues?.id
	const record = formProps.initialValues
	const { show, list } = useNavigation()

	// let options: SelectProps['options'] = [];

	const { data: provinceList } = useList<any>({
		resource: API_PATH.configRegions,
		metaData: {
			after: '/provinces/dropdownlist'
		}
	})

	const allProvinces = JSON.parse(localStorage.getItem(LOCATION_PROVINCES) || '[]');

	React.useEffect(()=> {
		const regionProvinces = !isEdit ? [] : (allProvinces.filter((t: any) => record?.provinceIds.indexOf(t.value) !== -1) || []);
		const availableProvinces = (provinceList?.data || []);
		const provinceOptions = [
			...regionProvinces, 
			...(!isEdit ? availableProvinces : availableProvinces.filter((t: any) => record?.provinceIds.indexOf(t.value) === -1))
		];
		
		setProvinces(provinceOptions);
	}, [record, provinceList])

	const onSubmit = () => {
		const handleResponse = {
			onSuccess: (res: any) => {
				notification.success({
					message: isEdit ? t('common.config.editSuccess') : t('common.config.createSuccess'),
				})
				show(PATH.configRegions, res?.data?.id)
			},
			onError: (error: any) => {
				setVisibleShowModal(false)
				const errorFieldMapping: any = {
					ER0152: 'provinceIds',
				}
				mappingErrorFromApi(error, form, errorFieldMapping)
			},
		}

		const dataSend = {
			resource: PATH.configRegions,
			values: {
				name: form.getFieldValue('name').trim(),
				code: form.getFieldValue('code').trim(),
				description: form.getFieldValue('description'),
				provinceIds: form.getFieldValue('provinceIds') || [],
			},
			id: '',
		}

		if (isEdit) {
			dataSend.id = formProps.initialValues?.id || ''
			mutateUpdate(dataSend, handleResponse)
		} else {
			mutateCreate(dataSend, handleResponse)
		}
	}

	return (
		<CreateCustom
			title={
				isEdit ? 
				(<Text style={{ marginBottom: 0 }}>{t('regions.titles.update')}: <span className="primary">{record?.name}</span> </Text>) : 
				(<Text style={{ marginBottom: 0 }}>{t('regions.titles.create')}</Text>)
			}
			{...props}
			saveButtonProps={{
				...saveButtonProps,
				title: isEdit ? t('common.saveEdit') : t('buttons.add')
			}}
			visibleShowModal={visibleShowModal}
			setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
			onSubmit={() => onSubmit()}
			confirmModalProps={{
				title: !isEdit ? t('regions.messages.createConfirm') : t('common.config.editConfirm'),
				description: isEdit ? t('common.config.editConfirmDesc') : '',
			}}
			saving={isLoadingCreate || isLoadingUpdate}
			cancelModalProps={{
				okButtonProps: {
					onClick: () => {
						isEdit ? show(PATH.configRegions, record?.id) : list(PATH.configRegions)
					},
					danger: true
				},
				cancelButtonProps: {}
			}}
		>
			<Form
				{...formProps}
				onFinish={() => setVisibleShowModal(true)}
				layout="vertical"
				labelCol={{ span: 10 }}
			>
				<div style={{ marginBottom: 12 }}>
					<Text style={{ fontWeight: 500, fontSize: 16 }}>
						{t('common.titles.detail')}{' '}
					</Text>
				</div>

				<Row gutter={12} style={{ width: '100%' }}>
					<Col span={12}>
						<Form.Item
							label={t('regions.fields.code')}
							name="code"
							rules={[
								{
									required: true,
									message: t('errors.ER005'),
								},
								{
									max: 20,
									message: t('errors.ER014', { max: '20' }),
								},
								{
									validator: async (_, value) => {
										if (!value) return;
										if (!isValidConfigCodeField(value)) {
											return Promise.reject(t("errors.ER0139"))
										}
									},
								},
							]}
						>
							<InputUpper
								placeholder={t('regions.placeholder.code')}
								disabled={isEdit}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							label={t('regions.fields.name')}
							name="name"
							rules={[
								{
									whitespace: true,
									required: true,
									message: t('errors.ER005'),
								},
								{
									max: 100,
									message: t('errors.ER014', { max: '100' }),
								},
							]}
						>
							<Input placeholder={t('regions.placeholder.name')} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							label={t('regions.fields.province')}
							name="provinceIds"
							rules={[
								{
									required: true,
									message: t('errors.ER005'),
								}
							]}
						>
							<Select
								mode="multiple"
								size="middle"
								placeholder={t('regions.placeholder.province')}
								style={{ width: '100%' }}
								options={provinces}
								filterOption={
									(inputValue: string, option: any) => {
										return option && searchSelect(inputValue, option?.label)
									}
								}
								/>
						</Form.Item>
					</Col>
					<Col span={12}></Col>

					<Col span={12}>
						<Form.Item
							label={t('regions.fields.description')}
							name="description"
							rules={[
								{
									max: 200,
									message: t('errors.ER014', { max: '200' }),
								},
							]}
						>
							<TextArea
								showCount={{
									formatter: (args: { count: number }) => `${args?.count}/200`,
								}}
								placeholder={t('regions.placeholder.description')}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</CreateCustom>
	)
}