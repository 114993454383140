import React from "react";
import { useTranslate, IResourceComponentsProps, useCreate, useUpdate, useNavigation } from "@pankod/refine-core";
import {
  Form,
  Input,
  useForm,
  notification,
  Typography
} from "@pankod/refine-antd";
import { IBaseConfig } from "interfaces";
import { PATH } from "configs/path";
import { CreateCustom } from "components/layout";
import { useNavigate } from 'react-router-dom'
import { mappingErrorFromApi } from "utils/commons";
import { useParams } from 'react-router-dom'

const { Title, Text } = Typography
const { TextArea } = Input;

export const UnitCreate: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const navigate = useNavigate()
  const { show, list } = useNavigation()
  const { id } = useParams()
  const [ visibleShowModal, setVisibleShowModal ] = React.useState<boolean>(false);
  const { mutate: mutateCreate, isLoading } = useCreate<IBaseConfig>();
  const { mutate: mutateUpdate } = useUpdate<IBaseConfig>()
  const { form, formProps, saveButtonProps, queryResult } = useForm<IBaseConfig>(
    {
      resource: PATH.configUnits,
      id: id
    }
  );
  const record: IBaseConfig | undefined = queryResult?.data?.data
  const isEdit = !!record?.id

  const onSubmit = () => {
    if (isEdit) {
      mutateUpdate(
        {
          id: id || '',
          resource: PATH.configUnits,
          values: {
            name: form.getFieldValue('name').trim(),
            description: form.getFieldValue('description'),
          },
        },
        {
          onSuccess: (res) => {
            notification.success({
              message: t('common.message.editSuccess')
            })
            navigate(`show/${res?.data?.id}`)
          },
          onError: (error: any) => {
            setVisibleShowModal(false)
            const errorFieldMapping: any = {
              'ER051': 'name'
            }
            mappingErrorFromApi(error, form, errorFieldMapping )
          },
        },
      )
    } else {
      mutateCreate(
        {
          resource: PATH.configUnits,
          values: {
            name: form.getFieldValue('name').trim(),
            description: form.getFieldValue('description'),
          },
        },
        {
          onSuccess: (res) => {
            notification.success({
              message: t('common.createSuccess')
            })
            navigate(`show/${res?.data?.id}`)
          },
          onError: (error: any) => {
            setVisibleShowModal(false)
            const errorFieldMapping: any = {
              'ER051': 'name'
            }
            mappingErrorFromApi(error, form, errorFieldMapping )
          },
        },
      )
    }
  }

  return (
    <CreateCustom
      {...props}
      title={
        isEdit
          ? <Text style={{marginBottom: 0}}>{t("units.titles.edit")}: <Text className="primary">{record?.name}</Text></Text>
          : t('units.titles.create')
      }
      confirmModalProps={{
        title: t('units.createConfirm')
      }}
      saveButtonProps={{
        ...saveButtonProps,
        title: isEdit
          ? t('common.saveEdit')
          : t('buttons.add')
      }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      saving={isLoading}
      cancelModalProps={{
				okButtonProps: {
					onClick: () => {
						isEdit ? show(PATH.configUnits, record?.id || '') : list(PATH.configUnits)
					},
					danger: true
				},
				cancelButtonProps: {}
			}}
    >
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout='vertical'
        style={{maxWidth: 500}}
      >
        <Title level={5}>{t('common.titles.detail')}</Title>
        <Form.Item
          label={t("units.fields.name")}
          name="name"
          rules={[
            {
              whitespace: true,
              required: true,
              message: t("errors.ER005"),
            },
            {
              max: 20,
              message: t("errors.ER014", { max: '20'}),
            },
          ]}
        >
          <Input placeholder={t('units.fields.namePlaceholder')}/>
        </Form.Item>
        <Form.Item
          label={t("units.fields.description")}
          name="description"
          rules={[
            {
              max: 200,
              message: t("errors.ER014", { max: '200'}),
            },
          ]}
        >
          <TextArea
            showCount={{
              formatter: (args: { count: number }) => `${args?.count}/200`,
            }}
            placeholder={t('costs.placeholder.description')}
          />
        </Form.Item>
      </Form>
    </CreateCustom>
  );
};
