import {
  GetOneResponse,
  IResourceComponentsProps,
  QueryObserverResult,
  useCreate,
  useList,
  useNavigation,
  useOne,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { CreateCustom } from "components/layout";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { formatNumber, isEmpty, mappingErrorFromApi } from "utils/commons";
import { API_PATH, PATH } from "configs/path";
import {
  BusinessPlanObjectData,
  Product,
  TPlanItem,
} from "interfaces/BusinessPlan";
import BusinessPlanInformation from "./components/Information";
import { Form, notification, useForm } from "@pankod/refine-antd";
import { cloneDeep } from "lodash";
import TableBusinessPlan from "./components/TableBusinessPlan";
import { useParams } from "@pankod/refine-react-router-v6";
import { ApprovalStatus } from "api/enums";
import {
  getBusinessPlanFinacialIndicator,
  getProductsForBusinessPlan,
} from "api";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import { ONE_THOUSAND_TRILLION } from "configs/constants";
import ButtonConfirm from "components/ButtonConfirm";

export interface BusinessPlanOverViewProductForm {
  productName?: string;
  productCode?: string;
  productId: string;
  partNumber: string;
  description: string;
  quantity: number;
  unitCost: number;
  unitPrice: number;
  unitId: string;
  unitName: string;
  id: string;
}
export interface IForm {
  caseId: string;
  businessPlanOverViewProduct: {
    [key: string]: BusinessPlanOverViewProductForm;
  };
  note: string;
  businessPlanOverViewDocuments: { fileId: string }[];
}

interface State {
  sumPrice: number;
  sumCost: number;
  initialValues: Partial<BusinessPlanObjectData>;
  isEdit: boolean;
  canEditCaseId: boolean;
  productState: [string[], Dispatch<SetStateAction<string[]>>];
  caseData?: QueryObserverResult<GetOneResponse<ICaseInfo>, unknown>;
  isLoadingFetchFinancial: boolean;
  planState: [TPlanItem[], Dispatch<SetStateAction<TPlanItem[]>>];
  onFetchFiancialIndicator: (data: {
    caseId: string;
    products: { [key: string]: BusinessPlanOverViewProductForm };
    plansRequest: TPlanItem[];
  }) => void;
  onChangeCase: (id: string) => void;
  getEstimateValueError: (data: TPlanItem) => string;
  stateModalFetchProduct: [boolean, Dispatch<SetStateAction<boolean>>];
  onFetchProducts: (fetchType: FetchProductForBusinessType) => void;
}

export enum TabKey {
  INFO = "info",
  COSTS = "costs",
  HISTORY = "history",
}

export enum FetchProductForBusinessType {
  OPPOTUNITY = "oppotunity",
  ESTIMATE_COST = "estimate_cost",
}

const { useWatch } = Form;

let timeoutFetchTableCalc: any;

const Container = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-direction: column;
  gap: 8px;
`;

export const BusinessPlanContext = createContext<State>({
  sumCost: 0,
  sumPrice: 0,
  initialValues: {},
  isEdit: false,
  canEditCaseId: false,
  productState: [[], () => {}],
  isLoadingFetchFinancial: false,
  planState: [[], () => []],
  onFetchFiancialIndicator: () => {},
  onChangeCase: () => {},
  getEstimateValueError: () => "",
  stateModalFetchProduct: [false, () => {}],
  onFetchProducts: (_: FetchProductForBusinessType) => {},
});

const BusinessPlanCreate: FC<IResourceComponentsProps> = memo((props) => {
  const productState = useState<string[]>([uuid()]);
  const [, setProducts] = productState;
  const { id } = useParams();
  const formInstance = useForm<any, any, IForm>();
  const { formProps, saveButtonProps, formLoading, form } = formInstance;
  const initialValues: Partial<BusinessPlanObjectData> =
    formProps?.initialValues || {};
  const [visibleModalWarningDataChange, setVisibleModalWarningDataChange] =
    useState(false);

  const isEdit = !!id;
  const canEditCaseId =
    initialValues?.status === ApprovalStatus.Draft || !isEdit;
  const { show } = useNavigation();
  const translate = useTranslate();
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const planState = useState<TPlanItem[]>([]);
  const [plans, setPlan] = planState;
  const { mutate: mutateCreate, isLoading } = useCreate();
  const [isLoadingFetchFinancial, setIsLoadingFetchFinancial] = useState(false);

  const { mutate: mutateUpdate, isLoading: isUpdating } = useUpdate();
  const businessPlanOverViewProduct = useWatch(
    "businessPlanOverViewProduct",
    form
  );

  const caseId = useWatch("caseId", form);

  const { data } = useList<{ id: string; code: string }>({
    resource: API_PATH.businessPlanStatusForCreate,
  });

  const stateModalFetchProduct = useState(false);
  const [, setModalFetchProduct] = stateModalFetchProduct;

  const status = data?.data;

  const toggleModalWarningDataChange = () =>
    setVisibleModalWarningDataChange((prev) => !prev);

  useOne({
    resource: API_PATH.financialIndicatorMaterData,
    id: "",
    successNotification(data: any) {
      setPlan(data?.data);
      return {
        message: `Successfully fetched.`,
        description: "Success with no errors",
        type: "success",
      };
    },
    queryOptions: {
      enabled: !isEdit,
    },
  });

  const [activeTab, setActiveTab] = useState(TabKey.INFO);

  const onChangeTab = (tabKey: TabKey) => setActiveTab(tabKey);

  const headerTab = useMemo(
    () => [
      {
        name: translate("Thông tin tổng quan"),
        key: TabKey.INFO,
      },
      {
        name: translate("Phương án kinh doanh tổng"),
        key: TabKey.COSTS,
      },
    ],
    []
  );

  const sumPrice = useMemo(() => {
    if (!businessPlanOverViewProduct) return 0;
    return Object.values(businessPlanOverViewProduct)?.reduce(
      (total, next) => total + (next?.quantity || 0) * (next?.unitPrice || 0),
      0
    );
  }, [businessPlanOverViewProduct]);

  const sumCost = useMemo(() => {
    if (!businessPlanOverViewProduct) return 0;
    return Object.values(businessPlanOverViewProduct)?.reduce(
      (total, next) => total + (next?.quantity || 0) * (next?.unitCost || 0),
      0
    );
  }, [businessPlanOverViewProduct]);

  const getEstimateValueError = (data: TPlanItem) => {
    const { isRequired, value, isPersonInput } = data;
    if (isRequired && isEmpty(value)) return translate("errors.ER005");
    if (
      isPersonInput &&
      !isEmpty(value) &&
      (value > ONE_THOUSAND_TRILLION || value < 1)
    ) {
      return translate(`errors.ER091`, {
        min: 1,
        max: formatNumber(ONE_THOUSAND_TRILLION),
      });
    }
    return "";
  };

  const validationDataForm = () => {
    const errors = plans.find((plan) => getEstimateValueError(plan));
    const errorFromServer = plans.filter(
      (plan) => plan.errorCode || plan.errorMessage
    );
    return !errorFromServer?.length && !errors;
  };
  const toggleModalConfirm = async (visible: boolean) => {
    setVisibleModalConfirm(visible);
  };

  const mappedDataToCalc = (data: {
    caseId: string;
    products: BusinessPlanOverViewProductForm[];
    plansRequest: any[];
  }) => {
    const { products, plansRequest, caseId } = data;
    const productsRequest = products?.map(
      ({ productId, unitCost, unitPrice, quantity }) => ({
        productId: productId,
        quantity: quantity || null,
        unitPrice: unitPrice || null,
        unitCost: unitCost || null,
      })
    );
    const indicators = plansRequest.map((d) => ({
      code: d.code,
      value: d.value,
    }));
    return { caseId, productsRequest, indicators };
  };

  const onFinishInformation = () => async () => {
    const open = await validationDataForm();
    if (open) {
      const { indicators, productsRequest } = mappedDataToCalc({
        caseId,
        plansRequest: plans,
        products: Object.values(businessPlanOverViewProduct || {}),
      });
      const isChange = await fetchFinancialIndicator({
        caseId,
        products: productsRequest,
        indicators,
        currentPlans: plans,
        compareAll: true,
      });
      if (isChange) return;
    }
    toggleModalConfirm(open);
  };

  const validateDraft = async () => {
    const validTable = validationDataForm();
    try {
      await form.validateFields();
      if (!validTable) throw new Error("nope");
      const { indicators, productsRequest } = mappedDataToCalc({
        caseId,
        plansRequest: plans,
        products: Object.values(businessPlanOverViewProduct || {}),
      });
      const isChange = await fetchFinancialIndicator({
        caseId,
        products: productsRequest,
        indicators,
        currentPlans: plans,
        compareAll: true,
      });
      if (isChange) throw new Error("nope");
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.resolve(false);
    }
  };

  const onCreate = (isChangeStatus?: boolean) => () => {
    const formData = form.getFieldsValue();

    const businessPlanOverViewProduct = Object.values(
      formData?.businessPlanOverViewProduct || {}
    );

    const { indicators } = mappedDataToCalc({
      caseId,
      plansRequest: plans,
      products: Object.values(businessPlanOverViewProduct || {}),
    });

    const products = businessPlanOverViewProduct.map(
      ({ productId, unitCost, unitPrice, quantity }) => ({
        productId: productId,
        quantity: quantity || null,
        unitPrice: unitPrice || null,
        unitCost: unitCost || null,
      })
    );

    const dataRequest = {
      resource: PATH.businessPlans,
      values: {
        caseId: formData?.caseId,
        originalBusinessPlanId:
          initialValues?.businessPlanOverView?.originalBusinessPlanId || null,
        status: isChangeStatus
          ? status?.[1]?.code
          : formProps?.initialValues?.businessPlanStatus?.code ||
            status?.[0].code,
        financialIndicator: { indicators, caseId, products },
        businessPlanOverView: {
          note: formData?.note,
          businessPlanOverViewDocuments:
            formData?.businessPlanOverViewDocuments?.map((d) => ({
              fileId: d?.fileId,
            })),
          businessPlanOverViewProduct,
        },
      },
      id: formProps?.initialValues?.id,
    };

    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: translate(
            isChangeStatus
              ? "Lưu và chuyển phương án kinh doanh thành công"
              : "Lưu nháp phương án kinh doanh thành công"
          ),
        });

        show(PATH.businessPlans, res?.data?.id);
      },
      onError: (error: any) => {
        toggleModalConfirm(false);
        mappingErrorFromApi(error, form);
      },
    };

    if (isEdit) {
      mutateUpdate(dataRequest, handleResponse);
    } else {
      mutateCreate(dataRequest, handleResponse);
    }
  };

  const fetchFinancialIndicator = async (data: {
    caseId: string;
    products: any[];
    indicators: any[];
    currentPlans: TPlanItem[];
    compareAll?: boolean;
  }): Promise<boolean> => {
    const {
      indicators,
      currentPlans,
      products,
      caseId,
      compareAll = false,
    } = data;
    let isDataChanged = false;
    setIsLoadingFetchFinancial(true);
    try {
      const res = await getBusinessPlanFinacialIndicator({
        caseId,
        indicators,
        products,
      });
      const dataIndicator = res.data.financialIndicator;
      const dataError = res?.data?.errors;
      const newPlans = cloneDeep(currentPlans).map((p) => ({
        ...p,
        errorCode: undefined,
        errorMessage: undefined,
      }));
      Array.from({ length: indicators?.length }).forEach((_, index) => {
        const dataIndex = newPlans[index];
        const valueIndicator = indicators?.[index]?.value;
        const dataIndexNewIndicator = dataIndicator[index];
        if (
          (compareAll || dataIndex.isPersonInput) &&
          (valueIndicator || 0) != (dataIndexNewIndicator?.value || 0)
        ) {
          isDataChanged = true;
        }
        const error = dataError.find((er: any) => er.field === dataIndex.code);
        if (error) {
          dataIndex.errorCode = error.errorCode;
          dataIndex.errorMessage = error.message;
        }
        newPlans[index] = { ...dataIndex, ...dataIndexNewIndicator };
      });
      if (isDataChanged) toggleModalWarningDataChange();
      setPlan(newPlans);
    } catch (error) {
      //
    } finally {
      setIsLoadingFetchFinancial(false);
    }
    return isDataChanged;
  };

  const onFetchFiancialIndicator = (data: {
    caseId: string;
    products: { [key: string]: BusinessPlanOverViewProductForm };
    plansRequest: any[];
  }) => {
    const { caseId, indicators, productsRequest } = mappedDataToCalc({
      ...data,
      products: Object.values(data?.products || {}),
    });

    if (timeoutFetchTableCalc) clearTimeout(timeoutFetchTableCalc);
    timeoutFetchTableCalc = setTimeout(
      () =>
        fetchFinancialIndicator({
          caseId,
          products: productsRequest,
          indicators,
          currentPlans: plans,
        }),
      1000
    );
  };

  const onChangeCase = async (caseId: string) => {
      onFetchFiancialIndicator({
        caseId,
        plansRequest: plans?.map((p) => ({ ...p, value: null })),
        products: businessPlanOverViewProduct,
      });
  };

  const caseData = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(caseId || ""),
    id: "",
    queryOptions: { enabled: !!caseId },
  });

  const onFetchProducts = async (fetchType: FetchProductForBusinessType) => {
    try {
      const ids: string[] = [];
      const defaultProducts: any = {};
      const resProduct = await getProductsForBusinessPlan(
        fetchType === FetchProductForBusinessType.OPPOTUNITY
          ? `${API_PATH.businessPlanProductFromOppotunity}/${caseId}`
          : `${API_PATH.businessPlanProductFromEstimateCost}/${caseId}`
      );

      resProduct?.data?.forEach((product: any) => {
        const id = uuid();
        ids.push(id);
        defaultProducts[id] = {
          id: id,
          productCode: product?.code,
          productId: product?.productId,
          productName: product?.name,
          partNumber: product?.partNumber,
          description: product?.description,
          quantity: product?.quantity,
          unitId: product?.unit?.id,
          unitName: product?.unit?.name,
          unitCost: product?.unitCost,
          unitPrice: product?.unitPrice,
        };
      });

      onFetchFiancialIndicator({
        caseId,
        plansRequest: plans?.map((p) => ({ ...p, value: null })),
        products: defaultProducts,
      });

      const empty = uuid();
      setProducts(ids.length ? ids : [empty]);
      setTimeout(
        () =>
          form.setFieldsValue({
            businessPlanOverViewProduct: ids.length ? defaultProducts : {},
          }),
        0
      );
    } catch (error: any) {
      mappingErrorFromApi(error, form);
    } finally {
      setModalFetchProduct(false);
    }
  };

  useEffect(() => {
    if (isEdit && initialValues?.id) {
      const { indicators } = mappedDataToCalc({
        caseId: initialValues?.caseId!,
        plansRequest: initialValues?.financialIndicator as any,
        products: initialValues?.businessPlanOverView
          ?.businessPlanOverViewProducts as any,
      });

      const products =
        initialValues?.businessPlanOverView?.businessPlanOverViewProducts?.map(
          ({ product, quantity, unitCost, unitPrice }) => ({
            productId: product?.id,
            quantity: quantity || null,
            unitPrice: unitPrice || null,
            unitCost: unitCost || null,
          })
        ) || [];

      fetchFinancialIndicator({
        caseId: initialValues?.caseId!,
        products,
        indicators,
        currentPlans: initialValues?.financialIndicator || [],
        compareAll: true,
      });
    }
  }, [isEdit, initialValues]);

  return (
    <BusinessPlanContext.Provider
      value={{
        sumCost,
        sumPrice,
        initialValues,
        isEdit,
        canEditCaseId,
        productState,
        caseData,
        isLoadingFetchFinancial,
        planState,
        onFetchFiancialIndicator,
        onChangeCase,
        getEstimateValueError,
        stateModalFetchProduct,
        onFetchProducts,
      }}
    >
      <CreateCustom
        {...props}
        title={translate(
          isEdit ? "Chỉnh sửa phương án kinh doanh" : "Tạo phương án kinh doanh"
        )}
        tabs={headerTab}
        onChangeTab={onChangeTab}
        onSubmit={onCreate(true)}
        visibleShowModal={visibleModalConfirm}
        setVisibleShowModal={toggleModalConfirm}
        saving={isLoading || isUpdating}
        confirmModalProps={{
          title: translate(
            isEdit
              ? "Bạn muốn chỉnh sửa và chuyển phương án kinh doanh này?"
              : "Bạn muốn tạo và chuyển phương án kinh doanh này?"
          ),
        }}
        saveButtonProps={{
          ...saveButtonProps,
          title: translate(isEdit ? "Lưu & Chuyển" : "Tạo & Chuyển"),
          disabled: isLoadingFetchFinancial || formLoading,
        }}
        onDraft={onCreate(false)}
        validateDraft={validateDraft}
        draftButtonProps={{
          title: translate(isEdit ? "Lưu chỉnh sửa" : "Lưu nháp"),
          disabled: isLoadingFetchFinancial || formLoading,
        }}
        draftModalProps={{
          title: translate("Bạn muốn lưu nháp phương án kinh doanh này?"),
        }}
        isLoading={formLoading}
        contentStyles={{ background: "transparent", padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form {...formProps} layout="vertical" onFinish={onFinishInformation()}>
          <ButtonConfirm
            hidden
            visible={visibleModalWarningDataChange}
            toggle={toggleModalWarningDataChange}
            onClick={toggleModalWarningDataChange}
            text={translate("popupWarningDataChange.title")}
            description={translate("popupWarningDataChange.desc")}
          />
          <Container active={activeTab === TabKey.INFO}>
            <BusinessPlanInformation />
          </Container>
          <Container active={activeTab === TabKey.COSTS}>
            <TableBusinessPlan />
          </Container>
        </Form>
      </CreateCustom>
    </BusinessPlanContext.Provider>
  );
});

export default BusinessPlanCreate;
