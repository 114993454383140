import React, { FC, memo, useContext, useMemo } from "react";
import { Box } from "../CaseInfo";
import {
  Button,
  Checkbox,
  Col,
  Row,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { ContractEstimatedFormContext } from "../../..";
import type { ColumnsType } from "antd/es/table";
import { ObjectSubmitCondition } from "interfaces";
import { SubmitConditionInfoType } from "api/enums";
import { CheckBoxError } from "pages/sale/contractEstimation/show/components/SubmitionInformation";

const { Title, Text } = Typography;

const SubmitConditionsInfo: FC = memo(() => {
  const { submitConditionFetch } = useContext(ContractEstimatedFormContext);
  const { refetch } = submitConditionFetch!;
  const translate = useTranslate();

  const columns = useMemo<ColumnsType<ObjectSubmitCondition>>(
    () => [
      {
        title: translate("common.no"),
        width: 50,
        dataIndex: "id",
        key: "id",
        render: (_, record, index) => index + 1,
      },
      {
        title: translate("Điều kiện"),
        width: 200,
        dataIndex: "description",
        key: "description",
        render: (_, record, index) => (
          <Text>{record?.submitConditionInfo?.description}</Text>
        ),
      },
      {
        title: translate("Thông tin"),
        width: 200,
        dataIndex: "url",
        key: "url",
        render: (_, record, index) => (
          <div
            dangerouslySetInnerHTML={{
              __html: record?.submitConditionInfo?.url,
            }}
          />
        ),
      },
      {
        title: translate("Thỏa điều kiện"),
        width: 50,
        dataIndex: "action",
        key: "action",
        align: "center",
        render: (_, record, index) => {
          const { submitConditionInfo, isOk } = record;
          return submitConditionInfo?.type ===
            SubmitConditionInfoType.Manual ? (
            <Checkbox checked={isOk} />
          ) : isOk ? (
            <Checkbox checked />
          ) : (
            <CheckBoxError checked />
          );
        },
      },
    ],
    []
  );

  return (
    <Box>
      <Title level={5}>{translate("Thông tin cần để chuyển DTHĐ")}</Title>
      <Row style={{ marginBottom: 16 }} align="middle">
        <Col flex={1} />

        <Button
          type="primary"
          onClick={() => refetch()}
          loading={submitConditionFetch?.isFetching}
        >
          {translate("Cập nhật")}
        </Button>
      </Row>
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={submitConditionFetch?.data?.data}
        scroll={{ x: 500 }}
        style={{ paddingLeft: 16 }}
        size="small"
      />
    </Box>
  );
});

export default SubmitConditionsInfo;
