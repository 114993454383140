import {
  ItemInCollectionOperator,
  NumberDateOperator,
  OptionOperator,
  StringOperator,
} from "api/enums";
import { CaseStatus } from "api/enums";
import { CustomerStatus } from "interfaces/Customer";
import { EWorkingGroupStatus } from "interfaces/WorkingGroup";
import { EstimateType } from "interfaces/InternalEstimate";

export const USER_STATUS = [
  {
    label: "Welcome",
    value: 0,
  },
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Deactive",
    value: 2,
  },
];

export const EMPLOYEE_STATUS = [
  {
    label: "employees.fields.status.active",
    value: 0,
  },
  {
    label: "employees.fields.status.deactive",
    value: 1,
  },
];

export const PAGE_SIZE_OPTIONS: number[] = [10, 20, 50, 100];

export const CUSTOMER_STATUS_LABEL: { [key in CustomerStatus]: string } = {
  "0": "customer.customerStatus.draft",
  "1": "customer.customerStatus.unassigned",
  "2": "customer.customerStatus.assigned",
};
export const WORKING_GROUP_STATUS_LABEL: {
  [key in EWorkingGroupStatus]: string;
} = {
  "0": "Ngưng hoạt động",
  "1": "Đang hoạt động",
};

export const CUSTOMER_STATUS_LIST = [
  {
    label: CUSTOMER_STATUS_LABEL[0],
    value: CustomerStatus.DRAFT,
  },
  {
    label: CUSTOMER_STATUS_LABEL[1],
    value: CustomerStatus.UNASSIGNED,
  },
  {
    label: CUSTOMER_STATUS_LABEL[2],
    value: CustomerStatus.ASSIGNED,
  },
];

export const WORKING_GROUP_STATUS_LIST = [
  {
    label: WORKING_GROUP_STATUS_LABEL[1],
    value: EWorkingGroupStatus.Active,
  },
  {
    label: WORKING_GROUP_STATUS_LABEL[0],
    value: EWorkingGroupStatus.Deactive,
  },
];
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DATE_AND_TIME_FORMAT = "DD/MM/YYYY - HH:mm:ss";

export const DEFAULT_KEY = "00000000-0000-0000-0000-000000000000";

export const ERROR_CODE_MAPPING_FIELD: any = {
  ER095: "name",
};

export const ONE_THOUSAND_TRILLION = 1e14;
export const ONE_HUNDRED_THOUSAND = 100000;

export const WORKID_TYPE_OPTIONS = [
  { label: "workid.departmentCaseId", value: 0 },
  { label: "caseid.businessCaseId", value: 1 },
];

export const NUMBER_DATE_OPERATORS = [
  { label: "=", value: NumberDateOperator.EqualTo },
  { label: "<>", value: NumberDateOperator.NotEqual },
  { label: ">", value: NumberDateOperator.GreaterThan },
  { label: "<", value: NumberDateOperator.LessThan },
  { label: ">=", value: NumberDateOperator.GreaterThanOrEqualTo },
  { label: "<=", value: NumberDateOperator.LessThanOrEqualTo },
];

export const ITEM_IN_COLLECTION_OPERATORS = [
  { label: "contains", value: ItemInCollectionOperator.Contains },
  { label: "not contain", value: ItemInCollectionOperator.NotContains },
];

export const OPTIONS_OPERATORS = [
  { label: "=", value: OptionOperator.EqualTo },
  { label: "<>", value: OptionOperator.NotEqual },
];

export const STRING_OPERATORS = [
  { label: "=", value: StringOperator.EqualTo },
  { label: "<>", value: StringOperator.NotEqual },
  { label: "contains", value: StringOperator.Contains },
  { label: "not contain", value: StringOperator.NotContains },
];

export const CASE_STATUS_OPTIONS = [
  { label: "Nháp", value: CaseStatus.Draft },
  { label: "Đang hoạt động", value: CaseStatus.Active },
  { label: "Ngưng hoạt động", value: CaseStatus.Inactive },
];

export const MAX_PERCENTAGE = 999;
