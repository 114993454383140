import { Button, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { ApprovalStatus } from "api/enums";
import { ApprovalServiceRequest } from "components/ApprovalServiceRequest";
import { PATH } from "configs/path";
import { IHeaderTab } from "interfaces";
import IContractEstimate, {
  ContractEstimateData,
  TabKey,
} from "interfaces/ContractEstimate";
import { FC, createContext, memo, useMemo, useState } from "react";
import ModalCloneContractEstimate from "../list/components/ModalClone";
import styled from "styled-components";
import CaseInfo from "./components/CaseInfo";
import General from "./components/General";
import ProductList from "./components/ProductList";
import { EstimateInfoShow } from "../create/components/EstimateInfo";
import SubmitionInfomation from "./components/SubmitionInformation";
import { ButtonTooltip } from "components";
import usePermissions from "hooks/permission";

const { Text } = Typography;

const ContractEstimationShow: FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();

  const { checkEditHaveHelperText } = usePermissions();

  const [visibleModalClone, setVisibleModalClone] = useState(false);

  const toggleModalClone = () => setVisibleModalClone((prev) => !prev);

  const headerTabs: IHeaderTab[] = useMemo(
    () => [
      {
        name: translate("Thông tin tổng quan"),
        key: TabKey.INFO,
        isActive: true,
        hidden: false,
      },
      {
        name: translate("Chi tiết dự toán hợp đồng"),
        key: TabKey.ESTIMATE_INFO,
        isActive: false,
        hidden: false,
      },
      {
        name: translate("Lịch sử phê duyệt"),
        key: TabKey.HISTORY,
        isActive: false,
        hidden: false,
      },
    ],
    []
  );

  return (
    <ApprovalServiceRequest
      {...props}
      headerTabs={headerTabs}
      defaultTab={TabKey.INFO}
      resource={PATH.contractEstimateds}
      renderInformation={(record: IContractEstimate, tab: TabKey) => (
        <RenderContent record={record?.objectData} tabKey={tab} />
      )}
      onApprovalSuccess={() => {}}
      requestName={translate("Phê duyệt DTHĐ")}
      title={(record: IContractEstimate) => (
        <>
          {translate("Dự toán hợp đồng")}:{" "}
          <Text className="primary">{record?.objectData?.case?.name}</Text>
        </>
      )}
      headerButton={(record: IContractEstimate) => (
        <>
          <ButtonTooltip
            hidden={record?.objectData?.status != ApprovalStatus.Approved}
            type="primary"
            onClick={toggleModalClone}
            {...checkEditHaveHelperText(PATH.contractEstimateds)}
          >
            {translate("Thay đổi DTHĐ")}
          </ButtonTooltip>
        </>
      )}
      elements={(record: IContractEstimate) => (
        <ModalCloneContractEstimate
          toggleModal={toggleModalClone}
          visibleModal={visibleModalClone}
          record={{
            ...record?.objectData,
          }}
        />
      )}
    />
  );
};

interface RenderContenProps {
  tabKey: TabKey;
  record: ContractEstimateData;
}

interface State {
  record: Partial<ContractEstimateData>;
}

export const ContractEstimateShowContext = createContext<State>({
  record: {},
});

const Container = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-direction: column;
  gap: 8px;
`;

const RenderContent: FC<RenderContenProps> = memo((props) => {
  const { record, tabKey } = props;

  return (
    <ContractEstimateShowContext.Provider value={{ record }}>
      <Container active={tabKey === TabKey.INFO}>
        <CaseInfo />
        <General />
        <ProductList />
        <SubmitionInfomation />
      </Container>
      <Container active={tabKey === TabKey.ESTIMATE_INFO}>
        <EstimateInfoShow plans={record?.financialIndicator} />
      </Container>
    </ContractEstimateShowContext.Provider>
  );
});

export default ContractEstimationShow;
