import { Typography } from '@pankod/refine-antd'
const { Text } = Typography

interface TextValueProps {
  value?: string
}

const TextValue = (props: any & TextValueProps) => {
  const { value, ...textProps } = props
  return <Text {...textProps}>{value || ''}</Text>
}

export default TextValue
