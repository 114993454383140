import {
  Button,
  ButtonProps,
  Icons,
  Modal,
  Space,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { isUndefined } from "lodash";
import { ReactNode, useState } from "react";

const { Text } = Typography;

interface ButtonConfirmProps {
  text?: ReactNode;
  description?: ReactNode;
  danger?: boolean;
  noCancel?: boolean;
  visible?: boolean;
  loading?: boolean;
  toggle?: () => void;
  customContent?: ReactNode;
}

const ButtonConfirm = (props: ButtonProps & ButtonConfirmProps) => {
  const t = useTranslate();
  const {
    text,
    description,
    onClick,
    noCancel = true,
    visible,
    toggle,
    loading,
    customContent,
    danger,
    ...otherProps
  } = props;

  const [isShow, show] = useState(false);

  return (
    <>
      <Button
        {...otherProps}
        onClick={(e) => {
          e.stopPropagation();
          show(true);
          if (toggle) toggle();
        }}
      />

      <Modal
        title=""
        visible={!isUndefined(visible) ? visible : isShow}
        onOk={(e) => {
          e.stopPropagation();
          show(false);
          if (onClick) onClick(e);
        }}
        onCancel={(e) => {
          e.stopPropagation();
          show(false);
          if (toggle) toggle();
        }}
        okText={t("buttons.confirm")}
        okButtonProps={{ danger: danger, loading }}
        cancelText={t("buttons.reject")}
        cancelButtonProps={{ hidden: noCancel, disabled: loading }}
      >
        {customContent || (
          <Space align="start">
            <Text style={{ fontSize: 22 }} type="warning">
              <Icons.InfoCircleOutlined />
            </Text>
            <Space direction="vertical">
              <Text style={{ fontWeight: 500, fontSize: 16, marginBottom: 0 }}>
                {text || "Bạn chắc chắn?"}
              </Text>
              <Text style={{ fontSize: 14 }}>{description}</Text>
            </Space>
          </Space>
        )}
      </Modal>
    </>
  );
};

export default ButtonConfirm;
