import { Descriptions } from "@pankod/refine-antd";
import { FC, memo, useContext } from "react";
import { ContractShowConText } from "../..";
import { useOne, useTranslate } from "@pankod/refine-core";
import { LinkText } from "components";
import { API_PATH } from "configs/path";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";

import { getSlugToRedirectWorkId } from "utils/commons";
import CCollapse, { CaseInfoPanel } from "components/CCollapse";

const CaseInfo: FC = memo(() => {
  const record = useContext(ContractShowConText);
  const translate = useTranslate();
  const caseId = record?.case?.value;

  const resCase = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(caseId || ""),
    id: "",
    queryOptions: { enabled: !!caseId },
  });

  const caseDetail = resCase?.data?.data;
  const loadingCase = resCase?.isFetching;
  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={1}
        title={translate("Thông tin Vụ việc")}
        layout="vertical"
      >
        <Descriptions.Item
          label={translate("Vụ việc")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <LinkText
            resource={getSlugToRedirectWorkId(true)}
            value={{ id: caseDetail?.id }}
            defaultValue={caseDetail?.caseCode}
            showTooltip={false}
          />
        </Descriptions.Item>
      </Descriptions>
      <div style={{ paddingLeft: 16 }}>
        <CCollapse loading={loadingCase} title={translate("Thông tin vụ việc")}>
          <CaseInfoPanel caseInfo={caseDetail} />
        </CCollapse>
      </div>
    </div>
  );
});

export default CaseInfo;
