import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Icons,
  Input,
  Row,
  Table,
  Typography,
  notification,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { SelectCustom } from "components";
import { API_PATH } from "configs/path";
import { FC, memo, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  BusinessPlanContext,
  BusinessPlanOverViewProductForm,
  FetchProductForBusinessType,
  IForm,
} from "../../..";
import { cloneDeep } from "lodash";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { Currency } from "components/Currency";
import ButtonConfirm from "components/ButtonConfirm";
import styled from "styled-components";

const { Title } = Typography;
const { TextArea } = Input;

const ControlButtonWrapperStyle = styled.div`
  display: flex;
  gap: 8px;
  overflow: auto;
`;

const { useWatch, useFormInstance } = Form;
const ProductList: FC = memo(() => {
  const translate = useTranslate();
  const form = useFormInstance<IForm>();
  const {
    sumCost,
    sumPrice,
    productState,
    caseData,
    planState: [plans],
    isEdit,
    initialValues,
    onFetchFiancialIndicator,
    onFetchProducts,
    stateModalFetchProduct,
  } = useContext(BusinessPlanContext);

  const [list, setList] = productState;
  const [visibleModalFetchProduct, setVisibleModalFetchProduct] =
    stateModalFetchProduct;

  const businessPlanOverViewProduct = useWatch(
    "businessPlanOverViewProduct",
    form
  );

  const [fetchType, setFetchType] = useState(FetchProductForBusinessType.OPPOTUNITY);

  const caseId = useWatch("caseId", form);

  const isShowRemoveButton = useMemo(() => list.length > 1, [list]);

  const onRemoveProduct = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const sumMargin = useMemo(
    () => sumPrice - (sumCost ?? 0),
    [sumPrice, sumCost]
  );

  const loadingCase = caseData?.isFetching;

  const onChangeProduct =
    (keyRow: string) => (_: any, __: any, option: any) => {
      const newList = cloneDeep(businessPlanOverViewProduct);
      newList[keyRow] = {
        ...newList[keyRow],
        productCode: option?.code,
        productId: option?.id,
        productName: option?.name,
        unitId: option?.unit?.id,
        unitName: option?.unit?.name,
      };

      form.setFieldsValue({ businessPlanOverViewProduct: newList });

      onFetchFiancialIndicator({
        caseId,
        plansRequest: plans,
        products: newList,
      });
    };

  const onChangeQuantity =
    (keyRow: string, field: keyof BusinessPlanOverViewProductForm) =>
    (value: any) => {
      const newList = cloneDeep(businessPlanOverViewProduct);

      newList[keyRow][field] = value as never;

      onFetchFiancialIndicator({
        caseId,
        plansRequest: plans,
        products: newList,
      });
    };

  const toggleModalFetchProduct = (fetchType: FetchProductForBusinessType) => {
    if (!caseId) {
      return notification.error({
        message: translate("errors.ER0194"),
      });
    }

    setFetchType(fetchType);
    
    setVisibleModalFetchProduct((prev) => !prev);
  };

  useEffect(() => {
    if (isEdit && initialValues?.id) {
      const ids: string[] = [];
      const businessPlanOverViewProduct: any = {};
      initialValues?.businessPlanOverView?.businessPlanOverViewProducts?.forEach(
        (bProduct) => {
          const id = uuid();
          ids.push(id);

          businessPlanOverViewProduct[id] = {
            id: bProduct?.id,
            productCode: bProduct?.product?.code,
            productId: bProduct?.product?.id,
            productName: bProduct?.product?.name,
            partNumber: bProduct?.partNumber,
            description: bProduct?.description,
            quantity: bProduct?.quantity,
            unitId: bProduct?.unit?.id,
            unitName: bProduct?.unit?.name,
            unitCost: bProduct?.unitCost,
            unitPrice: bProduct?.unitPrice,
          };
        }
      );

      setList(ids);
      setTimeout(
        () =>
          form.setFieldsValue({
            businessPlanOverViewProduct,
          }),
        0
      );
    }
  }, [isEdit, initialValues]);

  return (
    <Card>
      <Title level={5}>{translate("Danh sách sản phẩm")}</Title>
      <div style={{ paddingLeft: 16 }}>
        <Row style={{ marginBottom: 16 }} align="middle">
          <Col flex={1} />
          <ControlButtonWrapperStyle>
            <ButtonConfirm
              type="primary"
              onClick={async () => onFetchProducts(FetchProductForBusinessType.OPPOTUNITY)}
              text={translate(
                "Bạn muốn lấy dữ liệu danh sách sản phẩm/dịch vụ từ Cơ hội kinh doanh?"
              )}
              description={
                "Lưu ý: Sau khi lấy dữ liệu, các thông tin trong danh sách sản phẩm hiện tại sẽ bị xóa."
              }
              noCancel={false}
              visible={visibleModalFetchProduct && fetchType === FetchProductForBusinessType.OPPOTUNITY}
              toggle={() => toggleModalFetchProduct(FetchProductForBusinessType.OPPOTUNITY)}
            >
              {translate("Lấy từ CHKD")}
            </ButtonConfirm>
            
            <ButtonConfirm
              type="primary"
              onClick={async () => onFetchProducts(FetchProductForBusinessType.ESTIMATE_COST)}
              text={translate(
                "Bạn muốn lấy dữ liệu danh sách sản phẩm/dịch vụ từ Dự toán chi phí triển khai?"
              )}
              description={
                "Lưu ý: Sau khi lấy dữ liệu, các thông tin trong danh sách sản phẩm hiện tại sẽ bị xóa."
              }
              noCancel={false}
              visible={visibleModalFetchProduct && fetchType === FetchProductForBusinessType.ESTIMATE_COST}
              toggle={() => toggleModalFetchProduct(FetchProductForBusinessType.ESTIMATE_COST)}
            >
              {translate("Lấy từ DTCPTK")}
            </ButtonConfirm>

            <Button
              type="primary"
              onClick={() => {
                setList([...list, uuid()]);
              }}
              icon={<Icons.PlusOutlined />}
            >
              {translate("actions.create")}
            </Button>
          </ControlButtonWrapperStyle>
        </Row>
        <Table<String>
          style={{ marginTop: 8 }}
          size="small"
          className="vertical-align-top w-full"
          dataSource={list}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 1000,
          }}
          locale={{
            emptyText: " ",
          }}
          scroll={{ x: 2500 }}
          bordered
          loading={loadingCase && !!caseId}
        >
          <Table.Column
            title={translate("common.no")}
            dataIndex="code"
            width={"50px"}
            align="center"
            render={(v, keyRow: string, index) => index + 1}
          />

          <Table.Column
            title={translate("Tên sản phẩm")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <>
                  <Form.Item
                    hidden
                    name={[
                      "businessPlanOverViewProduct",
                      keyRow,
                      "id",
                    ]}
                  />
                  <Form.Item
                    hidden
                    name={[
                      "businessPlanOverViewProduct",
                      keyRow,
                      "productName",
                    ]}
                  />
                  <Form.Item
                    hidden
                    name={[
                      "businessPlanOverViewProduct",
                      keyRow,
                      "productCode",
                    ]}
                  />
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["businessPlanOverViewProduct", keyRow, "productId"]}
                    rules={[
                      {
                        required: true,
                        message: translate("errors.ER005"),
                      },
                    ]}
                  >
                    <SelectCustom
                      optionValue="id"
                      optionLabel="label"
                      resource={API_PATH.businessPlanProductDropdownlist}
                      label={
                        businessPlanOverViewProduct?.[keyRow]?.productCode &&
                        `${businessPlanOverViewProduct?.[keyRow]?.productCode} - ${businessPlanOverViewProduct?.[keyRow]?.productName}`
                      }
                      formatData={(r: any) => ({
                        ...r,
                        label: `${r?.code} - ${r?.name}`,
                      })}
                      placeholder="Chọn sản phẩm"
                      onChangeOption={onChangeProduct(keyRow)}
                    />
                  </Form.Item>
                </>
              );
            }}
          />
          <Table.Column
            title={translate("products.fields.partNumber")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["businessPlanOverViewProduct", keyRow, "partNumber"]}
                  rules={[
                    {
                      max: 200,
                      message: translate("errors.ER014", { max: "200" }),
                    },
                  ]}
                >
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    resource=""
                    placeholder="Nhập part number"
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("common.description")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["businessPlanOverViewProduct", keyRow, "description"]}
                  rules={[
                    {
                      max: 3000,
                      message: translate("errors.ER014", { max: "3000" }),
                    },
                    {
                      required: true,
                      message: translate("errors.ER005"),
                    },
                  ]}
                >
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    placeholder={translate("Nhập mô tả")}
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("common.quantity")}
            dataIndex="productGroup"
            width={100}
            render={(v, keyRow: string, index) => {
              return (
                <InputMoney
                  style={{ margin: 0 }}
                  name={["businessPlanOverViewProduct", keyRow, "quantity"]}
                  placeholder={translate("common.input.quantity")}
                  isRequired
                  isLimit
                  onChange={onChangeQuantity(keyRow, "quantity")}
                />
              );
            }}
          />
          <Table.Column
            title={translate("products.fields.unit")}
            dataIndex="productGroup"
            width={100}
            render={(v, keyRow: string, index) => {
              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={["businessPlanOverViewProduct", keyRow, "unitId"]}
                  rules={[
                    {
                      required: true,
                      message: translate("errors.ER005"),
                    },
                  ]}
                >
                  <SelectCustom
                    resource={API_PATH.unitsDropdownlist}
                    optionLabel="label"
                    optionValue="value"
                    placeholder={translate("products.placeholder.unit")}
                  />
                </Form.Item>
              );
            }}
          />
          <Table.Column
            title={translate("products.fields.unitPriceSell")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <InputMoney
                  style={{ margin: 0 }}
                  name={["businessPlanOverViewProduct", keyRow, "unitPrice"]}
                  placeholder={translate("products.placeholder.unitPrice.sell")}
                  isRequired
                  isLimit
                  onChange={onChangeQuantity(keyRow, "unitPrice")}
                />
              );
            }}
          />
          <Table.Column
            title={translate("products.fields.unitPriceCapital")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              return (
                <InputMoney
                  style={{ margin: 0 }}
                  name={["businessPlanOverViewProduct", keyRow, "unitCost"]}
                  placeholder={translate("products.placeholder.unitPrice.capital")}
                  //isRequired
                  isLimit
                  min={0}
                  errorCode="ER0204"
                  onChange={onChangeQuantity(keyRow, "unitCost")}
                />
              );
            }}
          />
          <Table.Column
            title={translate("Giá bán")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              const row = businessPlanOverViewProduct?.[keyRow];
              return row?.quantity && row?.unitPrice ? (
                <Currency
                  value={row?.quantity * row?.unitPrice}
                  showCurrency={false}
                />
              ) : (
                "-"
              );
            }}
          />

          <Table.Column
            title={translate("Giá vốn")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              const row = businessPlanOverViewProduct?.[keyRow];
              return row?.quantity && row?.unitCost ? (
                <Currency
                  value={row?.quantity * row?.unitCost}
                  showCurrency={false}
                />
              ) : (
                "-"
              );
            }}
          />

          <Table.Column
            title={translate("Margin")}
            dataIndex="productGroup"
            width={200}
            render={(v, keyRow: string, index) => {
              const row = businessPlanOverViewProduct?.[keyRow];
              const margin =
                (row?.quantity || 0) *
                ((row?.unitPrice || 0) - (row?.unitCost || 0));
              return (
                <Currency
                  value={margin}
                  showCurrency={false}
                  className={margin >= 0 ? "green" : "red"}
                />
              );
            }}
          />
          <Table.Column
            align="center"
            width="90px"
            title={translate("table.actions")}
            fixed="right"
            render={(v, keyRow, index) => {
              if (isShowRemoveButton)
                return (
                  <ButtonConfirm
                    text={translate("Bạn muốn xóa sản phẩm dịch vụ này?")}
                    description=""
                    onClick={onRemoveProduct(index)}
                    type="text"
                    danger
                    noCancel={false}
                  >
                    <Icons.DeleteOutlined />
                  </ButtonConfirm>
                );
            }}
          />
        </Table>
        <Descriptions
          column={{ sm: 1, md: 3 }}
          layout="vertical"
          className="no-padding"
          style={{ marginTop: 8 }}
        >
          <Descriptions.Item
            label={translate("Tổng giá bán")}
            labelStyle={{ fontWeight: 500 }}
            span={1}
          >
            <Currency showCurrency={false} value={sumPrice} />
          </Descriptions.Item>
          <Descriptions.Item
            label={translate("Tổng giá vốn")}
            labelStyle={{ fontWeight: 500 }}
            span={1}
          >
            <Currency showCurrency={false} value={sumCost} />
          </Descriptions.Item>

          <Descriptions.Item
            label={translate("Tổng margin")}
            labelStyle={{ fontWeight: 500 }}
            span={1}
          >
            <Currency
              className={sumMargin >= 0 ? "green" : "red"}
              showCurrency={false}
              value={sumMargin}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Card>
  );
});

export default ProductList;
