import { Modal, notification } from "@pankod/refine-antd";
import { PATH } from "configs/path";
import i18n from "i18n";

export const ContentModal = () => (
  <div dangerouslySetInnerHTML={{ __html: i18n.t("errors.ER010") }}></div>
);

const getErrorMessage = (error: any) => {
  return i18n.t(`errors.${error.errorCode}` || error?.errors[0]?.message || error?.message)
}

const isChangedPermission = (error: any) => {
  return error.httpErrorCode === 403 && ['ER0135', 'ER0136'].includes(error.errorCode)
}

export const showErrorToast = (error: any) => {
  if (error.httpErrorCode !== 401) {
    if (isChangedPermission(error)) {
      showPermissionChangedPopup(error)
      return
    }

    notification.error({
      message: getErrorMessage(error)
    })
  }
}

export const showErrorPopup = (error: any, title = '') => {
  if (error.httpErrorCode !== 401) {
    if (isChangedPermission(error)) {
      showPermissionChangedPopup(error, title)
      return
    }

    Modal.confirm(
      {
        title: getErrorMessage(error),
        okText: i18n.t('common.understand') as string,
        cancelButtonProps: { hidden: true }
      }
    )
  }
}

export const showPermissionChangedPopup = (error: any, title = '') => {
  const errorMessage = getErrorMessage(error)
  const titleText = title || i18n.t(`errorsTitle.${error.errorCode}`)
  let redirectUrl = `/${PATH.dashboards}`
  let okText = i18n.t('actions.backToDashboard')

  Modal.confirm(
    {
      title: titleText,
      content: errorMessage,
      okText: okText,
      cancelButtonProps: { hidden: true },
      onOk: () => {
        window.location.replace(redirectUrl)
      }
    }
  )
}