export enum UserRoles {
  SupperAdmin = "Supper Admin",
  TenantAdmin = "Tenant Admin",
  User = "User",
}

export enum UserStatus {
  Welcome = 0,
  Active = 1,
  Deactive = 2,
}
export enum EmployeeStatus {
  Active = 0,
  Deactive = 1,
}

export enum ApprovalProcedureStatus {
  Noset = 0,
  Draft = 1,
  Published = 2,
  Unpublished = 3,
}

export enum SubjectType {
  NONE,
  DIRECT_MANAGER,
  TILLE,
  EMPLOYEE,
  CASE_MEMBER,
}

export enum WorkflowDiagramApplyAcceptanceWhen {
  OnlyOne,
  All,
}

export enum WorkflowStatus {
  Noset,
  Draft,
  Published,
  Unpublished,
}

export enum WorkflowStepType {
  Begin,
  Normal,
  End,
  Decision,
}

export enum CompareDataTypeDecision {
  Decimal = 2000,
  Options = 2001,
  String = 2002,
  DateTime = 2003,
  ItemInCollection = 2004,
  Percent = 2005,
  Boolean = 2006,
}

export enum DisplayType {
  String = 4000,
  Number = 4001,
  Percent = 4002,
  Decimal = 4003,
}

export enum LogicalOperator {
  AND = 100,
  OR = 101,
}

export enum NumberDateOperator {
  EqualTo = 1,
  NotEqual = 2,
  GreaterThan = 3,
  LessThan = 4,
  GreaterThanOrEqualTo = 5,
  LessThanOrEqualTo = 6,
}

export enum StringOperator {
  EqualTo = 10,
  NotEqual = 11,
  Contains = 12,
  NotContains = 13,
}

export enum OptionOperator {
  EqualTo = 30,
  NotEqual = 31,
}

export enum ItemInCollectionOperator {
  Contains = 20,
  NotContains = 21,
}

export enum InputType {
  InputBox = 1000,
  Selection = 1001,
}

export enum CompareOperator {
  EqualTo = 1,
  NotEqual = 2,
  GreaterThan = 3,
  LessThan = 4,
  GreaterThanOrEqualTo = 5,
  LessThanOrEqualTo = 6,
}

export enum ApprovalStatus {
  Draft,
  Waiting,
  Approved,
  Approving,
  Rejected,
  Returned,
  Canceled,
  Expired,
}

export enum PaymentBeneficiaryType {
  Internal,
  Supplier,
}

export enum CurrencyType {
  VND,
  USD,
  EUR,
  SGD,
}

export enum CurrencyDisplayType {
  INPUT,
  LABEL,
}

export enum PaymentType {
  Cash,
  Banking,
}

export enum PaymentRequestType {
  Payment,
  Refund,
}

export enum RefundAdvancePaymentStatus {
  Paid,
  Unpaid,
}

export enum ApprovalActionStatus {
  Approved,
  Rejected,
  Returned,
  WaitingProcess,
  Submit,
  Cancel,
  Retrieve,
  Edit,
}

export enum ViewType {
  APPROVER,
  VIEWER,
}

export enum ConfigStatus {
  Active,
  Deactivate,
}

export enum Action {
  CREATE,
  UPDATE,
  DELETE,
}

export enum CaseStatus {
  Draft = "11",
  Active = "12",
  Inactive = "13",
}

export enum LookupTypeGroup {
  ContractType = 0,
  ContractStatus = 1,
  ContractGuaranteeType = 2,
  ContractPublishedType = 3,
  ContractGuaranteeCondition = 4,
  RevenueRecognitionStatus = 5,
}

export enum SubmitConditionInfoType {
  System = 1,
  Manual = 2,
}

export enum IntegrationStatus{
  SynchronizedFail = -1,
  SynchronizedSuccess = 1
}