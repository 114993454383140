import { Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { EditCustom } from "components/layout";
import { FC } from "react";
import BusinessOpportunitiesForm from "../create/components/Form";
import { useController } from "./controller";

const { Title, Text } = Typography;

const BusinessOpportunitiesEdit: FC<IResourceComponentsProps> = (props) => {
  const {
    record,
    visibleModalConfirmUpdate,
    isLoading,
    saveButtonProps,
    form,
    formProps,
    translate,
    onSubmit,
    toggleModalConfirm,
  } = useController();

  return (
    <EditCustom
      {...props}
      record={record}
      title={
        <Text style={{ marginBottom: 0 }}>
          {translate("businessOpportunities.editBusinessOpportunity")}:{" "}
          <Text className="primary">{record?.name}</Text>
        </Text>
      }
      onSubmit={onSubmit}
      visibleShowModal={visibleModalConfirmUpdate}
      setVisibleShowModal={toggleModalConfirm}
      isLoading={isLoading}
      saveButtonProps={{
        ...saveButtonProps,
      }}
    >
      <Title level={4}>{translate("common.titles.detail")}</Title>
      <BusinessOpportunitiesForm
        isEdit
        form={form}
        formProps={formProps}
        onFinishedFormValidate={() => toggleModalConfirm(true)}
        initialValue={record}
      />
    </EditCustom>
  );
};

export default BusinessOpportunitiesEdit;
