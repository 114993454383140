import { Button, Col, Radio, Row, Select } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useTable,
  useTranslate,
} from "@pankod/refine-core";
import { RefundAdvancePaymentStatus } from "api/enums";
import { SearchBox } from "components";
import { WORKID_TYPE_OPTIONS } from "configs/constants";
import { API_PATH, PATH } from "configs/path";
import { IPrepay } from "interfaces";
import { OptionViewApproval } from "interfaces/common";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { PrepayTable } from "../table";
import useStatusWithLocale from "hooks/statusOfFilter";

const RowStyle = styled(Row)`
  justify-content: flex-start;
  margin-bottom: 16px;
  @media only screen and (min-width: 1200px) {
    justify-content: flex-end;
  }
`;

export const PrepayRequest: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get("code") || "";

  const [prepayCode, setPrePayCode] = React.useState<string>(codeParam);
  const [isSearch, setIsSearch] = React.useState<boolean>(false);
  const dataStatus = useStatusWithLocale(API_PATH.statusForListFilter);
  
  const {
    tableQueryResult: { data: dataTable, isLoading, isRefetching },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    filters,
    setFilters,
    sorter,
    setSorter,
  } = useTable<IPrepay>({
    initialCurrent: 1,
    resource: PATH.prepays,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });
  const [optionViewApproval, setOptionViewApproval] = useState<OptionViewApproval | undefined>(undefined);
  // Read the active tab, payment code from url params
  React.useEffect(() => {
    if (codeParam) {
      setSearchFilter(codeParam);
    }
  }, [codeParam]);

  React.useEffect(() => {
    setCurrent(1);
    setIsSearch(filters && filters.length > 0);
  }, [filters]);

  const onSearch = (value: string) => {
    setCurrent(1);
    setIsSearch(value ? true : false);
    setSearchFilter(value);
  };

  const setSearchFilter = (value: any) => {
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  const changeOptionView = (value?: OptionViewApproval) => {
    setOptionViewApproval(optionViewApproval === value ? undefined : value)
    setFilters([
      {
        field: "optionViewApproval",
        operator: "eq",
        value: optionViewApproval === value ? undefined : value,
      },
    ])
  }

  return (
    <div className="list-content">
      <Row gutter={[16, 16]} style={{ rowGap: 0 }}>
        <Col lg={24} xl={18}>
          <SearchBox
            placeholder={t("prepays.fields.searchPlaceHolder")}
            onSearch={onSearch}
            style={{ width: 304 }}
            value={prepayCode}
            onChange={(value) => {
              setPrePayCode(value.target.value);
            }}
          />
          <Select
            allowClear
            className={"placeholderBlack"}
            placeholder={t("prepays.refundStatus")}
            style={{ width: 200 }}
            bordered={false}
            options={[
              {
                value: RefundAdvancePaymentStatus.Paid,
                label: t("prepays.refundStatuses.paid"),
              },
              {
                value: RefundAdvancePaymentStatus.Unpaid,
                label: t("prepays.refundStatuses.unpaid"),
              },
            ]}
            onChange={(value, _) => {
              setFilters([
                {
                  field: "refundAdvancePaymentStatus",
                  operator: "eq",
                  value: value,
                },
              ]);
            }}
          />
          <Select
            options={WORKID_TYPE_OPTIONS.map((op) => ({
              ...op,
              label: t(op.label),
            }))}
            allowClear
            style={{ width: 200 }}
            bordered={false}
            placeholder={t("prepays.fields.caseCode")}
            onChange={(e) => {
              setFilters([
                {
                  field: "caseType",
                  operator: "eq",
                  value: e,
                },
              ]);
            }}
          />
        </Col>
        <Col lg={24} xl={6}>
          <RowStyle gutter={8}>
            <Col>
              <Button
                onClick={() => changeOptionView(OptionViewApproval.MINE)}
                shape="round"
                type={
                  optionViewApproval === OptionViewApproval.MINE ? "primary" : "ghost"
                }
              >
             {t("common.my")} 
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => changeOptionView(OptionViewApproval.APPROVING)}
                shape="round"
                type={
                  optionViewApproval === OptionViewApproval.APPROVING ?"primary": "ghost"
                }
              >
              {t("common.needProcess")}
              </Button>
            </Col>
          </RowStyle>
        </Col>
      </Row>
      <Radio.Group
        defaultValue={-1}
        buttonStyle="solid"
        onChange={(e) => {
          setFilters([
            {
              field: "status",
              operator: "eq",
              value: e?.target?.value === -1 ? "" : e?.target?.value,
            },
          ]);
        }}
        style={{ display: "block", marginBottom: 16 }}
      >
        <Radio.Button value={-1}>{t("common.all")}</Radio.Button>
        {dataStatus?.data?.sort((a,b) => a.code - b.code).map((status: any) => (
          <Radio.Button key={status.code} value={status.code}>
            {status.displayName}
          </Radio.Button>
        ))}
      </Radio.Group>
      <PrepayTable
        isLoading={isLoading}
        isRefetching={isRefetching}
        dataTable={dataTable}
        isSearch={isSearch}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setCurrent={setCurrent}
        current={current}
        sorter={sorter}
        setSorter={setSorter}
        viewTab={"show"}
      />
    </div>
  );
};
