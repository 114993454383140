import {
  Button,
  Card,
  Descriptions,
  Icons,
  Space,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import {
  ApprovalStatus,
  CurrencyType,
  PaymentBeneficiaryType,
  PaymentType,
} from "api/enums";
import { LinkText } from "components";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import { Currency } from "components/Currency";
import { CurrencyUnit } from "components/CurrencyUnit";
import { DataText } from "components/DataText";
import { onGetLinkView } from "components/GetLinkPreview";
import { DATE_FORMAT } from "configs/constants";
import { API_PATH } from "configs/path";
import { IPrepay } from "interfaces";
import _ from "lodash";
import {
  formatISODateTimeToView,
  getSlugToRedirectWorkId,
} from "utils/commons";

const { Text } = Typography;

interface AttachmentsProps {
  record?: IPrepay;
}
const Attachments = ({ record }: AttachmentsProps) => {
  const list = record?.attachments || [];
  const t = useTranslate();
  if (list.length === 0) {
    return <div />;
  }
  return (
    <Table
      style={{ width: "100%" }}
      size="small"
      rowKey="id"
      dataSource={list}
      locale={{ emptyText: " " }}
      pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
    >
      <Table.Column
        dataIndex="cost"
        title={t("prepays.fields.attachment")}
        render={(v: any, item: any) => {
          return item.fileUrlOrigin;
        }}
      />

      <Table.Column
        title={t("table.actions")}
        width="120px"
        align="center"
        dataIndex="action"
        render={(v: any, item: any) => {
          return (
            <Button
              type="text"
              icon={<Icons.EyeOutlined />}
              className="primary"
              onClick={onGetLinkView({ fileUrl: item.fileUrl, path: API_PATH.prepaysDocumentsView })}
            />
          );
        }}
      />
    </Table>
  );
};

interface PrepayInfoProps {
  record?: IPrepay;
}
const PrepayInfo = ({ record }: PrepayInfoProps) => {
  let list = (record?.advancePaymentListCosts || []).concat({ id: "total" });
  list = _.orderBy(list, "ordinal", "asc");
  const t = useTranslate();
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        marginBottom: 24,
      }}
    >
      <Table
        style={{ width: "100%" }}
        size="small"
        locale={{ emptyText: " " }}
        rowKey="id"
        dataSource={list}
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
      >
        <Table.Column
          dataIndex="cost"
          title={t("prepays.cost.name")}
          render={(v: any, item: any) => {
            if (item.id === "total")
              return (
                <Space direction="vertical">
                  <Text strong>{t("prepays.cost.total")}</Text>
                  <Text strong>{t("prepays.cost.totalWord")}</Text>
                </Space>
              );
            return `${item?.cost?.code} - ${item.cost?.name}`;
          }}
        />
        <Table.Column
          dataIndex="cost"
          title={t("prepays.cost.prepays")}
          align="right"
          render={(v: any, item: any) => {
            if (item.id === "total")
              return (
                <Space direction="vertical">
                  <Text strong>
                    <Currency
                      value={record?.totalAmount || 0}
                      currencyType={record?.currencyType || CurrencyType.VND}
                    />
                  </Text>
                  <Text strong>{record?.totalAmountWord || " "}</Text>
                </Space>
              );
            return (
              <Currency
                value={item.money}
                currencyType={record?.currencyType || CurrencyType.VND}
              />
            );
          }}
        />

        <Table.Column
          title={t("prepays.cost.note")}
          dataIndex="note"
          render={(v: any, item: any) => {
            if (item.id === "total") return "";
            return item.note;
          }}
        />
      </Table>
    </Space>
  );
};

interface InformationProps {
  record?: IPrepay;
}

export const CaseCodeTextField = (props: any) => {
  const t = useTranslate();
  const { caseRecord } = props;
  const value = caseRecord?.code;

  if (!value) {
    return <Text type="secondary">{t("common.emptyText")}</Text>;
  }

  return (
    <LinkText
      value={{ id: caseRecord?.id, code: caseRecord?.code, description: caseRecord?.type === true ? caseRecord?.name : caseRecord?.description }}
      showFullInfo={true}
      resource={getSlugToRedirectWorkId(caseRecord?.type)}
      showTooltip={false}
    />
  );
};

export const Information = ({ record }: InformationProps) => {
  const t = useTranslate();

  return (
    <Card style={{ background: "#ffffff" }}>
      <Descriptions
        column={{ lg: 3, xs: 1 }}
        title={t("prepays.fields.infoMain")}
        layout="vertical"
      >
        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.case")}
        >
          <CaseCodeTextField caseRecord={record?.case} />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.code")}
        >
          <DataText value={record?.code} />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.name")}
        >
          <DataText value={record?.name} />
        </Descriptions.Item>
        <Descriptions.Item
          span={1}
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.status")}
        >
          <ApprovalStatusLabel status={record?.status || ApprovalStatus.Draft} />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.beneficiaryType")}
        >
          {record?.beneficiaryType === PaymentBeneficiaryType.Internal &&
            t("common.beneficiaryType.internal")}
          {record?.beneficiaryType === PaymentBeneficiaryType.Supplier &&
            t("common.beneficiaryType.supplier")}
        </Descriptions.Item>
        {record?.beneficiaryType === PaymentBeneficiaryType.Internal && (
          <>
            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={t("prepays.fields.employee")}
            >
              <DataText
                value={
                  record?.employee
                    ? `${record?.employee?.code} - ${record?.employee?.name}`
                    : undefined
                }
              />
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={t("prepays.fields.department")}
            >
              <DataText value={record?.department?.name} />
            </Descriptions.Item>
          </>
        )}

        {record?.beneficiaryType === PaymentBeneficiaryType.Supplier && (
          <Descriptions.Item
            span={2}
            labelStyle={{ fontWeight: 500 }}
            label={t("prepays.fields.supplier")}
          >
            <DataText value={record?.supplier?.name} />
          </Descriptions.Item>
        )}
        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.reason")}
        >
          <DataText value={record?.reason} />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.estimatedPaymentDate")}
        >
          <DataText
            value={
              record?.estimatedPaymentDate
                ? formatISODateTimeToView(
                  record?.estimatedPaymentDate?.toString(),
                  DATE_FORMAT
                )
                : ""
            }
          />
        </Descriptions.Item>

        <Descriptions.Item
          span={2}
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.currency")}
        >
          <CurrencyUnit currencyType={record?.currencyType} />
        </Descriptions.Item>

        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.costList")}
        >
          <PrepayInfo record={record} />
        </Descriptions.Item>

        <Descriptions.Item span={3} labelStyle={{ fontWeight: 500 }}>
          <Attachments record={record} />
        </Descriptions.Item>

        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: 500 }}
          label={t("prepays.fields.advanceType")}
        >
          {record?.advanceType === PaymentType.Banking &&
            t("common.paymentType.banking")}
          {record?.advanceType === PaymentType.Cash &&
            t("common.paymentType.cash")}
        </Descriptions.Item>
        {record?.advanceType === PaymentType.Banking && (
          <>
            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={t("prepays.fields.bankAccountName")}
            >
              <DataText value={record?.bank?.bankAccountName} />
            </Descriptions.Item>

            <Descriptions.Item
              span={2}
              labelStyle={{ fontWeight: 500 }}
              label={t("prepays.fields.bankAccountNumber")}
            >
              <DataText value={record?.bank?.bankAccountNumber} />
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={t("prepays.fields.bank")}
            >
              <DataText value={record?.bank?.bankName} />
            </Descriptions.Item>

            <Descriptions.Item
              span={2}
              labelStyle={{ fontWeight: 500 }}
              label={t("prepays.fields.branchBank")}
            >
              <DataText value={record?.bank?.bankBranch} />
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </Card>
  );
};
