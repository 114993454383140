import { Radio } from "@pankod/refine-antd";
import { useGetLocale, useSetLocale, useTranslate } from "@pankod/refine-core";
import { CSSProperties } from "react";

interface LangSelectProps {
  isDark?: boolean;
  isFixed?: boolean;
  isText?: boolean;
}

const LangSelect = ({ isDark, isFixed, isText }: LangSelectProps) => {
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const currentLocale = locale();
  const translate = useTranslate();

  const languages = [
    {
      key: "vi",
      name: "VIE",
    },
    {
      key: "en",
      name: "ENG",
    },
  ];
  if (process.env.REACT_APP_ENABLE_LANG_KEY === "True") {
    languages.push({
      key: "key",
      name: "KEY",
    });
  }
  const style: CSSProperties = isFixed
    ? {
        position: "absolute",
        right: 12,
        top: 12,
        display: "flex",
        gap: 6,
        zIndex: 10,
      }
    : {};

  return (
    <div style={style}>
      {isText ? translate("common.language") + ": " : ""}
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        value={currentLocale}
        onChange={(e) => changeLanguage(e.target.value)}
        size="small"
      >
        {languages.map((a) => (
          <Radio.Button key={a.key} value={a.key}>
            {a.name}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default LangSelect;
