import CaseId from "interfaces/CaseId";
import { createContext } from "react";

export enum RenderType {
  LIST = "list",
  CREATE = "create",
  EDIT = "edit",
  SHOW = "show",
}

export enum CaseIdTab {
  INFO_TAB = "info",
  PREPAY_TAB = "prepay",
  PAYMENT_TAB = "payment",
  CONSULTATION_TAB = "consultation",
}

export interface ParamsConsultation {
  renderType?: RenderType;
  consultationCode?: string;
  consultationTab?: string;
  approval?: boolean;
}

export interface ParamsPrepay {
  renderType?: RenderType;
  prepayCode?: string;
  prepayTab?: string;
  approval?: boolean;
}

export interface CurrentTabState<ParamType> {
  renderType: RenderType;
  setRenderType: (type: RenderType) => void;
  onChangeMode: (params: ParamType) => void;
  param: {
    params: ParamType;
    setParams: (params: ParamType) => void;
  };
}

export interface State {
  tab: CaseIdTab;
  consultation: CurrentTabState<ParamsConsultation>;
  prepay: CurrentTabState<ParamsPrepay>;
  caseId?: CaseId;
}

const initTabState: CurrentTabState<any> = {
  renderType: RenderType.LIST,
  setRenderType: () => {},
  onChangeMode: () => {},
  param: {
    params: {},
    setParams: () => {},
  },
};

export const BusinessWorkIdContext = createContext<State>({
  tab: CaseIdTab.INFO_TAB,
  consultation: initTabState,
  prepay: initTabState,
});
