import React from "react";
import { IResourceComponentsProps, useCreate, useTranslate, useShow, useNavigation } from "@pankod/refine-core";
import {
  Form,
  useForm,
  notification,
  Typography,
  useSelect,
  Input,
  Icons,
  Space,
  Button,
  Modal
} from "@pankod/refine-antd";
import { useParams, useSearchParams } from 'react-router-dom';
import { IMisRoles, IMisRolesData } from "interfaces";
import { PATH, API_PATH } from "configs/path";
import { EditCustom } from "components/layout";
import { DEFAULT_KEY } from "configs/constants";
import { useDimensions } from "hooks/dimensions"
import { SelectCustom } from "components/SelectCustom";
import { showErrorToast } from "api/common";


const { Title, Text, Paragraph } = Typography;

export const RolesEmployeeEdit: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { show } = useNavigation()
  const { id } = useParams();
  const refTable = React.useRef(null);
  const { width } = useDimensions(refTable)
  const [searchParams] = useSearchParams();
	const code = encodeURIComponent(searchParams.get('code') || '');
  const { form, formProps, saveButtonProps } = useForm<IMisRolesData>();
  const { queryResult } = useShow<any>();
  const { data, refetch } = queryResult;
  const record = {
    misRoles: data?.data.sort((a: IMisRoles, b:IMisRoles) => a.ordinal - b.ordinal)
  }
  const widthSelect = width*0.4 - 32;

  const [ visibleShowModal, setVisibleShowModal ] = React.useState<boolean>(false);
  const [ visibleErrorShowModal, setVisibleErrorShowModal ] = React.useState<boolean>(false);
  const [ indexRemoveRow, setIndexRemoveRow ] = React.useState<number | null>(null);
  const { mutate: mutateUpdate, isLoading } = useCreate<any>();

  const onSubmit = () => {
    const misRoles = form.getFieldValue('misRoles').map((i: IMisRoles, idx: number) => ({
      ...i,
      ordinal: idx
    }))
    mutateUpdate(
      {
        resource: PATH.userTenantMisRoles,
        values: {
          employeeId: id || '',
          misRoles: misRoles
        },
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: t('employees.editSuccess')
          })
          show(PATH.employees, id || '')
        },
        onError: (error: any) => {
          setVisibleShowModal(false)
          showErrorToast(error)
        },
      },
    )
  }

  return (
    <EditCustom
      {...props}
      title={<Text style={{marginBottom: 0}}>{t("userRoles.addRoles")}: <Text className="primary">{code}</Text></Text>}
      titlePopupConfirm={t('employees.editConfirm')}
      subtitlePopupConfirm={t('employees.editDescription')}
      saveButtonProps={{
        ...saveButtonProps,
        disabled: isLoading
      }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      isLoading={isLoading}
      onCancel={()=> show(PATH.employees, id || '')}
    >
      <Title level={4}>{t('userRoles.userRoles')}</Title>
      <Form
        {...formProps}
        onFinishFailed={(e) => {
          setIndexRemoveRow(null)
          setVisibleErrorShowModal(true)
        }}
        onFinish={() => {
          setVisibleShowModal(true)
        }} 
      >
        <Form.List
          name="misRoles"
          initialValue={record?.misRoles}
        >
          {(fields, { add, remove }) => (
            <>
              <Form.Item style={{textAlign: 'right'}}>
                <Button
                  icon={<Icons.PlusOutlined/>}
                  type="primary"
                  onClick={() => add({departmentId: DEFAULT_KEY})}
                >{t('userRoles.userRoles')}</Button>
              </Form.Item>
              <div ref={refTable} className="ant-table user-roles-table">
                <table>
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell" style={{width: '6%'}}>{t("common.no")}</th>
                      <th className="ant-table-cell" style={{width: '40%'}}>{t("userRoles.fields.misRoleId")}</th>
                      <th className="ant-table-cell" style={{width: '40%'}}>{t("userRoles.fields.departmentId")}</th>
                      <th className="ant-table-cell" style={{textAlign: 'center'}}>{t('table.actions')}</th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                  {fields.map((field, index) => {
                    return (
                      <tr key={field.key}>
                        <td className="ant-table-cell">{index + 1}</td>
                        <td className="ant-table-cell">
                          <Form.Item
                            {...field}
                            name={[index, 'misRoleId']}
                            rules={[
                              {
                                required: true,
                                message: '',
                              }
                            ]}
                          >
                            <SelectCustom
                              allowClear
                              resource={PATH.roles}
                              subFilter={API_PATH.dropdownlist}
                              style={{width: widthSelect || '100%'}}
                            />
                          </Form.Item>
                        </td>
                        <td className="ant-table-cell">
                          <Form.Item
                            {...field}
                            name={[index, 'departmentId']}
                            rules={[
                              {
                                required: true,
                                message: '',
                              }
                            ]}
                          >
                            <SelectCustom
                              allowClear
                              placeholder={t('userRoles.fields.descriptionPlaceHolder')}
                              resource={PATH.departments}
                              subFilter={API_PATH.departmentRoleDropdownlist}
                              style={{width: widthSelect || '100%'}}
                            />
                          </Form.Item>
                        </td>
                        <td className="ant-table-cell" style={{textAlign: 'center'}}>
                          <Icons.DeleteOutlined onClick={() => {
                            setIndexRemoveRow(field.name)
                            setVisibleErrorShowModal(true)
                          }}/>
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </div>
              <Modal
                title=""
                visible={visibleErrorShowModal}
                onOk={() => {
                  if (indexRemoveRow !== null) {
                    remove(indexRemoveRow)
                  }
                  setVisibleErrorShowModal(false)
                }}
                onCancel={() => setVisibleErrorShowModal(false)}
                okText={t('buttons.confirm')}
                okButtonProps={{
                  danger: indexRemoveRow !== null ? true : false
                }}
                cancelText={t('buttons.reject')}
              >
                <Space align="start">
                  <Text style={{fontSize: 22}} type="warning"><Icons.InfoCircleOutlined/></Text>
                  <div>
                    <Paragraph style={{fontWeight: 500, fontSize: 16, paddingTop: 5, marginBottom: 0}}>
                      {indexRemoveRow !== null ? t('userRoles.message.deleteConfirm') : t('userRoles.message.warningConfirm')}
                    </Paragraph>
                    {indexRemoveRow === null && <Paragraph style={{fontSize: 14}}>{t('userRoles.message.subWarningConfirm')}</Paragraph>}
                  </div>
                </Space>
              </Modal>
            </>
          )}
        </Form.List>
      </Form>
      
    </EditCustom>
  );
};
