import { useGetLocale, useList } from '@pankod/refine-core';
import { useEffect } from 'react';

const useStatusWithLocale = (path: string) => {
  const getLocale = useGetLocale();
  const locale = getLocale();
  const {data: dataStatus, refetch: refetchStatus} = useList({
    resource: path,
  });

  useEffect(() => {
    refetchStatus();
  }, [locale]);

  return dataStatus;
};

export default useStatusWithLocale;