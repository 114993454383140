import { Form, useForm, useSelect } from "@pankod/refine-antd";
import { useNavigation, useTable, useTranslate } from "@pankod/refine-core";
import { ApprovalStatus } from "api/enums";
import { API_PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { InteralEstimateTableRow } from "interfaces/InternalEstimate";
import { OptionViewApproval } from "interfaces/common";
import { useMemo, useState } from "react";

const { useWatch } = Form;
export const useController = () => {
  const { edit, show, create } = useNavigation();
  const translate = useTranslate();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();

  const { form, formProps } = useForm<any>();

  const table = useTable<InteralEstimateTableRow>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const { filters, setSorter, setCurrent, setFilters } = table;

  const optionViewApproval = useWatch("optionViewApproval", form);
  const status = useWatch("status", form);
  const oldStatus = useWatch("oldStatus", form);

  const onChangeOptionView = (option: OptionViewApproval) => () => {
    const value = optionViewApproval === option ? undefined : option;
    const isApproving = value === OptionViewApproval.APPROVING;

    form.setFieldsValue({
      optionViewApproval: value,
      status: isApproving ? ApprovalStatus?.Approving : oldStatus,
      oldStatus: isApproving ? status : oldStatus,
    });

    const statusFilter = oldStatus;

    setFilters([
      {
        field: "optionViewApproval",
        operator: "eq",
        value,
      },
      {
        field: "status",
        operator: "eq",
        value: isApproving
          ? ApprovalStatus?.Approving
          : statusFilter > -1
          ? statusFilter
          : undefined,
      },
    ]);
  };

  const { selectProps: typeSelectProps } = useSelect({
    resource: API_PATH.internalEstimateTypeApi,
    optionValue: "id",
    optionLabel: "name",
  });

  const { selectProps: interalEstimateStatus } = useSelect({
    resource: API_PATH.internalEstimateStatusDropdownlist,
    optionValue: "code",
    optionLabel: "displayName",
  });

  const statusOptions = interalEstimateStatus?.options;

  const [cloneItem, setCloneItem] = useState<any>();

  const [visibleModalClone, setVisibleModalClode] = useState(false);

  const toggleModalClone = () => setVisibleModalClode((prev) => !prev);

  const onSelectItemClone = (record: any) => {
    setCloneItem(record);
    toggleModalClone();
  };

  const dataTable = useMemo(
    () =>
      table?.tableQueryResult?.data?.data?.map((row) => ({
        ...row.objectData,
        submitEmployee: row?.submitEmployee,
        waitingProcessEmployees: row?.waitingProcessEmployees,
      })),
    [table?.tableQueryResult]
  );

  return {
    formProps,
    filters,
    form,
    table,
    translate,
    create,
    checkCreateHaveHelperText,
    setFilters,
    setCurrent,
    setSorter,
    checkEditHaveHelperText,
    edit,
    show,
    onChangeOptionView,
    optionViewApproval,
    statusOptions,
    typeSelectProps,
    toggleModalClone,
    visibleModalClone,
    cloneItem,
    onSelectItemClone,
    dataTable,
  };
};
