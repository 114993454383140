import { Spin } from "@pankod/refine-antd";
import { fetchList, fetchDetail } from "api";
import EndPoints from "configs/api";
import { API_PATH } from "configs/path";
import {
  PERMISSIONS,
  TOKEN_KEY,
  TENANTS,
  USER_EMPLOYEE,
} from "configs/storage";
import { permissionMapping } from "provider/authProvider";
import { createContext, useContext, useEffect, useState } from "react";

interface IPageContext {
  tenants?: any[];
  permissions?: any[];
  isPageReady?: boolean;
  reloadData?: Function;
  loadTenants?: Function;
  loadPermissions?: Function;
}
export const PageContext = createContext<IPageContext>({
  tenants: [],
  permissions: {} as any,
  isPageReady: false,
});

const getLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};
export const getLocalPermissions = () => {
  return getLocalStorage(PERMISSIONS);
};

export const getLocalTenants = (): any[] => {
  return getLocalStorage(TENANTS);
};

const InitProvider = (props: any) => {
  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem(TOKEN_KEY) !== null;

    // Only load page information (tenants, permission, ...) when user logged in
    if (!isLoggedIn) {
      setIsPageReady(true);
      return;
    }

    if (isLoggedIn) {
      Promise.all([loadTenants(), reloadData()]).then((res) => {
        setIsPageReady(true);
      });
    }
  }, []);

  const reloadData = async () => {
    try {
      await loadPermissions();
    } catch {}
    setIsPageReady(true);
  };

  const loadTenants = async () => {
    if (!getLocalTenants()) {
      const { data } = await fetchList(
        `/core/tenants/${API_PATH.myTenants}`,
        {}
      );
      localStorage.setItem(TENANTS, JSON.stringify(data));
    }
  };

  // Always load the pemissions list (tenant) when loading the page
  const loadPermissions = async () => {
    let allPermissions = {};
    // Tenant permissions
    const [dataPermision, dataEmployee] = await Promise.all([
      fetchList(EndPoints.getTenantPermission(), {}),
      fetchDetail(EndPoints.getEmployeeUser),
    ]);

    allPermissions = {
      ...permissionMapping(dataPermision?.data as any),
    };
    localStorage.setItem(USER_EMPLOYEE, JSON.stringify(dataEmployee?.data));
    localStorage.setItem(PERMISSIONS, JSON.stringify(allPermissions));
  };

  return (
    <PageContext.Provider
      value={{
        isPageReady,
        reloadData,
        loadTenants,
        loadPermissions,
      }}
    >
      {isPageReady ? props.children : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Spin /></div>}
    </PageContext.Provider>
  );
};
export default InitProvider;

export const usePage = () => {
  return useContext(PageContext);
};
