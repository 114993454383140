import React from 'react'
import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
  useNavigation,
  useUpdate,
} from '@pankod/refine-core'
import {
  Typography,
  Table,
  Icons,
  Descriptions,
  Tag,
  Space,
  Modal,
  notification,
} from '@pankod/refine-antd'
import { ShowCustom } from 'components/layout'
import usePermissions from 'hooks/permission'
import { PATH } from 'configs/path'
import { LinkText, ButtonTooltip, DataText } from 'components'
import { ConfigStatus } from 'api/enums'
import { DateTimeFieldCustom } from 'components/DateTimeTextField'
import { ConfigStatusTag } from 'components/StatusTag'
import { showErrorToast } from 'api/common'

const { Title, Text } = Typography

export const MarketAreaShow: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate()
  const { edit } = useNavigation()
  const { checkEditHaveHelperText } = usePermissions()

  const { queryResult } = useShow<any>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { mutate } = useUpdate()

  return (
    <ShowCustom
      {...props}
      isLoading={isLoading}
      title={
        <Text style={{ marginBottom: 0 }}>
          {t('marketAreas.titles.show')}:{' '}
          <span className="primary">{record?.name}</span>{' '}
        </Text>
      }
      breadcrumbText={record?.name}
      headerButtons={
        <Space>
          <ButtonTooltip
            icon={<Icons.EditOutlined />}
            onClick={() => edit(PATH.configMarketAreas, record.id)}
            {...checkEditHaveHelperText(PATH.configMarketAreas, !record?.status)}
          >
            {t('buttons.edit')}
          </ButtonTooltip>
          {record?.status ? (
            <ButtonTooltip
              danger
              type="primary"
              icon={<Icons.CloseCircleOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: t('marketAreas.message.deactiveConfirm'),
                  content: t('marketAreas.message.deactiveConfirmDesc'),
                  okText: t('buttons.confirm'),
                  cancelText: t('buttons.reject'),
                  okButtonProps: {
                    danger: true,
                  },
                  onOk: () => {
                    mutate(
                      {
                        resource: PATH.configMarketAreas,
                        id: record?.id || '',
                        metaData: {
                          type: '/deactivate',
                        },
                        values: {},
                      },
                      {
                        onSuccess: () => {
                          notification.success({
                          message: t('common.config.deactiveSuccess'),
                          })
                        },
                        onError: (error) => {
                          showErrorToast(error)
                        },
                      },
                    )
                  },
                })
              }}
              {...checkEditHaveHelperText(PATH.configMarketAreas)}
            >
              {t('buttons.deactivate')}
            </ButtonTooltip>
          ) : (
            <ButtonTooltip
              type="primary"
              icon={<Icons.CheckCircleOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: t('marketAreas.message.activeConfirm'),
                  okText: t('buttons.confirm'),
                  cancelText: t('buttons.reject'),
                  onOk: () => {
                    mutate({
                      resource: PATH.configMarketAreas,
                      id: record?.id || '',
                      metaData: {
                        type: '/activate',
                      },
                      values: {},
                    },
                    {
                      onSuccess: () => {
                        notification.success({
                          message: t('common.config.activeSuccess'),
                        })
                      },
                      onError: (error) => {
                        showErrorToast(error)
                      },
                    },)
                  },
                })
              }}
              {...checkEditHaveHelperText(PATH.configMarketAreas)}
            >
              {t('buttons.active')}
            </ButtonTooltip>
          )}
        </Space>
      }
    >
      <Descriptions
        column={3}
        title={t('common.titles.detail')}
        layout="vertical"
      >
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t('marketAreas.fields.code')}
        >
          <DataText value={record?.code} />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t('marketAreas.fields.name')}
        >
          <DataText value={record?.name} />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t('marketAreas.fields.status')}
        >
          <ConfigStatusTag status={record?.status} />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          span={2}
          label={t('marketAreas.fields.description')}
        >
          <DataText value={record?.description} className="line-break" />
        </Descriptions.Item>
        <Descriptions.Item label=""> </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t('common.updatedDate')}
        >
          {record?.updatedDate && (
            <DateTimeFieldCustom value={record?.updatedDate} />
          )}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          labelStyle={{ fontWeight: 500 }}
          label={t('common.updatedName')}
        >
          <DataText className="primary" value={record?.updatedName} />
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t('common.createdDate')}
        >
          {record?.createdDate && (
            <DateTimeFieldCustom value={record?.createdDate} />
          )}
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontWeight: 500 }}
          label={t('common.createdName')}
          span={2}
        >
          <DataText className="primary" value={record?.createdName} />
        </Descriptions.Item>
      </Descriptions>
    </ShowCustom>
  )
}
