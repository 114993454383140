import React, { useEffect } from 'react'
import {
  useTranslate,
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useUpdate,
} from '@pankod/refine-core'
import {
  Form,
  Input,
  useForm,
  notification,
  Typography,
  Icons,
  Row,
  Col,
} from '@pankod/refine-antd'

import { IMarketAreaConfig, IPost } from 'interfaces'
import { PATH, API_PATH } from 'configs/path'
import { CreateCustom } from 'components/layout'
import { SelectCustom } from 'components/SelectCustom'

import InputUpper from 'components/InputUpper'
import { showErrorToast } from 'api/common'
import { mappingErrorFromApi } from 'utils/commons'
const { Text, Paragraph } = Typography
const { TextArea } = Input

export const MarketAreaCreate: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate()
  const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>(false)
  const { mutate: mutateCreate, isLoading } = useCreate<IMarketAreaConfig>()
  const { mutate: mutateUpdate } = useUpdate<IMarketAreaConfig>()
  const { form, formProps, saveButtonProps } = useForm<IPost>()
  const { show,list } = useNavigation()

  const isEdit = !!formProps.initialValues?.id
  const record = formProps.initialValues

  const onSubmit = () => {
    const handleResponse = {
      onSuccess: (res: any) => {
        notification.success({
          message: isEdit
            ? translate('common.config.editSuccess')
            : translate('common.config.createSuccess'),
        })
        show(PATH.configMarketAreas, res?.data?.id)
      },
      onError: (error: any) => {
        setVisibleShowModal(false)
        const errorFieldMapping: any = {
          ER0130: 'name',
        }
        mappingErrorFromApi(error, form, errorFieldMapping)
      },
    }

    const dataSend = {
      resource: PATH.configMarketAreas,
      values: {
        name: form.getFieldValue('name').trim(),
        code: form.getFieldValue('code').trim(),
        description: form.getFieldValue('description'),
      },
      id: '',
    }

    if (isEdit) {
      dataSend.id = formProps.initialValues?.id || ''
      mutateUpdate(dataSend, handleResponse)
    } else {
      mutateCreate(dataSend, handleResponse)
    }
  }
   
  return (
    <CreateCustom
      {...props}
      saveButtonProps={{
        ...saveButtonProps,
        title: isEdit
          ? translate('common.saveEdit')
          : translate('marketAreas.button.create'),
      }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      confirmModalProps={{
        title: isEdit
          ? translate('common.config.editConfirm')
          : translate('marketAreas.message.createConfirm'),
        description: isEdit ? translate('common.config.editConfirmDesc') : '',
      }}
      title={
        isEdit
          ? <Text>{translate('marketAreas.titles.edit')}: <span className="primary">{record?.name}</span></Text>
          : translate('marketAreas.titles.create')
      }
      cancelModalProps={{
				okButtonProps: {
					onClick: () => {
						isEdit ? show(PATH.configMarketAreas, record?.id) : list(PATH.configMarketAreas)
					},
					danger: true
				},
				cancelButtonProps: {}
			}}
      saving={isLoading}
    >
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout="vertical"
        labelCol={{ span: 10 }}
      >
        <div style={{ marginBottom: 12 }}>
          <Text style={{ fontWeight: 500, fontSize: 16 }}>
            {translate('common.titles.detail')}{' '}
          </Text>
        </div>

        <Row gutter={12} style={{ width: '100%' }}>
          <Col span={12}>
            <Form.Item
              label={translate('marketAreas.fields.code')}
              name="code"
              rules={[
                {
                  required: true,
                  message: translate('errors.ER005'),
                },
                {
                  pattern: /^[A-Za-z0-9~!@#$%^&*\_\-\+\=\`\|\(\)\{\}\[\]\:\;\"\'\<\>\,\.\?\/]+$/,
                  message: translate('errors.ER0139'),
                },
                {
                  max: 20,
                  message: translate('errors.ER014', { max: '20' }),
                },
              ]}
            >
              <InputUpper
                placeholder={translate('marketAreas.placeholder.code')}
                disabled={isEdit}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate('marketAreas.fields.name')}
              name="name"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: translate('errors.ER005'),
                },
                {
                  max: 100,
                  message: translate('errors.ER014', { max: '100' }),
                },
              ]}
            >
              <Input placeholder={translate('marketAreas.placeholder.name')} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate('marketAreas.fields.description')}
              name="description"
              rules={[
                {
                  max: 200,
                  message: translate('errors.ER014', { max: '200' }),
                },
              ]}
            >
              <TextArea
                showCount={{
                  formatter: (args: { count: number }) => `${args?.count}/200`,
                }}
                placeholder={translate('marketAreas.placeholder.description')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CreateCustom>
  )
}
