import { Collapse, Table, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";
import { DataText } from "components";
import { ProjectMember } from "interfaces/Project";
import { FC, memo, useMemo, useState } from "react";

interface Props {
  label: string;
  dataTable?: ProjectMember[];
}

const { Text } = Typography;
const { Panel } = Collapse;

const TableDepartment: FC<Props> = memo((props) => {
  const { label, dataTable = [] } = props;
  const translate = useTranslate();
  const [visibleCollapse, setVisibleCollapse] = useState(true);

  const toggleCollapse = () => setVisibleCollapse((prev) => !prev);

  const columns = useMemo<ColumnsType<ProjectMember>>(
    () => [
      {
        title: translate("common.no"),
        width: 70,
        dataIndex: "id",
        key: "id",
        render: (_, __, index) => {
          return <Text>{index + 1}</Text>;
        },
      },
      {
        title: translate("Nhân viên"),
        width: 200,
        dataIndex: "employee",
        key: "employee",
        render: (_, record, index) => {
          return <DataText value={record?.employee?.name} />;
        },
      },
      {
        title: translate("Phòng ban"),
        width: 200,
        dataIndex: "department",
        key: "department",
        render: (_, record, index) => {
          return <DataText value={record?.department?.name} />;
        },
      },
      {
        title: translate("Chức vụ"),
        width: 200,
        dataIndex: "title",
        key: "title",
        render: (_, record, index) => {
          return <DataText value={record?.title?.name} />;
        },
      },
      {
        title: translate("Vai trò"),
        width: 200,
        dataIndex: "projectRole",
        key: "projectRole",
        render: (_, record, index) => {
          return <DataText value={record?.projectRole?.name} />;
        },
      },
    ],
    []
  );

  return (
    <div style={{ padding: 16, backgroundColor: "white" }}>
      <Collapse
        className="w-full"
        defaultActiveKey={["1"]}
        ghost
        onChange={toggleCollapse}
      >
        <Panel header={translate(label)} key="1">
          <Table
            style={{ marginTop: 12 }}
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            rowKey="id"
            columns={columns}
            dataSource={dataTable}
            scroll={{ x: 950 }}
          />
        </Panel>
      </Collapse>
    </div>
  );
});

export default TableDepartment;
