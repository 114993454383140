import { useTranslate } from "@pankod/refine-core";
import { IWorkflowNode, IWorkflowNodeData } from "interfaces";
import { Typography } from "antd";
import { Layout } from "antd";
import { ButtonProps, FormInstance, FormProps } from "@pankod/refine-antd";
import React from "react";
import { WorkflowStepType } from "api/enums";
import { validateNode } from "./diagramHelper";
import EndNode from "./DefaultNode/EndNote";
import StartNode from "./DefaultNode/StartNode";
import NoNodeSelected from "./DefaultNode/EmptyNode";
import NormalDataNode from "./DataNode/Normal";
import DecisionDataNode from "./DataNode/Decision";

const { Title } = Typography;
const { Content } = Layout;

type WorkflowStepProps = {
  step?: IWorkflowNodeData;
  otherSteps?: IWorkflowNodeData[];
  onSubmit: (e: IWorkflowNode) => void;
  onFormChange: () => void;
  form: FormInstance;
  formProps: FormProps;
  isUpdate: boolean;
  saveButtonProps: ButtonProps;
  isCreate?: boolean;
};

export const WorkflowDiagramNode: React.FC<WorkflowStepProps> = ({
  step,
  otherSteps,
  onSubmit,
  onFormChange,
  form,
  formProps,
  saveButtonProps,
  isUpdate,
  isCreate,
}) => {
  const t = useTranslate();
  const nodeInit = step;
  const dataNodeInit = step?.data;
  const isBegin = dataNodeInit?.type === WorkflowStepType.Begin;
  const isEnd = dataNodeInit?.type === WorkflowStepType.End;
  const isNormal = dataNodeInit?.type === WorkflowStepType.Normal;
  const isDecision = dataNodeInit?.type === WorkflowStepType.Decision;

  if (!step) {
    return <NoNodeSelected isCreate={isCreate} />;
  }

  const onUpdate = (newState: IWorkflowNode) => {
    const beforeStep = otherSteps?.find(
      (t) => t?.data?.defaultNextStep?.value === step.id
    )?.data;

    newState.errors = validateNode(newState, !!beforeStep);

    onSubmit({
      id: nodeInit?.id,
      ...newState,
    });
  };

  return (
    <Content style={{ paddingTop: 0, height: "100%" }}>
      <Title level={5}>{t("workflows.titles.stepSetup")}</Title>

      {isBegin && (
        <StartNode
          step={dataNodeInit}
          otherSteps={otherSteps}
          onChange={onUpdate}
          onFormChange={onFormChange}
          isUpdate={isUpdate}
        />
      )}
      {isEnd && <EndNode />}
      {isNormal && (
        <NormalDataNode
          form={form}
          formProps={formProps}
          saveButtonProps={saveButtonProps}
          step={dataNodeInit}
          otherSteps={otherSteps}
          onChange={onUpdate}
          onFormChange={onFormChange}
        />
      )}
      {isDecision && (
        <DecisionDataNode
          form={form}
          formProps={formProps}
          saveButtonProps={saveButtonProps}
          step={dataNodeInit}
          otherSteps={otherSteps}
          onChange={onUpdate}
          onFormChange={onFormChange}
        />
      )}
    </Content>
  );
};
