import {
  IResourceComponentsProps,
  useNavigation,
  useTable,
  useTranslate,
} from "@pankod/refine-core";
import {
  Icons,
  List,
  Radio,
  Space,
  Table,
  Tag,
  TextField,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { ConfigStatus } from "api/enums";
import { ButtonTooltip, DataText, EmptyData, SearchBox } from "components";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { IBusinessIndustryConfig } from "interfaces";
import { useNavigate } from "react-router-dom";
import React from "react";
import { formatISODateTimeToView } from "utils/commons";
import { ConfigStatusTag } from "components/StatusTag";

export const BusinessIndustryList: React.FC<IResourceComponentsProps> = (
  props
) => {
  const t = useTranslate();

  const navigate = useNavigate();
  const { create, edit } = useNavigation();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);

  const {
    tableQueryResult: { data: dataTable },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    sorter,
    setSorter,
    setFilters,
  } = useTable<IBusinessIndustryConfig>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const onSearch = (value: string) => {
    setCurrent(1);
    if (value) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  return (
    <>
      <List
        headerButtons={
          <ButtonTooltip
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => create(PATH.configBusinessIndustries)}
            {...checkCreateHaveHelperText(PATH.configBusinessIndustries)}
          >
            {t("actions.create")}
          </ButtonTooltip>
        }
        breadcrumb={null}
      >
        <div className="list-content">
          <Space align="start">
            <SearchBox
              placeholder={t("businessIndustries.search")}
              onSearch={onSearch}
            />
          </Space>
          <div>
            <Radio.Group
              defaultValue={-1}
              buttonStyle="solid"
              onChange={(e) => {
                setCurrent(1);
                setFilters([
                  {
                    field: "status",
                    operator: "eq",
                    value: e?.target?.value === -1 ? "" : !e?.target?.value,
                  },
                ]);
              }}
              style={{ display: "block", marginBottom: 16 }}
            >
              <Radio.Button value={-1}>{t("common.all")}</Radio.Button>
              <Radio.Button value={ConfigStatus.Active}>
                {t("common.status.active")}
              </Radio.Button>
              <Radio.Button value={ConfigStatus.Deactivate}>
                {t("common.status.deactivate")}
              </Radio.Button>
            </Radio.Group>
          </div>
          <Table
            dataSource={dataTable?.data}
            rowKey="id"
            locale={{
              emptyText: (
                <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
              ),
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  navigate(`show/${record.id}`);
                },
              };
            }}
            pagination={{
              pageSize: pageSize,
              current: current,
              total: dataTable?.total,
              onChange: (cur, size) => {
                setCurrent(cur);
                setPageSize(size);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
              },
              locale: {
                items_per_page: t("common.pageNumber"),
              },
              showTotal: (total) => (
                <p>{t("common.totalPage", { total: total })}</p>
              ),
            }}
            onChange={(_, f, s) => {
              const newSort = s as any;
              setSorter([
                {
                  field: newSort?.field,
                  order: newSort?.order,
                },
              ]);
            }}
            showSorterTooltip={false}
            scroll={{ x: "auto" }}
          >
            <Table.Column
              key="userId"
              title={t("common.no")}
              width={40}
              render={(_, __, index) => (current - 1) * pageSize + index + 1}
            />
            <Table.Column
              dataIndex="code"
              title={t("businessIndustries.fields.code")}
              defaultSortOrder={getDefaultSortOrder("code", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="name"
              width={220}
              title={t("businessIndustries.fields.name")}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="marketArea.name"
              width={220}
              title={t("businessIndustries.fields.marketAreas")}
              defaultSortOrder={getDefaultSortOrder("marketArea.name", sorter)}
              render={(value, record: any) => (
                <DataText value={record?.marketArea?.name} defaultValue="-" />
              )}
              sorter
            />
            <Table.Column
              dataIndex="status"
              title={t("businessIndustries.fields.status")}
              defaultSortOrder={getDefaultSortOrder("status", sorter)}
              width={220}
              sorter
              render={(r) => <ConfigStatusTag status={r} />}
            />
            <Table.Column
              key="updatedName"
              dataIndex="updatedName"
              title={t("common.updatedName")}
              render={(value) => (
                <TextField className="primary" value={value} />
              )}
              sorter
              width={220}
            />
            <Table.Column
              key="updatedDate"
              dataIndex="updatedDate"
              title={t("common.updatedDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(
                    value as string,
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                />
              )}
              width={200}
              defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
              sorter
            />
            <Table.Column
              key="createdName"
              dataIndex="createdName"
              title={t("common.createdName")}
              sorter
              render={(value) => (
                <TextField className="primary" value={value} />
              )}
              width={220}
            />
            <Table.Column
              key="createdDate"
              dataIndex="createdDate"
              title={t("common.createdDate")}
              render={(value) => (
                <TextField
                  value={formatISODateTimeToView(
                    value as string,
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                />
              )}
              width={200}
              defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
              sorter
            />

            <Table.Column<any>
              title={t("table.actions")}
              dataIndex="actions"
              align="center"
              fixed="right"
              width={120}
              render={(_, record) => (
                <Space>
                  <ButtonTooltip
                    type="text"
                    icon={<Icons.EditOutlined />}
                    className="gray"
                    onClick={(e) => {
                      e.stopPropagation();
                      edit(PATH.configBusinessIndustries, record.id);
                    }}
                    {...checkEditHaveHelperText(
                      PATH.configBusinessIndustries,
                      !record.status
                    )}
                  />
                  <div className="line-between-action"></div>
                </Space>
              )}
            />
          </Table>
        </div>
      </List>
    </>
  );
};
