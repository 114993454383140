import {
  Icons,
  Radio,
  Table,
  TextField,
  getDefaultSortOrder,
  Tag,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useTable,
  useTranslate,
} from "@pankod/refine-core";
import { ConfigStatus } from "api/enums";
import { ButtonTooltip, DataText, EmptyData, SearchBox } from "components";
import { ListCustom } from "components/layout";
import { PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { useNavigate } from "react-router-dom";
import React from "react";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { ConfigStatusTag } from "components/StatusTag";

export const CostList: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { create, show, edit } = useNavigation();
  const navigate = useNavigate();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);
  const {
    tableQueryResult: { data: dataTable, isLoading, isRefetching },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    filters,
    setFilters,
    sorter,
    setSorter,
  } = useTable<any>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const onSearch = (value: string) => {
    setCurrent(1);
    if (value) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  return (
    <ListCustom
      title={t("costs.titles.list")}
      headerButtons={
        <ButtonTooltip
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => create(PATH.configCosts)}
          {...checkCreateHaveHelperText(PATH.configCosts)}
        >
          {t("actions.create")}
        </ButtonTooltip>
      }
      breadcrumb={null}
    >
      <div className="list-content">
        <SearchBox
          placeholder={t("costs.search.placeholder")}
          onSearch={onSearch}
        />
        <Radio.Group
          defaultValue={-1}
          buttonStyle="solid"
          onChange={(e) => {
            setCurrent(1);
            setFilters([
              {
                field: "status",
                operator: "eq",
                value: e?.target?.value === -1 ? "" : e?.target?.value,
              },
            ]);
          }}
          style={{ display: "block", marginBottom: 16 }}
        >
          <Radio.Button value={-1}>{t("common.status.all")}</Radio.Button>
          <Radio.Button value={true}>{t("common.status.active")}</Radio.Button>
          <Radio.Button value={false}>
            {t("common.status.deactivate")}
          </Radio.Button>
        </Radio.Group>

        <Table
          dataSource={dataTable?.data}
          rowKey="id"
          locale={{
            emptyText: (
              <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
            ),
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                show(PATH.configCosts, record.id);
              },
            };
          }}
          pagination={{
            pageSize: pageSize,
            current: current,
            total: dataTable?.total,
            locale: {
              items_per_page: t("common.pageNumber"),
            },
            onChange: (cur, size) => {
              setCurrent(cur);
              setPageSize(size);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            },
            showTotal: (total) => (
              <p>{t("common.totalPage", { total: total })}</p>
            ),
          }}
          scroll={{ x: "auto" }}
          onChange={(_, f, s) => {
            const newSort = s as any;
            setSorter([
              {
                field: newSort?.field,
                order: newSort?.order,
              },
            ]);
          }}
          showSorterTooltip={false}
        >
          <Table.Column
            key="id"
            title={t("common.no")}
            render={(_, __, index) => (current - 1) * pageSize + index + 1}
            width={80}
          />
          <Table.Column
            dataIndex="code"
            title={t("costs.fields.code")}
            render={(value, record: any) => (
              <DataText value={record?.code} defaultValue="-" />
            )}
            defaultSortOrder={getDefaultSortOrder("code", sorter)}
            sorter
            width={200}
          />
          <Table.Column
            dataIndex="name"
            title={t("costs.fields.name")}
            render={(value, record: any) => <DataText value={record?.name} />}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
            width={200}
          />
          <Table.Column
            dataIndex="status"
            title={t("common.config.status")}
            render={(value, record: any) => (
              <ConfigStatusTag status={record?.status} />
            )}
            defaultSortOrder={getDefaultSortOrder("status", sorter)}
            sorter
            width={200}
          />
          <Table.Column
            dataIndex="updatedName"
            title={t("common.updatedName")}
            render={(value, record: any) => (
              <DataText
                className="primary"
                value={record?.updatedName}
                defaultValue="-"
              />
            )}
            defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
            sorter
            width={200}
          />
          <Table.Column
            dataIndex="updatedDate"
            title={t("common.updatedDate")}
            render={(value, record: any) => (
              <DateTimeFieldCustom value={record?.updatedDate} />
            )}
            defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
            sorter
            width={200}
          />

          <Table.Column
            dataIndex="createdName"
            key="createdName"
            title={t("common.createdName")}
            render={(value, record: any) => (
              <DataText
                className="primary"
                value={record?.createdName}
                defaultValue="-"
              />
            )}
            defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
            sorter
            width={200}
          />

          <Table.Column
            dataIndex="createdDate"
            key="createdDate"
            title={t("common.createdDate")}
            render={(value, record: any) => (
              <DateTimeFieldCustom value={record?.createdDate} />
            )}
            defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
            sorter
            width={200}
          />

          <Table.Column<any>
            title={t("table.actions")}
            dataIndex="actions"
            align="center"
            width={120}
            render={(_, record) => {
              return (
                <ButtonTooltip
                  type="text"
                  icon={<Icons.EditOutlined />}
                  className="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    edit(PATH.configCosts, record.id);
                  }}
                  {...checkEditHaveHelperText(
                    PATH.configCosts,
                    !record?.status
                  )}
                />
              );
            }}
          />
        </Table>
      </div>
    </ListCustom>
  );
};
