import {
  Button,
  Col,
  Divider,
  Form,
  Icons,
  Row,
  Select,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { SelectCustom } from "components";
import ButtonConfirm from "components/ButtonConfirm";
import { API_PATH } from "configs/path";
import { FC, memo } from "react";
import { searchSelect } from "utils/commons";
import { v4 as uuid } from "uuid";
import { Props, useController } from "./controller";

const { Text } = Typography;

const AssignExploitingForm: FC<Props> = memo((props) => {
  const {
    products,
    customers,
    formProps,
    initialValues,
    transformDefaultProductToFormProduct,
    transformDefaultCustomerToFormCustomer,
    employeeSelectProps,
    isEdit,
    isProduct,
    list,
    isShowButtonRemoveItem,
    AssignExploitingList,
    translate,
    onChangeItem,
    onFinishedFormValidate,
    setList,
    onRemoveItem,
    productOptions,
    customertOptions,
  } = useController(props);

  const renderListProduct = () => {
    return (
      <>
        <Table.Column
          title={translate("businessOpportunities.productId")}
          dataIndex="name"
          width={"10%"}
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                style={{ margin: 0 }}
                name={["products", keyRow, "code"]}
              >
                <Text>{products?.[keyRow]?.code || "-"}</Text>
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("businessOpportunities.productName")}
          dataIndex="name"
          render={(v, keyRow: string) => {
            return (
              <>
                <Form.Item name={["products", keyRow, "name"]} hidden />
                <Form.Item
                  required
                  style={{ margin: 0 }}
                  name={["products", keyRow, "id"]}
                  rules={[
                    {
                      required: true,
                      message: translate("errors.ER005"),
                    },
                  ]}
                >
                  <SelectCustom
                    optionLabel="name"
                    optionValue="id"
                    resource={API_PATH.productDropdownList}
                    label={products?.[keyRow]?.name}
                    useServerFiltering={false}
                    allowClear
                    onChangeOption={onChangeItem(keyRow)}
                    customOptions={productOptions}
                  />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("businessOpportunities.productGroup")}
          dataIndex="productGroup"
          render={(v, keyRow: string) => {
            return (
              <>
                <Form.Item
                  required
                  style={{ margin: 0 }}
                  name={["products", keyRow, "productGroup"]}
                >
                  <Text>{products?.[keyRow]?.productGroup || "-"}</Text>
                </Form.Item>
              </>
            );
          }}
        />
      </>
    );
  };

  const renderListCustomer = () => {
    return (
      <>
        <Table.Column
          title={translate("customer.customerCode")}
          dataIndex="code"
          width={"10%"}
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                style={{ margin: 0 }}
                name={["customers", keyRow, "code"]}
              >
                <Text>{customers?.[keyRow]?.code || "-"}</Text>
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("customer.customerName")}
          dataIndex="name"
          render={(v, keyRow: string) => {
            return (
              <>
                <Form.Item name={["customers", keyRow, "name"]} hidden />
                <Form.Item
                  required
                  style={{ margin: 0 }}
                  name={["customers", keyRow, "id"]}
                  rules={[
                    {
                      required: true,
                      message: translate("errors.ER005"),
                    },
                  ]}
                >
                  <SelectCustom
                    optionLabel="name"
                    optionValue="id"
                    resource={API_PATH.customerDropdownlistForAssignExploiting}
                    label={customers?.[keyRow]?.name}
                    allowClear
                    onChangeOption={onChangeItem(keyRow)}
                    useServerFiltering={false}
                    filters={[
                      {
                        field: "status",
                        operator: "eq",
                        value: 1,
                      },
                    ]}
                    customOptions={customertOptions}
                  />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("assignExploiting.industry")}
          dataIndex="businessIndustry"
          width={"10%"}
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                style={{ margin: 0 }}
                name={["customers", keyRow, "businessIndustry"]}
              >
                <Text>{customers?.[keyRow]?.businessIndustry || "-"}</Text>
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("customer.market")}
          dataIndex="marketArea"
          width={"10%"}
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                style={{ margin: 0 }}
                name={["customers", keyRow, "marketArea"]}
              >
                <Text>{customers?.[keyRow]?.marketArea || "-"}</Text>
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("customer.provinceCity")}
          dataIndex="province"
          width={"10%"}
          render={(v, keyRow: string) => {
            return (
              <Form.Item
                style={{ margin: 0 }}
                name={["customers", keyRow, "province"]}
              >
                <Text>{customers?.[keyRow]?.province || "-"}</Text>
              </Form.Item>
            );
          }}
        />
      </>
    );
  };

  return (
    <Form
      {...formProps}
      initialValues={{
        ...initialValues,
        employeeID: initialValues?.employee?.id || "",
        assignExploitingType: initialValues?.assignExploitingType?.id || 1,
        products: transformDefaultProductToFormProduct,
        customers: transformDefaultCustomerToFormCustomer,
      }}
      onFinish={onFinishedFormValidate}
      layout="vertical"
    >
      <Row>
        <Col span={12}>
          <Form.Item
            name="employeeID"
            rules={[{ required: true, message: translate("errors.ER005") }]}
            label={translate("businessOpportunities.employee")}
          >
            <Select
              {...(!isEdit
                ? employeeSelectProps
                : {
                    options: [
                      {
                        label: `${initialValues?.employee?.code} - ${initialValues?.employee?.name}`,
                        value: initialValues?.employee.id,
                      },
                    ],
                  })}
              disabled={isEdit}
              filterOption={(inputValue: string, option: any) => {
                return option && searchSelect(inputValue, option?.label);
              }}
            />
          </Form.Item>
          <Form.Item
            name="assignExploitingType"
            rules={[{ required: true, message: translate("errors.ER005") }]}
            label={translate("assignExploiting.assignExploitingType")}
          >
            <Select
              disabled={isEdit}
              options={AssignExploitingList}
              filterOption={(inputValue: string, option: any) => {
                return option && searchSelect(inputValue, option?.label);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row style={{ marginBottom: 16 }} align="middle">
        <Col flex={1}>
          <label style={{ fontWeight: 500 }}>
            {translate(
              isProduct
                ? "businessOpportunities.productList"
                : "customer.customers"
            )}
          </label>
        </Col>
        <Button
          type="primary"
          onClick={() => {
            setList([...list, uuid()]);
          }}
          icon={<Icons.PlusOutlined />}
        >
          {translate("actions.create")}
        </Button>
      </Row>

      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
      >
        {isProduct ? renderListProduct() : renderListCustomer()}

        <Table.Column
          align="center"
          width="120px"
          title={translate("table.actions")}
          render={(v, record, index) => {
            if (isShowButtonRemoveItem) return null;
            return (
              <ButtonConfirm
                text={translate("payments.costs.deleteConfirmText")}
                description=""
                onClick={onRemoveItem(index)}
                type="text"
              >
                <Icons.DeleteOutlined />
              </ButtonConfirm>
            );
          }}
        />
      </Table>
    </Form>
  );
});

export default AssignExploitingForm;
