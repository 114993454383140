import { Form, useForm, notification } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useCreate,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import { Typography } from "antd";
import { CreateCustom } from "components/layout";
import { PATH } from "configs/path";
import { IPrepay } from "interfaces";
import React, { useEffect, useMemo } from "react";
import {
  formatOnlyDateToUTC,
  mappingErrorFromApi,
  validateFieldsRequied,
} from "utils/commons";
import usePermissions from "hooks/permission";
import PrepayForm from "./components/form";
import { useNumToWord } from "hooks/useNumToWord";
import { LoadingIndicator } from "components/layout/pageLoading";

export const PrepayCreate: React.FC<IResourceComponentsProps> = (props) => {
  const { form, formProps, saveButtonProps, queryResult, formLoading } = useForm<IPrepay>();
  const record = queryResult?.data?.data?.objectData;
  const resourcePath = PATH.prepays;
  const t = useTranslate();
  const { checkCreate } = usePermissions();

  const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>();
  const { mutate: mutateCreate, isLoading } = useCreate<any>();

  const { list, show } = useNavigation();
  const costObj = Form.useWatch("listCosts", form) || {};
  const currency = Form.useWatch("currencyType", form);

  const listCosts = useMemo(
    () => Object.keys(costObj)?.map((k) => costObj[k]),
    [costObj]
  );

  const total = useMemo(
    () => listCosts?.reduce((a: any, b: any) => a + +(b.money || 0), 0),
    [listCosts]
  );

  const { text } = useNumToWord({
    currency,
    num: total,
  });

  const onSubmit = (isDraft = false) => {
    const valueForm: IPrepay = form.getFieldsValue() as unknown as IPrepay;

    const dataSend: any = {
      resource: PATH.prepays,
      metaData: isDraft
        ? {
            type: "draft",
          }
        : {},

      values: {
        ...valueForm,
        totalAmountWord: text,
        name: form.getFieldValue("name")?.trim(),
        reason: form.getFieldValue("reason")?.trim(),
        attachments: form.getFieldValue("attachments") || [],
        bankName: form.getFieldValue("bankName")?.trim(),
        bankAccountName: form.getFieldValue("bankAccountName")?.trim(),
        bankAccountNumber: form.getFieldValue("bankAccountNumber")?.trim(),
        listCosts: listCosts.map((a, idx) => ({ ...a, ordinal: idx })),
        estimatedPaymentDate: form.getFieldValue("estimatedPaymentDate")
          ? formatOnlyDateToUTC(form.getFieldValue("estimatedPaymentDate"))
          : null,
      },
    };

    mutateCreate(dataSend, {
      onSuccess: (res) => {
        setVisibleShowModal(false);
        notification.success({
          message: isDraft
            ? t("common.saveDraftSuccess")
            : t("common.sentSuccess"),
        });
        show(resourcePath, res.data?.id);
      },
      onError: (error: any) => {
        setVisibleShowModal(false);
        mappingErrorFromApi(error, form, {
          ER047: "name",
          ER046: "code",
        });
      },
    });
  };

  const onDraft = () => {
    onSubmit(true);
  };
  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
        ...record?.bank,
        listCosts: record?.advancePaymentListCosts?.map((item: any) => {
          return  {
            ...item,
            costId: item?.cost?.id,
          }
        }),
        employeeId:record?.employee?.id,
        supplierId: record?.supplier?.id,
        caseId: record?.case?.id,
      });
    }
  }, [record]);

  return (
    <>
     {formLoading && <LoadingIndicator isLoading={formLoading} />}
      <CreateCustom
        {...props}
        title={t("prepays.titles.create")}
        saveButtonProps={{
          ...saveButtonProps,
          title: t("prepays.actions.sendRequest"),
          disabled: !checkCreate(resourcePath),
        }}
        visibleShowModal={visibleShowModal}
        setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
        onSubmit={onSubmit}
        onDraft={onDraft}
        confirmModalProps={{
          title: t("prepays.message.confirmSend"),
        }}
        draftButtonProps={{
          title: t("buttons.draft"),
          type: "default",
          disabled: !checkCreate(resourcePath),
        }}
        cancelModalProps={{
          title: t("prepays.message.confirmCancel"),
          description: t("prepays.message.confirmCancelDesc"),
          okButtonProps: { danger: true },
          cancelButtonProps: {},
        }}
        draftModalProps={{
          title: t("prepays.message.confirmEditDraft"),
        }}
        validateDraft={() =>
          validateFieldsRequied(["name", "caseId"], form, t("errors.ER005"))
        }
        onCancel={() => list(resourcePath)}
        saving={isLoading}
      >
        <PrepayForm
          form={form}
          formProps={formProps}
          record={record}
          onFinishForm={() => setVisibleShowModal(true)}
        />
      </CreateCustom>
    </>
  );
};
