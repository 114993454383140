import { Typography } from "@pankod/refine-antd";
import { Position } from "reactflow";
import i18n from "i18n";
import { LogicalOperator } from "api/enums";

export const diagramOptionsRender = () => ({
  node: {
    start: {
      index: 0,
      type: "nodeCustom",
      targetPosition: Position.Right,
      data: {
        name: i18n.t("workflows.fields.startNodeName"), //'Bắt đầu',
        errors: ["errors.ER062"],
        isLineManagerApprove: false,
        type: 0,
      },
      position: { x: 0, y: 0 },
    },
    end: {
      index: 1,
      type: "nodeCustom",
      sourcePosition: Position.Left,
      data: {
        name: i18n.t("workflows.fields.endNodeName"),
        errors: ["errors.ER062"],
        isLineManagerApprove: false,
        type: 2,
      },
    },
    heightNodeStart: 130,
    heightNode: 180,
    widthNode: 300,
    margin: 20,
    defaultName: i18n.t("workflows.fields.stepNamePlaceHolder"),
  },
});

export const dataDecisionDefault = {
  approvalStepConditionForApprovalStep: {
    logical: LogicalOperator.AND,
    approvalStepConditionItemForApprovalStep: [],
  },
};
