import { Tag, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { IntegrationStatus } from "api/enums";
const { Text } = Typography;
interface IntegrationStatusTagProps {
  status?: any;
}
export const IntegrationStatusTag = ({ status }: IntegrationStatusTagProps) => {
  const t = useTranslate();
  if (status === IntegrationStatus.SynchronizedSuccess) {
    return (
      <Tag color="success">
        {t("integration.syncStatusType.synchronizedSuccess")}
      </Tag>
    );
  } else if (status === IntegrationStatus.SynchronizedFail) {
    return (
      <Tag color="red">{t("integration.syncStatusType.synchronizedFail")}</Tag>
    );
  } else {
    return <Text type="secondary">{t("common.emptyText")}</Text>;
  }
};
