import { UserRoles } from "api/enums";
import { PATH } from "configs/path";
import { ROLE_USER, PERMISSIONS } from "configs/storage";
import { Tooltip } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

const usePermissions = () => {
  const permissionsSuperAdmin = [PATH.admins];

  const getPermissions = () => {
    const dataPermissions = JSON.parse(
      localStorage.getItem(PERMISSIONS) || "{}"
    );
    return dataPermissions;
  };

  const redirectToAfterLogin = () => {
    const permissions = getPermissions();
    return Object.keys(permissions)[0];
  };

  const getRole = () => {
    const roles = localStorage.getItem(ROLE_USER) || "";
    return roles;
  };

  const isSuperAdmin = (): boolean => {
    const roles = getRole();
    return roles === UserRoles.SupperAdmin;
  };

  const isTenantAdmin = (): boolean => {
    const roles = getRole();
    return roles === UserRoles.TenantAdmin;
  };

  const isUser = (): boolean => {
    const roles = getRole();
    return roles === UserRoles.User;
  };

  const check = (key: string, action: String) => {
    const permissionsUser = getPermissions();

    if (isSuperAdmin()) {
      return permissionsSuperAdmin.indexOf(key) > -1;
    }

    const featureAccess = permissionsUser[key] || [];
    return featureAccess.indexOf(action) > -1;
  };

  const checkCreate = (key: string) => {
    return check(key, actions.CREATE);
  };

  const checkView = (key: string) => {
    return check(key, actions.VIEW);
  };

  const checkEdit = (key: string) => {
    return check(key, actions.EDIT);
  };

  const checkCreateHaveHelperText = (key: string, disabled?: boolean) => {
    const hasPermission = check(key, actions.CREATE);
    return {
      disabled: disabled || !hasPermission,
      helpertext: hasPermission ? undefined : "common.noHasPermission",
    };
  };

  const checkEditHaveHelperText = (key: string, disabled?: boolean) => {
    const hasPermission = check(key, actions.EDIT);
    return {
      disabled: disabled || !hasPermission,
      helpertext: hasPermission ? undefined : "common.noHasPermission",
    };
  };

  const checkDeleteHaveHelperText = (key: string, disabled?: boolean) => {
    const hasPermission = check(key, actions.DELETE);
    return {
      disabled: disabled || !hasPermission,
      helpertext: hasPermission ? undefined : "common.noHasPermission",
    };
  };

  const actions = {
    CREATE: "create",
    EDIT: "update",
    VIEW: "read",
    DELETE: "delete",
  };

  return {
    check,
    checkCreate,
    checkView,
    checkEdit,
    actions,
    getPermissions,
    isSuperAdmin,
    isTenantAdmin,
    isUser,
    redirectToAfterLogin,
    checkCreateHaveHelperText,
    checkEditHaveHelperText,
    checkDeleteHaveHelperText,
  };
};

export default usePermissions;
