export const USER_TOKEN_KEY = "USER_TOKEN_KEY";
export const USER_INFO_KEY = "USER_INFO_KEY";
export const SIGNIN_TIME_KEY = "SIGNIN_TIME_KEY";

const TOKEN_KEY = "TKAC";
const CUSTOM_TOKEN_KEY = "CUS_TKAC";
const REFRESH_KEY = "RFAC";
const EXPIRED_AT = "TK_EX";
const EXPIRED_IN = "TK_EX_IN";
const ROLE_USER = "ROLE";
const TENANT_ID = "TENANT_ID";
const REMEMBER_LOGIN = "REMEMBER_LOGIN";
const CURRENT_USER = "CURRENT_USER";
const LOCATION_PROVINCES = "LOCATION_PROVINCES";
const LOCATION_DISTRICTS = "LOCATION_DISTRICTS";
const LOCATION_WARDS = "LOCATION_WARDS";
const PERMISSIONS = "PERMISSIONS";
const TENANTS = "TENANTS";
const EXTERNAL_LOGIN = "EXTERNAL_LOGIN";
const USER_EMPLOYEE = "USER_EMPLOYEE";

export {
  TOKEN_KEY,
  REFRESH_KEY,
  EXPIRED_AT,
  EXPIRED_IN,
  CUSTOM_TOKEN_KEY,
  ROLE_USER,
  REMEMBER_LOGIN,
  TENANT_ID,
  CURRENT_USER as USER,
  LOCATION_PROVINCES,
  LOCATION_DISTRICTS,
  LOCATION_WARDS,
  PERMISSIONS,
  TENANTS,
  EXTERNAL_LOGIN,
  USER_EMPLOYEE,
};
