export enum ActionTypeEnum {
  CREATE,
  VIEW,
  EDIT,
  DELETE,
}

export enum CostTableTypeEnum {
  FULL,
  EDIT,
  READ,
  FULL_PREPAY,
}

export enum TableType {
  DEPARTMENT,
  TILLE,
  EMPLOYEE,
  FEATURE,
}
