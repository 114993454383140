import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useShow, useNavigation, useUpdate
} from "@pankod/refine-core";
import { Typography, Tag, Icons, Descriptions, Space, Button, Modal, notification } from "@pankod/refine-antd";
import { ShowCustom } from "components/layout";
import usePermissions from 'hooks/permission'
import { PATH } from "configs/path";
import { LinkText, ButtonTooltip, DataText } from "components";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { showErrorToast } from "api/common";

const { Paragraph, Text } = Typography;

export const UnitShow: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { edit, show } = useNavigation()
  const { checkEdit, checkCreate, checkEditHaveHelperText } = usePermissions()
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>()
  const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>(false)
  const { queryResult } = useShow<any>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const renderStatus = (active: boolean) => {
    if (active) {
      return <Tag color="success">{t("common.status.active")}</Tag>
    }

    return <Tag color="#F0F0F0" style={{color: '#8C8C8C'}}>{t("common.status.deactivate")}</Tag>
  }

  const onChangeStatus = () => {
    mutateUpdate(
      {
        id: record?.id,
        resource: PATH.configUnits,
        values: {},
        metaData:
          record?.status
          ? {type: '/deactivate'}
          : {type: '/activate'}
      },
      {
        onSuccess: (res) => {
          setVisibleShowModal(false)
          notification.success({
            message: record?.status
              ? t('common.config.deactiveSuccess')
              : t('common.config.activeSuccess'),
          })
        },
        onError: (error: any) => {
          setVisibleShowModal(false)
          showErrorToast(error)
        },
      },
    )
  }

  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        title={<Text style={{marginBottom: 0}}>{t("units.titles.show")}: <span className="primary">{record?.name}</span> </Text>}
        breadcrumbText={record?.name}
        headerButtons={
          <Space align="end">
            <ButtonTooltip
              icon={<Icons.EditOutlined/>}
              onClick={() => edit(PATH.configUnits, record.id)}
              {...checkEditHaveHelperText(PATH.configUnits, !record?.status)}
            >
              {t('buttons.edit')}
            </ButtonTooltip>
            {
              !record?.status && <ButtonTooltip
                icon={<Icons.CheckCircleOutlined />}
                type="primary"
                onClick={() => {setVisibleShowModal(true)}}
                {...checkEditHaveHelperText(PATH.configUnits)}
              >
                  {t('buttons.active')}
              </ButtonTooltip>
            }
            {
              record?.status && <ButtonTooltip
                icon={<Icons.CloseCircleOutlined/>}
                type="primary"
                danger
                onClick={() => {setVisibleShowModal(true)}}
                {...checkEditHaveHelperText(PATH.configUnits)}
              >
                  {t('buttons.deactivate')}
              </ButtonTooltip>
            }
          </Space>
        }
      >
        <Descriptions
          column={2}
          title={t("common.titles.detail")}
          layout="vertical"
        >
          <Descriptions.Item
            labelStyle={{ fontWeight: 500 }}
            label={t("units.fields.name")}
          >
            <DataText value={record?.name} />
          </Descriptions.Item>
          <Descriptions.Item label={t("common.config.status")}>{renderStatus(record?.status)}</Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ fontWeight: 500 }}
            span={2}
            label={t("departments.fields.description")}
          >
            <DataText value={record?.description} className="line-break"/>
          </Descriptions.Item>
          <Descriptions.Item label={t("common.updatedDate")}><DateTimeFieldCustom value={record?.updatedDate} /></Descriptions.Item>
          <Descriptions.Item label={t("common.updatedUsername")}><DataText value={record?.updatedName}/></Descriptions.Item>
          <Descriptions.Item label={t("common.createdDate")}><DateTimeFieldCustom value={record?.createdDate} /></Descriptions.Item>
          <Descriptions.Item label={t("common.createdUsername")}><DataText value={record?.createdName}/></Descriptions.Item>
        </Descriptions>
      </ShowCustom>
      <Modal
        title=""
        visible={visibleShowModal}
        onOk={() => onChangeStatus()}
        onCancel={() => {
          setVisibleShowModal(false)
        }}
        okText={t('buttons.confirm')}
        cancelText={t('buttons.reject')}
        okButtonProps={{
          danger:record?.status,
          loading: isLoadingUpdate
        }}
        cancelButtonProps={{disabled: isLoadingUpdate}}
      >
        <Space align="start">
          <Text style={{fontSize: 22}} type="warning"><Icons.InfoCircleOutlined/></Text>
          <div>
            <Paragraph style={{fontWeight: 500, fontSize: 16, paddingTop: 5, marginBottom: 0}}>{
              record?.status
              ? t('units.message.deactivateConfirm')
              : t('units.message.activateConfirm')
            }</Paragraph>
            {record?.status && <Paragraph>{t('common.config.editConfirmDesc')}</Paragraph>}
          </div>
        </Space>
      </Modal>
    </>
  );
};
