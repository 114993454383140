import {
  Button,
  Col,
  Descriptions,
  Icons,
  Row,
  Table,
  Typography,
  notification,
} from "@pankod/refine-antd";
import { FC, memo, useContext, useMemo } from "react";
import { ContractShowConText } from "../..";
import { useTranslate } from "@pankod/refine-core";
import type { ColumnsType } from "antd/es/table";
import { ContractGuaranteeInfos } from "interfaces/Contract";
import { Currency } from "components/Currency";
import { DataText } from "components";
import { formatISODateTimeToView, isEmpty } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
import { getLinkPrewviewFile } from "api";
import { API_PATH } from "configs/path";
const { Text } = Typography;

const InvoicePlan: FC = memo(() => {
  const record = useContext(ContractShowConText);
  const translate = useTranslate();

  const onGetLinkView = (fileUrl: string) => async () => {
    try {
      const res = await getLinkPrewviewFile(
        API_PATH.contractGetLinkPreviewFile,
        fileUrl
      );
      const url: string = res?.data as unknown as string;
      window.open(url, "_blank");
    } catch (error) {
      notification.error({
        message: "Đã có lỗi xảy ra. Vui lòng liên hệ quản trị viên.",
      });
    }
  };

  const columns = useMemo<ColumnsType<ContractGuaranteeInfos>>(
    () => [
      {
        title: translate("Loại bảo lãnh"),
        width: 150,
        dataIndex: "guaranteeType",
        key: "guaranteeType",
        render: (_, record) => {
          return <DataText value={record?.guaranteeType?.name || "-"} />;
        },
      },
      {
        title: translate("Ngày dự kiến phát hành"),
        width: 200,
        dataIndex: "estimatedIssueDate",
        key: "estimatedIssueDate",
        render: (value) => {
          return (
            <DataText
              value={value ? formatISODateTimeToView(value, DATE_FORMAT) : "-"}
            />
          );
        },
      },
      {
        title: translate("Thời gian hiệu lực"),
        width: 200,
        dataIndex: "validPeriod",
        key: "validPeriod",
        render: (value) => {
          return <DataText value={value ? `${value} ngày` : "-"} />;
        },
      },
      {
        title: translate("Đơn vị phát hành"),
        width: 200,
        dataIndex: "guaranteeIssueParty",
        key: "guaranteeIssueParty",
        render: (_, record) => {
          return <DataText value={record?.guaranteeIssueParty?.name || "-"} />;
        },
      },
      {
        title: translate("Điều kiện"),
        width: 200,
        dataIndex: "guaranteeCondition",
        key: "guaranteeCondition",
        render: (_, record) => {
          return <DataText value={record?.guaranteeCondition?.name || "-"} />;
        },
      },
      {
        title: translate("Giá trị bảo lãnh"),
        width: 200,
        dataIndex: "guaranteeValue",
        key: "guaranteeValue",
        render: (value, record) => {
          return (
            <DataText
              value={
                isEmpty(value) ? (
                  "-"
                ) : (
                  <Currency value={value} showCurrency={false} />
                )
              }
            />
          );
        },
      },
      {
        title: translate("Tệp đính kèm"),
        width: 200,
        dataIndex: "attachment",
        key: "attachment",
        render: (_, record) => {
          return (
            <Row gutter={8}>
              <Col style={{ flex: 1 }}>
                <Text>{record?.attachment?.fileUrlOrigin || "-"}</Text>
              </Col>
              <Col>
                <Button
                  type="text"
                  icon={<Icons.EyeOutlined />}
                  className="primary"
                  onClick={onGetLinkView(record?.attachment?.fileUrl)}
                  hidden={!record?.attachment?.fileUrl}
                />
              </Col>
            </Row>
          );
        },
      },
    ],
    []
  );
  return (
    <div style={{ background: "white", padding: 24 }}>
      <Descriptions
        column={2}
        title={translate("Thông tin chứng từ đảm bảo hợp đồng")}
        layout="vertical"
      />
      <Table
        size="small"
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={columns}
        dataSource={record?.contractGuaranteeInfos}
        scroll={{ x: 800 }}
      />
    </div>
  );
});

export default InvoicePlan;
