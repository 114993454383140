import { CSSProperties } from "react";

export const layoutStyles: CSSProperties = {
};

export const containerStyles: CSSProperties = {
    maxWidth: "448px",
    margin: "auto",
};

export const titleStyles: CSSProperties = {
    textAlign: 'center',
    paddingTop: '20px',
    whiteSpace: 'normal'
};
