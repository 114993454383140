import { Form, notification, useForm } from "@pankod/refine-antd";
import { BaseKey, GetOneResponse, IResourceComponentsProps, QueryObserverResult, useCreate, useNavigation, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { useParams } from "@pankod/refine-react-router-v6";
import { CreateCustom } from "components/layout";
import { API_PATH, PATH } from "configs/path";
import { ObjectSubmitCondition } from "interfaces";
import { TabKey } from "interfaces/InternalEstimate";
import { TPlanItem } from "interfaces/SettlementContract";
import { Dispatch, FC, SetStateAction, createContext, memo, useEffect, useState } from "react";
import styled from "styled-components";
import SettlementsContractDetail from "./detail";
import SettlementsContractInfo from "./info";

// Props: CreateSettlementsContractProps
const Container = styled.div<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? "node" : "inherit")};
`;
export type Indicator = {
    code: string;
    value: number;
}
interface SubmitConditionData {
    id: string | null;
    isOk: boolean;
    featureSubmitConditionId: string;
}
export interface IFormCreateSettlementsContract {
    caseId: string;
    note: string;
    indicators: [Indicator];
    settlementContractDocuments: { fileId: string }[];
    submitConditions: SubmitConditionData[];
}

interface StateCreateSettlementsContract {
    note: string;
    isEdit: boolean;
    caseId: string;
    indicators: [Indicator?];
    planState: [TPlanItem[], Dispatch<SetStateAction<TPlanItem[]>>];
    submitConditions: ObjectSubmitCondition[];
    submitConditionFetch?: QueryObserverResult<GetOneResponse<ObjectSubmitCondition[]>, unknown>;
    isLoadingFetchFinancial: boolean;
}

export const CreateSettlementsContractContext = createContext<StateCreateSettlementsContract>({
    note: "",
    isEdit: false,
    caseId: "",
    indicators: [],
    planState: [[], () => []],
    submitConditions: [],
    isLoadingFetchFinancial: false,
});


const CreateSettlementsContract: FC<IResourceComponentsProps> = memo((props) => {
    const translate = useTranslate();
    const { useWatch } = Form;
    const { show } = useNavigation();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(TabKey.INFO);
    const [isEdit] = useState(!!id);
    const { form, formProps, saveButtonProps, formLoading, queryResult } = useForm<
        any,
        any,
        IFormCreateSettlementsContract
    >({
        metaData: { isConverting: true },
    });

    const [visibleModalConfirmCreate, setVisibleShowModalConfirm] =
        useState(false);
    const toggleModalConfirm = (isOpen: boolean) =>
        setVisibleShowModalConfirm(isOpen);

    const tabsCreateSettlementsContract = [
        {
            name: translate("tab.overview.information"),
            key: TabKey.INFO,
            isActive: true,
        },
        {
            name: translate("tab.overview.detailSettlementContracts"),
            key: TabKey.ESTIMATE_INFO,
        },
    ]
    const dataEdit = queryResult?.data?.data?.objectData;
    const caseId = useWatch("caseId", form) ?? "";
    const settlementContractDocuments = useWatch("settlementContractDocuments", form) ?? [];
    const submitConditionForm = useWatch("submitConditions", form) ?? [];
    const submitConditionFetch = useOne<ObjectSubmitCondition[]>({
      resource: API_PATH.settlementContractsSubmitCondition,
      id: "",
      metaData: {
        after: `?refId=${id ?? ""}&caseId=${caseId}`,
      }
    });

    const planState = useState<TPlanItem[]>([]);
    const { mutate: mutateCreate } = useCreate();
    const { mutate: mutateUpdate } = useUpdate();

    const onSubmit = async (isDraft?: boolean) => {
        
        try {
            await form.validateFields();
            const note = form.getFieldValue("note");
            const documents = settlementContractDocuments?.map((item: any) => item.fileId) || [];

            const indicators = planState[0].map((item: TPlanItem) => ({
                code: item.code,
                value: item.value,
            }));

            const dataRequest = {
                resource: API_PATH.settlementContracts,
                values: {
                    caseId,
                    note,
                    settlementContractDocuments: documents,
                    indicators,
                    submitConditions: submitConditionForm,
                },
                metaData: {
                    type: isDraft ? "draft" : "",
                },
                id: id as BaseKey,
            }
            const handleResponse = {
                onSuccess: (res: any) => {
                    notification.success({
                        message: isDraft ? translate("settlements.draft.success") : translate("settlements.save.success"),
                    });
                    show(PATH.settlementContracts, res.data?.id);
                },
                onError: (res: any) => {
                    notification.error({
                        message: translate(res?.message),
                    });
                },
            }
            if (isEdit) {
                mutateUpdate(dataRequest, handleResponse);
                return;
            }
            mutateCreate(dataRequest,handleResponse);

        } catch (error) {

        }
    };
    const validateDraft = async () => {
        try {
            await form.validateFields();

            return Promise.resolve(true);
        } catch (error: any) {
            return Promise.resolve(false);
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            note: dataEdit?.note,
            settlementContractDocuments: dataEdit?.settlementContractDocuments,
            submitConditions: dataEdit?.objectSubmitConditions || [],
        });
    }, [dataEdit, form]);
    
    return (
        <CreateSettlementsContractContext.Provider value={{
            note: dataEdit?.note,
            isEdit,
            caseId: dataEdit?.case?.id,
            planState,
            indicators: [],
            submitConditionFetch,
            submitConditions: dataEdit?.objectSubmitConditions || [],
            isLoadingFetchFinancial: false,
        }}>
            <CreateCustom
                {...props}
                title={translate(isEdit ? "settlements.contract.edit" : "settlements.contract.create")}
                tabs={tabsCreateSettlementsContract}
                onChangeTab={(tab: TabKey) => {
                    setActiveTab(tab);
                }}
                validateDraft={validateDraft}
                bodyStyle={{ padding: 0 }}
                contentStyles={{ background: "transparent", padding: 0 }}
                visibleShowModal={visibleModalConfirmCreate}
                setVisibleShowModal={toggleModalConfirm}
                confirmModalProps={{
                    title: translate(
                        `Bạn muốn ${isEdit ? "sửa" : "tạo"} và chuyển quyết toán hợp đồng này?`
                    ),
                }}
                // onSubmit={() => onSubmit(false)}
                onDraft={() => onSubmit(true)}
                onSubmit={() => onSubmit()}
                saveButtonProps={{
                    ...saveButtonProps,
                    title: translate("Lưu và chuyển"),
                    disabled: formLoading,
                }}
                draftButtonProps={{
                    title: isEdit ? "Lưu chỉnh sửa" : "Lưu nháp",
                    disabled: formLoading,
                }}

                draftModalProps={{
                    title: translate(
                        `Bạn muốn lưu nháp quyết toán hợp đồng này?`
                    ),
                }}
                cancelModalProps={{
                    title: `Bạn muốn hủy ${isEdit ? "chỉnh sửa" : "tạo"
                        } quyết toán hợp đồng này`,
                    description: `Sau khi hủy ${isEdit ? "chỉnh sửa" : "tạo"
                        } quyết toán hợp đồng, mọi thông tin của bạn sẽ không được lưu lại.`,
                }}
            >
                <Form
                    {...formProps}
                    form={form}
                    layout="vertical"
                    onFinish={()=>toggleModalConfirm(true)}
                    >
                    <Container hidden={activeTab !== TabKey.INFO}>
                        <SettlementsContractInfo />
                    </Container>

                    <Container hidden={activeTab !== TabKey.ESTIMATE_INFO}>
                        <SettlementsContractDetail />
                    </Container>
                </Form>
            </CreateCustom>
        </CreateSettlementsContractContext.Provider>
    );
}
)
export default CreateSettlementsContract;
