import { useTranslate, IResourceComponentsProps, useCreate, useUpdate, useSelect, useNavigation } from "@pankod/refine-core";
import React, { useEffect } from "react";
import {
  Form,
  Input,
  useForm,
  notification,
  Typography,
  Row,
  Col,
  Select
} from "@pankod/refine-antd";
import { IBusinessIndustryConfig } from "interfaces";
import { API_PATH, PATH } from "configs/path";
import { CreateCustom } from "components/layout";
import { useNavigate } from 'react-router-dom'
import { mappingErrorFromApi } from "utils/commons";
import { useParams } from 'react-router-dom'
import InputUpper from "components/InputUpper";
import { SelectCustom } from "components";

const { Title } = Typography
const { TextArea } = Input;


export const BusinessIndustryCreate: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate()
  const navigate = useNavigate()
  const { show,list } = useNavigation()
  const { id } = useParams()
  const [ visibleShowModal, setVisibleShowModal ] = React.useState<boolean>(false);
  const { mutate: mutateCreate, isLoading } = useCreate<IBusinessIndustryConfig>();
  const { mutate: mutateUpdate } = useUpdate<IBusinessIndustryConfig>()
  const { form, formProps, saveButtonProps, queryResult } = useForm<IBusinessIndustryConfig>(
    {
      resource: PATH.configBusinessIndustries,
      id: id
    }
  );
  const record: IBusinessIndustryConfig | undefined = queryResult?.data?.data
  const isEdit = !!record?.id

  const onSubmit = () => {
    if (isEdit) {
      mutateUpdate(
        {
          id: id || '',
          resource: PATH.configBusinessIndustries,
          values: {
            name: form.getFieldValue('name').trim(),
            code: form.getFieldValue('code').trim(),
            description: form.getFieldValue('description'),
            marketAreaId: form.getFieldValue('marketAreaId')
          },
        },
        {
          onSuccess: (res) => {
            notification.success({
              message: t('common.config.editSuccess')
            })
            navigate(`show/${res?.data?.id}`)
          },
          onError: (error: any) => {
            setVisibleShowModal(false)
            const errorFieldMapping: any = {
                ER0130: 'name'
            }
            mappingErrorFromApi(error, form, errorFieldMapping )
          },
        },
      )
    } else {
      mutateCreate(
        {
          resource: PATH.configBusinessIndustries,
          values: {
            name: form.getFieldValue('name').trim(),
            code: form.getFieldValue('code').trim(),
            description: form.getFieldValue('description'),
            marketAreaId: form.getFieldValue('marketAreaId')
          },
        },
        {
          onSuccess: (res) => {
            notification.success({
              message: t('common.createSuccess')
            })
            navigate(`show/${res?.data?.id}`)
          },
          onError: (error: any) => {
            setVisibleShowModal(false)
            const errorFieldMapping: any = {
                ER0130: 'name'
            }
            mappingErrorFromApi(error, form, errorFieldMapping )
          },
        },
      )
    }
  }

  useEffect(() =>{
    if(isEdit) {
        form.setFieldsValue({
            marketAreaId: record?.marketArea?.id
        })
    }
  },[isEdit])

  return (
    <CreateCustom
      {...props}
      title={
        isEdit
          ? t('businessIndustries.titles.edit') + ': ' + record?.name
          : t('businessIndustries.titles.create')
      }
      confirmModalProps={{
        title: isEdit
          ? t('common.config.editConfirm')
          : t('businessIndustries.createConfirm'),
        description: isEdit ? t('common.config.editConfirmDesc') : '',
      }}
      saveButtonProps={{
        ...saveButtonProps,
        title: isEdit
          ? t('common.saveEdit')
          : t('buttons.add')
      }}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      saving={isLoading}
      cancelModalProps={{
          okButtonProps: {
              onClick: () => {
                  isEdit ? show(PATH.configBusinessIndustries, record?.id || '') : list(PATH.configBusinessIndustries)
              },
              danger: true
          },
          cancelButtonProps: {}
      }}
    >
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout="vertical"
        labelCol={{ span: 10 }}
      >
        <Title level={5}>{t('common.titles.detail')}</Title>

        <Row gutter={12} style={{ width: '100%' }}>
          <Col span={12}>
            <Form.Item
              label={t('businessIndustries.fields.code')}
              name="code"
              rules={[
                {
                  required: true,
                  message: t('errors.ER005'),
                },
                {
                  pattern: /^[A-Za-z0-9~!@#$%^&*\_\-\+\=\`\|\(\)\{\}\[\]\:\;\"\'\<\>\,\.\?\/]+$/,
                  message: t('errors.ER0139'),
                },
                {
                  max: 20,
                  message: t('errors.ER014', { max: '20' }),
                },
              ]}
            >
              <InputUpper
                placeholder={t('businessIndustries.placeholder.code')}
                disabled={isEdit}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('businessIndustries.fields.name')}
              name="name"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t('errors.ER005'),
                },
                {
                  max: 100,
                  message: t('errors.ER014', { max: '100' }),
                },
              ]}
            >
              <Input placeholder={t('businessIndustries.placeholder.name')} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
           <Form.Item
              label={t('businessIndustries.fields.marketAreas')}
              name="marketAreaId"
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                }
              ]}
            >
              <SelectCustom
                allowClear
                resource={API_PATH.configMarketAreas + API_PATH.dropdownlist}
                optionValue="id"
                optionLabel="label"
                label={record?.marketArea?.name}
                formatData={(r: any) => ({
                    ...r,
                    label: `${r.code} - ${r.name}`,
                })}
                
                useServerFiltering={false}
                placeholder={t('businessIndustries.placeholder.marketAreas')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t('businessIndustries.fields.description')}
              name="description"
              rules={[
                {
                  max: 200,
                  message: t('errors.ER014', { max: '200' }),
                },
              ]}
            >
              <TextArea
                showCount={{
                  formatter: (args: { count: number }) => `${args?.count}/200`,
                }}
                placeholder={t('businessIndustries.placeholder.description')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </CreateCustom>
  );

}