import { Modal, Space, Typography, Icons } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { cloneElement, useState } from "react";

const { Text } = Typography;

const ModalConfirm = (props: any) => {
  const {
    onChange,
    children,
    title,
    desc,
    enable,
    isDanger,
    onDone,
    onCancel,
    ...otherProps
  } = props;
  const [isShow, show] = useState(false);
  const [temp, setTemp] = useState<any>();
  const t = useTranslate();

  return (
    <>
      {cloneElement(children, {
        ...otherProps,
        onChange: (e: any, m: any, s: any) => {
          if (enable) {
            show(true);
            setTemp({ e, m, s });
          } else {
            props.onChange(e, m, s);
            onDone && onDone(e, m, s);
          }
        },
      })}

      <Modal
        title=""
        visible={isShow}
        onOk={() => {
          const [e, m, s] = Object.values(temp);
          show(false);
          props.onChange(e, m, s);
          onDone && onDone(e, m, s);
        }}
        onCancel={() => {
          onCancel && onCancel();
          show(false);
        }}
        okText={t("buttons.confirm")}
        okButtonProps={{ danger: isDanger }}
        cancelText={t("buttons.reject")}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <Space direction="vertical">
            <Text style={{ fontWeight: 500, fontSize: 16, marginBottom: 0 }}>
              {title || t("common.areYouSure")}
            </Text>
            <Text style={{ fontSize: 14 }}>{desc}</Text>
          </Space>
        </Space>
      </Modal>
    </>
  );
};

export default ModalConfirm;
