import {
  Button,
  Col,
  DatePicker,
  Form,
  Icons,
  Input,
  Row,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { FC, memo, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { ContractContext, IForm } from "../../..";
import { isEmpty, roundToHundreds } from "utils/commons";
import ButtonConfirm from "components/ButtonConfirm";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import { DATE_FORMAT } from "configs/constants";

const { TextArea } = Input;
const { Title } = Typography;
const { useWatch, useFormInstance } = Form;
const PaymentProgress: FC = memo(() => {
  const { initialValue, isEdit } = useContext(ContractContext);
  const form = useFormInstance<IForm>();
  const [list, setList] = useState<string[]>([]);
  const translate = useTranslate();
  const contractProducts = useWatch("contractProducts", form);
  const contractPaymentProcesses = useWatch("contractPaymentProcesses", form);

  const sumAfterTax = useMemo(
    () =>
      Object.values(contractProducts || {})?.reduce((sum, next) => {
        const { unitPrice = 0, quantity = 0, taxPercent = 0 } = next || {};
        const priceBeforeTax = unitPrice && quantity ? unitPrice * quantity : 0;
        const tax =
          priceBeforeTax && taxPercent
            ? (priceBeforeTax * taxPercent) / 100
            : 0;
        const priceAfterTax = priceBeforeTax + tax;
        return roundToHundreds(sum + priceAfterTax);
      }, 0),
    [contractProducts]
  );

  const sumPaymentValue = useMemo(
    () =>
      Object.values(contractPaymentProcesses || {}).reduce((sum, next) => {
        if (!isEmpty(next.paymentValue)) return sum + Number(next.paymentValue);
        return sum;
      }, 0),
    [contractPaymentProcesses]
  );

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  useEffect(() => {
    form.setFields([
      {
        name: "validateTotalPaymentValue",
        errors:
          isEmpty(contractProducts) ||
          isEmpty(contractPaymentProcesses) ||
          sumPaymentValue === 0 ||
          sumPaymentValue === sumAfterTax
            ? undefined
            : [translate("errors.ER0177")],
      },
    ]);
  }, [
    sumPaymentValue,
    sumAfterTax,
    contractPaymentProcesses,
    contractProducts,
  ]);

  useEffect(() => {
    if (isEdit && initialValue?.contractPaymentProcesses?.length) {
      const ids: string[] = [];
      const contractPaymentProcessesData: any = {};
      initialValue?.contractPaymentProcesses?.forEach((pp) => {
        ids.push(pp.id);
        contractPaymentProcessesData[pp.id] = {
          ...pp,
        };
      });

      setList(ids);
      setTimeout(
        () =>
          form.setFieldsValue({
            contractPaymentProcesses: contractPaymentProcessesData,
          }),
        0
      );
    }
  }, [isEdit, initialValue]);

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Row style={{ marginBottom: 8 }}>
        <Col flex={1}>
          <Title level={5}>Tiến độ thanh toán</Title>
        </Col>
        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setList([...list, uuid()]);
            }}
            icon={<Icons.PlusOutlined />}
          >
            {translate("actions.create")}
          </Button>
        </div>
      </Row>
      <Table<String>
        size="small"
        className="vertical-align-top"
        dataSource={list}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 1000,
        }}
        locale={{
          emptyText: " ",
        }}
        scroll={{ x: 800 }}
      >
        <Table.Column
          title={translate("Đợt")}
          dataIndex="term"
          width={100}
          render={(v, keyRow: string, index) => {
            return (
              <>
                <Form.Item
                  hidden
                  name={["contractPaymentProcesses", keyRow, "id"]}
                />
                <Form.Item
                  className="m-0"
                  name={["contractPaymentProcesses", keyRow, "term"]}
                  rules={[
                    {
                      max: 200,
                      message: translate("errors.ER014", { max: 200 }),
                    },
                  ]}
                >
                  <Input
                    className="w-full"
                    placeholder={translate("Nhập giá trị")}
                  />
                </Form.Item>
              </>
            );
          }}
        />
        <Table.Column
          title={translate("Ngày thanh toán dự kiến")}
          dataIndex="estimatedPaymentDate"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={[
                  "contractPaymentProcesses",
                  keyRow,
                  "estimatedPaymentDate",
                ]}
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                className="m-0"
              >
                <DatePicker
                  className="w-full"
                  format={DATE_FORMAT}
                  placeholder={DATE_FORMAT}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          title={translate("Giá trị thanh toán")}
          dataIndex="paymentValue"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <InputMoney
                name={["contractPaymentProcesses", keyRow, "paymentValue"]}
                isLimit
                isRequired={false}
                className="m-0"
                allowDecimal
                placeholder={translate("Nhập giá trị")}
              />
            );
          }}
        />
        <Table.Column
          title={translate("Ghi chú")}
          dataIndex="note"
          width={200}
          render={(v, keyRow: string, index) => {
            return (
              <Form.Item
                name={["contractPaymentProcesses", keyRow, "note"]}
                className="m-0"
                rules={[
                  {
                    max: 3000,
                    message: translate("errors.ER014", { max: 3000 }),
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 4 }}
                  placeholder={translate("Nhập giá trị")}
                />
              </Form.Item>
            );
          }}
        />
        <Table.Column
          align="center"
          width={90}
          fixed="right"
          title={translate("table.actions")}
          render={(v, record, index) => {
            return (
              <ButtonConfirm
                text={translate("Bạn muốn xóa dòng thông tin này?")}
                description=""
                onClick={onRemoveItem(index)}
                type="text"
                danger
              >
                <Icons.DeleteOutlined />
              </ButtonConfirm>
            );
          }}
        />
      </Table>
      <Form.Item
        name="validateTotalPaymentValue"
        className="m-0"
        style={{ margin: 0, minHeight: 0 }}
        rules={[
          {
            validator: () => {
              if (
                isEmpty(contractProducts) ||
                isEmpty(contractPaymentProcesses) ||
                sumPaymentValue === 0 ||
                sumPaymentValue === sumAfterTax
              ) {
                return Promise.resolve();
              }
              return Promise.reject(translate("errors.ER0177"));
            },
          },
        ]}
      />
    </div>
  );
});

export default PaymentProgress;
