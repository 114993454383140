import React, { useState } from "react";
import {
  useTranslate,
  useNavigation,
  useRouterContext,
  BaseRecord,
} from "@pankod/refine-core";
import { RefineCrudEditProps } from "@pankod/refine-ui-types";
import {
  Edit,
  Modal,
  Typography,
  Button,
  Icons,
  Space,
  ButtonProps,
} from "@pankod/refine-antd";
import { MESSAGE_CONFIRM } from "../create";
import "./styles.less";

const { Text, Paragraph } = Typography;

type EditCustomProps = {
  record?: BaseRecord;
  titlePopupConfirm?: string;
  subtitlePopupConfirm?: React.ReactNode | string;
  editButtonText?: string;
  draftButtonProps?: ButtonProps;
  visibleShowModal?: boolean;
  isLoading: boolean;
  name?: string;
  cancelModalProps?: {
    title?: string;
    description?: React.ReactNode | string;
  };
  hiddenSaveButton?: boolean;
  setVisibleShowModal?: (isShow: boolean) => void;
  onSubmit?: () => void;
  onDraft?: () => void;
  onCancel?: () => void;
};

export const EditCustom: React.FC<RefineCrudEditProps & EditCustomProps> = (
  props
) => {
  const t = useTranslate();
  const { goBack, show } = useNavigation();
  const [isCancel, setCancel] = useState(false);
  const [isDraft, setDraft] = useState(false);

  const {
    // Defaul RefineCrudEditProps
    saveButtonProps,
    isLoading,
    children,
    resource,
    hiddenSaveButton = false,
    //EditCustomProps
    record,
    name,
    titlePopupConfirm,
    subtitlePopupConfirm,
    editButtonText,
    visibleShowModal,
    setVisibleShowModal,
    onSubmit,
    onCancel,
    onDraft,
    draftButtonProps,
    cancelModalProps,
  } = props;

  const id = record?.id || "";

  React.useEffect(() => {
    window.onbeforeunload = function () {
      return t(MESSAGE_CONFIRM);
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      <Edit
        {...props}
        breadcrumb={false}
        goBack={false}
        headerProps={{ className: "ant-sticky" }}
        headerButtons={
          <>
            <Button
              onClick={() => {
                setCancel(true);
              }}
              disabled={isLoading}
            >
              {t("buttons.cancel")}
            </Button>

            {onDraft && (
              <Button
                {...draftButtonProps}
                disabled={isLoading}
                onClick={async () => {
                  onDraft();
                }}
              >
                {t("buttons.draft")}
              </Button>
            )}

            {!hiddenSaveButton && (
              <Button
                icon={<Icons.SaveOutlined />}
                type="primary"
                // disable setting on saveButtonProps
                {...saveButtonProps}
              >
                {editButtonText || t("common.saveEdit")}
              </Button>
            )}
          </>
        }
        footerButtons={<></>}
      >
        {children}
      </Edit>
      <Modal
        title=""
        visible={visibleShowModal}
        onOk={() => {
          onSubmit && onSubmit();
        }}
        onCancel={() => {
          setVisibleShowModal && setVisibleShowModal(false);
        }}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
        cancelButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Paragraph
              style={{
                fontWeight: 500,
                fontSize: 16,
                paddingTop: 5,
                marginBottom: 0,
              }}
            >
              {titlePopupConfirm || t("common.editConfirm")}
            </Paragraph>
            {!!subtitlePopupConfirm && (
              <Paragraph style={{ fontSize: 14 }}>
                {subtitlePopupConfirm}
              </Paragraph>
            )}
          </div>
        </Space>
      </Modal>

      <Modal
        title=""
        closable={!isLoading}
        visible={isCancel}
        onOk={() => {
          if (onCancel) {
            onCancel();
          } else {
            props ? show(resource || name || "", id) : goBack();
          }
        }}
        onCancel={() => {
          setCancel(false);
        }}
        okButtonProps={{
          danger: true,
        }}
        okText={t("buttons.confirm")}
        cancelText={t("buttons.reject")}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Paragraph
              style={{
                fontWeight: 500,
                fontSize: 16,
                paddingTop: 5,
                marginBottom: 0,
              }}
            >
              {cancelModalProps?.title || t("common.cancelConfirm")}
            </Paragraph>
            {cancelModalProps?.description && (
              <Paragraph style={{ fontSize: 14 }}>
                {cancelModalProps?.description ||
                  t("common.cancelConfirmDescription")}
              </Paragraph>
            )}
          </div>
        </Space>
      </Modal>
    </>
  );
};
