import { Form, FormInstance, FormProps } from "@pankod/refine-antd";
import { FC, memo, useEffect, useState } from "react";
import QuotationTable from "./ProductTable";
import General from "./General";
import CustomerInfo from "./Customer";
import QuotCreator from "../../../common/Creator";
import Policy from "./Policy";
import { v4 as uuid } from "uuid";
import Commercial from "./Commercial";
import { getProductsForQuotation } from "api";
import { API_PATH } from "configs/path";
import IQuotation from "interfaces/Quotation";
import { useGetIdentity } from "@pankod/refine-core";
import { GlobalState } from "interfaces";

interface Props {
  form: FormInstance<{}>;
  formProps: FormProps;
  isEdit?: boolean;
  onFinishedFormValidate?: () => void;
}

const { useWatch } = Form;

const FormQuotations: FC<Props> = memo((props) => {
  const { form, formProps, isEdit, onFinishedFormValidate } = props;
  const [listIdProduct, setList] = useState([uuid()]);
  const initialValues: Partial<IQuotation> = formProps?.initialValues || {};
  const quotationProductsForm = useWatch("quotationProducts", form);

  const { data: user } = useGetIdentity<GlobalState>();

  const handleChangeBusinessPlan = async (option: any) => {
    try {
      const ids: string[] = [];
      const quotationProducts: any = {};
      const resProduct = await getProductsForQuotation(
        `${API_PATH.quotationsProductsDefault}/${option?.value}`
      );
      const products = resProduct?.data;

      products?.forEach((product: any) => {
        const id = uuid();
        ids.push(id);
        quotationProducts[id] = {
          description: product?.description,
          partNumber: product?.partNumber,
          productId: product?.product?.id,
          quantity: product?.quantity,
          unitId: product?.unit?.id,
          unitPrice: product?.unitPrice,
        };
      });
      if (ids.length) {
        setList(ids);
      }

      setTimeout(
        () =>
          form.setFieldsValue({
            quotationProducts: ids.length
              ? quotationProducts
              : quotationProductsForm,
            caseId: option?.parentId,
          }),
        0
      );
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    if (isEdit) {
      const ids: string[] = [];
      const quotationProducts: any = {};
      initialValues?.quotationProducts?.forEach((product) => {
        ids.push(product.id);
        quotationProducts[product.id] = {
          description: product?.description,
          note: product?.note,
          partNumber: product?.partNumber,
          productId: product?.productId,
          quantity: product?.quantity,
          taxPercent: product?.taxPercent * 100,
          unitId: product?.unitId,
          unitPrice: product?.unitPrice,
        };
      });
      const empty = uuid();
      const isHasDefaultProduct = !!ids.length;

      setList(isHasDefaultProduct ? ids : [empty]);
      setTimeout(
        () =>
          form.setFieldsValue({
            quotationProducts: isHasDefaultProduct ? quotationProducts : {},
          }),
        0
      );
    }
  }, [isEdit, initialValues]);

  return (
    <Form
      {...formProps}
      initialValues={{
        ...initialValues,
        employeeEmail: initialValues?.commercialEmployee?.emailUser,
        employeePhone: initialValues?.commercialEmployee?.phoneNumber,
      }}
      layout="vertical"
      onFinish={onFinishedFormValidate}
      style={{ display: "flex", flexDirection: "column", gap: 8 }}
    >
      <General
        handleChangeBusinessPlan={handleChangeBusinessPlan}
        isEdit={isEdit}
      />
      <CustomerInfo />
      <QuotCreator
        creater={{
          fullName: user?.employee.employee.fullName,
          code: user?.employee.employee.code,
          phoneNumber: user?.employee.employee.phoneNumber,
        }}
      />
      <QuotationTable list={listIdProduct} setList={setList} />
      <Policy />
      <Commercial />
    </Form>
  );
});

export default FormQuotations;
