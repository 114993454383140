import { Table, Tag } from "@pankod/refine-antd";
import { useTable, useTranslate } from "@pankod/refine-core";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { API_PATH } from "configs/path";
import { FC, memo } from "react";
import styled from "styled-components";

interface PropsTodoList {
    id: string;
    featureId: string;
}

const DescriptionStyle = styled.div`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.85);
`;

const TodoList: FC<PropsTodoList> = memo((props: PropsTodoList) => {
    const t = useTranslate();
    const filterFeatureId = props.featureId ? `featureId=${props.featureId}` : "";
    const getColorStatus = (id: number) => {
        switch (id) {
            case 1:
            case 3:
                return "blue";
            case 0:
                return "yellow";
            case 2:
                return "green";
            case 4:
                return "red";
            case 5:
                return "magenta";
            default:
                return "default";
        }
    }
    const {
        tableQueryResult: { data },
        current,
        setCurrent,
    } = useTable({
        resource: API_PATH.todoList,
        metaData: {
            after: `?MyWorkStatusId=${props.id}&${filterFeatureId}`,
        }
    })

    return (
        <Table
            onHeaderRow={undefined}
            bordered={false}
            showHeader={false}
            dataSource={data?.data}
            pagination={
                {
                    hideOnSinglePage: true,
                    current: current,
                    onChange: (page, pageSize) => {
                        setCurrent(page);
                    },
                    total: data?.total,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    showSizeChanger: false,
                }
            }
        >
            <Table.Column dataIndex="name" key="name" onHeaderCell={undefined} render={(v, record: any, _) => {
                return <a href={`../../../../${record.approvalUrl}`} target="_blank" rel="noopener noreferrer" >
                    <div className="primary" >{record.code} {v?.length > 50 ? `${v.substring(0,50)}...`: `${v ?? ""}`}</div>
                    <DescriptionStyle>{record.description}</DescriptionStyle>
                    <div style={{ fontWeight: 300, fontSize: 12, color: "gray" }}>
                        Được tạo bởi {record.submitInfo.submitterName}, <DateTimeFieldCustom style={{color: "gray"}} value={record.submitInfo.submitTime}  />
                    </div>
                </a>
            }} />
            <Table.Column dataIndex="age" key="age" onHeaderCell={undefined} render={(v, record: any, index) => {
                return <div style={{ flexDirection: "row" }}>
                    <div>
                        <Tag color={getColorStatus(record.statusCode)} style={{ margin: "2px" }}>{record.statusName}</Tag>
                    </div>
                    <div>
                        <Tag style={{ backgroundColor: "#f5f5f5", color: "black" }} >{t(`common.${record.featureCode}`)}</Tag>
                    </div>
                </div>
            }} />
        </Table>

    );
});

export default TodoList;  