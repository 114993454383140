import { Col, Form, Row, Typography } from "@pankod/refine-antd";
import { useOne, useTranslate } from "@pankod/refine-core";
import { useSearchParams } from "@pankod/refine-react-router-v6";
import { SelectCustom } from "components";
import CCollapse, { CaseInfoPanel } from "components/CCollapse";
import { API_PATH } from "configs/path";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import { FC, memo, useContext, useState } from "react";
import { CreateSettlementsContractContext } from "..";

interface Props {
  onChangeCase: (value: string) => void;
}

const { Title } = Typography;

const CaseInfo: FC<Props> = memo((props) => {
  const { caseId } = useContext(CreateSettlementsContractContext)
  const [selectedCaseId, setSelectedCaseId] = useState<string>("");
  function onChangeCaseId(id: string) {
    setSelectedCaseId(id);
  }
  const translate = useTranslate();
  const { data: caseData, isFetching } = useOne<ICaseInfo>({
    resource: API_PATH.caseInfo(selectedCaseId),
    id: "",
    queryOptions: { enabled: !!selectedCaseId },
  });

  const caseDetail = caseData?.data;
  const params = useSearchParams();

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Thông tin vụ việc")}</Title>
      <Row gutter={16}>
        <Col lg={12} xs={24}>
          <Form.Item
            name="caseId"
            label="Vụ việc"
            initialValue={caseId ?? params[0].get("caseId")}
            rules={[{ required: true, message: translate("errors.ER005") }]}
            className={selectedCaseId ? "" : "m-0"}
          >
            <SelectCustom
              resource={API_PATH.settlementContractsCaseDropdownlist}
              optionLabel="name"
              optionValue="id"
              placeholder={translate("Chọn vụ việc")}
              style={{ color: "#000" }}
              allowClear
              onChangeItem={onChangeCaseId}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item hidden={!selectedCaseId} className="m-0">
            <CCollapse
              loading={isFetching}
              hidden={!selectedCaseId}
              title={translate("Thông tin vụ việc")}
            >
              <CaseInfoPanel caseInfo={caseDetail} />
            </CCollapse>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
});

export default CaseInfo;
