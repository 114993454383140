import { API_PATH, PATH } from "./path";

const version = 2;

const EndPoints = {
  login: () => `core/Users/login`,
  externalLogin: () => `core/Users/login/azure-ad`,
  logout: () => `core/Users/logout`,
  me: () => `/v${version}/Account/me`,
  createUser: () => `core/Users/create-user`,
  forgotPassword: () => `/core/users/forgot-password`,
  changePassword: () => `core/Users/change-password`,
  resetPassword: () => `core/Users/reset-password`,
  initByExternalAccount: () => "core/Users/active-user/azure-ad",
  list: (model: string) => `${model}`,
  detail: (model: string, id?: string) => `${model}${id ? `/${id}` : ""}`,
  update: (model: string, id?: string) => `${model}${id ? `/${id}` : ""}`,
  create: (model: string) => `${model}`,
  delete: (model: string, id?: string) => `${model}${id ? `/${id}` : ""}`,
  users: (model: string) => `core/Users/${model}`,
  tenants: (model: string) => `core/Tenants/${model}`,
  getLocationList: (model: string) => `core/${model}/dropdownlist`,
  getUserTenantMisRoles: (id: string) =>
    `api/userTenantMisRoles/get-my-mis-role/${id}`,
  getTenantPermission: () =>
    `api/Features/get-my-granted-features-with-permission`,
  getEmployeeUser: "api/Employees/me",
  exportPDFQuotation: (id: string) => `${API_PATH.quotationsApi}/${id}/export`,
};

export default EndPoints;
