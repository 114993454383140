import { Descriptions } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { DataText } from "components";
import { FC, memo, useContext } from "react";
import { ProjectShowContext } from "../..";

const ContactInfo: FC = memo(() => {
  const translate = useTranslate();
  const record = useContext(ProjectShowContext);
  const { contactCustomerEmail, contactCustomerName, contactCustomerPhone } =
    record || {};
  return (
    <Descriptions
      column={3}
      title={translate("Thông tin liên hệ")}
      layout="vertical"
      style={{ background: "white", padding: 24 }}
    >
      <Descriptions.Item
        label={translate("Tên khách hàng liên hệ")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={contactCustomerName} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Số điện thoại")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={contactCustomerPhone} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Email")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={contactCustomerEmail} />
      </Descriptions.Item>
    </Descriptions>
  );
});

export default ContactInfo;
