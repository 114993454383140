import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { Typography } from "antd";
import { PATH } from "configs/path";
import { FC, createContext, memo, useMemo, useState } from "react";
import { ApprovalServiceRequest } from "components/ApprovalServiceRequest";
import IInternalEstimate, {
  EstimateObjectData,
  TabKey,
} from "interfaces/InternalEstimate";
import styled from "styled-components";
import Information from "./components/Information";
import EstimateInfo from "./components/EstimateInfo";
import { Button } from "@pankod/refine-antd";
import { ApprovalStatus } from "api/enums";
import ModalCloneInternalEstimate from "../list/components/ModalClone";
import { ButtonTooltip } from "components";
import usePermissions from "hooks/permission";

interface Props {
  isApprover?: boolean;
}

const { Text } = Typography;

const InfomationStyle = styled.div<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  gap: 8px;
  min-height: 400px;
`;

const InternalEstimatesShow: React.FC<IResourceComponentsProps & Props> = (
  props
) => {
  const translate = useTranslate();

  const { checkEditHaveHelperText } = usePermissions();

  const [visibleModalClone, setVisibleModalClone] = useState(false);

  const toggleModalClone = () => setVisibleModalClone((prev) => !prev);

  const headerTabs = useMemo(
    () => [
      {
        name: translate("Thông tin tổng quan"),
        key: TabKey.INFO,
      },
      {
        name: translate("Chi tiết dự toán nội bộ"),
        key: TabKey.ESTIMATE_INFO,
      },
      {
        name: translate("approval.history"),
        key: TabKey.HISTORY,
        isActive: false,
        hidden: false,
      },
    ],
    []
  );

  return (
    <>
      <ApprovalServiceRequest
        {...props}
        headerTabs={headerTabs}
        defaultTab={TabKey.INFO}
        resource={PATH.internalEstimates}
        renderInformation={(record: IInternalEstimate, tab: TabKey) => (
          <InternalEstimateDetail record={record?.objectData || {}} tab={tab} />
        )}
        onApprovalSuccess={() => {}}
        requestName={translate("Phê duyệt DTNB")}
        title={(record: IInternalEstimate) => (
          <>
            {translate("Dự toán nội bộ")}:{" "}
            <Text className="primary">{record?.objectData?.case?.name}</Text>
          </>
        )}
        headerButton={(record: IInternalEstimate) => (
          <>
            <ButtonTooltip
              hidden={record?.objectData?.status != ApprovalStatus.Approved}
              type="primary"
              onClick={toggleModalClone}
              {...checkEditHaveHelperText(PATH.internalEstimates)}
            >
              {translate("Thay đổi DTNB")}
            </ButtonTooltip>
          </>
        )}
        elements={(record: IInternalEstimate) => (
          <ModalCloneInternalEstimate
            toggleModal={toggleModalClone}
            visibleModal={visibleModalClone}
            record={record?.objectData}
          />
        )}
      />
    </>
  );
};

export default InternalEstimatesShow;

interface DetailProps {
  record?: any;
  tab: TabKey;
}

interface State {
  record: Partial<EstimateObjectData>;
}

export const InternalEstimateShowContext = createContext<State>({ record: {} });

export const InternalEstimateDetail: FC<DetailProps> = memo(
  ({ record, tab }) => {
    return (
      <InternalEstimateShowContext.Provider value={{ record }}>
        <InfomationStyle hidden={tab !== TabKey.INFO}>
          <Information />
        </InfomationStyle>
        <InfomationStyle hidden={tab !== TabKey.ESTIMATE_INFO}>
          <EstimateInfo />
        </InfomationStyle>
      </InternalEstimateShowContext.Provider>
    );
  }
);
