import React, { useState } from "react";
import {
  Button,
  Card,
  Descriptions,
  Icons,
  Typography,
  Tag,
  Modal,
  Space,
  Row,
  Col,
  notification,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useShow,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { ShowCustom } from "components/layout";
import { PATH } from "configs/path";
import { WorkflowDiagramShow } from "pages/workflowDiagrams";
import { useNavigate } from "react-router-dom";
import { ApprovalProcedureStatus, WorkflowStatus } from "api/enums";
import { formatISODateTimeToView } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
import usePermissions from "hooks/permission";
import { EmptyData, DataText, ButtonTooltip } from "components";
import { showErrorToast } from "api/common";
import { AttachmentsView } from "components/AttachmentsView";

const { Title, Text, Paragraph } = Typography;
const enum TAB {
  INFORMATION = "INFORMATION",
  WORK_FLOW = "WORK_FLOW",
}

export const WorkflowShow: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { edit } = useNavigation();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>();
  const { queryResult, showId } = useShow<any>({});
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const { checkEdit, checkView, checkCreate } = usePermissions();
  const [activeTab, setAvticeTab] = useState(TAB.INFORMATION);

  const [selectedStatus, setSelectedStatus] = React.useState<WorkflowStatus>();
  const [visibleConfirmUpdateStatusModal, setVisibleConfirmUpdateStatusModal] =
    React.useState<boolean>();

  const renderStatus = (value: number) => {
    switch (value) {
      case ApprovalProcedureStatus.Published:
        return (
          <Tag color="success">{t("workflows.fields.status.published")}</Tag>
        );

      case ApprovalProcedureStatus.Noset:
        return <Tag color="red">{t("workflows.fields.status.noset")}</Tag>;

      case ApprovalProcedureStatus.Draft:
        return <Tag color="warning">{t("workflows.fields.status.draft")}</Tag>;

      default:
        return (
          <Tag color="#F0F0F0" style={{ color: "#8C8C8C" }}>
            {t("workflows.fields.status.unpublished")}
          </Tag>
        );
    }
  };

  const onConfirmUpdateStatus = (type: WorkflowStatus) => {
    setSelectedStatus(type);
    setVisibleConfirmUpdateStatusModal(true);
  };

  const onUpdateStatus = () => {
    if (selectedStatus) {
      mutateUpdate(
        {
          id: record?.id,
          resource: PATH.workflows,
          metaData: {
            type:
              selectedStatus == WorkflowStatus.Published
                ? "/publish"
                : "/un-publish",
          },
          values: {},
        },
        {
          onSuccess: (res: any) => {
            notification.success({
              message:
                selectedStatus == WorkflowStatus.Published
                  ? t("workflows.messages.publishSuccess")
                  : t("workflows.messages.unPublishSuccess"),
            });
            setVisibleConfirmUpdateStatusModal(false);
          },
          onError: (error: any) => {
            setVisibleConfirmUpdateStatusModal(false);
            showErrorToast(error);
          },
        }
      );
    }
  };

  const canNotEdit =
    record?.status === ApprovalProcedureStatus.Published ||
    !checkEdit(PATH.workflows);

  const onChangeTab = (newTab: TAB) => setAvticeTab(newTab);

  const renderContent = () => {
    switch (activeTab) {
      case TAB.INFORMATION:
        return (
          <Descriptions
            column={3}
            title={t("workflows.workflowInfo")}
            layout="vertical"
            extra={
              <ButtonTooltip
                type="primary"
                icon={<Icons.EditOutlined />}
                onClick={() => edit(PATH.workflows, record?.id)}
                disabled={canNotEdit}
                helpertext={canNotEdit ? "common.noHasPermission" : undefined}
              >
                {t("buttons.edit")}
              </ButtonTooltip>
            }
          >
            <Descriptions.Item label={t("workflows.fields.name")}>
              <DataText value={record?.name} />
            </Descriptions.Item>
            <Descriptions.Item label={t("workflows.fields.code")}>
              <DataText value={record?.code} />
            </Descriptions.Item>
            <Descriptions.Item label={t("workflows.fields.feature")}>
              <DataText value={record?.feature?.featureName} />
            </Descriptions.Item>
            <Descriptions.Item label={t("workflows.fields.status.title")}>
              {renderStatus(record?.status)}
            </Descriptions.Item>
            <Descriptions.Item label={t("workflows.fields.appliedFromDate")}>
              <DataText
                value={formatISODateTimeToView(
                  record?.appliedFromDate,
                  DATE_FORMAT
                )}
              />
            </Descriptions.Item>
            <Descriptions.Item label={t("workflows.fields.appliedToDate")}>
              <DataText
                value={formatISODateTimeToView(
                  record?.appliedToDate,
                  DATE_FORMAT
                )}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={t("workflows.fields.description")}
              span={3}
            >
              <DataText value={record?.description} />
            </Descriptions.Item>
            <Descriptions.Item span={3}>
              <AttachmentsView
                attachments={record?.attachments}
                title={t("common.fields.attachments")}
              />
            </Descriptions.Item>
          </Descriptions>
        );
      case TAB.WORK_FLOW:
        return (
          <Card style={{ padding: 0 }}>
            {!checkView(PATH.workflowsDiagrams) && (
              <div style={{ padding: 24 }}>
                <Title level={4} style={{ marginBottom: 0, fontWeight: 500 }}>
                  {t("workflows.workflowDetail")}
                </Title>
                <EmptyData
                  text={t("common.noPermission")}
                  style={{ paddingTop: 24, paddingBottom: 24 }}
                />
              </div>
            )}
            {checkView(PATH.workflowsDiagrams) && (
              <WorkflowDiagramShow workflowId={showId} workflow={record} />
            )}
          </Card>
        );
    }
  };

  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        title={
          <Text style={{ marginBottom: 0 }}>
            {t("workflows.titles.show")}:{" "}
            <Text className="primary">{record?.code}</Text>
          </Text>
        }
        breadcrumbText={record?.code}
        tabs={[
          {
            name: "Thông tin quy trình",
            isActive: activeTab === TAB.INFORMATION,
            key: TAB.INFORMATION,
          },
          {
            name: "Thiết lập sơ đồ",
            isActive: activeTab === TAB.WORK_FLOW,
            key: TAB.WORK_FLOW,
          },
        ]}
        onChangeTab={onChangeTab}
        headerButtons={
          <>
            {record?.status == WorkflowStatus.Unpublished && (
              <Button
                onClick={() => onConfirmUpdateStatus(WorkflowStatus.Published)}
                type="primary"
                icon={<Icons.CheckCircleOutlined />}
                disabled={
                  !checkCreate(PATH.workflowsDiagrams) &&
                  !checkEdit(PATH.workflowsDiagrams)
                }
              >
                {t("workflows.buttons.publish")}
              </Button>
            )}
            {record?.status == WorkflowStatus.Published && (
              <Button
                onClick={() =>
                  onConfirmUpdateStatus(WorkflowStatus.Unpublished)
                }
                type="primary"
                danger
                icon={<Icons.CloseCircleOutlined />}
                disabled={!checkEdit(PATH.workflowsDiagrams)}
              >
                {t("workflows.buttons.unpublish")}
              </Button>
            )}
          </>
        }
      >
        {renderContent()}
      </ShowCustom>
      <Modal
        title=""
        visible={visibleConfirmUpdateStatusModal}
        onOk={onUpdateStatus}
        onCancel={() => setVisibleConfirmUpdateStatusModal(false)}
        okText={t("buttons.confirm")}
        okButtonProps={{
          danger: selectedStatus === WorkflowStatus.Unpublished,
        }}
        cancelText={t("buttons.reject")}
      >
        <Space align="start">
          <Text style={{ fontSize: 22, marginRight: 10 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <Row>
            <Col>
              <Text style={{ fontWeight: 500, fontSize: 15 }}>
                {selectedStatus && selectedStatus === WorkflowStatus.Published
                  ? t("workflows.messages.publishConfirm")
                  : t("workflows.messages.unPublishConfirm")}
              </Text>
            </Col>
          </Row>
        </Space>
      </Modal>
    </>
  );
};
