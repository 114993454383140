import { Card, Descriptions } from "antd";
import { FC, memo, useContext, useMemo } from "react";
import { InternalEstimateShowContext } from "../../..";
import { useOne, useTranslate } from "@pankod/refine-core";
import { DataText } from "components";
import { formatISODateTimeToView } from "utils/commons";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "configs/constants";
import CCollapse, { ConsultInfoPanel } from "components/CCollapse";
import { ServiceRequestDetail } from "interfaces/ServiceRequest";
import { API_PATH } from "configs/path";
import {
  Button,
  Col,
  Icons,
  Row,
  Table,
  notification,
} from "@pankod/refine-antd";
import type { ColumnsType } from "antd/es/table";
import { IAttachment } from "interfaces";
import { getLinkPrewviewFile } from "api";

const General: FC = memo(() => {
  const { record } = useContext(InternalEstimateShowContext);
  const translate = useTranslate();

  const serviceRequestData = useOne<ServiceRequestDetail>({
    id: record?.referenceRequestItem?.code!,
    resource: API_PATH.serviceRequestDetail,
    queryOptions: {
      enabled: !!record?.referenceRequestItem?.code,
    },
  });

  const request = serviceRequestData?.data?.data;
  const isLoadingRequest = serviceRequestData?.isFetching;

  const onGetLinkView = (fileUrl: string) => async () => {
    try {
      const res = await getLinkPrewviewFile(
        API_PATH.internalEstimatePreviewFile,
        fileUrl
      );
      const url: string = res?.data as unknown as string;
      window.open(url, "_blank");
    } catch (error) {
      notification.error({
        message: "Đã có lỗi xảy ra. Vui lòng liên hệ quản trị viên.",
      });
    }
  };

  const columns = useMemo<ColumnsType<IAttachment>>(
    () => [
      {
        title: translate("Tài liệu"),
        width: 200,
        dataIndex: "fileUrlOrigin",
        key: "fileUrlOrigin",
        render: (value, record) => {
          return (
            <Row gutter={16}>
              <Col flex={1}>
                <DataText value={value} />
              </Col>
              <Col>
                <Button
                  type="text"
                  icon={<Icons.EyeOutlined />}
                  className="primary"
                  onClick={onGetLinkView(record?.fileUrl!)}
                />
              </Col>
            </Row>
          );
        },
      },
    ],
    []
  );

  return (
    <Card style={{ background: "#ffffff" }}>
      <Descriptions
        column={{ xs: 1, lg: 3 }}
        title={translate("Thông tin tổng quan")}
        layout="vertical"
      >
        <Descriptions.Item
          label={translate("Mã DTNB")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.code} />
        </Descriptions.Item>

        <Descriptions.Item
          label={translate("Mã DTNB gốc")}
          labelStyle={{ fontWeight: 500 }}
          span={2}
        >
          <DataText
            className="primary"
            value={record?.originalInternalEstimated?.code || "-"}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Loại dự toán")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.estimateType?.name} />
        </Descriptions.Item>

        <Descriptions.Item
          label={translate("Mã yêu cầu")}
          labelStyle={{ fontWeight: 500 }}
          span={2}
        >
          <DataText
            value={
              record?.referenceRequestItem?.code
                ? `${record?.referenceRequestItem?.code} - ${record?.referenceRequestItem?.name}`
                : "-"
            }
          />
        </Descriptions.Item>
        <Descriptions.Item span={3}>
          <CCollapse
            title={translate("Chi tiết yêu cầu dịch vụ")}
            loading={isLoadingRequest}
            hidden={!record?.referenceRequestItem}
          >
            <ConsultInfoPanel requestDetail={request} />
          </CCollapse>
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Ngày bắt đầu")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText
            value={formatISODateTimeToView(record?.startDate, DATE_FORMAT)}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Ngày kết thúc")}
          labelStyle={{ fontWeight: 500 }}
          span={2}
        >
          <DataText
            value={formatISODateTimeToView(record?.endDate, DATE_FORMAT)}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Người tạo")}
          labelStyle={{ fontWeight: 500 }}
          span={1}
        >
          <DataText value={record?.createdName} />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("Thời gian tạo")}
          labelStyle={{ fontWeight: 500 }}
          span={2}
        >
          <DataText
            value={formatISODateTimeToView(
              record?.createdDate,
              DATE_TIME_FORMAT
            )}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={translate("common.fields.attachments")}
          labelStyle={{ fontWeight: 500 }}
          span={3}
        >
          <Table
            className="w-full"
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            rowKey="id"
            columns={columns}
            dataSource={record?.listInternalEstimatedViewDocument}
          />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
});

export default General;
