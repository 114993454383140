import { Collapse, Descriptions, Tag } from "@pankod/refine-antd";
import { FC, ReactNode, memo } from "react";
import "./styles.less";
import CSpin from "components/CSpin";
import { useTranslate } from "@pankod/refine-core";
import { DataText } from "components/DataText";
import { CaseInfo as ICaseInfo } from "interfaces/CaseId";
import { Currency } from "components/Currency";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "configs/constants";
import { formatISODateTimeToView } from "utils/commons";
import { LinkText } from "components/LinkText";
import { SLUGS } from "configs/path";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import { ServiceRequestDetail } from "interfaces/ServiceRequest";
import { IntegrationStatusTag } from "components/StatusTag/IntegrationStatusTag";

const { Panel } = Collapse;

interface CaseInfoPanelProps {
  caseInfo?: ICaseInfo;
}
export const CaseInfoPanel: FC<CaseInfoPanelProps> = memo((props) => {
  const translate = useTranslate();
  const { caseInfo } = props;
  return (
    <Descriptions
      style={{ paddingTop: 16 }}
      column={{ xs: 1, lg: 3 }}
      title={translate("")}
      layout="vertical"
    >
      <Descriptions.Item
        label={translate("cases.infoBox.status")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={caseInfo?.caseStatus?.name} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("cases.infoBox.createdTime")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText
          value={
            caseInfo?.createdDate &&
            formatISODateTimeToView(caseInfo?.createdDate, DATE_TIME_FORMAT)
          }
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("cases.infoBox.createdUser")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={caseInfo?.creator} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("cases.infoBox.customer")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={caseInfo?.customer} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("cases.infoBox.AM")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={caseInfo?.commercialEmployee} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("cases.infoBox.department")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={caseInfo?.department} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("cases.infoBox.market")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={caseInfo?.market} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("cases.infoBox.caseValue")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <Currency value={caseInfo?.estimateContractValue} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("cases.infoBox.integrateStatus")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <IntegrationStatusTag status={caseInfo?.syncStatus} />
      </Descriptions.Item>
    </Descriptions>
  );
});

interface ConsultInfoPanelProps {
  requestDetail?: ServiceRequestDetail;
}
export const ConsultInfoPanel: FC<ConsultInfoPanelProps> = memo((props) => {
  const { requestDetail } = props;
  return (
    <Descriptions
      style={{ background: "#f4f4f4", padding: 12 }}
      column={{ lg: 3, xs: 1 }}
      layout="vertical"
    >
      <Descriptions.Item
        span={1}
        labelStyle={{ fontWeight: 500 }}
        label={"Mã yêu cầu"}
      >
        <LinkText
          resource={SLUGS.serviceRequest}
          value={{
            id: requestDetail?.id,
          }}
          defaultValue={`${requestDetail?.code} - ${requestDetail?.name}`}
          showTooltip={false}
        />
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        labelStyle={{ fontWeight: 500 }}
        label={"Trạng thái"}
      >
        <ApprovalStatusLabel status={requestDetail?.status} />
      </Descriptions.Item>
      <Descriptions.Item
        span={1}
        labelStyle={{ fontWeight: 500 }}
        label={"Người yêu cầu"}
      >
        <DataText
          value={
            requestDetail?.ownerEmployee &&
            `${requestDetail?.ownerEmployee?.code} - ${requestDetail?.ownerEmployee?.fullName}`
          }
        />
      </Descriptions.Item>
      <Descriptions.Item
        span={1}
        labelStyle={{ fontWeight: 500 }}
        label={"Ngày yêu cầu"}
      >
        <DataText
          value={formatISODateTimeToView(
            requestDetail?.requestDate,
            DATE_FORMAT
          )}
        />
      </Descriptions.Item>
      <Descriptions.Item
        span={1}
        labelStyle={{ fontWeight: 500 }}
        label={"Ngày yêu cầu hoàn thành"}
      >
        <DataText
          value={formatISODateTimeToView(
            requestDetail?.requestCompletionDate,
            DATE_FORMAT
          )}
        />
      </Descriptions.Item>
      <Descriptions.Item
        span={3}
        labelStyle={{ fontWeight: 500 }}
        label={"Nội dung yêu cầu"}
      >
        <DataText value={requestDetail?.description}></DataText>
      </Descriptions.Item>
    </Descriptions>
  );
});

interface Props {
  hidden?: boolean;
  loading?: boolean;
  id?: string;
  children?: ReactNode;
  title?: string;
}
const CCollapse: FC<Props> = memo((props) => {
  const { hidden, loading, children = "", title = "Title" } = props;
  if (hidden) return null;
  return (
    <div className="relative">
      <CSpin isLoading={loading} />
      <Collapse defaultActiveKey={["1"]} className="mis-case-detail-collapse">
        <Panel header={title} key="1">
          {children}
        </Panel>
      </Collapse>
    </div>
  );
});

export default CCollapse;
