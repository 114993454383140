import { Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { CreateCustom } from "components/layout";
import { FC } from "react";
import BusinessOpportunitiesForm from "./components/Form";
import { useController } from "./controller";

const { Title } = Typography;

const BusinessOpportunitiesCreate: FC<IResourceComponentsProps> = (props) => {
  const {
    visibleModalConfirmCreate,
    isLoading,
    saveButtonProps,
    form,
    formProps,
    translate,
    onSubmit,
    toggleModalConfirm,
  } = useController();
  return (
    <CreateCustom
      {...props}
      title={translate("businessOpportunities.newBusinessOpportunities")}
      onSubmit={onSubmit}
      visibleShowModal={visibleModalConfirmCreate}
      setVisibleShowModal={toggleModalConfirm}
      isLoading={isLoading}
      // saving={isLoading}
      confirmModalProps={{
        title: translate(
          "businessOpportunities.wantToCreateThisNewBusinessOpportunity?"
        ),
      }}
      saveButtonProps={{
        ...saveButtonProps,
        title: translate(
          "businessOpportunities.createNewBusinessOpportunities"
        ),
      }}
    >
      <Title level={4}>{translate("common.titles.detail")}</Title>
      <BusinessOpportunitiesForm
        form={form}
        formProps={formProps}
        onFinishedFormValidate={() => toggleModalConfirm(true)}
      />
    </CreateCustom>
  );
};

export default BusinessOpportunitiesCreate;
