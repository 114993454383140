import React from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useShow, useNavigation, useUpdate
} from "@pankod/refine-core";
import { Typography, Tag, Icons, Descriptions, Space, Button, Modal, notification } from "@pankod/refine-antd";
import { ShowCustom } from "components/layout";
import usePermissions from 'hooks/permission'
import { PATH } from "configs/path";
import { ConfigDisplayText, ButtonTooltip, DataText } from "components";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { showErrorToast } from "api/common";
import { ConfigStatusTag } from "components/StatusTag";


const { Paragraph, Text } = Typography;

export const BusinessTripItemShow: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { edit, show } = useNavigation()
  const { checkEdit, checkCreate, checkEditHaveHelperText } = usePermissions()
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useUpdate<any>()
  const [visibleShowModal, setVisibleShowModal] = React.useState<boolean>(false)
  const { queryResult } = useShow<any>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const onChangeStatus = () => {
    mutateUpdate(
      {
        id: record?.id,
        resource: PATH.configBusinessTripItems,
        values: {},
        metaData:
          record?.status
          ? {type: '/deactivate'}
          : {type: '/activate'}
      },
      {
        onSuccess: (res) => {
          setVisibleShowModal(false)
          notification.success({
            message: record?.status
              ? t('common.config.deactiveSuccess')
              : t('common.config.activeSuccess'),
          })
        },
        onError: (error: any) => {
          setVisibleShowModal(false)
          showErrorToast(error)
        },
      },
    )
  }

  return (
    <>
      <ShowCustom
        {...props}
        isLoading={isLoading}
        title={<Text style={{marginBottom: 0}}>{t("businessTripItems.titles.show")}: <span className="primary">{record?.name}</span> </Text>}
        breadcrumbText={record?.name}
        headerButtons={
          <Space align="end">
            <ButtonTooltip
              icon={<Icons.EditOutlined/>}
              onClick={() => edit(PATH.configBusinessTripItems, record.id)}
              {...checkEditHaveHelperText(PATH.configBusinessTripItems, !record?.status)}
            >
              {t('buttons.edit')}
            </ButtonTooltip>
            {
              !record?.status && <ButtonTooltip
                icon={<Icons.CheckCircleOutlined />}
                type="primary"
                onClick={() => {setVisibleShowModal(true)}}
                {...checkEditHaveHelperText(PATH.configBusinessTripItems)}
              >
                  {t('buttons.active')}
              </ButtonTooltip>
            }
            {
              record?.status && <ButtonTooltip
                icon={<Icons.CloseCircleOutlined/>}
                type="primary"
                danger
                onClick={() => {setVisibleShowModal(true)}}
                {...checkEditHaveHelperText(PATH.configBusinessTripItems)}
              >
                  {t('buttons.deactivate')}
              </ButtonTooltip>
            }
          </Space>
        }
      >
        <Descriptions
          column={3}
          title={t("common.titles.detail")}
          layout="vertical"
        >
            <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label={t("businessTripItems.fields.code")}
            >
                <DataText value={record?.code} />
            </Descriptions.Item>
            <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label={t("businessTripItems.fields.name")}
            >
                <DataText value={record?.name} />
            </Descriptions.Item>
            <Descriptions.Item label={t("common.config.status")}>
              <ConfigStatusTag status={record?.status} />
            </Descriptions.Item>
            <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label={t("businessTripItems.fields.cost")}
            >
              <ConfigDisplayText
                value={record?.cost?.name}
                status={record?.cost?.status}
              />
            </Descriptions.Item>
            <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label={t("businessTripItems.fields.unit")}
                span={2}
            >
              <ConfigDisplayText
                value={record?.unit?.name}
                status={record?.unit?.status}
              />
            </Descriptions.Item>
            <Descriptions.Item label={t("common.updatedDate")}><DateTimeFieldCustom value={record?.updatedDate} /></Descriptions.Item>
            <Descriptions.Item label={t("common.updatedUsername")} span={2}><DataText value={record?.updatedName}/></Descriptions.Item>
            <Descriptions.Item label={t("common.createdDate")}><DateTimeFieldCustom value={record?.createdDate} /></Descriptions.Item>
            <Descriptions.Item label={t("common.createdUsername")} span={2}><DataText value={record?.createdName}/></Descriptions.Item>
        </Descriptions>
      </ShowCustom>
      <Modal
        title=""
        visible={visibleShowModal}
        onOk={() => onChangeStatus()}
        onCancel={() => {
          setVisibleShowModal(false)
        }}
        okText={t('buttons.confirm')}
        cancelText={t('buttons.reject')}
        okButtonProps={{
          danger:record?.status,
          loading: isLoadingUpdate
        }}
        cancelButtonProps={{disabled: isLoadingUpdate}}
      >
        <Space align="start">
          <Text style={{fontSize: 22}} type="warning"><Icons.InfoCircleOutlined/></Text>
          <div>
            <Paragraph style={{fontWeight: 500, fontSize: 16, paddingTop: 5, marginBottom: 0}}>{
              record?.status
              ? t('businessTripItems.message.deactivateConfirm')
              : t('businessTripItems.message.activateConfirm')
            }</Paragraph>
            {record?.status && <Paragraph>{t('common.config.editConfirmDesc')}</Paragraph>}
          </div>
        </Space>
      </Modal>
    </>
  );
};
