import { Col, Collapse, Row, Table, Typography } from "@pankod/refine-antd";
import { FC, memo, useContext, useMemo, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import { Currency, generateCurrency } from "components/Currency";
import type { ColumnsType } from "antd/es/table";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import styled from "styled-components";
import { InternalEstimateFormContext } from "../../..";

const { Panel } = Collapse;
const { Text } = Typography;

export const TextStyle = styled(Text)<{ bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const TotalEstimate: FC = memo(() => {
  const translate = useTranslate();
  const [visibleCollapse, setVisibleCollapse] = useState(true);
  const { totalCostByMoney, totalCostByManday, totalCost } = useContext(
    InternalEstimateFormContext
  );

  const currency = useMemo(() => {
    return generateCurrency();
  }, []);

  const dataTable = useMemo(() => {
    return [
      { label: "Tổng chi phí bằng tiền", total: totalCostByMoney },
      { label: "Tổng chi phí nhân công", total: totalCostByManday },
      {
        label: "Tổng chi phí (Tổng giá vốn nội bộ)",
        total: totalCost,
      },
      { label: "Tổng giá bán nội bộ (=Tổng giá vốn nội bộ)", total: 0 },
    ];
  }, [totalCostByMoney, totalCostByManday, totalCost]);

  const columns = useMemo<ColumnsType<any>>(() => {
    return [
      {
        width: 350,
        title: translate("assignExploiting.customerCode"),
        dataIndex: "label",
        key: "label",
        render: (value, record, index) => (
          <TextStyle bold={index === 2}>{value}</TextStyle>
        ),
      },
      {
        title: translate("customer.customerName"),
        dataIndex: "total",
        key: "total",
        render: (value, record, index) => {
          if (index !== 3) {
            return <Currency value={value} showCurrency={false} />;
          }

          return (
            <InputMoney
              isRequired
              isLimit
              name="price"
              style={{ marginBottom: 0 }}
              className="m-0"
              placeholder={translate("Nhập giá trị")}
              rules={[
                {
                  validator: (_, value) => {
                    if (value < totalCost) {
                      return Promise.reject(translate("errors.ER0203"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            />
          );
        },
      },
    ];
  }, [translate, totalCost]);

  const toggleCollsap = () => setVisibleCollapse((prev) => !prev);

  return (
    <Row>
      <Col xs={24} xxl={12}>
        <Collapse
          defaultActiveKey={["1"]}
          ghost
          onChange={toggleCollsap}
          className="w-full"
          collapsible="header"
        >
          <Panel header={translate("Dự toán tổng")} key="1">
            <Table
              style={{ marginTop: 8 }}
              showHeader={false}
              pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
              rowKey="id"
              columns={columns}
              dataSource={dataTable}
              scroll={{ x: 550 }}
            />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
});

export default TotalEstimate;
