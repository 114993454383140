import React from "react";
import { useGetIdentity, useTranslate } from "@pankod/refine-core";
import {
  AntdLayout,
  Space,
  Menu,
  Button,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  Row,
  Col,
  Modal,
} from "@pankod/refine-antd";
import { useLogout } from "@pankod/refine-core";
import {
  rightMenuStyle,
  menuItemStyle,
  menuItemAccountStyle,
  avatarBoxStyles,
} from "./styles";
import { UserRoles } from "api/enums";
import { UpdatePassword, UpdatePasswordType } from "components/UpdatePassword";
import LangSelect from "../../LangSelect";

const { CaretDownOutlined } = Icons;
const { Text } = Typography;

export const Header: React.FC = () => {
  const { mutate: logout } = useLogout();
  const { data: user } = useGetIdentity();
  const translate = useTranslate();
  const [visibleLogoutModal, setVisibleLogoutModal] = React.useState(false);

  const [visibleChangePasswordModal, setVisibleChangePasswordModal] =
    React.useState<boolean>(false);

  const menuItems: any[] = [
    {
      className: "account-box",
      key: "0",
      show: true,
      label: (
        <>
          <Row>
            <Col span={6} style={avatarBoxStyles}>
              <Avatar
                src="/images/default-avatar.png"
                style={{ width: 40, height: 40 }}
              />
            </Col>
            <Col span={16}>
              {user?.role != UserRoles.User && (
                <Row>
                  <Col>
                    <Text>{user?.role}</Text>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Text style={menuItemAccountStyle}>{user?.email}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "1",
      show: true,
      label: (
        <Button style={menuItemStyle} block type="link" onClick={() => {}}>
          {translate("pages.logout.settings")}
        </Button>
      ),
    },
    {
      key: "2",
      show: user?.isSetupPassword,
      label: (
        <Button
          style={menuItemStyle}
          block
          type="link"
          onClick={() => {
            setVisibleChangePasswordModal(true);
          }}
        >
          {translate("pages.logout.changePassword")}
        </Button>
      ),
    },
    {
      key: "3",
      show: true,
      label: (
        <Button
          style={menuItemStyle}
          block
          type="link"
          onClick={() => setVisibleLogoutModal(true)}
        >
          {translate("pages.logout.logout")}
        </Button>
      ),
    },
  ];

  const menu = (
    <Menu
      style={rightMenuStyle}
      className="right-menu"
      items={menuItems.filter((t) => t.show === true)}
    />
  );

  const onLogout = () => {
    logout();
  };

  return (
    <>
      <AntdLayout.Header
        className="header"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0px 24px",
          height: "64px",
          backgroundColor: "#FFF",
          boxShadow: "0px 2px 8px -2px rgb(0 0 0 / 15%)",
        }}
      >
        <div style={{ flex: 1 }} />
        <Space style={{ marginLeft: "8px" }}>
          <Avatar
            src="/images/default-avatar.png"
            style={{ width: 24, height: 24 }}
          />
          <Dropdown overlay={menu}>
            <Button type="text">
              <Space>
                <Text>{user?.fullName || translate("common.hello")}</Text>
                <CaretDownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
        <LangSelect />
      </AntdLayout.Header>

      <Modal
        title=""
        visible={visibleLogoutModal}
        onOk={() => onLogout()}
        onCancel={() => setVisibleLogoutModal(false)}
        okText={translate("pages.logout.okAction")}
        cancelText={translate("pages.logout.cancelAction")}
      >
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>
              {translate("pages.logout.logout")}
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text style={{ fontWeight: 500, fontSize: 16 }}>
              {translate("pages.logout.logoutConfirm")}
            </Text>
          </Col>
        </Row>
      </Modal>

      <Modal
        title=""
        visible={visibleChangePasswordModal}
        onOk={() => onLogout()}
        onCancel={() => setVisibleChangePasswordModal(false)}
        okText={translate("pages.changePassword.title")}
        footer={null}
      >
        <Row>
          <Col span={24}>
            <UpdatePassword updateType={UpdatePasswordType.CHANGE_PASSWORD} />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
