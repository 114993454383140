import { notification, useForm } from "@pankod/refine-antd";
import {
  useNavigation,
  useShow,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import { PATH } from "configs/path";
import { CustomerStatus } from "interfaces/Customer";
import { useEffect, useRef, useState } from "react";
import { mappingErrorFromApi } from "utils/commons";

interface FormInterface {
  name: string;
  abbreviationName: string;
  identifiedTaxCode: string;
  phoneNumber: string;
  address: string;
  provinceId: string;
  districtId: string;
  wardId: string;
  businessIndustryId: string;
  email: string;
  website: string;
  paymentterm: number;
  hasBillingInfo: boolean;
  nameInBillingInfo: string;
  taxCodeInBillingInfo: string;
  phoneNumberInBillingInfo: string;
  emailInBillingInfo: string;
  addressInBillingInfo: string;
  code: string;
  status: CustomerStatus;
  identifiedtaxcode: string;
}

export const useController = () => {
  const _mounted = useRef(false);
  const { show } = useNavigation();
  const translate = useTranslate();
  const { form, formProps, saveButtonProps } = useForm<any, any, FormInterface>(
    {}
  );
  const { queryResult } = useShow();

  const record = queryResult?.data?.data;

  const [visibleModalConfirmEdit, setVisibleModalConfirmEdit] = useState(false);

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleModalConfirmEdit(isOpen);

  const { mutate: mutateUpdate, isLoading } = useUpdate<any>();

  useEffect(() => {
    if (record?.id) {
      _mounted.current = true;
      form.setFieldsValue({
        ...record,
        paymentterm: record?.paymentTerm,
        identifiedtaxcode: record?.identifiedTaxCode,
      });
    }
  }, [record]);

  const onFishValidateform = () => toggleModalConfirm(true);

  const onSubmit = () => {
    const dataForm = form.getFieldsValue();
    form.setFields([{ name: "paymentterm", errors: undefined }]);
    form.setFields([{ name: "identifiedtaxcode", errors: undefined }]);

    const params: FormInterface = {
      ...dataForm,
      abbreviationName: dataForm?.abbreviationName?.trim(),
      name: dataForm?.name?.trim(),
      address: dataForm?.address?.trim(),
      nameInBillingInfo: dataForm?.nameInBillingInfo?.trim(),
      addressInBillingInfo: dataForm?.addressInBillingInfo?.trim(),
    };
    mutateUpdate(
      {
        resource: PATH.customers,
        values: params,
        id: record?.id!,
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: translate("customer.notifycation.updateSuccessful"),
          });
          show(PATH.customers, res?.data?.id);
        },
        onError: (error) => {
          toggleModalConfirm(false);

          notification.error({
            message: translate("customer.notifycation.updateFailed"),
            description:
              error?.errors.length &&
              translate(
                `errors.${error?.errors?.[0]?.errorCode}`,
                error?.errors?.[0]?.value
              ),
          });
          const errorFieldMapping: any = {};

          mappingErrorFromApi(error, form, errorFieldMapping);
        },
      }
    );
  };

  return {
    formProps,
    isLoading,
    saveButtonProps,
    visibleModalConfirmEdit,
    form,
    record,
    _mounted,
    onSubmit,
    toggleModalConfirm,
    translate,
    onFishValidateform,
  };
};
