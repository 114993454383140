import { useTranslate } from "@pankod/refine-core";
import { Descriptions, Form, Input, Typography } from "antd";
import { DataText } from "components";
import { memo } from "react";
import { Regs, uppercase } from "utils/commons";
import { IForm } from "../../..";

const { Title } = Typography;
const { useWatch, useFormInstance } = Form;

const CustomerInfo = memo(() => {
  const form = useFormInstance<IForm>();
  const translate = useTranslate();

  const customerName = useWatch("customerName", form);
  const customerAddress = useWatch("customerAddress", form);
  const customerIdentifiedTaxCode = useWatch("customerIdentifiedTaxCode", form);

  return (
    <div style={{ backgroundColor: "white", padding: 24 }}>
      <Title level={5}>{translate("Thông tin khách hàng")}</Title>
      <Descriptions layout="vertical" column={3}>
        <Descriptions.Item label="Khách hàng" span={1}>
          <Form.Item className="m-0" name="customerName">
            <DataText value={customerName} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item style={{ width: 24 }}>{""}</Descriptions.Item>
        <Descriptions.Item label="Địa chỉ" span={1}>
          <Form.Item className="m-0" name="customerAddress">
            <DataText value={customerAddress} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item span={1}>
          <Form.Item
            className="w-full m-0"
            label="Người đại diện"
            name="customerRepresentative"
            rules={[
              {
                max: 100,
                message: translate("errors.ER014", { max: 100 }),
              },
            ]}
          >
            <Input placeholder="Nhập thông tin người đại diện" />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item style={{ width: 24 }}>{""}</Descriptions.Item>
        <Descriptions.Item span={1}>
          <Form.Item
            className="w-full m-0"
            label="Chức vụ"
            name="customerPosition"
            rules={[
              {
                max: 100,
                message: translate("errors.ER014", { max: 100 }),
              },
            ]}
          >
            <Input placeholder="Nhập chức vụ" />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Mã số thuê" span={1}>
          <Form.Item className="m-0" name="customerIdentifiedTaxCode">
            <DataText value={customerIdentifiedTaxCode} />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
});

export default CustomerInfo;
