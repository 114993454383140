import { Collapse, Descriptions, Table } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { FC, memo, useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { SettlementShowContext } from "../../..";
import type { ColumnsType } from "antd/es/table";
import { SettlementLaborManday } from "interfaces/Settlement";
import { DataText } from "components";
import { isEmpty } from "utils/commons";
import { Currency } from "components/Currency";

interface RowData extends SettlementLaborManday {
  employee?: any;
  isChildren?: boolean;
  index?: number;
}

const { Panel } = Collapse;
const StyledWrapper = styled.div`
  margin-top: 24px;
`;

const CostByManday: FC = memo(() => {
  const translate = useTranslate();
  const settlement = useContext(SettlementShowContext);

  const {
    totalEstimatedManday,
    totalEstimatedLaborCost,
    totalSettlementManday,
    totalSettlementLaborCost,
    settlementLaborMandays = [],
  } = settlement;

  const isChildren = useCallback((cost: RowData) => cost.isChildren, []);

  const dataTable = useMemo(() => {
    const datas: Partial<RowData>[] = [];
    var index = 1;
    settlementLaborMandays.forEach((cost) => {
      datas.push({ ...cost, index: index });
      if (cost.settlementLaborEmployees) {
        cost.settlementLaborEmployees.forEach((emp) => {
          datas.push({
            ...emp,
            isChildren: true,
            employee: `${emp?.employee?.code} - ${emp?.employee?.name}`,
          });
        });
      }
      index++;
    });
    return datas;
  }, [settlementLaborMandays]);

  const columns = useMemo<ColumnsType<RowData>>(
    () => [
      {
        title: translate("common.no"),
        width: 50,
        dataIndex: "id",
        key: "id",
        render: (_, record, __) => {
          if(isChildren(record)) return null;
          return <DataText value={record.index} />;
        },
      },
      // {
      //   title: translate("Tên sản phẩm"),
      //   width: 250,
      //   dataIndex: "productName",
      //   key: "productName",
      //   render: (_, record) => {
      //     if (isSum(record) || isChildren(record)) return null;
      //     return (
      //       <DataText
      //         value={
      //           isAllProducts
      //             ? "Tất cả sản phẩm"
      //             : `${record?.product?.code} - ${record?.product?.name}`
      //         }
      //       />
      //     );
      //   },
      //   sorter: (a, b) => a?.product?.code?.length - b?.product?.code?.length,
      // },
      {
        title: translate("settlements.laborType"),
        width: 170,
        dataIndex: "internalRateCard",
        key: "internalRateCard",
        render: (_, record) => {
          if (isChildren(record)) return null;
          return (
            <DataText
              value={
                !isEmpty(record?.proportion)
                  ? "-"
                  : record?.internalRateCard?.position
              }
            />
          );
        },
        sorter: (a, b) =>
          a?.internalRateCard?.position?.length -
          b?.internalRateCard?.position?.length,
      },
      // {
      //   title: translate("Tỷ lệ"),
      //   width: 100,
      //   dataIndex: "proportionPolicy",
      //   key: "proportionPolicy",
      //   render: (_, record) => {
      //     if (isSum(record) || isChildren(record)) return null;
      //     return (
      //       <DataText
      //         value={
      //           !isEmpty(record?.proportion) ? (
      //             <Currency
      //               value={record?.proportion * 100}
      //               showCurrency={false}
      //               after="%"
      //             />
      //           ) : (
      //             "-"
      //           )
      //         }
      //       />
      //     );
      //   },
      //   sorter: (a, b) => a?.proportion - b?.proportion,
      // },
      {
        title: translate("settlements.employee"),
        width: 220,
        dataIndex: "employee",
        key: "employee",
        render: (_, record) => {
          return <DataText value={record?.employee || "-"} />;
        },
        sorter: (a, b) => a?.employee - b?.employee,
      },
      {
        title: translate("settlements.quantity"),
        width: 200,
        dataIndex: "settlementQuantity",
        key: "settlementQuantity",
        render: (_, record) => {
          return (
            <DataText
              value={
                !isEmpty(record?.settlementQuantity) ? (
                  <Currency
                    value={record?.settlementQuantity}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.estimatedValue - b?.estimatedValue,
      },
      {
        title: translate("settlements.unitPrice"),
        width: 200,
        dataIndex: "unitPrice",
        key: "unitPrice",
        render: (_, record) => {
          if (isChildren(record)) return null;
          return (
            <DataText
              value={
                record?.unitPrice ? (
                  <Currency value={record?.unitPrice} showCurrency={false} />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.unitPrice - b?.unitPrice,
      },
      {
        title: translate("settlements.value"),
        width: 200,
        dataIndex: "settlementValue",
        key: "settlementValue",
        render: (_, record) => {
          return (
            <DataText
              value={
                !isEmpty(record?.settlementValue) ? (
                  <Currency
                    value={record?.settlementValue}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.settlementValue - b?.settlementValue,
      },
      {
        title: translate(`settlements.estimate.quantity`),
        width: 100,
        dataIndex: "estimatedQuantity",
        key: "estimatedQuantity",
        render: (_, record) => {
          if (isChildren(record)) return null;
          return (
            <DataText
              value={
                !isEmpty(record?.proportion) ? (
                  "-"
                ) : (
                  <Currency
                    className="blue"
                    value={record?.estimatedQuantity}
                    showCurrency={false}
                  />
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.estimatedQuantity - b?.estimatedQuantity,
      },
      {
        title: translate("settlements.estimate.value"),
        width: 200,
        dataIndex: "estimatedValue",
        key: "estimatedValue",
        render: (_, record) => {
          if (isChildren(record)) return null;
          return (
            <DataText
              value={
                record?.estimatedValue ? (
                  <Currency
                    value={record?.estimatedValue}
                    showCurrency={false}
                  />
                ) : (
                  "-"
                )
              }
            />
          );
        },
        sorter: (a, b) => a?.estimatedValue - b?.estimatedValue,
      },
    ],
    [isChildren, translate]
  );

  return (
    <StyledWrapper>
      <Collapse
        className="w-full"
        defaultActiveKey={["1"]}
        ghost
        collapsible="header"
      >
        <Panel header={translate("settlements.cost.labor")} key="1">
          <Table
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            rowKey="id"
            columns={columns}
            dataSource={dataTable as RowData[]}
            scroll={{ x: 1770 }}
            style={{ marginTop: 16 }}
          />
          <Descriptions
            style={{ marginTop: 4 }}
            layout="vertical"
            column={{ md: 2, xs: 1 }}
          >
            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={translate("settlements.cost.totalSettlementValue")}
            >
              <DataText
                value={
                  isEmpty(totalSettlementLaborCost) ? (
                    "-"
                  ) : (
                    <Currency
                      showCurrency={false}
                      value={totalSettlementLaborCost}
                    />
                  )
                }
              />
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={translate("settlements.cost.totalSettlementQuantity")}
            >
              <DataText
                value={
                  isEmpty(totalSettlementManday) ? (
                    "-"
                  ) : (
                    <Currency
                      showCurrency={false}
                      value={totalSettlementManday}
                    />
                  )
                }
              />
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={translate("settlements.cost.totalEstimateValue")}
            >
              <DataText
                value={
                  isEmpty(totalEstimatedLaborCost) ? (
                    "-"
                  ) : (
                    <Currency
                      className="blue"
                      showCurrency={false}
                      value={totalEstimatedLaborCost}
                    />
                  )
                }
              />
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={translate("settlements.cost.totalEstimateQuantity")}
            >
              <DataText
                value={
                  isEmpty(totalEstimatedManday) ? (
                    "-"
                  ) : (
                    <Currency
                      className="blue"
                      showCurrency={false}
                      value={totalEstimatedManday}
                    />
                  )
                }
              />
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>
    </StyledWrapper>
  );
});

export default CostByManday;
