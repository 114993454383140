import React from "react";
import {
	Row,
	Col,
	AntdLayout,
	Card,
	Typography,
	Form,
	Input,
	Button,
	Checkbox,
	Space,
	Icons,
	notification
} from "@pankod/refine-antd";
import { useLogin, useTranslate, useRouterContext } from "@pankod/refine-core";

import {
	layoutStyles,
	containerStyles,
	titleStyles
} from "./styles";
import { checkInviteValid, userActive, validatePasswordToken } from 'api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { NO_AUTH_PATH } from "configs/path";
import { UpdatePassword, UpdatePasswordType } from "components/UpdatePassword";
import LangSelect from "components/LangSelect";

const { Text, Title, Paragraph } = Typography;
export interface IResetPasswordForm {
	password: string;
	newPassword: string;
}
enum EmailTokenType {
	Invitation,
	ForgotPassword
}

export const CustomResetPasswordPage: React.FC = () => {
	const [form] = Form.useForm<IResetPasswordForm>();
	const translate = useTranslate();
	const [searchParams] = useSearchParams();
	const token = encodeURIComponent(searchParams.get('token') || '');
	const navigate = useNavigate()
	const [data, setData] = React.useState<any>()
	const [isSuccess, setIsSuccess] = React.useState<boolean>(false)
	const [isError, setIsError] = React.useState<boolean>(false);
	const [updatePasswordType, setUpdatePasswordType] = React.useState<UpdatePasswordType>(UpdatePasswordType.INIT_PASSWORD);
	const [email, setEmail] = React.useState<string>();

	const validateInvitationEmail = () => {
		checkInviteValid(
			{
				token: token
			}
		).then(res => {
			const data = res?.data
			if (data && data?.isResetPassword) {
				navigate('/')
			} if (data && !data?.isValid) {
				setIsError(true)
			} else {
				setData(res.data)
			}
		})
			.catch(err => {
				setIsError(true)
			})
	}
	React.useEffect(() => {
		validatePasswordToken({ token: token }).then((res) => {
			setIsError(!res.data.isValid);
			setEmail(res.data.email);
			switch (res.data.type) {
				case EmailTokenType.Invitation:
					setUpdatePasswordType(UpdatePasswordType.INIT_PASSWORD);
					break;
				case EmailTokenType.ForgotPassword:
					setUpdatePasswordType(UpdatePasswordType.FORGOT_PASSWORD); break;
			}

			if (!isError && res.data.type === EmailTokenType.Invitation) {
				validateInvitationEmail();
			}
		}).catch(err => {
			setIsError(true)
		})

	}, [])



	return (
		<AntdLayout style={layoutStyles}>

			<LangSelect isFixed isText />

			<Row
				justify="center"
				align="middle"
				style={{
					height: !isError ? "100vh" : "70vh",
				}}
			>
				<Col span={24}>
					<div style={containerStyles}>
						{
							!isError && !isSuccess &&
							<Card>
								<UpdatePassword
									updateType={updatePasswordType}
									email={email}
								/>
							</Card>
						}
						{data?.isResetPassword && !isSuccess && !isError && <ExistUser />}
						{isSuccess && <Success />}
						{isError && <Error type={updatePasswordType} />}
					</div>
				</Col>
			</Row>
		</AntdLayout>
	);
};

const ExistUser = () => {
	const translate = useTranslate();
	const navigate = useNavigate()

	const CardTitle = (
		<Title level={3} style={titleStyles}>
			{translate("pages.resetPassword.exitsUser")}
		</Title>
	);

	return (
		<Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
			<Paragraph>{translate("pages.resetPassword.desc")}</Paragraph>
			<Paragraph>{translate("pages.resetPassword.desc1")}</Paragraph>
			<Button
				type="primary"
				size="large"
				htmlType="submit"
				block
				onClick={() => navigate('/')}
			>
				{translate("buttons.backToLogin")}
			</Button>
		</Card>
	)
}

const Success = () => {
	const translate = useTranslate();
	const navigate = useNavigate()

	const CardTitle = (
		<Title level={3} style={titleStyles}>
			{translate("pages.resetPassword.resetSuccess")}
		</Title>
	);

	return (
		<Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
			<Paragraph>{translate("pages.resetPassword.successDesc")}</Paragraph>
			<Button
				type="primary"
				size="large"
				htmlType="submit"
				block
				onClick={() => navigate('/')}
			>
				{translate("buttons.backToLogin")}
			</Button>
		</Card>
	)
}
type ErrorProps = {
	type?: UpdatePasswordType
}
const Error = ({ type }: ErrorProps) => {
	const translate = useTranslate();
	const navigate = useNavigate()
	var title = '';
	var message = '';
	switch (type) {
		case UpdatePasswordType.INIT_PASSWORD:
			title = translate("pages.resetPassword.initAccountErrorTitle");
			message = translate("pages.resetPassword.initAccountErrorDesc");
			break;
		case UpdatePasswordType.FORGOT_PASSWORD:
		case undefined:
			title = translate("pages.resetPassword.forgotErrorTitle");
			message = translate("pages.resetPassword.initAccountErrorDesc");
			break;
	}

	const CardTitle = (
		<Title level={3} style={titleStyles}>
			{title}
		</Title>
	);

	return (
		<Card bordered={false} title={CardTitle} headStyle={{ borderBottom: 0 }}>
			<Paragraph style={{ color: '#697077' }}>{message}</Paragraph>
			<Button
				type="primary"
				size="large"
				onClick={() => {
					navigate('/');
				}}
				block
			>
				{translate("pages.changePassword.successButton")}
			</Button>
		</Card>
	)
}