import {
  Icons,
  Radio,
  Table,
  getDefaultSortOrder,
  Tag,
  useForm,
  Form,
  Select,
  useSelect,
  Space,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useNavigation,
  useTable,
  useTranslate,
} from "@pankod/refine-core";
import { ButtonTooltip, DataText, EmptyData, SearchBox } from "components";
import { ListCustom } from "components/layout";
import { API_PATH, PATH } from "configs/path";
import usePermissions from "hooks/permission";
import { useNavigate } from "react-router-dom";
import React from "react";
import { DateTimeFieldCustom } from "components/DateTimeTextField";
import { ConfigStatus } from "api/enums";
import { searchSelect } from "utils/commons";
import { ConfigStatusTag } from "components/StatusTag";

export const SupplierList: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { create, show, edit } = useNavigation();
  const navigate = useNavigate();
  const { checkCreateHaveHelperText, checkEditHaveHelperText } =
    usePermissions();
  const [isSearch, setIsSearch] = React.useState<boolean>(false);
  const { form, formProps } = useForm<any>();

  const {
    tableQueryResult: { data: dataTable, isLoading, isRefetching },
    pageSize,
    setPageSize,
    current,
    setCurrent,
    filters,
    setFilters,
    sorter,
    setSorter,
  } = useTable<any>({
    initialCurrent: 1,
    initialSorter: [
      {
        field: "createdDate",
        order: "desc",
      },
    ],
  });

  const onSearch = (value: string) => {
    setCurrent(1);
    if (value) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setFilters([
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ]);
  };

  const { selectProps: departmentSelectProps } = useSelect<any>({
    resource: API_PATH.businessIndustrySelect,
    optionValue: "value",
    optionLabel: "label",
    onSearch: () => [
      // avoid call api when search
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  return (
    <ListCustom
      title={t("suppliers.titles.list")}
      headerButtons={
        <ButtonTooltip
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => create(PATH.configSuppliers)}
          {...checkCreateHaveHelperText(PATH.configSuppliers)}
        >
          {t("actions.create")}
        </ButtonTooltip>
      }
      breadcrumb={null}
    >
      <div className="list-content">
        <Form {...formProps}>
          <Space>
            <SearchBox
              placeholder={t("suppliers.search.placeholder")}
              onSearch={onSearch}
            />
            <Form.Item
              name={["businessIndustryId"]}
              style={{ marginBottom: 16 }}
            >
              <Select
                {...departmentSelectProps}
                allowClear
                onChange={(e) => {
                  setFilters([
                    {
                      field: "businessIndustryId",
                      operator: "eq",
                      value: e,
                    },
                  ]);
                }}
                className={"placeholderBlack"}
                dropdownStyle={{ color: "black" }}
                style={{ width: 200 }}
                bordered={false}
                placeholder={t("suppliers.fields.businessIndustry")}
                filterOption={(inputValue: string, option: any) => {
                  return option && searchSelect(inputValue, option?.label);
                }}
              />
            </Form.Item>
          </Space>
          <Radio.Group
            defaultValue={-1}
            buttonStyle="solid"
            onChange={(e) => {
              setCurrent(1);
              setFilters([
                {
                  field: "status",
                  operator: "eq",
                  value: e?.target?.value === -1 ? "" : e?.target?.value,
                },
              ]);
            }}
            style={{ display: "block", marginBottom: 16 }}
          >
            <Radio.Button value={-1}>{t("common.status.all")}</Radio.Button>
            <Radio.Button value={true}>
              {t("common.status.active")}
            </Radio.Button>
            <Radio.Button value={false}>
              {t("common.status.deactivate")}
            </Radio.Button>
          </Radio.Group>
        </Form>

        <Table
          dataSource={dataTable?.data}
          rowKey="id"
          locale={{
            emptyText: (
              <EmptyData text={isSearch ? t("common.noDataSearch") : ""} />
            ),
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                show(PATH.configSuppliers, record.id);
              },
            };
          }}
          pagination={{
            pageSize: pageSize,
            current: current,
            total: dataTable?.total,
            locale: {
              items_per_page: t("common.pageNumber"),
            },
            onChange: (cur, size) => {
              setCurrent(cur);
              setPageSize(size);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
            },
            showTotal: (total) => (
              <p>{t("common.totalPage", { total: total })}</p>
            ),
          }}
          scroll={{ x: "3000px" }}
          onChange={(_, f, s) => {
            const newSort = s as any;
            setSorter([
              {
                field: newSort?.field,
                order: newSort?.order,
              },
            ]);
          }}
          showSorterTooltip={false}
        >
          <Table.Column
            key="id"
            title={t("common.no")}
            render={(_, __, index) => (current - 1) * pageSize + index + 1}
            width={80}
          />
          <Table.Column
            dataIndex="code"
            title={t("suppliers.fields.code")}
            render={(value, record: any) => (
              <DataText value={record?.code} defaultValue="-" />
            )}
            defaultSortOrder={getDefaultSortOrder("code", sorter)}
            sorter
            width={150}
          />
          <Table.Column
            dataIndex="name"
            title={t("suppliers.fields.name")}
            render={(value, record: any) => (
              <DataText value={record?.name} defaultValue="-" />
            )}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
            width={400}
          />

          <Table.Column
            dataIndex="taxNo"
            title={t("suppliers.fields.taxNo")}
            render={(value, record: any) => (
              <DataText value={record?.taxNo} defaultValue="-" />
            )}
            defaultSortOrder={getDefaultSortOrder("taxNo", sorter)}
            sorter
            width={200}
          />
          <Table.Column
            dataIndex="phoneNo"
            title={t("suppliers.fields.phone")}
            render={(value, record: any) => (
              <DataText value={record?.phoneNo} defaultValue="-" />
            )}
            defaultSortOrder={getDefaultSortOrder("phoneNo", sorter)}
            sorter
            width={200}
          />

          <Table.Column
            dataIndex="email"
            title={t("suppliers.fields.email")}
            render={(value, record: any) => (
              <DataText value={record?.email} defaultValue="-" />
            )}
            defaultSortOrder={getDefaultSortOrder("email", sorter)}
            sorter
            width={200}
          />

          <Table.Column
            dataIndex="website"
            title={t("suppliers.fields.website")}
            render={(value, record: any) => (
              <DataText value={record?.website} defaultValue="-" />
            )}
            defaultSortOrder={getDefaultSortOrder("website", sorter)}
            sorter
            width={200}
          />

          <Table.Column
            dataIndex="businessIndustry"
            title={t("suppliers.fields.businessIndustry")}
            render={(value, record: any) => (
              <DataText
                value={record?.businessIndustry?.name}
                defaultValue="-"
              />
            )}
            defaultSortOrder={getDefaultSortOrder(
              "businessIndustry.name",
              sorter
            )}
            sorter
            width={200}
          />

          <Table.Column
            dataIndex="status"
            title={t("common.config.status")}
            render={(value, record: any) => (
              <ConfigStatusTag status={record?.status} />
            )}
            defaultSortOrder={getDefaultSortOrder("status", sorter)}
            sorter
            width={200}
          />

          <Table.Column
            dataIndex="updatedName"
            title={t("common.updatedName")}
            render={(value, record: any) => (
              <DataText
                className="primary"
                value={record?.updatedName}
                defaultValue="-"
              />
            )}
            defaultSortOrder={getDefaultSortOrder("updatedName", sorter)}
            sorter
            width={200}
          />
          <Table.Column
            dataIndex="updatedDate"
            title={t("common.updatedDate")}
            render={(value, record: any) => (
              <DateTimeFieldCustom value={record?.updatedDate} />
            )}
            defaultSortOrder={getDefaultSortOrder("updatedDate", sorter)}
            sorter
            width={200}
          />

          <Table.Column
            dataIndex="createdName"
            key="createdName"
            title={t("common.createdName")}
            render={(value, record: any) => (
              <DataText
                className="primary"
                value={record?.createdName}
                defaultValue="-"
              />
            )}
            defaultSortOrder={getDefaultSortOrder("createdName", sorter)}
            sorter
            width={200}
          />

          <Table.Column
            dataIndex="createdDate"
            key="createdDate"
            title={t("common.createdDate")}
            render={(value, record: any) => (
              <DateTimeFieldCustom value={record?.createdDate} />
            )}
            defaultSortOrder={getDefaultSortOrder("createdDate", sorter)}
            sorter
            width={200}
          />

          <Table.Column<any>
            title={t("table.actions")}
            dataIndex="actions"
            align="center"
            fixed="right"
            width={120}
            render={(_, record) => {
              return (
                <ButtonTooltip
                  type="text"
                  icon={<Icons.EditOutlined />}
                  className="gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    edit(PATH.configSuppliers, record.id);
                  }}
                  {...checkEditHaveHelperText(
                    PATH.configSuppliers,
                    !record?.status
                  )}
                />
              );
            }}
          />
        </Table>
      </div>
    </ListCustom>
  );
};
