import { useTranslate } from "@pankod/refine-core";
import { Descriptions } from "antd";
import { DataText } from "components";
import IQuotation from "interfaces/Quotation";
import { FC, memo } from "react";

interface Props {
  record?: IQuotation;
}
const Customer: FC<Props> = memo((props) => {
  const { record } = props;
  const translate = useTranslate();
  return (
    <Descriptions
      column={2}
      title={translate("Thông tin khách hàng")}
      layout="vertical"
      style={{ background: "white", padding: 24 }}
    >
      <Descriptions.Item
        label={translate("Khách hàng")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.customerName} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Địa chỉ")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.customerAddress} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Người đại diện")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.customerRepresentative} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Chức vụ")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.customerPosition} />
      </Descriptions.Item>

      <Descriptions.Item
        label={translate("Mã số thuế")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={record?.customerIdentifiedTaxCode} />
      </Descriptions.Item>
    </Descriptions>
  );
});

export default Customer;
