import { Descriptions, Icons, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow, useTranslate, useNavigation } from "@pankod/refine-core"
import { getRolePermissions } from "api";
import { ShowCustom } from "components/layout";
import { PATH } from "configs/path";
import { IFeaturePermission } from "interfaces";
import { RolePermissions } from "pages/rolePermissions";
import React, { useEffect } from "react";
import { ActionTypeEnum } from "utils/enums";
import { v4 as uuid } from 'uuid';
import usePermissions from 'hooks/permission'
import { ButtonTooltip } from "components";

const { Text } = Typography;

export const RoleShow: React.FC<IResourceComponentsProps> = (props) => {
    const translate = useTranslate();
    const { edit } = useNavigation();
    const [rolePermissions, setRolePermissions] = React.useState<IFeaturePermission[]>([]);
    const { checkEditHaveHelperText } = usePermissions()

    const { queryResult, showId } = useShow<any>({
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;
    
    useEffect(() => {
        getRolePermissions(showId).then(val => {
            const temp = val.data.map(d => {
                const data =  {
                    create:  d.permissions?.includes('create'),
                    update:  d.permissions?.includes('update'),
                    delete:  d.permissions?.includes('delete'),
                    read:  d.permissions?.includes('read'),
                    print:  d.permissions?.includes('print'),
                    featureId: d.featureId,
                    featureName: d.featureName,
                    id: uuid()
                } as IFeaturePermission;
                data.all = (data.create && data.update && data.delete && data.read && data.print);
                return data;
            });
            setRolePermissions(temp);
        });
    }, [])

    return (
        <ShowCustom
            {...props}
            isLoading={isLoading}
            title={<Text style={{ marginBottom: 0 }}>{translate("roles.roleInfor")}: {record?.code}</Text>}
            breadcrumbText={record?.code}
            headerButtons={
                <ButtonTooltip
                    type="primary"
                    icon={<Icons.EditOutlined />}
                    onClick={()=> edit(PATH.roles, record?.id)}
                    {...checkEditHaveHelperText(PATH.roles)}
                    >
                    {translate('actions.edit')}
                </ButtonTooltip>
            }
        >
            <Descriptions column={2} title={translate("roles.roleInfor")} layout="vertical">
                <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={translate("roles.fields.code")}>{record?.code}</Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={translate("roles.fields.name")}>{record?.name}</Descriptions.Item>
                <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label={translate("roles.fields.description")}>{record?.description}</Descriptions.Item>
            </Descriptions>
            <RolePermissions actionType={ActionTypeEnum.VIEW} value={rolePermissions} />
        </ShowCustom>
    )
}