import {
  useNavigation,
  useShow,
  useTranslate,
  useUpdate,
} from "@pankod/refine-core";
import usePermissions from "hooks/permission";
import BusinessOpportunities, {
  BusinessOpportunitiesStatus,
} from "interfaces/BusinessOpportunities";
import { useCallback, useState } from "react";
import { notification, useSelect } from "@pankod/refine-antd";
import { API_PATH, PATH } from "configs/path";

export const getStatus = (
  value: BusinessOpportunitiesStatus = BusinessOpportunitiesStatus[
    "Confirm Opportunity"
  ]
) => {
  let agr: { color: string } = { color: "success" };

  switch (Number(value)) {
    case BusinessOpportunitiesStatus["Confirm Opportunity"]:
      agr.color = "default";
      break;
    case BusinessOpportunitiesStatus["Convert Leads to Opportunity"]:
    case BusinessOpportunitiesStatus["Consulting"]:
    case BusinessOpportunitiesStatus["Quotation/BID"]:
      agr.color = "blue";
      break;
    case BusinessOpportunitiesStatus["Win Deal"]:
    case BusinessOpportunitiesStatus["Negotiation contract"]:
      agr.color = "warning";
      break;
    case BusinessOpportunitiesStatus["Process contract"]:
      agr.color = "success";
      break;
    case BusinessOpportunitiesStatus["Won Deal"]:
      agr.color = "success";
      break;
    case BusinessOpportunitiesStatus["Lost Deal"]:
      agr.color = "error";
      break;
    default:
      agr.color = "success";
      break;
  }

  return agr;
};

export const useController = () => {
  const { edit } = useNavigation();
  const { checkEditHaveHelperText } = usePermissions();
  const translate = useTranslate();
  const { queryResult } = useShow<BusinessOpportunities>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { mutate: mutateUpdateStatus, isLoading: isUpdateLoading } =
    useUpdate();

  const [visibleModalClose, setVisisbleModalClose] = useState(false);

  const toggleModalClose = () => setVisisbleModalClose((prev) => !prev);

  const renderStatus = useCallback(
    (
      value: BusinessOpportunitiesStatus = BusinessOpportunitiesStatus[
        "Confirm Opportunity"
      ]
    ) => getStatus(value),
    []
  );

  const { selectProps: statusSelectProps } = useSelect({
    resource: API_PATH.businessOpportunituesStatusCloseDropdownlist,
    optionLabel: "name",
    optionValue: "id",
  });

  const isClose = [
    BusinessOpportunitiesStatus["Won Deal"],
    BusinessOpportunitiesStatus["Lost Deal"],
  ].includes(Number(record?.businessOppsStatus?.code));

  const onEdit = (values: any) => {
    mutateUpdateStatus(
      {
        resource: PATH.businessOpportunities,
        values: values,
        id: record?.id!,
        metaData: { type: "/updateStatusBusinessOpportunity" },
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: translate(
              "businessOpportunities.notify.closeTheBusinessPpportunitySuccessfully"
            ),
          });
          toggleModalClose();
        },
        onError: (error) => {
          toggleModalClose();
          notification.error({
            message: translate(
              "businessOpportunities.notify.closeTheFailedBusinessOpportunity"
            ),
          });
        },
      }
    );
  };

  return {
    isLoading,
    record,
    isClose,
    visibleModalClose,
    statusSelectProps,
    isUpdateLoading,
    translate,
    toggleModalClose,
    checkEditHaveHelperText,
    renderStatus,
    edit,
    onEdit,
  };
};
