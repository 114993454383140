import { Tag } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { EmployeeStatus } from "api/enums";

interface EmployeeStatusProps {
    value: number,
}

export const EmployeeStatusCustom = ({ value }: EmployeeStatusProps) => {
    const translate = useTranslate();

    switch (value) {
        case EmployeeStatus.Active:
            return <Tag color="success">{translate("users.fields.status.active")}</Tag>
        default:
            return <Tag color="#F0F0F0" style={{ color: '#8C8C8C' }}>{translate("users.fields.status.deactive")}</Tag>
    }
}