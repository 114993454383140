import { Collapse, Form, Table, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import {
  IForm,
  SettlementFormContext,
} from "pages/projectManagement/settlements/create";
import { memo, useContext, useMemo } from "react";
import styled from "styled-components";
import type { ColumnsType } from "antd/es/table";
import { Currency } from "components/Currency";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";

const StyledWrapper = styled.div`
  margin-top: 24px;
`;
const { Panel } = Collapse;

const { Text } = Typography;
const { useWatch } = Form;

type Data = {
  label: string;
  settlement?: number;
  estimate?: number;
  margin?: number;
};

const TotalCost = memo(() => {
  const {
    dataForCreate,
    totalSettlementValueManday,
    totalSettlementQuantityManday,
    initialValues,

    calcTotal,
  } = useContext(SettlementFormContext);
  const translate = useTranslate();
  const form = useFormInstance<IForm>();
  const listCostByMoney = useWatch("listCostByMoney", form);

  const dataTable = useMemo<Data[]>(() => {
    let dataBuilder = [
      {
        label: translate("settlements.total.cost"),
        settlement: calcTotal(
          Object.values(listCostByMoney || {}),
          "settlementValue"
        ),
        estimate:
          dataForCreate?.totalEstimatedByCash ??
          initialValues?.totalEstimatedByCash,
      },
      {
        label: translate("settlements.total.labor"),
        settlement: totalSettlementValueManday,
        estimate:
          dataForCreate?.totalEstimatedLaborCost ??
          initialValues?.totalEstimatedLaborCost,
      },
      {
        label: translate("settlements.total.total"),
      },
      {
        label: translate("settlements.total.manday"),
        settlement: totalSettlementQuantityManday,
        estimate:
          dataForCreate?.totalEstimatedManday ??
          initialValues?.totalEstimatedManday,
      },
    ];
    dataBuilder[2].settlement =
      (dataBuilder[0].settlement || 0) + (dataBuilder[1].settlement || 0);
    dataBuilder[2].estimate =
      (dataBuilder[0].estimate || 0) + (dataBuilder[1].estimate || 0);

    return dataBuilder.map((item) => ({
      ...item,
      margin: (item.settlement || 0) - (item.estimate || 0),
    }));
  }, [
    dataForCreate,
    listCostByMoney,
    totalSettlementValueManday,
    totalSettlementQuantityManday,
    calcTotal,
    translate,
    initialValues,
  ]);

  const columns = useMemo<ColumnsType<Data>>(() => {
    return [
      {
        width: 200,
        title: "",
        dataIndex: "label",
        align: "left",
        key: "label",
        render: (value) => <Text>{value}</Text>,
      },
      {
        title: translate("settlements.total.settlement"),
        dataIndex: "settlement",
        key: "settlement",
        render: (value) => <Currency value={value || 0} showCurrency={false} />,
      },
      {
        title: translate("settlements.total.estimation"),
        dataIndex: "estimate",
        key: "estimate",
        render: (value) => (
          <Currency className="blue" value={value || 0} showCurrency={false} />
        ),
      },
      {
        title: translate("settlements.total.margin"),
        dataIndex: "margin",
        key: "margin",
        render: (value) => <Currency value={value || 0} showCurrency={false} />,
      },
    ];
  }, [translate]);

  return (
    <StyledWrapper>
      <Collapse
        className="w-full"
        defaultActiveKey={["1"]}
        ghost
        collapsible="header"
      >
        <Panel header={translate("settlements.titles.summary")} key="1">
          <Table
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            rowKey="id"
            columns={columns}
            dataSource={dataTable}
            scroll={{ x: 550 }}
          />
        </Panel>
      </Collapse>
    </StyledWrapper>
  );
});

export default TotalCost;
