import { Collapse, Tag } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { LinkText } from "components";
import { PATH } from "configs/path";
import { CSSProperties, FC, memo } from "react";

const { Panel } = Collapse;

const styleView: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px",
  fontWeight: 400,
  fontSize: 13,
}

const BusinessOpportunities: FC<any> = memo((props: any) => {
    const translate = useTranslate();
    
    return (
        <div style={{ backgroundColor: "white" }}>
        <Collapse className="mis-case-detail-collapse" defaultActiveKey={["1"]} onChange={() => {
        }} ghost>
          <Panel
            header={translate(
              "businessOpportunities.businessOpportunities"
            )}
            key="1"
          >
            <div
              style={{
                borderRadius: 4,
                overflow: "hidden",
                border: "1px solid #F0F0F0",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F0F0F0",
                  padding: "10px 12px",
                  fontWeight: 500,
                }}
              >
                {translate("caseId.businessOpportunityDetail")}
              </div>
              <div
                style={styleView}
              >
                <LinkText
                  value={{
                    id: props.businessOpportunity?.id,
                    code: props.businessOpportunity?.name,
                  }}
                  resource={`${PATH.sales}/${PATH.businessOpportunities}`}
                />
                <Tag>
                  {`${props?.businessOpportunityStatus?.name} - ${props?.businessOpportunityStatus?.percentSuccess}%`}
                </Tag>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    )
});

export default BusinessOpportunities;
