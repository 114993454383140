import {
  Button,
  ButtonProps,
  Checkbox,
  Col,
  Divider,
  Form,
  Icons,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { SubjectType, WorkflowDiagramApplyAcceptanceWhen } from "api/enums";
import { SelectCustom } from "components/SelectCustom";
import { dataDecisionDefault } from "components/WorkflowDiagram/constants";
import { classNameSelect, nextStepOptions, subjectMapping } from "components/WorkflowDiagram/diagramHelper";
import { API_PATH } from "configs/path";
import { IApprovalStepApprover, IWorkflowNode, IWorkflowNodeData } from "interfaces";
import React from "react";
import { v4 as uuid } from "uuid";
const { Option } = Select;

interface Props {
  step?: IWorkflowNode;
  otherSteps?: IWorkflowNodeData[];
  onChange: (e: IWorkflowNode) => void;
  onFormChange: () => void;
  form: any;
  formProps: any;
  saveButtonProps: ButtonProps;
}

const { useWatch } = Form;

const { Title, Text, Paragraph } = Typography;

const NormalDataNode: React.FC<Props> = ({
  step,
  otherSteps,
  onChange,
  form,
  formProps,
  saveButtonProps,
  onFormChange,
}) => {
  const t = useTranslate();
  const [isLineManagerApprove, setIsLineManagerApprove] = React.useState<boolean>();
  const [subjectType, setSubjectType] = React.useState<SubjectType>(SubjectType.NONE);
  const [selectedSubjectValue, setSelectedSubjectValue] = React.useState<any>();
  const [subjectList, setSubjectList] = React.useState<any[]>([]);
  const [visibleConfirmModal, setVisibleConfirmModal] = React.useState<boolean>();
  const [selectedSubjectToRemove, setSelectedSubjectToRemove] = React.useState<boolean>();
  const subjectLimitation = 11; // 10 subjects + 1 (direct manager line)

  const defaultNextStep = useWatch("defaultNextStep", form);

  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue(step);
    const initSubjectList = [
      {
        id: uuid(),
        subjectType: SubjectType.DIRECT_MANAGER,
        subjectTypeName: t("workflows.directManager"),
        displayName: t("workflows.directManager"),
        value: "", // using isLineManagerApprove state for direct manager
      },
      ...subjectMapping(step?.approvalStepApprovers ?? []),
    ];

    setSubjectList(initSubjectList);
    setIsLineManagerApprove(step?.isLineManagerApprove ?? false);

    // Clear selection of previous props
    setSelectedSubjectValue(undefined);
    setSubjectType(SubjectType.NONE);
  }, [step]);

  const onConfirmRemoveSubject = (record: any) => {
    setSelectedSubjectToRemove(record.id);
    setVisibleConfirmModal(true);
  };

  const emitOnChangeEvent = () => {
    const formValue = form.getFieldsValue() as IWorkflowNode;
    const updatedStep: IWorkflowNode = {
      ...step,
      ...formValue,
      isLineManagerApprove: isLineManagerApprove,
      approvalStepApprovers: [
        ...subjectList.slice(1).map((data) => {
          // Remove direct manager line
          return {
            employeeId: data.subjectType === SubjectType.EMPLOYEE ? data.value : null,
            titleId: data.subjectType === SubjectType.TILLE ? data.value : null,
            caseRoleId: data.subjectType === SubjectType.CASE_MEMBER ? data.value : null,
            displayName: data.displayName,
            subjectTypeName: data.subjectTypeName,
            subjectType: data.subjectType,
            value: data.value,
          } as IApprovalStepApprover;
        }),
      ],
    };

    onChange(updatedStep);
  };

  const onChangeSubjectType = (value: SubjectType, option: any) => {
    setSubjectType(value);
    resetSubjectSelection();
  };

  const resetSubjectSelection = () => {
    setSubjectFieldError(""); // clear the error if any
    setSelectedSubjectValue(undefined);
  };

  const onAddSubject = () => {
    onFormChange();
    if (!selectedSubjectValue) {
      setSubjectFieldError(t("errors.ER060"));
      return;
    }

    if (
      subjectList.filter((t) => t.subjectType === subjectType && t.value === selectedSubjectValue.value).length !== 0
    ) {
      setSubjectFieldError(t("workflows.messages.existSubjectError"));
      return;
    }
    const newSubjectList = [...subjectList];
    newSubjectList.push({
      id: uuid(),
      subjectType: subjectType,
      subjectTypeName: getSubjectTypeName(subjectType),
      displayName: selectedSubjectValue.label,
      value: selectedSubjectValue.value,
      titleId: subjectType === SubjectType.TILLE ? selectedSubjectValue.value : null,
      employeeId: subjectType === SubjectType.EMPLOYEE ? selectedSubjectValue.value : null,
      caseRoleId: subjectType === SubjectType.CASE_MEMBER ? selectedSubjectValue.value : null,
    });
    setSubjectList(newSubjectList);

    setSelectedSubjectValue(undefined);
  };

  const getSubjectTypeName = (type: SubjectType) => {
    switch (type) {
      case SubjectType.EMPLOYEE:
        return t("workflows.subjectTypeEmployee");
      case SubjectType.TILLE:
        return t("workflows.subjectTypeTitle");
      case SubjectType.DIRECT_MANAGER:
        return t("workflows.directManager");
      case SubjectType.CASE_MEMBER:
        return t("workflows.subjectTypeCaseMember");
    }
  };

  const setSubjectFieldError = (error: string | undefined) => {
    const errors = error ? [error] : [];
    form.setFields([{ name: "subject", errors: errors }]);
  };

  const onRemoveSubject = () => {
    if (selectedSubjectToRemove) {
      setSubjectList([...subjectList.filter((t) => t.id !== selectedSubjectToRemove)]);
      setSelectedSubjectToRemove(undefined);
      setVisibleConfirmModal(false);
      onFormChange();
    }
  };

  const subjectColumns: any[] = [
    {
      title: t("workflows.subject"),
      dataIndex: "displayName",
      key: "displayName",
      width: "40%",
    },
    {
      title: t("workflows.subjectType"),
      dataIndex: "subjectTypeName",
      key: "subjectTypeName",
      width: "30%",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "20%",
      render: (_: any, record: any, index: any) => {
        if (index === 0) {
          return (
            <Checkbox
              onChange={(checked) => {
                setIsLineManagerApprove(checked.target.checked);
              }}
              checked={isLineManagerApprove}
            ></Checkbox>
          );
        } else {
          return (
            <Button
              onClick={() => onConfirmRemoveSubject(record)}
              type="text"
              size="small"
              style={{ color: "#8C8C8C" }}
              icon={<Icons.DeleteOutlined />}
            ></Button>
          );
        }
      },
    },
  ];

  const renderSubjectSelection = () => {
    switch (subjectType) {
      case SubjectType.NONE:
        return (
          <Input
            placeholder={t("workflows.selectSubjectPlaceHolder")}
            onChange={(value) => {
              if (value && !subjectType) {
                setSubjectFieldError(t("workflows.noSelectSubjectTypeError"));
              }
            }}
            style={{ width: "60%" }}
            suffix={<Icons.SearchOutlined style={{ color: "#D9D9D9" }} />}
          />
        );
      case SubjectType.EMPLOYEE:
        return (
          <SelectCustom
            allowClear
            value={selectedSubjectValue?.value}
            placeholder={t("workflows.selectSubjectEmployeePlaceHolder")}
            disabled={false}
            resource={API_PATH.employeeDropdownList}
            optionLabel="fullName"
            optionValue="id"
            style={{ width: "60%" }}
            valueInObject={true}
            onChangeItem={(value: any, option: any, data: any) => {
              const selectedData = data
                ? {
                    value: data.id,
                    label: `${data.code} - ${data.fullName}`,
                  }
                : undefined;
              setSelectedSubjectValue(selectedData);
              setSubjectFieldError(undefined);
            }}
            onClear={() => {
              setSelectedSubjectValue(undefined);
            }}
            useServerFiltering={true}
          />
        );
      case SubjectType.TILLE:
        return (
          <SelectCustom
            allowClear
            value={selectedSubjectValue?.value}
            placeholder={t("workflows.selectSubjectTitlePlaceHolder")}
            disabled={false}
            resource={API_PATH.employeeTitlesDropdownList}
            optionLabel="label"
            optionValue="value"
            style={{ width: "60%" }}
            valueInObject={true}
            onChangeItem={(value: any, option: any, data: any) => {
              const selectedData = data
                ? {
                    value: data.value,
                    label: data.label,
                  }
                : undefined;
              setSelectedSubjectValue(selectedData);
              setSubjectFieldError(undefined);
            }}
            onClear={() => {
              setSelectedSubjectValue(undefined);
            }}
          />
        );
      case SubjectType.CASE_MEMBER:
        return (
          <SelectCustom
            allowClear
            value={selectedSubjectValue?.value}
            placeholder={t("workflows.selectSubjectCaseMemberPlaceHolder")}
            disabled={false}
            resource={API_PATH.approvalCaseRoleDropdownlist}
            optionLabel="name"
            optionValue="id"
            style={{ width: "60%" }}
            valueInObject={true}
            onChangeItem={(value: any, option: any, data: any) => {
              const selectedData = data
                ? {
                    value: data.id,
                    label: data.name,
                  }
                : undefined;
              setSelectedSubjectValue(selectedData);
              setSubjectFieldError(undefined);
            }}
            onClear={() => {
              setSelectedSubjectValue(undefined);
            }}
          />
        );
    }
  };

  return (
    <>
      <Form
        {...formProps}
        initialValues={step}
        onFinish={emitOnChangeEvent}
        onFinishFailed={() => {}}
        layout="vertical"
        labelCol={{ span: 10 }}
        style={{ height: "100%" }}
        onChange={onFormChange}
      >
        <Row wrap={false} style={{ flexDirection: "column", height: "100%" }}>
          <Col flex="auto">
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t("workflows.fields.stepName")}
                  name="name"
                  style={{ maxWidth: 500 }}
                  rules={[
                    {
                      required: true,
                      message: t("errors.ER056"),
                    },
                    {
                      max: 300,
                      message: t("errors.ER014", { max: "300" }),
                    },
                    {
                      validator: async (_, value) => {
                        if (
                          value &&
                          otherSteps?.findIndex((t) => t.data?.name?.toLowerCase() === value.trim().toLowerCase()) !==
                            -1
                        ) {
                          return Promise.reject(t("errors.ER051"));
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder={t("workflows.fields.stepNamePlaceHolder")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  hidden={!!step?.name}
                  label={t("Loại")}
                  name="type"
                  style={{ maxWidth: 500 }}
                  rules={[
                    {
                      required: true,
                      message: t("errors.ER056"),
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "Normal", value: 1 },
                      {
                        label: "Gateway - Decision",
                        value: 3,
                      },
                    ]}
                    onChange={(value) =>
                      onChange({
                        ...step,
                        ...dataDecisionDefault,
                        type: value,
                        errors: ["errors.ER056", "errors.ER062", "errors.ER057"],
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Tabs defaultActiveKey="1" centered className="fullwidth-tab">
              <Tabs.TabPane tab={t("workflows.subject")} key="1">
                <Row gutter={[8, 16]}>
                  <Col span={24}>
                    <Title level={5}>{t("workflows.buttons.addSubject")}</Title>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="subject" style={{ maxWidth: 500 }} className="m-0">
                      <Input.Group compact style={{ width: "100%" }}>
                        <Select
                          onChange={onChangeSubjectType}
                          placeholder={t("workflows.subjectPlaceholder")}
                          style={{ width: "30%" }}
                          dropdownMatchSelectWidth={false}
                        >
                          <Option value={SubjectType.EMPLOYEE}>{t("workflows.subjectTypeEmployee")}</Option>
                          <Option value={SubjectType.TILLE}>{t("workflows.subjectTypeTitle")}</Option>
                          <Option value={SubjectType.CASE_MEMBER}>{t("workflows.subjectTypeCaseMember")}</Option>
                        </Select>

                        {renderSubjectSelection()}

                        <Button
                          disabled={subjectList.length === subjectLimitation}
                          onClick={onAddSubject}
                          type="primary"
                          icon={<Icons.PlusOutlined />}
                          style={{ width: "10%" }}
                        ></Button>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {t("workflows.subjectList")}
                      <Tooltip title={t("workflows.addSubjectTooltip")} placement="bottom">
                        <Icons.QuestionCircleOutlined style={{ marginLeft: 10 }} />
                      </Tooltip>
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Table
                      size="small"
                      columns={subjectColumns}
                      dataSource={subjectList}
                      pagination={{
                        pageSize: Number.MAX_VALUE,
                        hideOnSinglePage: true,
                      }}
                      rowKey="value"
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t("workflows.action")} key="2">
                <Text className="f-weight-500">{t("workflows.fields.approveCondition")}</Text>
                <Form.Item name="applyAcceptanceWhen">
                  <Radio.Group style={{ width: "100%" }} defaultValue={WorkflowDiagramApplyAcceptanceWhen.OnlyOne}>
                    <Radio style={{ width: "50%" }} value={WorkflowDiagramApplyAcceptanceWhen.OnlyOne}>
                      {t("workflows.fields.onlyOneApprove")}
                    </Radio>
                    <Radio value={WorkflowDiagramApplyAcceptanceWhen.All}>{t("workflows.fields.allApprove")}</Radio>
                  </Radio.Group>
                </Form.Item>
                <Row>
                  <Col span={24}>
                    <Space align="baseline">
                      <Text>{t("workflows.defaultStep")}</Text>
                      <Form.Item name="defaultNextStep">
                        <Select
                          className={classNameSelect(defaultNextStep?.type)}
                          style={{ width: "fit-content" }}
                          dropdownMatchSelectWidth={false}
                          placeholder={t("workflows.selectStep")}
                          options={nextStepOptions(otherSteps ?? [])}
                          onChange={(_, option) => {
                            form.setFieldsValue({
                              defaultNextStep: option,
                            });
                            onFormChange();
                          }}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
                <Text className="f-weight-500">
                  {t("workflows.settingOtherAction")}
                  <Tooltip placement="top" title={t("workflows.settingOtherActionHelper")}>
                    <Icons.QuestionCircleOutlined style={{ fontSize: 14, paddingLeft: 4 }} />
                  </Tooltip>
                </Text>
                <Form.Item valuePropName="checked" name="allowReturn" style={{ marginBottom: 0 }}>
                  <Checkbox>{t("workflows.fields.return")}</Checkbox>
                </Form.Item>
                <Form.Item valuePropName="checked" name="allowReject" style={{ marginBottom: 0 }}>
                  <Checkbox>{t("workflows.fields.reject")}</Checkbox>
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>
          </Col>
          <Col flex="80px" style={{ textAlign: "right" }}>
            <Divider type="horizontal" />
            <Button {...saveButtonProps} type="primary">
              {t("buttons.update")}
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        title=""
        visible={visibleConfirmModal}
        onOk={onRemoveSubject}
        onCancel={() => setVisibleConfirmModal(false)}
        okText={t("buttons.confirm")}
        okButtonProps={{
          danger: true,
        }}
        cancelText={t("buttons.reject")}
      >
        <Space align="start">
          <Text style={{ fontSize: 22 }} type="warning">
            <Icons.InfoCircleOutlined />
          </Text>
          <div>
            <Paragraph
              style={{
                fontWeight: 500,
                fontSize: 16,
                paddingTop: 5,
                marginBottom: 0,
              }}
            >
              {t("workflows.removeSubjectConfirmTitle")}
            </Paragraph>
          </div>
        </Space>
      </Modal>
    </>
  );
};

export default NormalDataNode;
