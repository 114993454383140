import { Modal, notification } from "@pankod/refine-antd";
import { useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { API_PATH, PATH } from "configs/path";
import { EstimateObjectData } from "interfaces/InternalEstimate";
import { FC, memo } from "react";

interface Props {
  record?: EstimateObjectData;
  visibleModal: boolean;
  toggleModal: () => void;
}
const ModalCloneInternalEstimate: FC<Props> = memo((props) => {
  const { toggleModal, visibleModal, record } = props;
  const translate = useTranslate();
  const { mutate, isLoading } = useUpdate();
  const { edit } = useNavigation();

  const onClone = () => {
    mutate(
      {
        resource: API_PATH.internalEstimateClone,
        id: record?.id!,
        values: {},
      },
      {
        onSuccess: (res: any) => {
          notification.success({
            message: translate("Thay đổi DTNB thành công"),
          });
          edit(PATH.internalEstimates, res?.data?.id);
          toggleModal();
        },
        onError: (error: any) => {
          toggleModal();

          notification.error({
            message: translate("Thay đổi DTNB thất bại"),
            description:
              !!error?.errors.length &&
              translate(
                `errors.${error?.errors?.[0]?.errorCode}`,
                error?.errors?.[0]?.value
              ),
          });
        },
      }
    );
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visibleModal}
      onOk={onClone}
      onCancel={toggleModal}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
      okText={translate("buttons.confirm")}
      cancelText={translate("buttons.cancel")}
      bodyStyle={{ padding: 0, margin: 0 }}
    >
      <div style={{ padding: "16px 16px 0 16px" }}>
        <strong>{translate("Bạn muốn thay đổi DTNB này?")}</strong>

        <div
          style={{
            margin: "8px 0 16px 0",
          }}
        >
          <div className="primary">{record?.code}</div>
        </div>
      </div>
    </Modal>
  );
});

export default ModalCloneInternalEstimate;
