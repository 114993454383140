import { CSSProperties } from "react";

export const layoutStyles: CSSProperties = {
    backgroundSize: "cover",
    background: 'white'
};

export const containerStyles: CSSProperties = {
    maxWidth: "408px",
    margin: "auto",
};

export const titleStyles: CSSProperties = {
    textAlign: "center",
    fontSize: "30px",
    letterSpacing: "-0.04em",
};

export const imageContainer: CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "28px",
};
