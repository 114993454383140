import { Icons, Modal, Space, Typography } from "@pankod/refine-antd";
import { FC, memo } from "react";
import { Props, useController } from "./controller";

const { Text, Paragraph } = Typography;
const ModalDelete: FC<Props> = memo((props) => {
  const {
    isLoading,
    onDelete,
    props: { toggleModal, visibleModal },
    translate,
  } = useController(props);

  return (
    <Modal
      title={translate("assignExploiting.removeExploit")}
      visible={visibleModal}
      onOk={onDelete}
      onCancel={toggleModal}
      okText={translate("buttons.confirm")}
      cancelText={translate("buttons.reject")}
      okButtonProps={{
        danger: true,
        loading: isLoading,
      }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Space align="start">
        <Text style={{ fontSize: 22 }} type="warning">
          <Icons.InfoCircleOutlined />
        </Text>
        <div>
          <Paragraph style={{ fontSize: 14 }}>
            {translate("assignExploiting.removeExploitConfirm")}
          </Paragraph>
        </div>
      </Space>
    </Modal>
  );
});

export default ModalDelete;
