import React from "react";
import { IResourceComponentsProps, useCreate, useTranslate, useNavigation } from "@pankod/refine-core";
import {
  DatePicker,
  Form,
  Input,
  useForm,
  notification,
  Radio,
  Typography,
  Col,
  Row,
  useSelect,
  Select,
  Icons
} from "@pankod/refine-antd";

import { IEmployee, ICategory, IFilterUser } from "interfaces";
import { PATH, API_PATH } from "configs/path";
import { useNavigate } from 'react-router-dom';
import { CreateCustom } from "components/layout";
import { DATE_FORMAT } from "configs/constants";
import { isValidEmail, formatTimeToUTC, formatOnlyDateToUTC, mappingErrorFromApi } from "utils/commons";
import { useLocationData } from "contexts/LocationsContext";
import { searchSelect } from "utils/commons"
import { SelectCustom } from "components/SelectCustom";
import InputUpper from "components/InputUpper";
import { showErrorToast } from "api/common";


const { Title, Text } = Typography;

export const EmployeeCreate: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();
  const { show } = useNavigation()
  const {
    provinces, getDistrictsByProvinceId, getWardsByDistrictId,
    getProvinceName, getDistrictName, getWardName
  } = useLocationData();
  const { mutate: mutateCreate, isLoading } = useCreate<any>();
  const { form, formProps, saveButtonProps } = useForm<IEmployee>();
  
  const [ visibleShowModal, setVisibleShowModal ] = React.useState<boolean>(false);
  const [ permenantProvinceId, setPermenantProvinceId ] = React.useState<string>('');
  const [ permenantDistrictId, setPermenantDistrictId ] = React.useState<string>('');
  const [ temporaryProvinceId, setTemporaryProvinceId ] = React.useState<string>('');
  const [ temporaryDistrictId, setTemporaryDistrictId ] = React.useState<string>('');

  const permenantDistricts =  getDistrictsByProvinceId(permenantProvinceId)
  const permenantWards =  getWardsByDistrictId(permenantDistrictId)
  const temporaryDistricts =  getDistrictsByProvinceId(temporaryProvinceId)
  const temporaryWards =  getWardsByDistrictId(temporaryDistrictId)

  const selectedUserEmail = form.getFieldValue('userTenantId')?.label;

  const { selectProps: departmentSelectProps } = useSelect<ICategory>({
    resource: API_PATH.departmentSelect,
    optionValue: 'id',
    optionLabel: 'name',
  });

  const onSubmit = () => {
    const valueForm: IEmployee = form.getFieldsValue() as unknown as IEmployee
    let birthdayUTCTime = null
    let joinDateUTCTime = null
    if (form.getFieldValue('birthday')) {
      birthdayUTCTime = formatOnlyDateToUTC(form.getFieldValue('birthday'))
    }

    if (form.getFieldValue('joinDate')) {
      joinDateUTCTime = formatOnlyDateToUTC(form.getFieldValue('joinDate'))
    }

    const permenantProvinceName = valueForm?.permenantProvinceId ? getProvinceName(valueForm?.permenantProvinceId) : null
    const permenantDistrictName = valueForm?.permenantDistrictId ? getDistrictName(valueForm?.permenantDistrictId, permenantDistricts) : null
    const permenantWardName = valueForm?.permenantWardId ? getWardName(valueForm?.permenantWardId, permenantWards) : null

    const temporaryProvinceName = valueForm?.temporaryProvinceId ? getProvinceName(valueForm?.temporaryProvinceId) : null
    const temporaryDistrictName = valueForm?.temporaryDistrictId ? getDistrictName(valueForm?.temporaryDistrictId, temporaryDistricts) : null
    const temporaryWardName = valueForm?.temporaryWardId ? getWardName(valueForm?.temporaryWardId, temporaryWards) : null

    const fullPermenantAddress = `${valueForm?.permenantAddress ? `${valueForm?.permenantAddress}` : ''}${(valueForm?.permenantAddress && permenantWardName) ? `,` : ''} ${permenantWardName ? `${permenantWardName},` : ''} ${permenantDistrictName ? `${permenantDistrictName},` : ''} ${permenantProvinceName ? `${permenantProvinceName}` : ''}`
    const fullTemporaryAddress = `${valueForm?.temporaryAddress ? `${valueForm?.temporaryAddress}` : ''}${(valueForm?.temporaryAddress && temporaryWardName) ? `,` : ''} ${temporaryWardName ? `${temporaryWardName},` : ''} ${temporaryDistrictName ? `${temporaryDistrictName},` : ''} ${temporaryProvinceName ? `${temporaryProvinceName}` : ''}`
    
    mutateCreate(
      {
        resource: PATH.employees,
        values: {
          ...valueForm,
          emailUser: form.getFieldValue('emailUser'),
          userTenantId: form.getFieldValue('userTenantId'),
          fullName: valueForm?.fullName ? valueForm?.fullName.trim() : '',
          code: valueForm?.code.trim(),
          birthday: birthdayUTCTime,
          joinDate: joinDateUTCTime,

          fullPermenantAddress: fullPermenantAddress.trim(),
          permenantDistrictId: valueForm?.permenantDistrictId,
          permenantProvinceId: valueForm?.permenantProvinceId,
          permenantWardId: valueForm?.permenantWardId,

          fullTemporaryAddress: fullTemporaryAddress.trim(),
          temporaryDistrictId: valueForm?.temporaryDistrictId,
          temporaryProvinceId: valueForm?.temporaryProvinceId,
          temporaryWardId: valueForm?.temporaryWardId,
        },
      },
      {
        onSuccess: (res) => {
          notification.success({
            message: t('employees.createSuccess')
          })
          show(PATH.employees, res?.data?.id)
        },
        onError: (error: any) => {
          setVisibleShowModal(false)
          const errorFieldMapping: any = {
            'ER041': 'userTenantId',
            'ER024': 'code',
            'ER064': 'idCard'
          }

          mappingErrorFromApi(error, form, errorFieldMapping )
        },
      },
    )
  }
 
  const renderCreateConfirm = () => {
    return selectedUserEmail ? (
      <div dangerouslySetInnerHTML={{__html: t('employees.createWithUserConfirm', {email: selectedUserEmail})}}></div>
    ) : '';
  }

  return (
    <CreateCustom
      {...props}
      visibleShowModal={visibleShowModal}
      setVisibleShowModal={(isShow: boolean) => setVisibleShowModal(isShow)}
      onSubmit={() => onSubmit()}
      saving={isLoading}
      saveButtonProps={{
        ...saveButtonProps,
        title: t('employees.create')
      }}
      confirmModalProps={{
        title: t('employees.createConfirm'),
        description: renderCreateConfirm()
      }}
    >
      <Title level={4}>{t('employees.employeesInfo')}</Title>
      <Form
        {...formProps}
        onFinish={() => setVisibleShowModal(true)}
        layout="vertical"
      >
        <Form.Item
          label={t("employees.fields.userTenantId")}
          name={["userTenantId"]}
        >
          <SelectCustom
            allowClear
            resource={API_PATH.usersSelect}
            optionLabel="emailUser"
            optionValue="userTenantId"
            onChange={(_, option)=> {
              const data = option as any
              form.setFieldsValue({
                userTenantId: data?.value,
                emailUser: data?.label
              })
            }}
            useServerFiltering={true}
          />
        </Form.Item>
        <Title level={5}>{t('employees.employeesInfo')}</Title>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
          <Col span={12}>
            <Form.Item
              label={t("employees.fields.code")}
              name="code"
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                },
                {
                  pattern: /^[A-Za-z0-9]+$/,
                  message: <div>
                    <p style={{marginBottom: 6}}>{t("errors.ER023")}</p>
                    <ul>
                      <li>{t("errors.ER023_1")}</li>
                      <li>{t("errors.ER023_2")}</li>
                    </ul>
                  </div>,
                },
                {
                  max: 50,
                  message: t("errors.ER014", { max: '50'}),
                },
              ]}
            >
              <InputUpper />
            </Form.Item>
            <Form.Item
              label={t("employees.fields.birthday")}
              name="birthday"
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                },
              ]}
          >
            <DatePicker placeholder={DATE_FORMAT} format={DATE_FORMAT} style={{width: '100%'}}/>
          </Form.Item>
          <Form.Item
              label={t("employees.fields.idCard")}
              name="idCard"
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                },
                {
                  pattern: /^[0-9]+$/,
                  message: t("errors.ER037")
                },
                {
                  min: 9,
                  message: t("errors.ER013", { min: '9'}),
                },

                {
                  max: 12,
                  message: t("errors.ER014", { max: '12'}),
                },
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label={t("employees.fields.lineManagerId")}
              name={["lineManagerId"]}
            >
              <SelectCustom
                allowClear
                resource={API_PATH.getLineManagerEmployees}
                optionValue="id"
                optionLabel="fullName"
                valueInObject={true}
                useServerFiltering={true}
              />
            </Form.Item>
            <Form.Item
              label={t("employees.fields.titleId")}
              name={["titleId"]}
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                }
              ]}
            >
              <SelectCustom
                allowClear
                resource={API_PATH.employeeTitlesDropdownList}
                label={''}
                optionLabel="label"
                optionValue="value"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("employees.fields.fullName")}
              name="fullName"
              rules={[
                {
                  required: true,
                  message: t("errors.ER005")
                },
                {
                  max: 300,
                  message: t("errors.ER014", { max: '300'}),
                },
                {
                  validator: async (_, value) => {
                    const g = /[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\]]/
                      if (g.test(value)) {
                        return Promise.reject(t("errors.ER050"))
                      }
                  }
                }
              ]}
            >
              <Input onBlur={(e) => {
                const value = e.target.value
                if (value) {
                  form.setFieldsValue({
                    fullName: value.toUpperCase()
                  })
                }
              }}/>
            </Form.Item>
            <Form.Item
              label={t("employees.fields.gender")}
              name="gender"
              initialValue={0}
              required
            >
              <Radio.Group>
                <Radio value={0}>{t('common.male')}</Radio>
                <Radio value={1}>{t('common.female')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t("employees.fields.emailEmployee")}
              name="emailEmployee"
              rules={[
                {
                  max: 255,
                  message: t("errors.ER014", { max: '255'}),
                },
                {
                  validator: async (_, value) => {
                      if (!value) return;
                      if (!isValidEmail(value)) {
                        return Promise.reject(t("errors.ER015"))
                      }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("employees.fields.departmentId")}
              name={["departmentId"]}
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                }
              ]}
            >
              <SelectCustom
                allowClear
                resource={API_PATH.departmentSelect}
              />
            </Form.Item>
            <Form.Item
              label={t("employees.fields.joinDate")}
              name="joinDate"
          >
            <DatePicker placeholder={DATE_FORMAT} format={DATE_FORMAT} style={{width: '100%'}}/>
          </Form.Item>
          </Col>
        </Row>
        <Title level={5}>{t('employees.permenantAddress')}</Title>
        <Form.Item
          label={t("employees.fields.address")}
          name="permenantAddress"
          rules={[
            {
              max: 300,
              message: t("errors.ER014", { max: '300'}),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
          <Col span={8}>
            <Form.Item
              label={t("employees.fields.provinceId")}
              name={["permenantProvinceId"]}
            >
              <Select
                options={provinces}
                showSearch
                allowClear
                placeholder={t('common.choose')}
                onChange={(value)=> {
                  const id = value as unknown as string || ''
                  form.setFieldsValue({
                    permenantDistrictId: null,
                    permenantWardId: null
                  })
                  setPermenantProvinceId(id)
                }}
                filterOption={
                  (inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label)
                  }
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("employees.fields.districtId")}
              name={["permenantDistrictId"]}
              rules={[
                {
                  validator: async (_, value) => {
                    if (form.getFieldValue('permenantProvinceId') && !value) {
                      return Promise.reject(t("errors.ER005"))
                    }
                  }
                }

              ]}
            >
              <Select
                options={permenantDistricts}
                allowClear
                showSearch
                placeholder={t('common.choose')}
                disabled={!form.getFieldValue('permenantProvinceId')}
                onChange={(value)=> {
                  const id = value as unknown as string || ''
                  setPermenantDistrictId(id)
                  form.setFieldsValue({
                    permenantWardId: null
                  })
                }}
                filterOption={
                  (inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label)
                  }
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("employees.fields.wardId")}
              name={["permenantWardId"]}
              rules={[
                {
                  validator: async (_, value) => {
                    if (form.getFieldValue('permenantDistrictId') && !value) {
                      return Promise.reject(t("errors.ER005"))
                    }
                  }
                }

              ]}
            >
              <Select
                options={permenantWards}
                allowClear
                showSearch
                placeholder={t('common.choose')}
                disabled={!form.getFieldValue('permenantDistrictId')}
                filterOption={
                  (inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label)
                  }
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Title level={5}>{t('employees.temporaryAddress')}</Title>
        <Form.Item
          label={t("employees.fields.address")}
          name="temporaryAddress"
          rules={[
            {
              max: 300,
              message: t("errors.ER014", { max: '300'}),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
          <Col span={8}>
            <Form.Item
              label={t("employees.fields.provinceId")}
              name={["temporaryProvinceId"]}
            >
              <Select
                options={provinces}
                allowClear
                showSearch
                placeholder={t('common.choose')}
                onChange={(e)=> {
                  const id = e as unknown as string
                  setTemporaryProvinceId(id)
                  form.setFieldsValue({
                    temporaryDistrictId: null,
                    temporaryWardId: null
                  })
                }}
                filterOption={
                  (inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label)
                  }
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("employees.fields.districtId")}
              name={["temporaryDistrictId"]}
              rules={[
                {
                  validator: async (_, value) => {
                    if (form.getFieldValue('temporaryProvinceId') && !value) {
                      return Promise.reject(t("errors.ER005"))
                    }
                  }
                }

              ]}
            >
              <Select
                options={temporaryDistricts}
                allowClear
                showSearch
                placeholder={t('common.choose')}
                disabled={!form.getFieldValue('temporaryProvinceId')}
                onChange={(e)=> {
                  const id = e as unknown as string
                  setTemporaryDistrictId(id)
                  form.setFieldsValue({
                    temporaryWardId: null
                  })
                }}
                filterOption={
                  (inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label)
                  }
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t("employees.fields.wardId")}
              name={["temporaryWardId"]}
              rules={[
                {
                    validator: async (_, value) => {
                        if (form.getFieldValue('temporaryDistrictId') && !value) {
                            return Promise.reject(t("errors.ER005"))
                        }
                    }
                }
              ]}
            >
              <Select
                options={temporaryWards}
                allowClear
                showSearch
                placeholder={t('common.choose')}
                disabled={!form.getFieldValue('temporaryDistrictId')}
                filterOption={
                  (inputValue: string, option: any) => {
                    return option && searchSelect(inputValue, option?.label)
                  }
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Title level={5}>{t('employees.personalInfo')}</Title>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
          <Col span={12}>
            <Form.Item
              label={t("employees.fields.emailPersonal")}
              name="emailPersonal"
              rules={[
                {
                  validator: async (_, value) => {
                      if (!value) return;
                      if (!isValidEmail(value)) {
                        return Promise.reject(t("errors.ER015"))
                      }
                  },
                },
                {
                  max: 255,
                  message: t("errors.ER014", { max: '255'}),
                },
              ]}
            >
              <Input />
            </Form.Item>
            
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("employees.fields.phoneNumber")}
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: t("errors.ER005"),
                },
                {
                  pattern: /^[0-9]+$/,
                  message: t("errors.ER037")
                },
                {
                  min: 10,
                  message: t("errors.ER013", { min: '10'}),
                },
                {
                  max: 13,
                  message: t("errors.ER014", { max: '13'}),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CreateCustom>
  );
};
