import { EXTERNAL_LOGIN } from "configs/storage";
import msalInstance, { msalConfig } from "configs/externalAuth";
import AzureLoginButton from "./AzureAdLoginButton";
import { MsalProvider } from "@azure/msal-react";

export interface ExternalLoginInformation {
    type?: 'azure' | 'google',
    page?: 'login' | 'reset-password'
    token?: string,
    email?: string,
    accessToken?: string,
    idToken?: string,
    refreshToken?: string,
    onLoginSuccess?: Function
}

export const ExternalLogin = (props: ExternalLoginInformation) => {
    const {
        type = 'azure',
        page = 'login',
        token,
        onLoginSuccess
    } = props
    
    const historyStorage = JSON.parse(localStorage.getItem(EXTERNAL_LOGIN) || "{}")
    if (!historyStorage.page || historyStorage.page !== page) {
        localStorage.setItem(EXTERNAL_LOGIN, JSON.stringify({ type: type, page: page, token: token }))
    }

    const onAuthenticated = (data: ExternalLoginInformation) => {
        if (onLoginSuccess) {
            onLoginSuccess(data)
        }
    }

    return (
        <>
            {
                type === "azure" &&
                <MsalProvider instance={msalInstance}>
                    <AzureLoginButton page={page} config={msalConfig} onSuccess={onAuthenticated} />
                </MsalProvider>
            }
        </>
    )
}
export default ExternalLogin
