import { notification, useForm } from "@pankod/refine-antd";
import { useCreate, useNavigation, useTranslate } from "@pankod/refine-core";
import { API_PATH, PATH } from "configs/path";
import { useState } from "react";
import { formatOnlyDateToUTC, mappingErrorFromApi } from "utils/commons";

export interface FormInterface {
  customerId: string;
  name: string;
  creationDate: Date;
  closedDate: Date;
  budgetEstimation: number;
  revenueEstimation: number;
  profitEstimation: number;
  description: string;
  products: {
    [key: string]: {
      productId: string;
      productName: string;
      unitId: string;
      quantity: number;
      unitPrice: number;
      partNumber: string;
      description: string;
      businessOppsProductId: string;
    };
  };
}

export const useController = () => {
  const { show } = useNavigation();
  const translate = useTranslate();

  const { form, formProps, saveButtonProps } = useForm<any, any, FormInterface>(
    {}
  );

  const { mutate: mutateCreate, isLoading } = useCreate<{ id: string }, any>();

  const onSubmit = () => {
    const dataForm = form.getFieldsValue();
    try {
      const businessOppsProduct = Object.values(dataForm.products || {});

      const dataToRequest = {
        ...dataForm,
        budgetEstimation: Number(dataForm?.budgetEstimation),
        revenueEstimation: Number(dataForm?.revenueEstimation),
        profitEstimation: Number(dataForm?.profitEstimation),
        creationDate: formatOnlyDateToUTC(dataForm.creationDate),
        closedDate: formatOnlyDateToUTC(dataForm.closedDate),
        businessOppsProduct,
      };

      mutateCreate(
        {
          resource: API_PATH.businessOpportunities,
          values: dataToRequest,
        },
        {
          onSuccess: (res) => {
            notification.success({
              message: translate(
                "businessOpportunities.notify.createSuccessful"
              ),
            });
            show(PATH.businessOpportunities, res?.data?.id);
          },
          onError: (error) => {
            toggleModalConfirm(false);
            const errorFieldMapping: any = {};
            notification.error({
              message: translate("businessOpportunities.notify.createFailed"),
            });
            mappingErrorFromApi(error, form, errorFieldMapping);
          },
        }
      );
    } catch (err) {
      //
    }
  };

  const [visibleModalConfirmCreate, setVisibleModalConfirmCreate] =
    useState(false);

  const toggleModalConfirm = (isOpen: boolean) =>
    setVisibleModalConfirmCreate(isOpen);

  return {
    visibleModalConfirmCreate,
    isLoading,
    saveButtonProps,
    form,
    formProps,
    translate,
    onSubmit,
    toggleModalConfirm,
  };
};
