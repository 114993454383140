import {
  Descriptions,
  Table,
  Icons,
  Space,
  Typography,
  Button,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import {
  PaymentBeneficiaryType,
  PaymentRequestType,
  ApprovalStatus,
  PaymentType,
  CurrencyType,
} from "api/enums";
import { LinkText } from "components";
import { ApprovalStatusLabel } from "components/ApprovalStatusLabel";
import { Currency } from "components/Currency";
import { CurrencyUnit } from "components/CurrencyUnit";
import { DataText } from "components/DataText";
import { onGetLinkView } from "components/GetLinkPreview";
import { DATE_TIME_FORMAT } from "configs/constants";
import { API_PATH, PATH } from "configs/path";
import { IPayment } from "interfaces";
import _ from "lodash";
import {
  formatISODateTimeToView,
  getSlugToRedirectWorkId,
} from "utils/commons";

const { Text } = Typography;

interface InformationTabProps {
  record?: IPayment;
}
export const Information = ({ record }: InformationTabProps) => {
  const t = useTranslate();
  const needPayMoreOrRefund =
    (record?.payMoreAmount && record?.payMoreAmount != 0) ||
    (record?.needRefundAmount && record?.needRefundAmount != 0);
  const {
    employee,
    department,
    supplier,
    beneficiaryType,
    advancePayment,
    requestType,
  } = record || {};
  const isInternal = beneficiaryType === PaymentBeneficiaryType.Internal;
  const isInDependencyPayment = advancePayment == null;
  const isPayment = record?.requestType === PaymentRequestType.Payment;
  const isRefund = record?.requestType === PaymentRequestType.Refund;

  return (
    <Descriptions
      column={{ lg: 3, xs: 1 }}
      title={t("payments.fields.infoMain")}
      layout="vertical"
    >
      <Descriptions.Item
        span={isInDependencyPayment ? 1 : 3}
        labelStyle={{ fontWeight: 500 }}
        label={t("payments.fields.type")}
      >
        {isPayment && t("payments.type.payment")}
        {isRefund && t("payments.type.refund")}
      </Descriptions.Item>
      {isInDependencyPayment && (
        <Descriptions.Item
          span={2}
          labelStyle={{ fontWeight: 500 }}
          label={t("payments.fields.case")}
        >
          <LinkText
            value={{ ...record?.case, code: record?.case?.name }}
            resource={getSlugToRedirectWorkId(record?.case?.type)}
            showTooltip={false}
          />
        </Descriptions.Item>
      )}

      <Descriptions.Item
        labelStyle={{ fontWeight: 500 }}
        label={t("payments.fields.code")}
      >
        <DataText value={record?.code} />
      </Descriptions.Item>

      <Descriptions.Item
        labelStyle={{ fontWeight: 500 }}
        label={t("payments.fields.name")}
      >
        <DataText value={record?.name} />
      </Descriptions.Item>
      <Descriptions.Item
        span={record?.status === ApprovalStatus.Draft ? 2 : 1}
        labelStyle={{ fontWeight: 500 }}
        label={t("payments.fields.status")}
      >
        <ApprovalStatusLabel status={record?.status as any} />
      </Descriptions.Item>

      {isInDependencyPayment && (
        <>
          <Descriptions.Item
            labelStyle={{ fontWeight: 500 }}
            label={t("payments.fields.beneficiaryType")}
          >
            {isInternal
              ? t("common.beneficiaryType.internal")
              : t("common.beneficiaryType.supplier")}
          </Descriptions.Item>
          {isInDependencyPayment && !isInternal && (
            <Descriptions.Item
              span={2}
              labelStyle={{ fontWeight: 500 }}
              label={t("payments.fields.supplier")}
            >
              <DataText value={supplier?.name} />
            </Descriptions.Item>
          )}
          {isInDependencyPayment && isInternal && (
            <>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label={t("payments.fields.employee")}
              >
                <DataText
                  value={
                    record?.employee
                      ? `${employee?.code} - ${employee?.name}`
                      : undefined
                  }
                />
              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label={t("payments.fields.department")}
              >
                <DataText value={department?.name} />
              </Descriptions.Item>
            </>
          )}
        </>
      )}

      <Descriptions.Item
        span={3}
        labelStyle={{ fontWeight: 500 }}
        label={t("payments.fields.description")}
      >
        <DataText value={record?.description} />
      </Descriptions.Item>
      {isInDependencyPayment && (
        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: 500 }}
          label={t("payments.fields.currency")}
        >
          <CurrencyUnit currencyType={record?.currencyType} />
        </Descriptions.Item>
      )}

      {!isInDependencyPayment && (
        <Descriptions.Item
          span={3}
          labelStyle={{ fontWeight: 500 }}
          label={t("payments.fields.prepay")}
        >
          <Text>
            {record?.advancePayment?.code} - {record?.advancePayment?.name}
          </Text>
        </Descriptions.Item>
      )}
      {record?.refundPaymentListCosts &&
        record.refundPaymentListCosts.length > 0 && (
          <Descriptions.Item span={3} labelStyle={{ fontWeight: 500 }}>
            <PrepayInfo record={record} />
          </Descriptions.Item>
        )}

      {((record?.payMoreAmount || 0) > 0 || isInDependencyPayment) && (
        <>
          <Descriptions.Item
            span={3}
            labelStyle={{ fontWeight: 500 }}
            label={t("payments.fields.refundType")}
          >
            {record?.refundType == null && <DataText value={undefined} />}
            {record?.refundType === PaymentType.Banking &&
              t("common.paymentType.banking")}
            {record?.refundType === PaymentType.Cash &&
              t("common.paymentType.cash")}
          </Descriptions.Item>
          {record?.refundType === PaymentType.Banking && (
            <>
              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label={t("payments.fields.bankAccountName")}
              >
                <DataText value={record?.bank?.bankAccountName} />
              </Descriptions.Item>

              <Descriptions.Item
                span={2}
                labelStyle={{ fontWeight: 500 }}
                label={t("payments.fields.bankAccountNumber")}
              >
                <DataText value={record?.bank?.bankAccountNumber} />
              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontWeight: 500 }}
                label={t("payments.fields.bank")}
              >
                <DataText value={record?.bank?.bankName} />
              </Descriptions.Item>

              <Descriptions.Item
                span={2}
                labelStyle={{ fontWeight: 500 }}
                label={t("payments.fields.branchBank")}
              >
                <DataText value={record?.bank?.bankBranch} />
              </Descriptions.Item>
            </>
          )}
        </>
      )}

      {isRefund && (
        <Descriptions.Item
          span={1}
          labelStyle={{ fontWeight: 500 }}
          label={t("payments.fields.totalNumber")}
        >
          <Currency
            customStyles={{ color: "black", fontWeight: 500 }}
            value={record?.totalAmount || 0}
            currencyType={record?.currencyType || CurrencyType.VND}
          />
        </Descriptions.Item>
      )}
      {isRefund && (
        <Descriptions.Item
          span={2}
          labelStyle={{ fontWeight: 500 }}
          label={t("payments.fields.totalWord")}
        >
          <DataText value={record?.realTotalAmountWord} />
        </Descriptions.Item>
      )}
      {record?.attachments && record.attachments.length > 0 && (
        <Descriptions.Item span={3} labelStyle={{ fontWeight: 500 }}>
          <Attachments record={record} />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

interface AttachmentsProps {
  record?: IPayment;
}
const Attachments = ({ record }: AttachmentsProps) => {
  const list = record?.attachments || [];
  const t = useTranslate();
  if (list.length === 0) {
    return <div />;
  }
  return (
    <Table
      style={{ width: "100%" }}
      size="small"
      rowKey="fileUrlOrigin"
      dataSource={list}
      locale={{ emptyText: " " }}
      pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
    >
      <Table.Column
        dataIndex="cost"
        title={t("payments.fields.attachment")}
        render={(v: any, item: any) => {
          return item.fileUrlOrigin;
        }}
      />

      <Table.Column
        title={t("table.actions")}
        width="120px"
        align="center"
        dataIndex="action"
        render={(v: any, item: any) => {
          return (
            <Button
              type="text"
              icon={<Icons.EyeOutlined />}
              className="primary"
              onClick={onGetLinkView({ fileUrl: item.fileUrl, path: API_PATH.paymentsDocumentsView })}
            />
          );
        }}
      />
    </Table>
  );
};

interface PrepayInfoProps {
  record?: IPayment;
}
const PrepayInfo = ({ record }: PrepayInfoProps) => {
  let list = (record?.refundPaymentListCosts || []).concat({ id: "total" });
  list = _.orderBy(list, "ordinal", "asc");

  const isEmptyCost = (record?.refundPaymentListCosts || []).length === 0;
  const {
    employee,
    department,
    supplier,
    beneficiaryType,
    advancePayment,
    requestType,
  } = record || {};

  const isInternal = beneficiaryType === PaymentBeneficiaryType.Internal;
  const isInDependencyPayment = advancePayment == null;
  const isPayment = requestType === PaymentRequestType.Payment;
  const isRefund = requestType === PaymentRequestType.Refund;
  const needPayMoreOrRefund =
    (record?.payMoreAmount && record?.payMoreAmount != 0) ||
    (record?.needRefundAmount && record?.needRefundAmount != 0);

  const t = useTranslate();

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        background: !isInDependencyPayment ? "#F5F5F5" : "transparent",
        padding: !isInDependencyPayment ? 12 : 0,
        marginBottom: 24,
      }}
    >
      {!isInDependencyPayment && (
        <>
          <Descriptions
            column={3}
            title=""
            layout="vertical"
            className="no-padding"
          >
            {record?.case && (
              <Descriptions.Item
                span={3}
                labelStyle={{ fontWeight: 500 }}
                label={t("payments.fields.case")}
              >
                <LinkText
                  value={{ ...record?.case, code: record?.case?.name }}
                  resource={getSlugToRedirectWorkId(record?.case?.type)}
                  showTooltip={false}
                />
              </Descriptions.Item>
            )}

            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={t("payments.fields.code")}
            >
              <DataText value={record?.advancePayment?.code} />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={t("payments.fields.name")}
            >
              <DataText value={record?.advancePayment?.name} />
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={t("payments.fields.time")}
            >
              <DataText
                value={
                  record?.advancePayment?.createdDate
                    ? formatISODateTimeToView(
                      record?.advancePayment?.createdDate as any,
                      DATE_TIME_FORMAT
                    )
                    : ""
                }
              />
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={t("payments.fields.beneficiaryType")}
            >
              {isInternal
                ? t("common.beneficiaryType.internal")
                : t("common.beneficiaryType.supplier")}
            </Descriptions.Item>

            {!isInternal && (
              <Descriptions.Item
                span={2}
                labelStyle={{ fontWeight: 500 }}
                label={t("payments.fields.supplier")}
              >
                <DataText value={supplier?.name} />
              </Descriptions.Item>
            )}
            {isInternal && (
              <>
                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label={t("payments.fields.employee")}
                >
                  <DataText
                    value={
                      record?.employee
                        ? `${employee?.code} - ${employee?.name}`
                        : undefined
                    }
                  />
                </Descriptions.Item>

                <Descriptions.Item
                  labelStyle={{ fontWeight: 500 }}
                  label={t("payments.fields.department")}
                >
                  <DataText value={department?.name} />
                </Descriptions.Item>
              </>
            )}

            <Descriptions.Item
              labelStyle={{ fontWeight: 500 }}
              label={t("payments.fields.currency")}
            >
              <CurrencyUnit
                currencyType={record?.advancePayment?.currencyType}
              />
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
      {!isEmptyCost && (
        <>
          <Text style={{ fontWeight: 500 }}>
            {t("payments.fields.costList")}
          </Text>

          <Table
            style={{ width: "100%" }}
            size="small"
            locale={{ emptyText: " " }}
            rowKey="id"
            dataSource={list}
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
          >
            <Table.Column
              dataIndex="cost"
              title={t("payments.costs.name")}
              width={"25%"}
              render={(v: any, item: any) => {
                if (item.id === "total") {
                  return (
                    <Space direction="vertical" align="start">
                      <Text style={{ fontWeight: 500 }}>
                        {t("payments.costs.total")}
                      </Text>
                      {isInDependencyPayment && (
                        <Text style={{ fontWeight: 500 }}>
                          {t("payments.costs.totalWord")}
                        </Text>
                      )}
                    </Space>
                  );
                }
                return (
                  <DataText
                    value={`${item.cost?.code} - ${item.cost?.name}`}
                    defaultValue="-"
                  />
                );
              }}
            />
            {!isInDependencyPayment && (
              <Table.Column
                dataIndex="cost"
                title={(isPayment ? "[1] " : "") + t("payments.costs.prepays")}
                align="right"
                width={"25%"}
                render={(v: any, item: any) => {
                  if (item.id === "total") {
                    return (
                      <Space direction="vertical" align="start">
                        <Text strong>
                          <Currency
                            strong
                            value={record?.totalAmount || 0}
                            currencyType={
                              record?.currencyType || CurrencyType.VND
                            }
                          />
                        </Text>
                        {!isRefund && (
                          <Text strong>
                            {record?.realTotalAmountWord || " "}
                          </Text>
                        )}
                      </Space>
                    );
                  } else {
                    return (
                      <Currency
                        value={item.money}
                        currencyType={record?.currencyType || CurrencyType.VND}
                      />
                    );
                  }
                }}
              />
            )}

            {!isRefund && (
              <Table.Column
                dataIndex="cost"
                title={
                  (!isInDependencyPayment ? "[2] " : "") +
                  t("payments.costs.moneyReal")
                }
                align="right"
                width={"25%"}
                render={(v: any, item: any) => {
                  if (item.id === "total") {
                    return (
                      <Space direction="vertical" align="end">
                        <Currency
                          strong
                          value={record?.realTotalAmount || 0}
                          currencyType={
                            record?.currencyType || CurrencyType.VND
                          }
                        />
                        {isInDependencyPayment && (
                          <DataText
                            value={record?.realTotalAmountWord}
                            defaultValue="-"
                          />
                        )}
                      </Space>
                    );
                  }
                  return (
                    <Currency
                      value={item.realMoney}
                      currencyType={record?.currencyType || CurrencyType.VND}
                    />
                  );
                }}
              />
            )}

            {!isRefund && (
              <Table.Column
                title={t("payments.costs.note")}
                width={"25%"}
                dataIndex="note"
                render={(v: any, item: any) => {
                  if (item.id === "total") return "";
                  return <DataText value={item.note} defaultValue="-" />;
                }}
              />
            )}
          </Table>
          {isPayment && !isInDependencyPayment && (
            <Space direction="vertical">
              <Space>
                <Text style={{ fontWeight: 500 }}>
                  {t("payments.costs.totalRefund")}:
                </Text>
                <Currency
                  customStyles={{ color: "black" }}
                  strong
                  value={record?.needRefundAmount}
                  currencyType={record?.currencyType || CurrencyType.VND}
                />
              </Space>

              <Space>
                <Text style={{ fontWeight: 500 }}>
                  {t("payments.costs.totalPayment")}:
                </Text>
                <Currency
                  strong
                  customStyles={{ color: "black" }}
                  value={record?.payMoreAmount}
                  currencyType={record?.currencyType || CurrencyType.VND}
                />
              </Space>
            </Space>
          )}
        </>
      )}
    </Space>
  );
};
