import { BrowserCacheLocation, Configuration, PublicClientApplication } from "@azure/msal-browser";
import { PATH } from "./path";

const callbackUrl = `${process.env.REACT_APP_PUBLIC_URL || ''}${PATH.externalLoginCallback}`

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_ApplicationID || '',
        authority: `${process.env.REACT_APP_AZURE_Instance}common`,
        redirectUri: callbackUrl,
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false,
    },
    scopes: process.env.REACT_APP_AZURE_LOGIN_SCOPES?.split(',') || [],
}
export const msalInstance = new PublicClientApplication(msalConfig)

export default msalInstance