import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "@pankod/refine-antd";
import { FC, memo } from "react";
import {
  isValidEmail,
  normalizeInputForm,
  normalizeNumber,
  searchSelect,
} from "utils/commons";
import { Props, useController } from "./controller";

const { Text } = Typography;

const CustomerForm: FC<Props> = memo((props) => {
  const {
    formProps,
    districts,
    propvinceSelectProps,
    provinceValue,
    wards,
    districtValue,
    businessIndustrySelectProps,
    isHasBillInfomation,
    isEdit,
    isAssigned,
    isDraft,
    onFinishedFormValidate,
    translate,
  } = useController(props);

  return (
    <Form {...formProps} onFinish={onFinishedFormValidate} layout="vertical">
      <Form.Item name={"status"} className="hidden" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        {isEdit && (
          <Col xs={24} lg={12}>
            <Form.Item label={translate("customer.customerCode")} name={"code"}>
              <Input disabled />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} lg={12} style={{ marginBottom: 24 }}>
          <Form.Item
            label={translate("customer.customerName")}
            name={"name"}
            rules={[
              { required: true, message: translate("errors.ER005") },
              { max: 100, message: translate("errors.ER014", { max: 100 }) },
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input />
          </Form.Item>
          <Text type="secondary">
            {translate("customer.warningWhenEnterCustomerName")}
          </Text>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={translate("customer.abbreviationName")}
            name={"abbreviationName"}
            rules={[
              { required: !isEdit, message: translate("errors.ER005") },
              { max: 10, message: translate("errors.ER014", { max: 10 }) },
            ]}
          >
            <Input disabled={!isDraft} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={translate("customer.taxCode")}
            name={"identifiedtaxcode"}
            rules={[
              { required: true, message: translate("errors.ER005") },
              { pattern: /^[0-9-]+$/, message: translate("errors.ER0101") },
              {
                min: 10,
                message: translate("errors.ER013", { min: "10" }),
              },
              {
                max: 14,
                message: translate("errors.ER014", { max: "14" }),
              },
            ]}
          >
            <Input disabled={!isDraft} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label={translate("customer.phoneNumber")}
            name={"phoneNumber"}
            rules={[
              {
                required: true,
                message: translate("errors.ER005"),
              },
              {
                pattern: /^[0-9]+$/,
                message: translate("errors.ER037"),
              },
              {
                min: 10,
                message: translate("errors.ER013", { min: "10" }),
              },
              {
                max: 11,
                message: translate("errors.ER014", { max: "11" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={translate("customer.specificAddress")} name={"address"}>
        <Input />
      </Form.Item>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={translate("customer.provinceCity")}
            name={"provinceId"}
            rules={[{ required: true, message: translate("errors.ER005") }]}
          >
            <Select
              {...propvinceSelectProps}
              disabled={isAssigned}
              filterOption={(inputValue: string, option: any) => {
                return option && searchSelect(inputValue, option?.label);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={translate("customer.district")}
            name={["districtId"]}
          >
            <Select
              disabled={!provinceValue}
              options={districts}
              showSearch
              filterOption={(inputValue: string, option: any) =>
                option && searchSelect(inputValue, option?.label)
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label={translate("customer.ward")} name={["wardId"]}>
            <Select
              disabled={!districtValue || !provinceValue}
              options={wards}
              showSearch
              filterOption={(inputValue: string, option: any) =>
                option && searchSelect(inputValue, option?.label)
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={translate("customer.businessScope")}
            name={"businessIndustryId"}
            rules={[{ required: true, message: translate("errors.ER005") }]}
          >
            <Select
              {...businessIndustrySelectProps}
              disabled={isAssigned}
              filterOption={(inputValue: string, option: any) => {
                return option && searchSelect(inputValue, option?.label);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label={translate("customer.email")}
            name={"email"}
            rules={[
              {
                max: 200,
                message: translate("errors.ER014", { max: "200" }),
              },
              {
                validator: async (_, value) => {
                  if (!value) return;
                  if (!isValidEmail(value)) {
                    return Promise.reject(translate("errors.ER015"));
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            colon={false}
            rules={[
              {
                max: 200,
                message: translate("errors.ER014", { max: "200" }),
              },
            ]}
            normalize={normalizeInputForm}
            label={translate("customer.website")}
            name={["website"]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label={translate("customer.debtMaturityDate")}
            name={"paymentterm"}
            normalize={normalizeNumber}
          >
            <Input className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="hasBillingInfo"
        valuePropName="checked"
        labelAlign="left"
      >
        <Checkbox>{translate("customer.billingInformation")}</Checkbox>
      </Form.Item>
      {isHasBillInfomation && (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={translate("customer.unitName")}
                name={"nameInBillingInfo"}
                rules={[
                  { required: true, message: translate("errors.ER005") },
                  {
                    max: 200,
                    message: translate("errors.ER014", { max: "200" }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={translate("customer.taxCode")}
                name={"taxCodeInBillingInfo"}
                rules={[
                  { required: true, message: translate("errors.ER005") },
                  { pattern: /^[0-9-]+$/, message: translate("errors.ER0101") },
                  {
                    min: 10,
                    message: translate("errors.ER013", { min: "10" }),
                  },
                  {
                    max: 14,
                    message: translate("errors.ER014", { max: "14" }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 80 }}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={translate("customer.phoneNumber")}
                name={"phoneNumberInBillingInfo"}
                rules={[
                  { required: true, message: translate("errors.ER005") },
                  {
                    pattern: /^[0-9]+$/,
                    message: translate("errors.ER037"),
                  },
                  {
                    min: 10,
                    message: translate("errors.ER013", { min: "10" }),
                  },
                  {
                    max: 11,
                    message: translate("errors.ER014", { max: "11" }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={translate("customer.email")}
                name={"emailInBillingInfo"}
                rules={[
                  { required: true, message: translate("errors.ER005") },
                  {
                    max: 200,
                    message: translate("errors.ER014", { max: "200" }),
                  },
                  {
                    validator: async (_, value) => {
                      if (!value) return;
                      if (!isValidEmail(value)) {
                        return Promise.reject(translate("errors.ER015"));
                      }
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={translate("customer.address")}
            name={"addressInBillingInfo"}
            rules={[
              { required: true, message: translate("errors.ER005") },
              {
                max: 200,
                message: translate("errors.ER014", { max: "200" }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}
    </Form>
  );
});

export default CustomerForm;
