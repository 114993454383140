import { Form, FormInstance, FormProps, useSelect } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { API_PATH } from "configs/path";
import { v4 as uuid } from "uuid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";

export interface Props {
  form: FormInstance<{}>;
  formProps: FormProps;
  isEdit?: boolean;
  onFinishedFormValidate?: () => void;
}

export const useController = (props: Props) => {
  const { form, formProps, onFinishedFormValidate } = props;
  const translate = useTranslate();

  const isEdit = formProps.initialValues?.id;

  const { useWatch } = Form;

  const { selectProps: employeeSelectProps } = useSelect({
    resource: API_PATH.employeesForAssignExploiting,
    optionLabel: "label",
    optionValue: "id",
    metaData: {
      formatData: (emp: any) => ({
        ...emp,
        label: `${emp.code} - ${emp.fullName}`,
      }),
    },
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value: value,
      },
    ],
  });

  const [listProduct, setListProduct] = useState<string[]>([uuid()]);

  const [listCustomer, setListCustomer] = useState<string[]>([uuid()]);

  const products = useWatch("products", form);

  const customers = useWatch("customers", form);

  const assignExploitingType = useWatch("assignExploitingType", form);

  const isProduct = assignExploitingType === 2;

  const [_, setItems] = useState<any>({});

  const list = isProduct ? listProduct : listCustomer;

  const setList = isProduct ? setListProduct : setListCustomer;

  const onChangeItem =
    (keyRow: string) => (_: any, __: any, selectedItem: any) => {
      const keyChange = isProduct ? "products" : "customers";
      const newList = cloneDeep(form.getFieldValue(keyChange));

      if (keyChange === "products") {
        newList[keyRow] = {
          ...newList[keyRow],
          id: selectedItem?.id,
          name: selectedItem?.name,
          code: selectedItem?.code,
          productGroup: selectedItem?.productGroup?.name,
        };
      } else {
        newList[keyRow] = {
          ...newList[keyRow],
          id: selectedItem?.id,
          name: selectedItem?.name,
          code: selectedItem?.code,
          province: selectedItem?.province?.name,
          businessIndustry: selectedItem?.businessIndustry?.name,
          marketArea: selectedItem?.businessIndustry?.marketArea?.name,
        };
      }

      form.setFieldsValue({ [keyChange]: newList });
      setItems(newList);
    };

  const onRemoveItem = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  const { initialValues = {} } = formProps;

  const transformDefaultProductToFormProduct = useMemo(() => {
    const products: { [key: string]: any } = {};
    initialValues?.productOwner?.forEach((product: any) => {
      products[product.id] = {
        code: product?.code,
        id: product?.id,
        name: product?.name,
        productGroup: product?.productGroup?.name,
      };
    });

    return products;
  }, [initialValues]);

  const transformDefaultCustomerToFormCustomer = useMemo(() => {
    const customers: { [key: string]: any } = {};
    initialValues?.customer?.forEach((customer: any) => {
      customers[customer.id] = {
        id: customer?.id,
        name: customer?.name,
        code: customer?.code,
        province: customer?.province?.name,
        businessIndustry: customer?.businessIndustry?.name,
        marketArea: customer?.businessIndustry?.marketArea?.name,
      };
    });

    return customers;
  }, [initialValues]);

  const isShowButtonRemoveItem = useMemo(() => {
    return (
      (isProduct && listProduct.length === 1) ||
      (!isProduct && listCustomer.length === 1)
    );
  }, [isProduct, listProduct, listCustomer]);

  useEffect(() => {
    if (isEdit) {
      initialValues?.assignExploitingType?.id === 1
        ? setListCustomer(
            initialValues?.customer.length
              ? initialValues?.customer?.map((p: any) => p?.id)
              : [uuid()]
          )
        : setListProduct(
            initialValues?.productOwner.length
              ? initialValues?.productOwner?.map((p: any) => p?.id)
              : [uuid()]
          );
    }
  }, [initialValues, isEdit]);

  const AssignExploitingList = useMemo(
    () => [
      { label: translate(`assignExploiting.selectorType.1`), value: 1 },
      { label: translate(`assignExploiting.selectorType.2`), value: 2 },
    ],
    [translate]
  );

  const productIdSelecteds = useMemo(
    () =>
      products ? Object.values(products).map((product: any) => product.id) : [],
    [products]
  );

  const productOptions = useCallback(
    (options: any[], value?: string) =>
      options.map((o) => ({
        ...o,
        label: o.name,
        value: o.id,
        disabled: productIdSelecteds
          .filter((productId) => productId !== value)
          .includes(o.id),
      })),
    [productIdSelecteds]
  );

  const productSelecteds = useMemo(
    () =>
      customers
        ? Object.values(customers).map((customer: any) => customer.id)
        : [],
    [customers]
  );

  const customertOptions = useCallback(
    (options: any[], value?: string) =>
      options.map((o) => ({
        ...o,
        label: o.name,
        value: o.id,
        disabled: productSelecteds
          .filter((customerId) => customerId !== value)
          .includes(o.id),
      })),
    [productSelecteds]
  );

  return {
    products,
    customers,
    formProps,
    initialValues,
    transformDefaultProductToFormProduct,
    transformDefaultCustomerToFormCustomer,
    employeeSelectProps,
    isEdit,
    isProduct,
    list,
    isShowButtonRemoveItem,
    translate,
    onChangeItem,
    onFinishedFormValidate,
    setList,
    onRemoveItem,
    AssignExploitingList,
    productOptions,
    customertOptions,
  };
};
