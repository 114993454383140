import { Descriptions, Tag } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { DataText, LinkText } from "components";
import { memo, useContext } from "react";
import { ProjectShowContext, getStatus } from "../..";
import { SLUGS } from "configs/path";
import { formatISODateTimeToView } from "utils/commons";
import { DATE_FORMAT } from "configs/constants";
import { Currency } from "components/Currency";

const ProjectInfo = memo(() => {
  const record = useContext(ProjectShowContext);
  const translate = useTranslate();
  const { case: caseDetail, projectStatus, code } = record || {};

  return (
    <Descriptions
      column={3}
      title={translate("Thông tin dự án")}
      layout="vertical"
      style={{ background: "white", padding: 24 }}
    >
      <Descriptions.Item
        label={translate("MVV kinh doanh")}
        labelStyle={{ fontWeight: 500 }}
        span={2}
      >
        <LinkText
          value={{ id: caseDetail?.id }}
          defaultValue={
            !!caseDetail && `${caseDetail?.code} - ${caseDetail?.name}`
          }
          resource={SLUGS.caseForSale}
          showFullInfo={false}
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Trạng thái")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <Tag {...getStatus(projectStatus?.code)}>{projectStatus?.name}</Tag>
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Mã dự án")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText value={code} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Ngày bắt đầu")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText
          value={formatISODateTimeToView(record?.startDate, DATE_FORMAT)}
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Ngày kết thúc")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <DataText
          value={formatISODateTimeToView(record?.endDate, DATE_FORMAT)}
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Tổng CP nhân công")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <Currency value={record?.totalLaborCost} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Tổng CP bằng tiền")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <Currency value={record?.totalPrice} />
      </Descriptions.Item>
      <Descriptions.Item
        label={translate("Tổng giá trị dự án")}
        labelStyle={{ fontWeight: 500 }}
        span={1}
      >
        <Currency value={record?.totalProjectValue} />
      </Descriptions.Item>
    </Descriptions>
  );
});

export default ProjectInfo;
