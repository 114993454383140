import {
  Descriptions,
  Form,
  Icons,
  Input,
  useSelect,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { Button, Collapse, Select, Table, Typography } from "antd";
import { DataText } from "components";
import { API_PATH } from "configs/path";
import { useFormRule } from "hooks/useFormRule";
import { FC, memo, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { isEmpty, searchSelect } from "utils/commons";
import { v4 as uuid } from "uuid";
import { IForm, InternalEstimateFormContext } from "../../..";
import InputMoney from "pages/sale/businessOpportunities/create/components/MoneyInput";
import { Currency } from "components/Currency";
import ButtonConfirm from "components/ButtonConfirm";
import { cloneDeep } from "lodash";
import { EstimateType } from "interfaces/InternalEstimate";

const { Panel } = Collapse;
const { Text } = Typography;
const { TextArea } = Input;
const { useWatch, useFormInstance } = Form;

export const PanelStyle = styled(Panel)`
  .ant-collapse-header-text {
    font-size: 16px;
  }
`;

const CostTable: FC = memo(() => {
  const { cost, totalCostByMoney: total } = useContext(
    InternalEstimateFormContext
  );
  const [list, setList] = cost;
  const { required, max } = useFormRule();
  const translate = useTranslate();
  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse((prev) => !prev);
  const form = useFormInstance<IForm>();
  const listInternalEstimatedCost = useWatch("listInternalEstimatedCost", form);
  const isAllProducts = useWatch("isAllProducts", form);
  const internalEstimateCode = useWatch("internalEstimateCode", form);

  const listInternalEstimateProduct = useWatch(
    "listInternalEstimateProduct",
    form
  );

  const productIdSelecteds = Object.values(listInternalEstimateProduct || {})
    .map((p) => p.productId)
    .filter(Boolean);

  const { selectProps: productSelectProps } = useSelect({
    resource: API_PATH.interalEstimateProductDropdownlist,
    optionLabel: "label",
    optionValue: "id",
    metaData: {
      formatData: (r: any) => ({
        ...r,
        label: `${r.code} - ${r.name}`,
      }),
    },
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const productOption = useMemo(
    () =>
      productSelectProps?.options?.filter((o) =>
        productIdSelecteds.includes(o.value as any)
      ),
    [productSelectProps, productIdSelecteds]
  );

  const { selectProps: costSelectProps } = useSelect({
    resource: API_PATH.internalEstimateCostDropdownlist,
    optionLabel: "label",
    optionValue: "value",
    metaData: {
      formatData: (r: any) => ({
        ...r,
        label: `${r.code} - ${r.label}`,
      }),
    },
    onSearch: () => [
      {
        field: "q",
        operator: "eq",
        value: undefined,
      },
    ],
  });

  const onRemoveProduct = (index: number) => () => {
    const newList = cloneDeep(list);
    newList.splice(index, 1);
    setList(newList);
  };

  return (
    <Collapse
      collapsible="header"
      className="w-full"
      defaultActiveKey={["1"]}
      ghost
      onChange={toggleCollapse}
    >
      <PanelStyle
        header={translate("Chi phí bằng tiền")}
        style={{ fontSize: 16 }}
        key="1"
        extra={
          collapse && (
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                setList([...list, uuid()]);
              }}
              icon={<Icons.PlusOutlined />}
              disabled={
                internalEstimateCode === EstimateType.Estimated_Consulting
              }
            >
              {translate("actions.create")}
            </Button>
          )
        }
      >
        <Table<String>
          style={{ marginTop: 8 }}
          size="small"
          className="vertical-align-top w-full"
          dataSource={list}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 1000,
          }}
          locale={{
            emptyText: " ",
          }}
          scroll={{ x: 1500 }}
        >
          <Table.Column
            title={translate("common.no")}
            dataIndex="name"
            width="50px"
            render={(v, keyRow: string, index) => {
              return <Text>{index + 1}</Text>;
            }}
          />
          <Table.Column
            title={translate("Sản phẩm")}
            dataIndex="productId"
            width="200px"
            render={(v, keyRow: string, index) => {
              const isPolicy =
                !!listInternalEstimatedCost?.[keyRow]?.orderSort &&
                !isAllProducts;
              const productName =
                listInternalEstimatedCost?.[keyRow]?.productName;
              return (
                <>
                  {isAllProducts
                    ? translate("Tất cả sản phẩm")
                    : isPolicy
                    ? productName
                    : null}
                  <Form.Item
                    hidden
                    name={["listInternalEstimatedCost", keyRow, "productName"]}
                  />
                  <Form.Item
                    name={["listInternalEstimatedCost", keyRow, "productId"]}
                    className="m-0"
                    rules={isAllProducts || isPolicy ? undefined : required}
                    hidden={isPolicy || isAllProducts}
                  >
                    <Select
                      {...productSelectProps}
                      options={productOption}
                      placeholder={translate("Chọn sản phẩm")}
                      filterOption={(inputValue: string, option: any) => {
                        return (
                          option && searchSelect(inputValue, option?.label)
                        );
                      }}
                    />
                  </Form.Item>
                </>
              );
            }}
          />
          <Table.Column
            title={translate("Tên chi phí")}
            dataIndex="costId"
            width="200px"
            render={(v, keyRow: string, index) => {
              const isPolicy =
                !!listInternalEstimatedCost?.[keyRow]?.orderSort &&
                !isAllProducts;
              return (
                <>
                  {isPolicy && !isAllProducts && "-"}
                  <Form.Item
                    name={["listInternalEstimatedCost", keyRow, "costId"]}
                    className="m-0"
                    rules={isPolicy ? undefined : required}
                    hidden={isPolicy}
                  >
                    <Select
                      {...costSelectProps}
                      placeholder={translate("Chọn chi phí")}
                      filterOption={(inputValue: string, option: any) => {
                        return (
                          option && searchSelect(inputValue, option?.label)
                        );
                      }}
                    />
                  </Form.Item>
                </>
              );
            }}
          />
          <Table.Column
            title={translate("Tỷ lệ")}
            dataIndex="percentage"
            width="150px"
            render={(v, keyRow: string, index) => {
              const percentage =
                listInternalEstimatedCost?.[keyRow]?.percentage;
              const isPolicy =
                listInternalEstimatedCost?.[keyRow]?.orderSort &&
                !isAllProducts;
              return (
                <>
                  <Form.Item
                    hidden
                    name={["listInternalEstimatedCost", keyRow, "orderSort"]}
                  />
                  <Form.Item
                    name={["listInternalEstimatedCost", keyRow, "percentage"]}
                    hidden
                  />

                  <DataText
                    value={
                      !isPolicy ? (
                        "-"
                      ) : (
                        <Currency
                          value={percentage * 100}
                          showCurrency={false}
                          after={"%"}
                        />
                      )
                    }
                  />
                </>
              );
            }}
          />
          <Table.Column
            width="150px"
            title={translate("common.quantity")}
            dataIndex="quantity"
            render={(v, keyRow: string) => {
              const isPolicy =
                listInternalEstimatedCost?.[keyRow]?.orderSort &&
                !isAllProducts;

              return isPolicy ? (
                "-"
              ) : (
                <InputMoney
                  name={["listInternalEstimatedCost", keyRow, "quantity"]}
                  isRequired
                  style={{ margin: 0 }}
                  placeholder={translate("Nhập giá trị")}
                />
              );
            }}
          />
          <Table.Column
            title={translate("products.fields.unitPrice")}
            dataIndex="unitPrice"
            width="200px"
            render={(v, keyRow: string) => {
              const isPolicy =
                !!listInternalEstimatedCost?.[keyRow]?.orderSort &&
                !isAllProducts;

              return (
                <InputMoney
                  name={["listInternalEstimatedCost", keyRow, "unitPrice"]}
                  isRequired
                  style={{ margin: 0 }}
                  readonly={isPolicy}
                  defaulValue={listInternalEstimatedCost?.[keyRow]?.unitPrice}
                  placeholder={translate("Nhập giá trị")}
                />
              );
            }}
          />
          <Table.Column
            title={translate("common.totalPrice.main")}
            dataIndex="total"
            width="200px"
            render={(v, keyRow: string) => {
              const unitPrice =
                listInternalEstimatedCost?.[keyRow]?.unitPrice || 0;
              const quantity =
                listInternalEstimatedCost?.[keyRow]?.quantity || 0;
              const percentage =
                listInternalEstimatedCost?.[keyRow]?.percentage;
              const isPolicy =
                listInternalEstimatedCost?.[keyRow]?.orderSort &&
                !isAllProducts;
              const money = unitPrice * (isPolicy ? percentage : quantity);
              return <Currency value={money} showCurrency={false} />;
            }}
          />
          <Table.Column
            title={translate("Ghi chú")}
            dataIndex="note"
            width="250px"
            render={(v, keyRow: string) => {
              const isPolicy =
                listInternalEstimatedCost?.[keyRow]?.orderSort &&
                !isAllProducts;

              return (
                <Form.Item
                  className="m-0"
                  name={["listInternalEstimatedCost", keyRow, "note"]}
                  rules={max(2000)}
                >
                  {isPolicy ? (
                    translate("Theo định mức công ty quy định")
                  ) : (
                    <TextArea
                      autoSize={{ minRows: 1, maxRows: 4 }}
                      placeholder={translate("Nhập ghi chú")}
                    />
                  )}
                </Form.Item>
              );
            }}
          />
          <Table.Column
            align="center"
            fixed="right"
            width={90}
            title={translate("table.actions")}
            render={(v, keyRow: string, index) => {
              const isPolicy =
                listInternalEstimatedCost?.[keyRow]?.orderSort &&
                !isAllProducts;

              if (isPolicy) return null;
              return (
                <ButtonConfirm
                  text={translate("Bạn muốn xóa sản phẩm dịch vụ này?")}
                  description=""
                  onClick={onRemoveProduct(index)}
                  type="text"
                  danger
                >
                  <Icons.DeleteOutlined />
                </ButtonConfirm>
              );
            }}
          />
        </Table>
        <Descriptions
          style={{ marginTop: 4 }}
          layout="horizontal"
          className="no-padding"
        >
          <Descriptions.Item label="Tổng chi phí bằng tiền">
            <DataText
              value={
                isEmpty(total) ? (
                  "-"
                ) : (
                  <Currency value={total} showCurrency={false} />
                )
              }
            />
          </Descriptions.Item>
        </Descriptions>
      </PanelStyle>
    </Collapse>
  );
});

export default CostTable;
