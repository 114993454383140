import React from "react";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Icons,
} from "@pankod/refine-antd";
import { Space } from "antd";

import { useTranslate, useCheckError } from "@pankod/refine-core";

import { layoutStyles, containerStyles, titleStyles } from "./styles";
import { TENANTS, TENANT_ID } from "configs/storage";
import { API_PATH } from "configs/path";
import { ITenantData } from "api/types";
import { useNavigate } from "react-router-dom";
import { defaultRedirectUrl } from "provider/authProvider";
import { fetchTenants } from "api";
import { usePage } from "hooks/page";

const { Title, Paragraph } = Typography;

export const ChooseTenantPage: React.FC = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { loadPermissions } = usePage();
  const { mutate: checkError } = useCheckError();
  const [tenants, setTenants] = React.useState<ITenantData[] | null>(null);

  const setTenantId = async (id: string) => {
    localStorage.setItem(TENANT_ID, id);
    loadPageData().then((t) => {
      navigate(`/${defaultRedirectUrl}`);
    });
  };

  const getTenants = async () => {
    fetchTenants(API_PATH.myTenants)
      .then((res) => {
        const data = res.data;
        localStorage.setItem(TENANTS, JSON.stringify(data));
        if (data.length === 1) {
          setTenantId(data[0].id);
          return;
        }
        setTenants(data as ITenantData[]);
      })
      .catch((error) => checkError(error));
  };

  const loadPageData = async () => {
    await Promise.all([
      loadPermissions ? loadPermissions() : Promise.resolve(),
    ]);
  };

  React.useEffect(() => {
    getTenants();
  }, []);

  const CardTitle = (
    <Title level={2} style={titleStyles}>
      {translate("pages.login.chooseTenant")}
    </Title>
  );

  if (!tenants) {
    return (
      <AntdLayout style={layoutStyles}>
        <Row
          justify="center"
          align="middle"
          style={{
            height: "100vh",
          }}
        >
          <Col>
            <Icons.LoadingOutlined />
          </Col>
        </Row>
      </AntdLayout>
    );
  }

  return (
    <AntdLayout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
          background: "white",
        }}
      >
        <Col xs={22}>
          <div style={containerStyles}>
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0 }}
              style={{ border: "none" }}
            >
              <Paragraph>{translate("pages.chooseTenant.title")}</Paragraph>
              {tenants.map((item: ITenantData, idx) => (
                <Space
                  key={idx}
                  className="tenant-item"
                  onClick={() => setTenantId(item.id)}
                >
                  <img src="/images/icons/home.png" width={22} />
                  <Typography.Text>{item?.name}</Typography.Text>
                </Space>
              ))}
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
