import { Form, FormItemProps } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { CurrencyType } from "api/enums";
import { MoneyInput } from "components/MoneyInput";
import { NamePath } from "rc-field-form/lib/interface";
import { CSSProperties, FC, ReactNode, memo } from "react";
import { Rule } from "rc-field-form/lib/interface";
import { ONE_THOUSAND_TRILLION } from "configs/constants";
import { Currency } from "components/Currency";

interface InputMoneyProps extends FormItemProps {
  name: NamePath;
  label?: ReactNode;
  placeholder?: string;
  isRequired?: boolean;
  style?: CSSProperties;
  value?: string;
  rules?: Rule[];
  isLimit?: boolean;
  allowDecimal?: boolean;
  decimalLength?: number;
  readonly?: boolean;
  defaulValue?: any;
  max?: number;
  min?: number;
  errorCode?: string;
  onChange?: (value: any) => void;
  isEmpty?: boolean;
}

const InputMoney: FC<InputMoneyProps> = memo((props) => {
  const translate = useTranslate();
  const {
    label,
    name,
    placeholder = "",
    isRequired = false,
    style = {},
    rules = [],
    isLimit = true,
    allowDecimal = false,
    decimalLength = 3,
    readonly,
    defaulValue = "",
    min = 1,
    max = ONE_THOUSAND_TRILLION,
    errorCode = "ER091",
    onChange,
    isEmpty = false,
    ...attr
  } = props;

  return (
    <Form.Item
      {...attr}
      label={label}
      name={name}
      style={style}
      rules={[
        { required: isRequired, message: translate("errors.ER005") },
        ...(isLimit
          ? [
              {
                validator: (_: any, value: any) => {
                  if (value === null || value === undefined)
                    return Promise.resolve();
                  const num = Number(value);

                  if (num < min || num > max) {
                    return Promise.reject(
                      new Error(
                        translate(`errors.${errorCode}`, {
                          min: min,
                          max: max.toLocaleString().toString(),
                        })
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]
          : []),
        ...rules,
      ]}
    >
      {readonly ? (
        isEmpty ? (
          "-"
        ) : (
          <Currency value={defaulValue} showCurrency={false} />
        )
      ) : (
        <MoneyInput
          decimalLength={decimalLength}
          placeholder={placeholder}
          align="left"
          allowClear
          currencyType={CurrencyType.VND}
          max={max}
          min={0}
          allowDecimal={allowDecimal}
          onChange={onChange}
        />
      )}
    </Form.Item>
  );
});

export default InputMoney;
