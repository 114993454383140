export default interface IContract {
  userParty: Party;
  serviceProviderParty: Party;
  thirdParty: Party;
  customerContractCode: string;
  signDate: Date;
  implementationDate: Date;
  completionDate: Date;
  description: string;
  contractDocuments: ContractDocuments[];
  contractProducts: ContractProducts[];
  contractPaymentProcesses: ContractPaymentProcesses[];
  contractInvoicingPlans: ContractInvoicingPlans[];
  contractGuaranteeInfos: ContractGuaranteeInfos[];
  contractImplementProgress: ContractImplementProgress[];
  totalBeforeTaxValue: number;
  totalTaxValue: number;
  totalAfterTaxValue: number;
  code: string;
  caseId: string;
  case: {
    value: string;
    code: string;
    label: string;
  };
  name: string;
  contractType: {
    id: string;
    code: string;
    name: string;  
    requireThirdParty: boolean;
    requireOriginal: boolean;
    requireProduct: boolean;
    userPartyEditable: boolean;
    providerPartyEditable: boolean;
  };
  contractTypeId: string;
  contractStatus: ContractStatus;
  contractStatusId: string;
  revenueBeforeTax: number;
  validDate: Date;
  expirationDate: Date;
  createdDate: Date;
  createdName: string;
  updatedDate: Date;
  updatedName: string;
  id: string;
  hasContractCommitmentTab: boolean;
  contractCode: string;
  originalContract: {
    id: string;
    code: string;
    name: string;
  }
}

export type Party = {
  partyType: PartyType;
  id: string;
  code: string;
  name: string;
  partyId: string;
};

export type ContractDocuments = {
  description: string;
  fileId: string;
  fileUrl: string;
  fileUrlOrigin: string;
  fileUrlView: string;
  uploadDate: Date;
};

export type ContractProducts = {
  productId: string;
  productName: string;
  partNumber: string;
  description: string;
  quantity: number;
  unitId: string;
  unit: {
    id: string;
    name: string;
  };
  unitPrice: number;
  taxPercent: number;
  beforeTaxValue: number;
  taxValue: number;
  afterTaxValue: number;
  createdDate: Date;
  createdName: string;
  updatedDate: Date;
  updatedName: string;
  id: string;
};

export type ContractPaymentProcesses = {
  term: string;
  paymentValue: number;
  estimatedPaymentDate: Date;
  id: string;
  note: string;
};

export type ContractInvoicingPlans = {
  invoiceTerm: string;
  estimatedInvoiceDate: Date;
  invoiceValue: number;
  id: string;
};

export type ContractGuaranteeInfos = {
  guaranteeType: {
    id: string;
    code: string;
    name: string;
  };
  guaranteeTypeId: string;
  guaranteeValue: number;
  guaranteeIssueParty: {
    id: string;
    code: string;
    name: string;
  };
  guaranteeIssuePartyId: string;
  guaranteeCondition: {
    id: string;
    code: string;
    name: string;
  };
  guaranteeConditionId: string;
  estimatedIssueDate: Date;
  validPeriod: number;
  attachment: {
    fileId: string;
    fileUrl: string;
    fileUrlOrigin: string;
    fileUrlView: string;
  };
  id: string;
};

export type ContractImplementProgress = {
  completionDate: string;
  description: string;
  id: string;
  implementationDate: string;
  paymentTerm: string;
};

export type ContractStatus = {
  id: string;
  code: ContractStatusCode;
  name: string;
};

export enum PartySide {
  User,
  ServiceProvider,
  Third
}

export enum PartyType {
  Tenant,
  Customer,
  Supplier,
}

export enum ContractStatusCode {
  Draft = "Draft",
  Signed = "Signed",
  InProgress = "InProgress",
}

export type ProductDefault = {
  description: string;
  partNumber: string;
  product: {
    id: string;
    code: string;
    name: string;
  };
  quantity: number;
  unit: { id: string; name: string };
  unitPrice: number;
};
